/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME } from "../constants";
import { TGetBlueprintSchemaModel } from "../Schema/IBlueprintSchema";
import { TTypeDesc } from "../Shared/ITypeDescriptor";
import { TSchemaConstObjectProps, TSchemaConstObjectPropsSpec } from "../schemas/const/SchemaConstObject";
import { ISchemaDataSource } from "../schemas/SchemaDataSource";
import { IScope } from "../Shared/Scope";
import { TModelPath } from "../Shared/TModelPath";
import { IDataSourceStateBase, TDataSourceUpdateStateFunction } from "./IDataSourceDefinition";
import { IEventDefinitionMap, TEmptyEventDefinitionMap, TEventEnabledMap, TEventTriggerMap, TSchemaEventsSpec } from "../Events/EventTypes";

export type TSchemaDataSourceResolvedOptsSpec<TDataSourceOpts extends TSchemaConstObjectProps> =
	TSchemaConstObjectPropsSpec<TDataSourceOpts>;

/**
 * Rendered data source instance
 */
export interface IDataSourceInstance<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase,
	TComponentEvents extends IEventDefinitionMap
> {
	/** Object type */
	[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE_INSTANCE;

	/** Data source type name */
	type: string;

	/** Unique datasource name within scope */
	id: string;

	/** Path */
	path: TModelPath;

	/** Original model node ID */
	modelNodeId: number;

	/** Resolved data source spec */
	opts: TSchemaDataSourceResolvedOptsSpec<TDataSourceOpts>;

	/** Spec of resolved events */
	eventsSpec: TSchemaEventsSpec;

	/** Trigger to handle component events */
	eventTriggers: TEventTriggerMap<TComponentEvents>;

	/** Map specifying which events are enabled */
	eventEnabled: TEventEnabledMap<TComponentEvents>;

	/** Data source state */
	state: TDataSourceState;

	/** Function to update state externally */
	setState: TDataSourceUpdateStateFunction<TDataSourceState>;

	/** Last used type descriptor of scope data */
	lastScopeDataTypeDescriptor?: TTypeDesc;

	/** Custom component data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customData?: any;

	/** Model node (available only in edit mode via non-compiled render function) */
	modelNode?: TGetBlueprintSchemaModel<ISchemaDataSource>;

	/** Debug meta-data - available only in design mode (via non-compiled render function) */
	debug?: {
		scope?: IScope;
	};
}

export type TGenericDataSourceInstance = IDataSourceInstance<TSchemaConstObjectProps, IDataSourceStateBase, TEmptyEventDefinitionMap>;
