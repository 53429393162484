/**
 * Label React component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ClassList } from "../Classes/ClassList";
import { LABEL_FLOW, LABEL_FLOW_string, LABEL_FLOW_default } from "../Enums/LABEL_FLOW";
import { Icon, IIconProps } from "./Icon";
import { Text, ITextProps } from "./Text";
import { FORCE_RENDER_default, IBaseProps, IRenderProps } from "./props";
import { TLabelProps } from "../SharedSchemas/Label";
import { getStringEnumValue } from "../Functions/enumHelpers";
import { Link } from "./Link";
import { TElementType } from "../Types/TElementType";
import { TElementProps } from "../Types/TElementProps";

/**
 * Label props
 */
export interface ILabelProps extends IBaseProps, IRenderProps, TLabelProps {
	/** Label text */
	text?: Partial<ITextProps>;

	/** Label icon */
	icon?: Partial<IIconProps>;

	/** Flow */
	flow?: LABEL_FLOW_string;

	/** Tooltip */
	tooltip?: string;

	/** Toggle spacing **/
	spacing?: boolean;

	/** Tag name, defaults to "div" */
	tagName?: string;

	/** Custom additional inline stle */
	inlineStyle?: React.CSSProperties;

	/** Html for ("label" tagName only) */
	htmlFor?: string;

	/** Children */
	children?: unknown;
}

/**
 * Label component
 */
export const Label: React.FunctionComponent<ILabelProps> = (props) => {
	const {
		text,
		icon,
		spacing = true,
		flow,
		tooltip,
		componentPath,
		componentMode,
		tagName = "div",
		inlineStyle,
		htmlFor,
		link,
		children = null,
		forceRender = FORCE_RENDER_default
	} = props;

	const textContent = text?.value ? (
		<Text
			{...text}
			classList={new ClassList("label__text")}
			componentPath={[ ...componentPath, "text" ]}
			componentMode={componentMode}
		/>
	) : null;

	const iconContent = icon?.source ? (
		<Icon
			{...icon}
			classList={new ClassList("label__icon")}
			componentPath={[ ...componentPath, "icon" ]}
			componentMode={componentMode}
		/>
	) : null;

	if (!textContent && !iconContent && !forceRender) {
		return null;
	}

	let elementType: TElementType = tagName;
	let elementProps: TElementProps = {};

	if (inlineStyle) {
		elementProps.style = inlineStyle;
	}

	if (htmlFor) {
		elementProps.htmlFor = htmlFor;
	}

	const classList = new ClassList("label");

	classList.addModifiers({
		spacing,
		"with-text": !!textContent,
		"with-icon": !!iconContent,
		flow: getStringEnumValue(LABEL_FLOW, flow, LABEL_FLOW_default)
	});

	if (props.classList) {
		classList.add(...props.classList);
	}

	// Resolve Class list
	elementProps.className = classList.toClassName();

	if (link && link.locationData?.type !== "NONE") {
		elementType = Link;
		elementProps = { ...elementProps, ...link, classList: classList };
	}

	if (tooltip) {
		elementProps = { ...elementProps, title: tooltip };
	}

	return React.createElement(
		elementType,
		elementProps,
		<>
			{iconContent}
			{textContent}
			{children}
		</>
	);
};
