/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	createEmptyScope,
	DesignContext,
	DOC_ERROR_SEVERITY,
	functionMapToScopeData,
	IFunctionResolver,
	IRuntimeError,
	loadCompiledModel,
	queryModel,
	QUERY_FN_OP,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	TGetBlueprintSchemaModel,
	Type
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintIntegration, DOC_TYPES } from "../../blueprints";
import { SECRET_VALUE_HINT } from "../../constants";
import { IAppEnvs } from "../../envvars";
import { ERROR_CODES, ERROR_NAMES, IntegrationError } from "../../errors";
import { IIntegrationOptions } from "../../integrations";
import { ILogger } from "../../logger";
import {
	GENERIC_RESOURCE_PERMISSIONS,
	IIntegrationDefRegistry,
	RESOURCE_PERMISSIONS
} from "../../registries";
import { TExportSecretsMap } from "../../secrets";
import { IExecutionContext } from "../../WebServer";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { RESOURCE_ERROR_NAMES } from "../ResourceErrorNames";
import { IIntegrationResourceProps, IIntegrationResourceType } from "./IIntegrationResource";

type TIntegrationSchema = typeof BlueprintIntegration;
type TIntegrationSchemaModel = TGetBlueprintSchemaModel<TIntegrationSchema>;

export class IntegrationResourceV1 implements IIntegrationResourceType {
	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.INTEGRATION_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.INTEGRATION;
	}

	public get statsName(): string {
		return "integrations";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-integration-v1");
	}

	protected exportSecrets(resource: IIntegrationResourceProps): TExportSecretsMap {
		const result = queryModel(resource.parsedData.model, (item) => {
			if ([ "constSecret" ].includes(item.node.schema.name)) {
				return {
					match: true,
					op: QUERY_FN_OP.BREAK
				};
			}

			return {
				match: false,
				op: QUERY_FN_OP.CONTINUE
			};
		});

		const secrets: TExportSecretsMap = {};

		result.forEach((res) => {
			secrets[res.node["value"]] = {
				resource: resource.uri,
				property: res.path
					.filter((item) => item.key !== " ")
					.map((item) => item.key)
					.join("."),
				value: SECRET_VALUE_HINT
			};
		});

		return secrets;
	}

	public setup(resource: IIntegrationResourceProps): IIntegrationResourceProps {
		resource.resourceType = RESOURCE_TYPES.INTEGRATION;
		resource.parsingDetails.isRegistered = true;

		return resource;
	}

	public async scan(resource: IIntegrationResourceProps): Promise<IIntegrationResourceProps> {
		resource.parsingDetails.isValidEnvConfig = false;
		resource.parsingDetails.hasDefinition = false;
		resource.parsedData.exportSecrets = {};

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Scan integration '${uri}'.`);

		let dCtx: DesignContext;
		let model: TIntegrationSchemaModel;

		try {
			dCtx = resourceManager.createDCtx(false);
			resource = await resourceManager.parseModel<IIntegrationResourceProps>(
				resource,
				dCtx,
				BlueprintIntegration
			);
			model = resource.parsedData.model as TIntegrationSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse integration model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.parsedData.exportSecrets = this.exportSecrets(resource);
			resource.parsedData.type = model.props.spec.name;
			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec;

			if (!spec) {
				this.logger.warn("Can't parse routes model.");
				this.logger.debug({ uri });
				return resource;
			}

			let fallbackOpts;
			if (spec?.spec?.opts) {
				fallbackOpts = spec?.spec?.opts["default"];
			}

			if (!fallbackOpts) {
				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [
							{
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: RESOURCE_ERROR_NAMES.RESOURCE_INVALID_CONFIG,
								message: `Integration expected to have an 'default' configuration .`,
								details: [ `Integration Id: '${spec.id}'` ],
								modelPath: [ "$" ],
								modelNodeId: model.nodeId
							} as IRuntimeError
						];
					}
				} as RuntimeContext);

				this.logger.warn(`Integration '${uri}' has invalid environment configuration.`);
				return resource;
			}

			resource.parsingDetails.isValidEnvConfig = true;

			const { id } = resource;
			const { type } = resource.parsedData;

			const definition = this.app
				.get("registriesRegistry")
				.get<IIntegrationDefRegistry>("integrationDefRegistry")
				.get(type);

			if (!definition) {
				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [
							{
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: RESOURCE_ERROR_NAMES.RESOURCE_UNSUPPORTED_TYPE,
								message: `Unsupported integration type. Can't parse unknown integration.`,
								details: [ `Integration Id: '${id}'` ],
								modelPath: [ "$" ],
								modelNodeId: model.nodeId
							} as IRuntimeError
						];
					}
				} as RuntimeContext);
				this.logger.warn(
					`Failed to load integration '${id}': Unsupported integration type '${type}'.`
				);
				return resource;
			}

			resource.parsingDetails.hasDefinition = true;
			return resource;
		} catch (error) {
			this.logger.debug({ error, message: error?.message });
			resourceManager.reportError(`Can't parse integration '${uri}'.`, error);
			return resource;
		} finally {
			try {
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug({ error, message: error?.message });
			}
		}
	}

	public async parse(resource: IIntegrationResourceProps): Promise<IIntegrationResourceProps> {
		resource.parsingDetails.renderFnOk = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse integration '${uri}'.`);

		let dCtx: DesignContext;
		let model: TIntegrationSchemaModel;
		let cCtx: CompileContext;

		try {
			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IIntegrationResourceProps>(
				resource,
				dCtx,
				BlueprintIntegration
			);
			model = resource.parsedData.model as TIntegrationSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse integration model.");
				this.logger.debug({ uri });
				return resource;
			}

			cCtx = resourceManager.createCCtx();
			const compiledModel = cCtx.compileModel(model.props.spec, true, true);
			resource.reportCompileErrors(cCtx);

			if (cCtx.hasFatalErrors()) {
				this.logger.info(`Integration '${uri}' has fatal compile errors.`);
				this.logger.debug(cCtx.getCompileErrors());
				return resource;
			}

			let renderFn;
			try {
				renderFn = loadCompiledModel(compiledModel.code);
			} catch (error) {
				this.logger.warn("Failed to load compiled model.");
				this.logger.debug({ error, message: error?.message });

				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [
							{
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: RESOURCE_ERROR_NAMES.RESOURCE_COMPILATION_ERROR,
								message: "Failed to load compiled model.",
								details: [ `Action Id: '${resource.id}'` ],
								modelPath: [ "$" ],
								modelNodeId: model.nodeId
							} as IRuntimeError
						];
					}
				} as RuntimeContext);

				return resource;
			}

			resource.parsedData.renderFn = renderFn;
			resource.parsingDetails.renderFnOk = true;
			return resource;
		} catch (error) {
			this.logger.debug({ error, message: error?.message });
			resourceManager.reportError(`Can't parse integration '${uri}'.`, error);
			return resource;
		} finally {
			try {
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug({ error, message: error?.message });
			}
		}
	}

	public getDependenciesToReload(
		resource: IIntegrationResourceProps,
		eventType?: RESOURCE_ON_EVENT_TYPE
	): string[] {
		if (
			eventType &&
			[ RESOURCE_ON_EVENT_TYPE.UPDATE, RESOURCE_ON_EVENT_TYPE.DELETE ].includes(eventType)
		) {
			return resource?.dependencies?.map((dep) => dep.uri) || [];
		}

		return [];
	}

	/**
	 * Returns resolved integration's configuration.
	 *
	 * @param resource
	 * @param appEnvId
	 * @param context
	 */
	public async getConfiguration(
		resource: IIntegrationResourceProps,
		appEnvId: string,
		context: IExecutionContext
	): Promise<IIntegrationOptions> {
		this.logger.debug("Get integration config.");

		let rCtx: RuntimeContext;
		let integrationOpts;

		try {
			const functionScopeData = functionMapToScopeData(
				this.app.get("resolversRegistry").get<IFunctionResolver>("function").getFunctionMap(),
				false
			);

			let constants = {};
			if (this.app.has("constantsManager")) {
				constants = this.app.get("constantsManager").getAllConstants();
			}

			const _app: IAppEnvs = {
				envs: {},
				theme: this.app.get("resourceManager")?.getManifest()?.defaultTheme || {
					themeId: null,
					styleName: null
				}
			};
			if (this.app.has("envVarManager")) {
				_app.envs = this.app.get("envVarManager").getPublicVars();
			}

			const scope = createEmptyScope(
				{
					globals: {
						...functionScopeData.data
					},
					params: {},
					session: context.session.export(),
					currentUser: context.user || null,
					appId: this.app.appId,
					appEnvId: context.session?.appEnvId || appEnvId,
					appName: this.app.config.appName,
					constants,
					_app
				},
				{
					globals: Type.Object({
						props: {
							...functionScopeData.type
						}
					})
				}
			);

			rCtx = new RuntimeContext(
				{
					resolvers: this.app.get("resolversRegistry").getAll(),
					mode: RUNTIME_CONTEXT_MODE.NORMAL
				},
				resource.parsedData.renderFn,
				scope
			);

			let spec;
			try {
				spec = await rCtx.renderAsync(true);
			} catch (error) {
				this.logger.warn("Failed to render integration configuration spec. Unhandled error.");
				this.logger.debug({ error, message: error.message });
				return integrationOpts;
			}

			if (rCtx.hasFatalErrors()) {
				const message = "Integration has invalid configuration.";

				this.logger.warn(message);
				this.logger.debug(rCtx.getRuntimeErrors());

				const details = rCtx.getRuntimeErrors().map((error) => ({
					message: `${error?.message}. ${error?.details?.join(". ")}`,
					path: `${error?.modelPath?.join(".")} (${error?.name})`
				}));

				throw new IntegrationError(
					ERROR_NAMES.INTEGRATION_EXEC,
					ERROR_CODES.INTEGRATION_EXEC,
					message,
					{
						details: {
							integrationType: resource.parsedData.type,
							integrationId: resource.id,
							details
						}
					}
				);
			}

			if (!spec) {
				this.logger.warn("Can't parse routes model.");
				return integrationOpts;
			}

			return spec?.opts?.[appEnvId] as IIntegrationOptions;
		} catch (error) {
			if (error instanceof IntegrationError) {
				throw error;
			}

			this.logger.debug({ error, message: error?.message });
			return integrationOpts;
		} finally {
			try {
				if (rCtx) {
					rCtx.destroy();
				}
			} catch (error) {
				this.logger.debug({ error, message: error?.message });
			}
		}
	}
}
