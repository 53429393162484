/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { TAllNodesSpec, TBlueprintRedirectNodeOptsSchemaSpec } from "../../blueprints";
import { createSuccessNodeResult } from "../helpers";

export async function redirectNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintRedirectNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	return createSuccessNodeResult(
		{
			opts,
			outputName: null,
			data: opts,
			typeDescriptor: Type.Object({
				props: {
					url: Type.String({}),
					status: Type.Integer({}),
					headers: Type.Map({
						items: { "[K: string]": Type.String({}) }
					}),
					body: Type.Any({})
				}
			})
		},
		aCtx,
		nCtx
	);
}
