/**
 * hae-lib-core
 *
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";

/**
 * Route Registry Item props
 */
export interface IRouteRegistryItemProps extends ItemRegistryItemProps {
	/** Path */
	path: string;
	/** Route label */
	label: string;
}

/**
 * Routes Registry
 */
export type IRoutesRegistry = ItemRegistry<IRouteRegistryItemProps>;

export class RoutesRegistry extends ItemRegistry<IRouteRegistryItemProps> {}
