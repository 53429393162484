/**
 * Field HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";

import { propGroups } from "@hexio_io/hae-lib-components";

import { termsEditor } from "../terms";

export const CustomValidationProps = {

	condition: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.customValidationCondition,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 0),

	message: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.customValidationMessage,
		default: "",
		constraints: {
			required: true
		}
	}), 10)

};

const calendarStringProps = {
	months: BP.Prop(
		BP.Array({
			...termsEditor.schemas.datePicker.stringsMonths,
			default: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			],
			items: BP.String({
				fallbackValue: "",
				constraints: {
					required: true
				}
			}),
			constraints: {
				required: true,
				minItems: 12,
				maxItems: 12
			},
			getElementModelNodeInfo(modelNode, index) {
				return {
					label: modelNode?.constant.value || "#" + index,
					icon: ""
				}
			}
		}),
		10
	),
	shortMonths: BP.Prop(
		BP.Array({
			...termsEditor.schemas.datePicker.stringsShortMonths,
			default: [
				"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
			],
			items: BP.String({
				fallbackValue: "",
				constraints: {
					required: true
				}
			}),
			constraints: {
				required: true,
				minItems: 12,
				maxItems: 12
			},
			getElementModelNodeInfo(modelNode, index) {
				return {
					label: modelNode?.constant.value || "#" + index,
					icon: ""
				}
			}
		}),
		20
	),
	days: BP.Prop(
		BP.Array({
			...termsEditor.schemas.datePicker.stringsDays,
			default: [
				"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
			],
			items: BP.String({
				fallbackValue: "",
				constraints: {
					required: true
				}
			}),
			constraints: {
				required: true,
				minItems: 7,
				maxItems: 7
			},
			getElementModelNodeInfo(modelNode, index) {
				return {
					label: modelNode?.constant.value || "#" + index,
					icon: ""
				}
			}
		}),
		30
	),
	shortDays: BP.Prop(
		BP.Array({
			...termsEditor.schemas.datePicker.stringsShortDays,
			default: [
				"S", "M", "T", "W", "T", "F", "S"
			],
			items: BP.String({
				fallbackValue: "",
				constraints: {
					required: true
				}
			}),
			constraints: {
				required: true,
				minItems: 7,
				maxItems: 7
			},
			getElementModelNodeInfo(modelNode, index) {
				return {
					label: modelNode?.constant.value || "#" + index,
					icon: ""
				}
			}
		}),
		40
	),
	goToToday: BP.Prop(
		BP.String({
			...termsEditor.schemas.datePicker.stringsGoToToday,
			default: "Go to today",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		50
	),
	prevMonthAriaLabel: BP.Prop(
		BP.String({
			...termsEditor.schemas.datePicker.stringsPrevMonthAriaLabel,
			default: "Go to previous month",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		60
	),
	nextMonthAriaLabel: BP.Prop(
		BP.String({
			...termsEditor.schemas.datePicker.stringsNextMonthAriaLabel,
			default: "Go to next month",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		70
	),
	prevYearAriaLabel: BP.Prop(
		BP.String({
			...termsEditor.schemas.datePicker.stringsPrevYearAriaLabel,
			default: "Go to previous year",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		80
	),
	nextYearAriaLabel: BP.Prop(
		BP.String({
			...termsEditor.schemas.datePicker.stringsNextYearAriaLabel,
			default: "Go to next year",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		90
	)
};

export const DatePickerProps = {

	// COMMON
	label: BP.Prop(BP.String({
		...termsEditor.schemas.timePicker.label,
		default: "",
		constraints: {
			required: false
		}
	}), 10, propGroups.common),

	ariaLabel: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.ariaLabel,
		default: "Select a date",
		constraints: {
			required: false
		}
	}), 20, propGroups.common),
	
	pickerAriaLabel: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.pickerAriaLabel,
		default: "Calendar",
		constraints: {
			required: false
		}
	}), 30, propGroups.common),

	placeholder: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.placeholder,
		default: "Select a date...",
		constraints: {
			required: false
		}
	}), 40, propGroups.common),
	
	openOnClick: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.openOnClick,
		default: true,
		fallbackValue: true,
		constraints: {
			required: false
		}
	}), 50, propGroups.common),

	allFocusable: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.allFocusable,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 60, propGroups.common),

	allowTextInput: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.allowTextInput,
		default: true,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 70, propGroups.common),

	formatter: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.datePicker.formatter,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.ScopedTemplate({
				template: BP.Expression({
					default: `DATE_FORMAT(value, "dd.MM.yyyy")`,
					placeholder: "",
					example: "DATE_FORMAT(value, \"yyyy-MM-dd\"); DATE_PARSE(value, \"yy/MM/dd\")"
				})
			}),
			example: "DATE_FORMAT(value, \"yyyy-MM-dd\"); DATE_PARSE(value, \"yy/MM/dd\")"
		}),
		80,
		propGroups.common
	),

	parser: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.datePicker.parser,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.ScopedTemplate({
				template: BP.Expression({
					default: `DATE_PARSE(value, "d.M.yyyy")`,
					placeholder: "",
					example: "DATE_PARSE(value, \"yyyy-M-d\"); DATE_PARSE(value, \"yy/M/d\")"
				})
			}),
			example: "DATE_PARSE(value, \"yyyy-M-d\"); DATE_PARSE(value, \"yy/M/d\")"
		}),
		85,
		propGroups.common
	),

	strings: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.datePicker.strings,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				...termsEditor.schemas.datePicker.strings,
				props: calendarStringProps
			})
		}),
		90,
		propGroups.common
	),

	// STATE¨
	fieldName: BP.Prop(
		BP.String({
			...termsEditor.field.name,
			default: null,
			fallbackValue: null,
			constraints: {
				required: false
			}
		}),
		90,
		propGroups.state
	),

	readOnly: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.readOnly,
		default: false,
		fallbackValue: false,
		constraints: {
			required: true
		}
	}), 100, propGroups.state),

	disabled: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.disabled,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 110, propGroups.state),
	
	disableAutoFocus: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.disableAutoFocus,
		default: true,
		fallbackValue: true,
		constraints: {
			required: false
		}
	}), 120, propGroups.state),


	// STYLE
	borderless: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.borderless,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 210, propGroups.style),
	
	underlined: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.underlined,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 220, propGroups.style),
	
	isMonthPickerVisible: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.isMonthPickerVisible,
		default: true,
		fallbackValue: true,
		constraints: {
			required: false
		}
	}), 230, propGroups.style),

	highlightCurrentMonth: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.highlightCurrentMonth,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 235, propGroups.style),
	
	highlightSelectedMonth: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.highlightSelectedMonth,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 240, propGroups.style),
	
	showCloseButton: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.showCloseButton,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 245, propGroups.style),

	showMonthPickerAsOverlay: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.showMonthPickerAsOverlay,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 250, propGroups.style),

	showWeekNumbers: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.showWeekNumbers,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 255, propGroups.style),


	// VALIDATION
	isRequired: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.isRequired,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 300, propGroups.validation),

	validate: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.validate,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 310, propGroups.validation),
	
	minDate: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.minDate
	}), 320, propGroups.validation),

	maxDate: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.maxDate
	}), 330, propGroups.validation),

	customValidation: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.datePicker.customValidation,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: CustomValidationProps,
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 340, propGroups.validation),

	// calendarAs
	// calendarProps
	// calloutProps
	// className
	// componentRef
	// dateTimeFormatter

	// firstDayOfWeek,
	// firstWeekOfYear
	// formatDate


	// initialPickerDate

	// onAfterMenuDismiss: BP.Prop(BP.Void({})),

	// onSelectDate
};


// --- Time Picker ---
export const TimePickerProps = {

	allowFreeform: BP.Prop(BP.Boolean({
		...termsEditor.schemas.timePicker.allowFreeform,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 0, propGroups.state),

	label: BP.Prop(BP.String({
		...termsEditor.schemas.timePicker.label,
		default: "",
		constraints: {
			required: false
		}
	}), 10, propGroups.common),

	increments: BP.Prop(BP.Integer({
		...termsEditor.schemas.timePicker.increments,
		constraints: {
			min: 0
		}
	}), 20, propGroups.common),

	readOnly: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.readOnly,
		default: false,
		fallbackValue: false,
		constraints: {
			required: true
		}
	}), 90, propGroups.state),

	validate: BP.Prop(BP.Boolean({
		...termsEditor.schemas.datePicker.validate,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 100, propGroups.validation),

	showSeconds: BP.Prop(BP.Boolean({
		...termsEditor.schemas.timePicker.showSeconds,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 30, propGroups.state),

	useHour12: BP.Prop(BP.Boolean({
		...termsEditor.schemas.timePicker.useHour12,
		default: false,
		fallbackValue: false,
		constraints: {
			required: false
		}
	}), 40, propGroups.state),

	customValidation: BP.Prop(BP.OptGroup({
		...termsEditor.schemas.datePicker.customValidation,
		enabledOpts: {
			default: false,
			fallbackValue: false,
		},
		value: BP.Object({
			props: CustomValidationProps,
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}), 300, propGroups.validation),

};
