/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IModelNodeCompileResult } from "../Schema/IModelNode";
import { IScope } from "../Shared/Scope";
import { RuntimeContext } from "./RuntimeContext";

/**
 * Escapes string value
 *
 * @param value Value
 */
export function escapeString(value: string): string {
	// return value.replace(/\\/g, "\\\\").replace(/"/g, "\\\"").replace(/(?:\r\n|\r|\n)/g, "\\n");
	const escapedStr = JSON.stringify(String(value));
	return escapedStr.substr(1, escapedStr.length - 2);
}

/**
 * Inlines value into native javascript code
 *
 * Supports: Date, Array, Object, number, string, boolean
 *
 * @param value Value
 */
export function inlineValue(value: unknown): string {
	if (value === null) {
		return "null";
	} else if (value instanceof Date) {
		return `new Date(${value.getTime()})`;
	} else if (value instanceof Array) {
		return `[${value.map((item) => inlineValue(item)).join(",")}]`;
	} else if (value instanceof Object) {
		return `{${Object.keys(value)
			.map((key) => `"${escapeString(key)}":${inlineValue(value[key])}`)
			.join(",")}}`;
	} else if (typeof value === "number") {
		return value.toString();
	} else if (typeof value === "boolean") {
		return value ? "true" : "false";
	} else if (typeof value === "string") {
		return `"${escapeString(value)}"`;
	} else {
		throw new Error(`Cannot inline unsupported data type '${typeof value}'.`);
	}
}

/**
 * Returns resolved param based on isScoped property
 * @param arg Argument
 */
export function applyCodeArg(arg: IModelNodeCompileResult, prevValueCode = "pv", pathCode = "pt"): string {
	return arg.isScoped ? `(${arg.code})(s,${prevValueCode},${pathCode})` : arg.code;
}

/**
 * Runs compiled node function
 *
 * @param compiledNode Compilation result
 * @param scope Scope
 * @param prevValue Previous value
 */
export function runCompiledNode(
	rCtx: RuntimeContext,
	compiledNode: IModelNodeCompileResult,
	scope: IScope,
	prevValue?: unknown
): unknown {
	let fn;

	if (compiledNode.isScoped) {
		fn = new Function("s", "pv", "rCtx", "helpers", `return (${compiledNode.code})(s, pv)`);
	} else {
		fn = new Function("s", "pv", "rCtx", "helpers", `return (${compiledNode.code})`);
	}

	return fn(scope, prevValue);
}
