/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ACCOUNT } from "./account";
import { ACTION } from "./action";
import { APP } from "./app";
import { ASSET } from "./asset";
import { AUTH } from "./auth";
import { ENDPOINT } from "./endpoint";
import { MANIFEST } from "./manifest";
import { RENDERER } from "./renderer";
import { RESOURCE } from "./resource";
import { SECRETS } from "./secrets";
import { SESSIONS } from "./sessions";
import { TEMP_FILE } from "./tempFile";
import { THEME } from "./theme";
import { WEBSERVER } from "./webserver";

/**
 * Constants
 */
export const CONST = {
	ACTION,
	ACCOUNT,
	APP,
	ASSET,
	AUTH,
	ENDPOINT,
	MANIFEST,
	RENDERER,
	RESOURCE,
	SECRETS,
	SESSIONS,
	TEMP_FILE,
	WEBSERVER,
	THEME
};

export const SECRET_VALUE_HINT = "write secret here";

export * from "./IConstantsManager";
export * from "./ConstantsManager";
