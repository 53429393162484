/*
 * Hexio App Engine Editor
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { StylingManager } from "./StylingManager";

export interface IStylingContext {
	stylingManager: StylingManager;
}

export const StylingContext = React.createContext<IStylingContext>(null);

export function useStylingContext(): IStylingContext {
	return React.useContext(StylingContext);
}

export interface IStylingContextProviderProps {
	stylingManager?: StylingManager;
}

export const StylingContextProvider: React.FC<IStylingContextProviderProps> = ({
	children,
	stylingManager
}) => {
	return <StylingContext.Provider value={{ stylingManager }}>{children}</StylingContext.Provider>;
};

StylingContextProvider.defaultProps = {
	stylingManager: new StylingManager()
};
