/**
 * Composed Chart HAE component Create Series
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IDataProcessorSettings } from "@amcharts/amcharts5/.internal/core/util/DataProcessor";

import { getStringEnumValue } from "@hexio_io/hae-lib-components";

import { COMPOSED_CHART_SERIES_TYPE } from "../../Enums/COMPOSED_CHART_SERIES_TYPE";
import {
	COMPOSED_CHART_LINE_SERIES_STYLE,
	COMPOSED_CHART_LINE_SERIES_STYLE_default
} from "../../Enums/COMPOSED_CHART_LINE_SERIES_STYLE";
import { COMPOSED_CHART_AXIS_TYPE } from "../../Enums/COMPOSED_CHART_AXIS_TYPE";

import { TResolvedSeries } from "./state";
import { TChartAxes, TSeries, TSeriesSettings } from "./types";
import { noStrokeSettings } from "./settings";
import { TDatetimeAxisProps, TBarSeriesProps, TLineSeriesProps, TValueAxisProps } from "./props";
import { IColors, TCleanUpQueue } from "../../types/charts";
import { getChartColor } from "../../Functions/chartHelpers";

/**
 * Creates series
 */
export function createSeries(
	series: TResolvedSeries,
	root: am5.Root,
	chart: am5xy.XYChart,
	chartAxes: TChartAxes,
	colors: IColors,
	cleanUpQueue: TCleanUpQueue,
	scrollbarChart?: am5xy.XYChart,
	scrollbarChartAxes?: TChartAxes,
	onAxisStart?: (axisId: string, value: number) => void,
	onAxisEnd?: (axisId: string, value: number) => void,
): void {
	const { background, base } = colors;

	series.forEach((item, index) => {
		// Series' axes

		const { xAxis, yAxis } = item;

		const chartXAxis = chartAxes[xAxis.id];
		const chartYAxis = chartAxes[yAxis.id];

		const scrollbarChartXAxis = scrollbarChart ? scrollbarChartAxes[xAxis.id] : null;
		const scrollbarChartYAxis = scrollbarChart ? scrollbarChartAxes[yAxis.id] : null;

		// Series' settings

		const foregroundColor = getChartColor(item.originalData.foregroundColor);

		const settings: TSeriesSettings = {
			name: item.originalData.title || `#${index + 1}`,
			xAxis: chartXAxis,
			yAxis: chartYAxis,
			stroke: foregroundColor,
			fill: foregroundColor
		};

		const keySettings: Partial<TSeriesSettings> = {};

		keySettings[xAxis.type === COMPOSED_CHART_AXIS_TYPE.CATEGORY ? "categoryXField" : "valueXField"] =
			item.xKey;
		keySettings[yAxis.type === COMPOSED_CHART_AXIS_TYPE.CATEGORY ? "categoryYField" : "valueYField"] =
			item.yKey;

		let chartSeries: TSeries;
		let scrollbarChartSeries: TSeries;

		switch (item.type) {
			case COMPOSED_CHART_SERIES_TYPE.LINE: {
				const typeDataValue = item.originalData.typeData.value[
					item.originalData.typeData.type
				] as TLineSeriesProps;

				const styleValue = getStringEnumValue(
					COMPOSED_CHART_LINE_SERIES_STYLE,
					typeDataValue.style,
					COMPOSED_CHART_LINE_SERIES_STYLE_default
				);

				settings.connect = typeDataValue.connectEmptyValues;

				switch (styleValue) {
					case COMPOSED_CHART_LINE_SERIES_STYLE.LINEAR: {
						chartSeries = am5xy.LineSeries.new(root, { ...settings, ...keySettings });

						if (scrollbarChart) {
							scrollbarChartSeries = am5xy.LineSeries.new(root, {
								...keySettings,
								xAxis: scrollbarChartXAxis,
								yAxis: scrollbarChartYAxis
							});
						}

						break;
					}

					case COMPOSED_CHART_LINE_SERIES_STYLE.SMOOTH: {
						chartSeries = am5xy.SmoothedXLineSeries.new(root, { ...settings, ...keySettings });

						if (scrollbarChart) {
							scrollbarChartSeries = am5xy.SmoothedXLineSeries.new(root, {
								...keySettings,
								xAxis: scrollbarChartXAxis,
								yAxis: scrollbarChartYAxis
							});
						}

						break;
					}

					case COMPOSED_CHART_LINE_SERIES_STYLE.STEP: {
						chartSeries = am5xy.StepLineSeries.new(root, { ...settings, ...keySettings });

						if (scrollbarChart) {
							scrollbarChartSeries = am5xy.StepLineSeries.new(root, {
								...keySettings,
								xAxis: scrollbarChartXAxis,
								yAxis: scrollbarChartYAxis
							});
						}

						break;
					}
				}

				chartSeries.strokes.template.setAll({
					strokeWidth: typeDataValue.strokeWidth
				});

				if (typeDataValue.fillOpacity > 0) {
					chartSeries.fills.template.setAll({
						fillOpacity: typeDataValue.fillOpacity / 100,
						visible: true
					});

					if (scrollbarChartSeries) {
						scrollbarChartSeries.fills.template.setAll({
							fillOpacity: 0.1,
							visible: true
						});
					}
				}

				if (typeDataValue.points) {
					const { size } = typeDataValue.points;

					chartSeries.bullets.push(() =>
						am5.Bullet.new(root, {
							locationY: 0,
							sprite: am5.Circle.new(root, {
								radius: (size + 1) / 2,
								stroke: foregroundColor,
								strokeWidth: 1,
								fill: background
							})
						})
					);
				}

				break;
			}

			case COMPOSED_CHART_SERIES_TYPE.BAR: {
				const typeDataValue = item.originalData.typeData.value[
					item.originalData.typeData.type
				] as TBarSeriesProps;

				chartSeries = am5xy.ColumnSeries.new(root, { ...settings, ...keySettings });

				const columnWidth = am5.percent(typeDataValue.columnWidth || 80);

				chartSeries.columns.template.setAll({
					fillOpacity: 1,
					width: columnWidth
				});

				if (scrollbarChart) {
					scrollbarChartSeries = am5xy.ColumnSeries.new(root, {
						...keySettings,
						xAxis: scrollbarChartXAxis,
						yAxis: scrollbarChartYAxis
					});

					scrollbarChartSeries.columns.template.setAll({
						...noStrokeSettings,
						fillOpacity: 0.5,
						width: columnWidth
					});
				}

				break;
			}
		}

		// Series' tooltip

		const tooltip = chartSeries.set(
			"tooltip",
			am5.Tooltip.new(root, {
				autoTextColor: false,
				getFillFromSprite: false,
				getStrokeFromSprite: false,
				pointerOrientation: "horizontal"
			})
		);

		tooltip.get("background").setAll({
			// ...roundedRectangleBaseSettings, doesnt work
			fill: background,
			stroke: base,
			strokeWidth: 1
		});

		let tooltipText: string;

		if (xAxis.type === COMPOSED_CHART_AXIS_TYPE.CATEGORY) {
			tooltipText = "[bold]{categoryX}[/]";
		} else if (xAxis.type === COMPOSED_CHART_AXIS_TYPE.DATETIME) {
			const typeDataValue = xAxis.originalData.typeData.value[
				xAxis.originalData.typeData.type
			] as TDatetimeAxisProps;

			tooltipText = `[bold]{valueX.formatDate('${typeDataValue.tooltipFormat}')}[/]`;
		} else {
			tooltipText = `[bold]{valueX}[/]`;
		}

		tooltip.label.setAll({
			fill: base,
			text: tooltipText
		});

		tooltip.label.adapters.add("text", (text: string) => {
			let result = text;

			chart.series.each((seriesItem) => {
				let value: string;

				if (yAxis.type === COMPOSED_CHART_AXIS_TYPE.VALUE) {
					const typeDataValue = yAxis.originalData.typeData.value[
						yAxis.originalData.typeData.type
					] as TValueAxisProps;

					if (typeDataValue.tooltipFormat) {
						value = `${seriesItem.get("valueYField")}.formatNumber('${typeDataValue.tooltipFormat}')`;
					}
				} else if (yAxis.type === COMPOSED_CHART_AXIS_TYPE.DATETIME) {
					const typeDataValue = yAxis.originalData.typeData.value[
						yAxis.originalData.typeData.type
					] as TDatetimeAxisProps;

					if (typeDataValue.tooltipFormat) {
						value = `${seriesItem.get("valueYField")}.formatDate('${typeDataValue.tooltipFormat}')`;
					}
				} else {
					value = seriesItem.get("valueYField");
				}

				// eslint-disable-next-line max-len
				result += `\n[${seriesItem.get("stroke").toString()}]●[/] [bold]${seriesItem.get(
					"name"
				)}:[/] {${value}}`;
			});

			return result;
		});

		// Zoom change events

		chartXAxis.on("start", (ev) => {
			onAxisStart && onAxisStart(xAxis.id, ev);
		});

		chartXAxis.on("end", (ev) => {
			onAxisEnd && onAxisEnd(xAxis.id, ev);
		});

		chartYAxis.on("start", (ev) => {
			onAxisStart && onAxisStart(yAxis.id, ev);
		});

		chartYAxis.on("end", (ev) => {
			onAxisEnd && onAxisEnd(yAxis.id, ev);
		});

		// Series' data

		const dataProcessorDateFields = [];
		const dataProcessorNumericFields = [];

		if (xAxis.type === COMPOSED_CHART_AXIS_TYPE.DATETIME) {
			dataProcessorDateFields.push(item.xKey);
		} else if (xAxis.type === COMPOSED_CHART_AXIS_TYPE.VALUE) {
			dataProcessorNumericFields.push(item.xKey);
		}

		if (yAxis.type === COMPOSED_CHART_AXIS_TYPE.DATETIME) {
			dataProcessorDateFields.push(item.yKey);
		} else if (yAxis.type === COMPOSED_CHART_AXIS_TYPE.VALUE) {
			dataProcessorNumericFields.push(item.yKey);
		}

		if (dataProcessorDateFields.length || dataProcessorNumericFields.length) {
			const dataProcessorSettings: IDataProcessorSettings = {};

			if (dataProcessorDateFields.length) {
				dataProcessorSettings.dateFields = dataProcessorDateFields;
				dataProcessorSettings.dateFormat = "i";
			}

			if (dataProcessorNumericFields.length) {
				dataProcessorSettings.numericFields = dataProcessorNumericFields;
			}

			chartSeries.data.processor = am5.DataProcessor.new(root, dataProcessorSettings);
		}

		chart.series.push(chartSeries);

		if (scrollbarChartSeries) {
			scrollbarChart.series.push(scrollbarChartSeries);
		}

		cleanUpQueue.unshift(() => chartSeries.remove("tooltip"));
	});
}
