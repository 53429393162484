/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export enum WHILE_NODE_ON_ERROR_TYPES {
	FAIL_ON_FIRST = "fail_on_first",
	FAIL_AFTER_ALL = "fail_after_all",
	IGNORE = "ignore"
}

export const BlueprintWhileNode = {
	name: NODE_TYPES.WHILE,
	label: termsEditor.nodes.while.label,
	description: termsEditor.nodes.while.description,
	icon: "mdi/sync",
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			condition: BP.Prop(
				BP.ScopedTemplate({
					template: BP.Expression({
						label: termsEditor.nodes.while.condition.label,
						description: termsEditor.nodes.while.condition.description
					})
				})
			),
			loopType: BP.Prop(
				BP.Enum.String({
					label: termsEditor.nodes.while.loopType.label,
					description: termsEditor.nodes.while.loopType.description,
					options: [
						{
							value: "whileDo",
							label: termsEditor.nodes.while.loopType.types.whileDo
						},
						{
							value: "doWhile",
							label: termsEditor.nodes.while.loopType.types.doWhile
						}
					],
					constraints: {
						required: true
					},
					default: "whileDo",
					fallbackValue: "whileDo"
				})
			),
			maxIterations: BP.Prop(
				BP.Integer({
					label: termsEditor.nodes.while.maxIterations.label,
					description: termsEditor.nodes.while.maxIterations.description,
					default: 128,
					fallbackValue: 128,
					constraints: {
						required: true,
						max: 16384,
						min: 1
					}
				})
			),
			aggregateResults: BP.Prop(
				BP.Boolean({
					label: termsEditor.nodes.while.aggregateResults.label,
					description: termsEditor.nodes.while.aggregateResults.description,
					default: true,
					fallbackValue: false
				})
			)
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_CATCH,
		[NODE_OUTPUT_NAMES.ON_ITEM]: NODE_OUTPUTS.ON_ITEM
	})
};

export type TBlueprintWhileNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintWhileNode["opts"]>;
