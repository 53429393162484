/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	TAllNodesSpec,
	TBlueprintActionNodeTypes,
	TBlueprintFileCreateNodeOptsSchemaSpec,
} from "../../blueprints";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";
import { CONST } from "../../constants";

export async function fileCreateNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintFileCreateNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { contents, inputEncoding } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["fileCreate"]["opts"]>;
	const { app } = aCtx;

	try {
		let fileContents: Buffer;

		switch(inputEncoding) {
			case "utf8": {
				fileContents = Buffer.from(contents, "utf8");
				break;
			}
			case "base64": {
				fileContents = Buffer.from(contents, "base64");
				break;
			}
			default: {
				throw new Error("Invalid input encoding");
			}
		}

		const fileId = await app.get('tempFileService').create(fileContents, CONST.TEMP_FILE.EXPIRES_IN);

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: {
					fileId
				},
				typeDescriptor: Type.Object({
					props: {
						fileId: Type.String({})
					}
				})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return createErrorNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					name: ERROR_NAMES.FILE_PARSE_ERROR,
					code: ERROR_CODES.FILE_PARSE_ERROR,
					message: error?.message || "Failed to parse input contents into file buffer."
				},
				typeDescriptor: Type.Object({
					props: {
						message: Type.String({}),
						name: Type.String({})
					}
				}),
				error
			},
			aCtx,
			nCtx
		);
	}
}
