/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const endpointsTerms = {
	requireAuthenticatedUser: {
		label: "endpoint:requireAuthenticatedUser#label",
		description: "endpoint:requireAuthenticatedUser#description"
	},
	timeout: {
		label: "endpoint:timeout#label",
		description: "endpoint:timeout#description"
	}
};
