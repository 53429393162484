/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUT_NAMES, TAllNodesSpec } from "../../blueprints";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult } from "../helpers";
import { TBlueprintLogNodeOptsSchemaSpec } from "../../blueprints/nodes/BlueprintNodeLog";

export async function logNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintLogNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;
	const { level, message, data, attributes } = opts;

	let logArgs: unknown[];

	let sanitizedData: unknown;

	try {
		// We try to serialize data first, but it fails for some reason (like recursive structure)
		// we pass the original data to the logger instead and it will "console.log" it to some extent
		sanitizedData = JSON.stringify(data);
	} catch {
		sanitizedData = data;
	}

	if (attributes && Object.keys(attributes).length > 0) {
		logArgs = [attributes, message, sanitizedData];
	} else {
		logArgs = [message, sanitizedData];
	}

	switch(level) {
		case "debug": {
			context.debug(...logArgs);
			break;
		}
		case "info": {
			context.info(...logArgs);
			break;
		}
		case "warn": {
			context.warn(...logArgs);
			break;
		}
		case "error": {
			context.error(...logArgs);
			break;
		}
	}	

	return createErrorNodeResult(
		{
			opts,
			outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: null,
			typeDescriptor: Type.Void({})
		},
		aCtx,
		nCtx
	);
}
