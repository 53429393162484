/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { CoreComponentEventNodeTypes } from "../ui";

export class ComponentEventResolver {
	public getEventNodeTypes(): unknown {
		return CoreComponentEventNodeTypes;
	}

	public getEventHandler(): void {
		throw new Error("Not supported on server-side.");
	}
}
