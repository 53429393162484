/**
 * Loading schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstBoolean,
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	ISchemaOptionalGroup,
	ISchemaValue
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../terms";

/**
 * Loading schema
 */
export function LoadingSchema(): ISchemaOptionalGroup<
	ISchemaValue<
		ISchemaConstObject<{
			renderContent: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstBoolean>>;
		}>
	>
> {
	return BP.OptGroup({
		...termsEditor.schemas.common.loading,
		alias: "working",
		enabledOpts: {
			default: false,
			fallbackValue: false
		},
		value: BP.Object({
			props: {
				renderContent: BP.Prop(
					BP.Boolean({
						...termsEditor.schemas.common.loadingRenderContent,
						default: false,
						fallbackValue: false,
						constraints: {
							required: true
						}
					})
				)
			},
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	});
}
