/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAccount, IClientSession, ISessionProvider } from "@hexio_io/hae-lib-core";
import { createEventEmitter, emitEvent } from "@hexio_io/hae-lib-shared";
import { ApiApp } from "./ApiApp";
import { IAppState_Session } from "../../shared/IAppState";
import { dataEqual } from "@hexio_io/hae-lib-blueprint";
import { ErrorTracker } from "@hexio_io/hae-lib-components";

/**
 * Session provider config
 */
export interface ISessionProviderConfig {
	reloadIntervalMs: number;
}

/**
 * Provides session and user data and handles reload
 */
export class SessionProvider implements ISessionProvider {
	/** Session object */
	private session: IClientSession = null;

	/** User object */
	private identity: IAccount = null;

	/** Emitted when session data has changed */
	public onChange = createEventEmitter<void>();

	/**
	 * Constructor
	 */
	public constructor(private apiApp: ApiApp, private config: ISessionProviderConfig) {
		this.handleReload(false);
	}

	/**
	 * Returns session object
	 */
	public getSession(): IClientSession {
		return this.session;
	}

	/**
	 * Returns user object
	 */
	public getIdentity(): IAccount {
		return this.identity;
	}

	/**
	 * Reload session
	 */
	public async reload(reloadUserAccount?: boolean): Promise<void> {
		await this.loadSession(reloadUserAccount);
	}

	/**
	 * Loads session and sets another timeout
	 *
	 * @param load If to load initial data
	 */
	private handleReload(load = true) {
		if (load) {
			this.loadSession();
		}

		setTimeout(() => this.handleReload(), this.config.reloadIntervalMs);
	}

	/**
	 * Loads session object
	 */
	private async loadSession(reloadUserAccount?: boolean) {
		try {
			const res = await this.apiApp.getSession(reloadUserAccount);

			const session = {
				sessionId: res.sessionId,
				locale: res.locale,
				meta: res.meta,
				identityKey: res.identityKey
			};

			const identity = res.identity;

			if (!dataEqual(this.session, session) || !dataEqual(this.identity, identity)) {
				this.session = session;
				this.setIdentity(identity);

				emitEvent(this.onChange);
			}
		} catch (err) {
			console.error("[SessionProvider] Failed to load session:", err);
		}
	}

	/**
	 * Injects app state
	 *
	 * @param state Session state
	 */
	public injectState(state: IAppState_Session): void {
		this.session = state.session;
		this.identity = state.identity;
		emitEvent(this.onChange);
	}

	/**
	 * Sets user object
	 */
	public setIdentity(newIdentity: IAccount): void {
		this.identity = newIdentity;

		const errorTracker = ErrorTracker.getInstance();

		if (newIdentity?.accountId || newIdentity?.email) {
			errorTracker.setUser({
				...newIdentity,
				id: newIdentity.accountId,
				email: newIdentity.email,
				username: newIdentity.fullName || newIdentity.email,
				name: newIdentity.fullName,
				ip_address: "{{auto}}",
				segment: newIdentity.jobRole,
				admin: newIdentity.isAdmin
			});
		} else {
			errorTracker.clearUser();
		}
	}
}
