/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaValue } from "./value/SchemaValue";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaValueStringOpts, SchemaValueString } from "./value/SchemaValueString";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

type TValueSchema = ISchemaValue<ISchemaConstString>;

/**
 * Editor styles
 */
export enum SCHEMA_STRING_WITH_CONST_EDITOR_STYLE {
	DEFAULT = "default",
	COLOR = "color",
	FONT_SIZE = "fontSize"
}

/**
 * Color constant item
 */
export interface ISchemaStringWithConstConstItem {
	value: string;
	label?: string;
}

/**
 * Schema model
 */
export interface ISchemaStringWithConstModel extends IModelNode<ISchemaStringWithConst> {
	value: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaStringWithConstOpts extends ISchemaValueStringOpts {
	constants?: ISchemaStringWithConstConstItem[];
	editorStyle?: string | SCHEMA_STRING_WITH_CONST_EDITOR_STYLE;
}

/**
 * Spec value
 */
export type TSchemaStringWithConstSpec = TGetBlueprintSchemaSpec<TValueSchema>;

/**
 * Default value
 */
export type TSchemaStringWithConstDefault = TGetBlueprintSchemaDefault<TValueSchema>;

/**
 * Schema type
 */
export interface ISchemaStringWithConst
	extends IBlueprintSchema<
		ISchemaStringWithConstOpts,
		ISchemaStringWithConstModel,
		TSchemaStringWithConstSpec,
		TSchemaStringWithConstDefault
	> {
	/**
	 * Returns a list of available configuration names
	 */
	getConstantList: (modelNode: ISchemaStringWithConstModel) => ISchemaStringWithConstConstItem[];
}

/**
 * Schema: Font size
 *
 * @param opts Schema options
 */
export function SchemaStringWithConst(opts: ISchemaStringWithConstOpts): ISchemaStringWithConst {
	const valueSchema = SchemaValueString({
		...opts,
		constraints: opts.constraints
	});

	const schema = createEmptySchema<ISchemaStringWithConst>("stringWithConst", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "value" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		valueModel: TGetBlueprintSchemaModel<TValueSchema>,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			value: valueModel
		});

		const model = assignParentToChildrenOf(modelNode);

		model.initRequiredValid = valueModel.initRequiredValid;

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const valueModel = valueSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, valueModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedValue = modelNode.value.schema.clone(dCtx, modelNode.value, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			value: clonedValue
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.value.schema.destroy(modelNode.value);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		const valueModel = valueSchema.parse(dCtx, idtNode, null);
		return createModel(dCtx, valueModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {
		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			const items: ICompletionItem[] = [];

			for (let i = 0; i < opts.constants.length; i++) {
				items.push({
					kind: CMPL_ITEM_KIND.EnumMember,
					label: opts.constants[i].value,
					insertText: opts.constants[i].value
				});
			}

			return items;
		});
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return valueSchema.serialize(modelNode.value, path);
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		return modelNode.value.schema.render(rCtx, modelNode.value, path, scope, prevSpec);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		return modelNode.value.schema.compileRender(cCtx, modelNode.value, path);
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		return valueSchema.validate(rCtx, path, modelNodeId, value, validateChildren);
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		return valueSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);
	};

	schema.castSpec = (rCtx, path, modelNodeId, value) => {
		return valueSchema.castSpec(rCtx, path, modelNodeId, value);
	};

	schema.compileCastSpec = (cCtx, path, modelNodeId) => {
		return valueSchema.compileCastSpec(cCtx, path, modelNodeId);
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaStringWithConst", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return valueSchema.getTypeDescriptor(modelNode?.value);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	schema.getConstantList = () => {
		return opts.constants || [];
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "value",
				node: modelNode.value
			}
		];
	};

	return schema;
}
