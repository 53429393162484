/**
 * Number Field Slider HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { CSSProperties } from "react";

import { Slider } from "@hexio_io/hae-lib-components";
import { isFunction } from "@hexio_io/hae-lib-shared";

export interface NumberFieldSliderProps {
	className?: string;
	min?: number;
	max?: number;
	step?: number;
	disabled?: boolean;
	defaultValue?: number;
	value?: number;
	reverse?: boolean;
	marks?: Array<{ position: number, label: string, color: string }>;
	onChange?: (value: number) => void;
}

export function NumberFieldSlider({ value, onChange, ...props }: NumberFieldSliderProps): React.ReactElement {
	const _sliderChangeHandler = React.useCallback(
		(newValue: number) => {
			if (isFunction(onChange)) {
				onChange(newValue);
			}
		},
		[ value, onChange ]
	);

	const marks: Record<string, { label: string, style: CSSProperties }> = {};

	if (props.marks) {
		for (let i = 0; i < props.marks.length; i++) {
			const mark = props.marks[i];

			marks[mark.position] = {
				label: mark.label,
				style: {
					color: mark.color
				}
			}
		}
	}

	return (
		<Slider
			{...props}
			value={value}
			onChange={_sliderChangeHandler}
			dots={Number.isFinite(props.step)}
			marks={marks}
		/>
	);
}
