/**
 * hae-lib-core
 *
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericFunctionDefinition } from "@hexio_io/hae-lib-blueprint";

/**
 * Function Definition Registry
 */
export type IFunctionDefRegistry = ItemRegistry<TGenericFunctionDefinition>;

export class FunctionDefRegistry extends ItemRegistry<TGenericFunctionDefinition> {}
