/**
 * Media Resolutions
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { MEDIA_RESOLUTIONS, MEDIA_RESOLUTIONS_string } from "@hexio_io/hae-lib-blueprint";

/** Breakpoints, see hae-lib-components\src\scss\constants\media.scss */

const SCREEN_BASE_MAX = 1899;
const SCREEN_TABLET_MAX = 1259;
const SCREEN_TABLET_DEFAULT = 900;
const SCREEN_PHONE_MAX = 849;
const SCREEN_PHONE_DEFAULT = 320;

const SCREEN_LARGE_MIN = SCREEN_BASE_MAX + 1;
const SCREEN_BASE_MIN = SCREEN_TABLET_MAX + 1;
const SCREEN_TABLET_MIN = SCREEN_PHONE_MAX + 1;

export interface IMediaResolution {
	label: string;
	icon?: string;
	order: number;
	required?: boolean;
	screenMin?: number;
	screenMax?: number;
	screenDefault?: number;
	query?: string;
}

export const mediaResolutions: Map<MEDIA_RESOLUTIONS_string, IMediaResolution> = new Map([
	[
		"screenBase",
		{
			...MEDIA_RESOLUTIONS.screenBase,
			icon: "mdi/monitor",
			screenMin: SCREEN_BASE_MIN,
			screenMax: SCREEN_BASE_MAX,
			query: ""
		}
	],
	[
		"screenLarge",
		{
			...MEDIA_RESOLUTIONS.screenLarge,
			icon: "mdi/television",
			screenMin: SCREEN_LARGE_MIN,
			query: `only screen and (min-width: ${SCREEN_LARGE_MIN}px)`
		}
	],
	[
		"screenTablet",
		{
			...MEDIA_RESOLUTIONS.screenTablet,
			icon: "mdi/tablet-android",
			screenMin: SCREEN_TABLET_MIN,
			screenMax: SCREEN_TABLET_MAX,
			screenDefault: SCREEN_TABLET_DEFAULT,
			query: `only screen and (min-width: ${SCREEN_TABLET_MIN}px) and (max-width: ${SCREEN_TABLET_MAX}px)`
		}
	],
	[
		"screenPhone",
		{
			...MEDIA_RESOLUTIONS.screenPhone,
			icon: "mdi/cellphone",
			screenMax: SCREEN_PHONE_MAX,
			screenDefault: SCREEN_PHONE_DEFAULT,
			query: `only screen and (max-width: ${SCREEN_PHONE_MAX}px)`
		}
	],
	[
		"print",
		{
			...MEDIA_RESOLUTIONS.print,
			icon: "mdi/printer-outline",
			query: "only print"
		}
	]
]);
