/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	createEmptyScope,
	functionMapToScopeData,
	IFunctionResolver,
	IScope,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	Type
} from "@hexio_io/hae-lib-blueprint";
import { IViewParamsSpec } from "@hexio_io/hae-lib-shared";
import { IViewGlobals } from "../globals/IViewGlobals";
import { IGlobalsResolver } from "../resolvers/IGlobalsResolver";

export function createViewScope(rCtx: RuntimeContext, viewParamsSpec: IViewParamsSpec): IScope {
	const globals = rCtx.getResolver<IGlobalsResolver<IViewGlobals>>("globals").getGlobals();
	const fnScopeMap = functionMapToScopeData(
		rCtx.getResolver<IFunctionResolver>("function").getFunctionMap(),
		rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR
	);

	return createEmptyScope(
		{
			globals: {
				...fnScopeMap.data
			},
			...globals.data,
			params: viewParamsSpec
		},
		{
			globals: Type.Object({
				props: {
					...fnScopeMap.type
				}
			}),
			...(globals.type?.props || {})
			// @todo add params type descriptor
		}
	);
}
