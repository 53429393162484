/**
 * Custom HTML HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from 'react';
import {
	DatePicker,
	DayOfWeek,
	defaultDatePickerStrings,
	mergeStyles,
} from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { useField, getFieldStateProps } from "../useField";
import { DateTime } from "luxon";

import { BP, COMPONENT_MODE, createSubScope, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	addFormItem,
	ClassList,
	propGroups,
	removeFormItem,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { DatePickerProps } from '../props';
import { isString, isValidValue } from '@hexio_io/hae-lib-shared';
import { IFieldState } from '../state';

function getDateValuesFromISOStringOrTimestamp(value: string | Date): { dateValue: Date } {
	let dateValue = null;

	const dateTime = DateTime.fromISO(new Date(value).toISOString());

	if(!dateTime.isValid) {
		return {
			dateValue
		};
	}else{
		dateValue = new Date(value)
		return {
			dateValue
		};
	}
}

const rootClass = mergeStyles({ maxWidth: "100%", selectors: { '> *': { marginBottom: 15 } } });

interface HAEComponentDatePicker_State extends IFieldState {
	initialValue:  string | Date | null | undefined;
	value: string | Date | null | undefined;
}

/**
 * Date Picker props
 */
const HAEComponentDatePicker_Props = {
	...DatePickerProps,

	value: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.value,
		default: "",
		fallbackValue: null,
		constraints: {
			required: true
		}
	}), 0, propGroups.common)

};

const HAEComponentDatePicker_Events = {
	change: {
		...termsEditor.schemas.datePicker.events.change
	}
};


const HAEComponentDatePicker_Definition = defineElementaryComponent<
	typeof HAEComponentDatePicker_Props,
	HAEComponentDatePicker_State,
	typeof HAEComponentDatePicker_Events
>({
	...termsEditor.components.datePicker.component,

	name: "datePicker",

	category: "fluentUi",

	icon: "mdi/calendar-month-outline",

	docUrl: "...",

	order: 1000,

	props: HAEComponentDatePicker_Props,

	events: HAEComponentDatePicker_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, _rCtx, scope) => {
		const specValue = isValidValue(spec.value) ?
			spec?.value.length ?
				new Date(spec?.value).toISOString()
				: null
			: null
		const initialValue = isValidValue(specValue) ? specValue : state?.initialValue;

		const value = state?.initialValue === initialValue ?
			(isValidValue(state?.value) ? state.value : specValue) :
			specValue;

		function setValue(value: string | Date | null | undefined) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: initial ? initialValue : null }));
		}

		const newState = {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			setValue,
			clearValue
		};

		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: newState.value,
			changed: newState.changed || false,
			valid: newState.valid || false,
			clearValue: clearValue
		});

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: state.initialValue,
			value: state.value,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.Any({ ...termsEditor.schemas.datePicker.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.datePicker.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.datePicker.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.datePicker.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentDatePicker_React: THAEComponentReact<typeof HAEComponentDatePicker_Definition> = ({
	props,
	componentInstance,
	state,
	setState,
	reactComponentClassList
}) => {

	const {
		allFocusable,
		allowTextInput,
		ariaLabel,
		borderless,
		label,
		disableAutoFocus,
		disabled,
		highlightCurrentMonth,
		highlightSelectedMonth,
		isMonthPickerVisible,
		isRequired,
		//initialPickerDate,
		minDate,
		maxDate,
		openOnClick,
		readOnly,
		pickerAriaLabel,
		placeholder,
		showCloseButton,
		showMonthPickerAsOverlay,
		showWeekNumbers,
		underlined,
		customValidation,
		formatter,
		parser,
		strings
	} = props;

	useEffect(() => {
		initializeIcons();
	}, [])

	const { value } = state;

	const componentPath = componentInstance.safePath;
	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const { idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-datePicker",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const id = idClassName;

	const [firstDayOfWeek] = React.useState(DayOfWeek.Monday);

	// Validate function

	const validate = React.useMemo(() => {
		if (!props.validate) {
			return false;
		}

		return (newValue: string) => {
			if (!isString(newValue)) {
				return false;
			}

			if ((newValue === "" && !isRequired) || disabled) {
				return true;
			}

			return DateTime.fromISO(
				newValue.includes("T") ? newValue.split("T")[1] : newValue
			).isValid;
		};
	}, [ props.validate, disabled, isRequired ]);

	const { setValue } = useField<string>(
		{
			id,
			state,
			validate,
			customValidation,
			onChange: !elementReadOnly && componentInstance.eventEnabled.change ? componentInstance.eventTriggers.change : undefined
		},
		setState
	);

	const formatFn = React.useMemo(() => {
		if (!formatter) {
			return undefined;
		}

		return (date: Date): string => {
			return String(formatter((parentScope) => createSubScope(parentScope, {
				value: date
			}, {
				value: Type.Date({})
			})));
		}
	}, [ formatter ]);

	const parseFn = React.useMemo(() => {
		if (!parser) {
			return undefined;
		}

		return (value: string): Date => {
			return parser((parentScope) => createSubScope(parentScope, {
				value: value
			}, {
				value: Type.String({})
			})) as Date;
		}
	}, [ parser ]);

	const dateValue = value ? getDateValuesFromISOStringOrTimestamp(value).dateValue : undefined;

	const elementRef = React.useRef<HTMLDivElement>();

	const onSelectDate = (date: string | Date | null | undefined) => {
		componentInstance.setState((prevState) => ({...prevState, value: date || null}))
		setValue(date as string);
	}

	return (
		<div ref={elementRef} className={rootClass}>
			<DatePicker
				allFocusable={allFocusable}
				allowTextInput={allowTextInput}
				borderless={borderless}
				disableAutoFocus={disableAutoFocus}
				disabled={disabled}
				label={label}
				highlightCurrentMonth={highlightCurrentMonth}
				highlightSelectedMonth={highlightSelectedMonth}
				isMonthPickerVisible={isMonthPickerVisible}
				isRequired={isRequired}
				value={dateValue as Date}
				minDate={minDate ? new Date(minDate) : undefined}
				maxDate={maxDate ? new Date(maxDate) : undefined}
				openOnClick={openOnClick}
				pickerAriaLabel={pickerAriaLabel}
				placeholder={placeholder}
				showCloseButton={showCloseButton}
				showMonthPickerAsOverlay={showMonthPickerAsOverlay}
				showWeekNumbers={showWeekNumbers}
				underlined={underlined}
				firstDayOfWeek={firstDayOfWeek}
				ariaLabel={ariaLabel}
				onSelectDate={(date) => onSelectDate(date)}
				strings={strings || defaultDatePickerStrings}
				formatDate={formatFn}
				parseDateFromString={parseFn}
			/>
		</div>
	);
};

export const HAEComponentDatePicker: THAEComponentDefinition<typeof HAEComponentDatePicker_Definition> = {
	...HAEComponentDatePicker_Definition,
	reactComponent: HAEComponentDatePicker_React
};
