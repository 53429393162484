/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { TAllNodesSpec, TBlueprintOutputNodeOptsSchemaSpec } from "../../blueprints";
import { createSuccessNodeResult } from "../helpers";

export async function outputNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintOutputNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const data = opts["value"];

	return createSuccessNodeResult(
		{
			opts,
			outputName: null,
			data,
			typeDescriptor: Type.Any({})
		},
		aCtx,
		nCtx
	);
}
