/**
 * Composed Chart HAE component Create Hand
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

import { HAEComponentGaugeChart_State } from "./state";
import { IColors } from "../../types/charts";
import { getStringEnumCssValue, SPACING } from "@hexio_io/hae-lib-components";
import { getChartColor } from "../../Functions/chartHelpers";
import { isNonEmptyString, isValidValue, toNumber } from "@hexio_io/hae-lib-shared";

/**
 * Creates hand
 */
export function createHand(
	hand: HAEComponentGaugeChart_State["hand"],
	root: am5.Root,
	axisDataItemRef: React.MutableRefObject<am5.DataItem<am5xy.IValueAxisDataItem>>,
	colors: IColors,
	viewportRootElement: HTMLElement
): am5radar.ClockHand {
	if (!hand) {
		return;
	}

	// Offset (radius)

	let radius: number | am5.Percent;

	if (isNonEmptyString(hand.offset)) {
		if (hand.offset.endsWith("%")) {
			if (hand.offset !== "0%") {
				radius = am5.percent(0 - toNumber(hand.offset));
			}
		} else {
			const offsetValue = getStringEnumCssValue(
				SPACING,
				hand.offset,
				"spacing-",
				undefined,
				undefined,
				viewportRootElement
			);

			if (offsetValue.endsWith("px") && offsetValue !== "0px") {
				radius = 0 - toNumber(offsetValue);
			}
		}
	}

	if (!isValidValue(radius)) {
		radius = am5.percent(100);
	}

	// Inner radius

	let innerRadius: number | am5.Percent;

	if (isNonEmptyString(hand.innerRadius)) {
		const innerRadiusNumber = toNumber(hand.innerRadius);

		if (innerRadiusNumber > 0) {
			if (hand.innerRadius.endsWith("%")) {
				innerRadius = am5.percent(innerRadiusNumber);
			} else if (hand.innerRadius.endsWith("px")) {
				innerRadius = innerRadiusNumber;
			}
		}
	}

	// Size (bottom width)

	let bottomWidth: number;

	if (isNonEmptyString(hand.size)) {
		const sizeValue = getStringEnumCssValue(
			SPACING,
			hand.size,
			"spacing-",
			undefined,
			undefined,
			viewportRootElement
		);

		if (sizeValue.endsWith("px")) {
			bottomWidth = Math.max(1, toNumber(sizeValue));
		}
	}

	const result = am5radar.ClockHand.new(root, {
		topWidth: 1,
		bottomWidth,
		pinRadius: Number.isFinite(bottomWidth) ? bottomWidth / 2 : undefined,
		radius,
		innerRadius
	});

	const color = hand.foregroundColor
		? getChartColor(hand.foregroundColor, viewportRootElement)
		: colors.black;

	result.pin.setAll({
		fill: color,
		forceHidden: !!innerRadius
	});

	result.hand.setAll({
		fill: color
	});

	axisDataItemRef.current.set(
		"bullet",
		am5xy.AxisBullet.new(root, {
			sprite: result
		})
	);

	return result;
}
