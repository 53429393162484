/**
 * Valid value helpers
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TEmptyString, TNonEmptyArray } from "../Types/THelperTypes";

import { getWindowFromElement } from "./domHelpers";

/**
 * Checks if value is valid value, including number 0 and "false"
 *
 * @param value Value to check
 */
export function isValidValue(value: unknown): boolean {
	// !!value tests against null, undefined, NaN etc.
	return !!value || value === "" || value === 0 || value === false;
}

/**
 * Checks if value is a string
 *
 * @param value Value to check
 */
export function isString(value: unknown): value is string {
	return typeof value === "string";
}

/**
 * Checks if value is empty string, can be used together with isValidValue
 *
 * @param value Value to check
 */
export function isEmptyString(value: unknown): value is TEmptyString {
	return isString(value) && value === "";
}

/**
 * Checks if value is valid string which is not empty
 *
 * @param value Value to check
 */
export function isNonEmptyString(value: unknown): value is string {
	return isString(value) && value !== "";
}

/**
 * Checks if value is object
 *
 * @param value Value to check
 */
export function isObject(value: unknown): boolean {
	return typeof value === "object";
}

/**
 * Checks if value is valid object
 *
 * @param value Value to check
 */
export function isValidObject(value: unknown): value is Record<string, unknown> {
	return isObject(value) && value !== null;
}

/**
 * Checks if value is valid object with valid values
 *
 * @param value Value to check
 * @param depth Depth, 0 is infinite
 */
export function isValidObjectWithValidValues(value: unknown, depth = 0): value is Record<string, unknown> {
	const validateObject = depth - 1 !== 0;

	return (
		isValidObject(value) &&
		Object.values(value).every((item) => {
			return isObject(item) && validateObject
				? isValidObjectWithValidValues(item, depth - 1)
				: isValidValue(item);
		})
	);
}

/**
 * Checks if value is non-empty object
 *
 * @param value Value to check
 */
export function isNonEmptyObject(value: unknown): value is Record<string, unknown> {
	return isValidObject(value) && Object.keys(value).length > 0;
}

/**
 * Checks if value is valid non-empty object with valid values
 *
 * @param value Value to check
 * @param depth Depth, 0 is infinite
 */
export function isNonEmptyObjectWithValidValues(value: unknown, depth = 0): value is Record<string, unknown> {
	const validateObject = depth - 1 !== 0;

	return (
		isNonEmptyObject(value) &&
		Object.values(value).every((item) => {
			return isObject(item)
				? validateObject
					? isNonEmptyObjectWithValidValues(item, depth - 1)
					: isNonEmptyObject(item)
				: isValidValue(item);
		})
	);
}

/**
 * Checks if value is array
 *
 * @param value Value to check
 */
export function isArray<T = unknown>(value: unknown): value is T[] {
	return Array.isArray(value);
}

/**
 * Checks if value is non-empty array
 *
 * @param value Value to check
 */
export function isNonEmptyArray<T = unknown>(value: unknown): value is TNonEmptyArray<T> {
	return isArray<T>(value) && value.length > 0;
}

/**
 * Checks if value is undefined
 *
 * @param value Value to check
 */
export function isUndefined(value: unknown): value is undefined {
	return typeof value === "undefined";
}

/**
 * Checks if value is defined
 *
 * @param value Value to check
 */
export function isDefined(value: unknown): boolean {
	return typeof value !== "undefined";
}

/**
 * Checks if value is null or undefined
 *
 * @param value Value to check
 */
export function isNil(value: unknown): value is null | undefined {
	return value == null;
}

/**
 * Checks if value is null
 *
 * @param value Value to check
 */
export function isNull(value: unknown): value is null {
	return value === null;
}

/**
 * Checks if value is null or undefined or empty string
 *
 * @param value Value to check
 */
export function isNilOrEmptyString(value: unknown): value is null | boolean | TEmptyString {
	return isNil(value) || value === "";
}

/**
 * Checks if value is a function
 *
 * @param value Value to check
 */
export function isFunction(value: unknown): value is (...args: unknown[]) => unknown {
	return typeof value === "function";
}

/**
 * Checks if value is a boolean
 *
 * @param value Value to check
 */
export function isBoolean(value: unknown): value is boolean {
	return typeof value === "boolean";
}

/**
 * Checks if value is a number
 *
 * @param value Value to check
 */
export function isNumber(value: unknown): value is number {
	return typeof value === "number";
}

/**
 * Checks if value is Set
 *
 * @param value Value to check
 */
export function isSet(value: unknown): value is Set<unknown> {
	return value instanceof Set;
}

/**
 * Checks if value is Element type
 *
 * @param value
 */
export function isElement(value: unknown): value is Element {
	return value && value instanceof getWindowFromElement(value as Element)?.Element;
}

/**
 * Checks if value is HTMLElement type
 *
 * @param value
 */
export function isHTMLElement(value: unknown): value is HTMLElement {
	return value && value instanceof getWindowFromElement(value as HTMLElement)?.HTMLElement;
}

/**
 * Checks if `value` is between `low` and `high` (included).
 *
 * @param low
 * @param high
 * @param value
 */
export function isInRange(low: number, high: number, value: number): boolean {
	return (value - low) * (value - high) <= 0;
}
