/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME, SCOPE_KEY_INDEX_PROP_NAME } from "../constants";
import { ITypeDescObject, TypeDescObject } from "./ITypeDescriptor";

export type TScopeData = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
	[SCOPE_KEY_INDEX_PROP_NAME]?: Set<string>;
};

export type TScopeType = ITypeDescObject["props"];

export type TScopeMetaData = { [K: string]: unknown };

/**
 * Evaluation scope
 */
export interface IScope {
	/** Object type */
	[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE;

	/** Global data */
	readonly globalData: TScopeData;
	/** Global type */
	readonly globalType?: ITypeDescObject;

	/** Map of child scopes */
	childScopes: Map<unknown, IScope>;

	/** Local data */
	localData: TScopeData;
	/** local type */
	localType?: ITypeDescObject;

	/** Meta-data passed to children */
	metaData?: TScopeMetaData;
}

/**
 * Create an empty scope
 *
 * @param initialData Initial data
 * @param initialType Initial type descriptor
 */
export function createEmptyScope(
	initialData?: TScopeData,
	initialType?: TScopeType,
	metaData?: TScopeMetaData
): IScope {
	const _data = initialData || {};
	const _type = TypeDescObject({ props: initialType || {} });

	const idIndex = new Set<string>();

	for (const k in _data) {
		idIndex.add(k);
	}

	_data[SCOPE_KEY_INDEX_PROP_NAME] = idIndex;

	return {
		[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.SCOPE,
		globalData: _data,
		globalType: _type,
		localData: _data,
		localType: _type,
		childScopes: new Map(),
		metaData: metaData
	};
}

/**
 * Creates a new shallow copied scope from existing one and add new initial values
 *
 * @param scope Parent scope
 * @param initialData Data to add
 * @param initialType Type for data to add
 * @param prevScope Previous scope (creates a copy of local data from it)
 */
export function createSubScope(
	scope: IScope,
	initialData?: TScopeData,
	initialType?: TScopeType,
	prevScope?: IScope,
	metaData?: TScopeMetaData
): IScope {
	const _localData = prevScope ? { ...prevScope.localData } : {};
	const _localType = prevScope
		? { ...prevScope.localType }
		: TypeDescObject({
			props: {}
		});

	const _globalData = {
		...scope.globalData,
		..._localData,
		...(initialData || {}),
		__local: _localData
	};

	const _globalType: ITypeDescObject = {
		...scope.globalType,
		props: {
			...scope.globalType.props,
			..._localType.props,
			...(initialType || {}),
			__local: _localType
		}
	};

	const _childScopes = prevScope ? prevScope.childScopes : new Map();

	const idIndex = new Set<string>();

	if (scope.localData && scope.localData[SCOPE_KEY_INDEX_PROP_NAME]) {
		scope.localData[SCOPE_KEY_INDEX_PROP_NAME].forEach((value) => idIndex.add(value));
	}

	if (initialData && typeof initialData === "object") {
		for (const k in initialData) {
			idIndex.add(k);
		}
	}

	_localData[SCOPE_KEY_INDEX_PROP_NAME] = idIndex;

	return {
		[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.SCOPE,
		globalData: _globalData,
		globalType: _globalType,
		localData: _localData,
		localType: _localType,
		childScopes: _childScopes,
		metaData: scope.metaData !== undefined ? { ...scope.metaData, ...metaData } : metaData
	};
}

/**
 * Resets scope identifiers
 * Sets current scope identifiers to initial scope ones
 *
 * @param currentScope
 * @param initialScope
 */
export function resetScopeIdentifiers(currentScope: IScope, initialScope: IScope): void {
	const idIndex = new Set<string>();

	if (initialScope.localData && initialScope.localData[SCOPE_KEY_INDEX_PROP_NAME]) {
		initialScope.localData[SCOPE_KEY_INDEX_PROP_NAME].forEach((value) => idIndex.add(value));
	}

	currentScope.localData[SCOPE_KEY_INDEX_PROP_NAME] = idIndex;
}
