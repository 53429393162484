/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IService } from "../services/IService";
import {
	TEditorApiAppMetadataResponse,
	TEditorApiDeploymentStatusResponse,
	TEditorApiDeploymentsResponse,
	TEditorApiDeploymentResponse,
	TEditorApiDeploymentEnvironmentsResponse,
	TEditorApiAccountResponse,
	TEditorApiTokenResponse,
	TEditorApiImpersonateTokenResponse,
	TEditorApiImpersonateAccountsResponse,
	TEditorApiUserResponse
} from "../api/editor/responses";

import { IAccountData, IAuthProfile } from "../accounts/IAccountManager";

export enum BERYLLIUM_API_CLIENT_TYPE {
	BE = "be",
	MOCKED = "mocked"
}

/**
 * Beryllium Api Client Options
 */
export interface IBerylliumApiClientOptions {
	appEngineImage: string;
	//ssh: boolean;
	hostname: string;
	basic: {
		username: string;
		password: string;
	};
	timeout?: number;
}

/**
 * Mocked Beryllium Api Client Options
 */
export interface IMockedBerylliumApiClientOptions {
	appId: string;
	appEnvId: string;
	appName?: string;
	appMetadata?: TEditorApiAppMetadataResponse;
	deploymentStatus?: TEditorApiDeploymentStatusResponse;
	deployments?: TEditorApiDeploymentsResponse;
	deployment?: TEditorApiDeploymentResponse;
	deploymentEnvironments?: TEditorApiDeploymentEnvironmentsResponse;
	account?: TEditorApiAccountResponse;
	token?: string;
	impersonateToken?: TEditorApiImpersonateTokenResponse;
	impersonatedAccounts?: TEditorApiImpersonateAccountsResponse;
	isValidLogin?: boolean;
	user?: TEditorApiUserResponse;
	permissions?: string[];
}

export interface IBerylliumApiClient extends IService {
	/**
	 * Returns application metadata
	 *
	 * @param appId Application ID
	 * @param token Token
	 * @returns
	 */
	getAppMeta(appId: string, token: string): Promise<TEditorApiAppMetadataResponse | null>;

	/**
	 * Returns deployment status
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param token Token
	 * @returns
	 */
	getDeploymentStatus(appId: string, appEnvId: string): Promise<TEditorApiDeploymentStatusResponse | null>;

	/**
	 * Returns deployments
	 *
	 * @param appId Application ID
	 * @param token Token
	 * @param appEnvId Application environment ID
	 * @param limit
	 * @returns
	 */
	getDeployments(
		appId: string,
		appEnvId?: string,
		limit?: number
	): Promise<TEditorApiDeploymentsResponse | null>;

	/**
	 * Deploys deployment
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param version Version
	 * @param token Token
	 * @returns
	 */
	deploymentDeploy(
		appId: string,
		appEnvId: string,
		version: string,
		token: string
	): Promise<TEditorApiDeploymentResponse | null>;

	/**
	 * Returns list of deployment environments
	 * @param appId Application ID
	 * @param token Token
	 * @returns
	 */
	getDeploymentEnvironments(appId: string): Promise<TEditorApiDeploymentEnvironmentsResponse | null>;

	/**
	 * Returns user's account
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param token Token
	 * @returns
	 */
	getUserAccount(appId: string, appEnvId: string, token: string): Promise<TEditorApiAccountResponse | null>;

	/**
	 * Returns editor's account
	 */
	getEditorAccount(token: string): Promise<TEditorApiAccountResponse | null>;

	/**
	 * Returns token
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param profile Profile
	 * @param invitationToken Invitation token
	 * @param isAdmin Is admin flag
	 * @returns
	 */
	getToken(
		appId: string,
		appEnvId: string,
		profile: IAuthProfile,
		isAdmin: boolean,
		invitationToken?: string
	): Promise<TEditorApiTokenResponse>;

	/**
	 * Renews token
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param token Token
	 * @returns
	 */
	exchangeToken(token: string): Promise<TEditorApiTokenResponse | null>;

	/**
	 * Returns impersonate accounts
	 *
	 * @param appId Application ID
	 * @param token Token
	 * @returns
	 */
	getImpersonateAccounts(appId: string, token: string): Promise<TEditorApiImpersonateAccountsResponse>;

	/**
	 * Checks if application login is valid
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @param token Token
	 * @returns
	 */
	isValidAppLogin(appId: string, appEnvId: string, token: string): Promise<boolean>;

	/**
	 * Signs in user
	 *
	 * @param email Email
	 * @param password Password
	 */
	signIn(email: string, password: string): Promise<TEditorApiUserResponse>;

	/**
	 * Renew token
	 *
	 * @param appId Application ID
	 * @param appEnvId Application environment ID
	 * @returns
	 */
	renewToken(appId: string, appEnvId: string, token: string): Promise<TEditorApiTokenResponse>;
}
