/**
 * Field Info component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ClassList, IBaseProps, Text, TTextProps } from "@hexio_io/hae-lib-components";

/**
 * Field Info props
 */
export interface IFieldInfoProps extends IBaseProps {
	/** Field required */
	descriptionText: TTextProps;
}

/**
 * Field Info component
 */
export const FieldInfo: React.FunctionComponent<IFieldInfoProps> = (props) => {
	const classList = new ClassList("cmp-field__info");

	if (props.classList) {
		classList.add(...props.classList);
	}

	return props.descriptionText?.value ? (
		<div className={classList.toClassName()}>
			<Text
				{...props.descriptionText}
				classList={new ClassList("cmp-field__description")}
				componentPath={props.componentPath}
				componentMode={props.componentMode}
			/>
		</div>
	) : null;
};
