/**
 * Composed Chart schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const composedChart = {
	items: {
		label: "schema:composedChart.items#label",
		description: "schema:composedChart.items#description"
	},

	axes: {
		label: "schema:composedChart.axes#label",
		description: "schema:composedChart.axes#description"
	},
	axisId: {
		label: "schema:composedChart.axes#props.id#label",
		description: "schema:composedChart.axes#props.id#description"
	},
	axisTitle: {
		label: "schema:composedChart.axes#props.title#label",
		description: "schema:composedChart.axes#props.title#description"
	},
	axisPosition: {
		label: "schema:composedChart.axes#props.position#label"
	},
	axisPositionValues: {
		horizontal_bottom: {
			label: "schema:composedChart.axes#props.position#values.horizontalBottom#label"
		},
		horizontal_top: {
			label: "schema:composedChart.axes#props.position#values.horizontalTop#label"
		},
		vertical_left: {
			label: "schema:composedChart.axes#props.position#values.verticalLeft#label"
		},
		vertical_right: {
			label: "schema:composedChart.axes#props.position#values.verticalRight#label"
		}
	},
	axisType: {
		label: "schema:composedChart.axes#props.type#label",
		description: "schema:composedChart.axes#props.type#description"
	},
	axisTypeValues: {
		value: {
			label: "schema:composedChart.axes#props.type#values.value#label"
		},
		category: {
			label: "schema:composedChart.axes#props.type#values.category#label"
		},
		datetime: {
			label: "schema:composedChart.axes#props.type#values.datetime#label"
		}
	},
	axisMin: {
		label: "schema:composedChart.axes#props.min#label"
	},
	axisMax: {
		label: "schema:composedChart.axes#props.max#label"
	},
	axisStrictMinMax: {
		label: "schema:composedChart.axes#props.strictMinMax#label"
	},
	axisDatetimeInterval: {
		label: "schema:composedChart.axes#props.datetimeInterval#label"
	},
	axisDatetimeIntervalUnit: {
		label: "schema:composedChart.axes#props.datetimeInterval#props.unit#label"
	},
	axisDatetimeIntervalCount: {
		label: "schema:composedChart.axes#props.datetimeInterval#props.count#label"
	},
	axisTooltipDatetimeFormat: {
		label: "schema:composedChart.axes#props.tooltipDatetimeFormat#label"
	},
	axisTooltipNumberFormat: {
		label: "schema:composedChart.axes#props.tooltipNumberFormat#label"
	},
	axisGrid: {
		label: "schema:composedChart.axes#props.grid#label",
		description: "schema:composedChart.axes#props.grid#description"
	},

	series: {
		label: "schema:composedChart.series#label",
		description: "schema:composedChart.series#description"
	},
	seriesTitle: {
		label: "schema:composedChart.series#props.title#label",
		description: "schema:composedChart.series#props.title#description"
	},
	seriesType: {
		label: "schema:composedChart.series#props.type#label"
	},
	seriesTypeValues: {
		line: {
			label: "schema:composedChart.series#props.type#values.line#label"
		},
		area: {
			label: "schema:composedChart.series#props.type#values.area#label"
		},
		bar: {
			label: "schema:composedChart.series#props.type#values.bar#label"
		}
	},
	seriesStyle: {
		label: "schema:composedChart.series#props.style#label"
	},
	seriesStyleValues: {
		linear: {
			label: "schema:composedChart.series#props.style#values.linear#label"
		},
		smooth: {
			label: "schema:composedChart.series#props.style#values.smooth#label"
		},
		step: {
			label: "schema:composedChart.series#props.style#values.step#label"
		}
	},
	seriesFillOpacity: {
		label: "schema:composedChart.series#props.fillOpacity#label",
		description: "schema:composedChart.series#props.fillOpacity#description"
	},
	seriesStrokeWidth: {
		label: "schema:composedChart.series#props.strokeWidth#label"
	},
	seriesConnectEmptyValues: {
		label: "schema:composedChart.series#props.connectEmptyValues#label"
	},
	seriesPoints: {
		label: "schema:composedChart.series#props.points#label"
	},
	seriesPointsSize: {
		label: "schema:composedChart.series#props.points#props.size#label"
	},
	seriesColumnWidth: {
		label: "schema:composedChart.series#props.columnWidth#label",
		description: "schema:composedChart.series#props.columnWidth#description"
	},
	seriesXAxis: {
		label: "schema:composedChart.series#props.xAxis#label"
	},
	seriesYAxis: {
		label: "schema:composedChart.series#props.yAxis#label"
	},
	seriesAxisId: {
		label: "schema:composedChart.series#props.axisId#label",
		description: "schema:composedChart.series#props.axisId#description"
	},
	seriesAxisKey: {
		label: "schema:composedChart.series#props.axisKey#label",
		description: "schema:composedChart.series#props.axisKey#description"
	},
	seriesAxisMapper: {
		label: "schema:composedChart.series#props.axisMapper#label",
		description: "schema:composedChart.series#props.axisMapper#description"
	},

	ranges: {
		label: "schema:composedChart.ranges#label",
		description: "schema:composedChart.ranges#description"
	},
	rangeId: {
		label: "schema:composedChart.ranges#props.id#label",
		description: "schema:composedChart.ranges#props.id#description"
	},
	rangeAxisId: {
		label: "schema:composedChart.ranges#props.axisId#label",
		description: "schema:composedChart.ranges#props.axisId#description"
	},
	rangeDraggable: {
		label: "schema:composedChart.ranges#props.draggable#label",
		description: "schema:composedChart.ranges#props.draggable#description"
	},
	rangeStart: {
		label: "schema:composedChart.ranges#props.start#label",
		description: "schema:composedChart.ranges#props.start#description"
	},
	rangeEnd: {
		label: "schema:composedChart.ranges#props.end#label",
		description: "schema:composedChart.ranges#props.end#description"
	},
	rangeValue: {
		label: "schema:composedChart.ranges#props.value#label",
		description: "schema:composedChart.ranges#props.value#description"
	},
	rangeTitle: {
		label: "schema:composedChart.ranges#props.title#label",
		description: "schema:composedChart.ranges#props.title#description"
	},
	rangeFillOpacity: {
		label: "schema:composedChart.ranges#props.fillOpacity#label",
		description: "schema:composedChart.ranges#props.fillOpacity#description"
	},

	legend: {
		label: "schema:composedChart.legend#label",
		description: "schema:composedChart.legend#description"
	},

	horizontalScrollbar: {
		label: "schema:composedChart.horizontalScrollbar#label",
		description: "schema:composedChart.horizontalScrollbar#description"
	},
	horizontalScrollbarHeight: {
		label: "schema:composedChart.horizontalScrollbar#props.height#label",
		description: "schema:composedChart.horizontalScrollbar#props.height#description"
	},
	horizontalScrollbarPreview: {
		label: "schema:composedChart.horizontalScrollbar#props.preview#label",
		description: "schema:composedChart.horizontalScrollbar#props.preview#description"
	},

	datetimeUtc: {
		label: "schema:composedChart.datetimeUtc#label"
	},

	datetimeFormat: {
		label: "schema:composedChart.datetimeFormat#label",
		description: "schema:composedChart.datetimeFormat#description"
	},

	cursorBehavior: {
		label: "schema:composedChart.cursorBehavior#label",
		description: "schema:composedChart.cursorBehavior#description"
	},

	events: {
		zoomChanged: {
			label: "schema:composedChart.events.zoomChanged#label",
			description: "schema:composedChart.events.zoomChanged#description"
		},
		dataPointClick: {
			label: "schema:composedChart.events.dataPointClick#label",
			description: "schema:composedChart.events.dataPointClick#description"
		},
		rangeChange: {
			label: "schema:composedChart.events.rangeChange#label",
			description: "schema:composedChart.events.rangeChange#description"
		}
	}
};
