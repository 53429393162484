/**
 * Form context
 *
 * @package hae-lib-components
 * @copyright 2023 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IScope } from "@hexio_io/hae-lib-blueprint";

export const FORM_CONTEXT_PROP_NAME = "__formContext";

export interface TFormItem {
	uid: number|string;
	name: string;
	value: unknown;
	valid: boolean;
	changed: boolean;
	clearValue: (initial: boolean) => void;
}

/**
 * Form context
 *
 * Aggregates form items and provides methods to get form values, check validity and check if form was changed
 */
export class FormContext {
	private items: Map<number|string, TFormItem> = new Map();

	/**
	 * Add form item to context
	 *
	 * @param item Form item
	 */
	public addItem(item: TFormItem): void {
		this.items.set(item.uid, item);
	}

	/**
	 * Remove form item from context
	 *
	 * @param uid Form item unique ID
	 */
	public removeItem(uid: number|string): void {
		this.items.delete(uid);
	}

	/**
	 * Returns form values
	 *
	 * @returns Form values
	 */
	public getValues(): Record<string, unknown> {
		const values: Record<string, unknown> = { root: {} };

		this.items.forEach((item) => {
			// Parse name to structure
			const fieldPath = parseFieldName(item.name);
	
			if (fieldPath[0] === null) {
				fieldPath.unshift("_");
			}
	
			let stack: Record<string, unknown>|unknown[] = values;
			let stackKey: string|number = "root";
	
			for (let i = 0; i < fieldPath.length; i++) {
				const key = fieldPath[i];
	
				if (key === null) {
					// Is array
					if (!Array.isArray(stack[stackKey])) {
						stack[stackKey] = [];
					}
	
					stack = stack[stackKey];
					stackKey = stack.length as number;
				} else {
					// Is object
					if (!(stack[stackKey] instanceof Object)) {
						stack[stackKey] = {};
					}
	
					stack = stack[stackKey];
					stackKey = key;
				}
			}
	
			stack[stackKey] = item.value;
		});
	
		return values["root"] as Record<string, unknown>;
	}

	/**
	 * Clear all form values
	 */
	public clearValues(initial = false): void {
		this.items.forEach((item) => {
			item.clearValue(initial);
		});
	}

	/**
	 * Returns if form is valid
	 *
	 * @returns Form validity
	 */
	public isValid(): boolean {
		for (const item of this.items.values()) {
			if (!item.valid) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Returns if form was changed
	 *
	 * @returns Changed state
	 */
	public isChanged(): boolean {
		for (const item of this.items.values()) {
			if (item.changed) {
				return true;
			}
		}

		return false;
	}
}

/**
 * Adds form item to the context if context exists in the scope
 *
 * @param scope Scope
 * @param item Form item
 */
export function addFormItem(scope: IScope, item: TFormItem): void {
	const ctx: FormContext = scope.globalData[FORM_CONTEXT_PROP_NAME];

	if (ctx) {
		ctx.addItem(item);
	}
}

/**
 * Removes form item from the context if context exists in the scope
 *
 * @param scope Scope
 * @param uid Form item unique ID
 */
export function removeFormItem(scope: IScope, uid: number): void {
	const ctx: FormContext = scope.globalData[FORM_CONTEXT_PROP_NAME];

	if (ctx) {
		ctx.removeItem(uid);
	}
}

/**
 * Function to parse field name in format "foo[bar][][baz]" to path array ["foo", "bar", null, "baz"]
 * If there are empty brackets, null is used as a placeholder
 *
 * @param name Field name
 */
function parseFieldName(name: string) {
	const path: Array<string|null> = [];
	const bracketRegex = /\[([^\]]*)\]/g;
	let match: RegExpExecArray | null;

	while ((match = bracketRegex.exec(name)) !== null) {
		const [, key] = match;
		path[path.length] = key ? decodeURIComponent(key) : null;
	}

	// Add the remaining non-bracket parts to the path array
	const remaining = name.replace(bracketRegex, '');
	if (remaining) {
		path.unshift(remaining);
	}

	return path;
}