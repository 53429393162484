/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

export enum SYSTEM_COLOR {
	ERROR = "error",
	WARNING = "warning",
	SUCCESS = "success",
	INFO = "info",
	HINT = "hint"
}
