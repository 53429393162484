/**
 * Custom HTML HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from "react";

import {
	BP,
	COMPONENT_MODE,
	createSubScope,
	defineElementaryComponent,
	TSchemaConstObjectPropsSpec,
	Type
} from "@hexio_io/hae-lib-blueprint";

import {
	addFormItem,
	ClassList,
	removeFormItem,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import {
	isBoolean,
	isDeepEqual,
	isEmptyString,
	isFunction,
	isNilOrEmptyString,
	isValidValue
} from "@hexio_io/hae-lib-shared";
import {
	propGroups
} from "@hexio_io/hae-lib-components";

import { basicSetup } from "codemirror";
import { EditorState, Extension } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { indentWithTab } from "@codemirror/commands"
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { markdown } from "@codemirror/lang-markdown";

// Themes does not work because of fucked up build process (stupid bundler includes the same package multiple times)
// import * as themes from "thememirror";


const languageMap: { [K: string]: { label: string, extension: Extension } } = {
	"plaintext": { label: "Plain Text", extension: null },
	"javascript": { label: "JavaScript", extension: javascript({ jsx: false, typescript: false }) },
	"javascript+jsx": { label: "JavaScript + JSX", extension: javascript({ jsx: true, typescript: false }) },
	"typescript": { label: "TypeScript", extension: javascript({ jsx: false, typescript: true }) },
	"typescript+tsx": { label: "TypeScript + TSX", extension: javascript({ jsx: true, typescript: true }) },
	"json": { label: "JSON", extension: json() },
	"html": { label: "HTML", extension: html() },
	"css": { label: "CSS", extension: css() },
	"markdown": { label: "Markdown", extension: markdown() }
};

const themeMap: { [K: string]: { label: string, extension: Extension } } = {
	"base": { label: "Base", extension: null }
};

/**
 * Component state
 */
interface HAEComponentCodeMirror_State {
	initialValue: string;
	value: string;
	valueRev: number;
	empty: boolean;
	touched: boolean;
	changed: boolean;
	valid: boolean;

	/** Methods */
	setValue?: (value: unknown) => void;
	clearValue?: (initial: boolean) => void;
	updateInternalValue?: (value: unknown) => void;
}

export const initialState = {
	empty: true,
	touched: false,
	changed: false,
	valid: true
};

function isFieldEmpty(value: unknown): boolean {
	return isNilOrEmptyString(value);
}

function getFieldStateProps(
	value: unknown,
	initialValue: unknown,
	state: HAEComponentCodeMirror_State,
	validate: boolean,
	isEmpty: ((value: unknown) => boolean) | boolean = isFieldEmpty
): {
	empty: boolean;
	touched: boolean;
	changed: boolean;
} {
	return {
		empty: isFunction(isEmpty) ? isEmpty(value) : isEmpty,
		touched: isBoolean(state?.touched) ? state.touched : initialState.touched,
		changed: !isDeepEqual(value, initialValue)
	};
}

function isValid(
	spec: TSchemaConstObjectPropsSpec<typeof HAEComponentCodeMirror_Props>,
	value: string
): {
	valid: boolean
} {
	if (spec.validate !== true) {
		return { valid: true };
	}

	if (spec.required === true && isEmptyString(value)) {
		return { valid: false };
	}

	if (spec.minLength !== null && value.length < spec.minLength) {
		return { valid: false };
	}

	if (spec.maxLength !== null && value.length > spec.maxLength) {
		return { valid: false };
	}

	if (!!spec.customValidation && spec.customValidation.condition === false) {
		return { valid: false };
	}

	return { valid: true };
}

/**
 * Component Props
 */
const HAEComponentCodeMirror_Props = {
	value: BP.Prop(
		BP.String({
			...termsEditor.components.codeMirror.value,
			default: "",
			fallbackValue: null,
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.common
	),

	language: BP.Prop(
		BP.Enum.String({
			...termsEditor.components.codeMirror.language,
			default: "plaintext",
			fallbackValue: "plaintext",
			constraints: {
				required: true
			},
			options: Object.keys(languageMap).map((key) => ({ value: key, label: languageMap[key].label }))
		}),
		10,
		propGroups.common
	),

	theme: BP.Prop(
		BP.Enum.String({
			...termsEditor.components.codeMirror.theme,
			default: "base",
			fallbackValue: "base",
			constraints: {
				required: true
			},
			options: Object.keys(themeMap).map((key) => ({ value: key, label: themeMap[key].label }))
		}),
		20,
		propGroups.common
	),

	fontSize: BP.Prop(
		BP.Integer({
			...termsEditor.components.codeMirror.fontSize,
			default: 13,
			fallbackValue: 13,
			constraints: {
				required: true,
				min: 8,
				max: 24
			},
		}),
		30,
		propGroups.common
	),

	tabSize: BP.Prop(
		BP.Integer({
			...termsEditor.components.codeMirror.tabSize,
			default: 2,
			fallbackValue: 2,
			constraints: {
				required: true
			}
		}),
		50,
		propGroups.common
	),

	wrapLines: BP.Prop(
		BP.Boolean({
			...termsEditor.components.codeMirror.wrapLines,
			default: false,
			fallbackValue: false,
			constraints: {
				required: false
			}
		}),
		60,
		propGroups.common
	),

	fieldName: BP.Prop(
		BP.String({
			...termsEditor.components.codeMirror.fieldName,
			default: null,
			fallbackValue: null,
			constraints: {
				required: false
			}
		}),
		90,
		propGroups.state
	),

	readOnly: BP.Prop(
		BP.Boolean({
			...termsEditor.components.codeMirror.readOnly,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		100,
		propGroups.state
	),

	validate: BP.Prop(
		BP.Boolean({
			...termsEditor.components.codeMirror.validate,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		100,
		propGroups.validation
	),

	required: BP.Prop(
		BP.Boolean({
			...termsEditor.components.codeMirror.required,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		110,
		propGroups.validation
	),

	customValidation: BP.Prop(
		BP.OptGroup({
			...termsEditor.components.codeMirror.customValidation,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				props: {
					condition: BP.Prop(
						BP.Boolean({
							...termsEditor.components.codeMirror.customValidationCondition,
							default: true,
							fallbackValue: true,
							constraints: {
								required: true
							}
						}),
						0
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		300,
		propGroups.validation
	),

	minLength: BP.Prop(
		BP.Integer({
			...termsEditor.components.codeMirror.minLength,
			constraints: {
				min: 0
			}
		}),
		130,
		propGroups.validation
	),

	maxLength: BP.Prop(
		BP.Integer({
			...termsEditor.components.codeMirror.maxLength,
			constraints: {
				min: 0
			}
		}),
		140,
		propGroups.validation
	)
};

const HAEComponentCodeMirror_Events = {
	change: {
		...termsEditor.components.codeMirror.events.change
	}
};

const HAEComponentCodeMirror_Definition = defineElementaryComponent<
	typeof HAEComponentCodeMirror_Props,
	HAEComponentCodeMirror_State,
	typeof HAEComponentCodeMirror_Events
>({
	...termsEditor.components.codeMirror.component,

	name: "codeMirror@v1",

	initialVariableName: "codeMirror",

	category: "form",

	icon: "mdi/code-tags",

	order: 1000,

	props: HAEComponentCodeMirror_Props,

	events: HAEComponentCodeMirror_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, _rCtx, scope) => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;
		const value = state?.initialValue === initialValue
			? isValidValue(state?.value)
				? state.value
				: spec.value
			: spec.value;

		const valueRev = state?.initialValue === initialValue
			? isValidValue(state?.value)
				? state?.valueRev ?? 0
				: (state?.valueRev ?? 0) + 1
			: (state?.valueRev ?? 0) + 1;

		function triggerChangeEvent(newValue: string) {
			componentInstance.eventTriggers.change?.((scope) => createSubScope(scope, {
				$event: {
					value: newValue
				}
			}, {
				$event: Type.Object({
					props: {
						value: Type.String({})
					}
				})
			}));
		}

		function updateInternalValue(newValue: string) {
			// We do not update revision here, because change is not triggered outside the component
			updateStateAsync((prevState) => ({ ...prevState, value: newValue }));
			triggerChangeEvent(newValue);			
		}

		function setValue(newValue: string) {
			// We DO update revision here, because change comes from outside the component
			updateStateAsync((prevState) => ({ ...prevState, value: newValue, valueRev: prevState.valueRev + 1 }));
			triggerChangeEvent(newValue);
		}

		function clearValue(initial = false) {
			const newValue = !initial ? "" : initialValue;

			// We DO update revision here, because change comes from outside the component
			updateStateAsync((prevState) => ({ ...prevState, value: newValue, valueRev: prevState.valueRev + 1 }));
			triggerChangeEvent(newValue);
		}

		// Validate
		const newState = {
			value,
			valueRev,
			initialValue,
			valid: isValid(spec, value).valid,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			setValue,
			clearValue,
			updateInternalValue
		};

		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: newState.value,
			changed: newState.changed || false,
			valid: newState.valid || false,
			clearValue: clearValue
		});

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.components.codeMirror.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.components.codeMirror.valid }),
				setValue: Type.Method({
					...termsEditor.components.codeMirror.setValue,
					argRequiredCount: 1,
					argSchemas: [BP.String({})],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.components.codeMirror.clearValue,
					argRequiredCount: 0,
					argSchemas: [BP.Boolean({ default: false })],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentCodeMirror_React: THAEComponentReact<typeof HAEComponentCodeMirror_Definition> = (
	{ props, state, componentInstance, reactComponentClassList }
) => {
	const { readOnly, language, tabSize, theme, fontSize, wrapLines } = props;

	const { value, updateInternalValue, valueRev } = state;
	const { safePath: componentPath, componentMode } = componentInstance;
	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const divRef = React.useRef<HTMLDivElement>();
	const editorRef = React.useRef<EditorView>();

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-code-mirror",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const id = idClassName;

	// Init editor
	useEffect(() => {
		if (!divRef.current) {
			return;
		}

		const extensions: Extension[] = [
			basicSetup,
			keymap.of([indentWithTab]),
			EditorState.tabSize.of(tabSize),
			EditorState.readOnly.of(elementReadOnly),
			EditorView.theme({
				"&": {
					fontSize: `${fontSize}px`
				}
			})
		];

		const langEntry = languageMap[language];
		const themeEntry = themeMap[theme];

		if (langEntry?.extension) {
			extensions.push(langEntry.extension);
		}

		if (themeEntry?.extension) {
			extensions.push(themeEntry.extension);
		}

		if (wrapLines) {
			extensions.push(EditorView.lineWrapping);
		}

		const startState = EditorState.create({
			doc: value,
			extensions: extensions
		});

		const view = new EditorView({
			state: startState,
			parent: divRef.current,
			dispatch: (tr) => {
				view.update([tr]);
				const newValue = view.state.doc.toString();

				updateInternalValue(newValue);
			}
		});

		editorRef.current = view;

		return () => {
			editorRef.current = undefined;
			view.destroy();
		}
	}, [ language, elementReadOnly, theme, fontSize, wrapLines, tabSize ]);

	// Update editor value
	useEffect(() => {
		if (!editorRef.current) {
			return;
		}

		const view = editorRef.current;

		view.dispatch({
			changes: {
				from: 0,
				to: view.state.doc.length,
				insert: value
			}
		});
	}, [ editorRef, valueRev ]);

	return (
		<div
			className={classList.toClassName()}
			id={id}
			ref={divRef}
		/>
	);
};

export const HAEComponentCodeMirror: THAEComponentDefinition<
	typeof HAEComponentCodeMirror_Definition
> = {
	...HAEComponentCodeMirror_Definition,
	reactComponent: HAEComponentCodeMirror_React
};
