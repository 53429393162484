/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorArrayHandler = IBlueprintSchemaValidatorHandler<unknown[]>;
type TValidatorArray<TOpts> = IBlueprintSchemaValidator<unknown[], TOpts>;

/**
 * Array validator options
 */
export type IValidatorArrayOpts = {
	/** Required value */
	required?: boolean;
	/** Constant value */
	minItems?: number;
	/** Maximum value */
	maxItems?: number;
};

const VALIDATOR_NAME = "ValidatorArray";

/**
 * Array items validator
 */
export const ValidatorArray: TValidatorArray<IValidatorArrayOpts> = (
	opts: IValidatorArrayOpts
): TValidatorArrayHandler => {
	if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
	}

	if (opts.minItems !== undefined && opts.minItems !== null && typeof opts.minItems !== "number") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `minItems` to be a number");
	}

	if (opts.maxItems !== undefined && opts.maxItems !== null && typeof opts.maxItems !== "number") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `maxItems` to be a number");
	}

	return {
		validate: (value: unknown[]): IBlueprintSchemaValidationError[] => {
			const errors = [];

			if (
				(opts.required === true && !Array.isArray(value)) ||
				(!opts.required && !(value === null || value === undefined) && !Array.isArray(value))
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
					message: "Should be an Array"
				});
			}

			if (
				opts.minItems !== undefined &&
				opts.minItems !== null &&
				Array.isArray(value) &&
				value.length < opts.minItems
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Shouldn't have fever than ${opts.minItems} items`,
						metaData: {}
					}
				];
			}

			if (
				opts.maxItems !== undefined &&
				opts.maxItems !== null &&
				Array.isArray(value) &&
				value.length > opts.maxItems
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Shouldn't have more than ${opts.maxItems} items`,
						metaData: {}
					}
				];
			}

			return errors;
		},

		compile: (): string => {
			const parts = [];

			if (opts.required === true) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(!Array.isArray(value)){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be an Array" }); }`
				);
			}

			if (!opts.required) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(!(value === null || value === undefined) && !Array.isArray(value)){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be an Array" }); }`
				);
			}

			if (opts.minItems !== undefined && opts.minItems !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(Array.isArray(value) && value.length < ${opts.minItems}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Shouldn't have fever than ${opts.minItems} items" }); }`
				);
			}

			if (opts.maxItems !== undefined && opts.maxItems !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(Array.isArray(value) && value.length > ${opts.maxItems}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Shouldn't have more than ${opts.maxItems} items" }); }`
				);
			}

			// Minification
			const code = parts
				.join(" ")
				.replace(/{ /g, "{")
				.replace(/ }/g, "}")
				.replace(/: "/g, ':"')
				.replace(/ ,/g, ",")
				.replace(/ < /g, "<")
				.replace(/ > /g, ">")
				.replace(/ === /g, "===")
				.replace(/ && /g, "&&")
				.replace(/Array\.isArray/g, "i")
				.replace(/value/g, "v")
				.replace(/errors.push/g, "e.push");

			return `const e=[];const i=Array.isArray;${code} return e;`;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		export: (): any => {
			return exportValidator(VALIDATOR_NAME, [ opts ]);
		}
	};
};
