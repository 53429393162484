/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ISchemaComponentList,
	ISchemaComponentListSpec,
	ISchemaComponentModel,
	TGetBlueprintSchemaModel,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { isValidObject, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import React, { useCallback, useContext, useState, DragEvent, useEffect, useRef } from "react";
import { HAEComponentChildContext } from "../HAEComponent/HAEComponentContext";
import {
	IHAEComponentListElementPlaceholder,
	THAEComponentListElement
} from "../HAEComponent/HAEComponentList";
import { EditContext } from "./EditContext";
import { DND_DROP_TYPE, IDnDSerializedComponentMetaData, TCanDropFunction } from "./EditDnD";
import { EDIT_SELECTION_ITEM_TYPE } from "./EditSelection";

export enum DROP_ZONE_MODE {
	VERTICAL = "vertical",
	HORIZONTAL = "horizontal",
	APPEND = "append",
	SINGLE = "single" // Replaces all elements with a new one - accepts only one element
}

export interface IUseComponentListDnDParams<
	TInheritedProps extends TSchemaConstObjectProps,
	TPlaceholderMetaData = never
> {
	components: ISchemaComponentListSpec<TInheritedProps>;
	//componentMode: COMPONENT_MODE;
	listElementRef: React.MutableRefObject<HTMLElement>;
	dropZoneMode: DROP_ZONE_MODE;
	modelNode?: TGetBlueprintSchemaModel<ISchemaComponentList<TInheritedProps>>;
	modifyModelOnDrop?: (
		itemModel: ISchemaComponentModel,
		element: IHAEComponentListElementPlaceholder<TInheritedProps, TPlaceholderMetaData>
	) => void;
	canDrop?: TCanDropFunction;
}

let debugEl: HTMLDivElement;
const DISPLAY_DEBUG_HINTS = false;

/**
 * Display small white box at left bottom corner
 *
 * @param content Content
 */
function showDebugHint(content: string) {
	if (!DISPLAY_DEBUG_HINTS) {
		return;
	}

	if (!debugEl) {
		debugEl = document.createElement("div");
		document.body.appendChild(debugEl);
		debugEl.style.position = "absolute";
		debugEl.style.display = "inline-block";
		debugEl.style.left = "0";
		debugEl.style.bottom = "0";
		debugEl.style.background = "#ffffff";
		debugEl.style.color = "#000000";
	}

	debugEl.innerHTML = content;
}

/**
 * Set placeholders drag over callback
 */
function setPlaceholdersDragOverCallback<TInheritedProps extends TSchemaConstObjectProps>(
	pList: IHAEComponentListElementPlaceholder<TInheritedProps, never>[],
	metaData: IDnDSerializedComponentMetaData,
	listElementRect: DOMRect,
	clientX: number,
	clientY: number
): IHAEComponentListElementPlaceholder<TInheritedProps, never>[] {
	pList.forEach((item, index) => {
		const elementOffset = metaData.items[item.srcIndexRef].offset;

		pList[index].offset = {
			x: clientX - listElementRect.x - elementOffset.x,
			y: clientY - listElementRect.y - elementOffset.y,
			parentx: clientX - listElementRect.x - elementOffset.parentx,
			parenty: clientY - listElementRect.y - elementOffset.parenty
		};
	});

	return pList;
}

/**
 * Hook that provides editing info and functions for HAE components
 *
 * @param cmpInstance Component instance
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useComponentListDnD = <
	TInheritedProps extends TSchemaConstObjectProps,
	TPlaceholderMetaData = never
>({
	components,
	listElementRef,
	dropZoneMode,
	modelNode,
	modifyModelOnDrop,
	canDrop
}: IUseComponentListDnDParams<TInheritedProps, TPlaceholderMetaData>) => {
	const editCtx = useContext(EditContext);
	const childCtx = useContext(HAEComponentChildContext);

	const [ placeholders, setPlaceholders ] = useState<
		IHAEComponentListElementPlaceholder<TInheritedProps>[]
	>([]);

	const [ dropState, setDropState ] = useState<{
		index: number;
	}>({
		index: -1
	});

	const lastOffset = useRef({
		x: 0,
		y: 0
	});

	const fixLastOffset = useCallback(
		(event: DragEvent<HTMLElement>, elementRect?: DOMRect) => {
			if (!listElementRef.current) {
				return;
			}

			const listElementRect = isValidObject(elementRect)
				? elementRect
				: listElementRef.current.getBoundingClientRect();

			lastOffset.current.x = event.clientX - listElementRect.x;
			lastOffset.current.y = event.clientY - listElementRect.y;
		},
		[ listElementRef.current ]
	);

	const isDropTarget = useRef(false);

	const internalCanDrop: TCanDropFunction = (metaData, dropTargetRef) => {
		if (!modelNode || childCtx.dndState.active) {
			return false;
		}

		if (dropZoneMode === DROP_ZONE_MODE.SINGLE && metaData.items.length > 1) {
			return false;
		}

		// @todo Check allowed components - need to implement method to a component list schema

		// Call parent function if present
		if (canDrop) {
			return canDrop(metaData, dropTargetRef);
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (!editCtx || !modelNode) {
			return;
		}

		const handleDnDStateChange = () => {
			const isCurrentDropTarget = editCtx.componentDnD.isDropTarget(modelNode);
			const shouldUpdate = editCtx.componentDnD.shouldUpdateElements();

			if (isCurrentDropTarget && !isDropTarget.current) {
				isDropTarget.current = true;

				const pList: IHAEComponentListElementPlaceholder<TInheritedProps>[] = [];
				const metaData = editCtx.componentDnD.getMetaData();

				if (metaData) {
					for (let i = 0; i < metaData.items.length; i++) {
						const elementOffset = metaData.items[i].offset;

						pList.push({
							type: "placeholder",
							dimensions: metaData.items[i].dimensions,
							inheritedProps: metaData.items[i].inheritedpropsspec,
							nodeId: metaData.items[i].nodeid,
							offset: {
								x: lastOffset.current.x - elementOffset.x,
								y: lastOffset.current.y - elementOffset.y,
								parentx: lastOffset.current.x - elementOffset.parentx,
								parenty: lastOffset.current.y - elementOffset.parenty
							},
							srcIndexRef: i
						});
					}

					if (dropZoneMode === DROP_ZONE_MODE.VERTICAL) {
						pList.sort((a, b) =>
							metaData.items[a.srcIndexRef].offset.y > metaData.items[b.srcIndexRef].offset.y
								? -1
								: metaData.items[a.srcIndexRef].offset.y ===
								  metaData.items[b.srcIndexRef].offset.y
								? 0
								: 1
						);
					} else if (dropZoneMode === DROP_ZONE_MODE.HORIZONTAL) {
						pList.sort((a, b) =>
							metaData.items[a.srcIndexRef].offset.x > metaData.items[b.srcIndexRef].offset.x
								? -1
								: metaData.items[a.srcIndexRef].offset.x ===
								  metaData.items[b.srcIndexRef].offset.x
								? 0
								: 1
						);
					}
				}

				setPlaceholders(pList);
				setDropState({
					index: -1
				});
			} else if ((!isCurrentDropTarget && isDropTarget.current) || shouldUpdate) {
				isDropTarget.current = false;

				setPlaceholders([]);
				setDropState({
					index: -1
				});
			}
		};

		onEvent(editCtx.componentDnD.onStateChange, handleDnDStateChange);

		return () => {
			offEvent(editCtx.componentDnD.onStateChange, handleDnDStateChange);
		};
	}, [ modelNode ]);

	// Drag over handler

	const handleDragOver = useCallback(
		(ev: DragEvent<HTMLElement>) => {
			if (!listElementRef.current || !editCtx) {
				return;
			}

			if (editCtx.componentDnD.over(ev.nativeEvent.dataTransfer, modelNode, internalCanDrop)) {
				ev.stopPropagation();
				ev.preventDefault();

				const metaData = editCtx.componentDnD.getMetaData();
				const listElementRect = listElementRef.current.getBoundingClientRect();

				fixLastOffset(ev, listElementRect);

				setPlaceholders((pList) =>
					setPlaceholdersDragOverCallback<TInheritedProps>(
						pList,
						metaData,
						listElementRect,
						ev.clientX,
						ev.clientY
					)
				);

				setDropState((prevState) => ({
					index: prevState.index
				}));

				showDebugHint(`CNT: ${ev.clientX - listElementRect.x},${ev.clientY - listElementRect.y}`);
			} else {
				showDebugHint("CNT: Drop not allowed.");
			}
		},
		[ listElementRef.current, modelNode ]
	);

	// Item drag over handler

	const itemDragOverTargetRef = useRef<EventTarget>();

	const handleItemDragOver = useCallback(
		(
			element: THAEComponentListElement<TInheritedProps>,
			ev: DragEvent<HTMLElement>,
			elRef: React.MutableRefObject<HTMLElement>
		) => {
			if (!listElementRef.current || !editCtx) {
				return;
			}

			if (ev.target === itemDragOverTargetRef.current) {
				ev.stopPropagation();
				ev.preventDefault();

				fixLastOffset(ev);

				editCtx.componentDnD.fixLastDragOver();

				return;
			}

			if (editCtx.componentDnD.over(ev.nativeEvent.dataTransfer, modelNode, internalCanDrop)) {
				ev.stopPropagation();
				ev.preventDefault();

				itemDragOverTargetRef.current = ev.target;

				const metaData = editCtx.componentDnD.getMetaData();
				const listElementRect = listElementRef.current.getBoundingClientRect();

				fixLastOffset(ev, listElementRect);

				let newDropIndex = null;

				if (element.type === "component") {
					if (
						metaData.items.filter((item) => item.nodeid === element.cmpInstance.modelNode?.nodeId)
							.length > 0
					) {
						newDropIndex = element.dropIndex;
					} else {
						const elRect = elRef.current.getBoundingClientRect();
						const offsetX = ev.clientX - elRect.x;
						const offsetY = ev.clientY - elRect.y;

						switch (dropZoneMode) {
							case DROP_ZONE_MODE.VERTICAL:
								newDropIndex =
									offsetY < elRect.height / 2 ? element.dropIndex : element.dropIndex + 1;
								break;
							case DROP_ZONE_MODE.HORIZONTAL:
								newDropIndex =
									offsetX < elRect.width / 2 ? element.dropIndex : element.dropIndex + 1;
								break;
							default: {
								newDropIndex = element.dropIndex;
								break;
							}
						}
					}
				}

				setPlaceholders((pList) =>
					setPlaceholdersDragOverCallback<TInheritedProps>(
						pList,
						metaData,
						listElementRect,
						ev.clientX,
						ev.clientY
					)
				);

				setDropState((prevState) => ({
					index: newDropIndex !== null ? newDropIndex : prevState.index
				}));

				showDebugHint(`EL: X:${ev.clientX - listElementRect.x} Y:${ev.clientY - listElementRect.y}`);
			} else {
				itemDragOverTargetRef.current = null;

				showDebugHint("EL: Drop not allowed.");
			}

			window.dispatchEvent(new CustomEvent("hae_editor_component_update"));
		},
		[ listElementRef.current, modelNode ]
	);

	// Drop handler

	const handleDrop = useCallback(
		(ev: DragEvent<HTMLElement>) => {
			ev.stopPropagation();

			if (!isDropTarget.current || !modelNode || !editCtx) {
				return;
			}

			if (!modelNode) {
				console.error("Missing component list model node.");
				ev.preventDefault();
				return false;
			}

			const targetDropIndex = dropState.index >= 0 ? dropState.index : components.length;

			showDebugHint(`Drop: Index ${targetDropIndex}`);

			const droppedCmpModels = editCtx.componentDnD.drop(
				ev.nativeEvent.dataTransfer,
				modelNode,
				editCtx.dCtx,
				targetDropIndex,
				placeholders,
				dropZoneMode === DROP_ZONE_MODE.SINGLE ? DND_DROP_TYPE.REPLACE : DND_DROP_TYPE.INSERT,
				modifyModelOnDrop
			);

			// Select dropped components
			if (droppedCmpModels) {
				editCtx.selection.setItems(
					droppedCmpModels.map((item) => ({
						type: EDIT_SELECTION_ITEM_TYPE.COMPONENT,
						nodeId: item.nodeId,
						cmpInstance: null,
						getCoords: null
					}))
				);
			}
		},
		[ modelNode, dropState ]
	);

	useEffect(() => {
		if (!editCtx) {
			return;
		}

		itemDragOverTargetRef.current = null;

		editCtx.componentDnD.finishDrop(modelNode);
	}, [ components, modelNode ]);

	// Create elements array
	const elements: THAEComponentListElement<TInheritedProps, TPlaceholderMetaData>[] = [];
	let phPlaced = false;
	let cmpDropIndex = 0;

	for (let i = 0; i < components.length; i++) {
		if (!phPlaced && dropState.index === cmpDropIndex) {
			for (let j = 0; j < placeholders.length; j++) {
				elements.push(placeholders[j]);
			}

			phPlaced = true;
		}

		// We want to render component only when not being dragged (is replaced with placeholder).
		// But also when the model node is not destroyed because model can be destryoed but not re-rendered yet.
		if (
			!components[i].customData?.isBeingDragged &&
			!(components[i].modelNode && components[i].modelNode.destroyed) &&
			(!isDropTarget.current || (isDropTarget.current && dropZoneMode !== DROP_ZONE_MODE.SINGLE))
		) {
			elements.push({
				type: "component",
				cmpInstance: components[i],
				srcIndex: i,
				dropIndex: cmpDropIndex
			});

			cmpDropIndex++;
		}
	}

	if (!phPlaced) {
		for (let j = 0; j < placeholders.length; j++) {
			elements.push(placeholders[j]);
		}
	}

	return {
		handleDragOver,
		handleItemDragOver,
		handleDrop,
		elements
	};
};
