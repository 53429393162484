/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	TAllNodesSpec,
	TBlueprintActionNodeTypes,
	TBlueprintTransformNodeOptsSchemaSpec
} from "../../blueprints";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";

export async function transformNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintTransformNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { code } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["transform"]["opts"]>;
	const { executor, memoryLimit, timeout, id } = aCtx;
	const { localScope, nodeId } = nCtx;
	try {
		const result = await executor.execute(id, nodeId, code, localScope.globalData, {
			memoryLimit,
			timeout
		});

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: result,
				typeDescriptor: result?.typeDescriptor || Type.Any({})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return createErrorNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					name: error?.error || error?.name || ERROR_NAMES.TRANSFORM_NODE,
					code: ERROR_CODES.TRANSFORM_NODE,
					message: error?.message || "Unknown transform error.",
					detail: error?.userMessage || null
				},
				typeDescriptor: Type.Object({
					props: {
						message: Type.String({}),
						name: Type.String({}),
						detail: Type.Any({})
					}
				}),
				error
			},
			aCtx,
			nCtx
		);
	}
}
