/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IItemRegistry, ItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";
import { IRegistriesRegistry } from "./IRegistryRegistry";

interface RegistryManagerItemProps<T extends ItemRegistryItemProps> extends ItemRegistryItemProps {
	registry: IItemRegistry<T>;
}

export class RegistriesRegistry implements IRegistriesRegistry {
	/** Registry registry */
	protected registry: IItemRegistry<RegistryManagerItemProps<ItemRegistryItemProps>> = new ItemRegistry<
		RegistryManagerItemProps<ItemRegistryItemProps>
	>();

	/**
	 * Registers resolver
	 *
	 * @param name Registry's name
	 * @param resolver Registry
	 */
	public register<T extends ItemRegistryItemProps>(name: string, registry: IItemRegistry<T>): void {
		this.registry.register({ name, registry });
	}

	/**
	 * Removes resolver
	 *
	 * @param name Registry's name
	 */
	public remove(name: string): void {
		this.registry.unregister(name);
	}

	/**
	 * Returns resolver by name
	 *
	 * @param name Registry's name
	 */
	public get<T extends IItemRegistry<ItemRegistryItemProps>>(name: string): T {
		const props = this.registry.get(name);
		return props ? (props.registry as unknown as T) : null;
	}

	/**
	 * Returns all registered resolvers
	 */
	public getAll(): IItemRegistry<ItemRegistryItemProps>[] {
		return this.registry.getItemList().map((props) => props.registry);
	}

	/**
	 * Flush all resolvers
	 */
	public flush(): void {
		this.registry.flush();
	}
}
