/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export class TranslateResolver {
	// @todo change when Translator class will be implemented
	public translate(t: string): string {
		return t;
	}
}
