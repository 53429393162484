/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	DesignContext,
	ICompileError,
	IDocumentError,
	IRuntimeError,
	RuntimeContext,
	TBlueprintIDTNode,
	TGenericBlueprintSchema,
	TGenericModelNode
} from "@hexio_io/hae-lib-blueprint";
import { TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IResourceDescription } from "./IResource";
import { IApplicationManifest } from "./IManifest";
import { TDefaultResourceType } from "./IResourceType";
import { IResourceAdapter } from "./IResourceAdapter";
import { IErrorReport } from "../errors";
import { DOC_TYPES } from "../blueprints";
import { IResourceProps } from "../registries";
import { IService } from "../services";
import { IAppServerErrorReport } from "../app";
import { IBlueprintContentMetadata, IBlueprintMetadata, TEditorApiBlueprintHistory } from "../api";
import { TResourceLockMetadata } from "./IResourceLock";

export type TResourceManagerLockMetadata = {
	fullName?: string;
	photo?: string;
	lockedAt?: Date;
	expiresIn?: number;
} & TResourceLockMetadata;

/**
 * Errors reported for a given document
 */
export interface IResourceErrorReport extends IErrorReport {
	uri: string;
	id: string;
	parseErrors: IDocumentError[];
	compileErrors: ICompileError[];
	runtimeErrors: IRuntimeError[];
}

/**
 * Blueprint document
 */
export interface IBlueprintDocument {
	doctype: DOC_TYPES;
	uri: string;
	ast: unknown;
	idt: TBlueprintIDTNode;
}

export enum RESOURCE_ON_EVENT_TYPE {
	/** After resource was created */
	CREATE = "create",
	/** After resource was renamed */
	RENAME = "rename",
	/** After resource was updated */
	UPDATE = "update",
	/** After resource was removed */
	DELETE = "delete",
	/** After resource was loaded */
	LOAD = "load",
	/** After resource was reloaded */
	RELOAD = "reload",
	/** After all resources were loaded */
	ALL_LOADED = "allLoaded"
}

export interface IResourceOnEventData {
	type: RESOURCE_ON_EVENT_TYPE;
	resource?: IResourceProps;
}

export interface IResourceManagerResourcesStats {
	limits: {
		assetsSizeMb: number;
		blueprintIntegrationsLimit: number;
		blueprintViewsLimit: number;
	};
	amount: { [K: string]: number };
}

export interface IAssetParams {
	basePath: string;
	name: string;
	isPublic: boolean;
	metadata: {
		assetFilename: string;
		assetSize: number;
		assetMimetype: string;
	};
}

/** Resource Manager */
export interface IResourceManager extends IService {
	adapter: IResourceAdapter;

	onResource: TSimpleEventEmitter<IResourceOnEventData>;

	registerResourceType(resourceType: TDefaultResourceType): void;
	loadManifest(): Promise<void>;
	getManifest(): IApplicationManifest;

	reloadAllResources(): Promise<void>;
	reloadResourceByUri(uri: string): Promise<IResourceProps>;
	reloadResourceById(id: string): Promise<IResourceProps>;
	reloadResourceBySecretKey(key: string): Promise<IResourceProps>;
	reloadDependencies(uriList: string[]): Promise<void>;
	loadResourceFromString(content: string, name: string): Promise<IResourceProps>;

	createDCtx(scan: boolean): DesignContext;
	createRCtx<TModel extends TGenericModelNode>(modelNode: TModel): RuntimeContext<TModel["schema"]>;
	createCCtx(): CompileContext;

	parseModel<TResource extends IResourceProps>(
		resource: TResource,
		dCtx: DesignContext,
		schema: TGenericBlueprintSchema
	): Promise<TResource>;

	renderSpec<TResource extends IResourceProps>(resource: TResource): Promise<TResource>;

	throwError(message: IAppServerErrorReport["message"], details?: IAppServerErrorReport["details"]): void;

	reportError(message: IAppServerErrorReport["message"], details?: IAppServerErrorReport["details"]): void;

	getResourceById(id: string): IResourceProps;
	getResourcesByProperties(conditions: { [K: string]: unknown }): IResourceProps[];
	getResourceListByType(docType: string): IResourceProps[];
	getResources(): IResourceProps[];
	hasResourceType(name: string): boolean;
	getResourceType(name: string): TDefaultResourceType;

	resToDesc<T extends IResourceProps>(resource: T): IResourceDescription;
	getBlueprintMeta<T extends IResourceProps>(resource: T): Promise<IBlueprintMetadata>;
	getResourceErrors(): { [K: string]: IResourceErrorReport };

	/**
	 * Synchronize repository
	 *
	 * @param overwrite Overwrite local changes
	 */
	syncRepository(params: { overwrite?: boolean; author?: string }): Promise<void>;

	/**
	 * Locks resource
	 *
	 * @param params Params
	 */
	lockBlueprint(params: {
		path: string;
		userId: string;
		metadata?: TResourceManagerLockMetadata;
	}): Promise<void>;

	/**
	 * Unlocks resource
	 *
	 * @param params Params
	 */
	unlockBlueprint(params: { path: string; userId: string }): Promise<void>;

	/**
	 * Touch resource
	 *
	 * @param params Params
	 */
	touchBlueprint(params: { path: string; userId: string }): Promise<void>;

	/**
	 * Creates directory
	 *
	 * @param params
	 */
	createDir(params: { dirName: string; author?: string }): Promise<void>;

	/**
	 * Removes directory
	 *
	 * @param params
	 */
	removeDir(params: { dirName: string; author?: string }): Promise<void>;

	/**
	 * Renames directory
	 *
	 * @param params
	 */
	renameDir(params: { dirName: string; newName: string; basePath: string; author?: string }): Promise<void>;

	/**
	 * Creates blueprint
	 *
	 * @param params Params
	 */
	createBlueprint(params: {
		content?: string;
		basePath: string;
		name: string;
		author: string;
	}): Promise<IBlueprintMetadata>;

	/** Creates Asset's blueprint */
	createAssetBlueprint(params: {
		content?: string;
		basePath: string;
		name: string;
		author: string;
	}): Promise<IBlueprintMetadata>;

	/**
	 * Updates blueprint content
	 *
	 * @param params Params
	 */
	updateBlueprintContent(params: {
		path: string;
		content: string;
		userId: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IResourceProps>;

	updateManifestContent(params: {
		content: string;
		userId: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IResourceProps>;

	/**
	 * Renames blueprint
	 *
	 * @param params Params
	 */
	renameBlueprint(params: {
		path: string;
		basePath: string;
		name: string;
		author?: string;
		userId?: string;
	}): Promise<IResourceProps>;

	/**
	 * Removes blueprint
	 *
	 * @param params Params
	 */
	removeBlueprint(params: { path: string; userId: string; author?: string }): Promise<void>;

	/**
	 * Returns blueprint content
	 *
	 * @param params Params
	 */
	getBlueprintContent(params: { path: string }): Promise<string>;

	getManifestContentMetadata(): Promise<IBlueprintContentMetadata>;

	getResource(params: { path: string }): Promise<IResourceProps>;

	/**
	 * Returns blueprint history
	 *
	 * @param path Blueprint path
	 */
	getBlueprintHistory(params: { path: string }): Promise<TEditorApiBlueprintHistory>;

	/**
	 * Returns blueprint's revision
	 *
	 * @param param Params
	 */
	getBlueprintRevision(params: { commitSha: string; path: string }): Promise<string>;

	makeRelease(params: { tag?: string; releaseNotes?: string }): Promise<string>;

	setProfileId(profileId: string): void;
	getResourceUriFromPath(name: string, basePath?: string): string;

	getStats(): IResourceManagerResourcesStats;
}
