/**
 * Label Position schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	ISchemaConstString,
	ISchemaOneOf,
	ISchemaValue,
	ISchemaVoid
} from "@hexio_io/hae-lib-blueprint";
import { LABEL_POSITION_default } from "../Enums/LABEL_POSITION";
import { dimensionPattern } from "../RegExp/dimensionPattern";
import { common } from "../terms/editor/schemas/common";

/**
 * Label Position schema
 */
export function LabelPositionSchema(): ISchemaOneOf<{
	TOP: {
		value: ISchemaVoid;
		label: string;
	};
	LEFT: {
		value: ISchemaValue<
			ISchemaConstObject<{
				width: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstString>>;
			}>
		>;
		label: string;
	};
}> {
	return BP.OneOf({
		...common.labelPosition,
		typeValueOpts: {
			...common.labelPosition
		},
		defaultType: LABEL_POSITION_default,
		types: {
			TOP: {
				...common.labelPositionValues.top,
				value: BP.Void({})
			},
			LEFT: {
				...common.labelPositionValues.left,
				value: BP.Object({
					props: {
						width: BP.Prop(
							BP.String({
								...common.labelWidth,
								default: "200px",
								fallbackValue: "200px",
								constraints: {
									...common.labelWidthConstraints,
									pattern: dimensionPattern
								}
							})
						)
					},
					editorOptions: {
						layoutType: "passthrough"
					}
				})
			}
		},
		editorOptions: {
			layoutType: "noHeader"
		}
	});
}
