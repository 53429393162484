/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export const BlueprintLogoutNode = {
	name: NODE_TYPES.LOGOUT,
	label: "Logout",
	icon: "mdi/logout",
	opts: BP.Const.Object({
		props: {},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS
	})
};

export type TBlueprintLogoutNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintLogoutNode["opts"]>;
