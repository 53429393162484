/**
 * Table HAE component sort
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { SORT_ORDER, SORT_ORDER_default } from "@hexio_io/hae-lib-components";

/**
 * Sorts items
 *
 * @param items Items
 * @param key Sort key or function, leave undefined to sort item itself
 * @param order Sort order
 */
export function sortItems<TItemType>(
	items: TItemType[],
	key?: string | ((item: TItemType) => unknown),
	order: SORT_ORDER = SORT_ORDER[SORT_ORDER_default]
): TItemType[] {
	const orderAsc = order === SORT_ORDER.ASC;

	const keyString = typeof key === "string" ? (key as string) : null;
	const keyFunction = typeof key === "function" ? key : null;

	return items.sort((itemA, itemB) => {
		let valueA =
			typeof keyString === "string"
				? itemA[keyString]
				: typeof keyFunction === "function"
				? keyFunction(itemA)
				: itemA;

		let valueB =
			typeof keyString === "string"
				? itemB[keyString]
				: typeof keyFunction === "function"
				? keyFunction(itemB)
				: itemB;

		if (typeof valueA === "boolean" && typeof valueB === "boolean") {
			if ((orderAsc && valueA && !valueB) || (!orderAsc && !valueA && valueB)) {
				return -1;
			}

			if ((orderAsc && !valueA && valueB) || (!orderAsc && valueA && !valueB)) {
				return 1;
			}

			return 0;
		}

		if (typeof valueA === "string" && typeof valueB === "string") {
			valueA = valueA.toUpperCase();
			valueB = valueB.toUpperCase();

			return orderAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
		}

		if ((orderAsc && valueA < valueB) || (!orderAsc && valueA > valueB)) {
			return -1;
		}

		if ((orderAsc && valueA > valueB) || (!orderAsc && valueA < valueB)) {
			return 1;
		}

		return 0;
	});
}
