/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	cmpDataEqual
} from "@hexio_io/hae-lib-blueprint";

interface DataSourceContextProvider_State {
	name: string;
	lastValue: unknown;
	wasInitialized: boolean;
}

const DataSourceContextProvider_Opts = {
	name: BP.Prop(
		BP.String({
			label: "Name",
			description: "Context Name"
		})
	),
	value: BP.Prop(
		BP.Any({
			label: "Value",
			description: "Value provided for the context that will be available to all consumers.",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		})
	)
};

const DataSourceContextProvider_Events = {};

/**
 * ContextProvider data source
 */
export const DataSourceContextProvider = defineElementaryDataSource<
	typeof DataSourceContextProvider_Opts,
	DataSourceContextProvider_State,
	typeof DataSourceContextProvider_Events
>({
	name: "contextProvider",
	label: "Context Provider",
	// eslint-disable-next-line max-len
	description: "Provides a context that can be consumed by other data sources in another views. Can be used to store global data including mmethods.",
	icon: "mdi/database-export",
	opts: DataSourceContextProvider_Opts,
	events: DataSourceContextProvider_Events,
	resolve: (opts, prevState, _updateStateAsync, _dsInstance, rCtx) => {
		if (prevState?.wasInitialized && prevState.name !== opts.name) {
			rCtx.releaseDataContext(prevState.name);
		}

		if (!cmpDataEqual(opts.value, prevState?.lastValue) || !prevState?.wasInitialized || prevState.name !== opts.name) {
			rCtx.setDataContext(opts.name, opts.value, Type.Any({}));
		}

		return {
			name: opts.name,
			lastValue: opts.value,
			wasInitialized: true
		};
	},
	getScopeData: (opts) => {
		return opts.value;
	},
	getScopeType: () => {
		return Type.Any({});
	},
	destroy: (_opts, state, rCtx) => {
		if (state?.name) {
			rCtx.releaseDataContext(state.name);
		}
	}
});
