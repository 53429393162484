/**
 * Checkbox Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, COMPONENT_MODE, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	ENUM_DEFAULT_VALUE,
	getStringEnumValue,
	Icon,
	ICON_NAME,
	Label,
	propGroups,
	TextProps,
	TextSchema,
	TextValueSchema,
	THAEComponentDefinition,
	THAEComponentReact,
	termsEditor as HAELibComponentsTerms,
	dimensionPattern,
	useStyleSheetRegistry,
	StyleSheet,
	Text,
	BackgroundColorSchema,
	ForegroundColorSchema,
	dimensionInPixelsPattern,
	addFormItem,
	removeFormItem
} from "@hexio_io/hae-lib-components";

import { isNonEmptyString, isValidValue } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { FieldBaseProps } from "./props";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { getFieldStateProps, useField } from "./useField";
import { IFieldState } from "./state";
import { CHECKBOX_STYLE } from "../../Enums/CHECKBOX_STYLE";
import { HAEComponentField_Events } from "./events";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

interface HAEComponentCheckboxField_State extends IFieldState {
	initialValue: boolean;
	value: boolean;
}

const HAEComponentCheckboxField_Props = {
	...FieldBaseProps,

	value: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.checkboxField.value,
			default: false,
			fallbackValue: null,
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.common
	),

	styleData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.checkboxField.styleData,
			typeValueOpts: {
				...termsEditor.schemas.checkboxField.styleData
			},
			defaultType: ENUM_DEFAULT_VALUE,
			types: {
				DEFAULT: {
					...termsEditor.schemas.checkboxField.styleDataValues.default,
					value: BP.Void({})
				},
				SWITCH: {
					...termsEditor.schemas.checkboxField.styleDataValues.switch,
					value: BP.Object({
						props: {
							truthyState: BP.Prop(
								BP.OptGroup({
									...termsEditor.schemas.checkboxField.truthyState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											text: BP.Prop(
												TextSchema({
													props: {
														value: BP.Prop(
															TextValueSchema({
																default: ""
															}),
															TextProps.value.order,
															TextProps.value.group
														),

														foregroundColor: BP.Prop(
															ForegroundColorSchema({
																alias: "foregroundColor_checkboxFieldTruthyStateText",
																default: "INHERIT"
															}),
															TextProps.foregroundColor.order
														),

														align: BP.Prop(BP.Void({})),

														overflow: BP.Prop(BP.Void({})),

														markdown: BP.Prop(BP.Void({}))
													},
													editorOptions: {
														defaultExpanded: false
													}
												}),
												0
											),

											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_checkboxFieldTruthyState",
													default: "PRIMARY"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_checkboxFieldTruthyState",
													default: "WHITE"
												}),
												20
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								0
							),

							falsyState: BP.Prop(
								BP.OptGroup({
									...termsEditor.schemas.checkboxField.falsyState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											text: BP.Prop(
												TextSchema({
													props: {
														value: BP.Prop(
															TextValueSchema({
																default: ""
															}),
															TextProps.value.order,
															TextProps.value.group
														),

														foregroundColor: BP.Prop(
															ForegroundColorSchema({
																alias: "foregroundColor_checkboxFieldFalsyStateText",
																default: "GRAY"
															}),
															TextProps.foregroundColor.order
														),

														align: BP.Prop(BP.Void({})),

														overflow: BP.Prop(BP.Void({})),

														markdown: BP.Prop(BP.Void({}))
													},
													editorOptions: {
														defaultExpanded: false
													}
												}),
												0
											),

											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_checkboxFieldFalsyState",
													default: "FIELD_BACKGROUND"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_checkboxFieldFalsyState",
													default: "LIGHT_GRAY"
												}),
												20
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								10
							),

							width: BP.Prop(
								BP.String({
									...termsEditor.schemas.checkboxField.width,
									default: "",
									constraints: {
										...HAELibComponentsTerms.schemas.common.dimensionConstraints,
										pattern: dimensionPattern
									}
								}),
								20
							),

							height: BP.Prop(
								BP.String({
									...termsEditor.schemas.checkboxField.height,
									default: "",
									constraints: {
										...HAELibComponentsTerms.schemas.common.dimensionConstraints,
										pattern: dimensionInPixelsPattern
									}
								}),
								30
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		0,
		propGroups.style
	)
};

const HAEComponentCheckboxField_Events = {
	...HAEComponentField_Events
};

const HAEComponentCheckboxField_Definition = defineElementaryComponent<
	typeof HAEComponentCheckboxField_Props,
	HAEComponentCheckboxField_State,
	typeof HAEComponentCheckboxField_Events
>({
	...termsEditor.components.checkboxField.component,

	name: "checkboxField",

	category: "form",

	icon: "mdi/checkbox-marked-outline",

	docUrl: "...",

	order: 40,

	props: HAEComponentCheckboxField_Props,

	events: HAEComponentCheckboxField_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, _rCtx, scope) => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue ?? null;
		const value =
			state?.initialValue === initialValue
				? isValidValue(state?.value)
					? state.value
					: spec.value ?? false
				: spec.value ?? false;

		function setValue(value: boolean) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? false : initialValue }));
		}

		const newState = {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate, false),
			setValue,
			clearValue
		};

		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: newState.value,
			changed: newState.changed || false,
			valid: newState.valid || false,
			clearValue: clearValue
		});

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.schemas.checkboxField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.Boolean({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentCheckboxField_React: THAEComponentReact<typeof HAEComponentCheckboxField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		fieldName,
		readOnly,
		enabled,
		validate,
		required,
		customValidation
	} = props;

	const { value, touched, changed, valid } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const componentPath = componentInstance.safePath;

	const styleValue = getStringEnumValue(CHECKBOX_STYLE, props.styleData.type);
	const styleDataValue = props.styleData.value[props.styleData.type];

	const truthyState = styleDataValue?.truthyState;
	const falsyState = styleDataValue?.falsyState;

	const hasTruthyStateText = isNonEmptyString(truthyState?.text?.value);
	const hasFalsyStateText = isNonEmptyString(falsyState?.text?.value);

	// Classlist and stylesheet

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;

	classList.addModifiers({
		checkbox: true,
		truthy: value,
		falsy: !value,
		style: styleValue,
		validate,
		"with-text": hasTruthyStateText || hasFalsyStateText
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, touched, changed, valid }),
		false
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		const selector = `.${idClassName}`;

		if (truthyState) {
			const truthyStateSelector = `${selector}.cmp-field--truthy .cmp-field__control`;

			result.addColorProperties([
				{
					selector: truthyStateSelector,
					name: "element-background-color",
					value: truthyState.backgroundColor
				},
				{
					selector: truthyStateSelector,
					name: "element-foreground-color",
					value: truthyState.foregroundColor
				}
			]);
		}

		if (falsyState) {
			const falsyStateSelector = `${selector}.cmp-field--falsy .cmp-field__control`;

			result.addColorProperties([
				{
					selector: falsyStateSelector,
					name: "element-background-color",
					value: falsyState.backgroundColor
				},
				{
					selector: falsyStateSelector,
					name: "element-foreground-color",
					value: falsyState.foregroundColor
				}
			]);
		}

		if (styleDataValue?.width) {
			result.addString(selector, `--element-control-custom-width: ${styleDataValue.width} !important;`);
		}

		if (styleDataValue?.height) {
			result.addString(selector, `--element-control-height: ${styleDataValue.height} !important;`);
		}

		return result;
	}, [ idClassName, truthyState, falsyState, styleDataValue ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	const inputProps: React.HTMLProps<HTMLInputElement> = {
		id,
		name: fieldName || id,
		className: "cmp-field__control-input",
		type: "checkbox",
		checked: !!value,
		readOnly: elementReadOnly,
		disabled: !enabled || elementReadOnly,
		required
	};

	const { setValue, setTouched } = useField<boolean>(
		{
			id,
			state,
			readOnly,
			validate,
			customValidation,
			validationDependencies: [ enabled, required ],
			isEmpty: false,
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	// Event handlers

	const _inputBlurHandler = React.useCallback(() => {
		setTouched();
	}, [ setTouched ]);

	const _inputChangeHandler = React.useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const { checked } = event.currentTarget;

			setValue(checked);
		},
		[ setValue ]
	);

	return (
		<label className={classList.toClassName()}>
			<div className="cmp-field__control">
				<input {...inputProps} onBlur={_inputBlurHandler} onChange={_inputChangeHandler} />

				{styleValue === CHECKBOX_STYLE.DEFAULT ? (
					<Icon
						source={ICON_NAME.CHECK}
						size="SMALL"
						componentPath={[ ...componentPath, "icon" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-field__control-icon")}
					/>
				) : styleValue === CHECKBOX_STYLE.SWITCH ? (
					<div className="cmp-field__control-switch">
						{hasTruthyStateText ? (
							<Text
								{...truthyState.text}
								componentPath={[ ...componentPath, "truthy-text" ]}
								componentMode={componentMode}
								classList={new ClassList("cmp-field__control-truthy-text")}
							/>
						) : null}

						{hasFalsyStateText ? (
							<Text
								{...falsyState.text}
								componentPath={[ ...componentPath, "falsy-text" ]}
								componentMode={componentMode}
								classList={new ClassList("cmp-field__control-falsy-text")}
							/>
						) : null}
					</div>
				) : null}
			</div>

			<div className="cmp-field__content">
				<Label
					text={{ ...labelText, tagName: "span" }}
					icon={{ ...labelIcon, size: "SMALL" }}
					tagName="div"
					componentPath={[ ...componentPath, "label" ]}
					componentMode={componentMode}
					classList={new ClassList("cmp-field__label")}
				>
					<FieldLabelInfo required={required} />
				</Label>

				<FieldInfo
					descriptionText={descriptionText}
					componentPath={[ ...componentPath, "info" ]}
					componentMode={componentMode}
				/>
			</div>
		</label>
	);
};

export const HAEComponentCheckboxField: THAEComponentDefinition<typeof HAEComponentCheckboxField_Definition> =
	{
		...HAEComponentCheckboxField_Definition,
		reactComponent: HAEComponentCheckboxField_React
	};
