/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaOpts,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	Prop,
	SchemaConstObject
} from "./const/SchemaConstObject";
import { ISchemaConstArray, SchemaConstArray } from "./const/SchemaConstArray";
import { ISchemaConstString, SchemaConstString } from "./const/SchemaConstString";
import { ISchemaConstDate, SchemaConstDate } from "./const/SchemaConstDate";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import { ISchemaConstEnum, SchemaConstEnum } from "./const/SchemaConstEnum";

export enum SCHEMA_COMMENT_STATUS {
	UNRESOLVED = "unresolved",
	ACCEPTED = "accepted",
	DECLINED = "declined"
}

export type TSchemaComment = ISchemaConstObject<{
	message: ISchemaConstObjectOptsProp<ISchemaConstString>;
	date: ISchemaConstObjectOptsProp<ISchemaConstDate>;
	authorId: ISchemaConstObjectOptsProp<ISchemaConstString>;
	authorName: ISchemaConstObjectOptsProp<ISchemaConstString>;
	status: ISchemaConstObjectOptsProp<ISchemaConstEnum<ISchemaConstString>>;
}>;

export type TSchemaCommentsMessages = ISchemaConstArray<TSchemaComment>;

type TSchemaCommentsInternal = ISchemaConstObject<{
	messages: ISchemaConstObjectOptsProp<TSchemaCommentsMessages>;
}>;

export type TSchemaCommentModel = TGetBlueprintSchemaModel<TSchemaComment>;

/**
 * Schema model
 */
export interface ISchemaCommentsModel extends IModelNode<ISchemaComments> {
	messages: TGetBlueprintSchemaModel<TSchemaCommentsMessages>;
	__internalModel: TGetBlueprintSchemaModel<TSchemaCommentsInternal>;
}

/**
 * Schema spec
 */
export type TSchemaCommentsSpec = TGetBlueprintSchemaSpec<TSchemaCommentsInternal>;

/**
 * Schema defaults
 */
export type TSchemaCommentsDefault = TGetBlueprintSchemaDefault<TSchemaCommentsInternal>;

/**
 * Schema options
 */
export type TSchemaCommentsOpts = Omit<TGetBlueprintSchemaOpts<TSchemaCommentsInternal>, "props">;

/**
 * Schema type
 */
export interface ISchemaComments
	extends IBlueprintSchema<
		TSchemaCommentsOpts,
		ISchemaCommentsModel,
		TSchemaCommentsSpec,
		TSchemaCommentsDefault
	> {
	addMessage: (
		modelNode: ISchemaCommentsModel,
		data: { message: string; date: Date; authorId: string; authorName: string },
		notify?: boolean
	) => void;
	removeMessage: (modelNode: ISchemaCommentsModel, index: number, notify?: boolean) => void;
	acceptMessage: (modelNode: ISchemaCommentsModel, index: number, notify?: boolean) => void;
	declineMessage: (modelNode: ISchemaCommentsModel, index: number, notify?: boolean) => void;
	unresolveMessage: (modelNode: ISchemaCommentsModel, index: number, notify?: boolean) => void;
}

/**
 * Schema: Comments
 *
 * @param opts Schema options
 */
export function SchemaComments(opts: TSchemaCommentsOpts): ISchemaComments {
	type TInternalModel = TGetBlueprintSchemaModel<TSchemaCommentsInternal>;

	const internalSchema = SchemaConstObject({
		...opts,
		props: {
			messages: Prop(
				SchemaConstArray({
					label: "Entries",
					items: SchemaConstObject({
						label: "Comment",
						props: {
							message: Prop(
								SchemaConstString({
									label: "Message",
									constraints: {
										required: true
									}
								}),
								1
							),
							date: Prop(
								SchemaConstDate({
									label: "Date",
									constraints: {
										required: true
									}
								}),
								2
							),
							authorId: Prop(
								SchemaConstString({
									label: "Author ID"
								}),
								3
							),
							authorName: Prop(
								SchemaConstString({
									label: "Author name"
								}),
								3
							),
							status: Prop(
								SchemaConstEnum({
									label: "Status",
									value: SchemaConstString({
										default: SCHEMA_COMMENT_STATUS.UNRESOLVED
									}),
									options: [
										{
											value: SCHEMA_COMMENT_STATUS.UNRESOLVED,
											label: "Unresolved"
										},
										{
											value: SCHEMA_COMMENT_STATUS.ACCEPTED,
											label: "Accepted"
										},
										{
											value: SCHEMA_COMMENT_STATUS.DECLINED,
											label: "Declines"
										}
									]
								}),
								4
							)
						},
						constraints: {
							required: true
						}
					})
				})
			)
		}
	});

	const schema = createEmptySchema<ISchemaComments>("comments", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "__internalModel", "messages" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		internalModel: TInternalModel,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			messages: internalModel.props.messages,
			__internalModel: internalModel
		});

		const model = assignParentToChildrenOf(modelNode);

		model.initRequiredValid = internalModel.initRequiredValid;

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const internalModel = internalSchema.createDefault(dCtx, null, defaultValue) as TInternalModel;

		return createModel(dCtx, internalModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const internalModel = internalSchema.clone(dCtx, modelNode.__internalModel, null) as TInternalModel;

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			messages: internalModel.props.messages,
			__internalModel: internalModel
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		internalSchema.destroy(modelNode.__internalModel);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		const internalModel = internalSchema.parse(dCtx, idtNode, null) as TInternalModel;

		if (!internalModel) {
			return null;
		}

		return createModel(dCtx, internalModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		if (internalSchema.provideCompletion) {
			internalSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}
	};

	schema.serialize = (modelNode, path) => {
		return internalSchema.serialize(modelNode.__internalModel, path);
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		return internalSchema.render(rCtx, modelNode.__internalModel, path, scope, prevSpec);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		return internalSchema.compileRender(cCtx, modelNode.__internalModel, path);
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		return internalSchema.validate(rCtx, path, modelNodeId, value, validateChildren);
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren) => {
		return internalSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaComments", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return internalSchema.getTypeDescriptor(modelNode.__internalModel);
	};

	schema.addMessage = (
		modelNode: ISchemaCommentsModel,
		{
			message,
			date,
			authorId,
			authorName
		}: { message: string; date: Date; authorId: string; authorName: string },
		notify?: boolean
	): void => {
		modelNode.messages.schema.addElement(
			modelNode.messages,
			{
				message: message,
				date: date,
				authorId: authorId,
				authorName: authorName,
				status: SCHEMA_COMMENT_STATUS.UNRESOLVED
			},
			notify
		);
	};

	schema.removeMessage = (modelNode: ISchemaCommentsModel, index: number, notify?: boolean): void => {
		modelNode.messages.schema.removeElement(modelNode.messages, index, notify);
	};

	schema.acceptMessage = (modelNode: ISchemaCommentsModel, index: number, notify?: boolean): void => {
		const item = modelNode.messages.items[index];

		if (!item) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Message at index '${index}' does not exist.`
			);
		}

		item.props.status.schema.setValue(item.props.status, SCHEMA_COMMENT_STATUS.ACCEPTED, notify);
	};

	schema.declineMessage = (modelNode: ISchemaCommentsModel, index: number, notify?: boolean): void => {
		const item = modelNode.messages.items[index];

		if (!item) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Message at index '${index}' does not exist.`
			);
		}

		item.props.status.schema.setValue(item.props.status, SCHEMA_COMMENT_STATUS.DECLINED, notify);
	};

	schema.unresolveMessage = (modelNode: ISchemaCommentsModel, index: number, notify?: boolean): void => {
		const item = modelNode.messages.items[index];

		if (!item) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Message at index '${index}' does not exist.`
			);
		}

		item.props.status.schema.setValue(item.props.status, SCHEMA_COMMENT_STATUS.UNRESOLVED, notify);
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "messages",
				node: modelNode.messages
			}
		];
	};

	return schema;
}
