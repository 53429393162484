/**
 * Container schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const container = {
	content: {
		label: "schema:container.content#label"
	},
	contentItemFlex: {
		label: "schema:container.content#inheritedProps.itemFlex#label",
		description: "schema:container.content#inheritedProps.itemFlex#description"
	},
	contentItemFlexValues: {
		auto: {
			label: "schema:container.content#inheritedProps.itemFlex#values.auto#label"
		},
		none: {
			label: "schema:container.content#inheritedProps.itemFlex#values.none#label"
		},
		stretch: {
			label: "schema:container.content#inheritedProps.itemFlex#values.stretch#label"
		},
		shrink: {
			label: "schema:container.content#inheritedProps.itemFlex#values.shrink#label"
		},
		stretch_shrink: {
			label: "schema:container.content#inheritedProps.itemFlex#values.stretch_shrink#label"
		}
	},
	contentItemFlexConstraints: {
		patternErrorMessage:
			"schema:container.content#inheritedProps.itemFlex#constraints.patternError#message"
	},
	flow: {
		label: "schema:container.flow#label"
	},
	flowValues: {
		column: {
			label: "schema:container.flow#values.column#label"
		},
		row: {
			label: "schema:container.flow#values.row#label"
		}
	},
	size: {
		label: "schema:container.size#label"
	},
	padding: {
		label: "schema:container.padding#label",
		description: "schema:container.padding#description"
	},
	spacing: {
		label: "schema:container.spacing#label",
		description: "schema:container.spacing#description"
	},
	role: {
		label: "schema:container.role#label",
		description: "schema:container.role#description"
	},
	roleValues: {
		none: {
			label: "schema:container.role#values.none#label"
		},
		form: {
			label: "schema:container.role#values.form#label"
		},
		section: {
			label: "schema:container.role#values.section#label"
		},
		article: {
			label: "schema:container.role#values.article#label"
		},
		header: {
			label: "schema:container.role#values.header#label"
		},
		header_banner: {
			label: "schema:container.role#values.header_banner#label"
		},
		footer: {
			label: "schema:container.role#values.footer#label"
		},
		footer_contentinfo: {
			label: "schema:container.role#values.footer_contentinfo#label"
		}
	}
};
