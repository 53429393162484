/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../app";
import { IServiceDefinition } from "../services/IServiceDefinition";
import { createSecretsProviderFactory } from "./createSecretsProviderFactory";
import { ISecretsProvider, ISecretsProviderOptions } from "./ISecretsProvider";

export function createSecretsProviderDefinition<
	TOptions extends ISecretsProviderOptions,
	TClass extends ISecretsProvider
>(
	name: string,
	category: string,
	label: string,
	optionsSchema: TGenericBlueprintSchema,
	entityClass: new (config: TOptions, app: IAppServer) => TClass
): IServiceDefinition<TOptions, TClass> {
	return {
		name,
		category,
		label,
		factory: createSecretsProviderFactory(entityClass),
		optionsSchema
	};
}
