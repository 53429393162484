/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const ACCOUNT = {
	CACHE: {
		EXPIRES_IN_MILLISECONDS: 5 * 60 * 1000,
		MAX_READ_COUNT: 100
	}
};
