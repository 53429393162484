/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	TAllNodesSpec,
	TBlueprintActionNodeOptsSchemaSpec,
	TBlueprintActionNodeTypes
} from "../../blueprints";
import { NODE_RESULT_TYPE, IActionResultError, TActionResult } from "../IActionResult";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";

export async function actionNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintActionNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { actionId, params } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["action"]["opts"]>;
	const { app, appEnvId, appId, appName, config, context } = aCtx;

	try {
		const actionResult = (await app
			.get("actionManager")
			.invoke(actionId, params, context, config, appId, appEnvId, appName)) as TActionResult;

		if (actionResult.status === NODE_RESULT_TYPE.SUCCESS) {
			return createSuccessNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
					data: actionResult.data,
					typeDescriptor: Type.Any({})
				},
				aCtx,
				nCtx
			);
		} else {
			const actionErrorResult = actionResult as IActionResultError;
			return createErrorNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					data: {
						name: "Unknown Nested Action Error.",
						code: ERROR_CODES.ACTION_NODE,
						message: "Can't process nested action invocation response."
					},
					debugDetail: { nestedAction: actionErrorResult.debug },
					typeDescriptor: Type.Any({}),
					nestedActionErrorResult: actionErrorResult
				},
				aCtx,
				nCtx
			);
		}
	} catch (error) {
		return createErrorNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ERROR,
				error,
				data: {
					message: error?.message || "Unknown internal action error.",
					name: ERROR_NAMES.ACTION_NODE,
					code: ERROR_CODES.ACTION_NODE
				},
				typeDescriptor: Type.Object({
					props: {
						message: Type.String({}),
						name: Type.String({})
					}
				})
			},
			aCtx,
			nCtx
		);
	}
}
