/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, INamedConfigResolver } from "@hexio_io/hae-lib-blueprint";

/**
 * Session Storage Types
 */
export enum SESSION_STORAGE_TYPES {
	ADAPPTIO = "adapptio",
	REDIS = "redis"
}

/**
 * Session Storage Adapptio Options schema
 */
export const sessionStorageAdapptioOptionsSchema = BP.Object({
	props: {}
});

/**
 * Session Storage Redis Options schema
 */
export const sessionStorageRedisOptionsSchema = BP.Object({
	props: {}
});

/**
 * Session Storage Options Resolver
 */
export const SessionStorageOptionsResolver: INamedConfigResolver = {
	getOptsSchemaByName: (name: string) => {
		switch (name) {
			case SESSION_STORAGE_TYPES.ADAPPTIO:
				return sessionStorageAdapptioOptionsSchema;
			case SESSION_STORAGE_TYPES.REDIS:
				return sessionStorageRedisOptionsSchema;
			default:
				return null;
		}
	},

	getNameList: () => [
		{
			name: SESSION_STORAGE_TYPES.ADAPPTIO,
			label: "InMemory"
		},
		{
			name: SESSION_STORAGE_TYPES.REDIS,
			label: "Redis"
		}
	]
};
