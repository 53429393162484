/**
 * QR Code schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const qrBarReader = {
	cameraPreference: {
		label: "schema:qrBarReader.cameraPreference#label",
		description: "schema:qrBarReader.cameraPreference#description"
	},
	cameraPreferenceFront: {
		label: "schema:qrBarReader.cameraPreferenceFront#label",
		description: "schema:qrBarReader.cameraPreferenceFront#description"
	},
	cameraPreferenceRear: {
		label: "schema:qrBarReader.cameraPreferenceRear#label",
		description: "schema:qrBarReader.cameraPreferenceRear#description"
	},
	cameraPreferenceFirst: {
		label: "schema:qrBarReader.cameraPreferenceFirst#label",
		description: "schema:qrBarReader.cameraPreferenceFirst#description"
	},
	cameraPreferenceLast: {
		label: "schema:qrBarReader.cameraPreferenceLast#label",
		description: "schema:qrBarReader.cameraPreferenceLast#description"
	},
	cameraPreferenceCustom: {
		label: "schema:qrBarReader.cameraPreferenceCustom#label",
		description: "schema:qrBarReader.cameraPreferenceCustom#description"
	},
	cameraIndex: {
		label: "schema:qrBarReader.cameraIndex#label",
		description: "schema:qrBarReader.cameraIndex#description"
	},
	allowedFormats: {
		label: "schema:qrBarReader.allowedFormats#label",
		description: "schema:qrBarReader.allowedFormats#description"
	},
	enhancedAccuracy: {
		label: "schema:qrBarReader.enhancedAccuracy#label",
		description: "schema:qrBarReader.enhancedAccuracy#description"
	},
	events: {
		scaned: {
			label: "schema:qrBarReader.event.onScanned#label"
		}
	}
};
