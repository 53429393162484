/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ITranslationManager } from "../i18n";

import { authTerms } from "./auth";
import { blueprintsTerms } from "./blueprints";
import { dataSourcesTerms } from "./dataSources";
import { manifestTerms } from "./manifest";
import { uiTerms } from "./ui";
import { endpointsTerms } from "./endpoints";
import { nodesTerms } from "./nodes";
import { runtimeTerms } from "./runtime";

/**
 * Editor terms
 */
export const termsEditor = {
	auth: authTerms,
	blueprints: blueprintsTerms,
	dataSources: dataSourcesTerms,
	manifest: manifestTerms,
	ui: uiTerms,
	endpoint: endpointsTerms,
	nodes: nodesTerms
};

/**
 * Runtime terms
 */
export const termsRuntime = {
	...runtimeTerms
};

/**
 * Registers core translations
 *
 * @param translationManager Translation Manager Instance
 */
export function registerCoreTerms(translationManager: ITranslationManager): void {
	translationManager.registerTerms("editor", termsEditor, "hae-lib-core");
	translationManager.registerTerms("runtime", termsRuntime, "hae-lib-core");
}
