/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Validator declaration
 */
export class ValidatorDeclarationError extends Error {
	public validatorName: string;
	public errorMessage: string;

	public constructor(name: string, message: string) {
		super(`Can't create validator '${name}' : ${message}`);

		this.validatorName = name;
		this.errorMessage = message;
	}
}
