/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const THEME = {
	STATIC_PATH: "themes"
};
