/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	TTypeDesc,
	createSubScope,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	SCHEMA_VALUE_TYPE
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../terms";

interface DataSourceMethod_State {
	invokeHandler: (args: unknown[]) => Promise<unknown>;
}

export const DataSourceMethod_Opts = {
	args: BP.Prop(
		BP.Array({
			...termsEditor.dataSources.method.args,
			items: BP.Object({
				...termsEditor.dataSources.method.argObject,
				props: {
					name: BP.Prop(
						BP.String({
							...termsEditor.dataSources.method.argName,
							constraints: {
								required: true
							},
							default: "arg",
							fallbackValue: "__unknownArg"
						})
					)
				}
			}),
			default: [],
			fallbackValue: [],
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.constant.value,
					icon: "mdi/help-rhombus-outline"
				};
			}
		})
	),
	minRequiredArgs: BP.Prop(
		BP.Integer({
			...termsEditor.dataSources.method.minRequiredArgs,
			default: 0,
			fallbackValue: 0
		})
	),
	restArgsName: BP.Prop(
		BP.String({
			...termsEditor.dataSources.method.restArgsName,
			default: "_restArgs",
			fallbackValue: "_restArgs"
		})
	),
};

export const DataSourceMethod_Events = {
	invoke: {
		...termsEditor.dataSources.method.events.invoke
	}
};

/**
 * Constant data source
 */
export const DataSourceMethod = defineElementaryDataSource<
	typeof DataSourceMethod_Opts,
	DataSourceMethod_State,
	typeof DataSourceMethod_Events
>({
	name: "method",
	label: termsEditor.dataSources.method.root.label,
	description: termsEditor.dataSources.method.root.description,
	icon: "mdi/lightning-bolt",
	opts: DataSourceMethod_Opts,
	events: DataSourceMethod_Events,
	resolve: (_opts, state, _updateStateAsync, dsInstance) => {
		return state ?? {
			invokeHandler: async (...args: unknown[]) => {
				// Definition
				const argsDef = dsInstance.opts.args ?? [];

				// Validate args
				const argsMap: Record<string, unknown> = {};
				const argsType: Record<string, TTypeDesc> = {};

				if (args.length < dsInstance.opts.minRequiredArgs) {
					throw new Error(`Too few arguments. Method requires at least ${dsInstance.opts.minRequiredArgs} arguments`);
				}

				for (let i = 0; i < argsDef.length; i++) {
					const arg = argsDef[i];
					argsMap[arg.name] = args[i] ?? null;
					argsType[arg.name] = args[i] ? Type.Any({}) : Type.Null({});
				}

				if (args.length > argsDef.length) {
					argsMap[dsInstance.opts.restArgsName] = args.slice(argsDef.length);
					argsType[dsInstance.opts.restArgsName] = Type.Any({});
				}

				if (dsInstance.eventEnabled.invoke) {
					return dsInstance.eventTriggers.invoke((parentScope) => {
						return createSubScope(parentScope, argsMap, argsType);
					});
				} else {
					return undefined;
				}
			}
		}
	},
	getScopeData: (_spec, state) => {
		return state.invokeHandler;
	},

	getScopeType: (opts) => {
		return Type.Method({
			...termsEditor.dataSources.method.invokeType,
			argRequiredCount: opts.minRequiredArgs,
			argSchemas: [ BP.Any({ defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING }) ],
			argRestSchema: BP.Any({ defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING }),
			returnType: Type.Any({})
		});
	},
});
