/**
 * Color schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaStringWithConst, ISchemaStringWithConstOpts } from "@hexio_io/hae-lib-blueprint";

import { COLOR } from "../Enums/COLOR";
import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { colorPattern } from "../RegExp/colorPattern";
import { termsEditor } from "../terms";

const schemaBaseOpts = {
	constants: getValuesFromStringEnum(COLOR, termsEditor.schemas.color.values),
	default: "",
	fallbackValue: "",
	editorOptions: {
		controlType: "colorSelector"
	}
};

/**
 * Background Color schema
 *
 * @param opts Options
 */
export function BackgroundColorSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "backgroundColor" }
): ISchemaStringWithConst {
	return BP.StringWithConst({
		...termsEditor.schemas.color.background,
		...schemaBaseOpts,
		...opts,
		example: "RED; #F5F4F3; rgba(255,0,0,0.5)",
		constraints: {
			...termsEditor.schemas.color.constraints,
			pattern: colorPattern,
			...opts.constraints
		}
	});
}

/**
 * Foreground Color schema
 *
 * @param opts Options
 */
export function ForegroundColorSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "foregroundColor" }
): ISchemaStringWithConst {
	return BP.StringWithConst({
		...termsEditor.schemas.color.foreground,
		...schemaBaseOpts,
		...opts,
		example: "RED; #F5F4F3; rgba(255,0,0,0.5)",
		constraints: {
			...termsEditor.schemas.color.constraints,
			pattern: colorPattern,
			...opts.constraints
		}
	});
}

/**
 * Border Color schema
 *
 * @param opts Options
 */
export function BorderColorSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "borderColor" }
): ISchemaStringWithConst {
	return BP.StringWithConst({
		...termsEditor.schemas.color.border,
		...schemaBaseOpts,
		...opts,
		example: "RED; #F5F4F3; rgba(255,0,0,0.5)",
		constraints: {
			...termsEditor.schemas.color.constraints,
			pattern: colorPattern,
			...opts.constraints
		}
	});
}
