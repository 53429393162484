/**
 * Component Helpers
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	COMPONENT_MODE,
	MEDIA_RESOLUTIONS_default,
	MEDIA_RESOLUTIONS_string
} from "@hexio_io/hae-lib-blueprint";
import { IEditContextValue } from "../Editor/EditContext";

/**
 * Returns media modifier
 *
 * @param editContext Edit context
 */
export function getMedia(editContext?: IEditContextValue): MEDIA_RESOLUTIONS_string | null {
	return editContext ? editContext.media || MEDIA_RESOLUTIONS_default : null;
}

/**
 * Checks if user interaction is enabled
 *
 * @param componentMode Component mode
 */
export function isUserInteractionEnabled(componentMode: COMPONENT_MODE): boolean {
	return componentMode === COMPONENT_MODE.NORMAL;
}

/**
 * Simple custom event handling check
 *
 * @param enabled Enabled param
 * @param componentMode Component mode
 */
export function isEventEnabled(enabled: boolean, componentMode: COMPONENT_MODE): boolean {
	return enabled && isUserInteractionEnabled(componentMode);
}
