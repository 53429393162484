/**
 * Custom Chart HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { ThemeProvider, PartialTheme } from '@fluentui/react';

import { createSubScope, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	Container,
	ContainerProps,
	isEventEnabled,
	THAEComponentDefinition,
	THAEComponentReact,
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { BaseEvents } from "../../base/BaseEvents";

interface HAEComponentFluentTheme_State {}

const appTheme: PartialTheme = {
	palette: {
		themePrimary: 'red'
	}
};

/**
 * Fluent Theme props
 */
const HAEComponentFluentTheme_Props = {
	...ContainerProps
};

const HAEComponentFluentTheme_Events = {
	...BaseEvents
};

const HAEComponentFluentTheme_Definition = defineElementaryComponent<
	typeof HAEComponentFluentTheme_Props,
	HAEComponentFluentTheme_State,
	typeof HAEComponentFluentTheme_Events
>({
	...termsEditor.components.fluentUi.component,

	name: "fluentUi",

	category: "fluentUi",

	icon: "mdi/code-braces",

	order: 1000,

	container: true,

	props: HAEComponentFluentTheme_Props,

	events: HAEComponentFluentTheme_Events,

	resolve: (_spec, state) => {
		return state || {};
	},

	getScopeData: (_spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentFluentTheme_React: THAEComponentReact<typeof HAEComponentFluentTheme_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {

	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;
	}, [ componentInstance.componentMode, componentInstance.eventEnabled.click, componentInstance.eventTriggers.click ]);


	return (
		<ThemeProvider theme={appTheme}>
			<Container
				{...props}
				componentPath={componentInstance.safePath}
				componentMode={componentInstance.componentMode}
				classList={reactComponentClassList}
				contentModelNode={componentInstance.modelNode?.props.props.content as unknown as any}
				onClick={_clickHandler}
			/>
		</ThemeProvider>
	);
};

export const HAEComponentFluentTheme: THAEComponentDefinition<typeof HAEComponentFluentTheme_Definition> = {
	...HAEComponentFluentTheme_Definition,
	reactComponent: HAEComponentFluentTheme_React
};
