/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintErrorNode = {
	name: NODE_TYPES.THROW,
	label: "Throw",
	icon: "mdi/alert-circle",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			errorName: BP.Prop(
				BP.String({
					label: termsEditor.nodes.error.errorName.label,
					description: termsEditor.nodes.error.errorName.description
				}),
				0
			),
			message: BP.Prop(
				BP.String({
					label: termsEditor.nodes.error.message.label,
					description: termsEditor.nodes.error.message.description
				}),
				1
			),
			/** This was renamed to `detail`, but here it should be `data` for backward compatibility. */
			data: BP.Prop(
				BP.Any({
					label: termsEditor.nodes.error.data.label,
					description: termsEditor.nodes.error.data.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				}),
				2
			)
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
};

export type TBlueprintErrorNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintErrorNode["opts"]>;
