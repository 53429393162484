/**
 * Field HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";

import {
	IconSchema,
	IconSizeSchema,
	TextFontSizeSchema,
	TextSchema,
	propGroups,
	IconProps,
	TextProps
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";

export const CustomValidationProps = {
	condition: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.customValidationCondition,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		0
	),

	message: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.customValidationMessage,
			default: "",
			constraints: {
				required: true
			}
		}),
		10
	)
};

export const FieldLabelTextProps = {
	align: BP.Prop(BP.Void({})),

	fontSize: BP.Prop(BP.Void({})),

	overflow: BP.Prop(BP.Void({}))
};

/**
 * Field base props
 */
export const FieldBaseProps = {
	labelText: BP.Prop(
		TextSchema({
			...termsEditor.schemas.field.labelText,
			props: {
				...FieldLabelTextProps
			}
		}),
		100,
		propGroups.common
	),

	labelIcon: BP.Prop(
		IconSchema({
			...termsEditor.schemas.field.labelIcon,
			props: {
				size: BP.Prop(BP.Void({}))
			}
		}),
		110,
		propGroups.common
	),

	descriptionText: BP.Prop(
		TextSchema({
			...termsEditor.schemas.field.descriptionText,
			props: {
				fontSize: BP.Prop(
					TextFontSizeSchema({
						default: "XSMALL"
					}),
					TextProps.fontSize.order,
					TextProps.fontSize.group
				),

				overflow: BP.Prop(BP.Void({}))
			},
			editorOptions: {
				defaultExpanded: false
			}
		}),
		120,
		propGroups.common
	),

	/*hidden: BP.Prop(BP.Boolean({
		...terms.schemas.field.hidden
	})),*/

	fieldName: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.name,
			default: null,
			fallbackValue: null,
			constraints: {
				required: false
			}
		}),
		90,
		propGroups.state
	),

	readOnly: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.readOnly,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		100,
		propGroups.state
	),

	enabled: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.enabled,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		110,
		propGroups.state
	),

	validate: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.validate,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		100,
		propGroups.validation
	),

	required: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.required,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		110,
		propGroups.validation
	),

	/*validReadOnlyOrDisabled: BP.Prop(BP.Boolean({
		...termsEditor.schemas.field.validReadOnlyOrDisabled,
		default: true,
		fallbackValue: true,
		constraints: {
			required: true
		}
	}), 120, propGroups.validation),*/

	customValidation: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.field.customValidation,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				props: CustomValidationProps,
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		300,
		propGroups.validation
	)
};

/**
 * Field length props
 */
export const FieldLengthProps = {
	minLength: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.field.minLength,
			constraints: {
				min: 0
			}
		}),
		130,
		propGroups.validation
	),

	maxLength: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.field.maxLength,
			constraints: {
				min: 0
			}
		}),
		140,
		propGroups.validation
	)
};
