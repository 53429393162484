/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppServer } from "../app";
import { IAuthProvider, IAuthProviderOptions } from "./IAuthProvider";
import { IAuthProviderFactory } from "./IAuthProviderFactory";

/**
 * Creates Auth Provider instance
 *
 * @param entity Auth Provider class
 * @returns
 */
export function createAuthProviderFactory<
	TOptions extends IAuthProviderOptions,
	TClass extends IAuthProvider
>(
	entity: new (integrationName: string, config: TOptions, app: IAppServer) => TClass
): IAuthProviderFactory<TClass, TOptions> {
	return {
		factory: (authProviderName: string, config: TOptions, app: IAppServer) => {
			const instance = new entity(authProviderName, config, app);
			return instance;
		}
	};
}
