/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";
import { DateTime } from "luxon";

const formatInputDate = (inputDate) => {
	if (typeof inputDate === "number") {
		return DateTime.fromSeconds(inputDate);
	} else if (typeof inputDate === "string") {
		return DateTime.fromISO(inputDate);
	} else if (inputDate instanceof Date) {
		return DateTime.fromJSDate(inputDate);
	} else if (inputDate instanceof Object) {
		return DateTime.fromObject(inputDate);
	}
	return null;
};

export const dateFormatFunc = declareFunction({
	name: "DATE_FORMAT",
	category: "date",
	label: "Format date",
	/* eslint-disable max-len */
	description: `Formats given date with a custom format.
	
See [Luxon documentation](https://moment.github.io/luxon/docs/manual/formatting.html#formatting-with-tokens--strings-for-cthulhu-) for more information.
	`,
	/* eslint-enable max-len */
	argRequiredCount: 2,
	argSchemas: [
		BP.Any({
			label: "Date",
			constraints: {
				required: true
			},
			fallbackValue: 0,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER
		}),
		BP.String({
			label: "Format",
			constraints: {
				required: true
			},
			fallbackValue: "i"
		})
	],
	argRestSchema: null,
	returnType: Type.String({
		label: "Formatted value"
	}),
	render: (_rCtx, args) => {
		const inputDate = args[0]();
		const format = args[1]();

		const dt: DateTime = formatInputDate(inputDate);

		if (dt) {
			return dt.toFormat(format);
		} else {
			throw new Error("Unsupported input date type.");
		}
	}
});

export const dateParseFunc = declareFunction({
	name: "DATE_PARSE",
	category: "date",
	label: "Parse date",
	description: `Parses date from given string with a custom format. Returns the Date object.
	
See [Luxon documentation](https://moment.github.io/luxon/docs/manual/parsing.html#fromformat) for more information.
	`,
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.String({
			label: "Format",
			constraints: {
				required: true
			},
			fallbackValue: "i"
		})
	],
	argRestSchema: null,
	returnType: Type.String({
		label: "Date object"
	}),
	render: (_rCtx, args) => {
		const value = args[0]();
		const format = args[1]();

		const res = DateTime.fromFormat(value, format);
		return res.toJSDate();
	}
});

export const dateNowFunc = declareFunction({
	name: "DATE_NOW",
	category: "date",
	label: "Current date",
	description: "Get current date as ISO-8601 string.",
	argRequiredCount: 0,
	argSchemas: [],
	argRestSchema: null,
	returnType: Type.String({
		label: "ISO-8601 date string"
	}),
	render: () => {
		return new Date().toISOString().split("T")[0];
	}
});

export const dateDiffFunc = declareFunction({
	name: "DATE_DIFF",
	category: "date",
	label: "Date difference",
	description: "Get a difference betwen a two date",
	argRequiredCount: 2,
	argSchemas: [
		BP.Any({
			label: "Date 1",
			constraints: {
				required: true
			},
			fallbackValue: 0,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER
		}),
		BP.Any({
			label: "Date 2",
			constraints: {
				required: true
			},
			fallbackValue: 0,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER
		}),
		BP.String({
			label: "Format",
			constraints: {
				required: false
			},
			fallbackValue: "seconds"
		})
	],
	argRestSchema: null,
	returnType: Type.Any({
		label: "Difference between two date"
	}),
	render: (_rCtx, args) => {
		const inputDate1 = args[0]();
		const inputDate2 = args[1]();
		const format = args[2]() ?? "seconds";

		const date_1 = formatInputDate(inputDate1);
		const date_2 = formatInputDate(inputDate2);

		if (date_1 && date_2) {
			return date_1.diff(date_2, format).toObject();
		} else {
			throw new Error("Unsupported input date type.");
		}
	}
});

export const dateAddFunc = declareFunction({
	name: "DATE_ADD",
	category: "date",
	label: "Date Add",
	description: "Adds or removes hours, minutes, days, etc. to date time object.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Any({
			label: "Date",
			constraints: {
				required: true
			},
			fallbackValue: 0,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER
		}),
		BP.Object({
			label: "Duration",
			constraints: {
				required: true
			},
			fallbackValue: {
				years: 0,
				quarters: 0,
				months: 0,
				weeks: 0,
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
				milliseconds: 0
			},
			props: {
				years: BP.Prop(BP.Float({
					label: "Years",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				quarters: BP.Prop(BP.Float({
					label: "Quarters",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				months: BP.Prop(BP.Float({
					label: "Months",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				weeks: BP.Prop(BP.Float({
					label: "Weeks",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				days: BP.Prop(BP.Float({
					label: "Days",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				hours: BP.Prop(BP.Float({
					label: "Hours",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				minutes: BP.Prop(BP.Float({
					label: "Minutes",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				seconds: BP.Prop(BP.Float({
					label: "Seconds",
					constraints: {
						required: false
					},
					fallbackValue: 0
				})),
				milliseconds: BP.Prop(BP.Float({
					label: "Milliseconds",
					constraints: {
						required: false
					},
					fallbackValue: 0
				}))
			}
		})
	],
	argRestSchema: null,
	returnType: Type.String({
		label: "Date object"
	}),
	render: (_rCtx, args) => {
		const inputDate = args[0]();
		const duration = args[1]();

		const date = formatInputDate(inputDate);

		if (!date) {
			throw new Error("Unsupported input date type.");
		}

		const res = date.plus(duration);
		return res.toJSDate();
	}
});
