/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaModel, TGetBlueprintSchemaSpec } from "../Schema/IBlueprintSchema";
import { ISchemaConstObject, ISchemaConstObjectOptsProp } from "../schemas/const/SchemaConstObject";
import { ISchemaFlowNodeTypeDefinitionMap } from "../schemas/SchemaFlowNode";
import { ISchemaFlowNodeList } from "../schemas/SchemaFlowNodeList";
import { ISchemaScopedTemplate } from "../schemas/SchemaScopedTemplate";
import { IScope } from "../Shared/Scope";

/**
 * Event definition object
 */
export interface IEventDefinition {
	/** Label */
	label?: string;
	/** Description */
	description?: string;
	/** Icon */
	icon?: string;
	/** Order in UI */
	order?: string;
}

/**
 * Event definition map
 */
export interface IEventDefinitionMap {
	[K: string]: IEventDefinition;
}

/**
 * Event trigger functions
 */
export type TEventTrigger = (scope: IScope | ((parentScope: IScope) => IScope)) => Promise<unknown>;

/**
 * Event triggers
 */
export type TEventTriggerMap<TEvents extends IEventDefinitionMap> = { [K in keyof TEvents]: TEventTrigger };

/**
 * Schema for event handler
 */
export type TSchemaEventHandler = ISchemaConstObject<{
	nodes: ISchemaConstObjectOptsProp<
		ISchemaScopedTemplate<ISchemaFlowNodeList<ISchemaFlowNodeTypeDefinitionMap>>
	>;
}>;

/**
 * Schema for events configuration
 */
export type TSchemaEvents = ISchemaConstObject<{
	[K: string]: ISchemaConstObjectOptsProp<TSchemaEventHandler>;
}>;

/**
 * Model for events configuration schema
 */
export type TSchemaEventsModel = TGetBlueprintSchemaModel<TSchemaEvents>;

/**
 * Resolved event spec
 */
export type TSchemaEventsSpec = TGetBlueprintSchemaSpec<TSchemaEvents>;

/**
 * Function to resolve event spec for a given event name
 */
export type TSchemaResolveEventSpecFn = (eventName: string) => TSchemaEventsSpec[string];

/**
 * Map of event name -> if event is enabled
 */
export type TEventEnabledMap<TEvents extends IEventDefinitionMap> = { [K in keyof TEvents]: boolean };

// eslint-disable-next-line @typescript-eslint/ban-types
export type TGenericEventDefinitionMap = {};

// eslint-disable-next-line @typescript-eslint/ban-types
export type TEmptyEventDefinitionMap = {};

/**
 * Error object throwed when custom "throw" output is activated by the event node
 */
export class CustomEventError extends Error {
	constructor(name: string, message: string, public details: unknown) {
		super(message);
		this.name = name;
	}
}