/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNode } from "../IDT/ISchemaIDT";
import { CompileContext } from "../Context/CompileContext";
import { inlineValue } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IScope } from "../Shared/Scope";
import { RuntimeContext } from "../Context/RuntimeContext";
import { TModelPath } from "../Shared/TModelPath";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGenericBlueprintSchemaWithValue,
	TGetBlueprintSchemaDefault
} from "./IBlueprintSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { IModelNode, IModelNodeCompileResult, MODEL_CHANGE_TYPE, TGenericModelNode } from "./IModelNode";
import { createEventEmitter, emitEvent, removeAllEventListeners } from "@hexio_io/hae-lib-shared";
import { v4 as uuid } from "uuid";
import {
	ISchemaComponentListModel,
	ISchemaConstArrayModel,
	ISchemaFlowNodeListModel,
	ISchemaFlowNodeTypeDefinitionMap,
	TSchemaConstObjectProps
} from "../schemas";

/**
 * Creates an empty schema
 *
 * @param name Schema name
 * @param opts Schema options
 */
export function createEmptySchema<TSchema extends TGenericBlueprintSchema>(
	name: string,
	opts: TSchema["opts"]
): TSchema {
	const _id = uuid();

	return {
		_id,
		name,
		opts,
		getEditorMetaData: <TMetaData extends Record<string, unknown>>(modelNode: IModelNode<TSchema>) => {
			return modelNode.editorMetaData as TMetaData;
		},
		setEditorMetaData: <TMetaData extends Record<string, unknown>>(
			modelNode: IModelNode<TSchema>,
			metaData: TMetaData
		) => {
			modelNode.editorMetaData = {
				...modelNode.editorMetaData,
				...metaData
			};

			emitEvent(modelNode.changeEvent, {
				modelNode,
				changeType: MODEL_CHANGE_TYPE.METADATA
			});
		},
		assignParent: (
			modelNode: IModelNode<TSchema>,
			parent: TBlueprintSchemaParentNode,
			emitUpdate: boolean
		): void => {
			modelNode.parent = parent;

			if (emitUpdate) {
				emitEvent(modelNode.changeEvent, {
					modelNode,
					changeType: MODEL_CHANGE_TYPE.PARENT
				});
			}
		}
	} as TSchema;
}

/**
 * Creates a model base properties
 *
 * @param schema Schema definition
 */
export function createModelNode<TSchema extends TGenericBlueprintSchema>(
	schema: TSchema,
	dCtx: DesignContext,
	parent: TBlueprintSchemaParentNode,
	validationErrors: IBlueprintSchemaValidationError[],
	props: Omit<
		ReturnType<TSchema["createDefault"]>,
		| "schema"
		| "changeEvent"
		| "ctx"
		| "lastScopeFromRender"
		| "nodeId"
		| "isValid"
		| "validationErrors"
		| "initRequiredValid"
		| "destroyed"
		| "editorMetaData"
		| "parent"
	>
): ReturnType<TSchema["createDefault"]> {
	const node = {
		schema: schema,
		ctx: dCtx,
		nodeId: dCtx.__getNextNodeId(),
		changeEvent: createEventEmitter(),
		isValid: validationErrors && validationErrors.length > 0 ? false : true,
		validationErrors: validationErrors,
		initRequiredValid: true,
		destroyed: false,
		editorMetaData: {},
		...props,
		parent
	} as ReturnType<TSchema["createDefault"]>;

	for (let i = 0; i < validationErrors.length; i++) {
		if (validationErrors[i].type === SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED) {
			node.initRequiredValid = false;
			break;
		}
	}

	dCtx.__registerNode(node);
	return node;
}

/**
 * Clones a model base and adds properties
 * Does not clone entire NODE! It's used internally in schemas.
 *
 * @param schema Schema definition
 */
export function cloneModelNode<TModelNode extends TGenericModelNode>(
	dCtx: DesignContext,
	srcNode: TModelNode,
	parent: TBlueprintSchemaParentNode,
	// eslint-disable-next-line max-len
	props: Omit<
		TModelNode,
		| "schema"
		| "changeEvent"
		| "ctx"
		| "lastScopeFromRender"
		| "nodeId"
		| "isValid"
		| "validationErrors"
		| "initRequiredValid"
		| "destroyed"
		| "editorMetaData"
		| "parent"
	>
): TModelNode {
	return {
		schema: srcNode.schema,
		ctx: dCtx,
		nodeId: srcNode.nodeId,
		changeEvent: createEventEmitter(),
		isValid: srcNode.isValid,
		validationErrors: srcNode.validationErrors.slice(),
		initRequiredValid: srcNode.initRequiredValid,
		destroyed: srcNode.destroyed,
		editorMetaData: { ...srcNode.editorMetaData },
		...props,
		parent
	} as TModelNode;
}

/**
 * Destroys a model node
 *
 * @param modelNode Model node instance
 */
export function destroyModelNode(modelNode: TGenericModelNode): void {
	modelNode.destroyed = true;
	removeAllEventListeners(modelNode.changeEvent);
	modelNode.ctx.__unregisterNode(modelNode);
}

/**
 * Handle model node change
 * Emits events and stuff
 *
 * @param modelNode Model node instance
 * @param changeType Change type
 */
export function handleModelNodeChange(modelNode: TGenericModelNode, changeType: MODEL_CHANGE_TYPE): void {
	const eventData = {
		modelNode,
		changeType
	};

	emitEvent(modelNode.changeEvent, eventData);
	emitEvent(modelNode.ctx.modelChangeEvent, eventData);
}

// /**
//  * Returns a map of function filtered by categories
//  *
//  * @param functionMap Function map
//  * @param categories Categories
//  */
// export function filterSchemaFunctionsByCategory(
// 	functionMap: TSchemaFunctionDefinitionMap, categories: string[]
// ): TSchemaFunctionDefinitionMap {

// 	const res: TSchemaFunctionDefinitionMap = {};

// 	for (const k in functionMap) {
// 		if (categories.includes(functionMap[k].category)) {
// 			res[k] = functionMap[k];
// 		}
// 	}

// 	return res;

// }

/**
 * Executes validators on a given value and logs errors into a Runtime Context
 *
 * @param rCtx Runtime Context
 * @param path Path in a data structure
 * @param modelNodeId Model Node ID
 * @param validators List of validators
 * @param severity Error severity
 * @param value Value to validate
 */
export function applyRuntimeValidators<TSpec>(
	rCtx: RuntimeContext,
	path: TModelPath,
	modelNodeId: number,
	validators: IBlueprintSchemaValidatorHandler<TSpec>[],
	severity: DOC_ERROR_SEVERITY,
	value: TSpec
): boolean {
	let errors: IBlueprintSchemaValidationError[] = [];

	// Validate
	for (let i = 0; i < validators.length; i++) {
		errors = errors.concat(validators[i].validate(value));
	}

	if (errors.length > 0) {
		rCtx.logValidationErrors(path, modelNodeId, severity, errors);
	}

	return errors.length === 0;
}

/**
 * Compiles validators into a validate function
 *
 * Helper for implementing of `schema.compileValidate`.
 * Return function in a form of `(v,pt)=>boolean`,
 * or inlined boolean such as `true` or `false`,
 * or null if there are no relevant validators to be used.
 *
 * @param cCtx
 * @param validators
 */
export function compileRuntimeValidators(
	cCtx: CompileContext,
	path: TModelPath,
	modelNodeId: number,
	validators: IBlueprintSchemaValidatorHandler<unknown>[],
	severity: DOC_ERROR_SEVERITY
): string {
	const validatorGlobals = validators
		.map((v) => v.compile())
		.filter((c) => c !== null)
		.map((c) => cCtx.addGlobalValue(`(v)=>{${c}}`));

	// No validators - return null
	if (validatorGlobals.length === 0) {
		return null;
	}

	const validatorExpression = "[].concat(" + validatorGlobals.map((g) => `${g}(v)`).join(".concat(") + ")";
	// eslint-disable-next-line max-len
	const validatorCode = `(v,pt)=>{const _e=${validatorExpression};if(_e.length>0){rCtx.logValidationErrors(pt,${inlineValue(
		modelNodeId
	)},${inlineValue(severity)},_e,v);return false}else{return true}}`;

	return cCtx.addGlobalValue(validatorCode);
}

/**
 * Validates constant schema's default value and throws a declaration error
 *
 * @param schema Blueprint schema
 * @param validators List of validators
 * @param defaultValue Default value
 */
export function validateDefaultValue<TSchema extends TGenericBlueprintSchema>(
	schema: TSchema,
	validators: IBlueprintSchemaValidatorHandler<TGetBlueprintSchemaDefault<TSchema>>[],
	defaultValue: TGetBlueprintSchemaDefault<TSchema>
): IBlueprintSchemaValidationError[] {
	let errors: IBlueprintSchemaValidationError[] = [];

	if (defaultValue !== undefined && defaultValue !== null) {
		// Validate
		for (let i = 0; i < validators.length; i++) {
			errors = errors.concat(validators[i].validate(defaultValue));
		}

		// if (errors.length > 0) {
		// 	throw new SchemaDeclarationError(
		// 		schema.name, schema.opts, `Failed to validate default value ${formatValidationErrors(errors)}`
		// 	);
		// }
	} else {
		for (let i = 0; i < validators.length; i++) {
			errors = errors.concat(validators[i].validate(defaultValue));
		}
	}

	return errors;
}

/**
 * Validates constant schema's value and return errors
 *
 * @param schema Blueprint schema
 * @param validators List of validators
 * @param defaultValue Default value
 */
export function validateValue<TSchema extends TGenericBlueprintSchema>(
	schema: TSchema,
	validators: IBlueprintSchemaValidatorHandler<TGetBlueprintSchemaDefault<TSchema>>[],
	defaultValue: TGetBlueprintSchemaDefault<TSchema>
): IBlueprintSchemaValidationError[] {
	let errors: IBlueprintSchemaValidationError[] = [];

	// Validate
	for (let i = 0; i < validators.length; i++) {
		errors = errors.concat(validators[i].validate(defaultValue));
	}

	return errors;
}

/**
 * Validates constant schema's value and updates model
 *
 * @param modelNode Model node
 * @param value Value
 * @param errorMessageName Name of TSpec
 */
export function validateValueAndUpdateModel<TSchema extends TGenericBlueprintSchemaWithValue<TValue>, TValue>(
	modelNode: IModelNode<TSchema>,
	validators: IBlueprintSchemaValidatorHandler<TValue>[],
	value: TValue
): IBlueprintSchemaValidationError[] {
	let errors: IBlueprintSchemaValidationError[] = [];

	// Validate
	for (let i = 0; i < validators.length; i++) {
		errors = errors.concat(validators[i].validate(value));
	}

	modelNode.validationErrors = errors;
	modelNode.isValid = errors.length === 0 ? true : false;

	return errors;
}

/**
 * Validates parsed IDT node value and report errors to Design Context
 *
 * @param dCtx Design Context
 * @param idtNode IDT node
 * @param validators List of validators
 * @param value Value to validate
 */
export function validateParsedValueAndReport<TValue>(
	dCtx: DesignContext,
	idtNode: TBlueprintIDTNode,
	validators: IBlueprintSchemaValidatorHandler<TValue>[],
	value: TValue
): IBlueprintSchemaValidationError[] {
	let errors: IBlueprintSchemaValidationError[] = [];

	// Validate
	for (let i = 0; i < validators.length; i++) {
		errors = errors.concat(validators[i].validate(value));
	}

	logParseValidationErrors(dCtx, idtNode, errors);

	return errors;
}

/**
 * Logs validation errors to a Design Context
 *
 * @param dCtx Design context
 * @param idtNode IDT Node to log at
 * @param validationErrors Validation errors
 */
export function logParseValidationErrors(
	dCtx: DesignContext,
	idtNode: TBlueprintIDTNode,
	validationErrors: IBlueprintSchemaValidationError[]
): void {
	if (!idtNode.parseInfo) {
		return;
	}

	for (let i = 0; i < validationErrors.length; i++) {
		dCtx.logParseError(idtNode.parseInfo.loc.uri, {
			range: idtNode.parseInfo.loc.range,
			severity: DOC_ERROR_SEVERITY.ERROR,
			name: DOC_ERROR_NAME.INVALID_VALUE,
			message: validationErrors[i].message,
			parsePath: idtNode.path
		});
	}
}

export function renderScalarNode<TValue>(
	modelNode: TGenericModelNode,
	scope: IScope,
	value: TValue,
	fallbackValue: TValue
): TValue {
	modelNode.lastScopeFromRender = scope;

	if (modelNode.isValid) {
		return value;
	} else {
		return fallbackValue;
	}
}

export function compileScalarNodeRender<TValue>(
	cCtx: CompileContext,
	modelNode: TGenericModelNode,
	path: TModelPath,
	severity: DOC_ERROR_SEVERITY,
	valueExpression: string,
	fallbackValue: TValue
): IModelNodeCompileResult {
	if (modelNode.isValid) {
		return {
			isScoped: false,
			code: valueExpression
		};
	} else {
		cCtx.logValidationErrors(path, modelNode.nodeId, severity, modelNode.validationErrors);

		return {
			code: inlineValue(fallbackValue),
			isScoped: false
		};
	}
}

/**
 * Validation function that always logs message that the subject cannot be defined as an expression (so validated).
 * Always returns false.
 *
 * @param rCtx Runtime Context
 * @param path Model Path
 * @param modelNodeId Model Node Id
 * @param messageSubject Message subject (what cannot be defined)
 */
export function validateAsNotSupported(
	rCtx: RuntimeContext,
	path: TModelPath,
	modelNodeId: number,
	messageSubject: string
): boolean {
	rCtx.logValidationErrors(path, modelNodeId, DOC_ERROR_SEVERITY.ERROR, [
		{
			type: SCHEMA_VALIDATION_ERROR_TYPE.NO_VALIDATE,
			message: `Schema '${messageSubject}' cannot be defined as an expression.`,
			metaData: {
				// @todo from terms
				translationTerm: "schema.errors.cannotBeDefinedAsExpression",
				args: {
					subject: messageSubject
				}
			}
		}
	]);

	return false;
}

/**
 * Validation function that always logs message that the subject cannot be defined as an expression (so validated).
 * Always returns false.
 *
 * @param rCtx Runtime Context
 * @param path Model Path
 * @param modelNodeId Model Node Id
 * @param messageSubject Message subject (what cannot be defined)
 */
export function compileValidateAsNotSupported(
	cCtx: CompileContext,
	path: TModelPath,
	modelNodeId: number,
	messageSubject: string
): string {
	cCtx.logValidationErrors(path, modelNodeId, DOC_ERROR_SEVERITY.ERROR, [
		{
			type: SCHEMA_VALIDATION_ERROR_TYPE.NO_VALIDATE,
			message: `Schema '${messageSubject}' cannot be defined as an expression.`,
			metaData: {
				// @todo from terms
				translationTerm: "schema.errors.cannotBeDefinedAsExpression",
				args: {
					subject: messageSubject
				}
			}
		}
	]);

	return `(v,pt)=>false`;
}

/**
 * Iterate through array child items of model and assign them parent
 *
 * @param srcParentModel source parent model to be assigned to children
 * @param childItemsArr child items to get assigned parent
 */

export function assignParentToArrItems<
	TModelNode extends
		| ISchemaFlowNodeListModel<ISchemaFlowNodeTypeDefinitionMap>
		| ISchemaConstArrayModel<TGenericBlueprintSchema>
		| ISchemaComponentListModel<TSchemaConstObjectProps>
>(srcParentModel: TModelNode, childItemsArr: TModelNode["items"]): TModelNode {
	if (!childItemsArr) {
		return srcParentModel;
	}

	if (!childItemsArr.length) {
		return srcParentModel;
	}

	for (let i = 0; i < childItemsArr.length; i++) {
		const childItem = childItemsArr[i] as TGenericModelNode;
		childItem.schema.assignParent(childItem, srcParentModel, false);
	}

	return srcParentModel;
}

/**
 * Iterate through object with children as properties and assign them parent
 *
 * @param srcParentModel source parent model to be assigned to children
 * @param objWithChildren object with children properties
 */
export function assignParentToObjAttributes<
	TModelNode extends IModelNode<IBlueprintSchema<any, any, any, any>>
>(srcParentModel: TModelNode, objWithChildren: { [key: string]: TModelNode }): TModelNode {
	for (const childKey in objWithChildren) {
		const child = objWithChildren[childKey];

		if (child) {
			child.schema.assignParent(child, srcParentModel, false);
		}
	}
	return srcParentModel;
}

/**
 * Iterate through defined object properties and assign them parent
 *
 * @param srcModel source parent model to be assigned to children
 * @param propPaths list of paths to properties to get assigned parent
 */
export function assignParentToModelProps<TModelNode extends IModelNode<IBlueprintSchema<any, any, any, any>>>(
	srcModel: TModelNode,
	propPaths: string | string[]
): TModelNode {
	if (Array.isArray(propPaths)) {
		for (let i = 0; i < propPaths.length; i++) {
			const propPath = propPaths[i];
			const modelProp = srcModel[propPath];
			if (modelProp) {
				modelProp.schema.assignParent(modelProp, srcModel, false);
			}
		}
	} else {
		const modelProp = srcModel[propPaths];
		if (modelProp) {
			modelProp.schema.assignParent(modelProp, srcModel, false);
		}
	}
	return srcModel;
}
