/*
 * Hexio App Engine Editor
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { StyleSheet } from "../Classes/StyleSheet";
import { IStyleTag, makeTag } from "./StyleTag";

export class StylingManager {
	public tag: IStyleTag;
	public styleSheets = new Map<string, StyleSheet>();

	constructor(target?: HTMLElement) {
		this.tag = makeTag(target);
	}

	public updateStyleSheet(id: string, styleSheet: StyleSheet): void {
		this.styleSheets.set(id, styleSheet);

		this.tag.insertRules(id, styleSheet);
	}

	public deleteStyleSheet(id: string): void {
		this.styleSheets.delete(id);

		this.tag.deleteRules(id);
	}

	public toString(): string {
		let css = "";

		for (const [ id, rules ] of this.styleSheets) {
			const rulesCss = rules.join("");

			css += rulesCss;
			//css += `${STYLE_ATTR}[id="${id}"] { content: "${id}" }`;
		}

		return css;
	}
}
