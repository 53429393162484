/**
 * Dimension pattern
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { cssLengthUnits, cssBaseLengthUnits } from "./cssUnits";

const dimensionValues = "auto|inherit|initial|max-content|min-content|revert|unset";

export const dimensionPattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssLengthUnits})|(${dimensionValues}))$`;

export const dimensionWithConstantPattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssLengthUnits})|(${dimensionValues})|[A-Z_]+)$`;

// eslint-disable-next-line max-len
export const dimensionWithConstantOrAutoOrInheritPattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssLengthUnits})|(auto|inherit)|[A-Z_]+)$`;

export const dimensionInPixelsPattern = "^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(px))$";

export const dimensionInPixelsWithConstantPattern = "^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(px)|[A-Z_]+)$";

export const dimensionInPixelsOrPercentsPattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssBaseLengthUnits}))$`;

// eslint-disable-next-line max-len
export const dimensionInPixelsOrPercentsWithConstantPattern = `^((([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssBaseLengthUnits})|[A-Z_]+)$`;

// eslint-disable-next-line max-len
export const lengthWithConstantOrAutoOrInheritPattern = `^((-?([0-9]+)(\\.[0-9]+)?|\\.[0-9]+)\\s*(${cssLengthUnits})|(auto|inherit)|[A-Z_]+)$`;
