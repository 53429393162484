/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IParseInfo } from "../Shared/IParseInfo";

/*
 * IDT = Intermediate Data Tree
 * Abstract representation of an object to parse and validate.
 */

/**
 * IDT node type
 */
export enum BP_IDT_TYPE {
	SCALAR = "scalar",
	MAP = "map",
	MAP_ELEMENT = "map_element",
	LIST = "list"
	// TAG = "tag"
}

/**
 * IDT scalar node sub-types
 */
export enum BP_IDT_SCALAR_SUBTYPE {
	NULL = "null",
	STRING = "string",
	INTEGER = "integer",
	FLOAT = "float",
	BOOLEAN = "booelan",
	REGEXP = "regexp",
	DATE = "date"
}

export type TBlueprintIDTNodePath = Array<string>;

/**
 * IDT Node base
 */
export interface IBlueprintIDTNodeBase<Type = BP_IDT_TYPE> {
	/** Node type */
	type: Type;

	/** Path in the data tree */
	path: TBlueprintIDTNodePath;

	/** Optional parse info */
	parseInfo?: IParseInfo;

	/** Option parse info of a parent key that produces this node */
	parentKeyParseInfo?: IParseInfo;
}

/**
 * Scalar node
 */
export interface IBlueprintIDTScalar extends IBlueprintIDTNodeBase<BP_IDT_TYPE.SCALAR> {
	subType: BP_IDT_SCALAR_SUBTYPE;
	value: number | string | boolean | null;
}

/**
 * Map element
 */
export interface IBlueprintIDTMapElement<TValueNode extends TBlueprintIDTNode = TBlueprintIDTNode>
	extends IBlueprintIDTNodeBase<BP_IDT_TYPE.MAP_ELEMENT> {
	key: IBlueprintIDTScalar;
	value: TValueNode;
}

/**
 * Map node
 */
export interface IBlueprintIDTMap extends IBlueprintIDTNodeBase<BP_IDT_TYPE.MAP> {
	items: Array<IBlueprintIDTMapElement>;
}

/**
 * List node
 */
export interface IBlueprintIDTList extends IBlueprintIDTNodeBase<BP_IDT_TYPE.LIST> {
	items: Array<TBlueprintIDTNode>;
}

/**
 * Tag node
 */
/*
export interface IBlueprintIDTTag extends IBlueprintIDTNodeBase<BP_IDT_TYPE.TAG> {
	tagName: string;
	value: TBlueprintIDTNode;
}
*/

/**
 * IDT node types
 */
export type TBlueprintIDTNode =
	| IBlueprintIDTScalar
	| IBlueprintIDTMap
	| IBlueprintIDTMapElement
	| IBlueprintIDTList; // | IBlueprintIDTTag;

export type TGetBlueprintIDTNodeByType<TType extends BP_IDT_TYPE> = TType extends BP_IDT_TYPE.SCALAR
	? IBlueprintIDTScalar
	: TType extends BP_IDT_TYPE.LIST
	? IBlueprintIDTList
	: TType extends BP_IDT_TYPE.MAP
	? IBlueprintIDTMap
	: TType extends BP_IDT_TYPE.MAP_ELEMENT
	? IBlueprintIDTMapElement
	: TBlueprintIDTNode;
