/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	OBJECT_TYPE,
	OBJECT_TYPE_PROP_NAME,
	createSubScope
} from "@hexio_io/hae-lib-blueprint";
import { dataEqual } from "@hexio_io/hae-lib-blueprint/src/Shared/Equal";
import { deriveChangedProps, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { ACTION_DELEGATE_STATE, IActionDelegate, IActionResultErrorObject } from "../actions";
import { IActionDelegateResolver } from "../resolvers";
import { termsEditor } from "../terms";

/**
 * Action Data Source Internal State
 */
export enum DS_ACTION_STATE {
	BLANK = "BLANK",
	LOADING = "LOADING",
	LOADED = "LOADED",
	ERROR = "ERROR"
}

/**
 * Action datasource
 */
interface DataSourceAction_State {
	/** Lifecycle state */
	state: DS_ACTION_STATE;

	/** If data source is enabled */
	enabled: boolean;

	/** Action ID */
	actionId: string;

	/** Action params */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	actionParams: any;

	/** Current reload interval (or null if reload is disabled) */
	reloadInterval: number;

	/** Action delegate */
	delegate: IActionDelegate;

	/** Loaded data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;

	/** If data were successfully loaded at least once or default value is used */
	hasData: boolean;

	/** Error message - if state is error */
	lastError: IActionResultErrorObject;

	/** When the params were debounced last time */
	lastDebounceTime: number;

	/** Timer to update params */
	debounceTimer: ReturnType<typeof setTimeout> | null;

	/** What to set after debounce timer fires */
	debounceTarget: {
		actionId: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		actionParams: any;
	}|null;

	/** If to reload data every time and avoid cache */
	forceReload: boolean;

	/** Function bound to delegate's onUpdate event */
	updateHandler: () => void;

	/** Function to refresh data (re-call action) */
	reloadHandler: () => void;
}

export const DataSourceAction_Opts = {
	action: BP.Prop(
		BP.ActionRef({
			label: termsEditor.dataSources.action.action.label,
			description: termsEditor.dataSources.action.action.description,
			constraints: {
				required: false
			},
			editorOptions: {
				layoutType: "section"
			}
		})
	),
	enabled: BP.Prop(
		BP.Boolean({
			label: termsEditor.dataSources.action.enabled.label,
			description: termsEditor.dataSources.action.enabled.description,
			constraints: {
				required: true
			},
			default: true,
			fallbackValue: null
		})
	),
	cacheData: BP.Prop(
		BP.Boolean({
			label: termsEditor.dataSources.action.cacheData.label,
			description: termsEditor.dataSources.action.cacheData.description,
			constraints: {
				required: false
			},
			default: false,
			fallbackValue: false
		})
	),
	reload: BP.Prop(
		BP.OptGroup({
			label: termsEditor.dataSources.action.reload.label,
			description: termsEditor.dataSources.action.reload.description,
			enabledOpts: {
				label: termsEditor.dataSources.action.reloadEnabled.label,
				description: termsEditor.dataSources.action.reloadEnabled.description
			},
			value: BP.Object({
				props: {
					interval: BP.Prop(
						BP.Integer({
							label: termsEditor.dataSources.action.reloadInterval.label,
							description: termsEditor.dataSources.action.reloadInterval.description,
							constraints: {
								required: true,
								min: 100
							},
							default: 60000,
							fallbackValue: null
						})
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		})
	),
	debounceTimeMs: BP.Prop(
		BP.Integer({
			label: termsEditor.dataSources.action.debounceTimeMs.label,
			description: termsEditor.dataSources.action.debounceTimeMs.description,
			constraints: {
				required: false,
				min: 0
			},
			default: 0,
			fallbackValue: 0
		})
	),
	default: BP.Prop(
		BP.Data({
			label: termsEditor.dataSources.action.default.label,
			description: termsEditor.dataSources.action.default.description,
			default: null,
			fallbackValue: null
		})
	)
};

export const DataSourceAction_Events = {
	dataLoaded: {
		...termsEditor.dataSources.action.events.dataLoaded,
		icon: "mdi/database-import"
	},
	error: {
		...termsEditor.dataSources.action.events.error,
		icon: "mdi/alert"
	}
};

/**
 * Updates state based on deleate status
 *
 * @param state Prev state
 * @returns New state
 */
function updateStateOnDelegateUpdate(state: DataSourceAction_State): DataSourceAction_State {
	let newDsState: DS_ACTION_STATE;

	switch (state.delegate.getState()) {
		case ACTION_DELEGATE_STATE.NOT_LOADED:
		case ACTION_DELEGATE_STATE.LOADING:
			newDsState = DS_ACTION_STATE.LOADING;
			break;
		case ACTION_DELEGATE_STATE.LOADED:
			newDsState = DS_ACTION_STATE.LOADED;
			break;
		case ACTION_DELEGATE_STATE.ERROR:
			newDsState = DS_ACTION_STATE.ERROR;
			break;
	}

	return {
		...state,
		state: newDsState,
		data: newDsState === DS_ACTION_STATE.LOADED ? state.delegate.getData() : state.data,
		hasData: state.hasData || state.delegate.wasLoaded(),
		lastError: state.delegate.getLastError()
	};
}

/**
 * Variable data source
 */
export const DataSourceAction = defineElementaryDataSource<
	typeof DataSourceAction_Opts,
	DataSourceAction_State,
	typeof DataSourceAction_Events
>({
	name: "action",
	label: termsEditor.dataSources.action.root.label,
	description: termsEditor.dataSources.action.root.description,
	icon: "mdi/motion-play",
	opts: DataSourceAction_Opts,
	events: DataSourceAction_Events,
	resolve: (opts, prevState, updateStateAsync, dsInstance, rCtx) => {
		// Initialize new state
		let state = prevState
			? { ...prevState }
			: ({
				state: DS_ACTION_STATE.BLANK,
				enabled: opts.enabled,
				actionId: null,
				actionParams: null,
				reloadInterval: null,
				delegate: null,
				data: opts.default || null,
				hasData: opts.default !== undefined && opts.default !== null ? true : false,
				lastError: null,
				lastDebounceTime: 0,
				debounceTimer: null,
				debounceTarget: null,
				forceReload: false,
				updateHandler: () => {
					updateStateAsync((prevState) => {
						const newState = updateStateOnDelegateUpdate(prevState);

						if (!rCtx.isInSSR()) {
							if (newState.state === DS_ACTION_STATE.LOADED && dsInstance.eventEnabled.dataLoaded) {
								dsInstance.eventTriggers.dataLoaded((parentScope) => createSubScope(parentScope, {
									_data: newState.data
								}, {
									_data: Type.Any({})
								}));
							}

							if (newState.state === DS_ACTION_STATE.ERROR && dsInstance.eventEnabled.error) {
								dsInstance.eventTriggers.error((parentScope) => createSubScope(parentScope, {
									_error: newState.lastError
								}, {
									_error: Type.Any({})
								}));
							}
						}

						return newState;
					})
				},
				reloadHandler: () =>
					updateStateAsync((prevState) => ({ ...prevState, forceReload: true }))
			});

		// When enabled change or action spec change
		const boolDerivedOpts = deriveChangedProps(
			{
				enabled: state.enabled ?? false
			},
			{
				enabled: opts.enabled
			}
		);

		const derivedOpts = {
			actionId: opts.action.actionId,
			params: opts.action.params,
			default: opts.default,
			cacheData: opts.cacheData,
			reload: opts.reload,
			...boolDerivedOpts
		};

		const delegateOpts = {
			actionId: state.delegate?.getActionId() ?? null,
			params: state.delegate?.getParams() ?? null,
		};

		// Debounce action and params change
		if (
			state.actionId !== derivedOpts.actionId ||
			!dataEqual(state.actionParams, derivedOpts.params)
		) {
			if (opts.debounceTimeMs > 0) {
				const now = Date.now();

				if (state.lastDebounceTime < (now - opts.debounceTimeMs)) {
					// We are out of the de-bounce slot, do immediate update
					state.actionId = derivedOpts.actionId;
					state.actionParams = derivedOpts.params;
					state.lastDebounceTime = now;
				} else {
					// We are in the de-bounce slot, do delayed update
					state.debounceTarget = {
						actionId: derivedOpts.actionId,
						actionParams: derivedOpts.params
					}

					if (!state.debounceTimer) {
						state.debounceTimer = setTimeout(() => {
							updateStateAsync((prevState) => ({
								...prevState,
								debounceTimer: null,
								debounceTarget: null,
								actionId: prevState.debounceTarget?.actionId ?? null,
								actionParams: prevState.debounceTarget?.actionParams ?? null,
								// lastDebounceTime: Date.now()
							}));
						}, opts.debounceTimeMs);
					}
				}
			} else {
				state.actionId = derivedOpts.actionId;
				state.actionParams = derivedOpts.params;
			}
		}

		if (
			state?.enabled !== derivedOpts.enabled ||
			state?.actionId !== delegateOpts.actionId ||
			!dataEqual(state?.actionParams, delegateOpts.params)
		) {
			// Release previous action delegate if was bound
			if (state.delegate) {
				if (state.reloadInterval) {
					state.delegate.removeReloadInterval(state.reloadInterval);
					state.reloadInterval = null;
				}

				offEvent(state.delegate.onUpdate, state.updateHandler);
				state.delegate = null;
			}

			// If enabled then bound a new delegate
			if (derivedOpts.enabled) {
				// Get delegate
				const delegate = rCtx
					.getResolver<IActionDelegateResolver>("actionDelegate")
					.getDelegate(state.actionId, state.actionParams);

				// Invoke action
				rCtx.__addAsyncOperation(
					delegate.invoke(state.forceReload || derivedOpts.cacheData === false)
				);

				// Bound listener
				onEvent(delegate.onUpdate, state.updateHandler);

				// Process data state
				state = updateStateOnDelegateUpdate({
					...state,
					enabled: true,
					actionId: state.actionId,
					actionParams: state.actionParams,
					delegate: delegate,
					forceReload: false,
					reloadInterval: null
				});

				// Otherwise set state to blank
			} else {
				state = {
					...state,
					state: DS_ACTION_STATE.BLANK,
					enabled: false,
					reloadInterval: null,
					data: derivedOpts.default || null,
					hasData: derivedOpts.default !== undefined && derivedOpts.default !== null ? true : false,
					lastError: null,
					forceReload: false
				};
			}

			// Otherwise if reload action is required
		} else if (state.forceReload && state.delegate) {
			rCtx.__addAsyncOperation(state.delegate.invoke(true));

			state = updateStateOnDelegateUpdate({
				...state,
				forceReload: false
			});
		}

		// Handle reload interval change
		const newReloadInterval = derivedOpts.reload?.interval || null;

		if (state.delegate && state.reloadInterval !== newReloadInterval) {
			if (state.reloadInterval !== null) {
				state.delegate.removeReloadInterval(state.reloadInterval);
			}

			if (newReloadInterval !== null) {
				state.delegate.addReloadInterval(newReloadInterval);
			}

			state.reloadInterval = newReloadInterval;
		}

		// console.log("DS Action State:", state);

		return state;
	},

	destroy: (_opts, state) => {
		// Clear debounce timer if set
		if (state.debounceTimer) {
			clearTimeout(state.debounceTimer);
			state.debounceTimer = null;
		}

		// Release previous action delegate if was bound
		if (state.delegate) {
			if (state.reloadInterval) {
				state.delegate.removeReloadInterval(state.reloadInterval);
			}

			offEvent(state.delegate.onUpdate, state.updateHandler);
		}
	},

	getScopeData: (opts, state) => {
		return {
			[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE,
			state: state.state,
			isLoading: state.state === DS_ACTION_STATE.LOADING ? true : false,
			hasData: state.hasData,
			data: state.data,
			actionParams: state.actionParams ?? {},
			lastError: state.lastError,
			reload: state.reloadHandler
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				state: Type.String({
					label: termsEditor.dataSources.action.scopeState.label,
					description: termsEditor.dataSources.action.scopeState.description
				}),
				isLoading: Type.Boolean({
					label: termsEditor.dataSources.action.scopeIsLoading.label,
					description: termsEditor.dataSources.action.scopeIsLoading.description
				}),
				hasData: Type.Boolean({
					label: termsEditor.dataSources.action.scopeHasData.label,
					description: termsEditor.dataSources.action.scopeHasData.description
				}),
				data: Type.Any({
					label: termsEditor.dataSources.action.scopeData.label,
					description: termsEditor.dataSources.action.scopeData.description
				}),
				lastError: Type.String({
					label: termsEditor.dataSources.action.scopeLastError.label,
					description: termsEditor.dataSources.action.scopeLastError.description
				}),
				reload: Type.Method({
					label: termsEditor.dataSources.action.scopeReload.label,
					description: termsEditor.dataSources.action.scopeReload.description,
					argRequiredCount: 0,
					argSchemas: [],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});
