/**
 * App Header HAE component
 * DEPRECATED
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, Type, defineElementaryComponent } from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Container,
	ContainerContentSchema,
	ClassList,
	ContainerProps,
	getValuesFromStringEnum,
	getStringEnumKeyByValue,
	CONTAINER_FLOW,
	StyleSheet,
	getStringEnumCssValue,
	HAEComponentMainContext,
	ContainerHorizontalAlignSchema,
	ContainerVerticalAlignSchema,
	HORIZONTAL_ALIGN,
	VERTICAL_ALIGN,
	dimensionWithConstantPattern,
	propGroups,
	useStyleSheetRegistry
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { APP_HEADER_HEIGHT, APP_HEADER_HEIGHT_default } from "../../Enums/APP_HEADER_HEIGHT";

interface HAEComponentAppHeader_State {}

const HAEComponentAppHeader_Props = {
	content: BP.Prop(ContainerContentSchema(), 0, propGroups.common),

	height: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.appHeader.height,
			constants: getValuesFromStringEnum(APP_HEADER_HEIGHT, termsEditor.schemas.appHeader.heightValues),
			default: APP_HEADER_HEIGHT_default,
			fallbackValue: APP_HEADER_HEIGHT_default,
			constraints: {
				...termsEditor.schemas.appHeader.heightConstraints,
				pattern: dimensionWithConstantPattern,
				required: true
			}
		}),
		10,
		propGroups.common
	),

	horizontalAlign: BP.Prop(
		ContainerHorizontalAlignSchema({
			default: getStringEnumKeyByValue(HORIZONTAL_ALIGN, HORIZONTAL_ALIGN.LEFT),
			fallbackValue: getStringEnumKeyByValue(HORIZONTAL_ALIGN, HORIZONTAL_ALIGN.LEFT)
		}),
		30,
		propGroups.common
	),

	verticalAlign: BP.Prop(
		ContainerVerticalAlignSchema({
			default: getStringEnumKeyByValue(VERTICAL_ALIGN, VERTICAL_ALIGN.STRETCH),
			fallbackValue: getStringEnumKeyByValue(VERTICAL_ALIGN, VERTICAL_ALIGN.STRETCH)
		}),
		40,
		propGroups.common
	),

	backgroundColor: ContainerProps.backgroundColor,

	foregroundColor: ContainerProps.foregroundColor,

	padding: ContainerProps.padding,

	spacing: ContainerProps.spacing
};

const HAEComponentAppHeader_Events = {};

const HAEComponentAppHeader_Definition = defineElementaryComponent<
	typeof HAEComponentAppHeader_Props,
	HAEComponentAppHeader_State,
	typeof HAEComponentAppHeader_Events
>({
	...termsEditor.components.appHeader.component,

	name: "appHeader",

	category: "layout",

	icon: "mdi/page-layout-header",

	docUrl: "...",

	order: 100,

	hidden: true,

	container: true,

	props: HAEComponentAppHeader_Props,

	events: HAEComponentAppHeader_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentAppHeader_React: THAEComponentReact<typeof HAEComponentAppHeader_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { content, height, horizontalAlign, verticalAlign } = props;

	const componentMainContext = React.useContext(HAEComponentMainContext);

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-app-header",
		componentInstance.safePath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Height

		if (height) {
			const heightValue = getStringEnumCssValue(APP_HEADER_HEIGHT, height, "app-header-height-");

			result.addString(`.${idClassName}`, `--element-height: ${heightValue};`);
			result.addString(
				`.hae-app-root--id-${componentMainContext.appRootId}`,
				`--app-header-height: ${heightValue};`
			);
		}

		return result;
	}, [ idClassName, height ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	return (
		<header className={classList.toClassName()} role="banner">
			<div className="cmp-app-header__holder">
				<Container
					content={content}
					flow={getStringEnumKeyByValue(CONTAINER_FLOW, CONTAINER_FLOW.ROW)}
					horizontalAlign={horizontalAlign}
					verticalAlign={verticalAlign}
					backgroundColor={props.backgroundColor}
					foregroundColor={props.foregroundColor}
					padding={props.padding}
					spacing={props.spacing}
					componentPath={[ ...componentInstance.safePath, "container" ]}
					componentMode={componentInstance.componentMode}
					classList={new ClassList("cmp-app-header__container")}
					contentModelNode={componentInstance.modelNode?.props.props.content as unknown as any}
				/>
			</div>
		</header>
	);
};

export const HAEComponentAppHeader: THAEComponentDefinition<typeof HAEComponentAppHeader_Definition> = {
	...HAEComponentAppHeader_Definition,
	reactComponent: HAEComponentAppHeader_React
};
