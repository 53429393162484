/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNode, BP_IDT_TYPE } from "./ISchemaIDT";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serializeIDTToData(idtNode: TBlueprintIDTNode): any {
	switch (idtNode.type) {
		case BP_IDT_TYPE.SCALAR: {
			return idtNode.value;
		}

		case BP_IDT_TYPE.LIST: {
			return idtNode.items.map((item) => serializeIDTToData(item));
		}

		case BP_IDT_TYPE.MAP: {
			const map = {};
			idtNode.items.forEach(
				(item) =>
					(map[serializeIDTToData(item.key)] = item.value ? serializeIDTToData(item.value) : null)
			);
			return map;
		}
	}
}
