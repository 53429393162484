/**
 * ClassList class
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ClassList } from "./ClassList";

import { isBoolean, isNilOrEmptyString } from "@hexio_io/hae-lib-shared";

/**
 * Bem modifiers map
 */
export interface IBemModifiersMap {
	[K: string]: boolean | string | number;
}

/**
 * Class List class
 *
 * Simple class for handling classLists
 */
export class Bem extends ClassList {
	/** BEM Block name */
	public blockName: string;

	/** BEM Element name */
	public elementName: string;

	/** Base class name (block + element) */
	public baseClassName: string;

	/**
	 * Class List constructor
	 *
	 * @param classNames multiple classNames (strings)
	 */
	constructor(
		blockName: string,
		elementName?: string,
		modifiers?: string | IBemModifiersMap,
		addBaseClass = true
	) {
		super();

		this.blockName = blockName;
		this.elementName = elementName;
		this.baseClassName = blockName + (elementName ? "__" + elementName : "");

		if (addBaseClass) {
			this.add(this.baseClassName);
		}

		if (modifiers) {
			this.mod(modifiers);
		}
	}

	/**
	 * Adds modifier(s)
	 *
	 * @param modifier Single modifier or a map { modifierName: boolean }
	 */
	public mod(modifier: string | IBemModifiersMap): Bem {
		if (modifier instanceof Object) {
			for (const k in modifier) {
				if (!isNilOrEmptyString(modifier[k])) {
					if (isBoolean(modifier[k])) {
						if (modifier[k]) {
							this.add(this.baseClassName + "--" + k);
						}
					} else {
						this.add(this.baseClassName + "--" + k + "-" + modifier[k]);
					}
				}
			}
		} else {
			this.add(this.baseClassName + "--" + modifier);
		}

		return this;
	}

	/**
	 * Returns a new Bem instance for a child element
	 *
	 * @param elementName Element name
	 * @param modifiers Modifiers
	 */
	public element(elementName: string, modifiers?: string | IBemModifiersMap): Bem {
		return new Bem(this.blockName, elementName, modifiers);
	}

	/**
	 * Creates BEM class from another one
	 *
	 * @param source Source BEM class
	 */
	public static clone(source: Bem): Bem {
		const copy = new Bem(source.blockName, source.elementName, undefined, false);

		for (let i = 0; i < source.length; i++) {
			copy.add(source[i]);
		}

		return copy;
	}
}

/**
 * Shorthand function to create BEM ClassList
 *
 * @param blockName Block name
 * @param elementName Element name
 * @param modifiers Modifiers
 * @returns
 */
export function BEM(blockName: string, elementName?: string, modifiers?: IBemModifiersMap): Bem {
	return new Bem(blockName, elementName, modifiers);
}
