/*
 * Hexio App Engine Editor
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { isEmptyString } from "@hexio_io/hae-lib-shared";
import React from "react";
import { StyleSheet } from "../Classes/StyleSheet";
import { useStylingContext } from "../Styling/StylingContext";

export interface IUseStyleSheetRegistry {
	add: (id: string, styleSheet: StyleSheet) => void;
}

/**
 * Get stylesheet registry to safely add stylesheets to manager
 */
export function useStyleSheetRegistry(): IUseStyleSheetRegistry {
	const stylingContext = useStylingContext();
	const registry = React.useRef<{ [K: string]: string }>({});
	// @ts-ignore
	const mountId = React.useId();

	const getStyleSheetId = React.useCallback(
		(id: string) => {
			return `${id}--${mountId}`;
		},
		[ mountId ]
	);

	const add = React.useCallback(
		(id: string, styleSheet: StyleSheet) => {
			const styleSheetId = getStyleSheetId(id);
			const styleSheetContent = styleSheet.toString();

			if (registry.current[styleSheetId] && registry.current[styleSheetId] === styleSheetContent) {
				return;
			}

			registry.current[styleSheetId] = styleSheetContent;
			stylingContext?.stylingManager?.updateStyleSheet(styleSheetId, styleSheet);
		},
		[ getStyleSheetId, stylingContext ]
	);

	React.useEffect(() => {
		return () => {
			for (const id in registry.current) {
				try {
					stylingContext?.stylingManager?.deleteStyleSheet(id);
				} catch (err) {
					console.warn("Failed to remove CSS rule for ID:", id, err);
				}
			}
		};
	}, []);

	return { add };
}
