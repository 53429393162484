/**
 * Gauge Chart Range Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum GAUGE_CHART_RANGE_TYPE {
	DEFAULT = "default",
	NULL = "null"
}

export type GAUGE_CHART_RANGE_TYPE_string = keyof typeof GAUGE_CHART_RANGE_TYPE;
