/**
 * Hexio App Engine Shared Library
 *
 * @package hae-lib-shared
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Base interface for item in ItemRegistry
 */
export interface ItemRegistryItemProps {
	/** Item name */
	name: string;

	/** Item category */
	category?: string;
}

export interface IItemRegistry<T extends ItemRegistryItemProps> {
	/**
	 * Registers item
	 *
	 * @param name Unique item name
	 */
	register(item: T): void;

	/**
	 * Unregister item
	 *
	 * @param name Unique item name
	 */
	unregister(name: string): void;

	/**
	 * Returns item by name
	 *
	 * @param name Name
	 */
	get(name: string): T;

	/**
	 * Returns all registered items
	 */
	getItems(): { [K: string]: T };

	/**
	 * Returns all registered items as an array
	 */
	getItemList(): T[];

	/**
	 * Return items in a given category
	 *
	 * @param category Category nme
	 */
	getItemsByCategory(category: string): { [K: string]: T };

	/**
	 * Returns list of items which are included in allowed categories
	 *
	 * @param allowedCategories Array of allowed categories
	 */
	getFilteredItems(allowedCategories: string[]): { [K: string]: T };

	/**
	 * Remove all items
	 */
	flush(): void;
}

/**
 * Registry class
 */
export class ItemRegistry<T extends ItemRegistryItemProps> implements IItemRegistry<T> {
	/**
	 * Item list
	 */
	private items: { [K: string]: T } = {};

	/**
	 * Category list
	 */
	private categories: { [C: string]: { [K: string]: T } } = {};

	/**
	 * Registers item
	 *
	 * @param name Unique item name
	 */
	public register(item: T): void {
		if (!item.name) {
			throw new Error(`Property 'name' expected to be a string.`);
		}

		if (this.items[item.name]) {
			throw new Error(`The item '${item.name}' is already registered.`);
		}

		this.items[item.name] = item;

		if (item.category) {
			if (!this.categories[item.category]) {
				this.categories[item.category] = {};
			}

			this.categories[item.category][item.name] = item;
		}
	}

	/**
	 * Unregister item
	 *
	 * @param name Unique item name
	 */
	public unregister(name: string): void {
		if (!this.items[name]) {
			throw new Error(`The item '${name}' is not registered.`);
		}

		const item = this.items[name];

		if (item.category) {
			if (this.categories[item.category] && this.categories[item.category][item.name]) {
				delete this.categories[item.category][item.name];
			}
		}

		delete this.items[name];
	}

	/**
	 * Returns item by name
	 *
	 * @param name Name
	 */
	public get(name: string): T {
		return this.items[name] || null;
	}

	/**
	 * Returns all registered items
	 */
	public getItems(): { [K: string]: T } {
		return this.items;
	}

	/**
	 * Returns all registered items filtered by category
	 */
	public getFilteredAllItems(): { [C: string]: { [K: string]: T } } {
		return this.categories;
	}

	/**
	 * Returns all registered items as an array
	 */
	public getItemList(): T[] {
		return Object.keys(this.items).map((key) => this.items[key]);
	}

	/**
	 * Return items in a given category
	 *
	 * @param category Category nme
	 */
	public getItemsByCategory(category: string): { [K: string]: T } {
		if (!category) {
			throw new Error(`Argument 'category' expected to be a string.`);
		}

		return this.categories[category] || {};
	}

	/**
	 * Returns list of items which are included in allowed categories
	 *
	 * @param allowedCategories Array of allowed categories
	 */
	public getFilteredItems(allowedCategories: string[]): { [K: string]: T } {
		if (!allowedCategories) {
			return this.items;
		}

		if (!Array.isArray(allowedCategories)) {
			throw new Error(`Argument 'allowedCategories' expected to be an array.`);
		}

		const filteredList: { [K: string]: T } = {};

		for (const category in this.categories) {
			if (allowedCategories.includes(category)) {
				const catItems = this.categories[category];

				for (const name in catItems) {
					filteredList[name] = catItems[name];
				}
			}
		}

		return filteredList;
	}

	public flush(): void {
		this.items = {};
	}
}
