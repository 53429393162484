/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createContext, useContext } from "react";

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { MEDIA_RESOLUTIONS, RuntimeContext, RUNTIME_CONTEXT_MODE } from "@hexio_io/hae-lib-blueprint";

import { TGenericHAEComponentDefinition } from "./THAEComponentDefinition";
import { IViewport } from "../Classes/Viewport";
import { IToastMessageManager } from "../ui/activities/ToastMessageManager";
import { IToastMessage, IToastMessageActivityItem } from "../ui/activities/ToastMessage";

/**
 * HAE Components render mode
 */
export enum HAE_RENDER_MODE {
	BROWSER = "browser",
	SSR = "ssr"
}

export interface IHAEComponentMainContextValue {
	componentRegistry: ItemRegistry<TGenericHAEComponentDefinition>;
	rCtx: RuntimeContext;
	mode: RUNTIME_CONTEXT_MODE;
	renderMode: HAE_RENDER_MODE;
	wasRenderedWithSsr: boolean;
	viewport: IViewport;
	toastMessageManager: IToastMessageManager<IToastMessageActivityItem, IToastMessage>;
	appRootId: string;
}

export interface IHAEComponentChildContextValue {
	dndState: {
		active: boolean;
	};
}

/**
 * HAE Component Main Context
 */
export const HAEComponentMainContext = createContext<IHAEComponentMainContextValue>(null);

export function useComponentMainContext(): IHAEComponentMainContextValue {
	return useContext(HAEComponentMainContext);
}

/**
 * HAE Component Child Context (passed to component children)
 */
export const HAEComponentChildContext = createContext<IHAEComponentChildContextValue>(null);
