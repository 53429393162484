/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { SCHEMA_TRANSLATE_KEY } from "../constants";
import { ISchemaConstString } from "./const/SchemaConstString";
import { SchemaValueString } from "./value/SchemaValueString";
import { ISchemaValue } from "./value/SchemaValue";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TypeDescString } from "../Shared/ITypeDescriptor";
import { applyCodeArg } from "../Context/CompileUtil";
import { ITranslationResolver } from "../Resolvers";

type TValueSchema = ISchemaValue<ISchemaConstString>;
type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;
type TValueSpec = TGetBlueprintSchemaSpec<TValueSchema>;
type TValueDefault = TGetBlueprintSchemaDefault<TValueSchema>;

/**
 * Schema model
 */
export interface ISchemaStringTranslateModel extends IModelNode<ISchemaStringTranslate> {
	value: TValueModel;
}

/**
 * Schema options
 */
export interface ISchemaStringTranslateOpts extends IBlueprintSchemaOpts {}

/**
 * Schema default
 */
export type TSchemaStringTranslateDefault = string;

/**
 * Schema type
 */
export interface ISchemaStringTranslate
	extends IBlueprintSchema<
		ISchemaStringTranslateOpts,
		ISchemaStringTranslateModel,
		TValueSpec,
		TValueDefault
	> {}

let valueSchemaInstance: TValueSchema = null;

/**
 * Schema: Variable reference
 *
 * @param opts Schema options
 */
export function SchemaStringTranslate(opts: ISchemaStringTranslateOpts): ISchemaStringTranslate {
	// Must be function with cache, otherwise it will loop to infinity
	const getValueSchema = () => {
		if (valueSchemaInstance) {
			return valueSchemaInstance;
		} else {
			return (valueSchemaInstance = SchemaValueString({
				allowTranslate: false,
				constraints: {
					required: true,
					min: 1
				}
			}));
		}
	};

	const schema = createEmptySchema<ISchemaStringTranslate>("stringTranslate", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "value" ]);
	};

	const createModel = (dCtx: DesignContext, value: TValueModel, parent: TBlueprintSchemaParentNode) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			value: value
		});

		const model = assignParentToChildrenOf(modelNode);

		model.initRequiredValid = value.initRequiredValid;

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const valueModel = getValueSchema().createDefault(dCtx, null, defaultValue);

		return createModel(dCtx, valueModel, parent);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check null
		if (
			!idtNode ||
			(idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL)
		) {
			return schema.createDefault(dCtx, parent);
		}

		// Check node
		if (
			idtNode.type !== BP_IDT_TYPE.MAP ||
			(idtNode.type === BP_IDT_TYPE.MAP && idtNode.items.length !== 1)
		) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.NOT_SINGLE_PROP,
					message: "Expecting a map with single property",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		// Check key
		const itemNode = idtNode.items[0];
		const itemKey = itemNode.key.value as string;

		if (itemKey !== SCHEMA_TRANSLATE_KEY) {
			if (itemNode.key.parseInfo) {
				dCtx.logParseError(itemNode.key.parseInfo.loc.uri, {
					range: itemNode.key.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.REF_INVALID_FORMAT,
					message: "Expecting a map element with a key '=#'.",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		// Create model
		const valueModel = getValueSchema().parse(dCtx, idtNode.items[0].value, null);

		return createModel(dCtx, valueModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {
		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});
	};

	schema.serialize = (modelNode, path) => {
		const _key = SCHEMA_TRANSLATE_KEY;

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[" + _key + "]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{" + _key + "}" ]),
						value: _key
					} as IBlueprintIDTScalar,
					value: getValueSchema().serialize(modelNode.value, path.concat([ _key ]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedValue = modelNode.value.schema.clone(dCtx, modelNode.value, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			value: clonedValue
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.value.schema.destroy(modelNode.value);
		destroyModelNode(modelNode);
	};

	schema.render = (rCtx, modelNode, path, scope) => {
		const valueToTranslate = modelNode.value.schema.render(rCtx, modelNode.value, path, scope);
		const resolver = rCtx.getResolver<ITranslationResolver>("translate");

		if (!resolver) {
			console.warn("Translate resolver is not available.");
			return valueToTranslate;
		}

		// @todo Handlebars interpolation
		return resolver.translate(valueToTranslate);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		const cmpValue = modelNode.value.schema.compileRender(cCtx, modelNode.value, path);

		const translateCode = cCtx.addGlobalValue(
			`(v)=>{${[
				`const _r=rCtx.getResolver("translate");`,
				`if(_r){return _r.resolve(v)}else{console.warn("Translate resolver is not available.");return v}`
			].join("")}}`
		);

		return {
			isScoped: false,
			code: `${translateCode}(${applyCodeArg(cmpValue, `undefined`, `pt`)})`
		};
	};

	// @todo Test and optionally replace with validateAsNotSupported(...)
	// No need for validation because value to translate is validated by ValueString and result is validated by parent node
	schema.validate = () => {
		return true;
	};

	schema.compileValidate = (): string => {
		return null;
	};

	schema.castSpec = (rCtx, path, modelNodeId, value) => {
		const valueSchema = getValueSchema();
		return valueSchema.castSpec(rCtx, path, modelNodeId, value);
	};

	schema.compileCastSpec = (cCtx, path, modelNodeId) => {
		const valueSchema = getValueSchema();
		return valueSchema.compileCastSpec(cCtx, path, modelNodeId);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaStringTranslate", [ opts ]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescString({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "value",
				node: modelNode.value
			}
		];
	};

	return schema;
}
