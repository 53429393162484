/**
 * Container HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Type, defineElementaryComponent, createSubScope } from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	Container,
	ContainerProps,
	isEventEnabled
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { BaseEvents } from "../../base/BaseEvents";

interface HAEComponentContainer_State {}

const HAEComponentContainer_Props = {
	...ContainerProps
};

const HAEComponentContainer_Events = {
	...BaseEvents
};

const HAEComponentContainer_Definition = defineElementaryComponent<
	typeof HAEComponentContainer_Props,
	HAEComponentContainer_State,
	typeof HAEComponentContainer_Events
>({
	...termsEditor.components.container.component,

	name: "container",

	category: "layout",

	icon: "mdi/selection",

	docUrl: "...",

	order: 20,

	container: true,

	props: HAEComponentContainer_Props,

	events: HAEComponentContainer_Events,

	resolve: (_spec, state) => {
		return state || {};
	},

	getScopeData: () => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentContainer_React: THAEComponentReact<typeof HAEComponentContainer_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	// Event handler

	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;
	}, [
		componentInstance.componentMode,
		componentInstance.eventEnabled.click,
		componentInstance.eventTriggers.click
	]);

	return (
		<Container
			{...props}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={reactComponentClassList}
			contentModelNode={componentInstance.modelNode?.props.props.content as unknown as any}
			onClick={_clickHandler}
		/>
	);
};

export const HAEComponentContainer: THAEComponentDefinition<typeof HAEComponentContainer_Definition> = {
	...HAEComponentContainer_Definition,
	reactComponent: HAEComponentContainer_React
};
