/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { EDITOR_APP_PERMISSIONS } from "../api";

export const MOCKED_TOKEN = "token";
export const MOCKED_APP_ID = "demo.app.id";
export const MOCKED_APP_ENV_ID = "demo.app.env.id";
export const MOCKED_APP_URL = "demo.adapptio.com";
export const MOCKED_IMAGE_ID = "demo-image";
export const MOCKED_IMAGE = "Adapptio v-demo";
export const MOCKED_APP_NAME = "Adapptio Demo";
export const MOCKED_URL = "demo-app--demo-dev.adapptio.com";
export const MOCKED_EMAIL = "user.demo@adapptio.com";
export const MOCKED_CERTIFICATE = "demo.cert";
export const MOCKED_PERMISSIONS = Object.values(EDITOR_APP_PERMISSIONS);
