/**
 * Box HAE component
 * DEPRECATED
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, Type, defineElementaryComponent, createSubScope } from "@hexio_io/hae-lib-blueprint";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	ClassList,
	Container,
	ContainerProps,
	BackgroundColorSchema,
	isEventEnabled
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { BaseEvents } from "../../base/BaseEvents";

interface HAEComponentBox_State {}

const HAEComponentBox_Props = {
	...ContainerProps,

	backgroundColor: BP.Prop(
		BackgroundColorSchema({
			alias: "backgroundColor_box",
			default: "WHITE"
		}),
		ContainerProps.backgroundColor.order,
		ContainerProps.backgroundColor.group
	)
};

const HAEComponentBox_Events = {
	...BaseEvents
};

const HAEComponentBox_Definition = defineElementaryComponent<
	typeof HAEComponentBox_Props,
	HAEComponentBox_State,
	typeof HAEComponentBox_Events
>({
	...termsEditor.components.box.component,

	name: "box",

	category: "layout",

	icon: "mdi/card",

	docUrl: "...",

	order: 10,

	hidden: true,

	container: true,

	props: HAEComponentBox_Props,

	events: HAEComponentBox_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentBox_React: THAEComponentReact<typeof HAEComponentBox_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-box", componentInstance.safePath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	// Event handler

	const _clickHandler = React.useMemo(() => {
		if (isEventEnabled(componentInstance.eventEnabled.click, componentInstance.componentMode)) {
			return () => componentInstance.eventTriggers.click((parentScope) => createSubScope(parentScope));
		}

		return;
	}, [
		componentInstance.componentMode,
		componentInstance.eventEnabled.click,
		componentInstance.eventTriggers.click
	]);

	return (
		<Container
			{...props}
			componentPath={componentInstance.safePath}
			componentMode={componentInstance.componentMode}
			classList={classList}
			contentModelNode={componentInstance.modelNode?.props.props.content as unknown as any}
			onClick={_clickHandler}
		/>
	);
};

export const HAEComponentBox: THAEComponentDefinition<typeof HAEComponentBox_Definition> = {
	...HAEComponentBox_Definition,
	reactComponent: HAEComponentBox_React
};
