/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

/**
 * Routes blueprint "spec" property schema
 */
export const BlueprintRoutesSpec = BP.Const.Map({
	keyOpts: {
		constraints: {
			required: true
		}
	},
	value: BP.Const.Object({
		props: {
			path: BP.Prop(
				BP.Const.String({
					constraints: {
						required: true
					}
				})
			),
			label: BP.Prop(
				BP.Const.String({
					constraints: {
						required: true
					}
				})
			)
		}
	})
});

/**
 * Route
 */
export interface IRoute {
	path: string;
	label: string;
}

export type TBlueprintRoutesSpecSchema = typeof BlueprintRoutesSpec;

/**
 * Routes Blueprint Schema
 */
export const BlueprintRoutes = createBlueprintSchema(DOC_TYPES.ROUTES_V1, BlueprintRoutesSpec);

export type TBlueprintRoutesSchema = typeof BlueprintRoutes;
