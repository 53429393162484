/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createContext } from "react";

/**
 * Map of icon packages to their respective URLs
 *
 * Examples:
 * ```
 * iconPackageUrlMapping: {
 *   "mdi": "https://cnd.hexio.cloud/media/icons/mdi",
 *   "app": "/media/icons"
 * }
 * ```
 */
export interface IIconPackageURLMapping {
	[K: string]: string;
}

/**
 * Media context
 */
export const MediaContext = createContext<{
	iconPackageUrlMapping: IIconPackageURLMapping;
}>(null);
