/**
 * Label component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const pixelGrid = {
	component: {
		label: "component:pixelGrid#label",
		description: "component:pixelGrid#description"
	},
	schema: {
		data: {
			label: "component:pixelGrid.schema.data#label",
			description: "component:pixelGrid.schema.data#description"
		},
		iconData: {
			label: "component:pixelGrid.schema.iconData#label",
			description: "component:pixelGrid.schema.iconData#description"
		},
		itemSize: {
			label: "component:pixelGrid.schema.itemSize#label",
			description: "component:pixelGrid.schema.itemSize#description"
		},
		itemMapping: {
			label: "component:pixelGrid.schema.itemMapping#label",
			description: "component:pixelGrid.schema.itemMapping#description"
		},
		iconMapping: {
			label: "component:pixelGrid.schema.iconMapping#label",
			description: "component:pixelGrid.schema.iconMapping#description"
		},
		itemMappingItem: {
			label: "component:pixelGrid.schema.itemMappingItem#label",
			description: "component:pixelGrid.schema.itemMappingItem#description"
		},
		itemMappingItemTitle: {
			label: "component:pixelGrid.schema.itemMappingItemTitle#label",
			description: "component:pixelGrid.schema.itemMappingItemTitle#description"
		},
		itemMappingItemValue: {
			label: "component:pixelGrid.schema.itemMappingItemValue#label",
			description: "component:pixelGrid.schema.itemMappingItemValue#description"
		},
		itemMappingItemBackgroundColor: {
			label: "component:pixelGrid.schema.itemMappingBackgroundItemColor#label",
			description: "component:pixelGrid.schema.itemMappingBackgroundItemColor#description"
		},
		itemMappingItemIconColor: {
			label: "component:pixelGrid.schema.itemMappingItemIconColor#label",
			description: "component:pixelGrid.schema.itemMappingItemIconColor#description"
		},
		itemMappingDefault: {
			label: "component:pixelGrid.schema.itemMappingDefault#label",
			description: "component:pixelGrid.schema.itemMappingDefault#description"
		},
		selection: {
			label: "component:pixelGrid.schema.selection#label",
			description: "component:pixelGrid.schema.selection#description"
		},
		selectionItem: {
			label: "component:pixelGrid.schema.selectionItem#label",
			description: "component:pixelGrid.schema.selectionItem#description"
		},
		selectionItemX: {
			label: "component:pixelGrid.schema.selectionItemX#label",
			description: "component:pixelGrid.schema.selectionItemX#description"
		},
		selectionItemY: {
			label: "component:pixelGrid.schema.selectionItemY#label",
			description: "component:pixelGrid.schema.selectionItemY#description"
		}
	},
	events: {
		onSelect: {
			label: "component:pixelGrid.events.onSelect#label",
			description: "component:pixelGrid.events.onSelect#description"
		}
	}
};
