/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createSubScope, Type } from "@hexio_io/hae-lib-blueprint";
import {
	MAP_NODE_ON_ERROR_TYPES,
	NODE_OUTPUT_NAMES,
	NODE_TYPES,
	TAllNodesSpec,
	TBlueprintMapNodeOptsSchemaSpec
} from "../../blueprints";
import { createSuccessNodeResult, setScopeVariable, unknownNodeError } from "../helpers";
import { IActionContext, INodeContext, INodeResult, processNodeResult } from "../ActionManager";
import { NODE_RESULT_TYPE } from "../IActionResult";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";

export async function mapNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintMapNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;
	const { items } = opts;

	try {
		if (!Array.isArray(items)) {
			context.debug("Node invalid opts error.");
			return createSuccessNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					data: {
						message: "Items expected to be an array.",
						name: ERROR_NAMES.MAP_NODE_ERROR
					},
					typeDescriptor: Type.Object({
						props: {
							message: Type.String({}),
							name: Type.String({})
						}
					})
				},
				aCtx,
				nCtx
			);
		}

		const mapNodeResult = createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ITEM,
				data: items,
				typeDescriptor: Type.Array({ items: [ Type.Any({}) ] })
			},
			aCtx,
			nCtx
		);

		const mapNodeOpts = opts as TBlueprintMapNodeOptsSchemaSpec;
		const mapResults: INodeResult[] = [];

		for (let index = 0; index < items.length; index++) {
			const item = items[index];

			context.debug("Map item:", { item, index, opts: mapNodeOpts });

			/** Set item as data for sub nodes invocation. */
			mapNodeResult.data = item;
			mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ITEM;

			const mapScope = createSubScope(nCtx.localScope);
			const varName = nCtx.nodeVarName || "";

			setScopeVariable(mapScope, varName, undefined, undefined);
			setScopeVariable(mapScope, `${varName}_item`, item, mapNodeResult.typeDescriptor);
			setScopeVariable(mapScope, `${varName}_index`, index, Type.Integer({}));

			const nCtxCopy = { ...nCtx };
			nCtxCopy.localScope = mapScope;
			nCtxCopy.nodeVarName = undefined;

			const itemNodeResult = await processNodeResult(mapNodeResult, aCtx, nCtxCopy);

			if (itemNodeResult.nCtx.nodeType === NODE_TYPES.RETURN) {
				context.debug("Node result has return node type.");
				return itemNodeResult;
			} else if (itemNodeResult.nCtx.status === NODE_RESULT_TYPE.ERROR) {
				context.debug("Node result has error.");

				if (mapNodeOpts.onError === MAP_NODE_ON_ERROR_TYPES.FAIL_ON_FIRST) {
					context.debug("Node fail on first.");

					const processedItems = [
						...mapResults.map((item) => item.data),
						...new Array(items.length - mapResults.length).fill(null)
					];

					const errorList = new Array(items.length).fill(null);

					errorList[index] = itemNodeResult.data;

					mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ERROR;
					mapNodeResult.data = {
						errorData: {
							name: itemNodeResult.data?.errorData?.name,
							code: itemNodeResult.data?.errorData?.code,
							message: itemNodeResult.data?.errorData?.message,
							detail: itemNodeResult.data?.errorData?.detail,
							errorList,
							processedItems
						},
						errorTracking: {
							actionName: aCtx.name,
							nodeName: nCtx.nodeName,
							nodeType: nCtx.nodeType,
							functionName: nCtx.functionName,
							integrationName: nCtx.integrationName,
							nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0
						}
					};
					if (aCtx.config.debug === true && mapNodeResult.debug) {
						mapNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_ERROR;
						mapNodeResult.debug.output.data = mapNodeResult.data;
						mapNodeResult.debug.executionTimeInMs = Date.now() - nCtx.startTimeInMs;
						aCtx.debug.nodes[nCtx.nodeId] = mapNodeResult.debug;
					}

					return await processNodeResult(mapNodeResult, aCtx, nCtx);
				} else {
					context.debug("Map node ignores error and adds item to map results.");
					mapResults.push(itemNodeResult);
				}
			} else {
				context.debug(`Map node adds item to map results.`);
				mapResults.push(itemNodeResult);
			}
		}

		/** Process map results. */

		if (mapNodeOpts.onError === MAP_NODE_ON_ERROR_TYPES.IGNORE) {
			context.debug("Node ignores errors.");

			mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_SUCCESS;
			/** Replaces error results with null. */
			mapNodeResult.data = mapResults.map((res) =>
				res.nCtx.status === NODE_RESULT_TYPE.SUCCESS ? res.data : null
			) as any;
			mapNodeResult.typeDescriptor = Type.Array({ items: [ Type.Any({}) ] });
			if (aCtx.config.debug === true && mapNodeResult.debug?.output) {
				mapNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_SUCCESS;
				mapNodeResult.debug.output.data = mapNodeResult.data;
			}
		} else if (mapNodeOpts.onError === MAP_NODE_ON_ERROR_TYPES.FAIL_AFTER_ALL) {
			const errors = mapResults.filter((res) => res.nCtx.status === NODE_RESULT_TYPE.ERROR) as any;

			if (errors.length > 0) {
				context.debug(`Node fails after all. Errors: ${errors.length}.`);

				const processedItems = mapResults.map((res) =>
					res.nCtx.status === NODE_RESULT_TYPE.SUCCESS ? res.data : null
				) as any;

				const errorList = mapResults.map((res) =>
					res.nCtx.status === NODE_RESULT_TYPE.ERROR ? res.data : null
				) as any;

				mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ERROR;
				mapNodeResult.data = {
					errorData: {
						name: ERROR_NAMES.MAP_NODE_ERROR,
						code: ERROR_CODES.MAP_NODE_ERROR,
						message: "One or more items failed to process.",
						detail: null,
						processedItems,
						errorList
					},
					errorTracking: {
						actionName: aCtx.name,
						nodeName: nCtx.nodeName,
						nodeType: nCtx.nodeType,
						functionName: nCtx.functionName,
						integrationName: nCtx.integrationName,
						nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0
					}
				};

				if (aCtx.config.debug === true && mapNodeResult.debug?.output) {
					mapNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_ERROR;
					mapNodeResult.debug.output.data = mapNodeResult.data;
				}
			} else {
				context.debug("Node return success result after all.");

				mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_SUCCESS;
				mapNodeResult.data = mapResults.map((res) => res.data) as any;
				if (aCtx.config.debug === true && mapNodeResult.debug?.output) {
					mapNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_SUCCESS;
					mapNodeResult.debug.output.data = mapNodeResult.data;
				}
			}

			mapNodeResult.typeDescriptor = Type.Array({ items: [ Type.Any({}) ] });
		} else {
			context.debug("Node has no errors.");

			mapNodeResult.outputName = NODE_OUTPUT_NAMES.ON_SUCCESS;
			mapNodeResult.data = mapResults.map((res) => res.data || null) as any;
			mapNodeResult.typeDescriptor = Type.Array({ items: [ Type.Any({}) ] });
			if (aCtx.config.debug === true && mapNodeResult.debug?.output) {
				mapNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_SUCCESS;
				mapNodeResult.debug.output.data = mapNodeResult.data;
			}
		}

		return mapNodeResult;
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);
	}
}
