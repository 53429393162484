/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { XMLParser, XMLBuilder, XMLValidator, X2jOptions, XmlBuilderOptions, validationOptions } from "fast-xml-parser";
import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const xmlParse = declareFunction({
	name: "XML_PARSE",
	category: "xml",
	label: "XML Parse",
	// eslint-disable-next-line max-len
	description: "Parses XML document into an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Document",
			description: "XML document as string",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.Object({
			label: "Options",
			description: "XML parser options",
			props: {
				allowBooleanAttributes: BP.Prop(BP.Boolean({
					label: "Allow Boolean Attributes",
					description: `To allow attributes without value.\n\nBy default boolean attributes are ignored`,
					default: true
				})),
				alwaysCreateTextNode: BP.Prop(BP.Boolean({
					label: "Always Create Text Node",
					default: false
				})),
				attributesGroupName: BP.Prop(BP.String({
					label: "Attributes Group Name"
				})),
				attributeNamePrefix: BP.Prop(BP.String({
					label: "Attributes Name Prefix"
				})),
				cdataPropName: BP.Prop(BP.String({
					label: "cDATA Property Name",
				})),
				commentPropName: BP.Prop(BP.String({
					label: "Comment Property Name",
				})),
				ignoreAttributes: BP.Prop(BP.Boolean({
					label: "Ignore Attributes",
					default: false
				})),
				ignoreDeclaration: BP.Prop(BP.Boolean({
					label: "Ignore Declaration",
					default: true
				})),
				ignorePiTags: BP.Prop(BP.Boolean({
					label: "Ignore Pi Tags",
					default: false
				})),
				parseAttributeValue: BP.Prop(BP.Boolean({
					label: "Parse Attribute Value",
					default: true
				})),
				parseTagValue: BP.Prop(BP.Boolean({
					label: "Parse Tag Value",
					default: true
				})),
				preserveOrder: BP.Prop(BP.Boolean({
					label: "Preserve Order",
					default: true
				})),
				processEntities: BP.Prop(BP.Boolean({
					label: "Process Entities",
					default: true
				})),
				removeNSPrefix: BP.Prop(BP.Boolean({
					label: "Remove Namespace Prefix",
					default: false
				})),
				textNodeName: BP.Prop(BP.String({
					label: "Text Node Name",
					default: "$text"
				})),
				trimValues: BP.Prop(BP.Boolean({
					label: "Trim Values",
					default: false
				})),
				unpairedTags: BP.Prop(BP.Array({
					label: "Unpaired Tags",
					// eslint-disable-next-line max-len
					description: `Unpaired Tags are the tags which don't have matching closing tag. Eg <br> in HTML. You can parse unpaired tags by providing their list to the validator.`,
					items: BP.String({})
				}))
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const xmlData = args[0]() as string;
		const opts: Partial<X2jOptions> = {
			allowBooleanAttributes: true,
			alwaysCreateTextNode: false,
			ignoreAttributes: false,
			ignoreDeclaration: true,
			ignorePiTags: false,
			parseAttributeValue: true,
			parseTagValue: true,
			preserveOrder: true,
			processEntities: true,
			removeNSPrefix: false,
			textNodeName: "$text",
			trimValues: false,
			...args[1]() as Partial<X2jOptions>,
		};

		const parser = new XMLParser(opts);
		return parser.parse(xmlData);
	}
});

export const xmlBuild = declareFunction({
	name: "XML_BUILD",
	category: "xml",
	label: "XML Build",
	// eslint-disable-next-line max-len
	description: "Build XML document from an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Document",
			description: "XML document as an object",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.MAP,
			constraints: {
				required: true
			},
			fallbackValue: {}
		}),
		BP.Object({
			label: "Options",
			description: "XML parser options",
			props: {
				arrayNodeName: BP.Prop(BP.String({
					label: "Array Node Name"
				})),
				attributesGroupName: BP.Prop(BP.String({
					label: "Attributes Group Name"
				})),
				attributeNamePrefix: BP.Prop(BP.String({
					label: "Attributes Name Prefix"
				})),
				cdataPropName: BP.Prop(BP.String({
					label: "cDATA Property Name",
				})),
				commentPropName: BP.Prop(BP.String({
					label: "Comment Property Name",
				})),
				format: BP.Prop(BP.Boolean({
					label: "Format",
					description: "By default, parsed XML is single line XML string. By format: true, you can format it for better view.",
					default: false
				})),
				indentBy: BP.Prop(BP.Integer({
					label: "Indent By",
					description: "Number of space. Applicable only if format: true is set."
				})),
				ignoreAttributes: BP.Prop(BP.Boolean({
					label: "Ignore Attributes",
					default: false
				})),
				preserveOrder: BP.Prop(BP.Boolean({
					label: "Preserve Order",
					default: true
				})),
				processEntities: BP.Prop(BP.Boolean({
					label: "Process Entities",
					default: true
				})),
				supressBooleanAttributes: BP.Prop(BP.Boolean({
					label: "Suppress Boolean Attributes",
					default: false
				})),
				suppressEmptyNode: BP.Prop(BP.Boolean({
					label: "Suppress Empty Node",
					default: false
				})),
				suppressUnpairedNode: BP.Prop(BP.Boolean({
					label: "Suppress Unpaired Node",
					default: false
				})),
				textNodeName: BP.Prop(BP.String({
					label: "Text Node Name",
					default: "$text"
				})),
				unpairedTags: BP.Prop(BP.Array({
					label: "Unpaired Tags",
					// eslint-disable-next-line max-len
					description: `Unpaired Tags are the tags which don't have matching closing tag. Eg <br> in HTML. You can parse unpaired tags by providing their list to the validator.`,
					items: BP.String({})
				}))
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const xmlData = args[0]() as object;
		const opts: Partial<XmlBuilderOptions> = {
			format: false,
			ignoreAttributes: false,
			preserveOrder: true,
			processEntities: true,
			suppressBooleanAttributes: false,
			suppressEmptyNode: false,
			suppressUnpairedNode: false,
			textNodeName: "$text",
			...args[1]() as Partial<XmlBuilderOptions>
		};

		const builder = new XMLBuilder(opts);
		return builder.build(xmlData);
	}
});

export const xmlIsValid = declareFunction({
	name: "XML_ISVALID",
	category: "xml",
	label: "Is valid XML",
	// eslint-disable-next-line max-len
	description: "Returns if a string is a valid XML document.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Document",
			description: "XML document as string",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.Object({
			label: "Options",
			description: "XML parser options",
			props: {
				allowBooleanAttributes: BP.Prop(BP.Boolean({
					label: "Allow Boolean Attributes",
					description: `To allow attributes without value.\n\nBy default boolean attributes are ignored`,
					default: true
				})),
				unpairedTags: BP.Prop(BP.Array({
					label: "Unpaired Tags",
					// eslint-disable-next-line max-len
					description: `Unpaired Tags are the tags which don't have matching closing tag. Eg <br> in HTML. You can parse unpaired tags by providing their list to the validator.`,
					items: BP.String({})
				}))
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {
		const xmlData = args[0]() as string;
		const opts: Partial<validationOptions> = {
			allowBooleanAttributes: true,
			...args[1]() as Partial<validationOptions>
		};

		return XMLValidator.validate(xmlData, opts);
	}
});
