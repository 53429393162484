/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaConstObject, ISchemaConstObjectOptsProp, Prop } from "./const/SchemaConstObject";
import { ISchemaValue } from "./value/SchemaValue";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { MEDIA_RESOLUTIONS, TMapMediaResolutions } from "../constants";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { IValidatorObjectOpts } from "../validators/ValidatorObject";

export type ISchemaResponsiveValueInternalSchema<TValueSchema extends TGenericBlueprintSchema> = ISchemaValue<
	ISchemaConstObject<TMapMediaResolutions<ISchemaConstObjectOptsProp<TValueSchema>>>
>;

type TSchemaResponsiveValueInternalModel<TValueSchema extends TGenericBlueprintSchema> =
	TGetBlueprintSchemaModel<ISchemaResponsiveValueInternalSchema<TValueSchema>>;

/**
 * Schema model
 */
export interface ISchemaResponsiveValueModel<TValueSchema extends TGenericBlueprintSchema>
	extends IModelNode<ISchemaResponsiveValue<TValueSchema>> {
	value: TSchemaResponsiveValueInternalModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaResponsiveValueOpts<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchemaOpts {
	value: TValueSchema;
	constraints?: IValidatorObjectOpts;
	default?: Partial<TGetBlueprintSchemaDefault<ISchemaResponsiveValueInternalSchema<TValueSchema>>>;
}

/**
 * Spec value
 */
export type TSchemaResponsiveValueSpec<TValueSchema extends TGenericBlueprintSchema> =
	TGetBlueprintSchemaSpec<ISchemaResponsiveValueInternalSchema<TValueSchema>>;

/**
 * Default value
 */
export type TSchemaResponsiveValueDefault<TValueSchema extends TGenericBlueprintSchema> =
	TGetBlueprintSchemaDefault<ISchemaResponsiveValueInternalSchema<TValueSchema>>;

/**
 * Schema type
 */
export interface ISchemaResponsiveValue<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchema<
		ISchemaResponsiveValueOpts<TValueSchema>,
		ISchemaResponsiveValueModel<TValueSchema>,
		TSchemaResponsiveValueSpec<TValueSchema>,
		TSchemaResponsiveValueDefault<TValueSchema>
	> {}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaResponsiveValue<TValueSchema extends TGenericBlueprintSchema>(
	opts: ISchemaResponsiveValueOpts<TValueSchema>
): ISchemaResponsiveValue<TValueSchema> {
	const internalSchema: ISchemaResponsiveValueInternalSchema<TValueSchema> = SchemaValueObject({
		label: opts.label,
		description: opts.description,
		constraints: opts.constraints,
		props: (() => {
			const props = {};

			for (const k in MEDIA_RESOLUTIONS) {
				props[k] = Prop(opts.value, MEDIA_RESOLUTIONS[k].order);
			}

			return props;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		})() as any,
		example: opts.example,
		tags: opts.tags
	});

	const schema = createEmptySchema<ISchemaResponsiveValue<TValueSchema>>("responsiveValue", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "value" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		internalModel: TSchemaResponsiveValueInternalModel<TValueSchema>,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, internalModel.validationErrors, {
			value: internalModel
		});

		const model = assignParentToChildrenOf(modelNode);

		model.initRequiredValid = internalModel.initRequiredValid;

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const _default = (defaultValue ?? opts.default) as TGetBlueprintSchemaDefault<
			ISchemaResponsiveValueInternalSchema<TValueSchema>
		>;
		const internalModel = internalSchema.createDefault(dCtx, null, _default);
		return createModel(dCtx, internalModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedValue = modelNode.value.schema.clone(dCtx, modelNode.value, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			value: clonedValue
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.value.schema.destroy(modelNode.value);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		const internalModel = internalSchema.parse(dCtx, idtNode, null);
		return createModel(dCtx, internalModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		if (internalSchema.provideCompletion) {
			internalSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return internalSchema.serialize(modelNode.value, path);
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		return modelNode.value.schema.render(rCtx, modelNode.value, path, scope, prevSpec);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		return modelNode.value.schema.compileRender(cCtx, modelNode.value, path);
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		return internalSchema.validate(rCtx, path, modelNodeId, value, validateChildren);
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		return internalSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaResponsiveValue", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return internalSchema.getTypeDescriptor(modelNode?.value);
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "value",
				node: modelNode.value
			}
		];
	};

	return schema;
}
