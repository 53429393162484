/**
 * Toast Messages
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { toNumber } from "@hexio_io/hae-lib-shared";

import {
	Button,
	BUTTON_STYLE,
	BUTTON_STYLE_string,
	ClassList,
	getStringEnumKeyByValue,
	Icon,
	ICON_NAME,
	ICON_SIZE_CLASS,
	makeCssTransition,
	useTranslate
} from "../..";

import { IToastMessage, IToastMessageActivityItem, TOAST_MESSAGE_TYPE } from "./ToastMessage";
import { useActivityContainer } from "./useActivityContainer";
import { IToastMessageManager } from "./ToastMessageManager";
import { ACTIVITY_ITEM_STATE } from "./IActivityItem";
import { termsRuntime } from "../../terms";
import { COMPONENT_MODE } from "@hexio_io/hae-lib-blueprint";

/**
 * Toast Messages props
 */
export interface IToastMessagesProps {
	/** Manager */
	manager: IToastMessageManager<IToastMessageActivityItem, IToastMessage>;
}

/**
 * Toast Messages component
 */
export const ToastMessages: React.FunctionComponent<IToastMessagesProps> = (props) => {
	const [ items, setItemAsReady, removeItem ] = useActivityContainer<IToastMessageActivityItem>(
		props.manager
	);

	return (
		<div className="toast-messages">
			{items.map((item) => {
				return (
					<ToastMessagesItem
						key={item.id}
						id={item.id}
						data={item.data}
						state={item.state}
						setAsReady={() => {
							setItemAsReady(item.id);
						}}
						remove={(removeFromState = false) => {
							removeItem(item.id, null, removeFromState);
						}}
					/>
				);
			})}
		</div>
	);
};

/** Default duration */
const DEFAULT_DURATION = 5000;

/**
 * Toast Messages Item props
 */
export interface IToastMessagesItemProps {
	/** Item id */
	id: string;

	/** Item data */
	data: IToastMessage;

	/** Item state */
	state: ACTIVITY_ITEM_STATE;

	/** Sets item as ready (state is set to default) */
	setAsReady: () => void;

	/** Remove function */
	remove: (removeFromState?: boolean) => void;
}

/**
 * Toast Messages Item component
 */
export const ToastMessagesItem: React.FunctionComponent<IToastMessagesItemProps> = (props) => {
	const { id, data, state, setAsReady, remove } = props;

	const elementRef = React.useRef<HTMLDivElement>();

	const t = useTranslate();

	const componentPath = [ "toast-messages", id ];

	const classList = new ClassList(
		"toast-messages__item",
		`toast-messages__item--id-${id}`,
		`toast-messages__item--state-${state}`,
		`toast-messages__item--type-${data.type}`
	);

	const added = state === ACTIVITY_ITEM_STATE.ADDED;
	const removed = state === ACTIVITY_ITEM_STATE.REMOVED;

	// In case when Item changes state (was just added or is to be removed)
	React.useEffect(() => {
		if (!added && !removed) {
			return;
		}

		const computedStyle = getComputedStyle(elementRef.current);

		const height = toNumber(computedStyle.height);

		if (added) {
			makeCssTransition(
				elementRef.current,
				{
					marginBottom: `-${height}px`,
					opacity: "0",
					position: "absolute",
					top: "100%"
				},
				{
					marginBottom: "0",
					opacity: "1"
				},
				"margin-bottom, opacity",
				"--element-transition-duration",
				setAsReady
			);
		} else if (removed) {
			makeCssTransition(
				elementRef.current,
				{
					height: `${height}px`
				},
				{
					height: "0",
					opacity: "0",
					paddingTop: "0"
				},
				"height, opacity, padding-top",
				"--element-transition-duration",
				() => {
					remove(true);
				}
			);
		}
	}, [ state ]);

	React.useEffect(() => {
		const duration = data.duration
			? data.duration * 1000
			: data.type === TOAST_MESSAGE_TYPE.SUCCESS
			? DEFAULT_DURATION
			: 0;

		if (duration) {
			setTimeout(() => {
				remove();
			}, duration);
		}
	}, []);

	const style: React.CSSProperties = {};

	if (added) {
		style.opacity = "0";
		style.position = "absolute";
		style.top = "100%";
	}

	let iconName: ICON_NAME;

	if (data.iconName) {
		iconName = data.iconName;
	} else {
		switch (data.type) {
			case TOAST_MESSAGE_TYPE.INFO:
				iconName = ICON_NAME.INFO_2;
				break;

			case TOAST_MESSAGE_TYPE.SUCCESS:
				iconName = ICON_NAME.SUCCESS_2;
				break;

			case TOAST_MESSAGE_TYPE.ERROR:
				iconName = ICON_NAME.ERROR_2;
				break;

			case TOAST_MESSAGE_TYPE.WARNING:
				iconName = ICON_NAME.WARNING_2;
				break;
		}
	}

	function _hideClickHandler() {
		remove();
	}

	return (
		<div ref={elementRef} className={classList.toClassName()} style={style}>
			<div className="toast-messages__item-box">
				<div className="toast-messages__item-inner">
					<Icon
						source={iconName}
						size={getStringEnumKeyByValue(ICON_SIZE_CLASS, ICON_SIZE_CLASS.MEDIUM)}
						componentPath={[ ...componentPath, "icon" ]}
						componentMode={COMPONENT_MODE.NORMAL}
					/>

					<div className="toast-messages__item-content">
						{data.message ? (
							<div className="toast-messages__item-message">{t("runtime", data.message)}</div>
						) : null}

						{data.details ? (
							<div className="toast-messages__item-details">{t("runtime", data.details)}</div>
						) : null}
					</div>
				</div>

				<Button
					style={getStringEnumKeyByValue(BUTTON_STYLE, BUTTON_STYLE.CLEAR)}
					labelIcon={{ source: ICON_NAME.CLOSE }}
					componentPath={[ ...componentPath, "hide-button" ]}
					componentMode={COMPONENT_MODE.NORMAL}
					classList={new ClassList("toast-messages__item-hide-button")}
					aria-label={t("runtime", termsRuntime.ui.toastMessages.hide)}
					onClick={_hideClickHandler}
				/>
			</div>
		</div>
	);
};
