/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME, SCOPE_KEY_INDEX_PROP_NAME } from "../constants";
import { RuntimeContext, RUNTIME_CONTEXT_MODE } from "../Context/RuntimeContext";
import { TTypeDesc, TypeDescAny } from "../Shared/ITypeDescriptor";
import {
	ISchemaConstObjectModel,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec
} from "../schemas/const/SchemaConstObject";
import { dataEqual } from "../Shared/Equal";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IScope } from "../Shared/Scope";
import {
	IDataSourceDefinition,
	IDataSourceInfo,
	IDataSourceStateBase,
	TDataSourceUpdateStateFunction,
	TDataSourceUpdateStateHandlerFunction
} from "./IDataSourceDefinition";
import { IDataSourceInstance } from "./IDataSourceInstance";
import { IEventDefinitionMap, TEventTriggerMap } from "../Events/EventTypes";
import { createEventTrigger } from "../Events/EventHandlers";

const DEBUG_LOG_CHANGES = false;

/**
 * Data source data provided to scope
 */
export interface IDataSourceScopeData {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}

/**
 * DataSources's resolve function
 *
 * Function takes current opts, current state, and returns a new state
 */
export type TDataSourceResolveFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase,
	TDataSourceEvents extends IEventDefinitionMap
> = (
	/** Current opts */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Function to update data source state asynchronously */
	updateStateAsync: TDataSourceUpdateStateFunction<TDataSourceState>,
	/** Data source instance */
	dsInstance: IDataSourceInstance<TDataSourceOpts, TDataSourceState, TDataSourceEvents>,
	/** Runtime context instance */
	rCtx: RuntimeContext,
	/** Current scope */
	scope: IScope
) => TDataSourceState;

/**
 * Function to return scope data based on spec and state
 */
export type TDataSourceGetScopeDataFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState
) => IDataSourceScopeData;

/**
 * Function to return scope data based on spec and state
 */
export type TDataSourceGetScopeTypeFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Opts model (available only in editor mode) */
	optsModel?: ISchemaConstObjectModel<TDataSourceOpts>
) => TTypeDesc;

/**
 * DataSource's destroy function
 *
 * Function takes current spec, current state
 */
export type TDataSourceDestroyFunction<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase
> = (
	/** Current specification */
	opts: TSchemaConstObjectPropsSpec<TDataSourceOpts>,
	/** Current state */
	state: TDataSourceState,
	/** Runtime context instance */
	rCtx: RuntimeContext
) => void;

/**
 * Elementary Data Source Definition
 */
export interface IElementaryDataSourceDefinition<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase,
	TDataSourceEvents extends IEventDefinitionMap
> extends IDataSourceInfo<TDataSourceOpts, TDataSourceEvents> {
	/** Resolves state */
	resolve: TDataSourceResolveFunction<TDataSourceOpts, TDataSourceState, TDataSourceEvents>;

	/** Returns scope data for a data source */
	getScopeData: TDataSourceGetScopeDataFunction<TDataSourceOpts, TDataSourceState>;

	/** Returns type of scope data for a data source */
	getScopeType: TDataSourceGetScopeTypeFunction<TDataSourceOpts, TDataSourceState>;

	/** Handles destroy process */
	destroy?: TDataSourceDestroyFunction<TDataSourceOpts, TDataSourceState>;
}

/**
 * Factory function to create elementary data source definition with lifecycle handling
 *
 * @param dataSourceDef Data Source definition
 * @returns
 */
export function defineElementaryDataSource<
	TDataSourceOpts extends TSchemaConstObjectProps,
	TDataSourceState extends IDataSourceStateBase,
	TDataSourceEvents extends IEventDefinitionMap
>(
	dataSourceDef: IElementaryDataSourceDefinition<TDataSourceOpts, TDataSourceState, TDataSourceEvents>
): IDataSourceDefinition<TDataSourceOpts, TDataSourceState, TDataSourceEvents> {
	return {
		name: dataSourceDef.name,
		label: dataSourceDef.label,
		description: dataSourceDef.description,
		icon: dataSourceDef.icon,
		order: dataSourceDef.order,
		docUrl: dataSourceDef.docUrl,
		opts: dataSourceDef.opts,
		events: dataSourceDef.events,

		render: (rCtx, spec, path, scope, prevInstance) => {
			// Get prev instance
			const dsInstance = (
				prevInstance && prevInstance instanceof Object && prevInstance.type === dataSourceDef.name
					? prevInstance
					: {
						[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.DATASOURCE_INSTANCE,
						type: dataSourceDef.name,
						id: spec.id,
						path: path,
						opts: spec.opts,
						eventTriggers: null,
						eventEnabled: spec.eventsEnabled,
						lastScopeDataTypeDescriptor: TypeDescAny({}),
						setState: (
							newState:
								| TDataSourceState
								| TDataSourceUpdateStateHandlerFunction<TDataSourceState>
						) => {
							if (newState instanceof Function) {
								dsInstance.state = newState(dsInstance.state);
							} else {
								dsInstance.state = newState;
							}

							dsInstance.customData.stateManuallyChanged = true;
							rCtx.__invalidate(dsInstance.path, true);
						},
						customData: {
							wasModified: false,
							destroyed: false,
							stateManuallyChanged: false
						}
					}
			) as IDataSourceInstance<TDataSourceOpts, TDataSourceState, TDataSourceEvents>;

			// Validate ID
			if (scope.localData[SCOPE_KEY_INDEX_PROP_NAME].has(spec.id)) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.DUPLICATE_SCOPE_ID,
					message: `Duplicate identifier '${spec.id}'.`,
					modelPath: path,
					modelNodeId: dsInstance.modelNodeId
				});

				spec.id += "_dup_" + path.join("_");
			}

			scope.localData[SCOPE_KEY_INDEX_PROP_NAME].add(spec.id);

			// Update props
			dsInstance.id = spec.id;
			dsInstance.path = path;
			dsInstance.opts = spec.opts;
			dsInstance.modelNode = spec.modelNode;
			dsInstance.eventsSpec = spec.events;
			dsInstance.eventEnabled = spec.eventsEnabled;

			// Assign debug info
			if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
				dsInstance.debug = {
					scope: scope
				};
			}

			// Create event triggers if not exist yet
			if (!dsInstance.eventTriggers) {
				dsInstance.eventTriggers = {} as TEventTriggerMap<TDataSourceEvents>;

				const getEventSpec = (eventName: string) => dsInstance.eventsSpec[eventName];

				for (const k in dsInstance.eventsSpec) {
					dsInstance.eventTriggers[k as keyof TDataSourceEvents] = createEventTrigger(
						rCtx,
						// Resolver name, unfortunate naming, originally was meant only for components,
						// now its used for data source events as well
						"componentEvent",
						getEventSpec,
						k,
						dsInstance.id,
						dsInstance.path,
						dsInstance.modelNodeId,
						dsInstance,
						dsInstance.modelNode?.events.props[k]?.props.nodes.template
					);
				}
			}

			if (!spec.hasErrors && (!spec.isLoading || (spec.isLoading && spec.isLoadingWithData))) {
				const newState = dataSourceDef.resolve(
					spec.opts,
					dsInstance.state,
					dsInstance.setState,
					dsInstance,
					rCtx,
					scope
				);

				if (dsInstance.customData.stateManuallyChanged || !dataEqual(dsInstance.state, newState)) {
					if (DEBUG_LOG_CHANGES) {
						console.log("Change: State", dsInstance.path, dsInstance.state, newState);
					}
					dsInstance.state = newState ? newState : dsInstance.state;
					dsInstance.customData.stateManuallyChanged = false;
				}
			}

			// Update scope data
			const scopeData = dataSourceDef.getScopeData(spec.opts, dsInstance.state);
			const isScopeDataModified = !dataEqual(scope.localData[spec.id], scopeData);

			if (isScopeDataModified) {
				if (DEBUG_LOG_CHANGES) {
					console.log("Change: Scope data", dsInstance.path, scope.localData[spec.id], scopeData);
				}

				scope.localData[spec.id] = scopeData;
				scope.globalData[spec.id] = scopeData;

				if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
					const typeDef = {
						...dataSourceDef.getScopeType(
							spec.opts,
							dsInstance.state,
							spec.modelNode.opts as unknown as ISchemaConstObjectModel<TDataSourceOpts>
						),
						label: spec.id
					} as TTypeDesc;

					scope.localType.props[spec.id] = typeDef;
					scope.globalType.props[spec.id] = typeDef;
				}

				rCtx.__invalidate(dsInstance.path, false);
			}

			// Set entity live
			rCtx.__setEntityLive(dsInstance, () => {
				dsInstance.customData.destroyed = true;

				if (dataSourceDef.destroy) {
					dataSourceDef.destroy(spec.opts, dsInstance.state, rCtx);
				}
			});

			return dsInstance;
		}
	};
}
