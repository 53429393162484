/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const arrPushFunc = declareFunction({
	name: "ARR_PUSH",
	category: "array",
	label: "Array Push",
	description: "Adds element to the end of an array.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		})
	],
	argRestSchema: BP.Any({
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
	}),
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args, restArgs) => {
		const arr = (args[0]() || []).slice();

		for (let i = 0; i < restArgs.length; i++) {
			arr.push(restArgs[i]());
		}

		return arr;
	}
});

export const arrPopFunc = declareFunction({
	name: "ARR_REMOVE_ITEM",
	category: "array",
	label: "Array Remove Item",
	description: "Deletes specific item from an array.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Any({
			label: "Item to delete",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args) => {
		let arr = (args[0]() || []).slice();

		arr = arr.filter((item) => item !== args[1]());

		return arr;
	}
});

export const arrSpliceFunc = declareFunction({
	name: "ARR_SPLICE",
	category: "array",
	label: "Array Splice",
	description: "Removes number of elements at given index and optionaly adds new elements and the index.",
	argRequiredCount: 3,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Integer({
			label: "Index",
			constraints: {
				required: true,
				min: 0
			},
			fallbackValue: 0
		}),
		BP.Integer({
			label: "Delete count",
			constraints: {
				required: true,
				min: 0
			},
			fallbackValue: 0
		})
	],
	argRestSchema: BP.Any({
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
	}),
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args, restArgs) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = ((args[0]() || []) as any[]).slice();
		const index = args[1]() as number;
		const deleteCount = args[2]() as number;
		const addElements = [];

		for (let i = 0; i < restArgs.length; i++) {
			addElements.push(restArgs[i]());
		}

		arr.splice(index, deleteCount, ...addElements);

		return arr;
	}
});

export const arrSliceFunc = declareFunction({
	name: "ARR_SLICE",
	category: "array",
	label: "Array Slice",
	description: "Returns a portion of an array.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Array",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Integer({
			label: "Start Index",
			constraints: {
				required: true,
				min: 0
			},
			fallbackValue: 0
		}),
		BP.Integer({
			label: "End Index",
			constraints: {
				required: false,
				min: 0
			},
			fallbackValue: 0
		})
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args) => {
		const startIndex = args[1]() as number;
		const endIndex = args[2]() as number;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return ((args[0]() || []) as any[]).slice(startIndex, endIndex);
	}
});

export const arrSumFunc = declareFunction({
	name: "ARR_SUM",
	category: "array",
	label: "Array Sum",
	description: "Calculates sum of all array elements.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Array",
			constraints: {
				required: true
			},
			items: BP.Float({
				default: 0,
				fallbackValue: 0
			}),
			fallbackValue: []
		})
	],
	argRestSchema: null,
	returnType: Type.Float({}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]() || []) as any[];
		let sum = 0;

		for (let i = 0; i < arr.length; i++) {
			sum += arr[i];
		}

		return sum;
	}
});

export const arrMapFunc = declareFunction({
	name: "ARR_MAP",
	category: "array",
	label: "Array Map",
	// eslint-disable-next-line max-len
	description:
		"Returns second argument (as an expression) for each array element. Variable 'item' and 'index' are available in an expression.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Any({
			label: "Map expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		}),
		BP.String({
			label: "Item variable name",
			default: "item",
			fallbackValue: "item"
		}),
		BP.String({
			label: "Index variable name",
			default: "index",
			fallbackValue: "index"
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]() || []) as any[];
		const itemVarName = (args[2]() as string) ?? "item";
		const indexVarName = (args[3]() as string) ?? "index";

		return arr.map((item, index) =>
			args[1](
				{
					[itemVarName]: item,
					[indexVarName]: index
				},
				{
					[itemVarName]: Type.Any({}),
					[indexVarName]: Type.Integer({})
				}
			)
		);
	}
});

export const arrFilterFunc = declareFunction({
	name: "ARR_FILTER",
	category: "array",
	label: "Array Filter",
	description:
		// eslint-disable-next-line max-len
		"Returns only array element for which the second argument expression returns true. Variable 'item' and 'index' are available in an expression.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Boolean({
			label: "Filter Result",
			constraints: {
				required: true
			},
			fallbackValue: false
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]() || []) as any[];

		return arr.filter((item, index) =>
			args[1](
				{
					item: item,
					index: index
				},
				{
					item: Type.Any({}),
					index: Type.Integer({})
				}
			)
		);
	}
});

export const arrReduceFunc = declareFunction({
	name: "ARR_REDUCE",
	category: "array",
	label: "Array Reduce",
	description:
		// eslint-disable-next-line max-len
		"Reduces provided array by a calling a second argument (as an expression). Variable 'previousValue', 'currentValue' and 'currentIndex' are available in an expression.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Any({
			label: "Reduced value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		}),
		BP.Any({
			label: "Initial value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		}),
		BP.String({
			label: "Previous value variable name",
			default: "previousValue",
			fallbackValue: "previousValue"
		}),
		BP.String({
			label: "Current value variable name",
			default: "currentValue",
			fallbackValue: "currentValue"
		}),
		BP.String({
			label: "Current index variable name",
			default: "currentIndex",
			fallbackValue: "currentIndex"
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]() || []) as any[];
		const initialValue = args[2]();

		const prevValueVarName = (args[3]() as string) ?? "previousValue";
		const currValueVarName = (args[4]() as string) ?? "currentValue";
		const currIndexVarName = (args[5]() as string) ?? "currIndex";

		return arr.reduce((prevValue, currValue, currIndex) => {
			return args[1](
				{
					[prevValueVarName]: prevValue,
					[currValueVarName]: currValue,
					[currIndexVarName]: currIndex
				},
				{
					[prevValueVarName]: Type.Any({}),
					[currValueVarName]: Type.Any({}),
					[currIndexVarName]: Type.Integer({})
				}
			);
		}, initialValue);
	}
});

export const arrFindFunc = declareFunction({
	name: "ARR_FIND",
	category: "array",
	label: "Array Find",
	description:
		// eslint-disable-next-line max-len
		"Returns indexes of array element for which the second argument expression returns true. Variable 'item' and 'index' are available in the expression.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Stack",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Boolean({
			label: "Filter Result",
			constraints: {
				required: true
			},
			fallbackValue: false
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]() || []) as any[];
		const result = [];

		for (let i = 0; i < arr.length; i++) {
			if (
				args[1](
					{
						item: arr[i],
						index: i
					},
					{
						item: Type.Any({}),
						index: Type.Integer({})
					}
				)
			) {
				result.push(i);
			}
		}

		return result;
	}
});

export const includesFunc = declareFunction({
	name: "INCLUDES",
	category: "array",
	label: "Includes",
	description: "Returns true if an array includes a given item.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Array",
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			constraints: {
				required: true
			},
			fallbackValue: []
		}),
		BP.Any({
			label: "Item",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Boolean({}),
	render: (_rCtx, args) => {
		const arr = args[0]();

		if (Array.isArray(arr)) {
			return arr.includes(args[1]());
		} else {
			return false;
		}
	}
});

export const minArrFunc = declareFunction({
	name: "ARR_MIN",
	category: "array",
	label: "Minimal value",
	description: "Returns minimal value from array",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Array",
			items: BP.Float({
				default: 0,
				fallbackValue: 0
			}),
			constraints: {
				required: true
			},
			fallbackValue: []
		})
	],
	argRestSchema: null,
	returnType: Type.Float({}),
	render: (_rCtx, args) => {
		const arr = args[0]();

		if (Array.isArray(arr)) {
			return Math.min.apply(null, arr);
		} else {
			return false;
		}
	}
});

export const maxArrFunc = declareFunction({
	name: "ARR_MAX",
	category: "array",
	label: "Maximum value",
	description: "Returns maximum value from array",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Array",
			items: BP.Float({
				default: 0,
				fallbackValue: 0
			}),
			constraints: {
				required: true
			},
			fallbackValue: []
		})
	],
	argRestSchema: null,
	returnType: Type.Float({}),
	render: (_rCtx, args) => {
		const arr = args[0]();

		if (Array.isArray(arr)) {
			return Math.max.apply(null, arr);
		} else {
			return false;
		}
	}
});

export const arrConcatFunc = declareFunction({
	name: "ARR_CONCAT",
	category: "array",
	label: "Array Concat",
	description: "Concatenate multiple arrays into one",
	argRequiredCount: 1,
	argSchemas: [],
	argRestSchema: BP.Array({
		label: "Array",
		items: BP.Any({
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		}),
		constraints: {
			required: true
		},
		fallbackValue: []
	}),
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, _args, restArgs) => {
		const inputArrays = restArgs.map((x) => x());
		const baseArr = inputArrays.shift();

		return baseArr.concat(...inputArrays);
	}
});

export const arrSortFunc = declareFunction({
	name: "ARR_SORT",
	category: "array",
	label: "Array Sort",
	description: "Sort an array based on a predicate expression.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Array({
			label: "Array",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Integer({
			label: "Sort predicate",
			// eslint-disable-next-line max-len
			description: "This argument should be an expression that comapres two items in the array.\n\nVariables `a` and `b` are available in the expression.\n\n Return: `-1` for `a < b`, `0` for `a == b`, `1` for `a > b`.",
			constraints: {
				required: true
			},
			fallbackValue: 0
		}),
		BP.String({
			label: "Item A variable name",
			default: "a",
			fallbackValue: "a"
		}),
		BP.String({
			label: "Item B variable name",
			default: "b",
			fallbackValue: "b"
		}),
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const arr = (args[0]()) as any[];

		if (arr === null) {
			return null;
		}

		const itemAVarName = (args[2]() as string) ?? "a";
		const itemBVarName = (args[3]() as string) ?? "b";

		return arr.slice().sort((a, b) =>
			args[1](
				{
					[itemAVarName]: a,
					[itemBVarName]: b
				},
				{
					[itemAVarName]: Type.Any({}),
					[itemBVarName]: Type.Any({})
				}
			) as number
		);
	}
});

export const arrFlatFunc = declareFunction({
	name: "ARR_FLAT",
	category: "array",
	label: "Array Flat",
	description: "Returns a new array with all sub-array elements concatenated into it recursively up to the specified depth.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Array({
			label: "Array",
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: []
		}),
		BP.Integer({
			label: "Depth",
			default: 1,
			fallbackValue: 1
		})
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Any({}) ]
	}),
	render: (_rCtx, args) => {
		const arr = args[0]() as unknown[];
		const depth = args[1]() as number;

		return arr.flat(depth);
	}
});
