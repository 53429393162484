/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IComponentResolver, TGenericComponentDefinition } from "@hexio_io/hae-lib-blueprint";
import { TGenericHAEComponentDefinition } from "@hexio_io/hae-lib-components";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IComponentDefRegistry } from "../registries/IComponentDefRegistry";

/**
 * Component Resolver
 */
export class ComponentResolver implements IComponentResolver {
	/**
	 * Invalidate event - emitted when action list has changed (and param schemas must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 */
	public constructor(private componentRegistry: IComponentDefRegistry) {
		this.onInvalidate = createEventEmitter();
	}

	/**
	 * Returns component definition by name
	 */
	public getByName(name: string): TGenericHAEComponentDefinition {
		return this.componentRegistry.get(name);
	}

	/**
	 * Returns a map of registered components filtered by allowed categories
	 */
	public getFilteredList(allowedCategories: string[]): { [K: string]: TGenericComponentDefinition } {
		return this.componentRegistry.getFilteredItems(allowedCategories);
	}
}
