/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	importSchema,
	ISchemaConstObject,
	ISchemaValue,
	TSchemaConstObjectProps,
	loadCompiledModel
} from "@hexio_io/hae-lib-blueprint";
import { ISchemaImportExport } from "@hexio_io/hae-lib-blueprint";
import { IViewApiResponse, IViewInstance } from "@hexio_io/hae-lib-core";
import { IAppState_ViewInstances } from "../../shared/IAppState";
import { ApiApp } from "./ApiApp";

/**
 * Map of loaded view instances
 */
interface ViewRepositoryInstanceMap {
	[K: string]: IViewInstance;
}

/**
 * View Repository
 * Loads and caches view definitions.
 */
export class ViewRepository {
	/** Application API client */
	private api: ApiApp;

	/** Cache of loaded view instances */
	private viewInstances: ViewRepositoryInstanceMap = {};

	/** Cache of loading promises of view instance to avoid loading of a single view multiple times */
	private loadingPromises: Map<string, Promise<IViewApiResponse>> = new Map();

	/**
	 * Repository constructor
	 *
	 * @param apiApp ApiApp Instance
	 */
	public constructor(apiApp: ApiApp) {
		this.api = apiApp;
	}

	/**
	 * Creates a view instance
	 *
	 * @param params Params schema
	 * @param code View code
	 * @param isValid If the view is valid and fully loaded
	 */
	private createViewInstance(params: ISchemaImportExport, code: string, isValid: boolean): IViewInstance {
		let paramsSchema: IViewInstance["params"];
		let renderFn: IViewInstance["renderFn"];

		try {
			paramsSchema =
				importSchema<ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>>(params).constSchema
					.opts.props;
		} catch (err) {
			throw new Error("Failed to import view params schema: " + String(err));
		}

		try {
			renderFn = loadCompiledModel(code);
		} catch (err) {
			throw new Error("Failed to load view compiled model: " + String(err));
		}

		return {
			params: paramsSchema,
			renderFn: renderFn,
			isValid: isValid
		};
	}

	/**
	 * Loads view from API and stores the instance
	 *
	 * @param viewId View ID
	 */
	private async loadView(viewId: string): Promise<void> {
		try {
			let viewData: IViewApiResponse;

			if (this.loadingPromises.has(viewId)) {
				viewData = await this.loadingPromises.get(viewId);
			} else {
				const promise = this.api.getView(viewId);
				this.loadingPromises.set(viewId, promise);
				viewData = await promise;
				this.loadingPromises.delete(viewId);
			}

			const instance = this.createViewInstance(viewData.paramsSchema, viewData.code, viewData.isValid);
			this.viewInstances[viewId] = instance;

			if (window.APP_DEBUG) {
				console.debug("[ViewRepository] View '%s' loaded via API.", viewId);
			}
		} catch (err) {
			console.error("[ViewRepository] Failed to load view '%s' via API:", viewId, err);
		}
	}

	/**
	 * Returns view instance by ID
	 *
	 * @param viewId View ID
	 */
	public async getView(viewId: string): Promise<IViewInstance> {
		// Try to load if not in cache
		if (!this.viewInstances[viewId]) {
			await this.loadView(viewId);
		}

		return this.viewInstances[viewId] || null;
	}

	/**
	 * Injects app state
	 *
	 * @param state View instances state
	 */
	public injectState(state: IAppState_ViewInstances): void {
		for (const k in state) {
			try {
				this.viewInstances[k] = this.createViewInstance(
					state[k].params,
					state[k].code,
					state[k].isValid
				);

				if (window.APP_DEBUG) {
					console.debug("[ViewRepository] View '%s' injected from state.", k);
				}
			} catch (err) {
				console.error("[ViewRepository] Failed to load view '%s' from app state:", k, err);
			}
		}
	}

	/**
	 * Preloads views
	 */
	public preloadViews(): void {
		this.api.getPreloadViews()
			.then((views) => {
				views.forEach((viewData) => {
					const instance = this.createViewInstance(viewData.paramsSchema, viewData.code, viewData.isValid);
					this.viewInstances[viewData.viewId] = instance;
				});
			})
			.catch((err) => {
				console.error("[ViewRepository] Failed to preload views:", err);
			});
	}
}
