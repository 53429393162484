/**
 * hae-ext-integrations
 *
 * Hexio App Engine Integration extensions library.
 *
 * @package hae-ext-integrations
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IIntegrationFunctionDefinition, TIntegrationFunctionsDefinitions } from "./IIntegrationDefinition";

/**
 * Integration Function Decorator
 *
 * @param paramsModelProps Parameters as DataModel
 */
export function FunctionDecorator(functionDefinition: IIntegrationFunctionDefinition) {
	const { name } = functionDefinition || {};

	return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
		const functionName = propertyKey;
		const originalMethod = descriptor.value;

		const handler = async function (params, context) {
			return originalMethod.call(this, params, context);
		};

		if (!target.functionDefinitions) {
			target.functionDefinitions = {} as TIntegrationFunctionsDefinitions;
		}

		if (target.functionDefinitions[functionName]) {
			throw new Error(`Function name '${functionName}' already registered.`);
		}

		if (functionName !== name) {
			throw new Error(
				`Function's name '${functionName}' doesn't match function's definition name ${name}.`
			);
		}

		target.functionDefinitions[functionName] = functionDefinition;

		descriptor.value = handler;
	};
}
