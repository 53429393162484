/**
 * Grid HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, TSchemaConstObjectPropsSpec } from "@hexio_io/hae-lib-blueprint";

import {
	getValuesFromStringEnum,
	LoadingSchema,
	propGroups,
	SPACING,
	spacingPattern,
	SPACING_default,
	SPACING_none,
	termsEditor as HAELibComponentsTerms
} from "@hexio_io/hae-lib-components";
import { GRID_ROLE, GRID_ROLE_default } from "../../Enums/GRID_ROLE";

import { termsEditor } from "../../terms";

/**
 * Grid Item Inherited props
 */
export const GridItemInheritedProps = {
	gridItemPosition: BP.Prop(
		BP.ResponsiveValue({
			...termsEditor.schemas.grid.contentItemPosition,
			value: BP.Object({
				props: {
					x: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.grid.contentItemPositionX,
							constraints: {
								min: 0
							}
						}),
						10
					),
					y: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.grid.contentItemPositionY,
							constraints: {
								min: 0
							}
						}),
						20
					),
					width: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.grid.contentItemPositionWidth,
							constraints: {
								min: 1
							}
						}),
						30
					),
					height: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.grid.contentItemPositionHeight,
							constraints: {
								min: 1
							}
						}),
						40
					)
				}
			})
		}),
		10
	),

	gridItemSpacing: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.grid.contentItemSpacing,
			constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
			default: "", // Has to stay empty
			constraints: {
				...HAELibComponentsTerms.schemas.common.spacingConstraints,
				pattern: spacingPattern
			}
		}),
		20
	)
};

/**
 * Grid Item Inherited props type
 */
export type TGridItemInheritedProps = typeof GridItemInheritedProps;

/**
 * Grid props
 */
export const HAEComponentGrid_Props = {
	content: BP.Prop(
		BP.ComponentList({
			...termsEditor.schemas.grid.content,
			alias: "gridContent",
			inheritedProps: {
				...GridItemInheritedProps
			}
		}),
		0,
		propGroups.common
	),

	columns: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.grid.columns,
			default: 12,
			constraints: {
				min: 1,
				max: 24,
				required: true
			}
		}),
		100,
		propGroups.common
	),

	padding: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.grid.padding,
			constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
			default: SPACING_none,
			constraints: {
				...HAELibComponentsTerms.schemas.common.spacingConstraints,
				pattern: spacingPattern
			}
		}),
		100,
		propGroups.style
	),

	spacing: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.grid.spacing,
			constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
			default: SPACING_default,
			constraints: {
				...HAELibComponentsTerms.schemas.common.spacingConstraints,
				pattern: spacingPattern
			}
		}),
		110,
		propGroups.style
	),

	loading: BP.Prop(LoadingSchema(), 100, propGroups.advanced),

	role: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.grid.role,
			options: getValuesFromStringEnum(GRID_ROLE, termsEditor.schemas.grid.roleValues),
			default: GRID_ROLE_default,
			fallbackValue: GRID_ROLE_default,
			constraints: {
				required: true
			}
		}),
		110,
		propGroups.advanced
	)
};

/**
 * Grid props type
 */
export type THAEComponentGrid_Props = Partial<TSchemaConstObjectPropsSpec<typeof HAEComponentGrid_Props>>;
