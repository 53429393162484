/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const ENDPOINT = {
	REQUEST_BODY_SIZE_LIMIT: "5mb",
	INVOCATION: {
		/** 10 seconds */
		DEFAULT_TIMEOUT: 10 * 1000,
		/** 1 second */
		MIN_TIMEOUT: 1 * 1000,
		/** % minutes */
		MAX_TIMEOUT: 5 * 60 * 1000
	}
};
