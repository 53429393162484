/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, SCHEMA_CONST_ANY_VALUE_TYPE, Type } from "@hexio_io/hae-lib-blueprint";

export const andFunc = declareFunction({
	name: "AND",
	category: "logic",
	label: "And",
	description: "Returns true if all arguments are truthful.",
	argRequiredCount: 2,
	argSchemas: [],
	argRestSchema: BP.Any({
		label: "Expression",
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN,
		constraints: {
			required: true
		}
	}),
	returnType: Type.Boolean({}),
	render: (_rCtx, _args, restArgs): boolean => {
		for (let i = 0; i < restArgs.length; i++) {
			if (!restArgs[i]()) {
				return false;
			}
		}

		return true;
	}
});

export const orFunc = declareFunction({
	name: "OR",
	category: "logic",
	label: "Or",
	description: "Returns true if any of arguments is truthful.",
	argRequiredCount: 2,
	argSchemas: [],
	argRestSchema: BP.Any({
		label: "Expression",
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN,
		constraints: {
			required: true
		}
	}),
	returnType: Type.Boolean({}),
	render: (_rCtx, _args, restArgs) => {
		for (let i = 0; i < restArgs.length; i++) {
			if (restArgs[i]()) {
				return true;
			}
		}

		return false;
	}
});

export const ifFunc = declareFunction({
	name: "IF",
	category: "logic",
	label: "If",
	description:
		"If the first argument is truthful the second argument is returned, otherwise the third one.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Any({
			label: "Expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN,
			constraints: {
				required: true
			}
		}),
		BP.Any({
			label: "Then",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		}),
		BP.Any({
			label: "Else",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		return args[0]() ? args[1]() : args[2] ? args[2]() : null;
	}
});

export const switchFunc = declareFunction({
	name: "SWITCH",
	category: "logic",
	label: "Switch",
	description: "Switch. Example: SWITCH('a', 'a', 'HELLO', 'b' 'WORLD', 'NONE') -> HELLO",
	argRequiredCount: 2,
	argSchemas: [
		BP.Any({
			label: "Value",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			}
		})
	],
	argRestSchema: BP.Any({
		label: "Switch operands",
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
		constraints: {
			required: true
		}
	}),
	returnType: Type.Any({}),
	render: (_rCtx, args, restArgs) => {
		const leftOperand = args[0]();

		let defaultCase = null;

		if (restArgs.length % 2 === 1) {
			defaultCase = restArgs.pop();
		}

		const resultsCase = restArgs;

		for (let i = 0; i < resultsCase.length; i = i + 2) {
			if (leftOperand === resultsCase[i]()) {
				return resultsCase[i + 1]();
			}
		}

		return defaultCase ? defaultCase() : null;
	}
});
