/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	cmpDataEqual,
	createSubScope
} from "@hexio_io/hae-lib-blueprint";
import { dataEqual } from "@hexio_io/hae-lib-blueprint/src/Shared/Equal";
import { termsEditor } from "../terms";

interface DataSourceVariable_State {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialValue: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValueHandler: (value: any) => void;
}

const DataSourceVariable_Opts = {
	initialValue: BP.Prop(
		BP.Any({
			label: termsEditor.dataSources.variable.initialValue.label,
			description: termsEditor.dataSources.variable.initialValue.description,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		})
	)
};

const DataSourceVariable_Events = {
	change: {
		...termsEditor.dataSources.variable.events.change
	}
};

/**
 * Variable data source
 */
export const DataSourceVariable = defineElementaryDataSource<
	typeof DataSourceVariable_Opts,
	DataSourceVariable_State,
	typeof DataSourceVariable_Events
>({
	name: "variable",
	label: termsEditor.dataSources.variable.root.label,
	description: termsEditor.dataSources.variable.root.description,
	icon: "mdi/variable",
	opts: DataSourceVariable_Opts,
	events: DataSourceVariable_Events,
	resolve: (opts, prevState, updateStateAsync, dsInstance, rCtx) => {
		const state = prevState
			? prevState
			: ({
				initialValue: opts.initialValue,
				value: opts.initialValue,
				setValueHandler: (value) => {
					updateStateAsync((prevState) => ({ ...prevState, value: value }));
				}
			} as DataSourceVariable_State);

		const initialValue = opts.initialValue !== undefined ? opts.initialValue : state.initialValue;

		// Handle change event
		if (dsInstance.eventEnabled.change) {
			rCtx.__callAfterReconcile(() => {
				if (!cmpDataEqual(dsInstance.state.value, dsInstance.customData.lastValue)) {
					const isFirstRun = dsInstance.customData.lastValue === undefined;
					dsInstance.customData.lastValue = dsInstance.state.value;

					if (!isFirstRun) {
						dsInstance.eventTriggers.change((parentScope) => createSubScope(parentScope, {
							_value: dsInstance.state.value
						}, {
							_value: Type.Any({})
						}));
					}
				}
			});
		}

		return {
			...state,
			initialValue: opts.initialValue,
			value: dataEqual(state.initialValue, initialValue)
				? state.value !== undefined
					? state.value
					: opts.initialValue
				: opts.initialValue
		};
	},
	getScopeData: (_opts, state) => {
		return {
			initialValue: state.initialValue,
			value: state.value,
			setValue: state.setValueHandler
		};
	},
	getScopeType: () => {
		return Type.Object({
			props: {
				initialValue: Type.Any({
					label: termsEditor.dataSources.variable.initialValue.label,
					description: termsEditor.dataSources.variable.initialValue.description
				}),
				value: Type.Any({
					label: termsEditor.dataSources.variable.value.label,
					description: termsEditor.dataSources.variable.value.description
				}),
				setValue: Type.Method({
					label: termsEditor.dataSources.variable.setValue.label,
					description: termsEditor.dataSources.variable.setValue.description,
					argRequiredCount: 1,
					argSchemas: [
						BP.Any({
							label: termsEditor.dataSources.variable.setValueArg.label,
							description: termsEditor.dataSources.variable.setValueArg.description,
							defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
						})
					],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});
