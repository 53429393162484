/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintVariableNodeOptsSchemaSpec } from "../../blueprints";
import { createSuccessNodeResult, unknownNodeError } from "../helpers";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";

export async function variableNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintVariableNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	try {
		const data = opts["value"];

		aCtx.context.debug("Return variable value:", data);

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data,
				typeDescriptor: Type.Any({})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);
	}
}
