/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, Type } from "@hexio_io/hae-lib-blueprint";
import CryptoJS from "crypto-js";

const outputFormatSchema = BP.Enum.String({
	label: "Format",
	options: [
		{
			value: "hex",
			label: "Hex"
		},
		{
			value: "base64",
			label: "Base64"
		}
	],
	default: "hex",
	fallbackValue: "hex"
});

function formatWordArray(arr: CryptoJS.lib.WordArray, format: string): string {
	switch(format) {
		case "hex":
			return CryptoJS.enc.Hex.stringify(arr);
		case "base64":
			return CryptoJS.enc.Base64.stringify(arr);
		default:
			throw new Error(`Unknown output format: ${format}`);
	}
}

export const cryptoSha256HashFunc = declareFunction({
	name: "SHA256",
	category: "crypto",
	label: "SHA256 Hash",
	description: "Computes SHA256 hash of the given string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "String"
		}),
		outputFormatSchema
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		const format = args[1]() ?? "hex";

		const hash = CryptoJS.SHA256(value);

		return formatWordArray(hash, format);
	}
});

export const cryptoSha256HmacFunc = declareFunction({
	name: "HMAC_SHA256",
	category: "crypto",
	label: "HMAC SHA256",
	description: "Computes HMAC signed SHA256 hash of the given string.",
	argRequiredCount: 2,
	argSchemas: [
		BP.String({
			label: "String"
		}),
		BP.String({
			label: "Key"
		}),
		outputFormatSchema
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		const key = args[1]();
		const format = args[2]() ?? "hex";

		const hash = CryptoJS.HmacSHA256(value, key);

		return formatWordArray(hash, format);
	}
});

export const cryptoMd5HashFunc = declareFunction({
	name: "MD5",
	category: "crypto",
	label: "MD5 Hash",
	description: "Computes MD5 hash of the given string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "String"
		}),
		outputFormatSchema
	],
	argRestSchema: null,
	returnType: Type.String({}),
	render: (_rCtx, args) => {
		const value = args[0]();
		const format = args[1]() ?? "hex";

		const hash = CryptoJS.MD5(value);

		return formatWordArray(hash, format);
	}
});