/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ITypeDescObject, TTypeDesc, TypeDescObject } from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, emitEvent, removeAllEventListeners } from "@hexio_io/hae-lib-shared";
import { IBaseGlobals } from "./IBaseGlobals";

/**
 * Manages global values
 */
export class GlobalsContainer<TGlobals extends IBaseGlobals> {
	/** Stored data */
	private data: TGlobals = {} as TGlobals;

	/** Stored type descriptor */
	private typeDesc: ITypeDescObject = TypeDescObject({ props: {} });

	/** On change event */
	public onChange = createEventEmitter<void>();

	/**
	 * Globals constructor
	 *
	 * @param defaultData Default data
	 */
	public constructor(defaultData?: Partial<TGlobals>) {
		if (defaultData !== undefined) {
			this.data = defaultData as TGlobals;
		}
	}

	/**
	 * Sets the value
	 *
	 * @param key Key
	 * @param value Value
	 * @param typeDesc Value Type Descriptor
	 */
	public set<TKey extends keyof TGlobals>(key: TKey, value: TGlobals[TKey], typeDesc?: TTypeDesc): void {
		this.data[key] = value;

		if (typeDesc) {
			this.typeDesc.props[key as string] = typeDesc;
		}

		emitEvent(this.onChange);
	}

	/**
	 * Gets the value
	 *
	 * @param key Key
	 */
	public get<TKey extends keyof TGlobals>(key: TKey): TGlobals[TKey] {
		return this.data[key];
	}

	/**
	 * Returns stored data
	 */
	public getData(): TGlobals {
		return this.data;
	}

	/**
	 * Returns data type descriptor
	 */
	public getType(): ITypeDescObject {
		return this.typeDesc;
	}

	/**
	 * Destroys the container
	 */
	public destroy(): void {
		removeAllEventListeners(this.onChange);
	}
}
