/*
 * Hexio App Engine Editor
 *
 * @package hae-editor
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useEffect } from "react";
import { IThemeProvider } from "./IThemeProvider";
import { isBrowser, offEvent, onEvent } from "@hexio_io/hae-lib-shared";

export interface IStylingThemeProps {
	themeProvider: IThemeProvider;
	children?: React.ReactNode;
}

export const StylingTheme: React.FC<IStylingThemeProps> = ({ children, themeProvider }) => {
	const handleThemeChange = useCallback(() => {
		if (!themeProvider || !isBrowser()) {
			return;
		}

		const themeCssUrl = themeProvider.getThemeCssUrl();

		if (!themeCssUrl) {
			return;
		}

		let linkEl = document?.getElementById("theme-css");

		if (!linkEl) {
			linkEl = document?.createElement("link");
			linkEl.setAttribute("id", "theme-css");
			linkEl.setAttribute("rel", "stylesheet");
			linkEl.setAttribute("type", "text/css");

			const head = document.getElementsByTagName("head")[0];
			head.appendChild(linkEl);
		}

		linkEl.setAttribute("href", themeCssUrl);
	}, []);

	useEffect(() => {
		if (!themeProvider) {
			return;
		}

		handleThemeChange();

		onEvent(themeProvider.onChange, handleThemeChange);

		return () => {
			offEvent(themeProvider.onChange, handleThemeChange);
		};
	}, []);

	return <>{children}</>;
};
