/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum RESOURCE_ERROR_NAMES {
	RESOURCE_COMPILATION_ERROR = "RESOURCE_COMPILATION_ERROR",
	RESOURCE_INVALID_CONFIG = "RESOURCE_INVALID_CONFIG",
	RESOURCE_UNSUPPORTED_TYPE = "RESOURCE_UNSUPPORTED_TYPE",
	ENDPOINT_INVALID_JSON_SCHEMA = "ENDPOINT_INVALID_JSON_SCHEMA",
	ENDPOINT_INVALID_PATH = "ENDPOINT_INVALID_PATH",
	ENDPOINT_INVALID_MEDIA_TYPE = "ENDPOINT_INVALID_MEDIA_TYPE"
}
