/**
 * Table functions
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericComponentInstance } from "@hexio_io/hae-lib-blueprint";

import { IAllowedResizeDimensions } from "@hexio_io/hae-lib-components";

/**
 * Resolves allow resize
 */
export function resolveAllowResize(
	componentInstance: TGenericComponentInstance<null>
): IAllowedResizeDimensions {
	if (!componentInstance.modelNode) {
		return null;
	}

	return {
		left: false,
		top: false,
		width: false,
		height: false
	};
}
