/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Hexio App Engine Shared library.
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import lodashCloneDeep from "lodash.clonedeep";

export function cloneDeepWithDataLoss(data: any): any {
	return JSON.parse(JSON.stringify(data));
}

export function cloneDeep(data: any): any {
	return lodashCloneDeep(data);
}
