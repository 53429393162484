/**
 * Link React component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { isBoolean, isNonEmptyString } from "@hexio_io/hae-lib-shared";

import {
	ClassList,
	getStringEnumValue,
	IAriaProps,
	LINK_TARGET,
	LINK_TARGET_default,
	RoutingContext
} from "../";
import { resolveAriaProps } from "../Functions/ariaHelpers";

import { TLinkProps } from "../SharedSchemas/Link";
import { TElementProps } from "../Types/TElementProps";
import { COMPONENT_MODE } from "@hexio_io/hae-lib-blueprint";

/**
 * Link component props
 */
export interface ILinkProps extends IAriaProps, TLinkProps {
	/** Link path */
	href?: string;

	/** External link */
	external?: boolean;

	/** Component mode, not used yet */
	componentMode?: COMPONENT_MODE;

	/** Class list conficugration */
	classList?: ClassList;

	/** Custom style */
	inlineStyle?: React.CSSProperties;

	/** Link rel attribute */
	rel?: string;

	/** Tab index */
	tabIndex?: number;

	/** Title */
	htmlTitle?: string;

	/** Children */
	children?: unknown;

	/** Forward ref */
	forwardRef?: React.MutableRefObject<HTMLAnchorElement | HTMLSpanElement>;

	/** On Click handler */
	onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement, MouseEvent>) => void;

	/** On Mouse Enter handler */
	onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement, MouseEvent>) => void;

	/** On Mouse Leave handler */
	onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement, MouseEvent>) => void;
}

/**
 * Link component
 */
export const Link: React.FunctionComponent<ILinkProps> = (props) => {
	const {
		enabled = true,
		inlineStyle,
		rel,
		tabIndex,
		htmlTitle,
		children,
		forwardRef,
		onClick,
		onMouseEnter,
		onMouseLeave
	} = props;

	const routingContext = React.useContext(RoutingContext);

	let elementType = "a";

	const elementProps: TElementProps = {
		style: inlineStyle,
		rel,
		tabIndex,
		title: htmlTitle,
		children,
		ref: forwardRef,
		onMouseEnter,
		onMouseLeave,
		...resolveAriaProps(props)
	};

	let external = false;

	if (enabled) {
		// Primitive (direct) configuration, link resolved in parent component
		if (props.href) {
			elementProps.href = props.href;

			if (isBoolean(props.external)) {
				external = props.external;
			}
		}
		// Link schema props
		else if (props.locationData) {
			const resolvedLink = routingContext.resolveLink(props.locationData, {
				exact: isBoolean(props.exact) ? props.exact : false
			});

			elementProps.href = resolvedLink.url;

			external = resolvedLink.isExternal;
		}
	}

	if (isNonEmptyString(elementProps.href)) {
		if (props.target) {
			elementProps.target = getStringEnumValue(LINK_TARGET, props.target, LINK_TARGET_default);
		}
	} else {
		elementType = "span";
	}

	if (props.classList) {
		elementProps.className = props.classList.toClassName();
	}

	elementProps.onClick = React.useCallback(
		(event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement, MouseEvent>) => {
			if (!external) {
				event.preventDefault();
			}

			if (typeof onClick === "function") {
				onClick(event);
			}

			if (!external && typeof elementProps.href === "string") {
				routingContext.navigate(elementProps.href);
			}
		},
		[ elementProps.href, external, onClick ]
	);

	return React.createElement(elementType, elementProps);
};
