/**
 * Icon React component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { isValidObject } from "@hexio_io/hae-lib-shared";

import { ClassList, StyleSheet, MediaContext, TIconProps } from "../";

import { ICON_SIZE_CLASS, ICON_SIZE_CLASS_string, ICON_SIZE_CLASS_default } from "../Enums/ICON_SIZE_CLASS";

import { SvgImage } from "./SvgImage";
import { getStringEnumValue } from "../Functions/enumHelpers";
import { FORCE_RENDER_default, IBaseProps, IRenderProps } from "./props";
import { icons } from "./icons";
import { ICON_SOURCE_FALLBACK_VALUE } from "../SharedSchemas/Icon";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";

/**
 * Icon props
 */
export interface IIconProps extends IBaseProps, IRenderProps, TIconProps {
	/** Size */
	size?: ICON_SIZE_CLASS_string;

	/** Tooltip */
	tooltip?: string;
}

/**
 * Icon component
 */
export const Icon: React.FunctionComponent<IIconProps> = (props) => {
	const { foregroundColor, size, tooltip, componentPath, forceRender = FORCE_RENDER_default } = props;

	const mediaContext = React.useContext(MediaContext);

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName("icon", componentPath, {
		componentClassList: props.classList
	});

	classList.add(getStringEnumValue(ICON_SIZE_CLASS, size, ICON_SIZE_CLASS_default));

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Foreground color
		if (foregroundColor) {
			result.addColorProperties({
				selector: `.${idClassName}`,
				name: "element-foreground-color",
				value: foregroundColor
			});
		}

		return result;
	}, [ idClassName, foregroundColor ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	const { source = forceRender ? ICON_SOURCE_FALLBACK_VALUE : "" } = props;

	if (!source) {
		return null;
	}

	const inline = icons[source];

	let content = null;

	if (!inline) {
		const [ context, name ] = source.split("/");

		if (
			!isValidObject(mediaContext.iconPackageUrlMapping) ||
			// eslint-disable-next-line no-prototype-builtins
			!(mediaContext.iconPackageUrlMapping as Record<string, unknown>).hasOwnProperty(context)
		) {
			return null;
		}

		const path = `${mediaContext.iconPackageUrlMapping[context]}/${name}.svg`;

		content = (
			<span className="icon__content">
				<SvgImage source={path} />
			</span>
		);
	} else {
		content = <span className="icon__content">{inline}</span>;
	}

	return (
		<span className={classList.toClassName()} title={tooltip}>
			{content}
		</span>
	);
};
