/**
 * Spacer HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	THAEComponentDefinition,
	THAEComponentReact,
	termsEditor as HAELibComponentsTerms,
	propGroups,
	ForegroundColorSchema,
	StyleSheet,
	mapResponsiveValue,
	dimensionPattern,
	useStyleSheetRegistry,
	HAEComponentMainContext,
	useComponentMainContext,
	useEditContext,
	getMedia,
	BorderRadiusSchema
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";

interface HAEComponentSpacer_State {}

/**
 * Spacer props
 */
const HAEComponentSpacer_Props = {
	foregroundColor: BP.Prop(
		ForegroundColorSchema({
			alias: "foregroundColor_spacer",
			default: "TRANSPARENT"
		}),
		0,
		propGroups.style
	),

	borderRadius: BP.Prop(BorderRadiusSchema(), 10, propGroups.style),

	width: BP.Prop(
		BP.ResponsiveValue({
			...HAELibComponentsTerms.schemas.common.width,
			value: BP.String({
				default: "",
				constraints: {
					...HAELibComponentsTerms.schemas.common.dimensionConstraints,
					pattern: dimensionPattern
				}
			})
		}),
		20,
		propGroups.style
	),

	height: BP.Prop(
		BP.ResponsiveValue({
			...HAELibComponentsTerms.schemas.common.height,
			value: BP.String({
				default: "",
				constraints: {
					...HAELibComponentsTerms.schemas.common.dimensionConstraints,
					pattern: dimensionPattern
				}
			})
		}),
		30,
		propGroups.style
	)
};

const HAEComponentSpacer_Events = {};

const HAEComponentSpacer_Definition = defineElementaryComponent<
	typeof HAEComponentSpacer_Props,
	HAEComponentSpacer_State,
	typeof HAEComponentSpacer_Events
>({
	...termsEditor.components.spacer.component,

	name: "spacer",

	category: "content",

	icon: "mdi/square",

	docUrl: "...",

	order: 200,

	props: HAEComponentSpacer_Props,

	events: HAEComponentSpacer_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentSpacer_React: THAEComponentReact<typeof HAEComponentSpacer_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { foregroundColor, borderRadius, width, height } = props;

	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editContext = useEditContext();
	const media = getMedia(inEditor ? editContext : undefined);

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-spacer",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Foreground color

		if (foregroundColor) {
			result.addColorProperties({
				selector: `.${idClassName} > .cmp-spacer__inner`,
				name: "element-foreground-color",
				value: foregroundColor
			});
		}

		// Border radius

		if (borderRadius) {
			result.addString(
				`.${idClassName} > .cmp-spacer__inner`,
				`--element-border-radius: ${borderRadius} !important;`
			);
		}

		// Width

		if (width) {
			const widthValue = mapResponsiveValue(width);

			result.addResponsiveValue(widthValue, `.${idClassName} > .cmp-spacer__inner`, "max-width", media);
		}

		// Height

		if (height) {
			const heightValue = mapResponsiveValue(height);

			result.addResponsiveValue(
				heightValue,
				`.${idClassName} > .cmp-spacer__inner`,
				"max-height",
				media
			);
		}

		return result;
	}, [ idClassName, foregroundColor, borderRadius, width, height, media ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	return (
		<div className={classList.toClassName()}>
			<div className="cmp-spacer__inner" />
		</div>
	);
};

export const HAEComponentSpacer: THAEComponentDefinition<typeof HAEComponentSpacer_Definition> = {
	...HAEComponentSpacer_Definition,
	reactComponent: HAEComponentSpacer_React
};
