/**
 * PropertyItemAdditionalText HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { ClassList, ITextProps, Text } from "@hexio_io/hae-lib-components";
import { COMPONENT_MODE, TComponentNodePath } from "@hexio_io/hae-lib-blueprint";

export interface PropertyItemAdditionalTextProps {
	text?: Partial<ITextProps>;
	componentPath: TComponentNodePath;
	componentMode: COMPONENT_MODE;
	classList?: ClassList;
	width?: string;
}

export const PropertyItemAdditionalText: React.FC<PropertyItemAdditionalTextProps> = React.memo(
	({ classList, text, width, componentPath, componentMode }) => {
		return text?.value ? (
			<div className={classList.toClassName()} style={width ? { width } : {}}>
				<Text {...text} componentPath={[ ...componentPath, "text" ]} componentMode={componentMode} />
			</div>
		) : null;
	}
);

PropertyItemAdditionalText.defaultProps = {
	classList: new ClassList()
};
