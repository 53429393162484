/**
 * Tree Navigation HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	IModelNodeInfo,
	ISchemaConstObject,
	ISchemaRecursiveModel,
	ISchemaValue,
	SCHEMA_VALUE_TYPE,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaModel,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";

import {
	ENUM_DEFAULT_VALUE,
	getValuesFromStringEnum,
	IconSchema,
	LinkSchema,
	propGroups,
	TextProps,
	TextSchema,
	TextValueSchema
} from "@hexio_io/hae-lib-components";
import { TREE_NAVIGATION_ITEM_EXPAND } from "../../Enums/TREE_NAVIGATION_ITEM_EXPAND";
import { TREE_NAVIGATION_STYLE, TREE_NAVIGATION_STYLE_default } from "../../Enums/TREE_NAVIGATION_STYLE";

import { termsEditor } from "../../terms";

/**
 * Tree Navigation Item base props
 */
export const ItemBaseProps = {
	labelText: BP.Prop(
		TextSchema({
			...termsEditor.schemas.treeNavigation.itemLabelText,
			alias: "text_treeNavigationItemLabel",
			props: {
				value: BP.Prop(
					TextValueSchema({
						alias: "textValue_treeNavigationItemLabel",
						default: "Item"
					}),
					TextProps.value.order,
					TextProps.value.group
				),

				align: BP.Prop(BP.Void({})),

				style: BP.Prop(BP.Void({})),

				fontSize: BP.Prop(BP.Void({})),

				overflow: BP.Prop(BP.Void({}))
			}
		}),
		0
	),

	labelIcon: BP.Prop(
		IconSchema({
			alias: "icon_treeNavigationItemLabel",
			props: {
				size: BP.Prop(BP.Void({}))
			}
		}),
		10
	),

	link: BP.Prop(
		LinkSchema({
			constraints: {
				required: true
			}
		}),
		20
	),

	expand: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.treeNavigation.itemExpand,
			options: getValuesFromStringEnum(
				TREE_NAVIGATION_ITEM_EXPAND,
				termsEditor.schemas.treeNavigation.itemExpandValues
			),
			default: ENUM_DEFAULT_VALUE
		}),
		30
	),

	visible: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.treeNavigation.itemVisible,
			default: true,
			fallbackValue: true,
			constraints: {
				required: false
			}
		}),
		40
	)
};

/**
 * Tree Navigation Item base props type
 */
export type TItemBaseProps = Partial<TSchemaConstObjectPropsSpec<typeof ItemBaseProps>>;

/**
 * Returns label for a navigation item element
 *
 * @param modelNode Model node
 * @param index Index
 */
const getElementModelNodeInfo = (
	modelNode: ISchemaRecursiveModel<TGenericBlueprintSchema>,
	index: number
): IModelNodeInfo => {
	const itemModel = modelNode.value as TGetBlueprintSchemaModel<
		ISchemaValue<ISchemaConstObject<typeof ItemBaseProps>>
	>;

	const label =
		itemModel.type === SCHEMA_VALUE_TYPE.CONST &&
		itemModel.constant.props.labelText.type === SCHEMA_VALUE_TYPE.CONST &&
		itemModel.constant.props.labelText.constant.props.value.type === SCHEMA_VALUE_TYPE.CONST
			? itemModel.constant.props.labelText.constant.props.value.constant.value
			: "#" + (index + 1);

	return {
		icon: "mdi/arrow-right",
		label: label
	};
};

/**
 * Tree Navigation props
 */
export const HAEComponentTreeNavigation_Props = {
	items: BP.Prop(
		BP.Array({
			...termsEditor.schemas.treeNavigation.items,
			items: BP.Recursive({
				value: (self) =>
					BP.Object({
						props: {
							...ItemBaseProps,

							items: BP.Prop(
								BP.Array({
									...termsEditor.schemas.treeNavigation.itemItems,
									items: self,
									getElementModelNodeInfo: getElementModelNodeInfo
								}),
								100
							)
						}
					}),
				editorOptions: {
					layoutType: "noHeader"
				}
			}),
			getElementModelNodeInfo: getElementModelNodeInfo
		}),
		0,
		propGroups.common
	),

	expandable: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.treeNavigation.expandable,
			enabledOpts: {
				default: true
			},
			value: BP.Object({
				props: {
					showLevels: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.treeNavigation.expandableShowLevels,
							default: 0,
							constraints: {
								min: 0
							}
						})
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		10,
		propGroups.common
	),

	style: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.treeNavigation.style,
			options: getValuesFromStringEnum(
				TREE_NAVIGATION_STYLE,
				termsEditor.schemas.treeNavigation.styleValues
			),
			default: TREE_NAVIGATION_STYLE_default
		}),
		0,
		propGroups.style
	)
};

/**
 * Tree Navigation props type
 */
export type THAEComponentTreeNavigation_Props = Partial<
	TSchemaConstObjectPropsSpec<typeof HAEComponentTreeNavigation_Props>
>;
