/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	areArrayOfStringsEqual,
	createEventEmitter,
	emitEvent,
	removeAllEventListeners
} from "@hexio_io/hae-lib-shared";
import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME, SCOPE_KEY_INDEX_PROP_NAME } from "../constants";
import { RUNTIME_CONTEXT_MODE, RuntimeContext } from "../Context/RuntimeContext";
import { TTypeDesc, TypeDescAny } from "../Shared/ITypeDescriptor";
import {
	ISchemaConstObjectModel,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec
} from "../schemas/const/SchemaConstObject";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IScope } from "../Shared/Scope";
import { cmpDataEqual } from "./ComponentHelpers";
import {
	IComponentDefinition,
	IComponentInfo,
	TComponentUpdateStateFunction,
	TComponentUpdateStateHandlerFunction
} from "./IComponentDefinition";
import {
	COMPONENT_MODE,
	IComponentInstance,
	TGenericComponentInstance,
	TGenericComponentInstanceList
} from "./IComponentInstance";
import { IComponentScopeData } from "./IComponentScopeData";
import { IComponentStateBase } from "./IComponentStateBase";
import { IEventDefinitionMap, TEventTriggerMap } from "../Events/EventTypes";
import { createEventTrigger } from "../Events/EventHandlers";

const DEBUG_LOG_CHANGES = false;

/**
 * Component's resolve function
 *
 * Function takes current spec, current state, and returns a new state
 */
export type TComponentResolveFunction<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap
> = (
	/** Current specification */
	spec: TSchemaConstObjectPropsSpec<TComponentProps>,
	/** Current state */
	state: TComponentState,
	/** Function to update component state asynchronously */
	updateStateAsync: TComponentUpdateStateFunction<TComponentState>,
	/** Component instance */
	componentInstance: IComponentInstance<TComponentProps, TComponentState, TComponentEvents>,
	/** Runtime context instance */
	rCtx: RuntimeContext,
	/** Current scope */
	scope: IScope
) => TComponentState;

/**
 * Function to return scope data based on spec and state
 */
export type TComponentGetScopeDataFunction<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase
> = (
	/** Current specification */
	spec: TSchemaConstObjectPropsSpec<TComponentProps>,
	/** Current state */
	state: TComponentState
) => IComponentScopeData;

/**
 * Function to return scope data based on spec and state
 */
export type TComponentGetScopeTypeFunction<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase
> = (
	/** Current specification */
	spec: TSchemaConstObjectPropsSpec<TComponentProps>,
	/** Current state */
	state: TComponentState,
	/** Props model (available only in editor mode) */
	propsModel?: ISchemaConstObjectModel<TComponentProps>
) => TTypeDesc;

/**
 * Component's destroy function
 *
 * Function takes current spec, current state
 */
export type TComponentDestroyFunction<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap
> = (
	/** Current specification */
	spec: TSchemaConstObjectPropsSpec<TComponentProps>,
	/** Current state */
	state: TComponentState,
	/** Component instance */
	componentInstance: IComponentInstance<TComponentProps, TComponentState, TComponentEvents>,
	/** Runtime context instance */
	rCtx: RuntimeContext,
	/** Last scope */
	scope: IScope
) => void;

/**
 * Function to return custom component instance list
 *
 * Function takes current spec, current state
 */
export type TComponentGetInstanceListFunction<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap
> = (
	/** Current specification */
	spec: TSchemaConstObjectPropsSpec<TComponentProps>,
	/** Current state */
	state: TComponentState,
	/** Previous instance list */
	prevInstanceList: TGenericComponentInstanceList,
	/** Current component instance */
	componentInstance: IComponentInstance<TComponentProps, TComponentState, TComponentEvents>,
	/** Runtime context instance */
	rCtx: RuntimeContext
) => TGenericComponentInstance[];

export interface IElementaryComponentDefinition<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap
> extends IComponentInfo<TComponentProps, TComponentEvents> {
	/** Resolves state */
	resolve: TComponentResolveFunction<TComponentProps, TComponentState, TComponentEvents>;

	/** Returns scope data for a component */
	getScopeData: TComponentGetScopeDataFunction<TComponentProps, TComponentState>;

	/** Returns type of scope data for a component */
	getScopeType: TComponentGetScopeTypeFunction<TComponentProps, TComponentState>;

	/** Returns custom component instance list */
	getInstanceList?: TComponentGetInstanceListFunction<TComponentProps, TComponentState, TComponentEvents>;

	/** Handles destroy process */
	destroy?: TComponentDestroyFunction<TComponentProps, TComponentState, TComponentEvents>;

	/**
	 * Hook to temporarily alter schema properties.
	 * See IComponentDefinition for more information.
	 */
	alterPropsSchemaHook?: IComponentDefinition<
		TComponentProps,
		TComponentState,
		TComponentEvents
	>["alterPropsSchemaHook"];

	/**
	 * Hook to revert temporarily altered schema properties.
	 * See IComponentDefinition for more information.
	 */
	restorePropsSchemaHook?: IComponentDefinition<
		TComponentProps,
		TComponentState,
		TComponentEvents
	>["restorePropsSchemaHook"];

	/**
	 * Hook to alter behaviour of inherited props assignment
	 * See IComponentDefinition for more information.
	 */
	assignInheritedPropsHook?: IComponentDefinition<
		TComponentProps,
		TComponentState,
		TComponentEvents
	>["assignInheritedPropsHook"];

	/**
	 * Hook to alter behaviour of inherited props UNassignment
	 * See IComponentDefinition for more information.
	 */
	unassignInheritedPropsHook?: IComponentDefinition<
		TComponentProps,
		TComponentState,
		TComponentEvents
	>["unassignInheritedPropsHook"];

	/**
	 * Hook to alter behaviour of rendering of inherits props
	 * See IComponentDefinition for more information.
	 */
	renderInheritedPropsHook?: IComponentDefinition<
		TComponentProps,
		TComponentState,
		TComponentEvents
	>["renderInheritedPropsHook"];
}

export function defineElementaryComponent<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap
>(
	componentDef: IElementaryComponentDefinition<TComponentProps, TComponentState, TComponentEvents>
): IComponentDefinition<TComponentProps, TComponentState, TComponentEvents> {
	return {
		name: componentDef.name,
		category: componentDef.category,
		label: componentDef.label,
		description: componentDef.description,
		icon: componentDef.icon,
		docUrl: componentDef.docUrl,
		order: componentDef.order,
		nonVisual: componentDef.nonVisual,
		hidden: componentDef.hidden,
		container: componentDef.container,
		events: componentDef.events,
		props: componentDef.props,
		initialVariableName: componentDef.initialVariableName,

		alterPropsSchemaHook: componentDef.alterPropsSchemaHook,
		restorePropsSchemaHook: componentDef.restorePropsSchemaHook,
		assignInheritedPropsHook: componentDef.assignInheritedPropsHook,
		unassignInheritedPropsHook: componentDef.unassignInheritedPropsHook,

		render: (rCtx, spec, path, scope, prevInstanceList) => {
			// Resolve path
			const cmpPath =
				scope.globalData["__componentPath"] instanceof Array
					? scope.globalData["__componentPath"].concat([ spec.id ])
					: [ "$", spec.id ];

			// Get prev instance
			const cmpInstance = (
				prevInstanceList &&
				prevInstanceList.rootSpec instanceof Object &&
				prevInstanceList.rootSpec.componentName === componentDef.name &&
				prevInstanceList.rootSpec.modelNodeId === spec.modelNodeId
					? prevInstanceList.rootSpec
					: {
						[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.COMPONENT,
						componentName: componentDef.name,
						id: spec.id,
						modelNodeId: spec.modelNodeId,
						uid: rCtx.__getNextUid(),
						rev: 0,
						originUid: null,
						path: cmpPath,
						safePath: null,
						componentMode: null,
						props: spec.props,
						inheritedProps: spec.inheritedProps,
						display: spec.display,
						comments: spec.comments,
						eventTriggers: null,
						eventEnabled: spec.eventsEnabled,
						onChange: createEventEmitter(),
						onDestroy: createEventEmitter(),
						lastScopeDataTypeDescriptor: TypeDescAny({}),
						setState: (
							newState:
								| TComponentState
								| TComponentUpdateStateHandlerFunction<TComponentState>
						) => {
							if (newState instanceof Function) {
								cmpInstance.state = newState(cmpInstance.state);
							} else {
								cmpInstance.state = newState;
							}

							cmpInstance.customData.stateManuallyChanged = true;
							rCtx.__invalidate(cmpInstance.path, true);
						},
						hasErrors: false,
						hasWarnings: false,
						isLoading: false,
						isLoadingWithData: false,
						wasRendered: false,
						isTemplated: false,
						inheritedPropsHasChanged: false,
						forceCompareInvalidate: false,
						prevData: {
							id: null,
							path: null,
							display: null,
							comments: null,
							props: null,
							inheritedProps: null,
							eventEnabled: null,
							state: null,
							hasErrors: null,
							hasWarnings: null,
							isLoading: null,
							isLoadingWithData: null
						},
						customData: {
							destroyed: false,
							stateManuallyChanged: false,
							afterRenderBound: false
						}
					}
			) as IComponentInstance<TComponentProps, TComponentState, TComponentEvents>;

			const runtimeContextMode = rCtx.getMode();

			let hasErrors = spec.hasErrors;
			let hasWarnings = spec.hasWarnings;
			let isLoading = spec.isLoading;
			let isLoadingWithData = spec.isLoadingWithData;

			// Validate ID

			if (scope.localData[SCOPE_KEY_INDEX_PROP_NAME].has(spec.id)) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.DUPLICATE_SCOPE_ID,
					message: `Duplicate identifier '${spec.id}'.`,
					modelPath: path,
					modelNodeId: cmpInstance.modelNodeId
				});

				spec.id += "_dup_" + path.join("_");
				hasWarnings = true;
			}

			scope.localData[SCOPE_KEY_INDEX_PROP_NAME].add(spec.id);

			// Update safe path

			if (!cmpInstance.safePath || !areArrayOfStringsEqual(cmpInstance.path, cmpPath)) {
				cmpInstance.safePath = cmpInstance.path.map((item) => {
					return `${item.length}-${(item || " ").replace(
						/[^\w-]/g,
						(character) => `c${character.charCodeAt(0)}`
					)}`;
				});
			}

			// Update instance props

			cmpInstance.id = spec.id;
			cmpInstance.path = cmpPath;
			cmpInstance.display = spec.display;
			cmpInstance.comments = spec.comments;
			cmpInstance.props = spec.props;
			cmpInstance.eventEnabled = spec.eventsEnabled;
			cmpInstance.wasRendered = spec.shouldBeRendered;

			// we don't know what inheritedProps will be
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(cmpInstance.inheritedProps as any) = spec.inheritedProps;

			// Assign prev render specs

			cmpInstance.__basePropsSpec = spec.baseProps;
			cmpInstance.__baseInheritedPropsSpec = spec.baseInheritedProps;
			cmpInstance.__modifiersSpec = spec.modifiers;
			cmpInstance.__eventsSpec = spec.events;

			// Assign edit info

			if (runtimeContextMode === RUNTIME_CONTEXT_MODE.EDITOR) {
				cmpInstance.modelNode = spec.modelNode;

				cmpInstance.debug = {
					scope: scope
				};
			}

			// Solve component mode

			cmpInstance.componentMode =
				runtimeContextMode === RUNTIME_CONTEXT_MODE.NORMAL
					? COMPONENT_MODE.NORMAL
					: runtimeContextMode === RUNTIME_CONTEXT_MODE.EDITOR &&
						cmpInstance.modelNode && !cmpInstance.isTemplated
						? COMPONENT_MODE.EDIT
						: COMPONENT_MODE.READONLY;

			// Create event triggers if not exist yet

			if (!cmpInstance.eventTriggers) {
				cmpInstance.eventTriggers = {} as TEventTriggerMap<TComponentEvents>;

				const getEventSpec = (eventName: string) => cmpInstance.__eventsSpec[eventName];

				for (const k in cmpInstance.__eventsSpec) {
					cmpInstance.eventTriggers[k as keyof TComponentEvents] = createEventTrigger(
						rCtx,
						"componentEvent",
						getEventSpec,
						k,
						cmpInstance.id,
						cmpInstance.path,
						cmpInstance.modelNodeId,
						cmpInstance,
						cmpInstance.modelNode?.events.props[k]?.props.nodes.template
					);
				}
			}

			if (spec.shouldBeRendered) {
				// Resolve new state (if not loading or already has data)
				rCtx.__beginLocalBoundary();

				if (!spec.isLoading || (spec.isLoading && spec.isLoadingWithData)) {
					const newState = componentDef.resolve(
						spec.props,
						cmpInstance.state,
						cmpInstance.setState,
						cmpInstance,
						rCtx,
						scope
					);
					cmpInstance.state = newState ? newState : cmpInstance.state;
				}

				const localBoundary = rCtx.__endLocalBoundary();

				// Assign error & warning & loading state

				hasErrors ||= localBoundary.error > 0;
				hasWarnings ||= localBoundary.warning > 0;
				isLoading ||= localBoundary.isLoading > 0;
				isLoadingWithData &&= localBoundary.isLoadingWithData === localBoundary.isLoading;

				cmpInstance.hasErrors = hasErrors;
				cmpInstance.hasWarnings = hasWarnings;

				cmpInstance.isLoading = isLoading;
				cmpInstance.isLoadingWithData = isLoadingWithData;

				// Update scope data

				const scopeData = componentDef.getScopeData(spec.props, cmpInstance.state);
				const isScopeDataModified = !cmpDataEqual(scope.localData[spec.id], scopeData);

				if (isScopeDataModified) {
					if (DEBUG_LOG_CHANGES) {
						console.log(
							"Change: Scope data",
							cmpInstance.id,
							scope.localData[spec.id],
							scopeData
						);
					}

					scope.localData[spec.id] = scopeData;
					scope.globalData[spec.id] = scopeData;

					if (
						runtimeContextMode === RUNTIME_CONTEXT_MODE.EDITOR ||
						(runtimeContextMode === RUNTIME_CONTEXT_MODE.NORMAL &&
							spec?.modelNode &&
							scope?.localType &&
							scope?.globalType)
					) {
						const typeDef = {
							...componentDef.getScopeType(
								spec.props,
								cmpInstance.state,
								spec.modelNode.props as unknown as ISchemaConstObjectModel<TComponentProps>
							),
							label: spec.id
						} as TTypeDesc;

						scope.localType.props[spec.id] = typeDef;
						scope.globalType.props[spec.id] = typeDef;
					}

					rCtx.__invalidate(cmpInstance.path, false);
				}
			}

			if (!cmpInstance.customData.afterRenderBound) {
				rCtx.__callAfterRender(() => {
					let hasChanged = false;

					// ID changed?
					if (cmpInstance.id !== cmpInstance.prevData.id) {
						if (DEBUG_LOG_CHANGES) {
							console.log("Change: ID", cmpInstance.prevData.id, spec.id);
						}

						hasChanged = true;
						cmpInstance.prevData.id = spec.id;
					}

					// Path changed?
					if (!cmpDataEqual(cmpInstance.path, cmpInstance.prevData.path)) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Path",
								cmpInstance.id,
								cmpInstance.prevData.path,
								cmpInstance.path
							);
						}

						hasChanged = true;
						cmpInstance.prevData.path = cmpInstance.path;
					}

					// Display changed?
					if (!cmpDataEqual(cmpInstance.display, cmpInstance.prevData.display)) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Display",
								cmpInstance.id,
								cmpInstance.prevData.display,
								cmpInstance.display
							);
						}

						hasChanged = true;
						cmpInstance.prevData.display = cmpInstance.display;
					}

					// Comments changed?
					if (!cmpDataEqual(cmpInstance.comments, cmpInstance.prevData.comments)) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Comments",
								cmpInstance.id,
								cmpInstance.prevData.comments,
								cmpInstance.comments
							);
						}

						hasChanged = true;
						cmpInstance.prevData.comments = cmpInstance.comments;
					}

					// Enabled events changed?
					if (!cmpDataEqual(cmpInstance.eventEnabled, cmpInstance.prevData.eventEnabled)) {
						if (DEBUG_LOG_CHANGES) {
							// eslint-disable-next-line max-len
							console.log(
								"Change: Events enabled",
								cmpInstance.id,
								cmpInstance.prevData.eventEnabled,
								cmpInstance.eventEnabled
							);
						}

						hasChanged = true;
						cmpInstance.prevData.eventEnabled = cmpInstance.eventEnabled;
					}

					// Props changed?
					if (!cmpDataEqual(cmpInstance.props, cmpInstance.prevData.props)) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Props",
								cmpInstance.id,
								cmpInstance.prevData.props,
								cmpInstance.props
							);
						}

						hasChanged = true;
						cmpInstance.prevData.props = cmpInstance.props;
					}

					// Set inherited props
					if (!cmpDataEqual(cmpInstance.inheritedProps, cmpInstance.prevData.inheritedProps)) {
						if (DEBUG_LOG_CHANGES) {
							// eslint-disable-next-line max-len
							console.log(
								"Change: Inherited props",
								cmpInstance.id,
								cmpInstance.prevData.inheritedProps,
								cmpInstance.inheritedProps,
								spec.inheritedProps
							);
						}

						hasChanged = true;
						cmpInstance.inheritedPropsHasChanged = true;
						cmpInstance.prevData.inheritedProps = cmpInstance.inheritedProps;
					} else {
						cmpInstance.inheritedPropsHasChanged = false;
					}

					// State changed?
					if (
						cmpInstance.customData.stateManuallyChanged ||
						!cmpDataEqual(cmpInstance.state, cmpInstance.prevData.state)
					) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: State",
								cmpInstance.id,
								cmpInstance.prevData.state,
								cmpInstance.state
							);
						}

						hasChanged = true;
						cmpInstance.prevData.state = cmpInstance.state;
						cmpInstance.customData.stateManuallyChanged = false;
					}

					// Errors state changed?
					if (cmpInstance.hasErrors !== cmpInstance.prevData.hasErrors) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Errors",
								cmpInstance.id,
								cmpInstance.prevData.hasErrors,
								cmpInstance.hasErrors
							);
						}

						hasChanged = true;
						cmpInstance.prevData.hasErrors = cmpInstance.hasErrors;
					}

					if (cmpInstance.hasWarnings !== cmpInstance.prevData.hasWarnings) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Warnings",
								cmpInstance.id,
								cmpInstance.prevData.hasWarnings,
								cmpInstance.hasErrors
							);
						}

						hasChanged = true;
						cmpInstance.prevData.hasWarnings = cmpInstance.hasWarnings;
					}

					// Loading state changed?
					if (
						cmpInstance.isLoading !== cmpInstance.prevData.isLoading ||
						cmpInstance.isLoadingWithData !== cmpInstance.prevData.isLoadingWithData
					) {
						if (DEBUG_LOG_CHANGES) {
							console.log(
								"Change: Loading %s [prev l/lwd: %s/%s] [new l/lwd: %s/%s]",
								cmpInstance.id,
								cmpInstance.prevData.isLoading,
								cmpInstance.prevData.isLoadingWithData,
								cmpInstance.isLoading,
								cmpInstance.isLoadingWithData
							);
						}
						hasChanged = true;
						cmpInstance.prevData.isLoading = cmpInstance.isLoading;
						cmpInstance.prevData.isLoadingWithData = cmpInstance.isLoadingWithData;
					}

					// Was rendered changed?
					if (cmpInstance.wasRendered !== cmpInstance.prevData.wasRendered) {
						hasChanged = true;
						cmpInstance.prevData.wasRendered = cmpInstance.wasRendered;
					}

					// Process changes
					if (hasChanged) {
						cmpInstance.rev++;

						if (!cmpInstance.customData.destroyed) {
							emitEvent(cmpInstance.onChange);
						}
					}

					cmpInstance.customData.afterRenderBound = false;
				});

				cmpInstance.customData.afterRenderBound = true;
			}

			// Set entity live

			rCtx.__setEntityLive(cmpInstance, () => {
				cmpInstance.customData.destroyed = true;

				removeAllEventListeners(cmpInstance.onChange);

				if (componentDef.destroy) {
					componentDef.destroy(spec.props, cmpInstance.state, cmpInstance, rCtx, scope);
				}

				emitEvent(cmpInstance.onDestroy);
				removeAllEventListeners(cmpInstance.onDestroy);
			});

			if (spec.shouldBeRendered || runtimeContextMode === RUNTIME_CONTEXT_MODE.EDITOR) {
				const cmpInstanceList = componentDef.getInstanceList
					? componentDef.getInstanceList(
						spec.props,
						cmpInstance.state,
						prevInstanceList,
						cmpInstance,
						rCtx
					)
					: [ cmpInstance ];

				return Object.assign(cmpInstanceList, { rootSpec: cmpInstance });
			} else {
				return Object.assign([], { rootSpec: cmpInstance });
			}
		}
	};
}
