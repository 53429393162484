/**
 * Menu Button HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";

import {
	TextSchema,
	IconSchema,
	ButtonProps,
	LinkSchema,
	TextProps,
	TextValueSchema,
	IconSizeSchema,
	propGroups,
	IconProps,
	ForegroundColorSchema,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	HORIZONTAL_ALIGN,
	HORIZONTAL_ALIGN_opts,
	HORIZONTAL_ALIGN_stretch
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { HAEComponentButton_Props } from "../Button/props";

export const HAEComponentMenuButton_Props = {
	labelText: BP.Prop(
		TextSchema({
			alias: "text_menuButtonLabel",
			props: {
				value: BP.Prop(
					TextValueSchema({
						alias: "textValue_menuButtonLabel",
						default: "Menu button"
					}),
					TextProps.value.order,
					TextProps.value.group
				),

				foregroundColor: BP.Prop(
					ForegroundColorSchema({
						alias: "foregroundColor_menuButtonLabelText",
						default: "INHERIT"
					}),
					TextProps.foregroundColor.order,
					TextProps.foregroundColor.group
				),

				overflow: BP.Prop(BP.Void({}))
			}
		}),
		0,
		propGroups.common
	),

	labelIcon: BP.Prop(
		IconSchema({
			alias: "icon_menuButtonLabel",
			props: {
				foregroundColor: BP.Prop(
					ForegroundColorSchema({
						alias: "foregroundColor_menuButtonLabelIcon",
						default: "INHERIT"
					}),
					IconProps.foregroundColor.order,
					IconProps.foregroundColor.group
				)
			},
			editorOptions: {
				defaultExpanded: false
			}
		}),
		10,
		propGroups.common
	),

	items: BP.Prop(
		BP.Array({
			...termsEditor.schemas.menuButton.items,
			default: null,
			fallbackValue: null,
			constraints: {
				required: true
			},
			items: BP.Object({
				props: {
					key: BP.Prop(
						BP.String({
							...termsEditor.schemas.menuButton.itemKey,
							default: ""
						}),
						0
					),

					labelText: BP.Prop(
						TextSchema({
							alias: "text_menuButtonItemLabel",
							props: {
								value: BP.Prop(
									TextValueSchema({
										alias: "textValue_menuButtonItemLabel",
										default: "Item"
									}),
									TextProps.value.order,
									TextProps.value.group
								),

								overflow: BP.Prop(BP.Void({}))
							},
							editorOptions: {
								defaultExpanded: false
							}
						}),
						10
					),

					labelIcon: BP.Prop(
						IconSchema({
							alias: "icon_menuButtonItemLabel",
							props: {
								size: BP.Prop(
									IconSizeSchema({
										alias: "iconSize_menuButtonItemLabel",
										default: "SMALL"
									}),
									IconProps.size.order,
									IconProps.size.group
								)
							},
							editorOptions: {
								defaultExpanded: false
							}
						}),
						20
					),

					hideOnClick: BP.Prop(
						BP.Boolean({
							...termsEditor.schemas.menuButton.itemHideOnClick,
							default: true,
							fallbackValue: true,
							constraints: {
								required: true
							}
						}),
						30
					),

					link: BP.Prop(LinkSchema(), 40),

					separated: BP.Prop(
						BP.Boolean({
							...termsEditor.schemas.menuButton.itemSeparated,
							default: false,
							fallbackValue: false,
							constraints: {
								required: true
							}
						}),
						50
					),

					visible: BP.Prop(
						BP.Boolean({
							...termsEditor.schemas.menuButton.itemVisible,
							default: true,
							fallbackValue: true,
							constraints: {
								required: false
							}
						}),
						60
					)
				}
			}),
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.labelText.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.labelText.constant.props.value.type ===
							SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.labelText.constant.props.value.constant.value,
					icon: "mdi/card-outline"
				};
			}
		}),
		20,
		propGroups.common
	),

	menuHorizontalAlign: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.menuButton.menuHorizontalAlign,
			options: getValuesFromStringEnum(
				HORIZONTAL_ALIGN,
				HAELibComponentsTerms.schemas.common.horizontalAlignValues,
				HORIZONTAL_ALIGN_opts
			),
			default: HORIZONTAL_ALIGN_stretch,
			editorOptions: {
				controlType: "buttons"
			}
		}),
		30,
		propGroups.common
	),

	enabled: ButtonProps.enabled,

	styleData: HAEComponentButton_Props.styleData
};
