/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from "react";
import { BEM } from "../Classes/Bem";
import { Viewport } from "../Classes/Viewport";
import { HAEComponentMainContext } from "./HAEComponentContext";

/**
 * Editor handle props
 */
export interface IHAEComponentEditorHandleProps {
	label: string;
	optionsActive?: boolean;
	onOptionsToggle?: () => void;
}

/**
 * Editor handle component
 *
 * @param params
 */
export const HAEComponentEditorHandle: React.FunctionComponent<IHAEComponentEditorHandleProps> = ({
	label,
	optionsActive,
	onOptionsToggle
}) => {
	const elementRef = React.useRef<HTMLSpanElement>();

	const componentMainContext = React.useContext(HAEComponentMainContext);
	const { viewport } = componentMainContext;

	React.useLayoutEffect(() => {
		function fixPosition() {
			if (!elementRef.current) {
				return;
			}

			elementRef.current.style.left = "";
			elementRef.current.style.top = "";
			elementRef.current.style.maxWidth = "";
			elementRef.current.style.position = "";

			let parentElement = elementRef.current;

			const elementRect = parentElement.getBoundingClientRect();
			const { width, height } = elementRect;
			let { left, top } = elementRect;

			while (
				parentElement.parentElement &&
				!parentElement.parentElement.classList.contains("hae-app-root")
			) {
				parentElement = parentElement.parentElement;

				if (parentElement?.scrollTop || parentElement?.scrollLeft) {
					const parentRect = parentElement.getBoundingClientRect();

					left = Math.max(left, parentRect.left);
					top = Math.max(top, parentRect.top);
				}
			}

			const {
				width: viewportWidth,
				height: viewportHeight,
				left: viewportLeft,
				top: viewportTop
			} = viewport.getProperties();

			const offsetLeft = Math.min(viewportLeft + viewportWidth - width, Math.max(viewportLeft, left));
			const offsetTop = Math.min(viewportTop + viewportHeight - height, Math.max(viewportTop, top));

			const styleOffsetLeft = offsetLeft > viewportLeft ? offsetLeft : viewportLeft;
			const styleOffsetTop = offsetTop > viewportTop ? offsetTop : viewportTop;
			const maxWidth = viewportWidth - (offsetLeft - viewportLeft);

			elementRef.current.style.left = `${styleOffsetLeft}px`;
			elementRef.current.style.top = `${styleOffsetTop}px`;
			elementRef.current.style.maxWidth = `${maxWidth}px`;
			elementRef.current.style.position = "fixed";
		}

		fixPosition();

		function _windowEditorComponentUpdateHandler() {
			setTimeout(() => fixPosition());
		}

		function _windowEditorViewportUpdateHandler() {
			fixPosition();
		}

		function _windowEditorViewportScrollHandler() {
			fixPosition();
		}

		function _windowContainerScrollHandler() {
			fixPosition();
		}

		window.addEventListener("hae_editor_component_update", _windowEditorComponentUpdateHandler);
		// window.addEventListener(Viewport.UPDATE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
		window.addEventListener(Viewport.RESIZE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
		window.addEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);
		window.addEventListener("hae_component_container_scroll", _windowContainerScrollHandler);

		return () => {
			window.removeEventListener("hae_editor_component_update", _windowEditorComponentUpdateHandler);
			// window.removeEventListener(Viewport.UPDATE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
			window.removeEventListener(Viewport.RESIZE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
			window.removeEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);
			window.removeEventListener("hae_component_container_scroll", _windowContainerScrollHandler);
		};
	}, [ elementRef.current, viewport ]);

	return (
		<span ref={elementRef} className="hae-component__editor-handle">
			<span className="hae-component__editor-handle-label">{label}</span>
			{onOptionsToggle ? (
				<button
					className={BEM("hae-component", "editor-handle-button", {
						active: optionsActive
					}).toClassName()}
					title="Toggle Options"
					onClick={onOptionsToggle}
				>
					{/* eslint-disable-next-line max-len */}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.1"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
					</svg>
				</button>
			) : null}
			{/* eslint-disable max-len */}
			{/* <button className="hae-component__editor-handle-button">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
		</button> */}
			{/* eslint-enable max-len */}
		</span>
	);
};
