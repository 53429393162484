/**
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useEffect, useRef } from "react";
import {
	IAllowedResizeDimensions,
	TResizeAfterRenderHandler,
	TResizeBeginHandler,
	TResizeEndHandler,
	TResizeUpdateHandler
} from "../Editor/IResize";
import { IResizeOffset } from "../Editor/IEditCommon";

interface IDragAxis {
	left: boolean;
	right: boolean;
	top: boolean;
	bottom: boolean;
}

/**
 * Editor handle props
 */
export interface IHAEComponentResizerProps<TSubject, TInitialState> {
	/** Allows resize in given dimensions */
	allowedDims: IAllowedResizeDimensions;

	/** Target element to resize (used for reading initial dimensions) */
	targetElement: HTMLElement;

	/** Subject to pass in callback */
	subject?: TSubject;

	/** Reference to any value that will trigger after-render function when changed */
	updateRef?: unknown;

	/** Callback to handle component resize begin */
	onResizeBegin?: TResizeBeginHandler<TSubject, TInitialState>;

	/** Callback to handle component resize update on mouse move (should update element style) */
	onResizeUpdate?: TResizeUpdateHandler<TSubject, TInitialState>;

	/** Callback to handle component resize end (should write changes to model) */
	onResizeEnd?: TResizeEndHandler<TSubject, TInitialState>;
}

/**
 * Editor handle component
 *
 * @param params
 */
export function HAEComponentResizer<TSubject, TInitialState = never>(
	props: IHAEComponentResizerProps<TSubject, TInitialState>
): React.ReactElement {
	const { allowedDims, targetElement, subject, onResizeBegin, onResizeUpdate, onResizeEnd, updateRef } =
		props;
	const afterRenderHandler = useRef<TResizeAfterRenderHandler>(null);

	const preventEvent = useCallback((ev: React.MouseEvent<HTMLElement>) => {
		ev.stopPropagation();
	}, []);

	const handleMouseDown = useCallback(
		(constraints: IDragAxis) => {
			return (ev: React.MouseEvent<HTMLElement>) => {
				if (!targetElement) {
					return;
				}

				ev.stopPropagation();
				ev.preventDefault();

				const initialDims = {
					width: targetElement.offsetWidth,
					height: targetElement.offsetHeight,
					screenX: ev.screenX,
					screenY: ev.screenY
				};

				let resizeOffset: IResizeOffset = {
					left: 0,
					top: 0,
					width: 0,
					height: 0,
					initialWidth: initialDims.width,
					initialHeight: initialDims.height
				};

				const initalState = onResizeBegin ? onResizeBegin(subject, resizeOffset) : null;

				let offsetX = 0;
				let offsetY = 0;

				let cursorClassName;

				if ((constraints.left && constraints.top) || (constraints.right && constraints.bottom)) {
					cursorClassName = "hae-editor-resize-nwse";
				} else if (
					(constraints.right && constraints.top) ||
					(constraints.left && constraints.bottom)
				) {
					cursorClassName = "hae-editor-resize-nesw";
				} else if (constraints.left || constraints.right) {
					cursorClassName = "hae-editor-resize-ew";
				} else if (constraints.top || constraints.bottom) {
					cursorClassName = "hae-editor-resize-ns";
				} else {
					cursorClassName = "hae-editor-resize";
				}

				const handleMouseMove = (ev: MouseEvent) => {
					ev.stopPropagation();

					//const { target } = ev;

					offsetX = ev.screenX - initialDims.screenX;
					offsetY = ev.screenY - initialDims.screenY;

					/*if (target instanceof HTMLIFrameElement && target.classList.contains("edt-view-preview__wrapper")) {
					const iframeRect = target.getBoundingClientRect();

					offsetX -= iframeRect.left;
					offsetY -= iframeRect.top;
				}*/

					let left = 0;
					let top = 0;
					let width = 0;
					let height = 0;

					if (constraints.left) {
						left = Math.min(initialDims.width, offsetX);
						width = Math.max(-initialDims.width, -offsetX);
					}

					if (constraints.top) {
						top = Math.min(initialDims.height, offsetY);
						height = Math.max(-initialDims.height, -offsetY);
					}

					if (constraints.right) {
						width = Math.max(-initialDims.width, offsetX);
					}

					if (constraints.bottom) {
						height = Math.max(-initialDims.height, offsetY);
					}

					resizeOffset = {
						left: left,
						top: top,
						width: width,
						height: height,
						initialWidth: initialDims.width,
						initialHeight: initialDims.height
					};

					onResizeUpdate(subject, targetElement, resizeOffset, initalState);

					// hasChanged = true;
				};

				const preventGlobal = (ev: MouseEvent) => {
					ev.stopPropagation();
					ev.preventDefault();
				};

				const handleMouseUp = (ev: MouseEvent) => {
					ev.stopPropagation();
					ev.stopImmediatePropagation();
					ev.preventDefault();

					window.removeEventListener("mousemove", handleMouseMove);
					document.body.classList.remove(cursorClassName);

					if (onResizeEnd) {
						afterRenderHandler.current = onResizeEnd(subject, resizeOffset, initalState) || null;
					}

					setTimeout(() => {
						window.removeEventListener("mouseover", preventGlobal, true);
						window.removeEventListener("click", preventGlobal, true);
						window.removeEventListener("mouseup", handleMouseUp);
					}, 1);
				};

				window.addEventListener("mouseover", preventGlobal, true);
				window.addEventListener("click", preventGlobal, true);
				window.addEventListener("mousemove", handleMouseMove);
				window.addEventListener("mouseup", handleMouseUp);

				document.body.classList.add(cursorClassName);
			};
		},
		[ targetElement, subject, onResizeBegin, onResizeUpdate, onResizeEnd ]
	);

	useEffect(() => {
		if (afterRenderHandler.current) {
			afterRenderHandler.current(targetElement);
		}
	}, [ updateRef ]);

	if (!targetElement) {
		return null;
	}

	const elements = [];

	// All dimensions
	if (allowedDims.left && allowedDims.top && allowedDims.width && allowedDims.height) {
		elements.push(
			<span
				key="r-t"
				className="hae-component__editor-resize hae-component__editor-resize--top"
				onMouseDown={handleMouseDown({ left: false, top: true, right: false, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="r-r"
				className="hae-component__editor-resize hae-component__editor-resize--right"
				onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="r-b"
				className="hae-component__editor-resize hae-component__editor-resize--bottom"
				onMouseDown={handleMouseDown({ left: false, top: false, right: false, bottom: true })}
				onClick={preventEvent}
			/>,
			<span
				key="r-l"
				className="hae-component__editor-resize hae-component__editor-resize--left"
				onMouseDown={handleMouseDown({ left: true, top: false, right: false, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="e-t"
				className="hae-component__editor-resize-edge hae-component__editor-resize-edge--top"
			>
				<span
					className="hae-component__editor-resize-edge-handle"
					onMouseDown={handleMouseDown({ left: false, top: true, right: false, bottom: false })}
					onClick={preventEvent}
				/>
			</span>,
			<span
				key="e-r"
				className="hae-component__editor-resize-edge hae-component__editor-resize-edge--right"
			>
				<span
					className="hae-component__editor-resize-edge-handle"
					onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: false })}
					onClick={preventEvent}
				/>
			</span>,
			<span
				key="e-b"
				className="hae-component__editor-resize-edge hae-component__editor-resize-edge--bottom"
			>
				<span
					className="hae-component__editor-resize-edge-handle"
					onMouseDown={handleMouseDown({ left: false, top: false, right: false, bottom: true })}
					onClick={preventEvent}
				/>
			</span>,
			<span
				key="e-l"
				className="hae-component__editor-resize-edge hae-component__editor-resize-edge--left"
			>
				<span
					className="hae-component__editor-resize-edge-handle"
					onMouseDown={handleMouseDown({ left: true, top: false, right: false, bottom: false })}
					onClick={preventEvent}
				/>
			</span>,
			<span
				key="c-tl"
				className="hae-component__editor-resize-corner hae-component__editor-resize-corner--top-left"
				onMouseDown={handleMouseDown({ left: true, top: true, right: false, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="c-tr"
				className="hae-component__editor-resize-corner hae-component__editor-resize-corner--top-right"
				onMouseDown={handleMouseDown({ left: false, top: true, right: true, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="c-bl"
				className="hae-component__editor-resize-corner hae-component__editor-resize-corner--bottom-left"
				onMouseDown={handleMouseDown({ left: true, top: false, right: false, bottom: true })}
				onClick={preventEvent}
			/>,
			<span
				key="c-br"
				className="hae-component__editor-resize-corner hae-component__editor-resize-corner--bottom-right"
				onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: true })}
				onClick={preventEvent}
			/>
		);

		// Width and height only
	} else if (allowedDims.width && allowedDims.height) {
		elements.push(
			<span
				key="r-r"
				className="hae-component__editor-resize hae-component__editor-resize--right"
				onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: false })}
				onClick={preventEvent}
			/>,
			<span
				key="r-b"
				className="hae-component__editor-resize hae-component__editor-resize--bottom"
				onMouseDown={handleMouseDown({ left: false, top: false, right: false, bottom: true })}
				onClick={preventEvent}
			/>,
			<span
				key="c-br"
				className="hae-component__editor-resize-corner hae-component__editor-resize-corner--bottom-right"
				onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: true })}
				onClick={preventEvent}
			/>
		);
	} else {
		if (allowedDims.left && allowedDims.width) {
			elements.push(
				<span
					key="r-l"
					className="hae-component__editor-resize hae-component__editor-resize--left"
					onMouseDown={handleMouseDown({ left: true, top: false, right: false, bottom: false })}
					onClick={preventEvent}
				/>,
				<span
					key="e-l"
					className="hae-component__editor-resize-edge hae-component__editor-resize-edge--left"
				>
					<span
						className="hae-component__editor-resize-edge-handle"
						onMouseDown={handleMouseDown({ left: true, top: false, right: false, bottom: false })}
						onClick={preventEvent}
					/>
				</span>
			);
		}

		if (allowedDims.width) {
			elements.push(
				<span
					key="r-r"
					className="hae-component__editor-resize hae-component__editor-resize--right"
					onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: false })}
					onClick={preventEvent}
				/>,
				<span
					key="e-r"
					className="hae-component__editor-resize-edge hae-component__editor-resize-edge--right"
				>
					<span
						className="hae-component__editor-resize-edge-handle"
						onMouseDown={handleMouseDown({ left: false, top: false, right: true, bottom: false })}
						onClick={preventEvent}
					/>
				</span>
			);
		}

		if (allowedDims.top && allowedDims.height) {
			elements.push(
				<span
					key="r-t"
					className="hae-component__editor-resize hae-component__editor-resize--top"
					onMouseDown={handleMouseDown({ left: false, top: true, right: false, bottom: false })}
					onClick={preventEvent}
				/>,
				<span
					key="e-t"
					className="hae-component__editor-resize-edge hae-component__editor-resize-edge--top"
				>
					<span
						className="hae-component__editor-resize-edge-handle"
						onMouseDown={handleMouseDown({ left: false, top: true, right: false, bottom: false })}
						onClick={preventEvent}
					/>
				</span>
			);
		}

		if (allowedDims.height) {
			elements.push(
				<span
					key="r-b"
					className="hae-component__editor-resize hae-component__editor-resize--bottom"
					onMouseDown={handleMouseDown({ left: false, top: false, right: false, bottom: true })}
					onClick={preventEvent}
				/>,
				<span
					key="e-b"
					className="hae-component__editor-resize-edge hae-component__editor-resize-edge--bottom"
				>
					<span
						className="hae-component__editor-resize-edge-handle"
						onMouseDown={handleMouseDown({ left: false, top: false, right: false, bottom: true })}
						onClick={preventEvent}
					/>
				</span>
			);
		}
	}

	return <>{elements}</>;
}
