/**
 * Tree Navigation HAE component content
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	ClassList,
	Link,
	IBaseProps,
	Icon,
	ICON_NAME,
	getStringEnumValue,
	RoutingContext,
	Label
} from "@hexio_io/hae-lib-components";

import { isBoolean, isNonEmptyArray, isValidObject } from "@hexio_io/hae-lib-shared";

import { TItemBaseProps, THAEComponentTreeNavigation_Props } from "./props";
import { TREE_NAVIGATION_ITEM_EXPAND } from "../../Enums/TREE_NAVIGATION_ITEM_EXPAND";
import { TREE_NAVIGATION_STYLE } from "../../Enums/TREE_NAVIGATION_STYLE";

/**
 * Tree Navigation Component base props
 */
interface ITreeNavigationComponentBaseProps {
	/** Level number */
	level?: number;

	/** List style */
	styleValue?: TREE_NAVIGATION_STYLE;

	/** Item click handler */
	onItemClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

/**
 * Tree Navigation List props
 */
export interface ITreeNavigationListProps
	extends IBaseProps,
		ITreeNavigationComponentBaseProps,
		THAEComponentTreeNavigation_Props {}

/**
 * Tree Navigation List component
 */
export const TreeNavigationList: React.FunctionComponent<ITreeNavigationListProps> = (props) => {
	const { items, expandable, level = 1, styleValue, componentPath, componentMode, onItemClick } = props;

	if (!isNonEmptyArray(items)) {
		return null;
	}

	return (
		<ul className="cmp-tree-navigation__list">
			{items.map((item: TItemBaseProps & THAEComponentTreeNavigation_Props, index) => {
				const itemKey = `level-${level}-${index}`;

				return (
					<TreeNavigationItem
						key={itemKey}
						{...item}
						expandable={expandable}
						level={level}
						styleValue={styleValue}
						componentPath={[ ...componentPath, itemKey ]}
						componentMode={componentMode}
						onItemClick={onItemClick}
					/>
				);
			})}
		</ul>
	);
};

/**
 * Tree Navigation Item props
 */
export interface ITreeNavigationItemProps
	extends IBaseProps,
		ITreeNavigationComponentBaseProps,
		TItemBaseProps,
		THAEComponentTreeNavigation_Props {}

/**
 * Tree Navigation Item component
 */
export const TreeNavigationItem: React.FunctionComponent<ITreeNavigationItemProps> = (props) => {
	const {
		link,
		labelText,
		labelIcon,
		expand,
		visible,
		items,
		expandable,
		level,
		styleValue,
		componentPath,
		componentMode,
		onItemClick
	} = props;

	const routingContext = React.useContext(RoutingContext);

	const resolvedLink = link?.locationData
		? routingContext.resolveLink(link.locationData, {
				exact: isBoolean(link.exact) ? link.exact : false
		  })
		: null;

	const hasMatch = resolvedLink ? isValidObject(resolvedLink.match) : false;
	const hasItems = isNonEmptyArray(items);

	React.useEffect(() => {
		if (!expanded && hasItems && (!expandable || hasMatch)) {
			setExpanded(true);
		}
	}, [ expandable, hasMatch ]);

	const [ expanded, setExpanded ] = React.useState(
		(() => {
			if (!hasItems) {
				return false;
			}

			if (hasMatch || !expandable) {
				return true;
			}

			const expandValue = getStringEnumValue(TREE_NAVIGATION_ITEM_EXPAND, expand);
			const showLevels = expandable?.showLevels || 0;

			switch (expandValue) {
				case TREE_NAVIGATION_ITEM_EXPAND.EXPANDED:
					return true;

				case TREE_NAVIGATION_ITEM_EXPAND.COLLAPSED:
					return false;

				case TREE_NAVIGATION_ITEM_EXPAND.DEFAULT:
				default:
					return showLevels === 0 || showLevels - 1 >= level;
			}
		})()
	);

	if (!visible) {
		return null;
	}

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-tree-navigation__item",
		componentPath
	);

	classList.addModifiers({
		current: hasMatch && resolvedLink.match.isExact,
		//match: hasMatch,
		expanded,
		"with-icon": !!labelIcon?.source,
		l: level
	});

	const labelIconSize =
		(styleValue === TREE_NAVIGATION_STYLE.MENU_LEFT || styleValue === TREE_NAVIGATION_STYLE.MENU_RIGHT) &&
		level === 1
			? "MEDIUM"
			: "SMALL";

	const labelContent = (
		<Label
			text={labelText}
			icon={{ ...labelIcon, size: labelIconSize }}
			spacing={false}
			classList={new ClassList("cmp-tree-navigation__item-label")}
			componentPath={[ ...componentPath, "label" ]}
			componentMode={componentMode}
		/>
	);

	const style = { "--element-item-indent-level": level - 1 } as React.CSSProperties;

	let _buttonClickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	if (expandable && hasItems) {
		_buttonClickHandler = (event) => {
			event.preventDefault();

			setExpanded(!expanded);
		};
	}

	return (
		<li className={classList.toClassName()} style={style}>
			<div className="cmp-tree-navigation__item-header">
				{expandable && hasItems ? (
					<button
						type="button"
						className="button button--style-clear"
						onClick={_buttonClickHandler}
					>
						<Icon
							source={expanded ? ICON_NAME.MENU_DOWN : ICON_NAME.MENU_RIGHT}
							size="SMALL"
							componentPath={[ ...componentPath, "expand-icon" ]}
							componentMode={componentMode}
							classList={new ClassList("cmp-tree-navigation__item-expand-icon")}
						/>
					</button>
				) : null}

				{resolvedLink?.url ? (
					<Link
						href={resolvedLink.url}
						external={resolvedLink.isExternal}
						target={link.target}
						enabled={link.enabled}
						componentMode={componentMode}
						classList={
							new ClassList(
								"cmp-tree-navigation__item-title",
								"cmp-tree-navigation__item-title--link"
							)
						}
						onClick={onItemClick}
					>
						{labelContent}
					</Link>
				) : (
					<div className="cmp-tree-navigation__item-title">{labelContent}</div>
				)}
			</div>

			{hasItems ? (
				<TreeNavigationList
					items={items}
					expandable={expandable}
					level={level + 1}
					componentPath={[ ...componentPath, "items" ]}
					componentMode={componentMode}
					onItemClick={onItemClick}
				/>
			) : null}
		</li>
	);
};
