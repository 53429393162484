/**
 * Link Location schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaOneOf, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../terms";

/**
 * Link schema oneOf types
 */
// @todo Translations!
export const LinkLocationSchema_Types = {
	ROUTE: {
		label: "Named Route",
		description: "Link to a named route with parameters.",
		value: BP.RouteRef({
			label: "Named Route",
			constraints: {
				required: true
			}
		})
	},
	VIEW: {
		label: "View",
		description: "Link to a view with parameters.",
		value: BP.ViewRef({
			label: "View",
			constraints: {
				required: true
			}
		})
	},
	URL: {
		label: "URL",
		description: "Link to a user specified URL address.",
		value: BP.String({
			label: "URL Address",
			constraints: {
				required: true
			},
			default: "/",
			fallbackValue: "#"
		})
	},
	NONE: {
		label: "None",
		value: BP.Void({})
	}
};

/**
 * Link schema options
 */
export interface ILinkLocationSchemaOpts {
	constraints?: {
		required?: boolean;
	};
	defaultType: keyof typeof LinkLocationSchema_Types;
}

/**
 * Link Schema
 *
 * @param opts Options
 */
export const LinkLocationSchema = (
	opts: ILinkLocationSchemaOpts
): ISchemaOneOf<typeof LinkLocationSchema_Types> =>
	BP.OneOf({
		...termsEditor.schemas.linkLocation.schema,
		types: LinkLocationSchema_Types,
		defaultType: opts.defaultType,
		constraints: {
			required: opts.constraints?.required ?? false
		}
	});

export type TLinkLocationSchema = ISchemaOneOf<typeof LinkLocationSchema_Types>;
export type TLinkLocationSpec = TGetBlueprintSchemaSpec<TLinkLocationSchema>;
