/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { DesignContext, RuntimeContext, TGetBlueprintSchemaModel } from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintTranslations, DOC_TYPES } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { ITranslationResourceProps, ITranslationResourceType, TTranslations } from "./ITranslationResource";

type TTranslationSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintTranslations>;

export class TranslationResourceV1 implements ITranslationResourceType {
	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.TRANSLATIONS_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.TRANSLATIONS;
	}

	public get statsName(): string {
		return "translations";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-translation-v1");
	}

	public setup(resource: ITranslationResourceProps): ITranslationResourceProps {
		resource.resourceType = RESOURCE_TYPES.TRANSLATIONS;
		resource.parsingDetails.isRegistered = true;

		return resource;
	}

	public async scan(resource: ITranslationResourceProps): Promise<ITranslationResourceProps> {
		return resource;
	}

	public async parse(resource: ITranslationResourceProps): Promise<ITranslationResourceProps> {
		let dCtx: DesignContext;
		let model: TTranslationSchemaModel;
		let rCtx: RuntimeContext;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse translation '${uri}'.`);

		try {
			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<ITranslationResourceProps>(
				resource,
				dCtx,
				BlueprintTranslations
			);
			model = resource.parsedData.model as TTranslationSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse translation model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec;

			if (!spec) {
				this.logger.warn("Can't parse translation model.");
				this.logger.debug({ uri });
				return resource;
			}

			const translation = spec.spec as TTranslations;
			resource.parsedData.translation = translation;

			// Temporary should be registered somewhere in Translation manager
			for (const [ language ] of Object.entries(translation)) {
				this.app.get("translationManager").removeSourceId("app", language, resource.id);
				this.app.get("translationManager").removeTranslations("app");
			}
			for (const [ language, terms ] of Object.entries(translation)) {
				this.app.get("translationManager").registerTranslations("app", language, terms, resource.id);
			}
			// Temporary should be registered somewhere in Translation manager

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			throw error;
		} finally {
			try {
				if (rCtx) {
					rCtx.destroy();
				}
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug(error);
			}
		}
	}

	public getDependenciesToReload(
		resource: ITranslationResourceProps,
		eventType?: RESOURCE_ON_EVENT_TYPE
	): string[] {
		return [];
	}
}
