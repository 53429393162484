/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintViewToHtmlNodeOptsSchemaSpec } from "../../blueprints";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";

export async function viewToHtmlNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintViewToHtmlNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { viewId, params } = opts;
	const { app, context, renderer } = aCtx;

	let html;

	if (renderer) {
		try {
			let ssrTimeoutMs;
			if (typeof aCtx.timeout === "number") {
				ssrTimeoutMs = aCtx.timeout;
			}

			html = await renderer(app, viewId, params, context, ssrTimeoutMs);
		} catch (error) {
			return createErrorNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					data: {
						name: ERROR_NAMES.VIEW2HTML_NODE_ERROR,
						code: ERROR_CODES.VIEW2HTML_NODE_ERROR,
						message: "Unknown View rendering error."
					},
					typeDescriptor: Type.Object({
						props: {
							message: Type.String({}),
							name: Type.String({})
						}
					}),
					error
				},
				aCtx,
				nCtx
			);
		}
	} else {
		html = `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8"><title>View to HTML</title></head>
<body><h1>Rendering View to HTML is disabled. Contact support.</h1></body>
</html>`;
	}

	return createSuccessNodeResult(
		{
			opts,
			outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
			data: html,
			typeDescriptor: Type.String({})
		},
		aCtx,
		nCtx
	);
}
