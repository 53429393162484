/**
 * Button React component
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ACTIVE_default, ClassList, ENABLED_default, TButtonProps, WORKING_default } from "../";

import { ENUM_DEFAULT_VALUE, getStringEnumCssValue, getStringEnumValue } from "../Functions/enumHelpers";
import {
	BUTTON_ROLE,
	BUTTON_ROLE_default,
	BUTTON_ROLE_string,
	BUTTON_ROLE_types
} from "../Enums/BUTTON_ROLE";
import { BUTTON_TYPE_string } from "../Enums/BUTTON_TYPE";
import { BUTTON_STYLE, BUTTON_STYLE_string } from "../Enums/BUTTON_STYLE";
import { SPACING } from "../Enums/SPACING";
import { Label } from "./Label";
import { StyleSheet } from "../Classes/StyleSheet";
import { IAriaProps, IBaseProps, IEventProps } from "./props";
import { Link } from "./Link";
import { TElementType } from "../Types/TElementType";
import { TElementProps } from "../Types/TElementProps";
import {
	COMPONENT_MODE,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";
import { LoadingInfo } from "./LoadingInfo";
import { resolveAriaProps } from "../Functions/ariaHelpers";
import { isBoolean, isFunction } from "@hexio_io/hae-lib-shared";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";
import { RoutingContext } from "../Routing/RoutingContext";
import { useStateProp } from "../Hooks/useStateProp";
import { IResolvedLink } from "../Routing/IResolvedLink";

/**
 * Button state selectors
 */
const HOVER_SELECTOR = ".button--mode-normal:hover";
const ACTIVE_SELECTOR = ".button--mode-normal:active, .button--active";

/**
 * Button style props
 */
export interface IButtonStyleProps {
	/** Background color */
	backgroundColor?: string;
	hoverBackgroundColor?: string;
	activeBackgroundColor?: string;

	/** Foreground color */
	foregroundColor?: string;
	hoverForegroundColor?: string;
	activeForegroundColor?: string;

	/** Border color */
	borderColor?: string;
	hoverBorderColor?: string;
	activeBorderColor?: string;

	/** Border width */
	borderWidth?: string;

	/** Border radius */
	borderRadius?: string;

	/** Padding */
	padding?: string;

	/** Button width */
	width?: string;
}

/**
 * Button click data
 */
export interface IButtonClickData {
	name?: string;
	active?: boolean;
	setActive?: (value: boolean) => void;
}

/**
 * Button props
 */
export interface IButtonProps
	extends IBaseProps,
		IEventProps<IButtonClickData>,
		IAriaProps,
		TButtonProps,
		IButtonStyleProps {
	/** Id */
	id?: string;

	/** Name */
	name?: string;

	/** Type */
	type?: BUTTON_TYPE_string;

	/** Role */
	role?: BUTTON_ROLE_string;

	/** Resolved link, has priority over "Link" */
	resolvedLink?: IResolvedLink;

	/** Link */
	link?: TSchemaConstObjectPropsSpec<TSchemaConstObjectProps>;

	/** Value */
	value?: string | number | boolean;

	/** Style */
	style?: BUTTON_STYLE_string;

	/** Button active */
	active?: boolean;

	/** Tab index */
	tabIndex?: number;

	/** Children */
	children?: unknown;

	/** Label children */
	labelChildren?: unknown;

	/** Stop propagation for on click event, defaults to "true" */
	stopPropagationOnClick?: boolean;

	/** Set active method */
	setActive?: (active: boolean) => void;

	/** On working state change handler */
	onWorkingStateChange?: (working: boolean) => void;
}

/**
 * Button component
 */
export const Button: React.FunctionComponent<IButtonProps> = (props) => {
	const {
		id,
		name,
		role,
		link,
		value = null,
		style = ENUM_DEFAULT_VALUE,
		labelText,
		labelIcon,
		tooltip,
		backgroundColor,
		hoverBackgroundColor,
		activeBackgroundColor,
		foregroundColor,
		hoverForegroundColor,
		activeForegroundColor,
		borderColor,
		hoverBorderColor,
		activeBorderColor,
		borderWidth,
		borderRadius,
		padding,
		width,
		enabled = ENABLED_default,
		componentPath,
		componentMode,
		children = null,
		labelChildren = null,
		tabIndex,
		stopPropagationOnClick = true,
		active = ACTIVE_default,
		setActive,
		onClick,
		onWorkingStateChange
	} = props;

	const type =
		props.resolvedLink || (link && link.locationData?.type !== "NONE") ? "LINK" : ENUM_DEFAULT_VALUE;

	const routingContext = React.useContext(RoutingContext);

	const elementRef = React.useRef<HTMLElement>();
	// const popupRef = React.useRef<IPopupRefProps>();

	const [ working, setWorking ] = useStateProp(props.working ?? WORKING_default);
	const workingRef = React.useRef(working);

	const safeSetWorking = React.useCallback(
		(value: boolean) => {
			if (elementRef.current) {
				setWorking(value);
			}
		},
		[ setWorking ]
	);

	React.useEffect(() => {
		if (isFunction(onWorkingStateChange)) {
			if (workingRef.current !== working) {
				workingRef.current = working;

				onWorkingStateChange(working);
			}
		}
	}, [ working ]);

	let elementType: TElementType = "button";
	let elementProps: TElementProps = {
		id,
		tabIndex,
		...resolveAriaProps(props)
	};

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName("button", componentPath, {
		componentClassList: props.classList,
		componentMode
	});

	// Button type / role

	let roleValue = BUTTON_ROLE.NONE;
	let match = false;

	switch (type) {
		case "DEFAULT": {
			roleValue = getStringEnumValue(BUTTON_ROLE, role, BUTTON_ROLE_default);

			classList.add(`button--role-${roleValue}`);

			elementProps.ref = elementRef;
			elementProps.type = BUTTON_ROLE_types[roleValue];

			if (!enabled) {
				elementProps.disabled = true;
			}

			break;
		}

		case "LINK": {
			if (enabled) {
				elementType = Link;
				elementProps = { ...elementProps, forwardRef: elementRef, componentMode };

				const resolvedLink =
					props.resolvedLink ||
					routingContext.resolveLink(link.locationData, {
						exact: isBoolean(link.exact) ? link.exact : false
					});

				elementProps.href = resolvedLink.url;
				elementProps.external = resolvedLink.isExternal;
				elementProps.target = link.target;

				if (resolvedLink.match) {
					match = true;
				}
			} else {
				elementType = "div";
				elementProps.ref = elementRef;
			}

			break;
		}
	}

	const styleValue = getStringEnumValue(BUTTON_STYLE, style);

	classList.addModifiers({
		type: type.toLowerCase(),
		style: style.toLowerCase(),
		enabled,
		disabled: !enabled,
		working,
		idle: !working,
		active: active || match,
		"icon-only": labelIcon?.source && (!labelText || !labelText.value)
	});

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		const selector = `.${idClassName}`;
		const enabledSelector = `${selector}.button--enabled`;
		const mainSelector = `${enabledSelector}:not(${HOVER_SELECTOR}, ${ACTIVE_SELECTOR})`;
		const hoverSelector = `${enabledSelector}${HOVER_SELECTOR}`;
		const activeSelector = `${enabledSelector}:is(${ACTIVE_SELECTOR})`;

		const tabOrCustomStyle = styleValue === BUTTON_STYLE.TAB || styleValue === BUTTON_STYLE.CUSTOM;
		const defaultOrTabOrCustomStyle = styleValue === BUTTON_STYLE.DEFAULT || tabOrCustomStyle;

		// Background color

		if (backgroundColor && defaultOrTabOrCustomStyle) {
			const name = "element-background-color";

			const focusAndWorkingSelector = `${enabledSelector}.button--mode-normal:focus-visible, ${enabledSelector}.button--working`;

			result.addColorProperties([
				{
					selector: mainSelector,
					name,
					value: backgroundColor
				},
				{
					selector: hoverSelector,
					name,
					value: hoverBackgroundColor || backgroundColor,
					valueSuffix: !hoverBackgroundColor ? "-hover" : undefined
				},
				{
					selector: focusAndWorkingSelector,
					name,
					value: backgroundColor,
					valueSuffix: "-active"
				},
				{
					selector: activeSelector,
					name,
					value: activeBackgroundColor || backgroundColor,
					valueSuffix: !activeBackgroundColor ? "-active" : undefined
				}
			]);
		}

		// Foreground color

		if (foregroundColor && defaultOrTabOrCustomStyle) {
			const name = "element-foreground-color";

			result.addColorProperties([
				{
					selector: mainSelector,
					name,
					value: foregroundColor
				},
				{
					selector: hoverSelector,
					name,
					value: hoverForegroundColor || foregroundColor,
					valueSuffix: !hoverForegroundColor ? "-hover" : undefined
				},
				{
					selector: activeSelector,
					name,
					value: activeForegroundColor || foregroundColor,
					valueSuffix: !activeForegroundColor ? "-active" : undefined
				}
			]);
		}

		// Border color

		if (borderColor && tabOrCustomStyle) {
			const name = "element-border-color";

			result.addColorProperties([
				{
					selector: mainSelector,
					name,
					value: borderColor
				},
				{
					selector: hoverSelector,
					name,
					value: hoverBorderColor || borderColor,
					valueSuffix: !hoverBorderColor ? "-hover" : undefined
				},
				{
					selector: activeSelector,
					name,
					value: activeBorderColor || borderColor,
					valueSuffix: !activeBorderColor ? "-active" : undefined
				}
			]);
		}

		// Border width

		if (borderWidth && tabOrCustomStyle) {
			result.addString(selector, `--element-border-width: ${borderWidth} !important;`);
		}

		// Border radius

		if (borderRadius && tabOrCustomStyle) {
			result.addString(selector, `--element-border-radius: ${borderRadius} !important;`);
		}

		// Padding

		if (padding && tabOrCustomStyle) {
			const paddingValue = getStringEnumCssValue(SPACING, padding, "spacing-");

			result.addString(selector, `--element-padding: ${paddingValue} !important;`);
		}

		// Width

		if (width) {
			result.addString(selector, `--element-width: ${width} !important;`);
		}

		return result;
	}, [
		idClassName,
		styleValue,
		backgroundColor,
		hoverBackgroundColor,
		activeBackgroundColor,
		foregroundColor,
		hoverForegroundColor,
		activeForegroundColor,
		borderColor,
		hoverBorderColor,
		activeBorderColor,
		borderWidth,
		borderRadius,
		padding,
		width
	]);

	styleSheetRegistry.add(idClassName, styleSheet);

	// Event handlers

	elementProps.onClick = async (
		event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
	) => {
		if (componentMode === COMPONENT_MODE.EDIT && isFunction(setActive)) {
			setActive(false);
			return;
		}

		if (componentMode !== COMPONENT_MODE.NORMAL) {
			event.stopPropagation();
			event.preventDefault();

			return;
		}

		if (stopPropagationOnClick) {
			event.stopPropagation();
		}

		if (elementType !== Link && roleValue !== BUTTON_ROLE.SUBMIT) {
			// Prevent default for Link is handled by its own onCLick handler
			// This also prevents from firing form's own onSubmit handler
			// Submit button must not have default event prevented
			event.preventDefault();
		}

		if (!enabled || working) {
			return;
		}

		if (typeof onClick === "function") {
			safeSetWorking(true);

			try {
				await onClick(value, {
					name,
					active,
					setActive
				});
			} catch (error) {
				console.log("Click event error", error);
			}

			safeSetWorking(false);
		}
	};

	if (tooltip) {
		if (elementType === Link) {
			elementProps.htmlTitle = tooltip;
		} else {
			elementProps.title = tooltip;
		}

		/*elementProps.onMouseEnter = () => {
			if (popupRef.current) {
				popupRef.current.show(elementRef.current);
			}
		}

		elementProps.onMouseLeave = () => {
			if (popupRef.current) {
				//popupRef.current.hide();
			}
		}*/
	}

	// Resolve Class list

	if (elementType === Link) {
		elementProps.classList = classList;
	} else {
		elementProps.className = classList.toClassName();
	}

	return React.createElement(
		elementType,
		elementProps,
		<>
			<Label
				text={labelText}
				icon={labelIcon}
				classList={new ClassList("button__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
				children={labelChildren}
			/>
			{children}
			{working ? (
				<LoadingInfo classList={new ClassList("button__loading-info")}>
					<div className="button__loading-info-background" />
				</LoadingInfo>
			) : null}
		</>
	);
};
