/**
 * Custom Chart component terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const codeMirror = {
	component: {
		label: "component:codeMirror#label",
		description: "component:codeMirror#description"
	},
	language: {
		label: "component:codeMirror.language#label",
	},
	theme: {
		label: "component:codeMirror.theme#label",
	},
	fontSize: {
		label: "component:codeMirror.fontSize#label",
	},
	tabSize: {
		label: "component:codeMirror.tabSize#label",
	},
	wrapLines: {
		label: "component:codeMirror.wrapLines#label",
	},
	value: {
		label: "component:codeMirror.value#label",
	},
	initialValue: {
		label: "component:codeMirror.initialValue#label",
	},
	fieldName: {
		label: "component:codeMirror.fieldName#label",
	},
	readOnly: {
		label: "component:codeMirror.readOnly#label",
	},
	validate: {
		label: "component:codeMirror.validate#label",
	},
	required: {
		label: "component:codeMirror.required#label",
	},
	customValidation: {
		label: "component:codeMirror.customValidation#label",
	},
	customValidationCondition: {
		label: "component:codeMirror.customValidationCondition#label",
	},
	minLength: {
		label: "component:codeMirror.minLength#label",
	},
	maxLength: {
		label: "component:codeMirror.maxLength#label",
	},
	valid: {
		label: "component:codeMirror.valid#label",
	},
	setValue: {
		label: "component:codeMirror.setValue#label",
	},
	clearValue: {
		label: "component:codeMirror.clearValue#label",
	},
	events: {
		change: {
			label: "component:codeMirror.events.change#label",
		}
	}
};
