/**
 * File Field Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum FILE_FIELD_TYPE {
	SIMPLE = "simple",
	LIST = "list"
	//GALLERY = "gallery"
}

export type FILE_FIELD_TYPE_string = keyof typeof FILE_FIELD_TYPE;

export const FILE_FIELD_TYPE_default: FILE_FIELD_TYPE_string = "SIMPLE";
