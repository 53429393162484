/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const AUTH = {
	LOGIN_FAILED_URL: "/",
	LOGIN_SUCCEED_URL: "/",
	DEFAULT_AUTH_PROVIDER_NAME: "adapptioOAuth",
	CHECK_TOKEN_BEFORE_EXPIRE_IN_SECONDS: 1800 // 30 minutes
};
