/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, DESIGN_CONTEXT_READ_MODE } from "@hexio_io/hae-lib-blueprint";

import { NODE_TYPES } from "./nodes/BlueprintNode";
import { DOC_TYPES } from "./DocTypes";
import { createBlueprintSchema } from "./BlueprintBase";
import { CONST } from "../constants";
import { BlueprintActionNodeTypes } from "./BlueprintNodes";

/**
 * Action blueprint "spec" property schema
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBlueprintActionSpec = (constantParams: boolean) =>
	BP.Const.Object({
		// @todo Translate!!!
		label: "action",
		constraints: {
			required: true
		},
		props: {
			params: BP.Prop(
				BP.Builder.Object({
					// @todo Add translation terms
					label: "Parameters",
					description: "A set of parameters the action can be called with.",
					constantOnly: constantParams
				})
			),
			timeout: BP.Prop(
				BP.Const.Integer({
					// @todo Add translation term + description
					label: "Timeout",
					default: CONST.ACTION.INVOCATION.DEFAULT_TIMEOUT,
					constraints: {
						required: true,
						min: CONST.ACTION.INVOCATION.MIN_TIMEOUT,
						/** Currently this is max possible timeout, because HttpClient has max timeout 300000 */
						max: CONST.ACTION.INVOCATION.MAX_TIMEOUT
					}
				})
			),
			maxRecursion: BP.Prop(
				BP.Const.Integer({
					label: "Max recursion",
					description:
						"Maximum number of the recursive action node calls. After exceeding this value the action will return an error.",
					default: CONST.ACTION.INVOCATION.NODE_MAX_RECURSION,
					constraints: {
						min: 1
					}
				})
			),
			requireAuthenticatedUser: BP.Prop(
				BP.Const.Boolean({
					// @todo Add translation term + description
					label: "Require authenticated user",
					description: "When true only logged in users will be able to invoke this action.",
					default: true
				})
			),
			private: BP.Prop(
				BP.Const.Boolean({
					// @todo Add translation term + description
					label: "Private",
					description: "When true this action can be invoked only from another action, from view or from integration. It can't be invoked via REST API.",
					default: false
				})
			),
			scheduled: BP.Prop(
				BP.OptGroup({
					label: "Scheduled",
					description: "Action will be executed automatically on the background.",
					enabledOpts: {
						allowExpression: false
					},
					value: BP.Const.Object({
						props: {
							once: BP.Prop(
								BP.Const.Boolean({
									label: "Once",
									description: "Execute action only once.",
									default: false
								})
							),
							interval: BP.Prop(
								BP.Const.Integer({
									label: "Interval [ms]",
									description: "Interval in milliseconds.",
									default: 60000,
									constraints: {
										min: 100
									}
								})
							)
						}
					})
				})
			),
			nodes: BP.Prop(
				BP.Conditional({
					condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
					value: BP.Special.FlowNodeList({
						nodeTypes: BlueprintActionNodeTypes,
						entryNode: {
							id: "start",
							type: NODE_TYPES.START,
							defaultPosition: {
								x: 10,
								y: 50
							},
							defaultOpts: {}
						}
					})
				})
			)
		}
	});

export type TBlueprintActionSpecSchema = ReturnType<typeof createBlueprintActionSpec>;

/**
 * Action Blueprint Schema
 */
export const BlueprintAction = createBlueprintSchema(DOC_TYPES.ACTION_V1, createBlueprintActionSpec(false));
export const BlueprintActionWithConstParams = createBlueprintSchema(
	DOC_TYPES.ACTION_V1,
	createBlueprintActionSpec(true)
);

/** Action Blueprint Schema Type */
export type TBlueprintActionSchema = typeof BlueprintAction;
