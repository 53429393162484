/**
 * Hexio App Engine Core
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { generatePath, matchPath } from "react-router-dom";
import { stringify as stringifyQs, parse as parseQs } from "qs";
import { INavigationResolver, IRoutingManager } from "@hexio_io/hae-lib-core";
import { createEventEmitter, emitEvent, isNumber, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IRouteListEntry, IRouteResolver } from "@hexio_io/hae-lib-blueprint";
import { IAppClientConfig_RoutesPathMap } from "../../shared/IAppClientConfig";
import {
	IResolvedLink,
	IResolvedLocation,
	IResolveLinkOpts,
	isLinkExternal,
	TLinkLocationSpec
} from "@hexio_io/hae-lib-components";

export class RoutingManager implements IRoutingManager {
	/** Route resolver */
	public routeResolver: IRouteResolver;

	/** Navigation resolver */
	public navigationResolver: INavigationResolver;

	/**
	 * Navigate event - emitted when location change
	 */
	public onNavigate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 */
	public constructor(private routesPathMap: IAppClientConfig_RoutesPathMap) {
		this.onNavigate = createEventEmitter();

		this.routeResolver = {
			getRouteByName: (routeKey) => this.getRouteByName(routeKey),
			getRouteList: () => {
				return Object.keys(this.routesPathMap).map((routeKey) => ({
					key: routeKey,
					label: this.routesPathMap[routeKey],
					path: this.routesPathMap[routeKey]
				}));
			},
			onInvalidate: createEventEmitter()
		};

		this.navigationResolver = {
			getCurrentLocation: () => this.getCurrentLocation(),
			resolveLink: (linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts) =>
				this.resolveLink(linkSpec, opts),
			onNavigate: this.onNavigate
		};

		window.addEventListener("popstate", () => {
			if (window.APP_DEBUG) {
				console.log("[RouteManager] Navigate");
			}

			emitEvent(this.onNavigate);
		});

		if (window.APP_DEBUG) {
			console.debug("[RouteManager] Configured routes path map:", routesPathMap);
		}
	}

	/**
	 * Return current location
	 */
	public getCurrentLocation(): IResolvedLocation {
		return {
			pathname: location.pathname,
			hash: location.hash.substr(1),
			query: parseQs(location.search.substr(1))
		};
	}

	/**
	 * Returns route object by key
	 *
	 * @param routeKey Route name
	 */
	private getRouteByName(routeKey: string): IRouteListEntry {
		const route = this.routesPathMap[routeKey];

		if (route) {
			return {
				key: routeKey,
				label: this.routesPathMap[routeKey],
				path: this.routesPathMap[routeKey]
			};
		} else {
			return null;
		}
	}

	/**
	 * Navigates to a new path (without reload)
	 *
	 * @param path New path
	 */
	public navigate(path: string | number): void {
		if (isNumber(path)) {
			history.go(path);
		} else if (path.startsWith("/auth") || (isLinkExternal(path) && !path.startsWith(location.origin))) {
			location.href = path;
		} else {
			history.pushState("", "", path);
		}

		emitEvent(this.onNavigate);
	}

	/**
	 * Resolves link based on link schema spec and options
	 *
	 * @param linkSpec Location specification
	 * @param opts Options
	 */
	public resolveLink(linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts): IResolvedLink {
		const location = this.getCurrentLocation();

		let linkUrl: string;
		let linkPath: string = null;

		switch (linkSpec.type) {
			case "ROUTE": {
				const route = this.getRouteByName(linkSpec.value.ROUTE.name);

				if (!route) {
					linkUrl = "#route-not-found";
					break;
				}

				try {
					linkUrl = linkPath = generatePath(route.path, linkSpec.value.ROUTE.params);
					const qs = stringifyQs(linkSpec.value.ROUTE.queryParams, {
						skipNulls: !linkSpec.value.ROUTE.serializeNullParams
					});

					if (qs) {
						linkUrl = linkUrl + "?" + qs;
					}
				} catch (err) {
					linkUrl = "#route-has-invalid-params";
					break;
				}

				break;
			}

			case "VIEW": {
				const route = this.getRouteByName("view");

				if (!route) {
					linkUrl = "#view-route-not-configured";
					break;
				}

				try {
					linkPath = generatePath(route.path, {
						viewId: linkSpec.value.VIEW.viewId
					});

					linkUrl =
						linkPath +
						"?" +
						stringifyQs({
							viewParams: linkSpec.value.VIEW.params
						});
				} catch (err) {
					linkUrl = "#view-route-has-invalid-path-configured";
					break;
				}

				break;
			}

			case "URL": {
				linkUrl = linkPath = linkSpec.value.URL;

				break;
			}

			case "NONE": {
				linkUrl = "";

				break;
			}
		}

		return {
			url: linkUrl,
			isExternal: isLinkExternal(linkUrl),
			match: linkPath
				? matchPath(location.pathname, {
						path: linkPath,
						exact: opts.exact,
						strict: opts.strict
				  })
				: null
		};
	}
}
