/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum ERROR_REPORT_TYPE {
	APP = "applicationError",
	RESOURCE = "resourceError",
	BLUEPRINT = "blueprintError",
	MODULE = "moduleError",
	EXTENSION = "extensionError"
}

export interface IErrorReport {
	type: ERROR_REPORT_TYPE;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}
