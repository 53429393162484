/**
 * Sidebar Layout schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const sidebarLayout = {
	content: {
		label: "schema:sidebarLayout.content#label",
		description: "schema:sidebarLayout.content#description"
	},
	sidebar: {
		label: "schema:sidebarLayout.sidebar#label",
		description: "schema:sidebarLayout.sidebar#description"
	},
	fixed: {
		label: "schema:sidebarLayout.fixed#label",
		description: "schema:sidebarLayout.fixed#description"
	},
	position: {
		label: "schema:sidebarLayout.position#label",
		description: "schema:sidebarLayout.position#description"
	},
	positionValues: {
		left: {
			label: "schema:sidebarLayout.position#values.left#label"
		},
		right: {
			label: "schema:sidebarLayout.position#values.right#label"
		}
	},
	width: {
		label: "schema:sidebarLayout.width#label",
		description: "schema:sidebarLayout.width#description"
	},
	widthValues: {
		small: {
			label: "schema:sidebarLayout.width#values.small#label",
			description: "schema:sidebarLayout.width#values.small#label"
		},
		medium: {
			label: "schema:sidebarLayout.width#values.medium#label",
			description: "schema:sidebarLayout.width#values.medium#label"
		},
		large: {
			label: "schema:sidebarLayout.width#values.large#label",
			description: "schema:sidebarLayout.width#values.large#label"
		}
	},
	widthConstraints: {
		patternErrorMessage: "schema:sidebarLayout.width#constraints.patternError#message"
	}
};
