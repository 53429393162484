/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { ERROR_CODES, ERROR_NAMES, IBaseErrorDetails, ServiceError } from "../errors";
import { IntegrationError } from "../errors";
import { IExecutionContext } from "../WebServer";
import { IIntegrationFunctionResult } from "./IIntegration";

/**
 * Integration Function Result Decorator
 *
 * @param paramsModelProps Parameters as DataModel
 */
export function ResultDecorator(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
	const originalMethod = descriptor.value;

	descriptor.value = async function (params, context: IExecutionContext, config) {
		try {
			const data = await originalMethod.call(this, params, context);
			const result: IIntegrationFunctionResult = { data };

			if (config?.withTypeDescriptor === true) {
				// TODO: type descriptor should be returned from the function
				result.typeDescriptor = Type.Any({});
			}

			return result;
		} catch (error) {
			const errorDetails: IBaseErrorDetails = {
				details: {
					integrationType: this.integrationDefinition?.name,
					integrationId: this.integrationName,
					functionName: propertyKey
				}
			};

			/** Catches ServiceErrors and IntegrationErrors and adds additional integration details. */
			if (error instanceof IntegrationError) {
				throw new IntegrationError(
					error.name,
					error.code,
					error.message,
					{
						details: {
							...(error.errorDetails?.details || {}),
							...errorDetails.details
						},
						safeDetails: {
							...(error.errorDetails?.safeDetails || {})
						}
					},
					error.sourceError
				);
			}

			throw new IntegrationError(
				ERROR_NAMES.INTEGRATION,
				ERROR_CODES.INTEGRATION,
				`Unhandled Error. Can't execute integration's function.`,
				errorDetails
			);
		}
	};
}
