/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

/**
 * Asset blueprint "spec" property schema
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const assetSpec = BP.Const.Object({
	// @todo Translate!!!
	label: "asset",
	constraints: {
		required: true
	},
	props: {
		isPublic: BP.Prop(
			BP.Const.Boolean({
				label: "Public",
				description: "Public asset will accessible on public static asset's route."
			})
		),
		metadata: BP.Prop(
			BP.Const.Object({
				label: "Metadata",
				description: "Asset's metadata.",
				props: {}
			})
		)
	}
});

export type TBlueprintAssetSpecSchema = typeof assetSpec;

/**
 * Asset Blueprint Schema
 */
export const BlueprintAsset = createBlueprintSchema(DOC_TYPES.ASSET_V1, assetSpec);

/** Asset Blueprint Schema Type */
export type TBlueprintAssetSchema = typeof BlueprintAsset;
