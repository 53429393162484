/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { TAllNodesSpec, TBlueprintErrorNodeOptsSchemaSpec } from "../../blueprints";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult } from "../helpers";

export async function errorNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintErrorNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;
	context.debug("Error node.");

	const { message, errorName, data } = opts;

	return createErrorNodeResult(
		{
			opts,
			outputName: null,
			data: {
				name: errorName || ERROR_NAMES.UNKNOWN_ERROR,
				code: ERROR_CODES.UNKNOWN_ERROR,
				message: message || "No additional information has been provided.",
				detail: data || null
			},
			typeDescriptor: Type.Object({
				props: {
					name: Type.String({}),
					code: Type.String({}),
					message: Type.String({}),
					detail: Type.Any({})
				}
			})
		},
		aCtx,
		nCtx
	);
}
