/**
 * Image schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const image = {
	source: {
		label: "schema:image.source#label",
		description: "schema:image.source#description"
	},
	alt: {
		label: "schema:image.alt#label",
		description: "schema:image.alt#description"
	},
	fit: {
		label: "schema:image.fit#label",
		description: "schema:image.fit#description"
	},
	fitValues: {
		contain: {
			label: "schema:image.fit#values.contain#label"
		},
		cover: {
			label: "schema:image.fit#values.cover#label"
		},
		fill: {
			label: "schema:image.fit#values.fill#label"
		}
	}
};
