/**
 * Hexio App Engine Node library.
 *
 * @package hae-lib-nore
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Query boolean parameter to boolean value
 * @param value Value
 * @param defaultValue Default value
 * @returns
 */
export function queryParamToBool(value: any, defaultValue: boolean): boolean {
	let result = defaultValue;

	if (typeof value === "string") {
		result = value.toLowerCase() === "true" ? true : false;
	} else if (typeof value === "boolean") {
		result = value;
	}

	return result;
}
