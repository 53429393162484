/**
 * Button Group Type enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum BUTTON_GROUP_TYPE {
	NONE = "none",
	TOGGLE = "toggle",
	SWITCH = "switch"
}

export type BUTTON_GROUP_TYPE_string = keyof typeof BUTTON_GROUP_TYPE;

export const BUTTON_GROUP_TYPE_default: BUTTON_GROUP_TYPE_string = "NONE";
