/**
 * Gauge Chart schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const gaugeChart = {
	value: {
		label: "schema:gaugeChart.value#label"
	},
	min: {
		label: "schema:gaugeChart.min#label"
	},
	max: {
		label: "schema:gaugeChart.max#label"
	},
	startAngle: {
		label: "schema:gaugeChart.startAngle#label"
	},
	endAngle: {
		label: "schema:gaugeChart.endAngle#label"
	},
	formatter: {
		label: "schema:gaugeChart.formatter#label",
		description: "schema:gaugeChart.formatter#description"
	},
	ranges: {
		label: "schema:gaugeChart.ranges#label"
	},
	rangeTypeData: {
		label: "schema:gaugeChart.ranges#props.typeData#label"
	},
	rangeTypeDataValues: {
		default: {
			label: "schema:gaugeChart.ranges#props.typeData#values.default#label"
		},
		null: {
			label: "schema:gaugeChart.ranges#props.typeData#values.null#label"
		}
	},
	rangeStartValue: {
		label: "schema:gaugeChart.ranges#props.startValue#label"
	},
	rangeEndValue: {
		label: "schema:gaugeChart.ranges#props.endValue#label"
	},
	position: {
		label: "schema:gaugeChart.position#label"
	},
	positionX: {
		label: "schema:gaugeChart.position#props.x#label",
		description: "schema:gaugeChart.position#props.x#description"
	},
	positionY: {
		label: "schema:gaugeChart.position#props.y#label",
		description: "schema:gaugeChart.position#props.y#description"
	},
	styleData: {
		label: "schema:gaugeChart.styleData#label"
	},
	styleDataValues: {
		angular: {
			label: "schema:gaugeChart.styleData#values.angular#label"
		},
		solid: {
			label: "schema:gaugeChart.styleData#values.solid#label"
		}
	},
	color: {
		label: "schema:gaugeChart.color#label"
	},
	size: {
		label: "schema:gaugeChart.size#label",
		description: "schema:gaugeChart.size#description"
	},
	hand: {
		label: "schema:gaugeChart.hand#label"
	},
	handOffset: {
		label: "schema:gaugeChart.hand#props.offset#label",
		description: "schema:gaugeChart.hand#props.offset#description"
	},
	handInnerRadius: {
		label: "schema:gaugeChart.hand#props.innerRadius#label",
		description: "schema:gaugeChart.hand#props.innerRadius#description"
	},
	handSize: {
		label: "schema:gaugeChart.hand#props.size#label",
		description: "schema:gaugeChart.hand#props.size#description"
	},
	ticks: {
		label: "schema:gaugeChart.ticks#label"
	},
	ticksValues: {
		label: "schema:gaugeChart.ticks#props.values#label"
	},
	ticksLines: {
		label: "schema:gaugeChart.ticks#props.lines#label"
	},
	label: {
		label: "schema:gaugeChart.label#label"
	}
};
