/**
 * Grid HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	ISchemaComponentListSpec,
	COMPONENT_MODE
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumCssValue,
	HAEComponentList,
	IHAEComponentListProps,
	mapResponsiveValue,
	SPACING,
	StyleSheet,
	THAEComponentDefinition,
	THAEComponentReact,
	getStringEnumValue,
	TElementProps,
	useLoading,
	LoadingInfo,
	useStyleSheetRegistry,
	getMedia,
	useEditContext,
	useComponentMainContext
} from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";
import { HAEComponentGrid_Props, TGridItemInheritedProps } from "./props";
import { getGridArea, getGridCellDimensions } from "./helpers";
import {
	handleResizeBegin,
	handleResizeEnd,
	handleResizeUpdate,
	IResizeInitialState,
	modifyModelOnDrop,
	resolveAllowResize,
	resolveChildInlineStyle
} from "./editModeFunctions";
import { GRID_ROLE, GRID_ROLE_default } from "../../Enums/GRID_ROLE";
import { isNonEmptyArray } from "@hexio_io/hae-lib-shared";

interface HAEComponentGrid_State {}

const HAEComponentGrid_Events = {
	...BaseEvents
};

const HAEComponentGrid_Definition = defineElementaryComponent<
	typeof HAEComponentGrid_Props,
	HAEComponentGrid_State,
	typeof HAEComponentGrid_Events
>({
	...termsEditor.components.grid.component,

	name: "grid",

	category: "layout",

	icon: "mdi/grid",

	docUrl: "...",

	order: 30,

	container: true,

	props: HAEComponentGrid_Props,

	events: HAEComponentGrid_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentGrid_React: THAEComponentReact<typeof HAEComponentGrid_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { content, columns, padding, spacing, loading, role } = props;

	const { safePath: componentPath, componentMode } = componentInstance;

	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editContext = useEditContext();
	const media = getMedia(inEditor ? editContext : undefined);

	const elementRef = React.useRef<HTMLDivElement>();

	let tagName = "div";

	const elementProps: TElementProps = {
		ref: elementRef
	};

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-grid",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const roleValue = getStringEnumValue(GRID_ROLE, role, GRID_ROLE_default);

	classList.addModifiers({
		role: roleValue,
		loading: !!loading
		//media
	});

	const [ loadingState, loadingElementRef ] = useLoading(!!loading);

	const stateInfoClassList = new ClassList("state-info").addModifiers({
		mode: componentMode === COMPONENT_MODE.EDIT ? "edit" : "normal"
	});

	switch (roleValue) {
		case GRID_ROLE.FORM:
			tagName = "form";

			elementProps.onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault();
			};

			break;
	}

	// Stylesheet

	const styleSheetRegistry = useStyleSheetRegistry();

	const innerSelector = `.${idClassName} > .cmp-grid__inner`;
	const contentSelector = `${innerSelector} > .cmp-grid__content`;
	const itemSelector = `${contentSelector} > .cmp-grid__item`;

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Columns

		if (columns) {
			result.addString(contentSelector, `--element-columns: ${columns};`);
		}

		// Padding

		if (padding) {
			const paddingValue = getStringEnumCssValue(SPACING, padding, "spacing-");

			result.addString(innerSelector, `--element-padding: ${paddingValue};`);
		}

		// Spacing

		if (spacing) {
			const spacingValue = getStringEnumCssValue(SPACING, spacing, "spacing-");

			result.addString(
				contentSelector,
				`--element-row-height: calc(var(--layout-grid-row-height) + (${spacingValue} / var(--layout-grid-row-height-divisor)));`
			);
			result.addString(itemSelector, `--element-spacing: ${spacingValue};`);
			result.addString(itemSelector, `--element-content-spacing: ${spacingValue};`);

			if (!spacingValue.endsWith("%")) {
				result.addString(contentSelector, `--element-offset-margin: ${spacingValue};`);
			}
		}

		return result;
	}, [ idClassName, innerSelector, contentSelector, itemSelector, columns, padding, spacing ]);

	// Items classlists & stylesheet

	const { itemsClassLists, itemsStyleSheet } = React.useMemo(() => {
		const resultClassLists: ClassList[] = [];
		const resultStyleSheet = new StyleSheet();

		if (isNonEmptyArray(content)) {
			content.forEach((item, index) => {
				const { classList: itemClassList, idClassName: itemIdClassName } =
					ClassList.getElementClassListAndIdClassName("cmp-grid__item", item.safePath, {
						componentMode
					});
				const itemIdSelector = `${itemSelector}.${itemIdClassName}`;

				const itemPositionValue = mapResponsiveValue(item.inheritedProps.gridItemPosition);

				if (itemPositionValue.length) {
					resultStyleSheet.addResponsiveValue(
						itemPositionValue,
						itemIdSelector,
						(position: { x: number; y: number; width: number; height: number }, mediaQuery) => {
							let { x, y, width, height } = position;

							// Fix values only for value without media query (base one)

							if (!mediaQuery) {
								if (!Number.isFinite(x)) {
									x = 0;
								}

								if (!Number.isFinite(y)) {
									y = 0;
								}

								if (!Number.isFinite(width)) {
									width = 1;
								}

								if (!Number.isFinite(height)) {
									height = 1;
								}
							} else {
								if (
									!Number.isFinite(x) ||
									!Number.isFinite(y) ||
									!Number.isFinite(width) ||
									!Number.isFinite(height)
								) {
									return "";
								}
							}

							// eslint-disable-next-line max-len
							return `--element-layout-height: calc(${height} * var(--element-row-height)); grid-area: ${getGridArea(
								x,
								y,
								width,
								height
							)};`;
						},
						media
					);
				}

				if (item.inheritedProps.gridItemSpacing) {
					const itemSpacingValue = getStringEnumCssValue(
						SPACING,
						item.inheritedProps.gridItemSpacing,
						"spacing-"
					);

					resultStyleSheet.addString(itemIdSelector, `--element-spacing: ${itemSpacingValue};`);
				}

				resultClassLists[index] = itemClassList;
			});
		}

		return {
			itemsClassLists: resultClassLists,
			itemsStyleSheet: resultStyleSheet
		};
	}, [ idClassName, innerSelector, contentSelector, itemSelector, content, media ]);

	const allStyleSheets = React.useMemo(() => {
		return new StyleSheet(...styleSheet, ...itemsStyleSheet);
	}, [ styleSheet.toString(), itemsStyleSheet.toString() ]);

	styleSheetRegistry.add(idClassName, allStyleSheets);

	// Edit mode

	// eslint-disable-next-line max-len
	let componentListEditProps: Omit<
		IHAEComponentListProps<TGridItemInheritedProps, never>,
		"components" | "componentPath" | "componentMode"
	> = {};

	if (componentMode === COMPONENT_MODE.EDIT) {
		componentListEditProps = {
			childInlineStyle: (element, index) => {
				return resolveChildInlineStyle(
					element,
					index,
					media,
					getGridCellDimensions(elementRef.current),
					columns
				);
			},
			allowResize: resolveAllowResize,
			onResizeBegin: (subject, resizeOffset) => {
				return handleResizeBegin(
					subject,
					resizeOffset,
					media,
					getGridCellDimensions(elementRef.current)
				);
			},
			onResizeUpdate: (subject, element, resizeOffset, initialState) => {
				return handleResizeUpdate(
					subject,
					element,
					resizeOffset,
					initialState as IResizeInitialState,
					columns
				);
			},
			onResizeEnd: (subject, resizeOffset, initialState) => {
				return handleResizeEnd(
					subject,
					resizeOffset,
					initialState as IResizeInitialState,
					media,
					columns
				);
			},
			modifyModelOnDrop: (itemModel, element) => {
				return modifyModelOnDrop(
					itemModel,
					element,
					media,
					getGridCellDimensions(elementRef.current),
					columns
				);
			},
			modelNode: componentInstance.modelNode?.props.props.content as unknown as any
		};
	}

	// Resolve Class list

	elementProps.className = classList.toClassName();

	return React.createElement(
		tagName,
		elementProps,
		<>
			{componentMode === COMPONENT_MODE.EDIT || !loading || loading.renderContent ? (
				<div className="cmp-grid__inner">
					<HAEComponentList<TGridItemInheritedProps>
						components={content as ISchemaComponentListSpec<TGridItemInheritedProps>}
						componentPath={[ ...componentPath, "component-list" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-grid__content")}
						childClassList={(element) =>
							element?.type === "component"
								? itemsClassLists[element.srcIndex]
								: new ClassList("cmp-grid__item")
						}
						childComponentClassList={new ClassList("cmp-grid__item-component")}
						{...componentListEditProps}
					/>
				</div>
			) : null}

			{loadingState ? (
				<div ref={loadingElementRef} className={stateInfoClassList.toClassName()}>
					<LoadingInfo
						iconSizeClass={componentMode !== COMPONENT_MODE.NORMAL ? "SMALL" : undefined}
					/>
				</div>
			) : null}
		</>
	);
};

export const HAEComponentGrid: THAEComponentDefinition<typeof HAEComponentGrid_Definition> = {
	...HAEComponentGrid_Definition,
	reactComponent: HAEComponentGrid_React
};
