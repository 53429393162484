/**
 * Creates Field ClassList Modifiers
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ClassList, TClassListModifiers, TModifiersObject } from "@hexio_io/hae-lib-components";
import { isBoolean } from "@hexio_io/hae-lib-shared";

/**
 * Creates modifiers
 */
export function createFieldClassListModifiers(
	classList: ClassList,
	{
		enabled,
		empty,
		touched,
		changed,
		valid
	}: {
		enabled: boolean;
		empty?: boolean;
		touched: boolean;
		changed: boolean;
		valid?: boolean;
	}
): TClassListModifiers {
	const modifiers: TModifiersObject = {
		enabled,
		disabled: !enabled,
		changed,
		unchanged: !changed,
		touched,
		untouched: !touched
	};

	if (isBoolean(valid)) {
		modifiers.valid = valid;
		modifiers.invalid = !valid;
	}

	if (isBoolean(empty)) {
		modifiers.empty = empty;
		modifiers.filled = !empty;
	}

	return classList.createModifiers(modifiers);
}
