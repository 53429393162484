/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import jsYaml from "js-yaml";
import { serializeIDTToData } from "./IDTToData";

import { TBlueprintIDTNode } from "./ISchemaIDT";

export function serializeIDTToYAML(idtNode: TBlueprintIDTNode): string {
	const data = serializeIDTToData(idtNode);

	const yaml = jsYaml.dump(data, {
		skipInvalid: true,
		noRefs: true,
		quotingType: '"'
	});

	return yaml;
}
