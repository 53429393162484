/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent } from "@hexio_io/hae-lib-shared";
import {
	HttpRequest,
	HTTP_METHOD,
	IHttpHeaders,
	IHttpResponse,
	TRequestBody,
	TRequestQuery
} from "./HttpRequest";

declare global {
	interface Window {
		APP_DEBUG: boolean;
	}
}

/**
 * HTTP Client class
 */
export class HttpClient {
	/** Server URL to prefix path with */
	private serverUrl: string;

	/** Global headers */
	private headers: IHttpHeaders = {};

	/** Global timeout */
	private timeoutMs = 300000;

	/** Event emitted when request ends up with an error */
	public onError = createEventEmitter<Error>();

	/**
	 * HTTP Client class constructor
	 *
	 * @param serverUrl Base server URL to prefix path with
	 */
	public constructor(serverUrl = "", headers: IHttpHeaders = {}) {
		this.serverUrl = serverUrl;
		this.headers = headers;
	}

	public getServerUrl(): string {
		return this.serverUrl;
	}

	/**
	 * Sets the global header
	 *
	 * @param name Name
	 * @param value Value
	 */
	public setHeader(name: string, value: string): void {
		this.headers[name] = value;
	}

	/**
	 * Returns globally set header by name
	 *
	 * @param name Name
	 */
	public getHeader(name: string): string {
		return this.headers[name];
	}

	/**
	 * Returns all headers
	 */
	public getAllHeaders(): IHttpHeaders {
		return this.headers;
	}

	/**
	 * Creates an instance of HttpRequest and sends it immediately
	 *
	 * @param method Method of request
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	private async sendRequest<TResBody>(
		method: HTTP_METHOD,
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		const req = new HttpRequest<TResBody>(
			method,
			`${this.serverUrl}${path}`,
			query,
			body,
			{
				...this.headers,
				...(headers || {})
			},
			timeout !== undefined ? timeout : this.timeoutMs
		);

		if (window.APP_DEBUG) {
			console.debug("[HTTP Client]: Request", req);
		}

		try {
			const res = await req.send();

			if (window.APP_DEBUG) {
				console.debug("[HTTP Client] Response:", res);
			}

			return res;
		} catch (err) {
			if (window.APP_DEBUG) {
				console.debug("[HTTP Client] Error:", err);
			}

			emitEvent(this.onError, err);

			throw err;
		}
	}

	/**
	 * Shorthand method that creates GET HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async get<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.GET, path, query, body, headers, timeout);
	}

	/**
	 * Shorthand method that creates POST HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async post<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.POST, path, query, body, headers, timeout);
	}

	/**
	 * Shorthand method that creates PUT HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async put<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.PUT, path, query, body, headers, timeout);
	}

	/**
	 * Shorthand method that creates PATCH HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async patch<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.PATCH, path, query, body, headers, timeout);
	}

	/**
	 * Shorthand method that creates DELETE HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async delete<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.DELETE, path, query, body, headers, timeout);
	}

	/**
	 * Shorthand method that creates HEAD HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async head(
		path: string,
		query?: TRequestQuery,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<never>> {
		return this.sendRequest<never>(HTTP_METHOD.HEAD, path, query, null, headers, timeout);
	}

	/**
	 * Shorthand method that creates OPTIONS HttpRequest and sends it immediately
	 *
	 * @param path Relative path
	 * @param query Query of request
	 * @param body Body (data) to send
	 * @param headers Request headers
	 * @param timeout Request timeout
	 */
	public async options<TResBody>(
		path: string,
		query?: TRequestQuery,
		body?: TRequestBody,
		headers?: IHttpHeaders,
		timeout?: number
	): Promise<IHttpResponse<TResBody>> {
		return this.sendRequest<TResBody>(HTTP_METHOD.OPTIONS, path, query, body, headers, timeout);
	}
}
