/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	SCHEMA_CONST_ANY_VALUE_TYPE
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../terms";

interface DataSourceConstant_State {}

export const DataSourceConstant_Opts = {
	value: BP.Prop(
		BP.Any({
			label: termsEditor.dataSources.constant.value.label,
			description: termsEditor.dataSources.constant.value.description,
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
		})
	)
};

export const DataSourceConstant_Events = {};

/**
 * Constant data source
 */
export const DataSourceConstant = defineElementaryDataSource<
	typeof DataSourceConstant_Opts,
	DataSourceConstant_State,
	typeof DataSourceConstant_Events
>({
	name: "constant",
	label: termsEditor.dataSources.constant.root.label,
	description: termsEditor.dataSources.constant.root.description,
	icon: "mdi/function-variant",
	opts: DataSourceConstant_Opts,
	events: DataSourceConstant_Events,
	resolve: () => {
		return {};
	},
	getScopeData: (opts) => {
		return opts.value;
	},
	getScopeType: () => {
		return Type.Any({});
	}
});
