/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IThemeRefItem, IThemeRefResolver, TThemeStyleNames } from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IResourceRegistry } from "../registries";
import { IThemeResourceProps, RESOURCE_TYPES } from "../resources";

/**
 *Theme Ref Resolver
 */
export class ThemeRefResolver implements IThemeRefResolver {
	/**
	 * Invalidate event - emitted when action list has changed (and param schemas must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	public constructor(protected resourceRegistry: IResourceRegistry) {
		this.onInvalidate = createEventEmitter();
	}

	public getThemeList(): IThemeRefItem[] {
		return this.resourceRegistry
			.getItemList()
			.filter((resource) => resource.resourceType === RESOURCE_TYPES.THEME)
			.map((resource) => {
				return {
					themeId: resource.id,
					label: resource.parsedData.label,
					styles: resource.parsedData.styles
				};
			});
	}

	public getStylesById(themeId: string): TThemeStyleNames {
		const resource = this.resourceRegistry
			.getItemList()
			.filter((resource) => resource.id === themeId)[0] as IThemeResourceProps;

		if (!resource) {
			return null;
		}

		return resource.parsedData.styles;
	}
}
