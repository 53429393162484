/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaChildNode,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators,
	assignParentToModelProps,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { applyCodeArg } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TypeDescObject } from "../Shared/ITypeDescriptor";
import { ValidatorObject } from "../validators/ValidatorObject";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { ISchemaValue, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaConstObject, TSchemaConstObjectProps } from "./const/SchemaConstObject";
import { IViewRefItem, IViewRefResolver } from "../Resolvers/IViewRefResolver";
import { ISchemaConstMap } from "./const/SchemaConstMap";
import { ISchemaConstAny, SCHEMA_CONST_ANY_VALUE_TYPE } from "./const/SchemaConstAny";
import { SchemaValueString } from "./value/SchemaValueString";
import { SchemaValueMap } from "./value/SchemaValueMap";
import { SchemaValueAny } from "./value/SchemaValueAny";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { ISchemaDynamic, SchemaDynamic } from "./SchemaDynamic";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

type TSchemaViewIDSchema = ISchemaValue<ISchemaConstString>;
type TSchemaViewResolvedParamsSchema =
	| ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>
	| typeof defaultParamsSchema;
type TSchemaViewGenericParamsSchema = ISchemaValue<ISchemaConstMap<ISchemaValue<ISchemaConstAny>>>;
type TSchemaViewUnionParamsSchema = TSchemaViewResolvedParamsSchema | TSchemaViewGenericParamsSchema;

type TSchemaViewParamsSchema = ISchemaDynamic<IParamsResolveParams, TSchemaViewUnionParamsSchema>;

interface IParamsResolveParams {
	viewId: string;
}

/**
 * Schema model
 */
export interface ISchemaViewRefModel extends IModelNode<ISchemaViewRef> {
	/** View ID */
	viewId: TGetBlueprintSchemaModel<TSchemaViewIDSchema>;
	/** View params - can be null! */
	params: TGetBlueprintSchemaModel<TSchemaViewParamsSchema>;
	/** Change event handler bound to model */
	__changeHandler: () => void;
	/** Resolver invalidate event bound to mdoel */
	__invalidateHandler: () => void;
	/** If the view ID constant change event has been bound */
	__viewIdConstantChangeEventBound: boolean;
	/** Last available view ID - for ref counting */
	__lastViewId: string;
}

/**
 * Schema options
 */
export interface ISchemaViewRefOpts extends IBlueprintSchemaOpts {
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
}

/**
 * Default value
 */
export interface ISchemaViewRefDefault {
	/** View ID */
	viewId: TGetBlueprintSchemaDefault<TSchemaViewIDSchema>;
	/** View params - can be null! */
	params: TGetBlueprintSchemaDefault<TSchemaViewParamsSchema>;
}

/**
 * Schema spec
 */
export interface ISchemaViewRefSpec {
	/** View ID */
	viewId: TGetBlueprintSchemaSpec<TSchemaViewIDSchema>;

	/** View params - can be null! */
	params: TGetBlueprintSchemaSpec<TSchemaViewParamsSchema>;
}

/**
 * Schema type
 */
export interface ISchemaViewRef
	extends IBlueprintSchema<
		ISchemaViewRefOpts,
		ISchemaViewRefModel,
		ISchemaViewRefSpec,
		ISchemaViewRefDefault
	> {
	/**
	 * Returns a list of available configuration names
	 */
	getViewList: (modelNode: ISchemaViewRefModel) => IViewRefItem[];
}

/**
 * Default schema for view ref parameters
 */
const defaultParamsSchema = SchemaValueMap({
	label: "Parameters",
	constraints: {
		required: false
	},
	value: SchemaValueAny({
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
	})
});

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaViewRef(opts: ISchemaViewRefOpts): ISchemaViewRef {
	type TIDModel = TGetBlueprintSchemaModel<TSchemaViewIDSchema>;
	type TParamsModel = TGetBlueprintSchemaModel<TSchemaViewParamsSchema>;

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });

	const resolvedParamsCache = new WeakMap();

	const getResolvedParamsSchema = (
		resolver: IViewRefResolver,
		params: IParamsResolveParams
	): TSchemaViewResolvedParamsSchema => {
		if (params.viewId) {
			const props = resolver.getParamsSchemaById(params.viewId);

			if (props === false) {
				return defaultParamsSchema;
			}

			if (!props) {
				throw new Error(`Unknown view ID '${params.viewId}'.`);
			}

			if (resolvedParamsCache.has(props)) {
				return resolvedParamsCache.get(props);
			}

			const retSchema = SchemaValueObject({
				label: "Parameters",
				constraints: {
					required: true
				},
				props: props
			});

			resolvedParamsCache.set(props, retSchema);
			return retSchema;
		} else {
			return null;
		}
	};

	const resolveParamsFromDesignCtx = (dCtx: DesignContext, params: IParamsResolveParams) => {
		return getResolvedParamsSchema(dCtx.getResolver<IViewRefResolver>("viewRef"), params);
	};

	const idSchema: TSchemaViewIDSchema = SchemaValueString({
		label: "View",
		constraints: {
			required: opts?.constraints?.required,
			min: 1
		},
		allowTranslate: false,
		icon: "mdi/link",
		editorOptions: {
			controlType: "viewSelector",
			layoutType: "noHeader"
		}
	});

	const paramsSchema = SchemaDynamic<IParamsResolveParams, TSchemaViewUnionParamsSchema>({
		resolveSchemaDesign: resolveParamsFromDesignCtx,
		defaultSchema: defaultParamsSchema
	});

	const keyPropRules = {
		viewId: {
			required: true,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				const actionList = dCtx.getResolver<IViewRefResolver>("viewRef").getViewList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = actionList.map((item) => ({
						kind: CMPL_ITEM_KIND.Reference,
						label: item.label,
						insertText: item.viewId
					}));

					return items;
				});
			}
		},
		params: {
			required: true
		}
	};

	const updateRef = (dCtx: DesignContext, prevId: string, newId: string) => {
		if (prevId !== null) {
			dCtx.__removeRef("view", prevId);
		}

		if (newId !== null) {
			dCtx.__addRef("view", newId);
		}
	};

	const schema = createEmptySchema<ISchemaViewRef>("viewRef", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "viewId", "params" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		idModel: TIDModel,
		paramsModel: TParamsModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			viewId: idModel,
			params: paramsModel,
			__changeHandler: null,
			__invalidateHandler: null,
			__viewIdConstantChangeEventBound: false,
			__lastViewId: null
		});

		const model = assignParentToChildrenOf(modelNode);

		const handleChange = (wasInvalidated?: boolean) => {
			if (model.viewId.type === SCHEMA_VALUE_TYPE.CONST) {
				if (!model.__viewIdConstantChangeEventBound) {
					onEvent(model.viewId.constant.changeEvent, model.__changeHandler);
					model.__viewIdConstantChangeEventBound = true;
				}

				model.params.schema.resolve(
					model.params,
					{
						viewId: model.viewId.constant.value
					},
					!wasInvalidated,
					wasInvalidated
				);

				updateRef(dCtx, model.__lastViewId, model.viewId.constant.value);
				model.__lastViewId = model.viewId.constant.value;
			} else {
				model.params.schema.reset(model.params, !wasInvalidated);

				updateRef(dCtx, model.__lastViewId, null);
				model.__lastViewId = null;
			}
		};

		model.__changeHandler = () => handleChange(false);
		model.__invalidateHandler = () => handleChange(true);

		onEvent(model.viewId.changeEvent, model.__changeHandler);
		onEvent(dCtx.getResolver<IViewRefResolver>("viewRef").onInvalidate, model.__invalidateHandler);

		if (model.viewId.type === SCHEMA_VALUE_TYPE.CONST) {
			onEvent(model.viewId.constant.changeEvent, model.__changeHandler);
			model.__viewIdConstantChangeEventBound = true;

			updateRef(dCtx, null, model.viewId.constant.value);
			model.__lastViewId = model.viewId.constant.value;
		}

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [ rootValidator as any ], defaultValue);

		const idModel = idSchema.createDefault(dCtx, null, defaultValue?.viewId);
		let paramsModel: TParamsModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params, {
				resolveParams: { viewId: idModel.constant.value }
			}) as TParamsModel;
		} else {
			paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params) as TParamsModel;
		}

		return createModel(dCtx, idModel, paramsModel, errors, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedId = modelNode.viewId.schema.clone(dCtx, modelNode.viewId, null);
		const clonedParams = modelNode.params.schema.clone(dCtx, modelNode.params, null);

		return createModel(dCtx, clonedId, clonedParams, modelNode.validationErrors, parent);
	};

	schema.destroy = (modelNode) => {
		modelNode.viewId.schema.destroy(modelNode.viewId);
		modelNode.params.schema.destroy(modelNode.params);

		offEvent(
			modelNode.ctx.getResolver<IViewRefResolver>("viewRef").onInvalidate,
			modelNode.__invalidateHandler
		);

		updateRef(modelNode.ctx, modelNode.__lastViewId, null);
		modelNode.__lastViewId = null;

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const {
			keys,
			isValid: isRootKeysValid,
			keysValid: rootKeysValidity
		} = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		const idModel = rootKeysValidity.viewId ? idSchema.parse(dCtx, keys["viewId"].value, null) : null;

		if (keys.params && keys.params.key && idtNode.parseInfo && keys.params.key.parseInfo) {
			paramsSchema.provideCompletion(
				dCtx,
				{
					uri: idtNode.parseInfo.loc.uri,
					range: {
						start: {
							line: keys.params.key.parseInfo.loc.range.end.line,
							col: keys.params.key.parseInfo.loc.range.end.col + 2
						},
						end: keys.params.parseInfo.loc.range.end
					}
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				keys.params.value,
				idModel && idModel.type === SCHEMA_VALUE_TYPE.CONST
					? {
							resolveParams: { viewId: idModel ? idModel.constant.value : null }
					  }
					: undefined
			);
		}

		if (!isRootKeysValid) {
			return schema.createDefault(dCtx, parent);
		}

		let paramsModel: TParamsModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null, {
				resolveParams: { viewId: idModel.constant.value }
			}) as TParamsModel;
		} else {
			paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null) as TParamsModel;
		}

		if (paramsModel.hasError) {
			if (keys["viewId"].value.parseInfo) {
				dCtx.logParseError(keys["viewId"].value.parseInfo.loc.uri, {
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.INVALID_REF,
					message: `Cannot resolve view: ${paramsModel.error}`,
					parsePath: keys["viewId"].value.path,
					range: keys["viewId"].value.parseInfo.loc.range
				});
			}
		}

		return createModel(dCtx, idModel, paramsModel, [], parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// name
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[viewId]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{viewId}" ]),
						value: "viewId"
					} as IBlueprintIDTScalar,
					value: modelNode.viewId.schema.serialize(modelNode.viewId, path.concat([ "viewId" ]))
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[params]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{params}" ]),
						value: "params"
					} as IBlueprintIDTScalar,
					value: modelNode.params.schema.serialize(modelNode.params, path.concat([ "params" ]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const viewId = modelNode.viewId.schema.render(
			rCtx,
			modelNode.viewId,
			path.concat([ "viewId" ]),
			scope,
			prevSpec?.viewId
		);
		const params = modelNode.params.schema.render(
			rCtx,
			modelNode.params,
			path.concat([ "params" ]),
			scope,
			prevSpec?.params
		);

		return {
			viewId: viewId,
			params: params
		};
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate params resolve state
		if (modelNode.params.hasError) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve view: ${modelNode.params.error}`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:viewRef#errors.cannotResolve",
					viewId: modelNode.params.__lastParams.viewId
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		// Log notice when dynamic viewId
		if (modelNode.viewId.type !== SCHEMA_VALUE_TYPE.CONST) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.HINT,
				name: DOC_ERROR_NAME.DYNAMIC_VALUE_NOTICE,
				// eslint-disable-next-line max-len
				message: `View ID is set dynamically. This prevents validation of view parameters at design time and may result in runtime errors when configured incorrectly.`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:view#errors.viewIdSetDynamically"
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		const idCmp = modelNode.viewId.schema.compileRender(cCtx, modelNode.viewId, path.concat("viewId"));
		const paramsCmp = modelNode.params.schema.compileRender(
			cCtx,
			modelNode.params,
			path.concat("params")
		);

		const idCode = applyCodeArg(
			idCmp,
			`typeof pv==="object"&&pv!==null?pv.viewId:undefined`,
			`pt.concat(["viewId"])`
		);
		const paramsCode = applyCodeArg(
			paramsCmp,
			`typeof pv==="object"&&pv!==null?pv.params:undefined`,
			`pt.concat(["params"])`
		);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({viewId:${idCode},params:${paramsCode}})`
		};
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		let isValid = true;

		// Validate self as object
		isValid =
			applyRuntimeValidators<{ [K: string]: unknown }>(
				rCtx,
				path,
				modelNodeId,
				[ rootValidator ],
				DOC_ERROR_SEVERITY.ERROR,
				value as unknown as { [K: string]: unknown }
			) && isValid;

		if (validateChildren && value instanceof Object) {
			isValid =
				idSchema.validate(rCtx, path.concat([ "viewId" ]), modelNodeId, value.viewId, true) &&
				isValid;
			isValid =
				paramsSchema.validate(rCtx, path.concat([ "params" ]), modelNodeId, value.params, true, {
					resolveParams: {
						viewId: value?.viewId
					}
				}) && isValid;
		}

		return isValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[ rootValidator ],
			DOC_ERROR_SEVERITY.ERROR
		);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {
			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const idValid = idSchema.compileValidate(cCtx, path.concat([ "viewId" ]), modelNodeId, true);
			const paramsValid = paramsSchema.compileValidate(
				cCtx,
				path.concat([ "params" ]),
				modelNodeId,
				true
			);

			if (idValid) {
				expressions.push(`_vd=(${idValid})(v.viewId,pt)&&_vd;`);
			}

			if (paramsValid) {
				expressions.push(`_vd=(${paramsValid})(v.params,pt)&&_vd;`);
			}

			expressions.push(`}`);
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaViewRef", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				viewId:
					modelNode?.viewId.schema.getTypeDescriptor(modelNode.viewId) ||
					idSchema.getTypeDescriptor(),
				params:
					modelNode?.params.schema.getTypeDescriptor(modelNode.params) ||
					defaultParamsSchema.getTypeDescriptor()
			},
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getViewList = (modelNode: ISchemaViewRefModel) => {
		return modelNode.ctx.getResolver<IViewRefResolver>("viewRef").getViewList();
	};

	schema.getChildNodes = (modelNode) => {
		const children: IBlueprintSchemaChildNode[] = [
			{
				key: "viewId",
				node: modelNode.viewId
			}
		];

		if (modelNode.params) {
			children.push({
				key: "params",
				node: modelNode.params
			});
		}

		return children;
	};

	return schema;
}
