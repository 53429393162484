/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { TAllNodesSpec, TBlueprintResponseNodeOptsSchemaSpec } from "../../blueprints";
import { createSuccessNodeResult } from "../helpers";

export async function responseNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintResponseNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	/** Filter default values from blueprint schema. */
	if (opts?.["headers"] && Object.keys(opts?.["headers"]).length === 0) {
		opts["headers"] = undefined;
	}

	/** Filter default values from blueprint schema. */
	if (opts?.["body"] === null) {
		opts["body"] = undefined;
	}

	return createSuccessNodeResult(
		{
			opts,
			outputName: null,
			data: opts,
			typeDescriptor: Type.Object({
				props: {
					status: Type.Integer({}),
					headers: Type.Map({
						items: { "[K: string]": Type.String({}) }
					}),
					body: Type.Any({})
				}
			})
		},
		aCtx,
		nCtx
	);
}
