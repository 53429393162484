/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	DesignContext,
	IThemeCustomCss,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintTheme, DOC_TYPES, TBlueprintThemeSchema } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { IThemeResourceProps, IThemeResourceType } from "./IThemeResource";

type TThemeSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintTheme>;

export class ThemeResourceV1 implements IThemeResourceType {
	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.THEME_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.THEME;
	}

	public get statsName(): string {
		return "themes";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-theme-v1");
	}

	public setup(resource: IThemeResourceProps): IThemeResourceProps {
		resource.resourceType = RESOURCE_TYPES.THEME;
		resource.parsingDetails.isRegistered = true;

		return resource;
	}

	protected combineCustomCss(resource: IThemeResourceProps): IThemeResourceProps {
		resource.parsedData.theme = {
			customCss: resource.parsedData.spec.spec.customCss || [],
			styles: resource.parsedData.spec.spec.styles || {}
		};

		resource.parsedData.theme.styles = resource.parsedData.theme?.styles || {};
		const styles = resource.parsedData.theme.styles;

		const rootCss: { [K: string]: IThemeCustomCss } =
			resource.parsedData.theme.customCss?.reduce((acc, val) => {
				acc[val.name] = val;
				return acc;
			}, {} as { [K: string]: IThemeCustomCss }) || {};

		for (const [ name, style ] of Object.entries(styles)) {
			if (style) {
				styles[name] = { label: style.label };
			} else {
				continue;
			}

			const mergedCss: { [K: string]: IThemeCustomCss } = { ...rootCss };

			for (const styleCss of style.customCss) {
				if (styleCss?.name) {
					/** Overwrite theme style if needed. */
					mergedCss[styleCss.name] = styleCss;
				}
			}

			styles[name]["customCss"] = Object.values(mergedCss);
		}

		return resource;
	}

	public async scan(resource: IThemeResourceProps): Promise<IThemeResourceProps> {
		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Scan theme '${uri}'.`);

		let dCtx: DesignContext;
		let model: TThemeSchemaModel;

		try {
			resource.parsingDetails.isValidTheme = false;

			dCtx = resourceManager.createDCtx(true);

			resource = await resourceManager.parseModel<IThemeResourceProps>(resource, dCtx, BlueprintTheme);
			model = resource.parsedData.model as TThemeSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse theme model.");
				return resource;
			}

			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec as TGetBlueprintSchemaSpec<TBlueprintThemeSchema>;

			if (!spec) {
				this.logger.warn("Can't parse theme model.");
				return resource;
			}

			resource = this.combineCustomCss(resource);

			resource.parsingDetails.isValidTheme = true;
			resource.parsedData.label = spec.spec.label;
			resource.parsedData.description = spec.spec.description;

			resource.parsedData.styles = Object.keys(spec.spec.styles).map((name) => {
				const style = spec.spec.styles[name];
				const label = style?.label || name;
				return { name, label };
			});

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't scan theme '${uri}'.`, error);
			return resource;
		} finally {
			if (model) {
				model.schema.destroy(model);
			}
			if (dCtx) {
				dCtx.destroy();
			}
		}
	}

	public async parse(resource: IThemeResourceProps): Promise<IThemeResourceProps> {
		return resource;
	}

	public getDependenciesToReload(resource: IThemeResourceProps): string[] {
		return [];
	}
}

export function getThemeCss(resource: IThemeResourceProps, styleName?: string): string {
	let mergedCss = "";

	if (!resource.parsedData.defaultCss) {
		resource.parsedData.defaultCss =
			resource.parsedData.theme?.customCss?.reduce((acc, val) => {
				acc += val.content || "";
				return acc;
			}, "") || "";
	}

	mergedCss += resource.parsedData.defaultCss;

	resource.parsedData.css = resource.parsedData.css || {};

	if (styleName) {
		if (!resource.parsedData.css[styleName]) {
			resource.parsedData.css[styleName] =
				resource.parsedData.theme?.styles?.[styleName]?.customCss?.reduce((acc, val) => {
					acc += val.content || "";
					return acc;
				}, "") || "";
		}

		mergedCss += "\n";

		mergedCss += resource.parsedData.css[styleName];
	}

	return mergedCss;
}
