/**
 * Toast Message Manager
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, TSimpleEventEmitter, getTimestamp } from "@hexio_io/hae-lib-shared";
import { IActivityContainerManager } from "./useActivityContainer";
import { IToastMessage, IToastMessageActivityItem } from "./ToastMessage";

/**
 * Toast Message Manager interface
 */
export interface IToastMessageManager<TActivityItem, TData>
	extends IActivityContainerManager<TActivityItem, TData> {}

/**
 * Toast Message Manager class
 */

export class ToastMessageManager implements IToastMessageManager<IToastMessageActivityItem, IToastMessage> {
	private items: IToastMessageActivityItem[] = [];

	public onUpdate: TSimpleEventEmitter<IToastMessageActivityItem>;

	/**
	 * Toast Message Manager class constructor
	 */
	public constructor() {
		this.onUpdate = createEventEmitter<IToastMessageActivityItem>();
	}

	/**
	 * Returns items
	 */
	public getItems(): IToastMessageActivityItem[] {
		return this.items;
	}

	/**
	 * Adds item
	 *
	 * @param activityItem Activity item
	 */
	public addItem(activityItem: IToastMessageActivityItem): string {
		if (!this.items.some((item) => item.id === activityItem.id)) {
			this.items.push(activityItem);

			emitEvent(this.onUpdate, activityItem);
		}

		return activityItem.id;
	}

	/**
	 * Adds item data
	 *
	 * @param data Item data
	 */
	public addItemData(data: IToastMessage): string {
		const id =
			data.id ||
			`${getTimestamp()}_${data.type}_${
				data.message ? JSON.stringify(data.message).replace(/\W/g, "") : "NONE"
			}`;

		return this.addItem({
			id,
			data
		});
	}

	/**
	 * Removes item
	 *
	 * @param id Item id
	 */
	public removeItem(id: string): void {
		const index = this.items.findIndex((item) => item.id === id);

		if (index >= 0) {
			this.items.splice(index, 1);

			emitEvent(this.onUpdate);
		}
	}

	/**
	 * Removes all items
	 */
	public removeAllItems(): void {
		this.items = [];

		emitEvent(this.onUpdate);
	}
}
