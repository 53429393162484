/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorBooleanHandler = IBlueprintSchemaValidatorHandler<boolean>;
type TValidatorBoolean<TOpts> = IBlueprintSchemaValidator<boolean, TOpts>;

/**
 * Boolean validator options
 */
export interface IValidatorBooleanOpts {
	/** Required value */
	required?: boolean;
	/** Constant value */
	const?: boolean;
}

/**
 * Validator name
 */
const VALIDATOR_NAME = "ValidatorBoolean";

/**
 * Boolean value validator
 */
export const ValidatorBoolean: TValidatorBoolean<IValidatorBooleanOpts> = (
	opts: IValidatorBooleanOpts
): TValidatorBooleanHandler => {
	if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
	}

	if (opts.const !== undefined && opts.const !== null && typeof opts.const !== "boolean") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `const` to be a boolean");
	}

	return {
		validate: (value: boolean): IBlueprintSchemaValidationError[] => {
			const errors = [];

			if (
				(opts.required === true && typeof value !== "boolean") ||
				(!opts.required && !(value === null || value === undefined) && typeof value !== "boolean")
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
					message: "Should be a boolean"
				});
			}

			if (
				opts.const !== undefined &&
				opts.const !== null &&
				typeof value === "boolean" &&
				value !== opts.const
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.CONST,
					message: `Should be equal to const '${opts.const}'`
				});
			}

			return errors;
		},

		compile: (): string => {
			const parts = [];

			if (opts.required === true) {
				// eslint-disable-next-line max-len
				parts.push(
					`if( typeof value !== "boolean" ){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}", message: "Should be a boolean" }); }`
				);
			}

			if (!opts.required) {
				// eslint-disable-next-line max-len
				parts.push(
					`if( !(value === null || value === undefined) && typeof value !== "boolean" ){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}", message: "Should be a boolean" }); }`
				);
			}

			if (opts.const !== undefined && opts.const !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if( typeof value === "boolean" && value !== ${opts.const} ){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.CONST}", message: "Should be equal to const '${opts.const}'" }); }`
				);
			}

			// Minification
			const code = parts
				.join(" ")
				.replace(/{ /g, "{")
				.replace(/ }/g, "}")
				.replace(/\( /g, "(")
				.replace(/ \)/g, ")")
				.replace(/: "/g, ':"')
				.replace(/ ,/g, ",")
				.replace(/ === /g, "===")
				.replace(/ && /g, "&&")
				.replace(/ !== /g, "!==")
				.replace(/"boolean"/g, "b")
				.replace(/value/g, "v")
				.replace(/errors.push/g, "e.push");

			return `const e=[];const b="boolean";${code} return e;`;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		export: (): any => {
			return exportValidator(VALIDATOR_NAME, [ opts ]);
		}
	};
};
