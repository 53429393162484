/**
 * Overlays
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { toNumber } from "@hexio_io/hae-lib-shared";

import {
	Button,
	BUTTON_STYLE,
	ClassList,
	getStringEnumKeyByValue,
	ICON_NAME,
	IIconProps,
	Label,
	makeCssTransition,
	useTranslate
} from "../..";

import { useActivityContainer } from "./useActivityContainer";
import { ACTIVITY_ITEM_STATE } from "./IActivityItem";
import { IOverlayManager } from "./OverlayManager";
import {
	IConfirmationDialogOverlay,
	IInfoDialogOverlay,
	IOverlayActivityItem,
	IOverlayCloseResult,
	IViewDialogOverlay,
	IViewOverlayBase,
	IViewSidebarOverlay,
	OVERLAY_CLOSABLE,
	OVERLAY_CLOSABLE_default,
	OVERLAY_SIZE,
	OVERLAY_SIZE_default,
	OVERLAY_TYPE,
	TOverlay
} from "./Overlay";
import { IOverlayComponent } from "./IOverlayComponent";
import { InfoDialogOverlay } from "./InfoDialogOverlay";
import { ConfirmationDialogOverlay } from "./ConfirmationDialogOverlay";
import { ViewOverlay } from "./ViewOverlay";
import { termsRuntime } from "../../terms";
import { COMPONENT_MODE } from "@hexio_io/hae-lib-blueprint";
import { getStringEnumValue } from "../../Functions/enumHelpers";
import { StyleSheet } from "../../Classes/StyleSheet";
import { useStyleSheetRegistry } from "../../Hooks/useStyleSheetRegistry";

/**
 * Overlays props
 */
export interface IOverlaysProps {
	/** Manager */
	manager: IOverlayManager<IOverlayActivityItem, TOverlay>;
}

/**
 * Overlays component
 */
export const Overlays: React.FunctionComponent<IOverlaysProps> = (props) => {
	const [ items, setItemAsReady, removeItem ] = useActivityContainer<IOverlayActivityItem>(props.manager);

	const lastId = items.map((item) => item.id)[items.length - 1];

	return (
		<>
			{items.map((item) => {
				const { type } = item.data;

				let closableValue = OVERLAY_CLOSABLE.YES;
				let dialog = true;

				switch (type) {
					case OVERLAY_TYPE.DIALOG_CONFIRMATION:
						closableValue = OVERLAY_CLOSABLE.NO;
						break;

					case OVERLAY_TYPE.DIALOG_INFO: {
						const viewDialogData = item.data as IInfoDialogOverlay;

						closableValue = getStringEnumValue(
							OVERLAY_CLOSABLE,
							viewDialogData.closable,
							OVERLAY_CLOSABLE_default
						);

						break;
					}

					case OVERLAY_TYPE.DIALOG_VIEW: {
						const viewDialogData = item.data as IViewDialogOverlay;

						closableValue = getStringEnumValue(
							OVERLAY_CLOSABLE,
							viewDialogData.closable,
							OVERLAY_CLOSABLE_default
						);

						break;
					}

					case OVERLAY_TYPE.SIDEBAR_VIEW: {
						const viewSidebarData = item.data as IViewSidebarOverlay;

						closableValue = getStringEnumValue(
							OVERLAY_CLOSABLE,
							viewSidebarData.closable,
							OVERLAY_CLOSABLE_default
						);

						dialog = false;

						break;
					}
				}

				const remove = (removeFromState = false, result?: IOverlayCloseResult) => {
					removeItem(item.id, result?.buttonId, removeFromState);
				};

				const content = (
					<OverlayItem
						id={item.id}
						data={item.data}
						closableValue={closableValue}
						dialog={dialog}
						state={item.state}
						setAsReady={() => {
							setItemAsReady(item.id);
						}}
						remove={remove}
					/>
				);

				// Display background in case of last (foremost) overlay
				return (
					<OverlayContainer
						key={item.id}
						id={item.id}
						background={lastId === item.id}
						closableValue={closableValue}
						dialog={dialog}
						remove={remove}
					>
						{content}
					</OverlayContainer>
				);
			})}
		</>
	);
};

/**
 * Overlay Container props
 */
export interface IOverlayContainerProps extends IOverlayComponent {
	/** Children */
	children: unknown;

	/** Show background */
	background?: boolean;

	/** Closable */
	closableValue: OVERLAY_CLOSABLE;

	/** Whether or not is overlay a dialog */
	dialog: boolean;
}

/**
 * Overlay Container
 */
export const OverlayContainer: React.FunctionComponent<IOverlayContainerProps> = (props) => {
	const { id, children, background, closableValue, dialog, remove } = props;

	const elementRef = React.useRef<HTMLDivElement>();

	React.useEffect(() => {
		const element = elementRef.current;

		if (element) {
			element.style.alignItems = element.scrollHeight > element.offsetHeight ? "flex-start" : "";
		}

		document.documentElement.classList.toggle("document-element--overlay-background", background);

		return () => {
			if (props.background) {
				document.documentElement.classList.remove("document-element--overlay-background");
			}
		};
	});

	const classList = new ClassList("overlay-container");

	classList.addModifiers({
		background: props.background,
		dialog
	});

	// Create event handler

	const _elementClickHandler = React.useMemo(() => {
		if (closableValue === OVERLAY_CLOSABLE.NO || closableValue === OVERLAY_CLOSABLE.BUTTON) {
			return;
		}

		return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const { target } = event;

			if (!(target instanceof Element) || !target.closest(".overlay-item")) {
				event.preventDefault();

				remove(undefined, { overlayId: id, buttonId: null, customData: null });
			}
		};
	}, [ id, closableValue ]);

	return (
		<div ref={elementRef} className={classList.toClassName()} onClick={_elementClickHandler}>
			{dialog ? <div className="overlay-container__content">{children}</div> : children}
		</div>
	);
};

/**
 * Overlay Item props
 */
export interface IOverlayItemProps extends IOverlayComponent {
	/** Item data */
	data: TOverlay;

	/** Closable */
	closableValue: OVERLAY_CLOSABLE;

	/** Whether or not is overlay a dialog */
	dialog: boolean;

	/** Item state */
	state: ACTIVITY_ITEM_STATE;

	/** Sets item as ready (state is set to default) */
	setAsReady: () => void;
}

/**
 * Overlay Item
 */
export const OverlayItem: React.FunctionComponent<IOverlayItemProps> = (props) => {
	const { id, data, closableValue, dialog, state, setAsReady, remove } = props;

	const { type, size, header } = data;

	const elementRef = React.useRef<HTMLDivElement>();

	const t = useTranslate();

	const componentPath = [ "overlays", id ];

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"overlay-item",
		componentPath
	);

	const sizeValue = size
		? size in OVERLAY_SIZE
			? getStringEnumValue(OVERLAY_SIZE, size, OVERLAY_SIZE_default)
			: null
		: OVERLAY_SIZE_default;

	classList.addModifiers({
		state,
		type,
		size: sizeValue
	});

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		if (size && !sizeValue) {
			result.addString(`.${idClassName}`, `--element-width: ${size} !important;`);
		}

		return result;
	}, [ idClassName, size, sizeValue ]);

	if (!styleSheet.isEmpty()) {
		styleSheetRegistry.add(idClassName, styleSheet);
	}

	const added = state === ACTIVITY_ITEM_STATE.ADDED;
	const removed = state === ACTIVITY_ITEM_STATE.REMOVED;

	// In case when Item changes state (was just added or is to be removed)

	React.useEffect(() => {
		if (!added && !removed) {
			return;
		}

		let height: number;
		let width: number;

		const computedStyle = getComputedStyle(elementRef.current);

		if (dialog) {
			height = toNumber(computedStyle.height);
		} else {
			width = toNumber(computedStyle.width);
		}

		const transitionProperties = dialog ? "margin-bottom, opacity" : "opacity, transform";

		if (added) {
			makeCssTransition(
				elementRef.current,
				dialog
					? {
							marginBottom: `-${height}px`,
							opacity: "0"
					  }
					: {
							opacity: "0",
							transform: `translateX(${width}px)`
					  },
				dialog
					? {
							marginBottom: "0",
							opacity: "1"
					  }
					: {
							opacity: "1",
							transform: "translateX(0px)"
					  },
				transitionProperties,
				"--element-transition-duration",
				setAsReady
			);
		} else if (removed) {
			makeCssTransition(
				elementRef.current,
				dialog
					? {
							marginBottom: "0",
							opacity: "1"
					  }
					: {
							opacity: "1",
							transform: "translateX(0px)"
					  },
				dialog
					? {
							marginBottom: `-${height}px`,
							opacity: "0"
					  }
					: {
							opacity: "0",
							transform: `translateX(${width}px)`
					  },
				transitionProperties,
				"--element-transition-duration",
				() => {
					remove(true);
				}
			);
		}
	}, [ state ]);

	// Create initial style

	const style: React.CSSProperties = {};

	if (added) {
		style.opacity = "0";
	}

	// Create event handler

	const _closeClickHandler = React.useMemo(() => {
		if (closableValue === OVERLAY_CLOSABLE.NO || closableValue === OVERLAY_CLOSABLE.CLICK_OUTSIDE) {
			return;
		}

		return () => remove(undefined, { overlayId: id, buttonId: null, customData: null });
	}, [ id, closableValue ]);

	// Render
	return (
		<div ref={elementRef} className={classList.toClassName()} style={style}>
			{header ? (
				<div className="overlay-item__header">
					<Label
						text={header.text}
						icon={header.icon as IIconProps}
						componentPath={[ ...componentPath, "header" ]}
						componentMode={COMPONENT_MODE.NORMAL}
						classList={new ClassList("overlay-item__header-label")}
					/>

					{_closeClickHandler ? (
						<Button
							style={getStringEnumKeyByValue(BUTTON_STYLE, BUTTON_STYLE.CLEAR)}
							labelIcon={{ source: ICON_NAME.CLOSE }}
							componentPath={[ ...componentPath, "close-button" ]}
							componentMode={COMPONENT_MODE.NORMAL}
							classList={new ClassList("overlay-item__close-button")}
							aria-label={t("runtime", termsRuntime.ui.overlays.close)}
							onClick={_closeClickHandler}
						/>
					) : null}
				</div>
			) : null}

			{(() => {
				switch (type) {
					case OVERLAY_TYPE.DIALOG_INFO: {
						return (
							<InfoDialogOverlay
								id={id}
								data={data as IInfoDialogOverlay}
								componentPath={componentPath}
								componentMode={COMPONENT_MODE.NORMAL}
								remove={remove}
							/>
						);
					}

					case OVERLAY_TYPE.DIALOG_CONFIRMATION: {
						return (
							<ConfirmationDialogOverlay
								id={id}
								data={data as IConfirmationDialogOverlay}
								componentPath={componentPath}
								componentMode={COMPONENT_MODE.NORMAL}
								remove={remove}
							/>
						);
					}

					case OVERLAY_TYPE.DIALOG_VIEW:
					case OVERLAY_TYPE.SIDEBAR_VIEW: {
						const viewOverlayData = data as IViewOverlayBase;

						if (viewOverlayData.viewRuntimeContext) {
							return (
								<ViewOverlay
									id={id}
									data={viewOverlayData}
									componentPath={componentPath}
									componentMode={COMPONENT_MODE.NORMAL}
									remove={remove}
								/>
							);
						}

						return null;
					}
				}
			})()}
		</div>
	);
};
