/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

// UUID cannot be imported with ES module style 🤦‍♂️
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { v4 as uuid } from "uuid";

import { BP, declareFunction, Type } from "@hexio_io/hae-lib-blueprint";

export const genRangeFunc = declareFunction({
	name: "GEN_RANGE",
	category: "generators",
	label: "Generate Range",
	description: "Returns an array of numbers within given range.",
	argRequiredCount: 2,
	argSchemas: [
		BP.Integer({
			label: "From",
			constraints: {
				required: true
			}
		}),
		BP.Integer({
			label: "To",
			constraints: {
				required: true
			}
		}),
		BP.Integer({
			label: "Step"
		})
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Integer({}) ]
	}),
	render: (_rCtx, args) => {
		const from = args[0]();
		const to = Math.max(from + 1, args[1]());
		const step = Math.max(1, args[2]() || 1);

		const res = [];

		for (let i = from; i < to; i += step) {
			res.push(i);
		}

		return res;
	}
});

export const genUuidFunc = declareFunction({
	name: "UUID",
	category: "generators",
	label: "Generate UUID",
	description: "Returns a UUID v4.",
	argRequiredCount: 0,
	argSchemas: [],
	argRestSchema: null,
	returnType: Type.Array({
		items: [ Type.Integer({}) ]
	}),
	render: () => {
		return uuid();
	}
});
