/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ERROR_CODES, ERROR_NAMES, IntegrationError } from "../errors";

/**
 * Removes null values from integration's options and integration function's params
 *
 * @param params Parameters
 * @param maxNestedLevel Max nested level
 * @param currentNestedLevel Current nested level
 *
 * @returns
 */
export function fixIntegrationParams(params: any, maxNestedLevel = 500, currentNestedLevel = 0): any {
	if (currentNestedLevel > maxNestedLevel) {
		throw new IntegrationError(
			ERROR_NAMES.INTEGRATION_EXEC,
			ERROR_CODES.INTEGRATION_EXEC,
			`Can't process integration function's params. Max nested level (${maxNestedLevel}) reached.`
		);
	}

	if (params === null) {
		return undefined;
	}

	if (params instanceof Buffer) {
		return params;
	}

	const _currentNestedLevel = ++currentNestedLevel;

	if (Array.isArray(params)) {
		params = params.map((value) => {
			if (value && typeof value === "object") {
				return fixIntegrationParams(value, maxNestedLevel, _currentNestedLevel);
			}

			return value;
		});

		return params;
	}

	if (typeof params === "object") {
		// Return if that's an empty object
		if (Object.keys(params).length === 0) {
			return params;
		}

		if ("$__preserveNulls__$" in params) {
			return params["$__preserveNulls__$"];
		}

		let fixedParams;
		Object.keys(params).forEach((key) => {
			const fixedParam = fixIntegrationParams(params[key], maxNestedLevel, _currentNestedLevel);

			if (fixedParam !== undefined) {
				if (!fixedParams) {
					fixedParams = {};
				}

				fixedParams[key] = fixedParam;
			}
		});

		params = fixedParams;
	}

	return params;
}
