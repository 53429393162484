/**
 * Image schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, TSchemaConstObjectPropsSpec } from "@hexio_io/hae-lib-blueprint";

import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { IMAGE_FIT, IMAGE_FIT_default } from "../Enums/IMAGE_FIT";
import { termsEditor } from "../terms";
import { LinkSchema } from "./Link";
import { BorderRadiusSchema } from "./BorderRadius";

/**
 * Image prop groups
 */
export const ImagePropGroups = {
	common: {
		...termsEditor.schemas.propGroups.common,
		id: "common",
		order: 0
	},

	link: {
		...termsEditor.schemas.propGroups.link,
		id: "link",
		order: 10
	},

	style: {
		...termsEditor.schemas.propGroups.style,
		id: "style",
		order: 20
	}
};

/**
 * Image props
 */
export const ImageProps = {
	source: BP.Prop(
		BP.String({
			...termsEditor.schemas.image.source,
			default: "",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		10,
		ImagePropGroups.common
	),

	alt: BP.Prop(
		BP.String({
			...termsEditor.schemas.image.alt,
			default: ""
		}),
		20,
		ImagePropGroups.common
	),

	fit: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.image.fit,
			options: getValuesFromStringEnum(IMAGE_FIT, termsEditor.schemas.image.fitValues),
			default: IMAGE_FIT_default
		}),
		30,
		ImagePropGroups.common
	),

	link: BP.Prop(LinkSchema(), 40, ImagePropGroups.link),

	borderRadius: BP.Prop(BorderRadiusSchema(), 50, ImagePropGroups.style)
};

/**
 * Image props type
 */
export type TImageProps = Partial<TSchemaConstObjectPropsSpec<typeof ImageProps>>;
