/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTScalar
} from "../../IDT/ISchemaIDT";
import { IBlueprintSchemaScalar, IBlueprintSchemaOpts } from "../../Schema/IBlueprintSchema";
import {
	applyRuntimeValidators,
	cloneModelNode,
	compileRuntimeValidators,
	compileScalarNodeRender,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	renderScalarNode,
	validateDefaultValue,
	validateParsedValueAndReport,
	validateValueAndUpdateModel
} from "../../Schema/SchemaHelpers";
import { applyCodeArg, inlineValue } from "../../Context/CompileUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../../Shared/IDocumentError";
import { DesignContext } from "../../Context/DesignContext";
import { IValidatorStringOpts, ValidatorString } from "../../validators/ValidatorString";
import { IBlueprintSchemaValidationError, IBlueprintSchemaValidatorHandler } from "../../Validator/IBlueprintSchemaValidator";
import { exportSchema } from "../../ExportImportSchema/ExportSchema";
import { TypeDescString } from "../../Shared/ITypeDescriptor";
import { TSchemaExpressionFnResult } from "../../Expression/ExpTypes";
import {
	handleExpressionRefactoring,
	ISchemaExpressionBasedModel,
	logExpressionErrorsToContext,
	parseExpression
} from "../expressionHelpers";
import { EXP_PARSER_MODE } from "../../Expression/ExpParser";
import { compile } from "../../Expression/ExpCompiler";
import { createEventEmitter, emitEvent, offEvent, onEvent } from "@hexio_io/hae-lib-shared"
import { MODEL_CHANGE_TYPE } from "../../Schema/IModelNode";

/**
 * Schema value type
 */
type TSpecType = string;

/**
 * Schema options
 */
export interface ISchemaConstStringOpts extends IBlueprintSchemaOpts {
	/** Default value for a new node */
	default?: string;
	/** Base validation constraints */
	constraints?: IValidatorStringOpts;
	/** Custom validators */
	validators?: IBlueprintSchemaValidatorHandler<TSpecType>[];
	/** Fallback value to return when validation fails */
	fallbackValue?: TSpecType;
	/** If to allow string interpolation - default to true */
	allowInterpolation?: boolean;
	/** Optional format for the editor - none, json, javascript, etc. */
	format?: string;
}

/**
 * Schema type
 */
export type ISchemaConstString = IBlueprintSchemaScalar<ISchemaConstStringOpts, ISchemaConstStringModel, TSpecType>

/**
 * Schema model
 */
export interface ISchemaConstStringModel extends ISchemaExpressionBasedModel<ISchemaConstString> {
	value: TSpecType;
	isExpression: boolean;
}

/**
 * Schema: String scalar constant
 *
 * @param opts Schema options
 */
export function SchemaConstString(opts: ISchemaConstStringOpts): ISchemaConstString {

	const validators: IBlueprintSchemaValidatorHandler<TSpecType>[] = [
		ValidatorString(opts.constraints || {})
	].concat(opts.validators || []);

	const errSeverity = opts.fallbackValue !== undefined ? DOC_ERROR_SEVERITY.WARNING : DOC_ERROR_SEVERITY.ERROR;

	const hasInterpolation = (value: string) => {
		return opts.allowInterpolation !== false && (typeof value === "string" && value.includes("${"));
	};

	const schema = createEmptySchema<ISchemaConstString>("constString", opts);

	const createModel = (dCtx: DesignContext, value: TSpecType, parent, validationErrors: IBlueprintSchemaValidationError[]) => {

		const model = createModelNode(schema, dCtx, parent, validationErrors, {
			value: value,
			// @todo
			isExpression: hasInterpolation(value),
			expAst: null,
			expParseErrors: [],
			expParseTrace: null,
			expCompiledCode: null,
			expCompiledFn: null,
			expLastValue: null,
			expLastValueChangeEvent: createEventEmitter(),
			__refactoringHandler: null,
		});

		if (model.isExpression) {
			parseExpression(model, EXP_PARSER_MODE.STRING_TEMPLATE);
		}

		model.__refactoringHandler = (event) => {
			if (model.isExpression && handleExpressionRefactoring(model, event)) {
				parseExpression(model, EXP_PARSER_MODE.STRING_TEMPLATE);
				handleModelNodeChange(model, MODEL_CHANGE_TYPE.VALUE);
			}
		};

		onEvent(dCtx.identifierRenameEvent, model.__refactoringHandler);

		return model;

	};

	schema.createDefault = (dCtx, parent, defaultValue) => {

		const value = defaultValue !== null && defaultValue !== undefined ? defaultValue : opts.default !== undefined ? opts.default : null;

		// Validate only if has no interpolation => is not an expression
		const errors = !hasInterpolation(defaultValue)
			? validateDefaultValue(schema, validators, value)
			: [];

		return createModel(
			dCtx,
			value,
			parent,
			errors,
		);

	}

	schema.clone = (dCtx, modelNode, parent) => {

		return cloneModelNode(dCtx, modelNode, parent, {
			value: modelNode.value,
			isExpression: modelNode.isExpression,
			expAst: modelNode.expAst,
			expParseErrors: modelNode.expParseErrors.slice(),
			expParseTrace: modelNode.expParseTrace ? modelNode.expParseTrace.slice() : null,
			expCompiledCode: modelNode.expCompiledCode,
			expLastValueChangeEvent: createEventEmitter(),
			expCompiledFn: modelNode.expCompiledFn,
		});

	};

	schema.destroy = (modelNode) => {

		offEvent(modelNode.ctx.identifierRenameEvent, modelNode.__refactoringHandler);

		modelNode.value = undefined;
		modelNode.expAst = null;
		modelNode.expParseErrors = null;
		modelNode.expParseTrace = null;
		modelNode.expCompiledCode = null;
		modelNode.expCompiledFn = null;
		modelNode.expLastValue = null;
		modelNode.__refactoringHandler = null;

		destroyModelNode(modelNode);

	}

	schema.parse = (dCtx, idtNode, parent) => {

		if (!idtNode) {
			return schema.createDefault(dCtx, parent);
		}

		if (idtNode.type !== BP_IDT_TYPE.SCALAR || (
			idtNode.type == BP_IDT_TYPE.SCALAR &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.STRING &&
			idtNode.subType !== BP_IDT_SCALAR_SUBTYPE.NULL
		)) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.STR_NOT_STRING,
					message: "Expecting a string",
					parsePath: idtNode.path
				});
			}

			return schema.createDefault(dCtx, parent);
		}

		// Validate only if has no interpolation => is not an expression
		const errors = !hasInterpolation(idtNode.value as string)
			? validateParsedValueAndReport(dCtx, idtNode, validators, idtNode.value as TSpecType)
			: [];

		const model = createModel(dCtx, idtNode.value as TSpecType, parent,errors);

		if (model.isExpression) {
			logExpressionErrorsToContext(model, idtNode);
		}

		return model;

	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			return null;
		});

	};

	schema.serialize = (modelNode, path) => {

		if (modelNode.value !== null) {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.STRING,
				path: path,
				value: modelNode.value
			} as IBlueprintIDTScalar;

		} else {

			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;

		}

	};

	schema.render = (rCtx, modelNode, path, scope) => {

		modelNode.lastScopeFromRender = scope;

		if (modelNode.isExpression) {

			let cmpResult: TSchemaExpressionFnResult;

			if (modelNode.expCompiledFn) {
				cmpResult = modelNode.expCompiledFn(rCtx, scope, path);
			} else {
				cmpResult = null;
			}

			if (cmpResult !== modelNode.expLastValue) {
				modelNode.expLastValue = cmpResult;
				emitEvent(modelNode.expLastValueChangeEvent);
			}

			const value = cmpResult ? cmpResult.result as TSpecType : null;
			const isValid = schema.validate(rCtx, path, modelNode.nodeId, value, true);

			if (isValid) {
				return value;
			} else {
				return (opts.fallbackValue !== undefined ? opts.fallbackValue : null) as TSpecType;
			}

		} else {

			return renderScalarNode(
				modelNode, scope,
				modelNode.value,
				opts.fallbackValue !== undefined && opts.fallbackValue !== null
					? opts.fallbackValue
					: opts.default !== undefined ? opts.default : null
			);

		}

	};

	schema.compileRender = (cCtx, modelNode, path) => {

		if (modelNode.isExpression) {

			let code: string = null;

			if (modelNode.expAst) {
				const compiledRes = compile(cCtx, modelNode.nodeId, modelNode.expAst, modelNode.value, false);
				code = compiledRes.code;
			}

			const value = {
				isScoped: true,
				code: `(s,pv,pt)=>${code ? `(${code})(s,pt).result` : "null"}`
			}

			const constValidator = schema.compileValidate(cCtx, path, modelNode.nodeId, true);

			if (constValidator !== null) {
				return {
					isScoped: true,
					// eslint-disable-next-line max-len
					code: `(s,pv,pt)=>{const _v=${applyCodeArg(value)};const _iv=${constValidator}(_v,pt);return _iv?_v:${inlineValue(opts.fallbackValue !== undefined ? opts.fallbackValue : null)}}`
				};
			} else {
				return value;
			}

		} else {

			return compileScalarNodeRender(
				cCtx, modelNode, path, errSeverity,
				inlineValue(modelNode.value),
				opts.fallbackValue !== undefined && opts.fallbackValue !== null
					? opts.fallbackValue
					: opts.default !== undefined ? opts.default : null
			);

		}

	};

	schema.validate = (rCtx, path, modelNodeId, value) => {
		return applyRuntimeValidators(rCtx, path, modelNodeId, validators, errSeverity, value);
	};

	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileRuntimeValidators(cCtx, path, modelNodeId, validators, errSeverity);
	};

	schema.castSpec = (rCtx, path, modelNodeId, value) => {

		if (value === null || value === undefined) {

			return value;

		} else if (typeof value === "string") {

			return value;

		} else if (typeof value === "function") {

			rCtx.logRuntimeError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.CAST_ERROR,
				message: `Cannot cast function to a string.`,
				modelPath: path,
				modelNodeId: modelNodeId,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:constString#errors.cannotCastFunction"
				}
			});

			return value;

		} else if (value instanceof Date) {

			return value.toLocaleString();

		} else if (value instanceof Object) {

			try {
				return JSON.stringify(value);
			} catch (err) {
				rCtx.logRuntimeError({
					severity: DOC_ERROR_SEVERITY.WARNING,
					name: DOC_ERROR_NAME.CAST_ERROR,
					message: `Cannot cast value to string.`,
					modelPath: path,
					modelNodeId: modelNodeId,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:constString#errors.cannotCastJson"
					}
				});
			}

		} else {

			return String(value);

		}

	};

	schema.compileCastSpec = (_cCtx, _path, modelNodeId) => {

		/* eslint-disable indent, max-len */
		return `(v,pt)=>{${[
			`if(v===null||v===undefined){`,
			`return v`,
			`}else if(typeof v==="string"){`,
			`return v`,
			`}else if(typeof v==="function"){`,
			`rCtx.logRuntimeError({severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},name:${inlineValue(DOC_ERROR_NAME.CAST_ERROR)},message:"Cannot cast function to a string.",modelPath:pt,modelNodeId:${inlineValue(modelNodeId)},metaData:{translationTerm:"schema:constString#errors.cannotCastFunction"}});`,
			`return v`,
			`}else if(v instanceof Date){`,
			`return v.toLocaleString()`,
			`}else if(typeof v==="object"){`,
			`try{`,
			`return JSON.stringify(v)`,
			`}catch(_e){`,
			`rCtx.logRuntimeError({severity:${inlineValue(DOC_ERROR_SEVERITY.WARNING)},name:${inlineValue(DOC_ERROR_NAME.CAST_ERROR)},message:"Cannot cast value to a string.",modelPath:pt,modelNodeId:${inlineValue(modelNodeId)},metaData:{translationTerm:"schema:constString#errors.cannotCastJson"}});`,
			`}`,
			`}else{`,
			`return String(v)`,
			`}`
		].join("")}}`
		/* eslint-enable indent, max-len */

	};

	schema.setValue = (modelNode, value, notify) => {

		modelNode.value = value;

		if (hasInterpolation(value)) {
			modelNode.isExpression = true;
			parseExpression(modelNode, EXP_PARSER_MODE.STRING_TEMPLATE);
		} else {
			modelNode.isExpression = false;
			modelNode.expAst = null;
			modelNode.expParseErrors = [];
			modelNode.expParseTrace = null;
			modelNode.expCompiledCode = null;
			modelNode.expCompiledFn = null;
			modelNode.expLastValue = null;

			validateValueAndUpdateModel(modelNode, validators, value);
		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}

	};

	schema.getValue = (modelNode) => {
		return modelNode.value;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaConstString", [opts]);
	};

	schema.getTypeDescriptor = () => {
		return TypeDescString({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	}

	schema.getChildNodes = () => {
		return [];
	}

	/* @todo Disabled because of SchemaBuilder
	 * If user configures default value that is not validate due to constraints
	 * the error will be thrown and blueprints becomes invalid.
	 * This could be resolved when schema builder will be able to dynamically validate
	 * the value field against configured constraints.
	 */
	// if (opts.default !== null && opts.default !== undefined) {
	// 	validateDefaultValue(schema, validators, opts.default);
	// }

	return schema;

}
