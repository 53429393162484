/**
 * Data Table HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";

import {
	SORT_ORDER_MODE_default,
	termsEditor as HAELibComponentsTerms,
	getValuesFromStringEnum,
	propGroups,
	HORIZONTAL_ALIGN_default,
	HORIZONTAL_ALIGN_BASIC,
	HORIZONTAL_ALIGN_opts,
	PaginationProps,
	SORT_ORDER_MODE,
	TextValueSchema,
	BOOLEAN,
	BOOLEAN_emptyStringDefault,
	BOOLEAN_miscStringDefault,
	BOOLEAN_default,
	BOOLEAN_opts,
	PaginationStyleProps
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { TableBaseProps, TableColumnBaseProps } from "../Table/baseProps";

export const DataTableColumnProps = {
	...TableColumnBaseProps,

	titleTextValue: BP.Prop(
		TextValueSchema({
			alias: "textValue_dataTableTitle",
			...termsEditor.schemas.table.columnTitle,
			editorOptions: {
				layoutType: ""
			}
		}),
		0
	),

	key: BP.Prop(
		BP.String({
			...termsEditor.schemas.table.columnKey,
			default: "",
			fallbackValue: "",
			constraints: {
				required: true,
				min: 1
			}
		}),
		10
	),

	mapper: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.table.columnMapper,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.ScopedTemplate({
				template: BP.Expression({
					default: "",
					placeholder: "row.key"
				})
			})
		}),
		15
	),

	formatter: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.table.columnFormatter,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.ScopedTemplate({
				template: BP.Expression({
					default: "value",
					placeholder: "value"
				})
			})
		}),
		16
	),

	typeData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.table.columnTypeData,
			typeValueOpts: {
				...termsEditor.schemas.table.columnTypeData
			},
			defaultType: "STRING",
			types: {
				STRING: {
					...termsEditor.schemas.table.columnTypeDataValues.string,
					value: BP.Void({})
				},
				NUMBER: {
					...termsEditor.schemas.table.columnTypeDataValues.number,
					value: BP.Void({})
				},
				BOOLEAN: {
					...termsEditor.schemas.table.columnTypeDataValues.boolean,
					value: BP.Object({
						props: {
							selectAll: BP.Prop(
								BP.Boolean({
									...termsEditor.schemas.table.columnSelectAll,
									default: true
								}),
								0
							),

							specificStrings: BP.Prop(
								BP.Array({
									...HAELibComponentsTerms.schemas.common.booleanFromSpecificString,
									items: BP.Object({
										props: {
											key: BP.Prop(
												BP.String({
													...HAELibComponentsTerms.schemas.common
														.booleanFromSpecificStringKey,
													default: "",
													fallbackValue: "",
													constraints: {
														required: true,
														min: 1
													}
												}),
												0
											),

											value: BP.Prop(
												BP.Enum.String({
													...HAELibComponentsTerms.schemas.common
														.booleanFromSpecificStringValue,
													options: getValuesFromStringEnum(
														BOOLEAN,
														HAELibComponentsTerms.schemas.common.booleanValues,
														BOOLEAN_opts
													),
													default: BOOLEAN_default,
													fallbackValue: BOOLEAN_default,
													editorOptions: {
														controlType: "buttons"
													},
													constraints: {
														required: true
													}
												}),
												10
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									}),
									getElementModelNodeInfo: (modelNode) => {
										let label = "";

										if (modelNode.constant.props.key.type === SCHEMA_VALUE_TYPE.CONST) {
											label += `"${modelNode.constant.props.key.constant.value}"`;
										}

										if (modelNode.constant.props.value.type === SCHEMA_VALUE_TYPE.CONST) {
											if (label) {
												label += ": ";
											}

											label += modelNode.constant.props.value.constant.value;
										}

										return {
											label,
											icon: null
										};
									}
								}),
								10
							),

							emptyString: BP.Prop(
								BP.Enum.String({
									...HAELibComponentsTerms.schemas.common.booleanFromEmptyString,
									options: getValuesFromStringEnum(
										BOOLEAN,
										HAELibComponentsTerms.schemas.common.booleanValues,
										BOOLEAN_opts
									),
									default: BOOLEAN_emptyStringDefault,
									fallbackValue: BOOLEAN_emptyStringDefault,
									editorOptions: {
										controlType: "buttons"
									},
									constraints: {
										required: true
									}
								}),
								20
							),

							miscString: BP.Prop(
								BP.Enum.String({
									...HAELibComponentsTerms.schemas.common.booleanFromMiscString,
									options: getValuesFromStringEnum(
										BOOLEAN,
										HAELibComponentsTerms.schemas.common.booleanValues,
										BOOLEAN_opts
									),
									default: BOOLEAN_miscStringDefault,
									fallbackValue: BOOLEAN_miscStringDefault,
									editorOptions: {
										controlType: "buttons"
									},
									constraints: {
										required: true
									}
								}),
								30
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		20
	),

	sortOrderMode: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.table.columnSortOrderMode,
			options: getValuesFromStringEnum(
				SORT_ORDER_MODE,
				HAELibComponentsTerms.schemas.common.sortOrderModeValues
			),
			default: SORT_ORDER_MODE_default
		}),
		25
	),

	horizontalAlign: BP.Prop(
		BP.Enum.String({
			...HAELibComponentsTerms.schemas.common.horizontalAlign,
			options: getValuesFromStringEnum(
				HORIZONTAL_ALIGN_BASIC,
				HAELibComponentsTerms.schemas.common.horizontalAlignValues,
				HORIZONTAL_ALIGN_opts
			),
			default: HORIZONTAL_ALIGN_default,
			fallbackValue: HORIZONTAL_ALIGN_default,
			editorOptions: {
				controlType: "buttons"
			},
			constraints: {
				required: true
			}
		}),
		30
	),

	editable: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.table.columnEditable,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		80
	)
};

export const HAEComponentDataTable_Props = {
	...TableBaseProps,

	columns: BP.Prop(
		BP.Array({
			...termsEditor.schemas.table.columns,
			fallbackValue: [],
			constraints: {
				required: true
			},
			items: BP.Const.Object({
				props: DataTableColumnProps
			}),
			outlineOptions: {
				displayChildren: true,
				allowAddElement: true
			},
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.props.titleTextValue.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.props.titleTextValue.constant.value,
					icon: "mdi/table-column"
				};
			}
		}),
		0,
		propGroups.common
	),

	pagination: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.table.pagination,
			enabledOpts: {
				default: false
			},
			value: BP.Object({
				props: {
					limit: BP.Prop(PaginationProps.limit.schema, PaginationProps.limit.order),

					page: BP.Prop(PaginationProps.page.schema, PaginationProps.page.order),

					maxDisplayedPages: BP.Prop(
						PaginationProps.maxDisplayedPages.schema,
						PaginationProps.maxDisplayedPages.order
					),

					typeData: BP.Prop(
						BP.OneOf({
							...HAELibComponentsTerms.schemas.pagination.typeData,
							typeValueOpts: {
								...HAELibComponentsTerms.schemas.pagination.typeData
							},
							defaultType: "CLIENT",
							types: {
								CLIENT: {
									...HAELibComponentsTerms.schemas.pagination.typeDataValues.client,
									value: BP.Void({})
								},
								SERVER: {
									...HAELibComponentsTerms.schemas.pagination.typeDataValues.server,
									value: BP.Object({
										props: {
											items: BP.Prop(PaginationProps.items.schema, 0)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}
							},
							constraints: {
								required: true
							},
							editorOptions: {
								layoutType: "noHeader"
							}
						}),
						100
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		20,
		propGroups.common
	),

	paginationStyle: BP.Prop(
		BP.Object({
			...termsEditor.schemas.table.paginationStyle,
			props: {
				backgroundColor: BP.Prop(
					PaginationStyleProps.backgroundColor.schema,
					PaginationStyleProps.backgroundColor.order
				),

				foregroundColor: BP.Prop(
					PaginationStyleProps.foregroundColor.schema,
					PaginationStyleProps.foregroundColor.order
				),

				borderRadius: BP.Prop(
					PaginationStyleProps.borderRadius.schema,
					PaginationStyleProps.borderRadius.order
				),

				boxShadow: BP.Prop(
					PaginationStyleProps.boxShadow.schema,
					PaginationStyleProps.boxShadow.order
				),

				item: BP.Prop(PaginationStyleProps.item.schema, PaginationStyleProps.item.order)
			},
			editorOptions: {
				defaultExpanded: false
			}
		}),
		TableBaseProps.spacing.order + 10,
		propGroups.style
	),

	itemCount: BP.Prop(
		BP.Const.Boolean({
			...termsEditor.schemas.table.itemCount,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.advanced
	),

	csvExport: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.table.csvExport,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				props: {
					header: BP.Prop(
						BP.Boolean({
							...termsEditor.schemas.table.csvExportHeader,
							default: true,
							fallbackValue: true,
							constraints: {
								required: true
							}
						}),
						0
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		0,
		propGroups.advanced
	)
};
