/**
 * QR Code HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { QRCodeSVG } from "qrcode.react";

import { Type, defineElementaryComponent, BP } from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../../terms";
import {
	BackgroundColorSchema,
	ClassList,
	ForegroundColorSchema,
	getStringEnumValue,
	getValuesFromStringEnum,
	LinkSchema,
	propGroups,
	RoutingContext,
	StyleSheet,
	THAEComponentDefinition,
	THAEComponentReact,
	useStyleSheetRegistry
} from "@hexio_io/hae-lib-components";

import {
	QR_CODE_ERROR_CORRECTION_LEVEL,
	QR_CODE_ERROR_CORRECTION_LEVEL_default
} from "../../Enums/QR_CODE_ERROR_CORRECTION_LEVEL";
import { isBoolean, isNonEmptyString } from "@hexio_io/hae-lib-shared";

interface HAEComponentQrCode_State {}

const HAEComponentQrCode_Props = {
	valueData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.qrCode.valueData,
			typeValueOpts: {
				...termsEditor.schemas.qrCode.valueData
			},
			defaultType: "TEXT",
			types: {
				TEXT: {
					...termsEditor.schemas.qrCode.valueDataValues.text,
					value: BP.String({
						default: ""
					})
				},
				LINK: {
					...termsEditor.schemas.qrCode.valueDataValues.link,
					value: LinkSchema({
						alias: "link_qrCode",
						props: {
							enabled: BP.Prop(BP.Void({}))
						}
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		0,
		propGroups.common
	),

	errorCorrectionLevel: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.qrCode.errorCorrectionLevel,
			options: getValuesFromStringEnum(QR_CODE_ERROR_CORRECTION_LEVEL),
			default: QR_CODE_ERROR_CORRECTION_LEVEL_default,
			fallbackValue: QR_CODE_ERROR_CORRECTION_LEVEL_default,
			constraints: {
				required: true
			},
			editorOptions: {
				controlType: "buttons"
			}
		}),
		10,
		propGroups.common
	),

	backgroundColor: BP.Prop(
		BackgroundColorSchema({
			alias: "backgroundColor_qrCode",
			default: "WHITE"
		}),
		10,
		propGroups.style
	),

	foregroundColor: BP.Prop(
		ForegroundColorSchema({
			alias: "foregroundColor_qrCode",
			default: "BLACK"
		}),
		20,
		propGroups.style
	)
};

const HAEComponentQrCode_Events = {};

const HAEComponentQrCode_Definition = defineElementaryComponent<
	typeof HAEComponentQrCode_Props,
	HAEComponentQrCode_State,
	typeof HAEComponentQrCode_Events
>({
	...termsEditor.components.qrCode.component,

	name: "qrcode",

	category: "content",

	icon: "mdi/qrcode",

	events: HAEComponentQrCode_Events,

	docUrl: "...",

	order: 220,

	props: HAEComponentQrCode_Props,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentQrCode_React: THAEComponentReact<typeof HAEComponentQrCode_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { valueData, errorCorrectionLevel, backgroundColor, foregroundColor } = props;

	const routingContext = React.useContext(RoutingContext);

	let value = "";

	switch (valueData.type) {
		case "TEXT":
			value = valueData.value[valueData.type];
			break;

		case "LINK": {
			const link = valueData.value[valueData.type];

			const resolvedLink = routingContext.resolveLink(link.locationData, {
				exact: isBoolean(link.exact) ? link.exact : false
			});

			value = resolvedLink.url;
		}
	}

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-qr-code",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Background color

		if (backgroundColor) {
			result.addColorProperties({
				selector: `.${idClassName}`,
				name: "element-background-color",
				value: backgroundColor
			});
		}

		// Foreground color

		if (foregroundColor) {
			result.addColorProperties({
				selector: `.${idClassName}`,
				name: "element-foreground-color",
				value: foregroundColor
			});
		}

		return result;
	}, [ idClassName, backgroundColor, foregroundColor ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	if (!isNonEmptyString(value)) {
		return null;
	}

	const errorCorrectionLevelValue = getStringEnumValue(
		QR_CODE_ERROR_CORRECTION_LEVEL,
		errorCorrectionLevel,
		QR_CODE_ERROR_CORRECTION_LEVEL_default
	);

	return (
		<div className={classList.toClassName()}>
			<QRCodeSVG
				value={value}
				level={errorCorrectionLevelValue}
				size={256}
				bgColor={"var(--element-background-color)"}
				fgColor={"var(--element-foreground-color)"}
			/>
		</div>
	);
};

export const HAEComponentQrCode: THAEComponentDefinition<typeof HAEComponentQrCode_Definition> = {
	...HAEComponentQrCode_Definition,
	reactComponent: HAEComponentQrCode_React
};
