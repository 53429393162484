/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAppServer } from "../app";
import { ERROR_CODES, ERROR_NAMES, IntegrationError } from "../errors";
import { IExecutionOptions } from "../managers/IExecutionOptions";
import { IExecutionContext } from "../WebServer";
import { IIntegration, IIntegrationFunctionParams, IIntegrationFunctionResult } from "./IIntegration";
import {
	IIntegrationFunctionDefinition,
	TDecoratorIntegrationDefinition,
	TIntegrationFunctionsDefinitions
} from "./IIntegrationDefinition";
import { IIntegrationOptions } from "./IIntegrationOptions";
import { createEventEmitter, emitEvent, onEvent, removeAllEventListeners } from "@hexio_io/hae-lib-shared";
import { ILogger } from "../logger";

/**
 * Base Integration
 */
export abstract class IntegrationBase<TConfig extends IIntegrationOptions> implements IIntegration {
	/** SSH Tunnel id */
	protected sshTunnelId: string;

	/** Integration type */
	public type: string;

	/** Logger instance */
	protected logger: ILogger;

	/** Integration Definitions */
	protected integrationDefinition: TDecoratorIntegrationDefinition;

	/** Function Definitions */
	protected functionDefinitions: TIntegrationFunctionsDefinitions;

	/** Debug information */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected debug?: any[];

	public onSshTunnelError = createEventEmitter<any>();

	public constructor(
		protected integrationName: string,
		protected config: TConfig,
		protected app: IAppServer
	) {
		this.logger = app.get("logger").facility(`integration-${integrationName}`);
	}

	/**
	 * Initializes the service
	 */
	public async init(): Promise<void> {
		this.logger.debug("Initialize");

		const sshTunnelConfig = this.config.sshTunnel;

		if (sshTunnelConfig) {
			try {
				const { privateKey } = sshTunnelConfig;

				if (!this.app.get("sshTunnelService").hasConfig(sshTunnelConfig)) {
					const { remoteHost, remotePort } = this.getRemoteHostAndPort();
					sshTunnelConfig.remoteHost = remoteHost;
					sshTunnelConfig.remotePort = remotePort;

					const { port, id } = await this.app
						.get("sshTunnelService")
						.getTunnel(sshTunnelConfig, privateKey);

					// Update integration configuration to make it work with ssh tunnel
					sshTunnelConfig.remoteHost = remoteHost;
					sshTunnelConfig.remotePort = remotePort;
					sshTunnelConfig.localPort = port;

					this.logger.debug(sshTunnelConfig);

					this.config.sshTunnel = sshTunnelConfig;
					this.sshTunnelId = id;
				}

				const sshTunnel = await this.app.get("sshTunnelService").get(sshTunnelConfig);
				if (sshTunnel) {
					onEvent(sshTunnel.onError, (error) => {
						emitEvent(this.onSshTunnelError, error);
					});
				}
			} catch (error) {
				this.logger.debug(error.message);
				this.logger.debug(error);

				throw new IntegrationError(
					ERROR_NAMES.INTEGRATION,
					ERROR_CODES.INTEGRATION,
					`Can't initialize ssh tunnel.`,
					{
						details: {
							integrationType: this.integrationDefinition.name,
							integrationId: this.integrationName,
							errorCode: error.code
						}
					}
				);
			}
		}
	}

	/**
	 * Dispose the service
	 */
	public async dispose(): Promise<void> {
		this.logger.info("Disposing...");

		if (this.sshTunnelId) {
			await this.app.get("sshTunnelService").release(this.sshTunnelId);
			removeAllEventListeners(this.onSshTunnelError);
		}
	}

	/**
	 * Sets definitions
	 *
	 * @param integrationDefinition Integration definition
	 * @param functionDefinitions Function definitions
	 */
	public setDefinitions(
		integrationDefinition: TDecoratorIntegrationDefinition,
		functionDefinitions: TIntegrationFunctionsDefinitions
	): void {
		this.integrationDefinition = integrationDefinition;
		this.functionDefinitions = functionDefinitions;
		this.type = integrationDefinition.name;
	}

	/**
	 * Invokes a function
	 *
	 * @param functionName Function name
	 * @param params Parameters
	 * @param config Options
	 * @returns
	 */
	public async invoke(
		functionName: string,
		params: IIntegrationFunctionParams,
		context: IExecutionContext,
		config?: IExecutionOptions
	): Promise<IIntegrationFunctionResult> {
		if (this.sshTunnelId) {
			this.logger.debug(`Use ssh tunnel: ${this.sshTunnelId}`);
		}

		if (typeof this[functionName] !== "function") {
			context.warn(`Function '${functionName}' not found.`);

			throw new IntegrationError(
				ERROR_NAMES.INTEGRATION,
				ERROR_CODES.INTEGRATION,
				`Can't execute integration function. Function '${functionName}' not found.`,
				{
					details: {
						integrationType: this.integrationDefinition.name,
						integrationId: this.integrationName,
						functionName
					}
				}
			);
		}

		const functionDefinition = this.functionDefinitions[functionName] as IIntegrationFunctionDefinition;

		if (!functionDefinition) {
			context.warn(`Function definition for '${functionName}' function not found.`);

			throw new IntegrationError(
				ERROR_NAMES.INTEGRATION,
				ERROR_CODES.INTEGRATION,
				`Can't execute integration function. Function's definition for '${functionName}' function not found.`,
				{
					details: {
						integrationType: this.integrationDefinition.name,
						integrationId: this.integrationName,
						functionName
					}
				}
			);
		}

		let sshTunnelError: any;
		onEvent(this.onSshTunnelError, (error) => {
			sshTunnelError = error;
		});

		let result;

		try {
			result = await this[functionName].call(this, params, context, config);
		} catch (error) {
			if (sshTunnelError) {
				if (error instanceof IntegrationError) {
					throw new IntegrationError(
						error.name,
						error.code,
						error.message,
						{
							details: {
								...(error.errorDetails.details || {}),
								sshTunnelError: sshTunnelError.message
							}
						},
						error.sourceError
					);
				} else {
					throw new IntegrationError(
						ERROR_NAMES.INTEGRATION,
						ERROR_CODES.INTEGRATION,
						"Can't invoke integration function.",
						{
							details: {
								sshTunnelError: sshTunnelError.message,
								errorMessage: error.message
							}
						}
					);
				}
			}

			throw error;
		}

		return result;
	}

	/**
	 * Tests integration
	 *
	 * @returns
	 */
	public async test(context: IExecutionContext): Promise<void> {
		return;
	}

	/**
	 * Returns host and port
	 * @returns
	 */
	public getRemoteHostAndPort(): { remoteHost: string; remotePort: number } {
		throw new Error("Not implemented.");
	}

	/**
	 * Return actual config
	 * @returns
	 */
	public getConfig(): TConfig {
		return this.config;
	}

	public setConfig(config: TConfig): void {
		this.config = config;
	}
}
