/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { CompileContext } from "../Context/CompileContext";
import { TExpAst_Expression } from "../Expression/ExpAst";
import { EXP_PARSER_MODE, parse } from "../Expression/ExpParser";
import { renameExpRootIdentifiers } from "../Expression/ExpRefactoring";
import { TSchemaExpressionCompiledFn } from "../Expression/ExpTypes";
import { BP_IDT_TYPE, TBlueprintIDTNode } from "../IDT/ISchemaIDT";
import { TGenericBlueprintSchema } from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import { DOC_ERROR_SEVERITY, IDocumentError } from "../Shared/IDocumentError";
import { REFACTORING_OP, TRefactoringEvent } from "../Shared/Refactoring";
import { SCHEMA_VALIDATION_ERROR_TYPE } from "../Validator/IBlueprintSchemaValidator";

/**
 * Schema model for expression based schemas
 */
export interface ISchemaExpressionBasedModel<TSchema extends TGenericBlueprintSchema>
	extends IModelNode<TSchema> {
	value: string;
	expAst: TExpAst_Expression;
	expParseErrors: IDocumentError[];
	expCompiledCode: string;
	expCompiledFn: TSchemaExpressionCompiledFn;
	expLastValue?: unknown;
	expParseTrace?: string[];
	/** Change event - emitted when model node has changed, eg. it's value, state, etc. */
	expLastValueChangeEvent: TSimpleEventEmitter<void>;
	__refactoringHandler?: (event: TRefactoringEvent) => void;
}

/**
 * Logs expression parse errors to a Design context
 *
 * @param modelNode Expression-based model node
 * @param idtNode Idt node from which the model was parsed
 */
export function logExpressionErrorsToContext(
	modelNode: ISchemaExpressionBasedModel<TGenericBlueprintSchema>,
	idtNode: TBlueprintIDTNode
): void {
	if (!idtNode || !idtNode.parseInfo?.loc) {
		return;
	}

	let isQuoted = false;
	const nodeRange = idtNode.parseInfo.loc.range;

	if (idtNode && idtNode.type === BP_IDT_TYPE.SCALAR && typeof idtNode.value === "string") {
		if (
			nodeRange.start.line === nodeRange.end.line &&
			nodeRange.end.col - nodeRange.start.col !== idtNode.value.length
		) {
			isQuoted = true;
		}
	}

	modelNode.expParseErrors.forEach((err) => {
		modelNode.ctx.logParseError(idtNode.parseInfo.loc.uri, {
			range: {
				start: {
					line: nodeRange.start.line + err.range.start.line,
					col: nodeRange.start.col + err.range.start.col + (isQuoted ? 1 : 0)
				},
				end: {
					line: nodeRange.end.line + err.range.end.line,
					col: nodeRange.end.col + err.range.end.col + (isQuoted ? 1 : 0)
				}
			},
			severity: err.severity,
			name: err.name,
			message: err.message,
			metaData: err.metaData,
			relatedInformation: err.relatedInformation,
			parsePath: idtNode.path
		});
	});
}

export function parseExpression(
	modelNode: ISchemaExpressionBasedModel<TGenericBlueprintSchema>,
	parserMode: EXP_PARSER_MODE
): void {
	modelNode.validationErrors = [];
	modelNode.expAst = null;
	modelNode.expParseErrors = [];
	modelNode.expParseTrace = null;
	modelNode.expCompiledCode = null;
	modelNode.expCompiledFn = null;

	if (modelNode.value === null || modelNode.value === "") {
		return;
	}

	try {
		const parseResult = parse(modelNode.value, parserMode);

		modelNode.expAst = parseResult.ast;
		modelNode.expParseErrors = parseResult.errors;
		modelNode.expParseTrace = parseResult.trace;

		modelNode.expParseErrors.forEach((err) => {
			if (err.severity !== DOC_ERROR_SEVERITY.ERROR) {
				return;
			}

			modelNode.validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.INVALID_EXPRESSION,
				message: `${err.message} (in expression at ${err.range.start.line + 1}:${
					err.range.start.col + 1
				})`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:expression#errors.invalidExpression",
					args: {
						message: err.message,
						line: err.range.start.line,
						col: err.range.start.col
					}
				}
			});
		});

		if (modelNode.expAst) {
			const cCtx = new CompileContext({
				resolvers: {}
			});

			const compiledRes = cCtx.compileExpression(
				modelNode.expAst,
				modelNode.value,
				modelNode.nodeId,
				true,
				true
			);
			modelNode.expCompiledCode = compiledRes.code;
			modelNode.expCompiledFn = new Function(
				"rCtx",
				"s",
				"pt",
				compiledRes.code
			) as TSchemaExpressionCompiledFn;
		}
	} catch (err) {
		console.warn("Failed to parse and compile an expression:", modelNode.value, err);

		modelNode.validationErrors.push({
			type: SCHEMA_VALIDATION_ERROR_TYPE.INVALID_EXPRESSION,
			message: "Failed to parse expression (unexpected error).",
			metaData: {
				// @todo add to translation table
				translationTerm: "schema:expression#errors.failedToParse"
			}
		});
	}

	modelNode.isValid = modelNode.validationErrors.length === 0 ? true : false;
}

export function handleExpressionRefactoring(
	modelNode: ISchemaExpressionBasedModel<TGenericBlueprintSchema>,
	event: TRefactoringEvent
): boolean {
	if (!modelNode.expAst) {
		return;
	}

	let wasModified = false;

	if (event.operation === REFACTORING_OP.IDENTIFIER_RENAME) {
		const oldValue = modelNode.value;
		modelNode.value = renameExpRootIdentifiers(
			modelNode.value,
			modelNode.expAst,
			event.oldIdentifier,
			event.newIdentifier
		);

		if (modelNode.value !== oldValue) {
			wasModified = true;
		}
	}

	return wasModified;
}
