/**
 * Custom HTML HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from "react";

import {
	BP,
	COMPONENT_MODE,
	defineElementaryComponent,
	Type
} from "@hexio_io/hae-lib-blueprint";

import { TimePicker } from '@fluentui/react/lib/TimePicker';
import { IStackTokens, Stack, IStackStyles, IComboBoxStyles, IComboBox, initializeIcons } from '@fluentui/react';

import {
	ClassList,
	propGroups,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { TimePickerProps } from "../props";
import { isString, isValidValue } from "@hexio_io/hae-lib-shared";
import { getFieldStateProps, useField } from "../useField";
import { DateTime } from "luxon";
import { IFieldState } from "../state";

function getTimeValuesFromISOStringOrTimestamp(value: string): { dateValue: string } {
	let dateValue = null;

	const dateTime = DateTime.fromISO(value);

	if(!dateTime.isValid) {
		return {
			dateValue
		};
	}else{
		dateValue = new Date()
		dateValue.setUTCHours(dateTime.c.hour, dateTime.c.minute)
		dateValue = dateValue.toISOString()
		return {
			dateValue
		};
	}

}

const stackStyles: Partial<IStackStyles> = { root: { maxWidth: "100%" } };
const stackTokens: IStackTokens = { childrenGap: 20 };

const timePickerStyles: Partial<IComboBoxStyles> = {};

interface HAEComponentTimePicker_State extends IFieldState {
	value: string;
}

/**
 * Custom Component Props
 */
const HAEComponentTimePicker_Props = {
	...TimePickerProps,

	value: BP.Prop(BP.String({
		...termsEditor.schemas.datePicker.value,
		default: "",
		fallbackValue: null,
		constraints: {
			required: true
		}
	}), 0, propGroups.common)
};

const HAEComponentTimePicker_Events = {
	change: {
		...termsEditor.schemas.datePicker.events.change
	}
};

const HAEComponentTimePicker_Definition = defineElementaryComponent<
	typeof HAEComponentTimePicker_Props,
	HAEComponentTimePicker_State,
	typeof HAEComponentTimePicker_Events
>({
	...termsEditor.components.timePicker.component,

	name: "timePicker",

	category: "form",

	icon: "mdi/alarm",

	order: 1000,

	props: HAEComponentTimePicker_Props,

	events: HAEComponentTimePicker_Events,

	hidden: true,

	resolve: (spec, state, updateStateAsync) => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;

		const value = state?.initialValue === initialValue ?
			(isValidValue(state?.value) ? state.value : getTimeValuesFromISOStringOrTimestamp(spec.value).dateValue) :
			getTimeValuesFromISOStringOrTimestamp(spec.value).dateValue;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue() {
			updateStateAsync((prevState) => ({ ...prevState, value: null }));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			setValue,
			clearValue
		};
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: state.initialValue,
			value: state.value,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.Any({ ...termsEditor.schemas.datePicker.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.datePicker.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.datePicker.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.datePicker.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const count = 0

const HAEComponentTimePicker_React: THAEComponentReact<typeof HAEComponentTimePicker_Definition> = ({
	componentInstance,
	props,
	state,
	setState,
	reactComponentClassList
}) => {

	const {
		allowFreeform,
		increments,
		label,
		readOnly,
		showSeconds,
		useHour12,
		customValidation
	} = props;

	useEffect(() => {
		initializeIcons();
	}, [])

	const { value } = state;

	const componentPath = componentInstance.safePath;
	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const { idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-timePicker",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const id = idClassName;

	// Validate function

	const validate = React.useMemo(() => {
		if (!props.validate) {
			return false;
		}

		// return (newValue: string) => {
		// 	if (!isString(newValue)) {
		// 		return false;
		// 	}

		// 	return DateTime.fromISO(
		// 		newValue.includes("T") ? newValue.split("T")[1] : newValue
		// 	).isValid;
		// };

		return true
	}, [ props.validate ]);

	const { setValue } = useField<string>(
		{
			id,
			state,
			validate,
			customValidation,
			onChange: !elementReadOnly && componentInstance.eventEnabled.change ? componentInstance.eventTriggers.change : undefined
		},
		setState
	);

	const { dateValue } = getTimeValuesFromISOStringOrTimestamp(value);

	const onChange = (_: React.FormEvent<IComboBox>, date: Date) => {
		componentInstance.setState((prevState) => ({...prevState, value: date.toISOString() || null}))
		setValue(date.toISOString())
	}

	const elementRef = React.useRef<HTMLDivElement>();


	return (
		<div ref={elementRef}>
			<Stack tokens={stackTokens} styles={stackStyles}>
				<TimePicker
					styles={timePickerStyles}
					allowFreeform={allowFreeform}
					autoComplete="on"
					// defaultValue={new Date(dateValue)}
					// increments={increments}
					label={label}
					showSeconds={showSeconds}
					useHour12={useHour12}
					onChange={onChange}
				/>
			</Stack>
		</div>
	);
};

export const HAEComponentTimePicker: THAEComponentDefinition<typeof HAEComponentTimePicker_Definition> = {
	...HAEComponentTimePicker_Definition,
	reactComponent: HAEComponentTimePicker_React
};
