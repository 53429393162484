/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGenericBlueprintSchema } from "@hexio_io/hae-lib-blueprint";
import { ILogger } from "../logger";
import { createSessionStorageFactory } from "./createSessionStorageFactory";
import { ISessionStorage, ISessionStorageOptions } from "./ISessionStorage";
import { ISessionStorageDefinition } from "./ISessionStorageDefinition";

/**
 * Creates Session Storage definition
 */
export function createSessionStorageDefinition<
	TStorageOptions extends ISessionStorageOptions,
	TClass extends ISessionStorage
>(
	name: string,
	category: string,
	label: string,
	optionsSchema: TGenericBlueprintSchema,
	entityClass: new (config: TStorageOptions, logger: ILogger) => TClass
): ISessionStorageDefinition<TStorageOptions, TClass> {
	return {
		name,
		category,
		label,
		factory: createSessionStorageFactory(entityClass),
		optionsSchema
	};
}
