/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTScalar,
	TBlueprintIDTNode
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import {
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { TypeDescNull } from "../Shared/ITypeDescriptor";
import { dataEqual } from "../Shared/Equal";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { emitEvent } from "@hexio_io/hae-lib-shared";

/**
 * Schema model
 */
export interface ISchemaDynamicModel<TResolveParams, TValueSchema extends TGenericBlueprintSchema>
	extends IModelNode<ISchemaDynamic<TResolveParams, TValueSchema>> {
	/** Resolved schema */
	resolvedSchema: TValueSchema;
	/** Resolved value model */
	value: TGetBlueprintSchemaModel<TValueSchema>;
	/** If the dynamic schema has been resolved */
	isResolved: boolean;
	/** If resolving dynamic schema has failed */
	hasError: boolean;
	/** Resolution error details */
	error?: string;
	/** Error instance */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errorInstance?: any;
	/** INTERNAL: Last params used to resolve the schema */
	__lastParams: TResolveParams;
}

/**
 * Schema options
 */
export interface ISchemaDynamicOpts<TResolveParams, TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchemaOpts {
	/**
	 * Function to resolve schema with Design Context available
	 *
	 * @param params Resolve params
	 */
	resolveSchemaDesign(dCtx: DesignContext, params: TResolveParams): TValueSchema;

	/** Default schema - used when value was not resolved yet, or model was reseted */
	defaultSchema?: TValueSchema;
}

/**
 * Schema spec
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TSchemaDynamicSpec<TValueSchema extends TGenericBlueprintSchema> =
	TGetBlueprintSchemaSpec<TValueSchema>;

/**
 * Schema default
 */
export type TSchemaDynamicDefault<TValueSchema extends TGenericBlueprintSchema> =
	TGetBlueprintSchemaDefault<TValueSchema>;

/**
 * Schema create opts
 */
export type TSchemaDynamicCreateOpts<TResolveParams> = {
	resolveParams?: TResolveParams;
};

/**
 * Schema type
 */
export interface ISchemaDynamic<TResolveParams, TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchema<
		ISchemaDynamicOpts<TResolveParams, TValueSchema>,
		ISchemaDynamicModel<TResolveParams, TValueSchema>,
		TSchemaDynamicSpec<TValueSchema>,
		TSchemaDynamicDefault<TValueSchema>,
		TSchemaDynamicCreateOpts<TResolveParams>
	> {
	/**
	 * Resolves dynamic schema
	 *
	 * @param modelNode Model node
	 * @param params Resolve function params
	 * @param notify If to emit change event(s)
	 * @param force If to force resolve even if params haven't changed
	 */
	resolve: (
		modelNode: ISchemaDynamicModel<TResolveParams, TValueSchema>,
		params: TResolveParams,
		notify?: boolean,
		force?: boolean
	) => void;

	/**
	 * Resets to default schema
	 *
	 * @param modelNode Model node
	 * @param notify If to emit change event
	 */
	reset: (modelNode: ISchemaDynamicModel<TResolveParams, TValueSchema>, notify?: boolean) => void;
}

/**
 * Schema: Dynamic
 * Used internally to resolve schema dynamically based on some another value.
 *
 * @param opts Schema options
 */
export function SchemaDynamic<TResolveParams, TValueSchema extends TGenericBlueprintSchema>(
	opts: ISchemaDynamicOpts<TResolveParams, TValueSchema>
): ISchemaDynamic<TResolveParams, TValueSchema> {
	type TValueModel = TGetBlueprintSchemaModel<TValueSchema>;

	const schema = createEmptySchema<ISchemaDynamic<TResolveParams, TValueSchema>>("dynamic", opts);

	const resolveValue = (
		modelNode: ISchemaDynamicModel<TResolveParams, TValueSchema>,
		resolveParams: TResolveParams,
		initialValue?: TGetBlueprintSchemaDefault<TValueSchema>,
		initialIDT?: TBlueprintIDTNode,
		force?: boolean
	) => {
		// Skip if params hasn't change
		if (!force && dataEqual(resolveParams, modelNode.__lastParams)) {
			return false;
		}

		modelNode.__lastParams = resolveParams;

		const prevResolvedSchema = modelNode.resolvedSchema;
		const prevValueIDT = modelNode.value
			? modelNode.value.schema.serialize(modelNode.value, [ "$_sd" ])
			: initialIDT;

		if (resolveParams) {
			let resolvedSchema: TValueSchema;
			let errorInstance;

			try {
				resolvedSchema = opts.resolveSchemaDesign(modelNode.ctx, resolveParams) ?? null;

				if (resolvedSchema === prevResolvedSchema && prevResolvedSchema !== null) {
					// Developer note: Be carefull about this early return, eg. previous value must be destroyed
					// only when the params are not same and this branch does not execute (I had bug here).
					return false;
				}

				modelNode.resolvedSchema = resolvedSchema;
			} catch (err) {
				errorInstance = err;
				modelNode.resolvedSchema = null;
			}

			// Destroy previous model
			if (modelNode.value) {
				modelNode.value.schema.destroy(modelNode.value);
				modelNode.value = null;
			}

			// Has errors
			if (errorInstance) {
				modelNode.value = null;
				modelNode.isResolved = false;
				modelNode.hasError = true;
				modelNode.errorInstance = errorInstance;
				modelNode.error = String(errorInstance);

				// Schema was resolved
			} else {
				if (!resolvedSchema) {
					modelNode.value = null;
				} else if (prevValueIDT !== undefined) {
					modelNode.value = resolvedSchema.parse(
						modelNode.ctx,
						prevValueIDT,
						modelNode
					) as TValueModel;
				} else {
					modelNode.value = resolvedSchema.createDefault(
						modelNode.ctx,
						modelNode,
						initialValue
					) as TValueModel;
				}

				modelNode.isResolved = true;
				modelNode.hasError = false;
				modelNode.error = null;
				modelNode.errorInstance = null;
			}
		} else {
			// Destroy previous model
			if (modelNode.value) {
				modelNode.value.schema.destroy(modelNode.value);
				modelNode.value = null;
			}

			// Create default
			if (opts.defaultSchema) {
				modelNode.resolvedSchema = opts.defaultSchema;

				if (prevValueIDT !== undefined) {
					modelNode.value = opts.defaultSchema.parse(
						modelNode.ctx,
						prevValueIDT,
						modelNode
					) as TValueModel;
				} else {
					modelNode.value = opts.defaultSchema.createDefault(
						modelNode.ctx,
						modelNode,
						initialValue
					) as TValueModel;
				}
			} else {
				modelNode.value = null;
				modelNode.resolvedSchema = null;
			}

			modelNode.isResolved = true;
			modelNode.hasError = false;
			modelNode.error = null;
			modelNode.errorInstance = null;
		}

		return modelNode.resolvedSchema !== prevResolvedSchema;
	};

	const createModel = (
		dCtx: DesignContext,
		initialResolveParams: TResolveParams,
		initialValue: TGetBlueprintSchemaDefault<TValueSchema>,
		initialIDT: TBlueprintIDTNode,
		parent: TBlueprintSchemaParentNode
	) => {
		const model = createModelNode(schema, dCtx, parent, [], {
			value: null,
			resolvedSchema: null,
			isResolved: false,
			hasError: false,
			error: null,
			__lastParams: initialResolveParams || null
		});

		resolveValue(model, initialResolveParams, initialValue, initialIDT, true);

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {
		return createModel(dCtx, createOpts?.resolveParams, defaultValue, undefined, parent);
	};

	schema.parse = (dCtx, idtNode, parent, createOpts) => {
		return createModel(dCtx, createOpts?.resolveParams, undefined, idtNode, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode, createOpts) => {
		let valueSchema: TGenericBlueprintSchema = null;

		if (createOpts?.resolveParams) {
			try {
				valueSchema = opts.resolveSchemaDesign(dCtx, createOpts.resolveParams);
			} catch (err) {
				// Do nothing
			}
		} else if (opts.defaultSchema) {
			valueSchema = opts.defaultSchema;
		}

		if (valueSchema !== null && valueSchema.provideCompletion) {
			valueSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		} else {
			dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
				return null;
			});
		}
	};

	schema.serialize = (modelNode, path) => {
		if (modelNode.value) {
			return modelNode.value.schema.serialize(modelNode.value, path);
		} else {
			return {
				type: BP_IDT_TYPE.SCALAR,
				subType: BP_IDT_SCALAR_SUBTYPE.NULL,
				path: path,
				value: null
			} as IBlueprintIDTScalar;
		}
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedValue = modelNode.value
			? modelNode.value.schema.clone(dCtx, modelNode.value, null)
			: null;

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			value: clonedValue as TValueModel,
			resolvedSchema: modelNode.resolvedSchema,
			isResolved: modelNode.isResolved,
			hasError: modelNode.hasError,
			error: modelNode.error,
			__lastParams: modelNode.__lastParams
		});

		const { value } = clone;
		if (value) value.schema.assignParent(value, clone, false);

		return clone;
	};

	schema.destroy = (modelNode) => {
		if (modelNode.value) {
			modelNode.value.schema.destroy(modelNode.value);
		}

		modelNode.error = undefined;
		modelNode.__lastParams = undefined;

		destroyModelNode(modelNode);
	};

	schema.render = (rCtx, modelNode, path, scope) => {
		if (modelNode.value) {
			return modelNode.value.schema.render(rCtx, modelNode.value, path, scope);
		} else {
			return null;
		}
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		if (modelNode.value) {
			return modelNode.value.schema.compileRender(cCtx, modelNode.value, path);
		} else {
			return {
				isScoped: false,
				code: "null"
			};
		}
	};

	/*
	 * Dynamic value cannot be validated exactly because we cannot resolve params in runtime.
	 * Eg. we don't have index of all action during runtime (actions are called when required).
	 * So only default schema can be validated.
	 */
	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		if (opts.defaultSchema) {
			return opts.defaultSchema.validate(rCtx, path, modelNodeId, value, validateChildren);
		} else {
			return true;
		}
	};

	/*
	 * Dynamic value cannot be validated exactly because we cannot resolve params in runtime.
	 * Eg. we don't have index of all action during runtime (actions are called when required).
	 * So only default schema can be validated.
	 */
	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		if (opts.defaultSchema) {
			return opts.defaultSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);
		} else {
			return null;
		}
	};

	schema.export = (): ISchemaImportExport => {
		throw new Error("Dynamic schema does not support export.");
	};

	schema.getTypeDescriptor = (modelNode) => {
		if (modelNode?.value) {
			return modelNode.value.schema.getTypeDescriptor(modelNode.value);
		} else {
			return TypeDescNull({
				label: opts.label,
				description: opts.description,
				example: opts.example,
				tags: opts.tags
			});
		}
	};

	schema.resolve = (modelNode, params, notify, force) => {
		const hasChanged = resolveValue(modelNode, params, undefined, undefined, force);

		if (notify && hasChanged) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		} else {
			const eventData = {
				modelNode: modelNode,
				changeType: MODEL_CHANGE_TYPE.STRUCTURE
			};

			emitEvent(modelNode.changeEvent, eventData);
		}
	};

	schema.reset = (modelNode, notify) => {
		resolveValue(modelNode, null, undefined, undefined, false);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		} else {
			const eventData = {
				modelNode: modelNode,
				changeType: MODEL_CHANGE_TYPE.STRUCTURE
			};

			emitEvent(modelNode.changeEvent, eventData);
		}
	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.value
			? [
					{
						key: "value",
						node: modelNode.value
					}
			  ]
			: [];
	};

	return schema;
}
