/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { useContext } from "react";
import { TranslationContext } from "./TranslationContext";

export type TTranslateHookFn = (
	domain: string,
	term: string,
	vars?: { [K: string]: string | number | boolean },
	fallbackValue?: string
) => string;

/**
 * Hook that returns a translation function
 */
export function useTranslate(): TTranslateHookFn {
	const tCtx = useContext(TranslationContext);

	return (
		domain: string,
		term: string,
		vars?: { [K: string]: string | number | boolean },
		fallbackValue?: string
	) => {
		if (tCtx.translate) {
			return tCtx.translate(domain, term, vars, fallbackValue);
		} else {
			console.warn("No translate function is available.");
			return term;
		}
	};
}
