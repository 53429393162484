/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IAccount } from "../accounts";
import { ILogger } from "../logger";
import { ISession } from "../sessions";
import { IExecutionContext, IExecutionContextRequest } from "./IExecutionContext";

/**
 * Execution context
 */
export class ExecutionContext implements IExecutionContext {
	private _user: IAccount;

	public get user(): IAccount {
		return this._user;
	}

	public meta: { actionNodeCalls: number };

	public request: IExecutionContextRequest = {};

	public constructor(
		public session: ISession,
		protected logger: ILogger,
		user?: IAccount,
		request?: IExecutionContextRequest
	) {
		this.meta = { actionNodeCalls: 0 };
		this._user = user ? user : null;
		this.request = request || this.request;
	}

	public debug(...args: unknown[]): void {
		this.logger.debug(...args);
	}

	public info(...args: unknown[]): void {
		this.logger.info(...args);
	}

	public notice(...args: unknown[]): void {
		this.logger.notice(...args);
	}

	public warn(...args: unknown[]): void {
		this.logger.warn(...args);
	}

	public error(...args: unknown[]): void {
		this.logger.error(...args);
	}
}
