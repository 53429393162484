/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBaseDependencies, IDependencyContainer, IDisposableDependency } from "./IDependencyContainer";

/**
 * Dependency Container Components
 */
type TDependencyContainerComponents<TDep> = { [K in keyof TDep]: TDep[K] };

/**
 * Dependency Container
 */
export class DependencyContainer<TDep extends IBaseDependencies> implements IDependencyContainer<TDep> {
	/** Dependency list */
	private dependencies: TDependencyContainerComponents<TDep>;

	public constructor() {
		this.dependencies = {} as TDependencyContainerComponents<TDep>;
	}

	/**
	 * Registers dependencies
	 *
	 * @param name Dependency name
	 * @param dependency Dependency instance
	 */
	public register<TName extends keyof TDep>(name: TName, dependency: TDep[TName]): void {
		if (this.dependencies[name]) {
			throw new Error(`Dependency ${String(name)} already registered.`);
		}

		this.dependencies[name] = dependency;
	}

	/**
	 * Returns dependency
	 *
	 * @param name Dependency name
	 * @returns
	 */
	public get<TName extends keyof TDep>(name: TName): TDep[TName] | null {
		if (!this.dependencies[name]) {
			throw new Error(`Dependency ${String(name)} was not registered.`);
		}

		return this.dependencies[name];
	}

	/**
	 * Returns if a dependency is registered
	 *
	 * @param name Dependency name
	 */
	public has<TName extends keyof TDep>(name: TName): boolean {
		return this.dependencies[name] ? true : false;
	}

	/**
	 * Initializes dependencies
	 */
	public async init(): Promise<void> {
		for (const name of Object.keys(this.dependencies)) {
			const dependency = this.dependencies[name] as IDisposableDependency;
			if (typeof dependency.init === "function") {
				await dependency.init();
			}
		}
	}

	/**
	 * Disposes dependencies
	 */
	public async dispose(): Promise<void> {
		for (const name of Object.keys(this.dependencies)) {
			const dependency = this.dependencies[name] as IDisposableDependency;
			if (typeof dependency.dispose === "function") {
				await dependency.dispose();
			}
		}
	}
}
