/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TErrors } from "../IError";

export const GRPC_ERRORS: TErrors = {
	1: "The operation was aborted on the client side.",
	2: "Unknown error.",
	3: "Incorrect request parameters specified. Details are provided in the details field.",
	4: "Exceeded the server response timeout.",
	5: "The requested resource not found.",
	6: "Attempt to create a resource that already exists.",
	7: "The user has no permissions required to perform the operation.",
	8: "The request limit exceeded.",
	9: "The operation was canceled because the conditions required for the operation were not met. Examples: attempting to delete a non-empty folder or calling the rmdir command for an object that is not a folder.",
	10: "The operation failed due to a concurrent computing conflict, such as an invalid sequence of commands or an aborted transaction.",
	11: "Out of range. For example, searching or reading outside of the file.",
	12: "The operation is not supported by the service.",
	13: "Internal server error. This error means that the operation cannot be performed due to a server-side technical problem. For example, due to insufficient computing resources.",
	14: "The service is currently unavailable. Try again in a few seconds.",
	15: "Permanent data loss or damage.",
	16: "The operation requires authentication."
};

export const GRPC_ERROR_NAMES: TErrors = {
	0: "OK",
	1: "CANCELLED",
	2: "UNKNOWN",
	3: "INVALID_ARGUMENT",
	4: "DEADLINE_EXCEEDED",
	5: "NOT_FOUND",
	6: "ALREADY_EXISTS",
	7: "PERMISSION_DENIED",
	8: "RESOURCE_EXHAUSTED",
	9: "FAILED_PRECONDITION",
	10: "ABORTED",
	11: "OUT_OF_RANGE",
	12: "UNIMPLEMENTED",
	13: "INTERNAL",
	14: "UNAVAILABLE",
	15: "DATA_LOSS",
	16: "UNAUTHENTICATED"
};
