/**
 * Use Arrow Keys Navigation HAE hook
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Key } from "ts-key-enum";

export enum ARROW_KEYS_NAVIGATION_TYPE {
	VERTICAL = "vertical",
	DEFAULT = VERTICAL
}

/**
 * Use Arrow Keys Navigation
 *
 * @param type Navigation type, not implemented yet
 * @param targetSelector Target selector
 * @param focusableElementsSelector Focusable elements selector
 */
export function useArrowKeysNavigation(
	type: ARROW_KEYS_NAVIGATION_TYPE,
	targetSelector: string,
	focusableElementsSelector = "a, button, input, *[tabindex]"
): (event: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => void {
	return React.useCallback(
		(event: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => {
			const up = event.key === Key.ArrowUp;
			const down = event.key === Key.ArrowDown;

			if (up || down) {
				event.preventDefault();

				const target =
					event.currentTarget === window
						? document.body
						: (event.currentTarget as HTMLElement).closest(targetSelector);

				if (!target || !document.activeElement || !target.contains(document.activeElement)) {
					return;
				}

				const focusableElements = Array.from<HTMLElement>(
					target.querySelectorAll(focusableElementsSelector)
				);

				let index = focusableElements.indexOf(document.activeElement as HTMLElement);

				if (index >= 0) {
					index = up
						? index > 0
							? index - 1
							: focusableElements.length - 1
						: index < focusableElements.length - 1
						? index + 1
						: 0;

					if (typeof focusableElements[index] !== "undefined") {
						focusableElements[index].focus();
					}
				}
			}
		},
		[ targetSelector, focusableElementsSelector ]
	);
}
