/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { createSuccessNodeResult, unknownNodeError } from "../helpers";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintLogoutNodeOptsSchemaSpec } from "../../blueprints";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";

export async function logoutNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintLogoutNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;

	context.debug("Logout node.");

	try {
		await context.session.logout();

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: null,
				typeDescriptor: Type.Void({})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);
	}
}
