/**
 * Iframe HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { HTMLAttributeReferrerPolicy } from "react";

import { BP, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumValue,
	getValuesFromStringEnum,
	THAEComponentDefinition,
	THAEComponentReact,
	propGroups
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { IFRAME_LOADING, IFRAME_LOADING_default } from "../../Enums/IFRAME_LOADING";

interface HAEComponentIframe_State {}

/**
 * Iframe props
 */
const HAEComponentIframe_Props = {
	source: BP.Prop(
		BP.String({
			...termsEditor.schemas.iframe.source,
			default: "",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.common
	),

	loading: BP.Prop(
		BP.Enum.String({
			...termsEditor.schemas.iframe.loading,
			options: getValuesFromStringEnum(IFRAME_LOADING, termsEditor.schemas.iframe.loadingValues),
			default: IFRAME_LOADING_default
		}),
		10,
		propGroups.common
	),

	htmlAllow: BP.Prop(
		BP.String({
			...termsEditor.schemas.iframe.htmlAllow
		}),
		0,
		propGroups.advanced
	),

	htmlReferrerPolicy: BP.Prop(
		BP.String({
			...termsEditor.schemas.iframe.htmlReferrerPolicy
		}),
		10,
		propGroups.advanced
	),

	htmlSandbox: BP.Prop(
		BP.String({
			...termsEditor.schemas.iframe.htmlSandbox
		}),
		20,
		propGroups.advanced
	)
};

const HAEComponentIframe_Events = {};

const HAEComponentIframe_Definition = defineElementaryComponent<
	typeof HAEComponentIframe_Props,
	HAEComponentIframe_State,
	typeof HAEComponentIframe_Events
>({
	...termsEditor.components.iframe.component,

	name: "iframe",

	category: "content",

	icon: "mdi/application-outline",

	docUrl: "...",

	order: 210,

	props: HAEComponentIframe_Props,

	events: HAEComponentIframe_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const classNames = {
	root: "cmp-iframe",
	iframe: "cmp-iframe__iframe"
};

const HAEComponentIframe_React: THAEComponentReact<typeof HAEComponentIframe_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { source, loading, htmlReferrerPolicy, htmlAllow, htmlSandbox, ...restProps } = props;

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		classNames.root,
		componentInstance.safePath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const iframeClassList = new ClassList(classNames.iframe);

	const iframeProps: React.IframeHTMLAttributes<HTMLIFrameElement> = {
		...restProps,
		id: idClassName,
		src: source,
		loading: getStringEnumValue(IFRAME_LOADING, loading, IFRAME_LOADING_default),
		allow: htmlAllow,
		sandbox: htmlSandbox,
		referrerPolicy: htmlReferrerPolicy as HTMLAttributeReferrerPolicy
	};

	return (
		<div className={classList.toClassName()}>
			<iframe className={iframeClassList.toClassName()} {...iframeProps} />
		</div>
	);
};

export const HAEComponentIframe: THAEComponentDefinition<typeof HAEComponentIframe_Definition> = {
	...HAEComponentIframe_Definition,
	reactComponent: HAEComponentIframe_React
};
