/**
 * Text component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const oneOf = {
	component: {
		label: "component:oneOf#label",
		description: "component:oneOf#description"
	},
	schema: {
		options: {
			label: "component:oneOf.schema.options#label",
			description: "component:oneOf.schema.options#description"
		},
		optionItem: {
			label: "component:oneOf.schema.optionItem#label"
		},
		optionCondition: {
			label: "component:oneOf.schema.optionCondition#label",
			description: "component:oneOf.schema.optionCondition#description"
		},
		optionPreview: {
			label: "component:oneOf.schema.optionPreview#label",
			description: "component:oneOf.schema.optionPreview#description"
		},
		optionContent: {
			label: "component:oneOf.schema.optionContent#label",
			description: "component:oneOf.schema.optionContent#description"
		}
	},
	typeDesc: {
		matchIndex: {
			label: "component:oneOf.typeDesc.matchIndex#label",
			description: "component:oneOf.typeDesc.matchIndex#description"
		}
	}
};
