/*
 * Hexio App Engine Editor
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

/* Node.ELEMENT_TYPE */
import { isBrowser, isNil } from "@hexio_io/hae-lib-shared";

const ELEMENT_TYPE = 1;

export const STYLE_ATTR = "data-styles";

/** Find last style element if any inside target */
const findLastStyleTag = (target: HTMLElement): undefined | HTMLStyleElement => {
	const { childNodes } = target;

	for (let i = childNodes.length; i >= 0; i--) {
		const child = childNodes[i] as HTMLElement | null | undefined;
		if (child && child.nodeType === ELEMENT_TYPE && child.hasAttribute(STYLE_ATTR)) {
			return child as HTMLStyleElement;
		}
	}

	return undefined;
};

/** Create a style element inside `target` or <head> after the last */
function createStyleTag(target?: HTMLElement): HTMLStyleElement {
	const head = document.head as HTMLElement;
	const parent = target || head;
	const style = document.createElement("style");
	const prevStyle = findLastStyleTag(parent);
	const nextSibling = !isNil(prevStyle) ? prevStyle.nextSibling : null;

	style.setAttribute(STYLE_ATTR, "true");
	parent.insertBefore(style, nextSibling);

	return style;
}

export interface IStyleTag {
	insertRules(id: string, rules: string[]): void;
	deleteRules(id: string): void;
}

class CSSOmTag implements IStyleTag {
	private element: HTMLStyleElement;
	private sheet: CSSStyleSheet;
	private rulesIndexes: string[] = [];

	constructor(target?: HTMLElement) {
		const element = (this.element = createStyleTag(target));

		// Avoid Edge bug where empty style elements don't create sheets
		element.appendChild(document.createTextNode(""));

		this.sheet = element.sheet;
	}

	insertRules(id: string, rules: string[]) {
		this.deleteRules(id);

		for (let i = 0; i < rules.length; i++) {
			const index = this.sheet.cssRules.length;

			try {
				this.sheet.insertRule(rules[i], index);
				this.rulesIndexes[index] = id;
			} catch (err) {
				console.warn(err);
				console.warn(rules[i]);
			}
		}
	}

	deleteRules(id: string): void {
		const index = this.rulesIndexes.findIndex((ruleId) => ruleId === id);

		while (this.rulesIndexes[index] === id) {
			this.sheet.deleteRule(index);
			this.rulesIndexes.splice(index, 1);
		}
	}
}

// This is too slow when resizing
class TextTag implements IStyleTag {
	element: HTMLStyleElement;
	nodes = new Map<string, Node[]>();
	length: number;

	constructor(target?: HTMLElement) {
		this.element = createStyleTag(target);
		this.length = 0;
	}

	insertRules(id: string, rules: string[]): void {
		if (!this.nodes.has(id)) {
			this.nodes.set(id, []);
		}

		const nodes = this.nodes.get(id);

		for (let i = 0; i < rules.length; i++) {
			if (nodes[i]) {
				nodes[i].textContent = rules[i];
			} else {
				nodes[i] = document.createTextNode(rules[i]);
				this.element.insertBefore(nodes[i], null);
			}
		}
	}

	deleteRules(id: string): void {
		if (this.nodes.has(id)) {
			const nodes = this.nodes.get(id);

			for (let i = 0; i < nodes.length; i++) {
				this.element.removeChild(nodes[i]);
			}

			this.nodes.delete(id);
		}
	}
}

class VirtualTag implements IStyleTag {
	private rules = new Map<string, string[]>();
	private length = 0;

	constructor(target?: HTMLElement) {
		// empty constructor
	}

	insertRules(id: string, rules: string[]): void {
		this.rules.set(id, [ ...rules ]);
	}

	deleteRules(id: string): void {
		this.rules.delete(id);
	}
}

/** Create a CSSStyleSheet-like tag depending on the environment */
export function makeTag(target?: HTMLElement): IStyleTag {
	if (!isBrowser()) {
		return new VirtualTag(target);
	} else {
		return new CSSOmTag(target);
	}
}
