/**
 * Number Field Input HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect, useRef, useState } from "react";

import { isFunction } from "@hexio_io/hae-lib-shared";

export interface NumberFieldInputProps extends React.HTMLProps<HTMLInputElement> {
	onValueChange?: (value: number) => void;
	inputDebounceMs?: number;
}

export const NumberFieldInput: React.FC<NumberFieldInputProps> = ({
	value,
	onValueChange,
	inputDebounceMs,
	...props
}) => {
	const [ internalValue, setInternalValue ] = useState<string>(
		Number.isFinite(value) ? `${value}` : ""
	);

	const debounceTimeout = useRef<ReturnType<typeof setTimeout>>(undefined);

	const _inputChangeHandler = React.useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const { valueAsNumber, value } = event.currentTarget;
			
			setInternalValue(value);

			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
				debounceTimeout.current = undefined;
			}

			if (isFunction(onValueChange)) {
				if (inputDebounceMs) {
					debounceTimeout.current = setTimeout(() => {
						onValueChange(Number.isFinite(valueAsNumber) ? valueAsNumber : null);
					}, inputDebounceMs);
				} else {
					onValueChange(Number.isFinite(valueAsNumber) ? valueAsNumber : null);
				}
			}
		},
		[ onValueChange, inputDebounceMs, debounceTimeout ]
	);

	useEffect(() => {
		if (value !== internalValue) {
			setInternalValue(Number.isFinite(value) ? `${value}` : "");
		}
	}, [ value ]);

	useEffect(() => {
		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
				debounceTimeout.current = undefined;
			}
		};
	}, []);

	return (
		<input
			{...props}
			type="number"
			value={internalValue}
			onChange={_inputChangeHandler}
		/>
	);
};
