/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../../terms";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export enum ENDPOINT_PARAM_IN_TYPES {
	Query = "query",
	Header = "header",
	Path = "path",
	Cookie = "cookie"
}

export enum ENDPOINT_BODY_PARSER_TYPES {
	NONE = "none",
	JSON = "json",
	FORM = "form",
	TEXT = "text"
}

export enum ENDPOINT_MEDIA_TYPES {
	ANY = "*/*",
	APPLICATION_JSON = "application/json",
	APPLICATION_X_WWW_FORM_URLENCODED = "application/x-www-form-urlencoded",
	TEXT_CSS = "text/css",
	TEXT_PLAIN = "text/plain",
	TEXT_HTML = "text/html"
}

export enum ENDPOINT_REQUEST_METHODS {
	GET = "get",
	PUT = "put",
	POST = "post",
	DELETE = "delete",
	PATCH = "patch"
	//OPTIONS = "options",
	//HEAD = "head",
	//TRACE = "trace",
}

const DescriptionSchema = BP.String({
	label: termsEditor.nodes.request.description.label,
	description: termsEditor.nodes.request.description.description
});
//const OperationIdSchema = BP.String({});
const RequiredSchema = BP.Boolean({
	label: termsEditor.nodes.request.required.label,
	description: termsEditor.nodes.request.required.description,
	default: false
});

const DeprecatedSchema = BP.Boolean({
	label: termsEditor.nodes.request.deprecated.label,
	description: termsEditor.nodes.request.deprecated.description,
	default: false
});

const JsonSchemaSchema = BP.String({
	label: termsEditor.nodes.request.jsonSchema.label,
	description: termsEditor.nodes.request.jsonSchema.description,
	example: `{
  "type": "object",
	"required": ["email"],
  "properties": {
    "user": { "type": "string" },
    "email": { "type": "string", "format": "email" }
  }
}`
});

const allowEmptyValue = BP.Boolean({
	label: termsEditor.nodes.request.parameter.allowEmptyValue.label,
	description: termsEditor.nodes.request.parameter.allowEmptyValue.description,
	default: false
});

//const summarySchema = BP.String({
//	label: termsEditor.nodes.request.summary.label,
//	description: termsEditor.nodes.request.summary.description,
//});

const ParameterObjectSchema = BP.Object({
	label: termsEditor.nodes.request.parameter.label,
	description: termsEditor.nodes.request.parameter.description,
	props: {
		name: BP.Prop(
			BP.String({
				label: termsEditor.nodes.request.parameter.name.label,
				description: termsEditor.nodes.request.parameter.name.description,
				constraints: {
					required: true
				}
			})
		),
		in: BP.Prop(
			BP.Enum.String({
				label: termsEditor.nodes.request.parameter.in.label,
				description: termsEditor.nodes.request.parameter.in.description,
				constraints: {
					required: true
				},
				options: Object.entries(ENDPOINT_PARAM_IN_TYPES).map(([ label, value ]) => ({ label, value }))
			})
		),
		//description: BP.Prop(DescriptionSchema),
		required: BP.Prop(RequiredSchema),
		deprecated: BP.Prop(DeprecatedSchema),
		allowEmptyValue: BP.Prop(allowEmptyValue),
		schema: BP.Prop(JsonSchemaSchema)
	}
});

const ParametersSchema = BP.Array({
	label: termsEditor.nodes.request.parameters.label,
	description: termsEditor.nodes.request.parameters.description,
	items: ParameterObjectSchema,
	default: []
});

//const HeaderObjectSchema = BP.Object({
//	label: termsEditor.nodes.request.header.label,
//	description: termsEditor.nodes.request.header.description,
//	props: {
//		description: BP.Prop(DescriptionSchema),
//		required: BP.Prop(RequiredSchema),
//		deprecated: BP.Prop(DeprecatedSchema),
//		allowEmptyValue: BP.Prop(allowEmptyValue),
//		schema: BP.Prop(JsonSchemaSchema)
//	}
//});

//const ExampleObjectSchema = BP.Object({
//	label: termsEditor.nodes.request.example.label,
//	description: termsEditor.nodes.request.example.description,
//	props: {
//		summary: BP.Prop(summarySchema),
//		description: BP.Prop(DescriptionSchema),
//		value: BP.Prop(BP.Any({
//			label: termsEditor.nodes.request.example.value.label,
//			description: termsEditor.nodes.request.example.value.description,
//			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
//		})),
//	}
//});

//const RequestBodyEncodingsSchema = BP.Map({
//	value: BP.Object({
//		props: {
//			contentType: BP.Prop(BP.Enum.String({
//				default: "text/plain",
//				options: [
//					{ label: "text/plain", value: "text/plain" },
//					{ label: "application/json", value: "application/json" }
//				],
//				constraints: {
//					required: true
//				}
//			}))
//		}
//	})
//});

//const MediaTypeSchema = BP.String({
//	label: termsEditor.nodes.request.mediaType.label,
//	description: termsEditor.nodes.request.mediaType.description,
//});

const MediaTypeSchema = BP.StringWithConst({
	label: termsEditor.nodes.request.mediaType.label,
	description: termsEditor.nodes.request.mediaType.description,
	constants: Object.entries(ENDPOINT_MEDIA_TYPES).map(([ , name ]) => ({ value: name, label: name })),
	default: ENDPOINT_MEDIA_TYPES.ANY,
	fallbackValue: ENDPOINT_MEDIA_TYPES.ANY
});

//const ExamplesMapSchema = BP.Map({
//	label: termsEditor.nodes.request.examples.label,
//	description: termsEditor.nodes.request.examples.description,
//	value: ExampleObjectSchema
//});

const RequestBodyObjectSchema = BP.Object({
	label: termsEditor.nodes.request.requestBody.label,
	description: termsEditor.nodes.request.requestBody.description,
	props: {
		//description: BP.Prop(DescriptionSchema),
		content: BP.Prop(
			BP.Object({
				label: termsEditor.nodes.request.requestBody.content.label,
				description: termsEditor.nodes.request.requestBody.content.description,
				props: {
					mediaType: BP.Prop(MediaTypeSchema),
					schema: BP.Prop(JsonSchemaSchema)
					//parser: BP.Prop(BP.Enum.String({
					//	label: termsEditor.nodes.request.requestBody.content.parser.label,
					//	description: termsEditor.nodes.request.requestBody.content.parser.description,
					//	default: ENDPOINT_BODY_PARSER_TYPES.TEXT,
					//	options: [
					//		{
					//			//label: "None",
					//			label: termsEditor.nodes.request.requestBody.content.parser.none.label,
					//			description: termsEditor.nodes.request.requestBody.content.parser.none.description,
					//			value: ENDPOINT_BODY_PARSER_TYPES.NONE
					//		},
					//		{
					//			//label: "Text",
					//			label: termsEditor.nodes.request.requestBody.content.parser.text.label,
					//			description: termsEditor.nodes.request.requestBody.content.parser.text.description,
					//			value: ENDPOINT_BODY_PARSER_TYPES.TEXT
					//		},
					//		{
					//			//label: "Form data",
					//			label: termsEditor.nodes.request.requestBody.content.parser.form.label,
					//			description: termsEditor.nodes.request.requestBody.content.parser.form.description,
					//			value: ENDPOINT_BODY_PARSER_TYPES.FORM
					//		},
					//		{
					//			//label: "Json",
					//			label: termsEditor.nodes.request.requestBody.content.parser.json.label,
					//			description: termsEditor.nodes.request.requestBody.content.parser.json.description,
					//			value: ENDPOINT_BODY_PARSER_TYPES.JSON
					//		}
					//	]
					//})),
					//limit: BP.Prop(BP.String({
					//	label: termsEditor.nodes.request.requestBody.content.limit.label,
					//	description: termsEditor.nodes.request.requestBody.content.limit.description,
					//	example: "500b; 10kb; 2mb;",
					//	default: CONST.ENDPOINT.REQUEST_BODY_SIZE_LIMIT
					//})),
					//examples: BP.Prop(ExamplesMapSchema),
					//encoding: BP.Prop(RequestBodyEncodingsSchema),
					//binary: BP.Prop(BP.Boolean({
					//	default: false
					//})),
				}
			})
		),
		required: BP.Prop(RequiredSchema)
		//content: BP.Prop(BP.Map({
		//	keyOpts: { default: "*/*" },
		//	constraints: {
		//		required: true
		//	},
		//	value: BP.Object({
		//		props: {
		//			schema: BP.Prop(JsonSchemaSchema),
		//			examples: BP.Prop(ExamplesMapSchema),
		//			//encoding: BP.Prop(RequestBodyEncodingsSchema),
		//			//binary: BP.Prop(BP.Boolean({
		//			//	default: false
		//			//})),
		//			parser: BP.Prop(BP.Enum.String({
		//				default: ENDPOINT_BODY_PARSER_TYPES.TEXT,
		//				options: [
		//					{ label: "None", value: ENDPOINT_BODY_PARSER_TYPES.NONE },
		//					{ label: "Text", value: ENDPOINT_BODY_PARSER_TYPES.TEXT },
		//					{ label: "Form data", value: ENDPOINT_BODY_PARSER_TYPES.FORM },
		//					{ label: "Json", value: ENDPOINT_BODY_PARSER_TYPES.JSON }
		//				]
		//			})),
		//			limit: BP.Prop(BP.String({
		//				label: "Body limit",
		//				description: "The size limit of the body content. Used only in case if body parser is selected.",
		//				example: "500b; 10kb; 2mb;",
		//				default: CONST.ENDPOINT.REQUEST_BODY_SIZE_LIMIT
		//			}))
		//		}
		//	})
		//})),
	}
});

//const ResponseEncodingsSchema = BP.Map({
//	value: BP.Object({
//		props: {
//			contentType: BP.Prop(BP.Enum.String({
//				default: "application/json",
//				options: [
//					{ label: "text/plain", value: "text/plain" },
//					{ label: "application/json", value: "application/json" }
//				],
//				constraints: {
//					required: true
//				}
//			}))
//		}
//	})
//});

//const ResponseObjectSchema = BP.Object({
//	props: {
//		description: BP.Prop(DescriptionSchema),
//		headers: BP.Prop(BP.Map({
//			value: HeaderObjectSchema
//		})),
//		content: BP.Prop(BP.Object({
//			props: {
//				mediaType: BP.Prop(MediaTypeSchema),
//				schema: BP.Prop(JsonSchemaSchema),
//				examples: BP.Prop(ExamplesMapSchema),
//				//encoding: BP.Prop(ResponseEncodingsSchema)
//			}
//		}))
//		//content: BP.Prop(BP.Map({
//		//	value: BP.Object({
//		//		props: {
//		//			schema: BP.Prop(JsonSchemaSchema),
//		//			examples: BP.Prop(ExamplesMapSchema),
//		//			encoding: BP.Prop(ResponseEncodingsSchema),
//		//		}
//		//	})
//		//}))
//	}
//});

//const ResponsesMapSchema = BP.Map({
//	label: termsEditor.nodes.request.responses.label,
//	description: termsEditor.nodes.request.responses.description,
//	keyOpts: {
//		validators: [
//			ValidatorString({
//				pattern: "^(default|[0-9]{3})$",
//				patternErrorMessage: "Response key expected to be a status code string. Allowed values '100'-'599' or 'default' key."
//			})
//		]
//	},
//	value: ResponseObjectSchema
//});

const TagsStringSchema = BP.Array({
	label: termsEditor.nodes.request.tags.label,
	description: termsEditor.nodes.request.tags.description,
	items: BP.String({
		label: termsEditor.nodes.request.tags.item.label,
		description: termsEditor.nodes.request.tags.item.description
	}),
	default: []
});

export const RequestNodeSchema = BP.Object({
	label: termsEditor.nodes.common.options.label,
	description: termsEditor.nodes.common.options.description,
	props: {
		path: BP.Prop(
			BP.String({
				label: termsEditor.nodes.request.path.label,
				description: termsEditor.nodes.request.path.description,
				default: "/",
				constraints: {
					required: true,
					/**
					 * More about path characters
					 * {@link https://spec.openapis.org/oas/v3.1.0#path-templating}
					 * {@link https://www.rfc-editor.org/rfc/rfc3986#section-2.3}
					 */
					// eslint-disable-next-line no-useless-escape
					pattern: "^/[a-zA-Z0-9._~/{}-]*$",
					patternErrorMessage: "Path contains invalid characters.",
					min: 1
				},
				example: "/users/{user_id}"
			})
		),
		method: BP.Prop(
			BP.Enum.String({
				label: termsEditor.nodes.request.method.label,
				description: termsEditor.nodes.request.method.description,
				default: ENDPOINT_REQUEST_METHODS.GET,
				options: Object.entries(ENDPOINT_REQUEST_METHODS).map(([ label, value ]) => ({
					value,
					label
				})),
				constraints: {
					required: true
				}
			})
		),
		tags: BP.Prop(TagsStringSchema),
		parameters: BP.Prop(ParametersSchema),
		requestBody: BP.Prop(RequestBodyObjectSchema)
		//summary: BP.Prop(summarySchema),
		//description: BP.Prop(DescriptionSchema),
		//operationId: BP.Prop(OperationIdSchema),
		//responses: BP.Prop(ResponsesMapSchema),
		//deprecated: BP.Prop(DeprecatedSchema),
	},
	editorOptions: {
		layoutType: "passthrough"
	}
});

export type TRequestNodeSchema = typeof RequestNodeSchema;

export const BlueprintRequestNode = {
	name: NODE_TYPES.REQUEST,
	label: "Request",
	icon: "mdi/tray-arrow-down",
	opts: RequestNodeSchema,
	editorOptions: {
		displayInPalette: false
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_REQUEST]: NODE_OUTPUTS.ON_REQUEST
	})
};

export type TBlueprintRequestNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintRequestNode["opts"]
>;
