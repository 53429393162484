/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IIntegrationRefFunctionItem,
	IIntegrationRefInstanceItem,
	IIntegrationRefResolver,
	IIntegrationRefTypeItem,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IIntegrationFunctionDefinition, TGenericIntegrationDefinition } from "../integrations";
import { IIntegrationDefRegistry, IResourceRegistry } from "../registries";
import { IIntegrationResourceProps, RESOURCE_TYPES } from "../resources";

/**
 * Integration Ref Resolver
 */
export class IntegrationRefResolver implements IIntegrationRefResolver {
	/**
	 * Invalidate event - emitted when integration instance list has changed (and model must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 */
	public constructor(
		protected resourceRegistry: IResourceRegistry,
		protected integrationDefRegistry: IIntegrationDefRegistry
	) {
		this.onInvalidate = createEventEmitter();
	}

	/**
	 * Formats integration type
	 *
	 * @param integrationDefinition Integration definition
	 * @returns
	 */
	private formatIntegrationType(
		integrationDefinition: TGenericIntegrationDefinition
	): IIntegrationRefTypeItem {
		return {
			integrationType: integrationDefinition.name,
			label: integrationDefinition.label,
			category: integrationDefinition.category,
			description: integrationDefinition.description,
			icon: integrationDefinition.icon,
			docUrl: integrationDefinition.docUrl
		};
	}

	/**
	 * Returns a list of available integration types
	 *
	 * @returns
	 */
	public getTypeList(): IIntegrationRefTypeItem[] {
		return this.integrationDefRegistry
			.getItemList()
			.map((definition) => this.formatIntegrationType(definition));
	}

	/**
	 * Returns integration type information by given type
	 *
	 * @param integrationType Integration type
	 * @returns
	 */
	public getTypeInfo(integrationType: string): IIntegrationRefTypeItem {
		return this.formatIntegrationType(this.integrationDefRegistry.get(integrationType));
	}

	/**
	 * Returns if a given integration type exists
	 *
	 * @param integrationType Integration type
	 * @returns
	 */
	public typeExists(integrationType: string): boolean {
		return this.integrationDefRegistry.get(integrationType) === null ? false : true;
	}

	/**
	 * Formats Integration info
	 *
	 * @param integrationDefinition Integration definition
	 * @param instanceProps Integration instance props
	 * @returns
	 */
	private formatIntegrationConfigInfo(
		integrationDefinition: TGenericIntegrationDefinition,
		instanceProps: IIntegrationResourceProps
	): IIntegrationRefInstanceItem {
		return {
			integrationId: instanceProps.id,
			label: integrationDefinition.label,
			description: integrationDefinition.description
		};
	}

	/**
	 * Returns integration instance list for a given type
	 *
	 * @param integrationType Integration type
	 * @returns
	 */
	public getInstanceListByType(integrationType: string): IIntegrationRefInstanceItem[] {
		const definition = this.integrationDefRegistry.get(integrationType);

		if (!definition) {
			return [];
		}

		const integrations = this.resourceRegistry
			.getItemList()
			.filter((resource) => resource.resourceType === RESOURCE_TYPES.INTEGRATION)
			.map((item: IIntegrationResourceProps) =>
				this.formatIntegrationConfigInfo(definition, item as IIntegrationResourceProps)
			);

		return integrations;
	}

	/**
	 * Returns integration instance info by given type and id
	 *
	 * @param integrationType Integration type
	 * @param integrationId
	 * @returns
	 */
	public getInstanceInfo(integrationType: string, integrationId: string): IIntegrationRefInstanceItem {
		const definition = this.integrationDefRegistry.get(integrationType);
		const instance = this.resourceRegistry
			.getItemList()
			.filter((item) => item.id === integrationId)[0] as IIntegrationResourceProps;

		if (!definition || !instance || instance.parsedData.type !== definition.name) {
			return null;
		}

		return this.formatIntegrationConfigInfo(definition, instance);
	}

	/**
	 * Returns if instance with given type and id exists
	 *
	 * @param integrationType Integration type
	 * @param integrationId
	 * @returns
	 */
	public instanceExists(integrationType: string, integrationId: string): boolean {
		const definition = this.integrationDefRegistry.get(integrationType);
		const instance = this.resourceRegistry
			.getItemList()
			.filter((item) => item.id === integrationId)[0] as IIntegrationResourceProps;
		const exists = definition && instance && instance.parsedData.type === definition.name ? true : false;
		return exists;
	}

	/**
	 * Formats Function into
	 *
	 * @param functionDefinition Function definition
	 * @returns
	 */
	private formatFunctionInfo(
		functionDefinition: IIntegrationFunctionDefinition
	): IIntegrationRefFunctionItem {
		return {
			functionName: functionDefinition.name,
			label: functionDefinition.label,
			description: functionDefinition.description,
			icon: functionDefinition.icon,
			docUrl: functionDefinition.docUrl
		};
	}

	/**
	 * Returns integration function list for a given type
	 *
	 * @param integrationType Integration type
	 * @returns
	 */
	public getFunctionList(integrationType: string): IIntegrationRefFunctionItem[] {
		const integrationDefinition = this.integrationDefRegistry.get(integrationType);

		if (integrationDefinition === null) {
			return [];
		}

		return Object.values(integrationDefinition.functions).map((item) => this.formatFunctionInfo(item));
	}

	/**
	 * Returns integration function information by given type and function name
	 *
	 * @param integrationType Integration type
	 * @param functionName Function name
	 * @returns
	 */
	public getFunctionInfo(integrationType: string, functionName: string): IIntegrationRefFunctionItem {
		const integrationDefinition = this.integrationDefRegistry.get(integrationType);

		if (integrationDefinition === null) {
			return null;
		}

		const functionDefinition = integrationDefinition.functions[functionName];

		if (!functionDefinition) {
			return null;
		}

		return this.formatFunctionInfo(functionDefinition);
	}

	/**
	 * Returns a params schema by action ID
	 *
	 * @param integrationType Integration type
	 * @param functionName Function name
	 * @returns
	 */
	public getFunctionParamsSchema(integrationType: string, functionName: string): TSchemaConstObjectProps {
		const integrationDefinition = this.integrationDefRegistry.get(integrationType);

		if (integrationDefinition === null) {
			return null;
		}

		const functionDefinition = integrationDefinition.functions[functionName];

		if (!functionDefinition) {
			return null;
		}

		return functionDefinition.paramsSchema;
	}
}
