/**
 * Use Chart hook
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { getCSSPropertyValue } from "@hexio_io/hae-lib-components";
import { isNumber, toNumber } from "@hexio_io/hae-lib-shared";
import { IColors, IStyles } from "../../types/charts";
import { getChartColor } from "../../Functions/chartHelpers";
import { TResolvedAxes } from "./state";
import { COMPOSED_CHART_AXIS_TYPE } from "../../Enums/COMPOSED_CHART_AXIS_TYPE";

export type AxisZoomState = {
	[K: string]: {
		start: number;
		end: number;
	}
}

export type AxisZoomEvent = {
	[K: string]: {
		startPosition: number;
		startValue: number|string|null;
		endPosition: number;
		endValue: number|string|null;
	}
}

export const colorProperties = {
	black: "BLACK",
	white: "WHITE",
	base: "TEXT",
	background: "BACKGROUND",
	primary: "PRIMARY",
	lightGray: "LIGHT_GRAY"
};

/**
 * Use Chart hook
 */
export function useChart(
	resolved: boolean,
	elementRef: React.MutableRefObject<HTMLDivElement>,
	viewportRootElement: HTMLElement,
	animate = false,
	datetimeUtc = false
): {
	rootRef: React.MutableRefObject<am5.Root>;
	colorRef: React.MutableRefObject<IColors>;
	colorValuesString: string;
	styleRef: React.MutableRefObject<IStyles>;
	styleValuesString: string;
} {
	const rootRef = React.useRef<am5.Root>();

	// Colors and styles

	const colorRef = React.useRef<IColors>({
		black: null,
		white: null,
		base: null,
		background: null,
		primary: null,
		lightGray: null
	});
	const styleRef = React.useRef<IStyles>({
		borderRadius: null,
		fontFamily: null,
		fontSizeBase: null,
		fontSizeLarge: null,
		spacing: null
	});

	React.useLayoutEffect(() => {
		if (!resolved) {
			return;
		}

		const colors = {};

		Object.entries(colorProperties).forEach(([ key, value ]) => {
			colors[key] = getChartColor(value, viewportRootElement);
		});

		colorRef.current = colors;

		styleRef.current = {
			borderRadius: toNumber(getCSSPropertyValue("--border-radius", viewportRootElement)) || 0,
			fontFamily:
				(getCSSPropertyValue("font-family", viewportRootElement) || "").split(",")[0] || "sans-serif",
			fontSizeBase: getCSSPropertyValue("--font-size-base", viewportRootElement),
			fontSizeLarge: getCSSPropertyValue("--font-size-large", viewportRootElement),
			spacing: toNumber(getCSSPropertyValue("--spacing-medium", viewportRootElement)) || 10
		};
	}, [ resolved, viewportRootElement ]);

	const colorValuesString = Object.values(colorRef.current).join(" ");
	const styleValuesString = Object.values(styleRef.current).join(" ");

	// Base configuration

	React.useLayoutEffect(() => {
		if (!resolved) {
			return;
		}

		const { base } = colorRef.current;
		const { fontFamily, fontSizeBase } = styleRef.current;

		const root = am5.Root.new(elementRef.current);

		root.interfaceColors.set("text", base);

		const defaultTheme = am5.Theme.new(root);

		defaultTheme.rule("Label").setAll({
			fontFamily,
			fontSize: fontSizeBase
		});

		const themes = [ defaultTheme ];

		if (animate) {
			console.log(111);

			themes.push(am5themes_Animated.new(root));
		}

		root.setThemes(themes);

		root.utc = datetimeUtc;

		rootRef.current = root;

		return () => {
			if (root) {
				root.dispose();
			}
		};
	}, [ resolved, colorValuesString, styleValuesString, animate, datetimeUtc ]);

	return {
		rootRef,
		colorRef,
		colorValuesString,
		styleRef,
		styleValuesString
	};
}

export function useChartEvents(
	resolvedAxes: TResolvedAxes,
	onAxisZoom: (state: AxisZoomEvent) => void,
	debouceTimeoutMs: number
) {
	const [ axisZoomState, setAxisZoomState ] = useState<AxisZoomState>({});
	const axisZoomTimeout = useRef<ReturnType<typeof setTimeout>>();
	const axisZoomInit = useRef(false);

	const onAxisStart = useCallback((axisId: string, value: number) => {
		setAxisZoomState((prev) => ({
			...prev,
			[axisId]: {
				start: value,
				end: prev[axisId]?.end ?? 1
			}
		}));
	}, [ setAxisZoomState ]);

	const onAxisEnd = useCallback((axisId: string, value: number) => {
		setAxisZoomState((prev) => ({
			...prev,
			[axisId]: {
				start: prev[axisId]?.start ?? 0,
				end: value
			}
		}));
	}, [ setAxisZoomState ]);

	const emitZoomEvent = useCallback((state: AxisZoomState) => {
		const eventData: AxisZoomEvent = {};

		for (const k in state) {
			const startPosition = state[k].start;
			const endPosition = state[k].end;
			let startValue: number|string|null = null;
			let endValue: number|string|null = null;

			const axis = resolvedAxes.get(k);

			if (axis) {
				switch (axis.type) {
					case COMPOSED_CHART_AXIS_TYPE.VALUE: {
						const config = axis.originalData.typeData.value.VALUE;
						
						if (config.min && config.max) {
							const range = config.max - config.min;

							startValue = config.min + (startPosition * range);
							endValue = config.min + (endPosition * range);
						}

						break;
					}
					case COMPOSED_CHART_AXIS_TYPE.DATETIME: {
						const config = axis.originalData.typeData.value.DATETIME;
						
						if (config.min && config.max) {
							const min = new Date(config.min);
							const max = new Date(config.max);

							const minTimestamp = min.getTime();
							const maxTimestamp = max.getTime();

							const range = maxTimestamp - minTimestamp;

							const startTimestamp = minTimestamp + (startPosition * range);
							const endTimestamp = minTimestamp + (endPosition * range);

							const startDate = new Date(startTimestamp);
							const endDate = new Date(endTimestamp);

							if (isNumber(config.min)) {
								startValue = startDate.getTime();
							} else {
								startValue = startDate.toISOString();
							}

							if (isNumber(config.max)) {
								endValue = endDate.getTime();
							} else {
								endValue = endDate.toISOString();
							}
						}

						break;
					}
				}
			}

			eventData[k] = {
				startPosition,
				startValue,
				endPosition,
				endValue
			};
		}

		if (Object.keys(eventData).length > 0) {
			onAxisZoom(eventData);
		}
	}, [ resolvedAxes, onAxisZoom ]);

	useEffect(() => {
		axisZoomInit.current = false;
		setAxisZoomState({});
	}, [ setAxisZoomState, axisZoomInit, debouceTimeoutMs, emitZoomEvent, resolvedAxes ]);

	useEffect(() => {
		if (!axisZoomInit.current) {
			axisZoomInit.current = true;
			return;
		}

		if (axisZoomTimeout.current) {
			clearTimeout(axisZoomTimeout.current);
		}

		axisZoomTimeout.current = setTimeout(() => {
			emitZoomEvent(axisZoomState);
			axisZoomTimeout.current = undefined;
		}, debouceTimeoutMs);

		return () => {
			if (axisZoomTimeout.current) {
				clearTimeout(axisZoomTimeout.current);
				axisZoomTimeout.current = undefined;
			}
		};
	}, [axisZoomState, axisZoomTimeout, axisZoomInit, debouceTimeoutMs, emitZoomEvent]);

	return {
		axisZoomState,
		onAxisStart,
		onAxisEnd
	};
}