/**
 * Chart helper functions
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";

import { COLOR, getStringEnumCssValue } from "@hexio_io/hae-lib-components";
import { isNonEmptyArray } from "@hexio_io/hae-lib-shared";
import { TCleanUpQueue } from "../types/charts";

/**
 * Creates clean up queue
 *
 * @param root Root
 */
export function createCleanUpQueue(root: am5.Root): TCleanUpQueue {
	return [ () => root.container.children.clear(), () => root.tooltipContainer.children.clear() ];
}

/**
 * Runs clean up
 *
 * @param cleanUpQueue Queue
 */
export function runCleanUp(cleanUpQueue: TCleanUpQueue): void {
	if (isNonEmptyArray(cleanUpQueue)) {
		cleanUpQueue.forEach((item) => item());
	}
}

/**
 * Returns chart color
 *
 * @param value Color value
 * @param computeElement Compute element
 */
export function getChartColor(value: string, computeElement?: HTMLElement): am5.Color {
	try {
		return am5.color(
			getStringEnumCssValue(
				COLOR,
				value || "BLACK",
				"color-",
				undefined,
				undefined,
				computeElement || true
			)
		);
	} catch (error) {
		// eslint-disable-next-line no-empty
	}

	return am5.color("#000000");
}
