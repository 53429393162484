/**
 * Box Shadow schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, ISchemaStringWithConst, ISchemaStringWithConstOpts } from "@hexio_io/hae-lib-blueprint";
import { BOX_SHADOW, BOX_SHADOW_default } from "../Enums/BOX_SHADOW";
import { getValuesFromStringEnum } from "../Functions/enumHelpers";

import { termsEditor } from "../terms";

/**
 * Box Shadow schema
 *
 * @param opts Options
 */
export function BoxShadowSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "boxShadow" }
): ISchemaStringWithConst {
	return BP.StringWithConst({
		...termsEditor.schemas.common.boxShadow,
		constants: getValuesFromStringEnum(BOX_SHADOW, termsEditor.schemas.common.boxShadowValues),
		default: BOX_SHADOW_default,
		fallbackValue: BOX_SHADOW_default,
		...opts
		/*constraints: {
			//...HAELibComponentsTerms.schemas.common.boxShadowConstraints,
			//pattern: ,
			...opts.constraints
		}*/
	});
}
