/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	IDataSourceResolver,
	TGenericDataSourceDefinition,
	TGenericDataSourceDefinitionMap
} from "@hexio_io/hae-lib-blueprint";
import { IDataSourceDefRegistry } from "../registries/IDataSourceDefRegistry";

/**
 * DataSource Resolver
 */
export class DataSourceResolver implements IDataSourceResolver {
	/**
	 * Constructor
	 */
	public constructor(private dataSourceRegistry: IDataSourceDefRegistry) {}

	/**
	 * Returns data source definition by name
	 */
	public getByType(type: string): TGenericDataSourceDefinition {
		return this.dataSourceRegistry.get(type);
	}

	/**
	 * Returns a map of registered data sources
	 */
	public getList(): TGenericDataSourceDefinitionMap {
		return this.dataSourceRegistry.getItems();
	}
}
