/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintSetSessionNodeOptsSchemaSpec } from "../../blueprints";
import { createSuccessNodeResult, unknownNodeError } from "../helpers";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";

export async function setSessionNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintSetSessionNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;

	const { userIdentityKey, meta } = opts;
	context.debug("Set session node:", { userIdentityKey, meta });

	if (userIdentityKey) {
		context.session.userIdentityKey = userIdentityKey;
	}
	if (meta) {
		context.session.meta = meta;
	}

	try {
		await context.session.save();

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: context.session.export(),
				typeDescriptor: Type.Void({})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);
	}
}
