/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TTypeDesc } from "@hexio_io/hae-lib-blueprint";
import { TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IActionDebugData } from "./INodeDebugData";
import { IActionParams } from "./IActionParams";
import { IActionResultErrorObject } from "./IActionResult";

/**
 * Action delegate lifecycle state
 */
export enum ACTION_DELEGATE_STATE {
	NOT_LOADED = "NOT_LOADED",
	LOADING = "LOADING",
	LOADED = "LOADED",
	ERROR = "ERROR"
}

/**
 * Action Delegete
 *
 * Provides interface to invoke actions
 */
export interface IActionDelegate {
	/** Event emitted when new data are recieved */
	readonly onUpdate: TSimpleEventEmitter<void>;

	/**
	 * Returns action ID
	 */
	getActionId(): string;

	/**
	 * Returns action params
	 */
	getParams(): IActionParams;

	/**
	 * Returns delegate action state
	 */
	getState(): ACTION_DELEGATE_STATE;

	/**
	 * Returns if data was already loaded at least once
	 */
	wasLoaded(): boolean;

	/**
	 * Returns loaded data
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getData(): any;

	/**
	 * Returns action result type descriptor (if was provided in response)
	 */
	getTypeDescriptor(): TTypeDesc;

	/**
	 * Returns action result debug data (if were provided in response)
	 */
	getDebugData(): IActionDebugData;

	/**
	 * Returns last error (present even when not in error state anymore)
	 */
	getLastError(): IActionResultErrorObject;

	/**
	 * Returns when the action was invoked last time as a unix timestamp in milliseconds
	 */
	getLastInvocationTimestamp(): number;

	/**
	 * Invokes an action
	 *
	 * @param reload If true the data will be reloaded even when cached, defaults to false
	 */
	invoke(reload?: boolean): Promise<void>;

	/**
	 * Adds interval for automatic refresh
	 *
	 * @param reloadIntervalMs Reload interval in milliseconds
	 */
	addReloadInterval(reloadIntervalMs: number): void;

	/**
	 * Removes interval for automatic refresh
	 *
	 * @param reloadIntervalMs Reload interval in milliseconds
	 */
	removeReloadInterval(reloadIntervalMs: number): void;
}
