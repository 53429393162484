/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { TGetBlueprintSchemaModel, TGetBlueprintSchemaSpec } from "../Schema/IBlueprintSchema";
import { TTypeDesc } from "../Shared/ITypeDescriptor";
import { TSchemaConstObjectProps, TSchemaConstObjectPropsSpec } from "../schemas/const/SchemaConstObject";
import { OBJECT_TYPE, OBJECT_TYPE_PROP_NAME, TMapMediaResolutions } from "../constants";
import { ISchemaComments } from "../schemas/SchemaComments";
import { ISchemaComponent } from "../schemas/SchemaComponent";
import { IScope } from "../Shared/Scope";
import { TComponentUpdateStateFunction } from "./IComponentDefinition";
import { IComponentStateBase } from "./IComponentStateBase";
import {
	IEventDefinitionMap,
	TEventEnabledMap,
	TEventTriggerMap,
	TGenericEventDefinitionMap,
	TSchemaEventsSpec
} from "../Events/EventTypes";

export type TSchemaComponentResolvedPropsSpec<TComponentProps extends TSchemaConstObjectProps> =
	TSchemaConstObjectPropsSpec<TComponentProps>;

export type TSchemaComponentResolvedInheritedPropsSpec<
	TComponentInheritedProps extends TSchemaConstObjectProps
> = TSchemaConstObjectPropsSpec<TComponentInheritedProps>;

/**
 * Component modifiers resolved spec
 */
export type TSchemaComponentModifiersSpec<TComponentProps extends TSchemaConstObjectProps> = Array<{
	name: string;
	enabled: boolean;
	props: TSchemaConstObjectPropsSpec<TComponentProps>;
	inheritedProps: TSchemaConstObjectPropsSpec<TComponentProps>;
	preview: boolean;
}>;

/**
 * Component display resolved spec
 */
export type TSchemaComponentDisplaySpec = {
	cssClassName: string;
	cssVisibility: "visible" | "hidden";
	mediaResolution: TMapMediaResolutions<boolean>;
	showLoading: boolean;
};

/**
 * Component mode
 */
export enum COMPONENT_MODE {
	NORMAL = "normal",
	READONLY = "readonly",
	EDIT = "edit"
}

/**
 * Component comments spec
 */
export type TSchemaComponentCommentsSpec = TGetBlueprintSchemaSpec<ISchemaComments>;

/**
 * Component node path
 */
export type TComponentNodePath = string[];

export type TComponentEventTrigger = (scope: IScope | ((parentScope: IScope) => IScope)) => Promise<void>;

/**
 * Rendered component instance
 */
export interface IComponentInstance<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap,
	TComponentInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> {
	/** Object type */
	[OBJECT_TYPE_PROP_NAME]: OBJECT_TYPE.COMPONENT;

	/** Component name */
	componentName: string;

	/** Component node path in render-tree */
	path: TComponentNodePath;

	/** Component node path sanitized */
	safePath: TComponentNodePath;

	/** Component mode */
	componentMode: COMPONENT_MODE;

	/** Original model node ID */
	modelNodeId: number;

	/** Unique component name within scope */
	id: string;

	/* Unique component ID within runtime */
	uid: number;

	/** Revision - incremented on every render when something change */
	rev: number;

	/*
	 * Unique component UID of a component that created this one.
	 * Can be used to link child component rendered within a dynamic container to a container's placeholder.
	 */
	originUid: number;

	/** Resolved component spec */
	props: TSchemaComponentResolvedPropsSpec<TComponentProps>;

	/** Resolved component spec */
	inheritedProps: TSchemaComponentResolvedInheritedPropsSpec<TComponentInheritedProps>;

	/** Spec of resolved base props */
	__basePropsSpec: TSchemaConstObjectPropsSpec<TComponentProps>;

	/** Spec of resolved inherted base props */
	__baseInheritedPropsSpec: TSchemaConstObjectPropsSpec<TSchemaConstObjectProps>;

	/** Spec of resolved modifiers */
	__modifiersSpec: TSchemaComponentModifiersSpec<TComponentProps>;

	/** Spec of resolved events */
	__eventsSpec: TSchemaEventsSpec;

	/** Trigger to handle component events */
	eventTriggers: TEventTriggerMap<TComponentEvents>;

	/** Map specifying which events are enabled */
	eventEnabled: TEventEnabledMap<TComponentEvents>;

	/** Spec of resolved events */
	display: TSchemaComponentDisplaySpec;

	/** Component state */
	state: TComponentState;

	/** Function to update state externally */
	setState: TComponentUpdateStateFunction<TComponentState>;

	/** Event emitter triggered when component spec or state change */
	onChange: TSimpleEventEmitter<void>;

	/** Event emitter triggered when component was destroyed */
	onDestroy: TSimpleEventEmitter<void>;

	/** Whenever component or it's children logs errors */
	hasErrors: boolean;

	/** Whenever component or it's children logs warnings */
	hasWarnings: boolean;

	/** If the component is loading */
	isLoading: boolean;

	/** If the component is loading but already has data from previous load */
	isLoadingWithData: boolean;

	/**
	 * If the component was fully rendered and could be displayed - if false, only dummy ghost should be displayed,
	 * used in edit mode to display "invisible" components.
	 */
	wasRendered: boolean;

	/** Last used type descriptor of scope data */
	lastScopeDataTypeDescriptor: TTypeDesc;

	/** If component is templated - eg. dynamic */
	isTemplated: boolean;

	/** Determines if inherited props has changed - use for component comparision to detect this change and "pass" it to parent */
	inheritedPropsHasChanged: boolean;

	/** If force comparistion function to mark this component as changed (used to force spec change) */
	forceCompareInvalidate: boolean;

	/** Comments (available in editor) */
	comments?: TSchemaComponentCommentsSpec;

	/** Component instance data from a previous render (entire render, not a single pass) */
	prevData: {
		/** Unique component name within scope */
		id: string;

		/** Component node path in render-tree */
		path: TComponentNodePath;

		/** Spec of resolved events */
		display: TSchemaComponentDisplaySpec;

		/** Comments (available in editor) */
		comments?: TSchemaComponentCommentsSpec;

		/** Resolved component spec */
		props: TSchemaComponentResolvedPropsSpec<TComponentProps>;

		/** Resolved component spec */
		inheritedProps: TSchemaComponentResolvedInheritedPropsSpec<TComponentInheritedProps>;

		/** Map specifying which events are enabled */
		eventEnabled: TEventEnabledMap<TComponentEvents>;

		/** Component state */
		state: TComponentState;

		/** Whenever component or it's children logs errors */
		hasErrors: boolean;

		/** Whenever component or it's children logs warnings */
		hasWarnings: boolean;

		/** If the component is loading */
		isLoading: boolean;

		/** If the component is loading but already has data from previous load */
		isLoadingWithData: boolean;

		/** If the component was fully rendered */
		wasRendered: boolean;
	};

	/** Custom component data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customData?: any;

	/** Model node (available only in edit mode via non-compiled render function) */
	modelNode?: TGetBlueprintSchemaModel<ISchemaComponent>;

	/** Debug meta-data - available only in design mode (via non-compiled render function) */
	debug?: {
		scope?: IScope;
	};
}

export type TGenericComponentInstance<
	TComponentInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> = IComponentInstance<
	TSchemaConstObjectProps,
	IComponentStateBase,
	TGenericEventDefinitionMap,
	TComponentInheritedProps
>;

export interface ISchemaInstanceList<
	TComponentProps extends TSchemaConstObjectProps,
	TComponentState extends IComponentStateBase,
	TComponentEvents extends IEventDefinitionMap,
	TComponentInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> extends Array<TGenericComponentInstance<TComponentInheritedProps>> {
	rootSpec: IComponentInstance<
		TComponentProps,
		TComponentState,
		TComponentEvents,
		TComponentInheritedProps
	>;
}

export type TGenericComponentInstanceList<
	TComponentInheritedProps extends TSchemaConstObjectProps = Record<string, never>
> = ISchemaInstanceList<
	TSchemaConstObjectProps,
	IComponentStateBase,
	TGenericEventDefinitionMap,
	TComponentInheritedProps
>;
