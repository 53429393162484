/**
 * Viewport Class
 *
 * @package hae-lib-shared
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isBoolean, isBrowser } from "@hexio_io/hae-lib-shared";

interface IViewportProperties {
	left: number;
	top: number;
	width: number;
	height: number;
}

export interface IViewport {
	fix(): void;
	getNode(): Window | HTMLElement;
	getRootElement(): HTMLElement;
	setRootElement(rootElement: HTMLElement): void;
	setInEditor(inEditor: boolean): void;
	getProperties(): IViewportProperties;
	getWindowWidth(): number;
	getWindowHeight(): number;
	getScrollLeft(): number;
	getScrollTop(): number;
	removeAllEventListeners(): void;
}

const defaultProperties: IViewportProperties = {
	left: 0,
	top: 0,
	width: 0,
	height: 0
};

export class Viewport implements IViewport {
	private rootElement: HTMLElement = null;
	private ready = false;
	private inEditor = false;
	private properties: IViewportProperties = defaultProperties;
	private resizeObserver: ResizeObserver;
	private eventListeners: Map<() => void, [string, Window | HTMLElement]> = new Map();

	// public onResize: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 *
	 * @param rootElementOrSelector Root element or selector
	 * @param inEditor In editor
	 */
	constructor(rootElementOrSelector: HTMLElement | string, inEditor = false) {
		if (isBrowser() && rootElementOrSelector) {
			this.setRootElement(rootElementOrSelector);

			if (isBoolean(inEditor)) {
				this.setInEditor(inEditor);
			}
		}

		this.fix();
	}

	public static DEFAULT_ROOT_SELECTOR = ".hae-app-root";
	private static WRAPPER_SELECTOR = ".edt-view-preview__wrapper";

	public static UPDATE_EVENT_TYPE = "hae_editor_viewport_update";
	public static UPDATE_MODE_EVENT_TYPE = "hae_editor_viewport_mode_update";
	public static SCROLL_EVENT_TYPE = "hae_editor_viewport_scroll";
	public static RESIZE_EVENT_TYPE = "hae_editor_viewport_resize";

	/**
	 * Fix
	 */
	public fix() {
		if (!this.ready) {
			return;
		}

		this.fixProperties();
		this.fixEventListeners();
	}

	/**
	 * Returns viewport node
	 */
	public getNode(): HTMLElement | Window {
		if (!this.ready) {
			return;
		}

		return this.getEditorNode() || window;
	}

	/**
	 * Returns viewport editor node
	 */
	private getEditorNode(): HTMLElement {
		if (!this.ready || !this.inEditor) {
			return;
		}

		return this.getRootElement().closest(Viewport.WRAPPER_SELECTOR) as HTMLElement;
	}

	/**
	 * Sets root element
	 */
	public setRootElement(rootElementOrSelector: HTMLElement | string): void {
		if (isBrowser() && rootElementOrSelector) {
			if (rootElementOrSelector instanceof HTMLElement) {
				this.rootElement = rootElementOrSelector;
			} else {
				this.rootElement = (document.querySelector(rootElementOrSelector) as HTMLElement) || null;
			}
		} else {
			this.rootElement = null;
		}

		this.ready = !!this.rootElement;
	}

	/**
	 * Returns root element
	 */
	public getRootElement(): HTMLElement {
		if (!this.ready) {
			return;
		}

		return this.rootElement;
	}

	/**
	 * Sets in editor
	 */
	public setInEditor(inEditor: boolean): void {
		this.inEditor = inEditor;
	}

	/**
	 * Returns window's width
	 */
	public getWindowWidth(): number {
		if (!this.ready) {
			return 0;
		}

		return window.visualViewport?.width || window.innerWidth;
	}

	/**
	 * Returns window's height
	 */
	public getWindowHeight(): number {
		if (!this.ready) {
			return 0;
		}

		return window.visualViewport?.height || window.innerHeight;
	}

	/**
	 * Fixes viewport properties
	 */
	private fixProperties(): void {
		if (!this.ready) {
			return;
		}

		if (!this.inEditor) {
			this.properties = {
				...defaultProperties,
				width: this.getWindowWidth(),
				height: this.getWindowHeight()
			};
		} else {
			const rootElement = this.getRootElement();
			const editorNode = this.getEditorNode();

			if (rootElement && editorNode) {
				const {
					left: nodeLeft,
					top: nodeTop,
					width: nodeWidth,
					height: nodeHeight
				} = editorNode.getBoundingClientRect();
				const {
					left: rootLeft,
					top: rootTop,
					width: rootWidth /*, height: rootHeight*/
				} = rootElement.getBoundingClientRect();

				// Only set properties when viewport is active (visible)

				if (rootWidth !== 0) {
					this.properties.left = Math.max(nodeLeft, rootLeft);
					this.properties.top = Math.max(nodeTop, rootTop);

					this.properties.width = Math.min(nodeWidth, rootWidth);

					if (this.properties.width === nodeWidth) {
						this.properties.width -= Math.max(0, nodeWidth - editorNode.clientWidth);
					}

					this.properties.height = nodeHeight - Math.max(0, nodeHeight - editorNode.clientHeight);

					Object.entries(this.properties).forEach(([ key, value ]) => {
						rootElement.style.setProperty(`--viewport-${key}`, `${value}px`);
					});
				}
			}
		}
	}

	/**
	 * Returns viewport dimensions and offset
	 */
	public getProperties(): IViewportProperties {
		if (!this.ready) {
			return defaultProperties;
		}

		return this.properties;
	}

	/**
	 * Returns viewport's scroll left
	 */
	public getScrollLeft(): number {
		if (!this.ready) {
			return 0;
		}

		if (this.inEditor) {
			return this.getEditorNode()?.scrollLeft || 0;
		}

		return (
			(typeof window.scrollX === "number" ? window.scrollX : document.documentElement.scrollLeft) || 0
		);
	}

	/**
	 * Returns viewport's scroll top
	 */
	public getScrollTop(): number {
		if (!this.ready) {
			return 0;
		}

		if (this.inEditor) {
			return this.getEditorNode()?.scrollTop || 0;
		}

		return (
			(typeof window.scrollY === "number" ? window.scrollY : document.documentElement?.scrollTop) || 0
		);
	}

	/**
	 * Fixes event listeners
	 */
	private fixEventListeners() {
		if (!this.ready) {
			return;
		}

		this.removeAllEventListeners();

		this.addEventListener("scroll", this._scrollHandler);

		if (this.inEditor) {
			this.addEventListener(Viewport.UPDATE_EVENT_TYPE, this._updateHandler, window);

			const editorNode = this.getEditorNode();

			if (editorNode) {
				if (!this.resizeObserver) {
					this.resizeObserver = new ResizeObserver(() => {
						this.fixProperties();
					});
				}

				this.resizeObserver.observe(editorNode);
			}
		}
	}

	/**
	 * Add event listener
	 *
	 * @param type Event type
	 * @param handler Event handler
	 * @param node Node
	 */
	private addEventListener(type: string, handler: () => void, node = this.getNode()): void {
		if (!this.ready || this.eventListeners.has(handler)) {
			return;
		}

		node.addEventListener(type, handler);

		this.eventListeners.set(handler, [ type, node ]);
	}

	/**
	 * Remove event listener
	 *
	 * @param type Event type
	 * @param handler Event handler
	 * @param node Node
	 */
	private removeEventListener(type: string, handler: () => void, node = this.getNode()): void {
		if (!this.ready) {
			return;
		}

		node.removeEventListener(type, handler);

		this.eventListeners.delete(handler);
	}

	/**
	 * Removes all event listeners
	 */
	public removeAllEventListeners(): void {
		[ ...this.eventListeners.entries() ].forEach(([ handler, [ type, node ] ]) => {
			this.removeEventListener(type, handler, node);
		});

		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	}

	/**
	 * Update handler
	 */
	private _updateHandler = (): void => {
		this.fixProperties();
	};

	/**
	 * Scroll handler
	 */
	private _scrollHandler = (): void => {
		window.dispatchEvent(new CustomEvent(Viewport.SCROLL_EVENT_TYPE));
	};
}
