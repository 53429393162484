/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export const BlueprintViewToHtmlNode = {
	name: NODE_TYPES.VIEW_TO_HTML,
	label: "View to HTML",
	icon: "mdi/language-html5",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.ViewRef({
		constraints: { required: true }
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR
	})
};

export type TBlueprintViewToHtmlNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintViewToHtmlNode["opts"]
>;
