/**
 * Button Group HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	Type,
	defineElementaryComponent,
	COMPONENT_MODE,
	ISchemaComponentListSpec
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getMedia,
	getStringEnumCssValue,
	getStringEnumValue,
	HAEComponentList,
	IButtonClickData,
	IHAEComponentListProps,
	ORIENTATION,
	ORIENTATION_default,
	ORIENTATION_flow,
	SPACING,
	StyleSheet,
	THAEComponentDefinition,
	THAEComponentReact,
	useComponentMainContext,
	useEditContext,
	useStyleSheetRegistry,
	useTranslate
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { HAEComponentButtonGroup_Props, TButtonGroupItemInheritedProps } from "./props";
import { resolveChildInlineStyle } from "./editModeFunctions";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";
import { resolveButtonStyle } from "../Button/resolveButtonStyle";
import { isBoolean, isFunction, isNonEmptyString, isValidObject } from "@hexio_io/hae-lib-shared";
import { BUTTON_GROUP_TYPE, BUTTON_GROUP_TYPE_default } from "../../Enums/BUTTON_GROUP_TYPE";
import { IButtonStateBase } from "../Button/Button";

interface IButtonGroupStateStates {
	[K: string]: IButtonStateBase | unknown;
}

interface HAEComponentButtonGroup_State {
	active: string[];
	matching: string[];
	states: IButtonGroupStateStates;
}

const HAEComponentButtonGroup_Events = {};

const HAEComponentButtonGroup_Definition = defineElementaryComponent<
	typeof HAEComponentButtonGroup_Props,
	HAEComponentButtonGroup_State,
	typeof HAEComponentButtonGroup_Events
>({
	...termsEditor.components.buttonGroup.component,

	name: "buttonGroup",

	category: "layout",

	icon: "mdi/gamepad-square",

	docUrl: "...",

	order: 35,

	container: true,

	props: HAEComponentButtonGroup_Props,

	events: HAEComponentButtonGroup_Events,

	resolve: (spec) => {
		const active = [];
		const matching = [];
		const states: IButtonGroupStateStates = {};

		spec.content
			.filter((item) => !!(item && item.id))
			.forEach((item) => {
				const buttonState = item.state as IButtonStateBase;

				if (buttonState && isBoolean(buttonState.active)) {
					states[item.id] = {
						active: buttonState.active,
						setActive: buttonState.setActive,
						link: buttonState.link,
						working: buttonState.working
					};

					if (buttonState.active) {
						active.push(item.id);
					}

					if (isValidObject(buttonState.link?.match)) {
						matching.push(item.id);
					}
				}
			});

		const newState = {
			active,
			matching,
			states
		};

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			active: state.active,
			matching: state.matching,
			states: state.states
		};
	},

	getScopeType: (spec) => {
		const statesItems = {};

		spec.content.map((item) => {
			statesItems[item.id] = Type.Any({});
		});

		return Type.Object({
			props: {
				active: Type.Array({
					...termsEditor.schemas.buttonGroup.scopeActive,
					items: [ Type.String({}) ]
				}),
				matching: Type.Array({
					...termsEditor.schemas.buttonGroup.scopeMatching,
					items: [ Type.String({}) ]
				}),
				states: Type.Map({
					...termsEditor.schemas.buttonGroup.scopeStates,
					items: statesItems
				})
			}
		});
	}
});

const HAEComponentButtonGroup_React: THAEComponentReact<typeof HAEComponentButtonGroup_Definition> = ({
	props,
	state,
	componentInstance,
	reactComponentClassList
}) => {
	const { content, orientation, borderRadiusGroup, separatorColor, separatorSize } = props;

	const { states } = state;

	const { safePath: componentPath, componentMode } = componentInstance;

	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editContext = useEditContext();
	const media = getMedia(inEditor ? editContext : undefined);

	const t = useTranslate();

	// Type

	const specTypeValue = getStringEnumValue(
		BUTTON_GROUP_TYPE,
		props.typeData.type,
		BUTTON_GROUP_TYPE_default
	);

	let _buttonClickHandler: (value: unknown, data: IButtonClickData) => void;

	switch (specTypeValue) {
		case BUTTON_GROUP_TYPE.TOGGLE: {
			_buttonClickHandler = (value, data) => {
				if (isValidObject(data) && isFunction(data.setActive)) {
					data.setActive(!data.active);
				}
			};

			break;
		}

		case BUTTON_GROUP_TYPE.SWITCH: {
			_buttonClickHandler = (value, data) => {
				if (isValidObject(data) && isFunction(data.setActive)) {
					if (data.active) {
						const { activeRequired } = props.typeData.value[props.typeData.type];

						if (!activeRequired) {
							data.setActive(false);
						}
					} else {
						Object.entries(states)
							.filter(([ key, value ]) => {
								return (
									key !== data.name &&
									isValidObject(value) &&
									value.active === true &&
									isFunction(value.setActive)
								);
							})
							.forEach(([ , value ]: [string, IButtonStateBase]) => value.setActive(false));

						data.setActive(true);
					}
				}
			};

			break;
		}
	}

	// Style

	const orientationValue = getStringEnumValue(ORIENTATION, orientation, ORIENTATION_default);
	const orientationValueHorizontal = orientationValue === ORIENTATION.HORIZONTAL;

	const flowValue = ORIENTATION_flow[orientationValue];

	const resolvedButtonStyle = resolveButtonStyle(props.styleData);

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-button-group",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		orientation: orientationValue,
		"border-radius-group": borderRadiusGroup
	});

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		const separatorSelector = `.${idClassName} > .cmp-button-group__content > .cmp-button-group__item`;
		const separatorElementSelector = `${separatorSelector}, ${separatorSelector}::before`;

		// Separator color

		if (separatorColor) {
			result.addColorProperties({
				selector: separatorSelector,
				name: "element-foreground-color",
				value: separatorColor
			});
		}

		// Separator size && offset

		if (separatorSize) {
			const separatorSizeValue = getStringEnumCssValue(SPACING, separatorSize, "spacing-");

			let properties = `--element-size: ${separatorSizeValue} !important;`;

			if (borderRadiusGroup === false && isNonEmptyString(resolvedButtonStyle?.borderRadius)) {
				properties += `--element-offset: ${resolvedButtonStyle.borderRadius} !important;`;
			}

			result.addString(separatorElementSelector, properties);
		}

		return result;
	}, [ idClassName, separatorColor, separatorSize, borderRadiusGroup, resolvedButtonStyle?.borderRadius ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	// Edit mode

	// eslint-disable-next-line max-len
	let componentListEditProps: Omit<
		IHAEComponentListProps<TButtonGroupItemInheritedProps, never>,
		"components" | "componentPath" | "componentMode"
	> = {};

	if (componentMode === COMPONENT_MODE.EDIT) {
		componentListEditProps = {
			childInlineStyle: (element, index) => {
				return resolveChildInlineStyle(element, index, media);
			} /*/
			allowResize: resolveAllowResize,
			onResizeBegin: (subject, resizeOffset) => {
				return handleResizeBegin(subject, resizeOffset, media, getGridCellDimensions(elementRef.current));
			},
			onResizeUpdate: (subject, element, resizeOffset, initialState) => {
				return handleResizeUpdate(subject, element, resizeOffset, initialState as IResizeInitialState, columns);
			},
			onResizeEnd: (subject, resizeOffset, initialState) => {
				return handleResizeEnd(subject, resizeOffset, initialState as IResizeInitialState, media, columns);
			},
			modifyModelOnDrop: (itemModel, element) => {
				return modifyModelOnDrop(itemModel, element, media, getGridCellDimensions(elementRef.current), columns);
			},*/,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			modelNode: componentInstance.modelNode?.props.props.content as unknown as any,
			dropZoneMode: orientationValueHorizontal ? DROP_ZONE_MODE.HORIZONTAL : DROP_ZONE_MODE.VERTICAL
		};
	}

	return (
		<div className={classList.toClassName()}>
			<HAEComponentList<TButtonGroupItemInheritedProps>
				components={content as ISchemaComponentListSpec<TButtonGroupItemInheritedProps>}
				componentPath={[ ...componentPath, "component-list" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-button-group__content")}
				childClassList={new ClassList("cmp-button-group__item", "item", `item--flow-${flowValue}`)}
				childComponentClassList={(element) =>
					new ClassList(
						"cmp-button-group__item-component",
						"item__component",
						`item__component--type-${element.type}`
					)
				}
				childComponentAdditionalProps={(element) => {
					if (element.type === "component" && element.cmpInstance) {
						const result: Record<string, unknown> = {};

						if (element.cmpInstance.inheritedProps?.itemOverrideStyle) {
							result.resolvedButtonStyle = resolvedButtonStyle;
						}

						if (isFunction(_buttonClickHandler)) {
							result.onClick = _buttonClickHandler;
						}

						return result;
					}
				}}
				dropText={t("editor", termsEditor.components.buttonGroup.dropText.label)}
				{...componentListEditProps}
			/>
		</div>
	);
};

export const HAEComponentButtonGroup: THAEComponentDefinition<typeof HAEComponentButtonGroup_Definition> = {
	...HAEComponentButtonGroup_Definition,
	reactComponent: HAEComponentButtonGroup_React
};
