/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const SESSIONS = {
	DEFAULT_STORAGE_ADAPTER_NAME: "adapptio",
	EXPIRATION: {
		// 1 year
		COOKIE: 365 * 24 * 60 * 60 * 1000,
		// 1 month
		STORAGE: 30 * 24 * 60 * 60 * 1000,
		// 1 month
		LAST_LOGIN: 30 * 24 * 60 * 60 * 1000
	},
	STORAGE: {
		FILENAME: "../var/sessions.json",
		// 1 month
		TTL_IN_MILLISECONDS: 1000 * 60 * 60 * 30,
		TTL_INTERVAL_IN_MILLISECONDS: 10000
	}
};
