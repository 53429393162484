/**
 * Button type resolver
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ISchemaConstObject,
	TGetBlueprintSchemaSpec,
	TSchemaConstObjectProps,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";
import { BUTTON_ROLE_string, BUTTON_TYPE_string } from "@hexio_io/hae-lib-components";
import { HAEComponentButton_Props } from "./props";

/**
 * Resolved button type
 */
interface IResolvedButtonType {
	type: BUTTON_TYPE_string;
	role?: BUTTON_ROLE_string;
	link?: TSchemaConstObjectPropsSpec<TSchemaConstObjectProps>;
}

/**
 * Resolves button type
 *
 * @param typeData Type data
 */
export function resolveButtonType(
	typeData: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentButton_Props>>["typeData"]
): IResolvedButtonType {
	const type = typeData.type as BUTTON_TYPE_string;

	const result: IResolvedButtonType = {
		type
	};

	switch (type) {
		case "DEFAULT": {
			result.role = typeData.value[type].role as BUTTON_ROLE_string;
			break;
		}

		case "LINK": {
			result.link = typeData.value[type];
			break;
		}
	}

	return result;
}
