/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const HaeExpressionMonarchTokens = {
	// Set defaultToken to invalid to see what you do not tokenize yet
	// defaultToken: 'invalid',

	keywords: [ "true", "TRUE", "false", "FALSE", "null", "NULL", "and", "AND", "or", "OR", "not", "NOT" ],

	operators: [ "==", "!=", "<", "<=", ">", ">=", "+", "-", "*", "/", "%", "||", "&&", "&" ],

	// we include these common regular expressions
	symbols: /[=><!~?:&|+\-*/^%]+/,
	escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
	digits: /\d+(_+\d+)*/,
	octaldigits: /[0-7]+(_+[0-7]+)*/,
	binarydigits: /[0-1]+(_+[0-1]+)*/,
	hexdigits: /[[0-9a-fA-F]+(_+[0-9a-fA-F]+)*/,

	regexpctl: /[(){}[\]$^|\-*+?.]/,
	regexpesc: /\\(?:[bBdDfnrstvwWn0\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})/,

	tokenizer: {
		root: [ [ /[{}]/, "delimiter.bracket" ], { include: "common" } ],

		common: [
			// functions
			[ /[a-zA-Z_$][a-zA-Z_0-9$]*\(/, { token: "type", goBack: 1 } ],

			// identifiers and keywords
			[
				/[a-zA-Z_$][a-zA-Z_0-9$]*/,
				{
					cases: {
						"@keywords": "keyword",
						"@default": "variable"
					}
				}
			],

			// whitespace
			{ include: "@whitespace" },

			// regular expression: ensure it is terminated before beginning (otherwise it is an opeator)
			[
				/\/(?=([^\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|\]|\}|$))/,
				{ token: "regexp", bracket: "@open", next: "@regexp" }
			],

			// delimiters and operators
			[ /[()[\]]/, "@brackets" ],
			[ /[<>](?!@symbols)/, "@brackets" ],
			[
				/@symbols/,
				{
					cases: {
						"@operators": "delimiter",
						"@default": ""
					}
				}
			],

			// numbers
			[ /(@digits)[eE]([-+]?(@digits))?/, "number.float" ],
			[ /(@digits)\.(@digits)([eE][-+]?(@digits))?/, "number.float" ],
			[ /(@digits)/, "number" ],

			// delimiter: after number because of .\d floats
			[ /[;,.]/, "delimiter" ],

			// strings
			[ /"([^"\\]|\\.)*$/, "string.invalid" ], // non-teminated string
			[ /'([^'\\]|\\.)*$/, "variable.invalid" ], // non-teminated string
			[ /"/, "string", "@string_double" ],
			[ /'/, "variable", "@string_single" ],
			[ /`/, "string", "@string_backtick" ]
		],

		whitespace: [
			[ /[ \t\r\n]+/, "" ],
			[ /\/\*\*(?!\/)/, "comment.doc", "@jsdoc" ],
			[ /\/\*/, "comment", "@comment" ],
			[ /\/\/.*$/, "comment" ]
		],

		comment: [
			[ /[^/*]+/, "comment" ],
			[ /\*\//, "comment", "@pop" ],
			[ /[/*]/, "comment" ]
		],

		jsdoc: [
			[ /[^/*]+/, "comment.doc" ],
			[ /\*\//, "comment.doc", "@pop" ],
			[ /[/*]/, "comment.doc" ]
		],

		// We match regular expression quite precisely
		regexp: [
			[
				/(\{)(\d+(?:,\d*)?)(\})/,
				[ "regexp.escape.control", "regexp.escape.control", "regexp.escape.control" ]
			],
			[
				/(\[)(\^?)(?=(?:[^\]\\/]|\\.)+)/,
				[ "regexp.escape.control", { token: "regexp.escape.control", next: "@regexrange" } ]
			],
			[ /(\()(\?:|\?=|\?!)/, [ "regexp.escape.control", "regexp.escape.control" ] ],
			[ /[()]/, "regexp.escape.control" ],
			[ /@regexpctl/, "regexp.escape.control" ],
			[ /[^\\/]/, "regexp" ],
			[ /@regexpesc/, "regexp.escape" ],
			[ /\\\./, "regexp.invalid" ],
			[ /(\/)([gimsuy]*)/, [ { token: "regexp", bracket: "@close", next: "@pop" }, "keyword.other" ] ]
		],

		regexrange: [
			[ /-/, "regexp.escape.control" ],
			[ /\^/, "regexp.invalid" ],
			[ /@regexpesc/, "regexp.escape" ],
			[ /[^\]]/, "regexp" ],
			[ /\]/, { token: "regexp.escape.control", next: "@pop", bracket: "@close" } ]
		],

		string_double: [
			[ /[^\\"]+/, "string" ],
			[ /@escapes/, "string.escape" ],
			[ /\\./, "string.escape.invalid" ],
			[ /"/, "string", "@pop" ]
		],

		string_single: [
			[ /[^\\']+/, "variable" ],
			[ /@escapes/, "variable.escape" ],
			[ /\\./, "variable.escape.invalid" ],
			[ /'/, "variable", "@pop" ]
		],

		string_backtick: [
			[ /\$\{/, { token: "delimiter.bracket", next: "@bracketCounting" } ],
			[ /[^\\`$]+/, "string" ],
			[ /@escapes/, "string.escape" ],
			[ /\\./, "string.escape.invalid" ],
			[ /`/, "string", "@pop" ]
		],

		bracketCounting: [
			[ /\{/, "delimiter.bracket", "@bracketCounting" ],
			[ /\}/, "delimiter.bracket", "@pop" ],
			{ include: "common" }
		]
	}
};

export const HaeExpressionMonarchTokens_Interpolation = {
	...HaeExpressionMonarchTokens,
	defaultToken: "string",
	tokenizer: {
		...HaeExpressionMonarchTokens.tokenizer,
		root: [ [ /\$\{/, { token: "delimiter.bracket", next: "@bracketCounting" } ] ]
	}
};
