/**
 * Terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { button } from "./editor/schemas/button";
import { color } from "./editor/schemas/color";
import { common } from "./editor/schemas/common";
import { container } from "./editor/schemas/container";
import { events } from "./editor/schemas/events";
import { httpRequest } from "./editor/schemas/httpRequest";
import { icon } from "./editor/schemas/icon";
import { image } from "./editor/schemas/image";
import { item } from "./editor/schemas/item";
import { label } from "./editor/schemas/label";
import { link } from "./editor/schemas/link";
import { linkLocation } from "./editor/schemas/linkLocation";
import { pagination } from "./editor/schemas/pagination";
import { propGroups } from "./editor/schemas/propGroups";
import { text } from "./editor/schemas/text";

export const termsEditor = {
	schemas: {
		button,
		common,
		color,
		container,
		events,
		httpRequest,
		icon,
		image,
		item,
		label,
		link,
		linkLocation,
		pagination,
		propGroups,
		text
	}
};

export const termsRuntime = {
	errors: {
		componentNotFound: "component:errors.componentNotFound",
		componentHasErrors: "component:errors.componentHasErrors",
		componentHasInvalidConfiguration: "component:errors.componentHasInvalidConfiguration"
	},
	states: {
		componentNotRendered: "component:states.componentNotRendered"
	},
	ui: {
		overlays: {
			close: "ui:overlays.close"
		},
		toastMessages: {
			hide: "ui:toastMessages.hide"
		}
	}
};
