/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { NODE_TYPES, TNodeTypes } from "../blueprints";
import { TNodeHandler } from "./TFlowNodeHandler";
import {
	actionNodeHandler,
	conditionNodeHandler,
	errorNodeHandler,
	fileCreateNodeHandler,
	fileReadNodeHandler,
	integrationNodeHandler,
	loginNodeHandler,
	logoutNodeHandler,
	mapNodeHandler,
	outputNodeHandler,
	redirectNodeHandler,
	reduceNodeHandler,
	requestNodeHandler,
	responseNodeHandler,
	setSessionNodeHandler,
	startNodeHandler,
	timeoutNodeHandler,
	transformNodeHandler,
	variableNodeHandler,
	viewToHtmlNodeHandler,
	whileNodeHandler
} from "./handlers";
import { actionNodeHandler_v2 } from "./handlers/action_v2";
import { logNodeHandler } from "./handlers/log";

export const ACTION_FLOW_NODES: { [keys in TNodeTypes]?: TNodeHandler } = {
	[NODE_TYPES.ACTION_V1_DEPRECATED]: actionNodeHandler,
	[NODE_TYPES.ACTION_V2]: actionNodeHandler_v2,
	[NODE_TYPES.CONDITION]: conditionNodeHandler,
	[NODE_TYPES.THROW]: errorNodeHandler,
	[NODE_TYPES.INTEGRATION]: integrationNodeHandler,
	[NODE_TYPES.RETURN]: outputNodeHandler,
	[NODE_TYPES.START]: startNodeHandler,
	[NODE_TYPES.TRANSFORM]: transformNodeHandler,
	[NODE_TYPES.VIEW_TO_HTML]: viewToHtmlNodeHandler,
	[NODE_TYPES.LOGIN]: loginNodeHandler,
	[NODE_TYPES.LOGOUT]: logoutNodeHandler,
	[NODE_TYPES.SET_SESSION]: setSessionNodeHandler,
	[NODE_TYPES.MAP]: mapNodeHandler,
	[NODE_TYPES.REDUCE]: reduceNodeHandler,
	[NODE_TYPES.VAR]: variableNodeHandler,
	[NODE_TYPES.TIMEOUT]: timeoutNodeHandler,
	[NODE_TYPES.WHILE]: whileNodeHandler,
	[NODE_TYPES.FILE_CREATE]: fileCreateNodeHandler,
	[NODE_TYPES.FILE_READ]: fileReadNodeHandler,
	[NODE_TYPES.LOG]: logNodeHandler
};

export const ENDPOINT_FLOW_NODES: { [keys in TNodeTypes]?: TNodeHandler } = {
	[NODE_TYPES.REQUEST]: requestNodeHandler,
	[NODE_TYPES.RESPONSE]: responseNodeHandler,
	[NODE_TYPES.REDIRECT]: redirectNodeHandler,
	[NODE_TYPES.ACTION_V1_DEPRECATED]: actionNodeHandler,
	[NODE_TYPES.ACTION_V2]: actionNodeHandler_v2,
	[NODE_TYPES.CONDITION]: conditionNodeHandler,
	[NODE_TYPES.TRANSFORM]: transformNodeHandler,
	[NODE_TYPES.LOG]: logNodeHandler
};
