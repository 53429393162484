/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";
import { inlineValue } from "../Context/CompileUtil";

type TValidatorEnumHandler = IBlueprintSchemaValidatorHandler<number | string>;
type TValidatorEnum<TOpts> = IBlueprintSchemaValidator<number | string, TOpts>;

/**
 * Enum items validator options
 */
export type IValidatorEnumOpts = {
	/** Required value */
	required?: boolean;
	/** Enum value */
	enum?: (string | number)[];
};

const VALIDATOR_NAME = "ValidatorEnum";

/**
 * Enum items validator
 */
export const ValidatorEnum: TValidatorEnum<IValidatorEnumOpts> = (
	opts: IValidatorEnumOpts
): TValidatorEnumHandler => {
	if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
	}

	if (opts.enum !== undefined && opts.enum !== null && !Array.isArray(opts.enum)) {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `enum` to be an Array");
	}

	if (opts.enum !== undefined && opts.enum !== null && Array.isArray(opts.enum) && opts.enum.length === 0) {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `enum` to have some elements");
	}

	return {
		validate: (value: number | string): IBlueprintSchemaValidationError[] => {
			const errors = [];

			if (
				// eslint-disable-next-line max-len
				(opts.required === true && typeof value !== "number" && typeof value !== "string") ||
				(typeof value === "number" && isNaN(value)) ||
				// eslint-disable-next-line max-len
				(!opts.required &&
					!(value === null || value === undefined) &&
					((typeof value !== "number" && typeof value !== "string") ||
						(typeof value === "number" && isNaN(value))))
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
					message: "Should be a string, integer or a number"
				});
			}

			if (value !== null && value !== undefined && opts.enum && !opts.enum.includes(value)) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Should be one of [ ${opts.enum.join(", ")} ]`,
						metaData: {}
					}
				];
			}

			return errors;
		},

		compile: (): string => {
			const parts = [];

			if (opts.required === true) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value !== "number" && typeof value !== "string" || (typeof value === "number" && isNaN(value))){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a string, integer or a number" }); }`
				);
			}

			if (!opts.required) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(!(value === null || value === undefined) && (typeof value !== "number" && typeof value !== "string" || (typeof value === "number" && isNaN(value)))){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a string, integer or a number" }); }`
				);
			}

			if (opts.enum !== undefined) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(value!==null&&value!==undefined&&![${opts.enum
						.map((x) => inlineValue(x))
						.join(",")}].includes(value)){ errors.push({ type: "${
						SCHEMA_VALIDATION_ERROR_TYPE.RANGE
					}",message: "Should be one of [ ${opts.enum.join(", ")} ]" }); }`
				);
			}

			// Minification
			const code = parts
				.join(" ")
				.replace(/{ /g, "{")
				.replace(/ }/g, "}")
				.replace(/: "/g, ':"')
				.replace(/ ,/g, ",")
				.replace(/ === /g, "===")
				.replace(/ && /g, "&&")
				.replace(/Array\.isArray/g, "i")
				.replace(/value/g, "v")
				.replace(/"number"/g, "n")
				.replace(/"string"/g, "s")
				.replace(/errors.push/g, "e.push");

			return `const e=[];const n="number";const s="string";${code} return e;`;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		export: (): any => {
			return exportValidator(VALIDATOR_NAME, [ opts ]);
		}
	};
};
