/**
 * Pagination React component
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";

import { ClassList, ICON_NAME, TPaginationProps, BOX_SHADOW } from "../";

import { IBaseProps } from "./props";
import { Button } from "./Button";
import { Icon } from "./Icon";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";
import { StyleSheet } from "../Classes/StyleSheet";
import { getStringEnumCssValue } from "../Functions/enumHelpers";
import { mapResponsiveValue } from "../Functions/responsiveValueHelpers";
import { useEditContext } from "../Editor/EditContext";
import { useComponentMainContext } from "../HAEComponent/HAEComponentContext";
import { getMedia } from "../Functions/componentHelpers";

/**
 * Returns total number of pages
 */
export function getTotalPages(items: number, limit: number): number {
	return Math.ceil(items / limit);
}

/**
 * Pagination props
 */
export interface IPaginationProps extends IBaseProps, TPaginationProps {
	/** Button click handler */
	onButtonClick?: (value: number) => void;
}

/**
 * Pagination component
 */
export const Pagination: React.FunctionComponent<IPaginationProps> = (props) => {
	const {
		items,
		limit,
		page,
		maxDisplayedPages,
		backgroundColor,
		foregroundColor,
		borderRadius,
		boxShadow,
		item,
		componentPath,
		componentMode,
		onButtonClick
	} = props;

	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editContext = useEditContext();
	const media = getMedia(inEditor ? editContext : undefined);

	const totalPages = getTotalPages(items, limit);
	const displayedPages = Math.min(totalPages, maxDisplayedPages);
	const displayedPagesHalf = displayedPages / 2;

	// Correction: prev pages + next pages
	// Don't ask me how I figured this one out
	const correction =
		Math.min(page - 1, Math.ceil(displayedPagesHalf) - 1) +
		Math.max(0, page + Math.floor(displayedPagesHalf) - totalPages);

	const pages =
		displayedPages > 0 ? [ ...Array(displayedPages).keys() ].map((item) => page + item - correction) : [];

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"pagination",
		componentPath,
		{ componentClassList: props.classList }
	);

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		const selector = `.${idClassName}`;

		// Background color

		if (backgroundColor) {
			result.addColorProperties({ selector, name: "element-background-color", value: backgroundColor });
		}

		// Foreground color

		if (foregroundColor) {
			result.addColorProperties([
				{ selector, name: "element-foreground-color", value: foregroundColor },
				{
					selector: `${selector} .pagination__item:not(.pagination__item--current)`,
					name: "element-foreground-color",
					value: foregroundColor
				}
			]);
		}

		// Border radius

		if (borderRadius) {
			result.addString(selector, `--element-border-radius: ${borderRadius} !important;`);
		}

		// Box shadow

		if (boxShadow) {
			const boxShadowValue = getStringEnumCssValue(BOX_SHADOW, boxShadow, "box-shadow-");

			result.addString(selector, `--element-box-shadow: ${boxShadowValue} !important;`);
		}

		// Item

		if (item) {
			const itemSelector = `${selector} .pagination__item`;

			if (item.borderRadius) {
				result.addString(itemSelector, `--element-border-radius: ${item.borderRadius} !important;`);
			}

			if (item.size) {
				const sizeValue = mapResponsiveValue(item.size);

				result.addResponsiveValue(sizeValue, selector, "--element-item-size", media);
			}

			if (item.currentState) {
				const itemCurrentStateSelector = `${itemSelector}.pagination__item--current`;

				if (item.currentState.backgroundColor) {
					result.addColorProperties({
						selector: itemCurrentStateSelector,
						name: "element-background-color",
						value: item.currentState.backgroundColor
					});
				}

				if (item.currentState.foregroundColor) {
					result.addColorProperties({
						selector: itemCurrentStateSelector,
						name: "element-foreground-color",
						value: item.currentState.foregroundColor
					});
				}
			}
		}

		return result;
	}, [ idClassName, backgroundColor, foregroundColor, borderRadius, boxShadow, item, media ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	return (
		<div className={classList.toClassName()}>
			{page > 1 ? (
				<Button
					style="CLEAR"
					value={page - 1}
					componentPath={[ ...componentPath, "page-prev" ]}
					componentMode={componentMode}
					classList={new ClassList("pagination__item", "pagination__item--prev")}
					onClick={onButtonClick}
				>
					<Icon
						source={ICON_NAME.CHEVRON_LEFT}
						componentPath={[ ...componentPath, "page-prev-icon" ]}
						componentMode={componentMode}
					/>
				</Button>
			) : (
				<span className="pagination__item pagination__item--prev pagination__item--disabled">
					<Icon
						source={ICON_NAME.CHEVRON_LEFT}
						componentPath={[ ...componentPath, "page-prev-icon" ]}
						componentMode={componentMode}
					/>
				</span>
			)}

			{pages.length ? (
				<div className="pagination__pages">
					{!pages.includes(1) ? (
						<>
							<Button
								style="CLEAR"
								value={1}
								componentPath={[ ...componentPath, "page-first" ]}
								componentMode={componentMode}
								classList={new ClassList("pagination__item")}
								onClick={onButtonClick}
							>
								{1}
							</Button>

							{!pages.includes(2) ? (
								<span className="pagination__item pagination__item--more">{"…"}</span>
							) : null}
						</>
					) : null}

					{pages.map((item) => {
						const itemClassList = new ClassList("pagination__item");

						if (page === item) {
							itemClassList.add("pagination__item--current");
						}

						return (
							<Button
								key={item}
								style="CLEAR"
								value={item}
								componentPath={[ ...componentPath, `page-${item}` ]}
								componentMode={componentMode}
								classList={itemClassList}
								onClick={onButtonClick}
							>
								{item}
							</Button>
						);
					})}

					{!pages.includes(totalPages) ? (
						<>
							{!pages.includes(totalPages - 1) ? (
								<span className="pagination__item pagination__item--more">{"…"}</span>
							) : null}

							<Button
								style="CLEAR"
								value={totalPages}
								componentPath={[ ...componentPath, "page-last" ]}
								componentMode={componentMode}
								classList={new ClassList("pagination__item")}
								onClick={onButtonClick}
							>
								{totalPages}
							</Button>
						</>
					) : null}
				</div>
			) : null}

			{page < totalPages ? (
				<Button
					style="CLEAR"
					value={page + 1}
					componentPath={[ ...componentPath, "page-next" ]}
					componentMode={componentMode}
					classList={new ClassList("pagination__item", "pagination__item--next")}
					onClick={onButtonClick}
				>
					<Icon
						source={ICON_NAME.CHEVRON_RIGHT}
						componentPath={[ ...componentPath, "page-next-icon" ]}
						componentMode={componentMode}
					/>
				</Button>
			) : (
				<span className="pagination__item pagination__item--next pagination__item--disabled">
					<Icon
						source={ICON_NAME.CHEVRON_RIGHT}
						componentPath={[ ...componentPath, "page-next-icon" ]}
						componentMode={componentMode}
					/>
				</span>
			)}
		</div>
	);
};
