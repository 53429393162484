/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BaseError, IBaseError } from "../errors";

/**
 * Action Error
 */
export class ActionError extends BaseError implements IBaseError {
	//public constructor(error: string, message: string) {
	//	super(error, message);
	//}
}
