/**
 * Info Dialog Overlay
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Button, Text, IBaseProps, ClassList } from "../..";

import { IInfoDialogOverlay } from "./Overlay";
import { IOverlayComponent } from "./IOverlayComponent";

/**
 * Info Dialog Overlay props
 */
interface IInfoDialogOverlayProps extends IBaseProps, IOverlayComponent {
	/** Data */
	data: IInfoDialogOverlay;
}

/**
 * Info Dialog Overlay component
 */
export const InfoDialogOverlay: React.FunctionComponent<IInfoDialogOverlayProps> = (props) => {
	const { id, remove, componentPath, componentMode } = props;
	const { text, buttons } = props.data;

	return (
		<div className="overlay-item__content">
			<Text
				{...text}
				componentPath={[ ...componentPath, "info-dialog-text" ]}
				componentMode={componentMode}
			/>

			{buttons.length ? (
				<div className="overlay-item__buttons">
					{buttons.map((item, index) => {
						const buttonId = item.id || `${index}`;

						function _buttonClickHandler() {
							remove(undefined, { overlayId: id, buttonId, customData: null });
						}

						return (
							<Button
								key={buttonId}
								labelText={item.labelText}
								labelIcon={item.labelIcon}
								backgroundColor={item.backgroundColor}
								foregroundColor={item.foregroundColor}
								width={item.width}
								enabled={item.enabled}
								componentPath={[ ...componentPath, buttonId ]}
								componentMode={componentMode}
								classList={new ClassList("button--layout")}
								onClick={_buttonClickHandler}
							/>
						);
					})}
				</div>
			) : null}
		</div>
	);
};
