/**
 * hae-lib-components
 *
 * Hexio App Engine library to help creating components.
 *
 * @package hae-lib-components
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createContext } from "react";

/**
 * Translation Context Value
 */
export interface ITranslationContextValue {
	translate: (
		domain: string,
		term: string,
		vars?: { [K: string]: string | number | boolean },
		fallbackValue?: string
	) => string;
}

/**
 * HAE Translation context
 */
export const TranslationContext = createContext<ITranslationContextValue>({
	translate: null
});
