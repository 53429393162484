/**
 * Position schema
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaOneOf,
	TGetBlueprintSchemaSpec,
	TSchemaConstObjectPropsSpec
} from "@hexio_io/hae-lib-blueprint";
import { POSITION_resolution_default } from "../Enums/POSITION";
import { SPACING, SPACING_none } from "../Enums/SPACING";
import { getValuesFromStringEnum } from "../Functions/enumHelpers";
import { lengthWithConstantOrAutoOrInheritPattern } from "../RegExp/dimensionPattern";
import { spacingPattern } from "../RegExp/spacingPattern";

import { termsEditor } from "../terms";
import { ZIndexSchema } from "./ZIndex";

/**
 * Defaults
 */
export const POSITION_DEFAULT_INSET = "auto";

/**
 * Position props
 */
export const PositionProps = {
	margin: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.common.margin,
			example: "0px 0px 0px 300px",
			constants: getValuesFromStringEnum(SPACING, termsEditor.schemas.common.spacingValues),
			default: SPACING_none,
			constraints: {
				...termsEditor.schemas.common.spacingConstraints,
				pattern: spacingPattern
			}
		}),
		0
	),

	zIndex: BP.Prop(ZIndexSchema(), 100)
};

export const positionInsetKeys = [ "top", "right", "bottom", "left" ];

positionInsetKeys.forEach((item, index) => {
	PositionProps[item] = BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.common.positionInset[item],
			constants: getValuesFromStringEnum(SPACING, termsEditor.schemas.common.spacingValues),
			default: POSITION_DEFAULT_INSET,
			constraints: {
				...termsEditor.schemas.common.spacingConstraints,
				pattern: lengthWithConstantOrAutoOrInheritPattern
			}
		}),
		(index + 1) * 10
	);
});

/**
 * Position props type
 */
export type TPositionProps = Partial<TSchemaConstObjectPropsSpec<typeof PositionProps>>;

/**
 * Position types
 */
export const PositionTypes = {
	INHERIT: {
		...termsEditor.schemas.common.inherit,
		value: BP.Void({})
	},
	RELATIVE: {
		...termsEditor.schemas.common.positionValues.relative,
		value: BP.Object({
			props: PositionProps,
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	},
	ABSOLUTE: {
		...termsEditor.schemas.common.positionValues.absolute,
		value: BP.Object({
			props: PositionProps,
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	},
	FIXED: {
		...termsEditor.schemas.common.positionValues.fixed,
		value: BP.Object({
			props: PositionProps,
			editorOptions: {
				layoutType: "passthrough"
			}
		})
	}
};

export type TPositionSpec = TGetBlueprintSchemaSpec<ISchemaOneOf<typeof PositionTypes>>;

/**
 * Position schema
 */
export function PositionSchema() {
	return BP.ResponsiveValue({
		...termsEditor.schemas.common.position,
		value: BP.OneOf({
			defaultType: "RELATIVE",
			types: PositionTypes
		}),
		default: POSITION_resolution_default
	});
}
