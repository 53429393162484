/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TThemeStyleNames } from "@hexio_io/hae-lib-blueprint";
import { IResourceDependency, IResourceMetadata } from "../registries";

/**
 * Resource Types
 */
export enum RESOURCE_TYPES {
	DIRECTORY = "directory",
	VIEW = "view",
	ACTION = "action",
	CONFIG = "config",
	INTEGRATION = "integration",
	ROUTES = "routes",
	ENDPOINT = "endpoint",
	TRANSLATIONS = "translations",
	THEME = "theme",
	ASSET = "asset",
	MANIFEST = "manifest",
	UNKNOWN = "unknown"
}

/**
 * Resource Description
 */
export interface IResourceDescription {
	id: string;
	name: string;
	resourceType: RESOURCE_TYPES;
	basePath: string;
	parseData: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		paramsSchema: any;
		valid: boolean;
		/** Integration type */
		integrationType?: string;
		/** Styles */
		styles?: TThemeStyleNames;
	};
	/** List of resources this one depends on */
	dependencies: string[];
	/** Last modified timestamp */
	lastModified: number;
	attributes?: {
		hidden?: boolean;
		allowWrite?: boolean;
		allowRemove?: boolean;
		allowRename?: boolean;
	};
	metadata?: IResourceMetadata;
}
