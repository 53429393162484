/**
 * Custom Chart schema terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const timePicker = {
	allowFreeform: {
		label: "schema:timePicker.allowFreeform#label",
		description: "schema:timePicker.allowFreeform#description"
	},
	increments: {
		label: "schema:timePicker.increments#label",
		description: "schema:timePicker.increments#description"
	},
	label: {
		label: "schema:timePicker.label#label",
		description: "schema:timePicker.label#description"
	},
	showSeconds: {
		label: "schema:timePicker.showSeconds#label",
		description: "schema:timePicker.showSeconds#description"
	},
	useHour12: {
		label: "schema:timePicker.useHour12#label",
		description: "schema:timePicker.useHour12#description"
	}
};
