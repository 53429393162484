/**
 * OptionsFieldRadioGroup HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { ClassList, ILabelProps, Label, Text } from "@hexio_io/hae-lib-components";
import { IOptionsFieldBaseProps } from "./optionsFieldTypes";

export interface IOptionsFieldRadioGroupProps extends IOptionsFieldBaseProps {
	name: string;
}

export const OptionsFieldRadioGroup: React.FC<IOptionsFieldRadioGroupProps> = ({
	id,
	items,
	//selectedItem,
	value,
	allowCustomValue,
	//allowCustomValueTypeValue,
	customValue,
	componentPath,
	componentMode,
	//required,
	readOnly,
	disabled,
	name,
	onSelect
}) => {
	const _customInputClickHandler = React.useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const { currentTarget } = event;

			onSelect(currentTarget.value);
		},
		[ onSelect ]
	);

	const _inputOnChangeHandler = React.useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const { currentTarget } = event;

			onSelect(currentTarget.value);
		},
		[ onSelect ]
	);

	return (
		<fieldset disabled={disabled || readOnly} className="cmp-field__options-list" role="radiogroup">
			{items.map((item, index) => (
				<label key={item.value || index} className="cmp-field__options-item">
					<div className="cmp-field__options-item-control">
						<input
							id={index === 0 ? id : `${id}-${index}`}
							type="radio"
							name={name}
							value={item.value}
							checked={item.value === value}
							className="cmp-field__options-item-control-input"
							onChange={_inputOnChangeHandler}
						/>

						<span className="cmp-field__options-item-control-icon" />
					</div>

					<div className="cmp-field__options-item-content">
						<Label
							text={
								item.labelText?.value
									? item.labelText
									: { ...item.labelText, value: item.value }
							}
							icon={item.labelIcon?.source ? { ...item.labelIcon, size: "SMALL" } : undefined}
							componentPath={[ ...componentPath, `${index}`, "label" ]}
							componentMode={componentMode}
						/>

						{item.descriptionText?.value && (
							<Text
								{...item.descriptionText}
								classList={new ClassList("cmp-field__description")}
								componentPath={[ ...componentPath, `${index}`, "label", "description" ]}
								componentMode={componentMode}
							/>
						)}
					</div>
				</label>
			))}

			{allowCustomValue ? (
				<label className="cmp-field__options-item cmp-field__options-item--custom">
					<div className="cmp-field__options-item-control">
						<input
							type="radio"
							name={name}
							value={customValue || ""}
							checked={value === customValue}
							className={"cmp-field__options-item-control-input"}
							onChange={_inputOnChangeHandler}
						/>

						<span className="cmp-field__options-item-control-icon" />
					</div>

					<div className="cmp-field__options-item-content">
						<input
							type="text"
							className="cmp-field__options-custom-value"
							placeholder={allowCustomValue.placeholder}
							onClick={_customInputClickHandler}
							onChange={_inputOnChangeHandler}
						/>
					</div>
				</label>
			) : null}
		</fieldset>
	);
};
