/**
 * hae-lib-integrations
 *
 * Hexio App Engine Integration library.
 *
 * @package hae-lib-integrations
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TDecoratorIntegrationDefinition } from "./IIntegrationDefinition";

/**
 * Integration decorator
 *
 * @param integrationDefinition Integration definition
 * @returns
 */
export function StoreDefinition(integrationDefinition: TDecoratorIntegrationDefinition) {
	// eslint-disable-next-line @typescript-eslint/ban-types
	const constructorFunction = function (constructor: Function) {
		constructor.prototype.integrationDefinition = integrationDefinition;
	};

	return constructorFunction;
}
