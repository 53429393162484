/**
 * Custom HTML HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, useEffect, useLayoutEffect } from "react";

import {
	BP,
	cmpDataEqual,
	createSubScope,
	defineElementaryComponent,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	Type
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	ErrorBoundary,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import {
	createEventEmitter,
	emitEvent,
	offEvent,
	onEvent,
	TSimpleEventEmitter
} from "@hexio_io/hae-lib-shared";

interface HAEComponentCustomComponent_State {
	scopeData: Record<string, unknown>;
	onUpdate: { currentHandler?: (...args: unknown[]) => unknown };
	handleUpdate: (...args: unknown[]) => unknown;
}

type TGenericFunction = (...args) => unknown;

const SAFE_MODE_LC_KEY = "adapptio-custom-component-fn-exec";

const codeCache = new Map<string, TGenericFunction>();
const cssCache = new Map<string, { element: HTMLElement; uses: number }>();
const externalScriptCache = new Map<
	string,
	{ element: HTMLElement; uses: number; isLoaded: boolean; isModule: boolean }
>();
const onExternalScriptLoaded = createEventEmitter<string>();

function getCompiledCode(code: string) {
	if (codeCache.has(code)) {
		return codeCache.get(code);
	} else {
		const fn = new Function(
			"domEl",
			"props",
			"state",
			"scopeData",
			"setScopeData",
			"triggerEvent",
			"componentMode",
			"args",
			code
		);
		codeCache.set(code, fn as TGenericFunction);
		return fn;
	}
}

function addCssBlock(code: string) {
	if (cssCache.has(code)) {
		cssCache.get(code).uses++;
	} else {
		const styleTag = document.createElement("style");
		styleTag.innerHTML = code;
		document.head.appendChild(styleTag);

		cssCache.set(code, {
			uses: 1,
			element: styleTag
		});
	}
}

function removeCssBlock(code: string) {
	const cache = cssCache.get(code);

	if (cache) {
		cache.uses--;

		if (cache.uses <= 0) {
			document.head.removeChild(cache.element);
			cssCache.delete(code);
		}
	}
}

function addExternalScript(src: string, isModule: boolean) {
	if (externalScriptCache.has(src)) {
		externalScriptCache.get(src).uses++;
	} else {
		const scriptTag = document.createElement("script");

		scriptTag.type = isModule ? "module" : "text/javascript";

		scriptTag.src = src;

		const cacheId = (isModule ? "module" : "script") + ":" + src;

		const scriptInfo = {
			uses: 1,
			element: scriptTag,
			isLoaded: false,
			isModule: isModule
		};

		externalScriptCache.set(cacheId, scriptInfo);
		document.head.appendChild(scriptTag);

		scriptTag.onload = () => {
			scriptInfo.isLoaded = true;
			emitEvent(onExternalScriptLoaded, cacheId);
		};
	}
}

function removeExternalScript(src: string, isModule: boolean) {
	const cacheId = (isModule ? "module" : "script") + ":" + src;
	const cache = externalScriptCache.get(cacheId);

	if (cache) {
		cache.uses--;

		if (cache.uses <= 0) {
			document.head.removeChild(cache.element);
			externalScriptCache.delete(cacheId);
		}
	}
}

function checkIfExternalScriptsAreLoaded(scripts: string[], areModules: boolean) {
	for (let i = 0; i < scripts.length; i++) {
		const cacheId = (areModules ? "module" : "script") + ":" + scripts[i];

		if (!externalScriptCache.get(cacheId)?.isLoaded) {
			return false;
		}
	}

	return true;
}

function addOrRemoveScripts(list: string[], ref: React.MutableRefObject<string[]>, areModules: boolean) {
	if (!cmpDataEqual(list, ref.current)) {
		if (ref.current) {
			for (let i = 0; i < ref.current.length; i++) {
				if (!list.includes(ref.current[i])) {
					console.debug("Remove custom component external script:", ref.current[i]);
					removeExternalScript(ref.current[i], areModules);
				}
			}
		}

		for (let i = 0; i < list.length; i++) {
			if (!ref.current || !ref.current.includes(list[i])) {
				console.debug("Add custom component external script:", list[i]);
				addExternalScript(list[i], areModules);
			}
		}

		ref.current = list;
	}
}

function isLocalStorageAvailable() {
	try {
		if (typeof window !== "undefined" && window.localStorage) {
			window.localStorage.setItem("${SAFE_MODE_LC_KEY}_test", "test");

			if (window.localStorage.getItem("${SAFE_MODE_LC_KEY}_test") === "test") {
				return true;
			}
		}
	} catch (err) {
		return false;
	}

	return false;
}

function doesSafeModeAllowExec() {
	if (isLocalStorageAvailable()) {
		return window.localStorage.getItem(SAFE_MODE_LC_KEY) !== "true";
	} else {
		return true;
	}
}

function safeModeStartExec() {
	if (isLocalStorageAvailable()) {
		window.localStorage.setItem(SAFE_MODE_LC_KEY, "true");
	}
}

function safeModeFinishExec() {
	if (isLocalStorageAvailable()) {
		window.localStorage.removeItem(SAFE_MODE_LC_KEY);
	}
}

function safeModeInit() {
	if (isLocalStorageAvailable()) {
		if (window.localStorage.getItem(SAFE_MODE_LC_KEY)) {
			// eslint-disable-next-line max-len
			if (
				confirm(
					"Custom function did not finish last time, probably due to a cycle. For that reason, execution of custom functions was disabled. Do you want to re-enabled it?"
				)
			) {
				window.localStorage.removeItem(SAFE_MODE_LC_KEY);
			}
		}
	}
}

safeModeInit();

/**
 * Custom Component Props
 */
const HAEComponentCustomComponent_Props = {
	props: BP.Prop(
		BP.Map({
			label: termsEditor.components.customComponent.props.label,
			description: termsEditor.components.customComponent.props.description,
			value: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		})
	),
	htmlTemplate: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.htmlTemplate.label,
			description: termsEditor.components.customComponent.htmlTemplate.description,
			editorOptions: {
				controlType: "codeHTML"
			}
		})
	),
	className: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.className.label,
			description: termsEditor.components.customComponent.className.description
		})
	),
	styleSheet: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.styleSheet.label,
			description: termsEditor.components.customComponent.styleSheet.description,
			editorOptions: {
				controlType: "codeCSS"
			}
		})
	),
	onMountCode: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.onMountCode.label,
			description: termsEditor.components.customComponent.onMountCode.description,
			editorOptions: {
				controlType: "codeJavaScript"
			}
		})
	),
	onPropsChangedCode: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.onPropsChangedCode.label,
			description: termsEditor.components.customComponent.onPropsChangedCode.description,
			editorOptions: {
				controlType: "codeJavaScript"
			}
		})
	),
	onUpdateMethodCode: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.onUpdateMethodCode.label,
			// eslint-disable-next-line max-len
			description: termsEditor.components.customComponent.onUpdateMethodCode.description,
			editorOptions: {
				controlType: "codeJavaScript"
			}
		})
	),
	onDisposeCode: BP.Prop(
		BP.String({
			label: termsEditor.components.customComponent.onDisposeCode.label,
			description: termsEditor.components.customComponent.onDisposeCode.description,
			editorOptions: {
				controlType: "codeJavaScript"
			}
		})
	),
	externalScripts: BP.Prop(
		BP.Array({
			label: termsEditor.components.customComponent.externalScripts.label,
			description: termsEditor.components.customComponent.externalScripts.description,
			items: BP.String({
				label: "Script URL"
			})
		})
	),
	externalModules: BP.Prop(
		BP.Array({
			label: termsEditor.components.customComponent.externalModules.label,
			description: termsEditor.components.customComponent.externalModules.description,
			items: BP.String({
				label: "Module URL"
			})
		})
	)
};

const HAEComponentCustomComponent_Events = {
	customEvent: {
		label: termsEditor.components.customComponent.customEvent.label,
		description: termsEditor.components.customComponent.customEvent.description,
		icon: "mdi/gesture-tap"
	}
};

const HAEComponentCustomComponent_Definition = defineElementaryComponent<
	typeof HAEComponentCustomComponent_Props,
	HAEComponentCustomComponent_State,
	typeof HAEComponentCustomComponent_Events
>({
	...termsEditor.components.customComponent.component,

	name: "customComponent",

	category: "logic",

	icon: "mdi/code-tags",

	order: 1000,

	props: HAEComponentCustomComponent_Props,

	events: HAEComponentCustomComponent_Events,

	resolve: (_spec, state) => {
		if (state) {
			return state;
		}

		const onUpdateHandler = {
			currentHandler: undefined
		};

		return {
			scopeData: {},
			onUpdate: onUpdateHandler,
			handleUpdate: (...args: unknown[]) => {
				if (onUpdateHandler.currentHandler) {
					return onUpdateHandler.currentHandler(...args);
				}
			}
		};
	},

	getScopeData: (spec, state) => {
		return {
			...(state.scopeData ?? {}),
			update: state.handleUpdate
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				update: Type.Method({
					label: "Update component",
					argRequiredCount: 1,
					argSchemas: [
						BP.Any({
							defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
						})
					],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentCustomComponent_ReactCore: THAEComponentReact<
	typeof HAEComponentCustomComponent_Definition
> = ({ props, state, componentInstance, reactComponentClassList }) => {
	const internalState = React.useRef({});
	const elementRef = React.useRef<HTMLDivElement>();
	const lastProps = React.useRef({
		props: props.props,
		code: props.onPropsChangedCode
	});
	const lastExternalScripts = React.useRef([]);
	const lastExternalModules = React.useRef([]);

	// Update external scripts
	addOrRemoveScripts(props.externalScripts, lastExternalScripts, false);
	addOrRemoveScripts(props.externalModules, lastExternalModules, true);

	const [ areExternalScriptsLoaded, setAreExternalScriptsLoaded ] = React.useState(
		checkIfExternalScriptsAreLoaded(props.externalScripts, false) &&
			checkIfExternalScriptsAreLoaded(props.externalModules, true)
	);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-custom-cmp",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	// Prepare handlers
	const setScopeData = useCallback(
		(
			scopeData:
				| Record<string, unknown>
				| ((prevScopeData: Record<string, unknown>) => Record<string, unknown>)
		) => {
			componentInstance.setState((prevState) => {
				return {
					...prevState,
					scopeData: typeof scopeData === "function" ? scopeData(prevState.scopeData) : scopeData
				};
			});
		},
		[ componentInstance ]
	);

	const triggerEvent = useCallback(
		(eventData: unknown) => {
			if (componentInstance.eventEnabled.customEvent) {
				componentInstance.eventTriggers.customEvent((parentScope) =>
					createSubScope(parentScope, {
						eventData: eventData
					})
				);
			}
		},
		[ componentInstance ]
	);

	const executeJsCode = useCallback(
		(debugName: string, code: string, cmpProps: unknown, cmpScopeData: unknown, args?: unknown[]) => {
			if (!doesSafeModeAllowExec()) {
				// eslint-disable-next-line max-len
				console.warn(
					"[Safe Mode Enabled] Custom function has not finished in the previous run. Custom function execution has been disabled. Reload the page to enabled it again."
				);
				return;
			}

			safeModeStartExec();

			let ret = null;

			try {
				const fn = getCompiledCode(code);
				ret = fn(
					elementRef.current,
					cmpProps,
					internalState.current,
					cmpScopeData,
					setScopeData,
					triggerEvent,
					componentInstance.componentMode,
					args
				);
			} catch (err) {
				console.warn(`Failed to execute custom component '${debugName}' function:`, err);
			}
			safeModeFinishExec();

			return ret;
		},
		[ elementRef, internalState, setScopeData, triggerEvent, componentInstance.componentMode ]
	);

	// Update CSS
	useEffect(() => {
		addCssBlock(props.styleSheet);

		return () => {
			removeCssBlock(props.styleSheet);
		};
	}, [ props.styleSheet ]);

	// Keep track of scripts loaded state
	useEffect(() => {
		const handleLoad = () => {
			const loaded =
				checkIfExternalScriptsAreLoaded(props.externalScripts, false) &&
				checkIfExternalScriptsAreLoaded(props.externalModules, true);

			if (loaded !== areExternalScriptsLoaded) {
				setAreExternalScriptsLoaded(loaded);
			}
		};

		onEvent(onExternalScriptLoaded, handleLoad);
		handleLoad();

		return () => {
			offEvent(onExternalScriptLoaded, handleLoad);
		};
	}, [ areExternalScriptsLoaded, lastExternalScripts.current, lastExternalModules.current ]);

	// Handle custom update method
	useEffect(() => {
		const handleMethod = (...args: unknown[]) => {
			return executeJsCode(
				"onUpdateMethod",
				props.onUpdateMethodCode,
				props.props,
				state.scopeData,
				args
			);
		};

		state.onUpdate.currentHandler = handleMethod;

		return () => {
			state.onUpdate.currentHandler = undefined;
		};
	}, [ executeJsCode, props.props, state.scopeData, props.onUpdateMethodCode, state.onUpdate ]);

	// Handle onMount and onDispose
	useLayoutEffect(() => {
		if (!areExternalScriptsLoaded) {
			console.debug(
				"Custom component's external scripts are not loaded yet. Skipping onMount for now."
			);
			return;
		}

		if (props.onMountCode) {
			executeJsCode("onMount", props.onMountCode, props.props, state.scopeData);
		}

		return () => {
			if (props.onDisposeCode) {
				executeJsCode("onDispose", props.onDisposeCode, props.props, state.scopeData);
			}
		};
	}, [
		props.htmlTemplate,
		props.onMountCode,
		props.onDisposeCode,
		setScopeData,
		componentInstance,
		executeJsCode,
		areExternalScriptsLoaded
	]);

	// Update props
	if (
		elementRef.current &&
		(!cmpDataEqual(props.props, lastProps.current.props) ||
			lastProps.current.code !== props.onPropsChangedCode)
	) {
		lastProps.current.props = props.props;
		lastProps.current.code = props.onPropsChangedCode;

		if (props.onPropsChangedCode && areExternalScriptsLoaded) {
			executeJsCode("onPropsChanged", props.onPropsChangedCode, props.props, state.scopeData);
		}
	}

	// Generate template markup
	const markup = React.useMemo(() => {
		return (
			<div
				className={props.className}
				ref={elementRef}
				dangerouslySetInnerHTML={{ __html: props.htmlTemplate ?? "" }}
			/>
		);
	}, [
		props.htmlTemplate,
		props.onMountCode,
		props.onDisposeCode,
		setScopeData,
		componentInstance,
		executeJsCode
	]);

	return <div className={classList.toClassName()}>{markup}</div>;
};

const HAEComponentCustomComponent_React: THAEComponentReact<typeof HAEComponentCustomComponent_Definition> = (
	props
) => {
	return (
		<ErrorBoundary
			beforeCapture={(scope) => {
				scope.setTag("custom-component", true);
			}}
		>
			<HAEComponentCustomComponent_ReactCore {...props} />
		</ErrorBoundary>
	);
};

export const HAEComponentCustomComponent: THAEComponentDefinition<
	typeof HAEComponentCustomComponent_Definition
> = {
	...HAEComponentCustomComponent_Definition,
	reactComponent: HAEComponentCustomComponent_React
};
