/**
 * Composed Chart Axis Datetime Unit enum
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum COMPOSED_CHART_AXIS_DATETIME_UNIT {
	MILLISECOND = "millisecond",
	SECOND = "second",
	MINUTE = "minute",
	HOUR = "hour",
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
	YEAR = "year"
}

export const COMPOSED_CHART_AXIS_DATETIME_UNIT_default: keyof typeof COMPOSED_CHART_AXIS_DATETIME_UNIT =
	"HOUR";
