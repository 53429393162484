/**
 * Icon schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const icon = {
	schema: {
		label: "schema:icon#label"
	},
	source: {
		label: "schema:icon.source#label",
		description: "schema:icon.source#description"
	},
	size: {
		label: "schema:icon.size#label",
		description: "schema:icon.size#description"
	},
	sizeValues: {
		small: {
			label: "schema:icon.size#values.small#label"
		},
		medium: {
			label: "schema:icon.size#values.medium#label"
		},
		large: {
			label: "schema:icon.size#values.large#label"
		},
		xlarge: {
			label: "schema:icon.size#values.xlarge#label"
		},
		xxlarge: {
			label: "schema:icon.size#values.xxlarge#label"
		}
	}
};
