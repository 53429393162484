/**
 * Custom HTML schema terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const datePicker = {
	allFocusable: {
		label: "schema:datePicker.allFocusable#label"
	},
	allowTextInput: {
		label: "schema:datePicker.allowTextInput#label"
	},
	ariaLabel: {
		label: "schema:datePicker.ariaLabel#label"
	},
	borderless: {
		label: "schema:datePicker.borderless#label"
	},
	disableAutoFocus: {
		label: "schema:datePicker.disableAutoFocus#label"
	},
	disabled: {
		label: "schema:datePicker.disabled#label"
	},
	highlightCurrentMonth: {
		label: "schema:datePicker.highlightCurrentMonth#label"
	},
	highlightSelectedMonth: {
		label: "schema:datePicker.highlightSelectedMonth#label"
	},
	initialPickerDate: {
		label: "schema:datePicker.initialPickerDate#label"
	},
	isMonthPickerVisible: {
		label: "schema:datePicker.isMonthPickerVisible#label"
	},
	isRequired: {
		label: "schema:datePicker.isRequired#label"
	},
	minDate: {
		label: "schema:datePicker.minDate#label"
	},
	maxDate: {
		label: "schema:datePicker.maxDate#label"
	},
	formatter: {
		label: "schema:datePicker.formatter#label",
		description: "schema:datePicker.formatter#description"
	},
	parser: {
		label: "schema:datePicker.parser#label",
		description: "schema:datePicker.parser#label"
	},
	// onAfterMenuDismiss: {
	// 	label: "schema:datePicker.onAfterMenuDismiss#label"
	// },
	openOnClick: {
		label: "schema:datePicker.openOnClick#label"
	},
	pickerAriaLabel: {
		label: "schema:datePicker.pickerAriaLabel#label"
	},
	placeholder: {
		label: "schema:datePicker.placeholder#label"
	},
	showCloseButton: {
		label: "schema:datePicker.showCloseButton#label"
	},
	showMonthPickerAsOverlay: {
		label: "schema:datePicker.showMonthPickerAsOverlay#label"
	},
	showWeekNumbers: {
		label: "schema:datePicker.showWeekNumbers#label"
	},
	underlined: {
		label: "schema:datePicker.underlined#label"
	},
	value: {
		label: "schema:datePicker.value#label",
		description: "schema:datePicker.value#description"
	},
	events: {
		change: {
			label: "schema:field.events.change#label"
		}
	},
	initialValue: {
		label: "schema:checkboxField.initialValue#label",
		description: "schema:checkboxField.initialValue#description"
	},
	customValidation: {
		label: "schema:field.customValidation#label"
	},
	customValidationCondition: {
		label: "schema:field.customValidation#props.condition#label"
	},
	customValidationMessage: {
		label: "schema:field.customValidation#props.message#label"
	},
	readOnly: {
		label: "schema:field.readOnly#label"
	},
	validate: {
		label: "schema:field.validate#label"
	},
	valid: {
		label: "schema:field.valid#label"
	},
	setValue: {
		label: "schema:field.setValue#label"
	},
	clearValue: {
		label: "schema:field.clearValue#label"
	},
	strings: {
		label: "schema:datePicker.strings#label",
		description: "schema:datePicker.strings#description"
	},
	stringsMonths: {
		label: "schema:datePicker.stringsMonths#label"
	},
	stringsShortMonths: {
		label: "schema:datePicker.stringsShortMonths#label"
	},
	stringsDays: {
		label: "schema:datePicker.stringsDays#label"
	},
	stringsShortDays: {
		label: "schema:datePicker.stringsShortDays#label"
	},
	stringsGoToToday: {
		label: "schema:datePicker.stringsGoToToday#label"
	},
	stringsPrevMonthAriaLabel: {
		label: "schema:datePicker.stringsPrevMonthAriaLabel#label"
	},
	stringsNextMonthAriaLabel: {
		label: "schema:datePicker.stringsNextMonthAriaLabel#label"
	},
	stringsPrevYearAriaLabel: {
		label: "schema:datePicker.stringsPrevYearAriaLabel#label"
	},
	stringsNextYearAriaLabel: {
		label: "schema:datePicker.stringsNextYearAriaLabel#label"
	}
};
