/**
 * Tree Navigation HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Type, defineElementaryComponent } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	getStringEnumValue,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";
import { BaseEvents } from "../../base/BaseEvents";
import { termsEditor } from "../../terms";
import { HAEComponentTreeNavigation_Props } from "./props";
import { TreeNavigationList } from "./Content";
import { TREE_NAVIGATION_STYLE, TREE_NAVIGATION_STYLE_default } from "../../Enums/TREE_NAVIGATION_STYLE";

interface HAEComponentTreeNavigation_State {}

const HAEComponentTreeNavigation_Events = {
	...BaseEvents
};

const HAEComponentTreeNavigation_Definition = defineElementaryComponent<
	typeof HAEComponentTreeNavigation_Props,
	HAEComponentTreeNavigation_State,
	typeof HAEComponentTreeNavigation_Events
>({
	...termsEditor.components.treeNavigation.component,

	name: "treeNavigation",

	category: "navigation",

	icon: "mdi/file-tree",

	docUrl: "...",

	order: 10,

	props: HAEComponentTreeNavigation_Props,

	events: HAEComponentTreeNavigation_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentTreeNavigation_React: THAEComponentReact<typeof HAEComponentTreeNavigation_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { items, expandable } = props;

	const componentPath = componentInstance.safePath;

	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-tree-navigation", componentPath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	const styleValue = getStringEnumValue(TREE_NAVIGATION_STYLE, props.style, TREE_NAVIGATION_STYLE_default);

	classList.addModifiers({
		style: styleValue,
		expandable: !!expandable
	});

	return (
		<div className={classList.toClassName()}>
			<TreeNavigationList
				items={items}
				expandable={expandable}
				styleValue={styleValue}
				componentPath={componentPath}
				componentMode={componentInstance.componentMode}
			/>
		</div>
	);
};

export const HAEComponentTreeNavigation: THAEComponentDefinition<
	typeof HAEComponentTreeNavigation_Definition
> = {
	...HAEComponentTreeNavigation_Definition,
	reactComponent: HAEComponentTreeNavigation_React
};
