/**
 * PropertyItemValue HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import { PROPERTY_ITEM_VALUE_MODE } from "../../Enums/PROPERTY_ITEM_VALUE_MODE";
import {
	Button,
	ClassList,
	HAEComponentMainContext,
	ICON_NAME,
	ITextProps,
	Text,
	TOAST_MESSAGE_TYPE,
	useTranslate
} from "@hexio_io/hae-lib-components";
import { COMPONENT_MODE, TComponentNodePath } from "@hexio_io/hae-lib-blueprint";
import { termsRuntime } from "../../terms";

export interface IPropertyItemValueClassLists {
	root?: ClassList;
	text?: ClassList;
	button?: ClassList;
	copyButton?: ClassList;
	togglePasswordButton?: ClassList;
}

export interface IPropertyItemValueProps<ID = number | string> {
	componentPath: TComponentNodePath;
	componentMode: COMPONENT_MODE;
	id: ID;
	classLists?: IPropertyItemValueClassLists;
	text: Partial<ITextProps>;
	mode?: PROPERTY_ITEM_VALUE_MODE;
	copy?: boolean;
	onPasswordVisibilityToggle?: (id: ID) => void;
	passwordVisible?: boolean;
}

export const PropertyItemValue: React.FC<IPropertyItemValueProps> = React.memo(
	({
		id,
		text,
		componentPath,
		componentMode,
		classLists,
		mode,
		copy,
		onPasswordVisibilityToggle,
		passwordVisible
	}) => {
		const componentMainContext = React.useContext(HAEComponentMainContext);

		const passwordMode = React.useMemo(() => mode === PROPERTY_ITEM_VALUE_MODE.PASSWORD, [ mode ]);

		const t = useTranslate();

		const textValue = text?.value || "";

		const displayedValue = React.useMemo(
			() => (passwordMode && !passwordVisible ? Array(textValue.length).fill("*").join("") : textValue),
			[ mode, textValue, passwordVisible ]
		);

		const _copyButtonClickHandler = React.useCallback(() => {
			if (copy) {
				try {
					navigator.clipboard.writeText(textValue);

					componentMainContext.toastMessageManager.addItemData({
						type: TOAST_MESSAGE_TYPE.SUCCESS,
						message: t("runtime", termsRuntime.components.propertyItem.copy.message)
						//details: `"${value.length > 100 ? value.substr(0, 100) + "…" : value}"`
					});
				} catch (error) {
					// eslint-disable-next-line no-empty
				}
			}
		}, [ textValue, copy ]);

		const _togglePasswordButtonClickHandler = React.useCallback(() => {
			onPasswordVisibilityToggle(id);
		}, [ id ]);

		return (
			<div className={classLists.root?.toClassName()}>
				{copy && (
					<Button
						style="CLEAR"
						labelIcon={{ source: ICON_NAME.COPY, size: "SMALL" }}
						componentPath={[ ...componentPath, "copy" ]}
						componentMode={componentMode}
						classList={classLists.copyButton}
						aria-label={t("runtime", termsRuntime.components.propertyItem.copy.label)}
						onClick={_copyButtonClickHandler}
					/>
				)}
				{passwordMode && (
					<Button
						style="CLEAR"
						labelIcon={{
							source: passwordVisible ? ICON_NAME.EYE_OFF : ICON_NAME.EYE,
							size: "SMALL"
						}}
						componentPath={[ ...componentPath, "toggle-password" ]}
						componentMode={componentMode}
						classList={classLists.togglePasswordButton}
						aria-label={t(
							"runtime",
							passwordVisible
								? termsRuntime.components.propertyItem.hideValue.label
								: termsRuntime.components.propertyItem.showValue.label
						)}
						onClick={_togglePasswordButtonClickHandler}
					/>
				)}
				<Text
					componentPath={[ ...componentPath, "text" ]}
					componentMode={componentMode}
					classList={classLists.text}
					{...text}
					value={displayedValue}
				/>
			</div>
		);
	}
);

PropertyItemValue.defaultProps = {
	classLists: {}
};
