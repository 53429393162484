/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IDataSourceDefRegistry } from "../registries";
import { DataSourceVariable } from "./DataSourceVariable";
import { DataSourceAction } from "./DataSourceAction";
import { DataSourceConstant } from "./DataSourceConstant";
import { DataSourceMethod } from "./DataSourceMethod";
import { DataSourceContextProvider } from "./DataSourceContextProvider";
import { DataSourceContextConsumer } from "./DataSourceContextConsumer";

export * from "./DataSourceVariable";
export * from "./DataSourceConstant";
export * from "./DataSourceAction";
export * from "./DataSourceMethod";
export * from "./DataSourceContextProvider";
export * from "./DataSourceContextConsumer";

/**
 * Registers core data sources
 *
 * @param registry Data source definition registry
 */
export function registerCoreDataSources(registry: IDataSourceDefRegistry): void {
	registry.register(DataSourceVariable);
	registry.register(DataSourceConstant);
	registry.register(DataSourceAction);
	registry.register(DataSourceMethod);
	registry.register(DataSourceContextProvider);
	registry.register(DataSourceContextConsumer);
}
