/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	Type,
	defineElementaryDataSource,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	cmpDataEqual,
	TTypeDesc,
	RuntimeContext,
	IDataContextChangeEventData,
	createSubScope
} from "@hexio_io/hae-lib-blueprint";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";

interface DataSourceContextConsumer_State {
	value: unknown;
	type?: TTypeDesc;
	isBound: boolean;
	changeListener: (eventData: IDataContextChangeEventData) => void;
}

const DataSourceContextConsumer_Opts = {
	name: BP.Prop(
		BP.String({
			label: "Name",
			description: "Context Name"
		})
	),
	defaultValue: BP.Prop(
		BP.Any({
			label: "Default Value",
			description: "Default value to use if the context is not provided.",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.MAP
		})
	)
};

const DataSourceContextConsumer_Events = {
	change: {
		label: "Value Changed"
	}
};

function resolveContextValue(rCtx: RuntimeContext, contextName: string, defaultValue: unknown) {
	let currentRuntime = rCtx;

	while (currentRuntime) {
		const context = currentRuntime.getDataContext(contextName);

		if (context !== undefined) {
			return {
				value: context.data,
				type: context.type,
				isBound: true
			};
		}

		currentRuntime = currentRuntime.getParentContext();
	}

	return {
		value: defaultValue,
		type: Type.Any({}),
		isBound: false
	};
}

/**
 * ContextConsumer data source
 */
export const DataSourceContextConsumer = defineElementaryDataSource<
	typeof DataSourceContextConsumer_Opts,
	DataSourceContextConsumer_State,
	typeof DataSourceContextConsumer_Events
>({
	name: "contextConsumer",
	label: "Context Consumer",
	// eslint-disable-next-line max-len
	description: "Provides a value from a parent context provider.",
	icon: "mdi/database-import-outline",
	opts: DataSourceContextConsumer_Opts,
	events: DataSourceContextConsumer_Events,
	resolve: (opts, prevState, updateStateAsync, dsInstance, rCtx) => {
		if (dsInstance.eventEnabled.change) {
			rCtx.__callAfterReconcile(() => {
				if (!cmpDataEqual(dsInstance.state.value, dsInstance.customData.lastValue)) {
					const isFirstRun = dsInstance.customData.lastValue === undefined;
					dsInstance.customData.lastValue = dsInstance.state.value;

					if (!isFirstRun) {
						dsInstance.eventTriggers.change((parentScope) => createSubScope(parentScope, {
							_value: dsInstance.state.value
						}, {
							_value: Type.Any({})
						}));
					}
				}
			});
		}

		if (prevState) {
			return {
				...prevState,
				value: prevState.isBound ? prevState.value : opts.defaultValue
			};
		} else {
			const contextValue = resolveContextValue(rCtx, opts.name, opts.defaultValue);

			const changeListener = () => {
				const contextValue = resolveContextValue(rCtx, opts.name, dsInstance.opts.defaultValue);

				if (
					!cmpDataEqual(dsInstance.state.isBound, contextValue.isBound) ||
					!cmpDataEqual(dsInstance.state.value, contextValue.value) ||
					!cmpDataEqual(dsInstance.state.type, contextValue.type)
				) {
					updateStateAsync({
						...dsInstance.state,
						isBound: contextValue.isBound,
						value: contextValue.value,
						type: contextValue.type,
					});
				}
			};

			onEvent(rCtx.dataContextChangeEvent, changeListener);

			return {
				isBound: contextValue.isBound,
				value: contextValue.value,
				type: contextValue.type,
				changeListener: changeListener
			}
		}
	},
	getScopeData: (_opts, state) => {
		return state.value;
	},
	getScopeType: (_opts, state) => {
		return state.type ?? Type.Any({});
	},
	destroy: (_opts, state, rCtx) => {
		if (state?.changeListener) {
			offEvent(rCtx.dataContextChangeEvent, state.changeListener);
		}
	}
});
