/**
 * Shared utilities
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { isFunction } from "../Functions/isValidValue";

/**
 * History patch object
 * Undo function performs operation undo.
 * Redo function performs operation redo.
 *
 * Dispose function is called when patch is removed from the history - should remove all reference to clean up memory.
 * Function `disposeUndo` is called when patch was removed from past histroy
 *   - eg. when history limit is reached and patch is removed from end of stack.
 * Function `disposeRedo` is called when patch was removed from future history
 *   - eg. when user does undo and then has made a new change.
 */
export interface IHistoryPatch {
	undo: () => void;
	redo: () => void;
	disposeUndo: () => void;
	disposeRedo: () => void;
}

/**
 * Undo/Redo Manager Options
 */
export interface IUndoRedoManagerOpts {
	/** Max number of patches in history */
	historyLimit: number;
}

/**
 * Class to manage undo/redo history
 */
export class UndoRedoManager {
	/** Max number of patches in history */
	private historyLimit: number;

	/** List of patches */
	private patches: IHistoryPatch[] = [];

	/** Current patch index */
	private currentPatchIndex = -1;

	/**
	 * Constructor
	 *
	 * @param opts Configuration options
	 */
	public constructor(opts: IUndoRedoManagerOpts) {
		this.historyLimit = opts.historyLimit;
	}

	/**
	 * Returns patch at current index
	 */
	private getCurrentPatch(): IHistoryPatch {
		return this.patches[this.currentPatchIndex];
	}

	/**
	 * Returns next patch
	 */
	private getNextPatch(): IHistoryPatch {
		return this.patches[this.currentPatchIndex + 1];
	}

	/**
	 * Removes and disposes patches in the future from current index
	 */
	private removeFuturePatches(): void {
		for (let i = this.currentPatchIndex + 1; i < this.patches.length; i++) {
			this.patches[i].disposeRedo();
		}

		this.patches.length = this.currentPatchIndex + 1;
	}

	/**
	 * Adds a new history patch
	 *
	 * @param patch Patch object
	 */
	public add(patch: IHistoryPatch): void {
		this.removeFuturePatches();

		if (this.patches.length >= this.historyLimit) {
			const lastPatch = this.patches.shift();
			lastPatch.disposeUndo();
			this.currentPatchIndex--;
		}

		this.patches.push(patch);
		this.currentPatchIndex = this.patches.length - 1;
	}

	/**
	 * Returns if undo operation is applicable
	 */
	public canUndo(): boolean {
		const currentPatch = this.getCurrentPatch();
		return isFunction(currentPatch?.undo);
	}

	/**
	 * Returns if redo operation is applicable
	 */
	public canRedo(): boolean {
		const nextPatch = this.getNextPatch();
		return isFunction(nextPatch?.redo);
	}

	/**
	 * Performs undo
	 */
	public undo(): void {
		if (!this.canUndo()) {
			return;
		}

		const currentPatch = this.getCurrentPatch();
		this.currentPatchIndex = this.currentPatchIndex - 1;

		currentPatch.undo();
	}

	/**
	 * Performs redo
	 */
	public redo(): void {
		if (!this.canRedo()) {
			return;
		}

		this.currentPatchIndex = this.currentPatchIndex + 1;
		const currentPatch = this.getCurrentPatch();

		currentPatch.redo();
	}

	/**
	 * Clear history
	 */
	public clear(): void {
		this.removeFuturePatches();

		for (let i = 0; i < this.patches.length; i++) {
			this.patches[i].disposeUndo();
		}

		this.patches = [];
		this.currentPatchIndex = -1;
	}
}
