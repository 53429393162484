/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createSubScope, TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	NODE_TYPES,
	TAllNodesSpec,
	TBlueprintActionNodeTypes,
	TBlueprintWhileNodeOptsSchemaSpec
} from "../../blueprints";
import { createSuccessNodeResult, unknownNodeError } from "../helpers";
import { IActionContext, INodeContext, INodeResult, processNodeResult } from "../ActionManager";
import { NODE_RESULT_TYPE } from "../IActionResult";

export async function whileNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintWhileNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { nodeVarName } = nCtx;
	const {
		condition,
		loopType,
		maxIterations,
		aggregateResults
	} = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["while"]["opts"]>;

	try {
		let i = 0;
		let lastResultData: unknown = null;
		const results: INodeResult[] = [];

		const whileNodeResult = createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ITEM,
				data: null,
				typeDescriptor: Type.Void({})
			},
			aCtx,
			nCtx
		);

		const createChildScope = () => createSubScope(nCtx.localScope, {
			$lastResult: lastResultData,
			[`${nodeVarName}_lastResult`]: lastResultData
		}, {
			$lastResult: Type.Any({})
		});

		const evalCondition = () => {
			return condition(createChildScope());
		};

		while (i < maxIterations) {
			i++;

			// While-do mode
			if (loopType === "whileDo") {
				const conditionResult = evalCondition();

				if (!conditionResult) {
					break;
				}
			}

			// Eval nodes
			const childScope = createChildScope();
			const childNodeContext = { ...nCtx, localScope: childScope };
			const itemNodeResult = await processNodeResult(whileNodeResult, aCtx, childNodeContext);

			if (itemNodeResult.nCtx.nodeType === NODE_TYPES.RETURN) {
				return itemNodeResult;
			} else if (itemNodeResult.nCtx.status === NODE_RESULT_TYPE.ERROR) {
				whileNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ERROR;
				whileNodeResult.data = {
					errorData: {
						name: itemNodeResult.data?.errorData?.name,
						code: itemNodeResult.data?.errorData?.code,
						message: itemNodeResult.data?.errorData?.message,
						detail: itemNodeResult.data?.errorData?.detail,
						processedItems: results.map((res) => res.data),
						errorList: [ itemNodeResult.data ]
					},
					errorTracking: {
						actionName: aCtx.name,
						nodeName: nCtx.nodeName,
						nodeType: nCtx.nodeType,
						functionName: nCtx.functionName,
						integrationName: nCtx.integrationName,
						nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0
					}
				};

				if (aCtx.config.debug === true && whileNodeResult.debug) {
					whileNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_ERROR;
					whileNodeResult.debug.output.data = whileNodeResult.data;
					whileNodeResult.debug.executionTimeInMs = Date.now() - nCtx.startTimeInMs;
					aCtx.debug.nodes[nCtx.nodeId] = whileNodeResult.debug;
				}
		
				return await processNodeResult(whileNodeResult, aCtx, nCtx);
			} else {
				if (aggregateResults) {
					results[results.length] = itemNodeResult;
				}

				lastResultData = itemNodeResult.data;
			}

			// Do-while mode
			if (loopType === "doWhile") {
				const conditionResult = evalCondition();

				if (!conditionResult) {
					break;
				}
			}
		}

		whileNodeResult.outputName = NODE_OUTPUT_NAMES.ON_SUCCESS;
		whileNodeResult.data = aggregateResults ? results.map((res) => res.data) : lastResultData;
		whileNodeResult.typeDescriptor = aggregateResults ? Type.Array({ items: [ Type.Any({}) ] }) : Type.Any({});

		if (aCtx.config.debug === true && whileNodeResult.debug?.output) {
			whileNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_SUCCESS;
			whileNodeResult.debug.output.data = whileNodeResult.data;
		}

		return whileNodeResult;
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);	
	}
}
