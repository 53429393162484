/**
 * Button HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";

import {
	termsEditor as HAELibComponentsTerms,
	ButtonProps,
	BUTTON_ROLE,
	getValuesFromStringEnum,
	BUTTON_ROLE_default,
	LinkSchema,
	BackgroundColorSchema,
	ForegroundColorSchema,
	BorderColorSchema,
	propGroups,
	BorderRadiusSchema,
	SPACING,
	spacingPattern,
	SPACING_narrow,
	SPACING_default,
	ENUM_DEFAULT_VALUE
} from "@hexio_io/hae-lib-components";

export const HAEComponentButton_Props = {
	...ButtonProps,

	typeData: BP.Prop(
		BP.OneOf({
			...HAELibComponentsTerms.schemas.button.typeData,
			typeValueOpts: {
				...HAELibComponentsTerms.schemas.button.typeData
			},
			defaultType: "DEFAULT",
			types: {
				DEFAULT: {
					...HAELibComponentsTerms.schemas.button.typeDataValues.default,
					value: BP.Object({
						props: {
							role: BP.Prop(
								BP.Enum.String({
									...HAELibComponentsTerms.schemas.button.role,
									options: getValuesFromStringEnum(
										BUTTON_ROLE,
										HAELibComponentsTerms.schemas.button.roleValues
									),
									default: BUTTON_ROLE_default
								}),
								0
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				LINK: {
					...HAELibComponentsTerms.schemas.button.typeDataValues.link,
					value: LinkSchema({
						alias: "link_button",
						props: {
							enabled: BP.Prop(BP.Void({}))
						}
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		0,
		propGroups.type
	),

	styleData: BP.Prop(
		BP.OneOf({
			...HAELibComponentsTerms.schemas.button.styleData,
			typeValueOpts: {
				...HAELibComponentsTerms.schemas.button.styleData
			},
			defaultType: ENUM_DEFAULT_VALUE,
			types: {
				DEFAULT: {
					...HAELibComponentsTerms.schemas.button.styleDataValues.default,
					value: BP.Object({
						props: {
							backgroundColor: BP.Prop(
								BackgroundColorSchema({
									alias: "backgroundColor_button",
									default: "PRIMARY"
								}),
								10
							),

							foregroundColor: BP.Prop(
								ForegroundColorSchema({
									alias: "foregroundColor_button",
									default: "WHITE"
								}),
								20
							),

							hoverState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataHoverState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_button",
													default: "PRIMARY"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_button",
													default: "WHITE"
												}),
												20
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								30
							),

							activeState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataActiveState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_button",
													default: "PRIMARY"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_button",
													default: "WHITE"
												}),
												20
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								40
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				CLEAR: {
					...HAELibComponentsTerms.schemas.button.styleDataValues.clear,
					value: BP.Void({})
				},
				TAB: {
					...HAELibComponentsTerms.schemas.button.styleDataValues.tab,
					value: BP.Object({
						props: {
							backgroundColor: BP.Prop(
								BackgroundColorSchema({
									alias: "backgroundColor_buttonTabStyle",
									default: "TRANSPARENT"
								}),
								10
							),

							foregroundColor: BP.Prop(
								ForegroundColorSchema({
									alias: "foregroundColor_buttonTabStyle",
									default: "TEXT"
								}),
								20
							),

							borderColor: BP.Prop(
								BorderColorSchema({
									alias: "borderColor_buttonTabStyle",
									default: "TRANSPARENT"
								}),
								30
							),

							borderWidth: BP.Prop(
								BP.String({
									...HAELibComponentsTerms.schemas.common.borderWidth,
									default: "0px 0px 2px"
								}),
								40
							),

							borderRadius: BP.Prop(BorderRadiusSchema(), 50),

							padding: BP.Prop(
								BP.StringWithConst({
									...HAELibComponentsTerms.schemas.common.padding,
									constants: getValuesFromStringEnum(
										SPACING,
										HAELibComponentsTerms.schemas.common.spacingValues
									),
									default: `2px ${SPACING_narrow} 0px`,
									constraints: {
										...HAELibComponentsTerms.schemas.common.spacingConstraints,
										pattern: spacingPattern
									}
								}),
								60
							),

							hoverState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataHoverState,
									enabledOpts: {
										default: true,
										fallbackValue: true
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_buttonTabStyleHoverState",
													default: "TRANSPARENT"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_buttonTabStyleHoverState",
													default: "PRIMARY"
												}),
												20
											),

											borderColor: BP.Prop(
												BorderColorSchema({
													alias: "borderColor_buttonTabStyleHoverState",
													default: "TRANSPARENT"
												}),
												30
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								100
							),

							activeState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataActiveState,
									enabledOpts: {
										default: true,
										fallbackValue: true
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_buttonTabStyleActiveState",
													default: "TRANSPARENT"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_buttonTabStyleActiveState",
													default: "PRIMARY"
												}),
												20
											),

											borderColor: BP.Prop(
												BorderColorSchema({
													alias: "borderColor_buttonTabStyleActiveState",
													default: "PRIMARY"
												}),
												30
											)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								110
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				CUSTOM: {
					...HAELibComponentsTerms.schemas.button.styleDataValues.custom,
					value: BP.Object({
						props: {
							backgroundColor: BP.Prop(
								BackgroundColorSchema({
									alias: "backgroundColor_button",
									default: "PRIMARY"
								}),
								10
							),

							foregroundColor: BP.Prop(
								ForegroundColorSchema({
									alias: "foregroundColor_button",
									default: "WHITE"
								}),
								20
							),

							borderColor: BP.Prop(BorderColorSchema(), 30),

							borderWidth: BP.Prop(
								BP.String({
									...HAELibComponentsTerms.schemas.common.borderWidth,
									default: ""
								}),
								40
							),

							borderRadius: BP.Prop(
								BorderRadiusSchema({
									default: "6px"
								}),
								50
							),

							padding: BP.Prop(
								BP.StringWithConst({
									...HAELibComponentsTerms.schemas.common.padding,
									constants: getValuesFromStringEnum(
										SPACING,
										HAELibComponentsTerms.schemas.common.spacingValues
									),
									default: `0px ${SPACING_default}`,
									constraints: {
										...HAELibComponentsTerms.schemas.common.spacingConstraints,
										pattern: spacingPattern
									}
								}),
								60
							),

							hoverState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataHoverState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_button",
													default: "PRIMARY"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_button",
													default: "WHITE"
												}),
												20
											),

											borderColor: BP.Prop(BorderColorSchema(), 30)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								100
							),

							activeState: BP.Prop(
								BP.OptGroup({
									...HAELibComponentsTerms.schemas.button.styleDataActiveState,
									enabledOpts: {
										default: false,
										fallbackValue: false
									},
									value: BP.Object({
										props: {
											backgroundColor: BP.Prop(
												BackgroundColorSchema({
													alias: "backgroundColor_button",
													default: "PRIMARY"
												}),
												10
											),

											foregroundColor: BP.Prop(
												ForegroundColorSchema({
													alias: "foregroundColor_button",
													default: "WHITE"
												}),
												20
											),

											borderColor: BP.Prop(BorderColorSchema(), 30)
										},
										editorOptions: {
											layoutType: "passthrough"
										}
									})
								}),
								110
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		0,
		propGroups.style
	)
};

/**
 * Button type string
 */
//export type BUTTON_TYPE_string = keyof TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentButton_Props>>["typeData"]["value"];

/**
 * Button style string
 */
//export type BUTTON_STYLE_string = keyof TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentButton_Props>>["styleData"]["value"];
