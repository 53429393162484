/**
 * File Field File Status enum
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum FILE_FIELD_FILE_STATUS {
	WAITING = "WAITING",
	UPLOADING = "UPLOADING",
	UPLOADED = "UPLOADED",
	ERROR = "ERROR"
}

export type FILE_FIELD_FILE_STATUS_string = keyof typeof FILE_FIELD_FILE_STATUS;

export const FILE_FIELD_FILE_STATUS_default: FILE_FIELD_FILE_STATUS_string = "WAITING";
