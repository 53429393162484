/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { uuid } from "@hexio_io/hae-lib-shared";
import { CONST } from "../constants";
import { ILocaleSettings } from "./ILocaleSettings";
import { IEditorIdentityKey, IIdentityKey, ISession, ISessionContext } from "./ISession";
import { ISessionMetaData } from "./ISessionMetadata";
import { ISessionStorage } from "./ISessionStorage";

export class Session implements ISession {
	/** Indicates that user is authenticated in request. */
	private _authenticated = false;

	/** Indicates that user was logged in. This state is deserialized from session store. */
	public userLoggedIn = false;
	public editorLoggedIn = false;
	public sessionId: string = null;
	public appEnvId?: string = null;

	public locale?: ILocaleSettings;
	public meta?: ISessionMetaData = {};

	public editorPermissions?: string[] = [];
	public lastEditorPermissionsLoad?: number;

	public lastLoginAt?: Date;
	public updatedAt?: Date;

	public isNew?: boolean = false;

	private _editorIdentityKey?: IEditorIdentityKey = null;
	public get editorIdentityKey(): IEditorIdentityKey {
		return this._editorIdentityKey;
	}
	public set editorIdentityKey(value: IEditorIdentityKey) {
		if (value !== null && value !== undefined) {
			this.editorLoggedIn = true;
		}

		this._editorIdentityKey = value;
	}

	private _userIdentityKey?: IIdentityKey = null;
	public get userIdentityKey(): IIdentityKey {
		return this._userIdentityKey;
	}
	public set userIdentityKey(value: IIdentityKey) {
		if (value !== null && value !== undefined) {
			this.userLoggedIn = true;
		}

		this._userIdentityKey = value;
	}

	public constructor(
		protected options?: {
			storage?: ISessionStorage;
			maxAge?: number;
		},
		data?: Partial<ISession>
	) {
		if (data) {
			this.sessionId = data.sessionId || this.sessionId;
			this.lastLoginAt = data.lastLoginAt ? data.lastLoginAt : undefined;
			this.userLoggedIn = data.userLoggedIn ?? false;
			this.editorLoggedIn = data.editorLoggedIn ?? false;
			this.appEnvId = data.appEnvId || undefined;
			this.userIdentityKey = data.userIdentityKey || null;
			this.editorIdentityKey = data.editorIdentityKey || null;
			this.locale = data.locale || undefined;
			this.meta = data.meta || this.meta;
			this.isNew = data.isNew ?? false;
			this.editorPermissions = data.editorPermissions || [];
			this.lastEditorPermissionsLoad = data.lastEditorPermissionsLoad;
			this.updatedAt = data.updatedAt || new Date();
		}

		/** Generate new session id. */
		this.sessionId = this.sessionId || uuid();
	}

	public isAuthenticated(): boolean {
		return this._authenticated;
	}

	public async save(): Promise<void> {
		//console.log("Save session:", { hasStorage: !!this.options?.storage });
		//console.log("Session:", {
		//	sessionId: this.sessionId,
		//	sessionData: {
		//		sessionId: this.sessionId,
		//		userLoggedIn: this.userLoggedIn,
		//		editorLoggedIn: this.editorLoggedIn,
		//		appEnvId: this.appEnvId,
		//		lastLoginAt: this.lastLoginAt,
		//		editorPermissions: this.editorPermissions,
		//		lastEditorPermissionsLoad: this.lastEditorPermissionsLoad,
		//		userIdentityKey: this.userIdentityKey,
		//		editorIdentityKey: this.editorIdentityKey,
		//		meta: this.meta
		//	}
		//});

		if (this.options?.storage) {
			this.options.storage.set(
				this.sessionId,
				{
					sessionId: this.sessionId,
					userLoggedIn: this.userLoggedIn,
					editorLoggedIn: this.editorLoggedIn,
					appEnvId: this.appEnvId,
					lastLoginAt: this.lastLoginAt,
					editorPermissions: this.editorPermissions,
					lastEditorPermissionsLoad: this.lastEditorPermissionsLoad,
					userIdentityKey: this.userIdentityKey,
					editorIdentityKey: this.editorIdentityKey,
					meta: this.meta,
					updatedAt: this.updatedAt
				},
				this.options?.maxAge || CONST.SESSIONS.EXPIRATION.STORAGE
			);
		}
	}

	public async login(
		options: { updateLastLoginAt: boolean } = { updateLastLoginAt: false }
	): Promise<void> {
		if (options.updateLastLoginAt) {
			this.lastLoginAt = new Date();
		}

		this.updatedAt = new Date();

		this._authenticated = true;
		return this.save();
	}

	public async logout(options: { logoutEditor: boolean }): Promise<void> {
		this._authenticated = false;

		this.userIdentityKey = undefined;
		this.userLoggedIn = false;

		if (options?.logoutEditor === true) {
			this.editorIdentityKey = undefined;
			this.editorLoggedIn = false;
		}

		return this.save();
	}

	public export(): Partial<ISessionContext> {
		return {
			sessionId: this.sessionId,
			loggedIn: this._authenticated,
			appEnvId: this.appEnvId,
			identityKey: this.userIdentityKey,
			meta: this.meta,
			locale: this.locale,
			isNew: this.isNew,
			lastLoginAt: this.lastLoginAt,
			isLoggingIn: false,
			updatedAt: this.updatedAt
		};
	}
}
