/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, removeAllEventListeners } from "@hexio_io/hae-lib-shared";
import { YAMLNode } from "hae-yaml-ast-parser";
import { parseAstToIDT, parseYAMLToIDT } from "../IDT/YAMLToIDT";
import { TGenericBlueprintSchema, TGetBlueprintSchemaModel } from "../Schema/IBlueprintSchema";
import { IModelChangeEvent, TGenericModelNode } from "../Schema/IModelNode";
import { ICompletionItem } from "../Shared/ICompletionItem";
import { DOC_ERROR_SEVERITY, IDocumentError, IDocumentErrorMap } from "../Shared/IDocumentError";
import { IDocumentPosition } from "../Shared/IDocumentPosition";
import { TCompletionFunction } from "../Shared/Completion";
import { IDocumentRange } from "../Shared/IDocumentRange";
import { REFACTORING_OP, TRefactoringEvent } from "../Shared/Refactoring";

// UUID cannot be imported with ES module style 🤦‍♂️
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { v4 as uuid } from "uuid";

const PENDING_OP_CHECK_LIMIT = 16;

/**
 * Context read mode
 * In combination with SchemaConditional allows to parse only a part of schema based on this mode.
 */
export enum DESIGN_CONTEXT_READ_MODE {
	SCAN = "scan",
	FULL = "full"
}

/**
 * Design Context resolvers
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type TGenericDesignContextResolvers = {};

/**
 * Design context options
 */
export interface IDesignContextOpts<TResolvers extends TGenericDesignContextResolvers> {
	resolvers: TResolvers;
	readMode?: DESIGN_CONTEXT_READ_MODE;
	enableCompletition?: boolean;
}

interface ICompletionEntry {
	range: IDocumentRange;
	minColumn: number;
	fn: TCompletionFunction;
}

/**
 * Completition index - map of `documentUri => [lineNumber][completitionItem]`
 */
interface ICompletitionIndex {
	[K: string]: ICompletionEntry[];
}

/**
 * Index of references and their counts
 */
export interface IRefIndex {
	[K: string]: {
		[K: string]: number;
	};
}

/**
 * Parsing context
 */
export class DesignContext<
	TResolvers extends TGenericDesignContextResolvers = TGenericDesignContextResolvers
> {
	/** Unique context ID */
	private contextId: string;

	/** Read mode */
	private readMode: DESIGN_CONTEXT_READ_MODE;

	/** Parse errors bound to documents */
	private parseErrors: IDocumentErrorMap = {};

	/** Last node ID - used for generating unique node IDs */
	private lastNodeId = 0;

	/** Map of a model node ID to a node instance */
	private nodeIdMap = new Map<number, TGenericModelNode>();

	/** Index to store identifiers and their counts */
	private identifierIndex: { [K: string]: number } = {};

	/** List of references reported by model */
	private refIndex: IRefIndex = {};

	/**
	 * Event emitted when any of model nodes change
	 */
	public modelChangeEvent = createEventEmitter<IModelChangeEvent>();

	/**
	 * Event emitted when identifier has been renamed
	 */
	public identifierRenameEvent = createEventEmitter<TRefactoringEvent>();

	/** Resolvers */
	private resolvers: TResolvers;

	/** List of pending async operations */
	private asyncOps: Promise<unknown>[] = [];

	/** Asnyc operation revision - used to track changes */
	private asyncOpsRev = 0;

	/* Error counts by type */
	private errorCountError = 0;
	private errorCountWarning = 0;
	private errorCountInfo = 0;
	private errorCountHint = 0;

	/** If the completition is enabled (and index will be built) */
	private enableCompletition?: boolean;

	/** Index of completition resolvers for each document */
	private completitionIndex: ICompletitionIndex = {};

	/**
	 * Design context
	 *
	 * @param opts Options
	 */
	public constructor(opts: IDesignContextOpts<TResolvers>) {
		this.resolvers = opts.resolvers;
		this.readMode = opts.readMode || DESIGN_CONTEXT_READ_MODE.FULL;
		this.enableCompletition = opts.enableCompletition || false;
		this.contextId = uuid();
	}

	/**
	 * INTERNAL: Returns next node ID (used to generate unique node IDs)
	 */
	public __getNextNodeId(): number {
		return this.lastNodeId++;
	}

	/**
	 * INTERNAL: Registers node ID to its instance
	 *
	 * @param node Node instance
	 */
	public __registerNode(node: TGenericModelNode): void {
		this.nodeIdMap.set(node.nodeId, node);
	}

	/**
	 * INTERNAL: Unregisters node ID
	 *
	 * @param node Node instance
	 */
	public __unregisterNode(node: TGenericModelNode): void {
		this.nodeIdMap.delete(node.nodeId);
	}

	/**
	 * INTERNAL: Add pending async operation
	 *
	 * @param op Async operation promise
	 */
	public __addAsyncOperation(op: Promise<unknown>): void {
		this.asyncOps.push(op);
		this.asyncOpsRev++;

		const removeOp = () => {
			const i = this.asyncOps.indexOf(op);

			if (i >= 0) {
				this.asyncOps.splice(i, 1);
			}
		};

		op.then(removeOp, removeOp);
	}

	/**
	 * INTERNAL: Adds identifier to the index
	 *
	 * @param identifier Identifier
	 */
	public __addIdentifier(identifier: string): void {
		this.identifierIndex[identifier] = (this.identifierIndex[identifier] || 0) + 1;
	}

	/**
	 * INTERNAL: Removes identifier from the index
	 *
	 * @param identifier Identifier
	 */
	public __removeIdentifier(identifier: string): void {
		this.identifierIndex[identifier] = (this.identifierIndex[identifier] || 0) - 1;

		if (this.identifierIndex[identifier] <= 0) {
			delete this.identifierIndex[identifier];
		}
	}

	/**
	 * INTERNAL: Renames identifier and potentially fires rename event
	 *
	 * @param oldIdentifier Old identifier
	 * @param newIdentifier New identifier
	 */
	public __renameIdentifier(oldIdentifier: string, newIdentifier: string): void {
		// Remove old
		this.identifierIndex[oldIdentifier] = (this.identifierIndex[oldIdentifier] || 0) - 1;

		if (this.identifierIndex[oldIdentifier] <= 0) {
			delete this.identifierIndex[oldIdentifier];
			// Emit rename event (eg. all identifiers with this name has been renamed)
			emitEvent(this.identifierRenameEvent, {
				operation: REFACTORING_OP.IDENTIFIER_RENAME,
				oldIdentifier: oldIdentifier,
				newIdentifier: newIdentifier
			});
		}

		// Add new
		this.identifierIndex[newIdentifier] = (this.identifierIndex[newIdentifier] || 0) + 1;
	}

	/**
	 * Returns unique identifier
	 * If identifier with base name already exists the number is appended
	 *
	 * @param baseName Identifier base name
	 * @param alwaysAddNumber If to add number to the even when identifier does not exist in the index
	 */
	public getUniqueIdentifier(baseName: string, alwaysAddNumber = true): string {
		let i = 0;
		let identifier: string;

		do {
			identifier = baseName + (i > 0 ? i : alwaysAddNumber ? 1 : "");
			i++;
		} while (this.identifierIndex[identifier]);

		return identifier;
	}

	/**
	 * Returns identifier index
	 */
	public getIdentifierIndex(): { [K: string]: number } {
		return this.identifierIndex;
	}

	/**
	 * INTERNAL: Adds reference to the index
	 *
	 * @param type Reference type
	 * @param refName Reference name
	 */
	public __addRef(type: string, refName: string): void {
		if (!this.refIndex[type]) {
			this.refIndex[type] = {};
		}

		this.refIndex[type][refName] = (this.refIndex[type][refName] || 0) + 1;
	}

	/**
	 * INTERNAL: Removes reference from index
	 *
	 * @param type Reference type
	 * @param refName Reference name
	 */
	public __removeRef(type: string, refName: string): void {
		if (!this.refIndex[type] || !this.refIndex[type][refName]) {
			return;
		}

		this.refIndex[type][refName] -= 1;

		if (this.refIndex[type][refName] <= 0) {
			delete this.refIndex[type][refName];
		}
	}

	/**
	 * Returns index of all references
	 */
	public getRefsIndex(): IRefIndex {
		return this.refIndex;
	}

	/**
	 * Returns references by type
	 *
	 * @param type Type
	 */
	public getRefsByType(type: string): IRefIndex[string] {
		return this.refIndex[type] || {};
	}

	/**
	 * Returns unique context ID
	 */
	public getContextId(): string {
		return this.contextId;
	}

	/**
	 * Returns node by its ID
	 *
	 * @param nodeId Node ID
	 */
	public getNodeById(nodeId: number): TGenericModelNode {
		return this.nodeIdMap.get(nodeId) || null;
	}

	/**
	 * Resets node sequence - USE CAREFULLY! Can cause node duplicates. Make sure no other model is currently using this context.
	 */
	public resetNodeSeqId(): void {
		this.lastNodeId = 0;
	}

	/**
	 * Returns current read mode
	 */
	public getReadMode(): DESIGN_CONTEXT_READ_MODE {
		return this.readMode;
	}

	/**
	 * Sets a context read mode
	 *
	 * @param readMode New read moed
	 */
	public setReadMode(readMode: DESIGN_CONTEXT_READ_MODE): void {
		this.readMode = readMode;
	}

	/**
	 * Returns resolver by name
	 *
	 * Throw an error if resolver is not available.
	 *
	 * @param name Resolver name
	 * @returns Resolver
	 */
	public getResolver<TResolver>(name: string): TResolver {
		const resolver = this.resolvers?.[name];

		if (resolver) {
			return resolver as unknown as TResolver;
		} else {
			throw new Error(`Resolver '${name}' is not available.`);
		}
	}

	/**
	 * INTERNAL: Sets a new resolvers - used only for testing
	 *
	 * @param resolvers Resolvers
	 */
	public __setResolvers(resolvers: TResolvers): void {
		this.resolvers = resolvers;
	}

	private getCompletionElement(stack: ICompletionEntry[], position: IDocumentPosition): ICompletionEntry {
		let lastEntry: ICompletionEntry = null;

		for (let i = 0; i < stack.length; i++) {
			const itemRange = stack[i].range;

			// In range
			if (
				(itemRange.start.line < position.line ||
					(itemRange.start.line === position.line && itemRange.start.col <= position.col)) &&
				stack[i].minColumn <= position.col
			) {
				if (
					!lastEntry ||
					(lastEntry &&
						(itemRange.start.line >= lastEntry.range.start.line ||
							(itemRange.start.line === lastEntry.range.start.line &&
								itemRange.start.col <= lastEntry.range.start.col)))
				) {
					lastEntry = stack[i];
				}
			}
		}

		return lastEntry;
	}

	/**
	 * Adds entry to an autocompletition index
	 *
	 * @param documentUri Document URI
	 * @param range Range in the document
	 * @param minColumn Minimum column to apply competion function (for identation checking)
	 * @param completitionFn Completition function
	 */
	public __addCompletition(
		documentUri: string,
		range: IDocumentRange,
		minColumn: number,
		completitionFn: TCompletionFunction
	): void {
		if (!this.enableCompletition) {
			return;
		}

		let docIndex = this.completitionIndex[documentUri];

		if (!this.completitionIndex[documentUri]) {
			docIndex = this.completitionIndex[documentUri] = [];
		}

		docIndex.push({
			range: range,
			fn: completitionFn,
			minColumn: minColumn
		});
	}

	/**
	 * Returns completition for a given document position
	 *
	 * @param documentUri Document URI
	 * @param position Position
	 */
	public getCompletion(
		documentUri: string,
		position: IDocumentPosition
	): {
		items: ICompletionItem[];
		range: IDocumentRange;
	} {
		const docIndex = this.completitionIndex[documentUri];

		if (!docIndex) {
			return {
				items: [],
				range: {
					start: position,
					end: position
				}
			};
		}

		const res = this.getCompletionElement(docIndex, position);

		console.log("Completion", position, res, docIndex);

		if (res) {
			const items: ICompletionItem[] = res.fn(this);

			return {
				items: items,
				range: res.range
			};
		} else {
			return {
				items: [],
				range: {
					start: position,
					end: position
				}
			};
		}
	}

	/**
	 * Logs a parse error
	 * @param error Error
	 */
	public logParseError(documentUri: string, error: IDocumentError): void {
		if (!this.parseErrors[documentUri]) {
			this.parseErrors[documentUri] = [];
		}

		this.parseErrors[documentUri].push(error);

		switch (error.severity) {
			case DOC_ERROR_SEVERITY.ERROR:
				this.errorCountError++;
				break;
			case DOC_ERROR_SEVERITY.WARNING:
				this.errorCountWarning++;
				break;
			case DOC_ERROR_SEVERITY.INFO:
				this.errorCountInfo++;
				break;
			case DOC_ERROR_SEVERITY.HINT:
				this.errorCountHint++;
				break;
		}
	}

	/**
	 * Resets error counters
	 */
	public resetErrorCounters(): void {
		this.errorCountError = 0;
		this.errorCountWarning = 0;
		this.errorCountInfo = 0;
		this.errorCountHint = 0;
	}

	/**
	 * Get errors for all documents
	 */
	public getParseErrors(): IDocumentErrorMap {
		return this.parseErrors;
	}

	/**
	 * Returns count of logged errors by type
	 */
	public getErrorTypeCounts(): { error: number; warning: number; info: number; hint: number } {
		return {
			error: this.errorCountError,
			warning: this.errorCountWarning,
			info: this.errorCountInfo,
			hint: this.errorCountHint
		};
	}

	/**
	 * Returns true if context has errors
	 */
	public hasErrors(): boolean {
		return Object.keys(this.parseErrors).length > 0;
	}

	/**
	 * Returns if context has errors of type "error"
	 */
	public hasFatalErrors(): boolean {
		return this.errorCountError > 0;
	}

	/**
	 * Get errors for a single document
	 *
	 * @param documentUri Document URI
	 */
	public getParseDocumentErrors(documentUri: string): Array<IDocumentError> {
		return this.parseErrors[documentUri] || [];
	}

	/**
	 * Removes all parse errors
	 */
	public clearAllParseErrors(): void {
		this.parseErrors = {};

		this.errorCountError = 0;
		this.errorCountHint = 0;
		this.errorCountInfo = 0;
		this.errorCountWarning = 0;
	}

	/**
	 * Removes all parse errors for a given document
	 *
	 * @param documentUri Document URI
	 */
	public clearParseDocumentErrors(documentUri: string): void {
		if (this.parseErrors[documentUri]) {
			// @todo Recalculate error counts

			this.parseErrors[documentUri] = [];
		}
	}

	/**
	 * Removes all data related to a provided document URI
	 *
	 * @param documentUri Document URI
	 */
	public removeDocument(documentUri: string): void {
		this.clearParseDocumentErrors(documentUri);
		delete this.completitionIndex[documentUri];
	}

	/**
	 * Loads and parses yaml document for a given schema
	 *
	 * @param schema Schema
	 * @param yamlContents YAML contents
	 * @param documentUri Document URI
	 */
	public loadYAMLDocument<TSchema extends TGenericBlueprintSchema>(
		schema: TSchema,
		yamlContents: string,
		documentUri?: string
	): TGetBlueprintSchemaModel<TSchema> {
		const parseResult = parseYAMLToIDT(yamlContents, documentUri);

		if (!parseResult.ast) {
			return null;
		}

		// Add parse errors
		if (parseResult.ast.errors.length > 0) {
			parseResult.ast.errors.map((e) => {
				this.logParseError(documentUri, {
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: e.name,
					message: e.message,
					range: {
						start: { line: e.mark.line, col: e.mark.column },
						end: { line: e.mark.line, col: e.mark.column + e.mark.buffer.length }
					},
					parsePath: []
				});
			});
		}

		if (!parseResult.idt) {
			return null;
		}

		this.completitionIndex[documentUri] = [];

		schema.provideCompletion(this, parseResult.idt.parseInfo.loc, 0, parseResult.idt);
		const model = schema.parse(this, parseResult.idt, null) as TGetBlueprintSchemaModel<TSchema>;

		return model;
	}

	/**
	 * Returns promise which resolves when all async operations are finished
	 */
	public async waitForPendingOperations(): Promise<void> {
		let checks = 0;
		let lastRev = this.asyncOpsRev;

		while (lastRev !== this.asyncOpsRev) {
			lastRev = this.asyncOpsRev;
			checks++;

			if (checks > PENDING_OP_CHECK_LIMIT) {
				throw new Error("Async operation checks limit reached, terminating.");
			}

			await Promise.all(this.asyncOps);
		}
	}

	/**
	 * Loads AST Node
	 *
	 * @param astNode AST node
	 */
	public loadASTNode<TSchema extends TGenericBlueprintSchema>(
		schema: TSchema,
		astNode: YAMLNode
	): TGetBlueprintSchemaModel<TSchema> {
		const parseResult = parseAstToIDT(astNode);

		if (!parseResult.ast) {
			return null;
		}

		// Add parse errors
		if (parseResult.ast.errors.length > 0) {
			parseResult.ast.errors.map((e) => {
				this.logParseError(parseResult.ast.documentUri, {
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: e.name,
					message: e.message,
					range: {
						start: { line: e.mark.line, col: e.mark.column },
						end: { line: e.mark.line, col: e.mark.column + e.mark.buffer.length }
					},
					parsePath: []
				});
			});
		}

		if (!parseResult.idt) {
			return null;
		}

		this.completitionIndex[parseResult.ast.documentUri] = [];
		const model = schema.parse(this, parseResult.idt, null) as TGetBlueprintSchemaModel<TSchema>;

		return model;
	}

	/**
	 * Resets context's state
	 */
	public reset(): void {
		this.parseErrors = {};
		this.nodeIdMap = new Map<number, TGenericModelNode>();
		this.identifierIndex = {};
		this.refIndex = {};

		this.errorCountError = 0;
		this.errorCountWarning = 0;
		this.errorCountInfo = 0;
		this.errorCountHint = 0;
	}

	/**
	 * Destroys the context
	 */
	public destroy(): void {
		this.parseErrors = null;
		this.nodeIdMap = null;
		this.identifierIndex = null;
		this.resolvers = null;
		this.asyncOps = [];
		this.completitionIndex = null;

		removeAllEventListeners(this.modelChangeEvent);
		removeAllEventListeners(this.identifierRenameEvent);
	}

	/**
	 * Returns a count of registered model nodes
	 */
	public getRegisteredNodeCount(): number {
		return this.nodeIdMap?.size ?? 0;
	}
}
