/**
 * Text component terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const form = {
	component: {
		label: "component:form#label",
		description: "component:form#description"
	},
	schema: {
		contentTemplate: {
			label: "component:form.schema.contentTemplate#label",
			description: "component:form.schema.contentTemplate#description"
		},
		additionalItems: {
			label: "component:form.schema.additionalItems#label",
			description: "component:form.schema.additionalItems#description"
		},
		additionalItemObject: {
			label: "component:form.schema.additionalItemObject#label",
			description: "component:form.schema.additionalItemObject#description"
		},
		additionalItemName: {
			label: "component:form.schema.additionalItemName#label",
			description: "component:form.schema.additionalItemName#description"
		},
		additionalItemValue: {
			label: "component:form.schema.additionalItemValue#label",
			description: "component:form.schema.additionalItemValue#description"
		},
		additionalItemValid: {
			label: "component:form.schema.additionalItemValid#label",
			description: "component:form.schema.additionalItemValid#description"
		},
		additionalItemChanged: {
			label: "component:form.schema.additionalItemChanged#label",
			description: "component:form.schema.additionalItemChanged#description"
		},
		events: {
			change: {
				label: "component:form.events.change#label"
			},
			clearValue: {
				label: "component:form.events.clearValue#label"
			}
		}
	},
	typeDesc: {
		content: {
			label: "component:form.typeDesc.content#label",
			description: "component:form.typeDesc.content#description"
		},
		clearValue: {
			label: "component:form.typeDesc.clearValue#label",
			description: "component:form.typeDesc.clearValue#description"
		}
	}
};
