/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createSubScope, Type } from "@hexio_io/hae-lib-blueprint";
import { TBlueprintReduceNodeOptsSchemaSpec } from "../../blueprints/nodes/BlueprintNodeReduce";
import {
	createErrorNodeResult,
	createSuccessNodeResult,
	setScopeVariable,
	unknownNodeError
} from "../helpers";
import { IActionContext, INodeContext, INodeResult, processNodeResult } from "../ActionManager";
import { NODE_OUTPUT_NAMES, NODE_TYPES, TAllNodesSpec } from "../../blueprints";
import { NODE_RESULT_TYPE } from "../IActionResult";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";

export async function reduceNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintReduceNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { context } = aCtx;
	const { items } = opts;

	context.debug("Reduce items:", { items });

	try {
		if (!Array.isArray(items)) {
			context.debug("Node invalid opts error.");
			return createErrorNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					data: {
						name: ERROR_NAMES.MAP_NODE_ERROR,
						code: ERROR_CODES.MAP_NODE_ERROR,
						message: "Items expected to be an array."
					},
					typeDescriptor: Type.Object({
						props: {
							name: Type.String({}),
							code: Type.String({}),
							message: Type.String({})
						}
					})
				},
				aCtx,
				nCtx
			);
		}

		const reduceNodeResult = createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ITEM,
				data: items,
				typeDescriptor: Type.Array({ items: [ Type.Any({}) ] })
			},
			aCtx,
			nCtx
		);

		const reduceNodeOpts = opts as TBlueprintReduceNodeOptsSchemaSpec;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let accumulator: any = reduceNodeOpts.initialValue;
		for (let index = 0; index < items.length; index++) {
			const item = items[index];
			context.debug("Reduce item:", { item, index, accumulator, opts: reduceNodeOpts });

			/** Update reduce node result with item data and process it. */
			reduceNodeResult.data = item;
			reduceNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ITEM;

			const reduceScope = createSubScope(nCtx.localScope);
			setScopeVariable(reduceScope, nCtx.nodeVarName, undefined, undefined);

			const varName = nCtx.nodeVarName || "";
			setScopeVariable(reduceScope, `${varName}_item`, item, reduceNodeResult.typeDescriptor);
			setScopeVariable(reduceScope, `${varName}_index`, index, Type.Integer({}));
			setScopeVariable(reduceScope, `${varName}_acc`, accumulator, Type.Any({}));

			const nCtxCopy = { ...nCtx };
			nCtxCopy.localScope = reduceScope;
			nCtxCopy.nodeVarName = undefined;

			const itemNodeResult = await processNodeResult(reduceNodeResult, aCtx, nCtxCopy);

			if (itemNodeResult.nCtx.nodeType === NODE_TYPES.RETURN) {
				context.debug("Node result has return node type.");
				return itemNodeResult;
			} else if (itemNodeResult.nCtx.status === NODE_RESULT_TYPE.ERROR) {
				context.debug("Node result has error.");

				if (reduceNodeOpts.ignoreErrors !== true) {
					context.debug("Node fails on error.");

					/** Update reduce node with error details and process it. */
					reduceNodeResult.outputName = NODE_OUTPUT_NAMES.ON_ERROR;
					if (aCtx.config.debug === true && reduceNodeResult.debug?.output) {
						reduceNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_ERROR;
					}
					reduceNodeResult.data = {
						errorData: {
							name: itemNodeResult.data?.errorData?.name,
							code: itemNodeResult.data?.errorData?.code,
							message: itemNodeResult.data?.errorData?.message,
							detail: itemNodeResult.data?.errorData?.detail
						},
						errorTracking: {
							actionName: aCtx.name,
							nodeName: nCtx.nodeName,
							nodeType: nCtx.nodeType,
							functionName: nCtx.functionName,
							integrationName: nCtx.integrationName,
							nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0
						}
					};

					if (aCtx.config.debug === true && reduceNodeResult.debug) {
						reduceNodeResult.debug.output.data = reduceNodeResult.data;
						reduceNodeResult.debug.executionTimeInMs =
							Date.now() - reduceNodeResult.nCtx.startTimeInMs;
					}

					return reduceNodeResult;
				} else {
					context.debug("Node ignores errors.");
					/** Ignore error and continue. */
				}
			} else {
				accumulator = itemNodeResult.data;
				context.debug("Updated accumulator:", { accumulator });
			}
		}

		context.debug("Reduced value:", { value: accumulator });

		/** Update reduce node result with data and return in on success output. */
		reduceNodeResult.outputName = NODE_OUTPUT_NAMES.ON_SUCCESS;
		reduceNodeResult.data = accumulator as any;
		reduceNodeResult.typeDescriptor = Type.Any({});

		if (aCtx.config.debug === true && reduceNodeResult.debug?.output) {
			reduceNodeResult.debug.output.name = NODE_OUTPUT_NAMES.ON_SUCCESS;
			reduceNodeResult.debug.output.data = reduceNodeResult.data;
		}

		return reduceNodeResult;
	} catch (error) {
		return unknownNodeError(opts, error, aCtx, nCtx);
	}
}
