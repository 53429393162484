/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, declareFunction, Type } from "@hexio_io/hae-lib-blueprint";
import { isValidObject } from "@hexio_io/hae-lib-shared";
import { decodeJwt } from "jose";

export const parseJwt = declareFunction({
	name: "JWT_PARSE",
	category: "jwt",
	label: "Parse JWT",
	description: "Parses JWT and returns claims.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "JWT"
		})
	],
	argRestSchema: null,
	returnType: Type.Array({
		items: [
			Type.Object({
				props: {
					name: Type.String({}),
					value: Type.Any({})
				}
			})
		]
	}),
	render: (_rCtx, args) => {
		const jwt = args[0]();

		const payload = decodeJwt(jwt);

		if (isValidObject(payload)) {
			return Object.entries(payload).map(([ name, value ]) => ({ name, value }));
		} else {
			return [];
		}
	}
});
