/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaValue } from "./value/SchemaValue";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaValueStringOpts, SchemaValueString } from "./value/SchemaValueString";
import { IIconNameItem, IIconNameResolver, IIconPackItem } from "../Resolvers";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

type TNameSchema = ISchemaValue<ISchemaConstString>;

/**
 * Schema model
 */
export interface ISchemaIconNameModel extends IModelNode<ISchemaIconName> {
	name: TGetBlueprintSchemaModel<TNameSchema>;
}

/**
 * Schema options
 */
export interface ISchemaIconNameOpts extends ISchemaValueStringOpts {}

/**
 * Spec value
 */
export type TSchemaIconNameSpec = TGetBlueprintSchemaSpec<TNameSchema>;

/**
 * Default value
 */
export type TSchemaIconNameDefault = TGetBlueprintSchemaDefault<TNameSchema>;

/**
 * Schema type
 */
export interface ISchemaIconName
	extends IBlueprintSchema<
		ISchemaIconNameOpts,
		ISchemaIconNameModel,
		TSchemaIconNameSpec,
		TSchemaIconNameDefault
	> {
	/**
	 * Returns a list of available icons
	 */
	getIconList: (modelNode: ISchemaIconNameModel, iconPackName?: string) => IIconNameItem[];

	/**
	 * Returns a list of available icon packs
	 */
	getIconPackList: (modelNode: ISchemaIconNameModel) => IIconPackItem[];
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaIconName(opts: ISchemaIconNameOpts): ISchemaIconName {
	const nameSchema = SchemaValueString({
		...opts,
		constraints: {
			...(opts.constraints || {}),
			pattern: opts.constraints?.pattern || "^(.+)/(.+)$"
		},
		editorOptions: {
			controlType: "iconSelector"
		}
	});

	const schema = createEmptySchema<ISchemaIconName>("iconName", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "name");
	};

	const createModel = (
		dCtx: DesignContext,
		nameModel: TGetBlueprintSchemaModel<TNameSchema>,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			name: nameModel
		});

		const model = assignParentToChildrenOf(modelNode);

		model.initRequiredValid = nameModel.initRequiredValid;

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const nameModel = nameSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, nameModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedName = modelNode.name.schema.clone(dCtx, modelNode.name, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			name: clonedName
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.name.schema.destroy(modelNode.name);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		const nameModel = nameSchema.parse(dCtx, idtNode, null);
		return createModel(dCtx, nameModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn) => {
		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			const resolver = dCtx.getResolver<IIconNameResolver>("iconName");
			const items: ICompletionItem[] = [];

			const iconList = resolver.getIconList();

			for (let i = 0; i < iconList.length; i++) {
				items.push({
					kind: CMPL_ITEM_KIND.EnumMember,
					label: `${iconList[i].name}`,
					insertText: `${iconList[i].name}`
				});
			}

			return items;
		});
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return nameSchema.serialize(modelNode.name, path);
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		return modelNode.name.schema.render(rCtx, modelNode.name, path, scope, prevSpec);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		return modelNode.name.schema.compileRender(cCtx, modelNode.name, path);
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		return nameSchema.validate(rCtx, path, modelNodeId, value, validateChildren);
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		return nameSchema.compileValidate(cCtx, path, modelNodeId, validateChildren);
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaIconName", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return nameSchema.getTypeDescriptor(modelNode?.name);
	};

	schema.getIconList = (modelNode: ISchemaIconNameModel, iconPackName?: string) => {
		return modelNode.ctx.getResolver<IIconNameResolver>("iconName").getIconList(iconPackName);
	};

	schema.getIconPackList = (modelNode: ISchemaIconNameModel) => {
		return modelNode.ctx.getResolver<IIconNameResolver>("iconName").getIconPackList();
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "name",
				node: modelNode.name
			}
		];
	};

	return schema;
}
