/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, SCHEMA_CONST_ANY_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { termsEditor } from "../../terms";
import { BlueprintNodeTypes } from "../BlueprintNodes";

export enum NODE_TYPES {
	START = "start",
	INTEGRATION = "integration",
	ACTION_V1_DEPRECATED = "action",
	ACTION_V2 = "action@v2",
	TRANSFORM = "transform",
	CONDITION = "condition",
	/** RETURN has value `output` because of the backward compatibility */
	/** @todo this can be renamed in next major release. */
	RETURN = "output",
	/** THROW has value `error` because of the backward compatibility */
	/** @todo this can be renamed in next major release. */
	THROW = "error",
	VIEW_TO_HTML = "viewToHtml",
	REQUEST = "request",
	LOGIN = "login",
	LOGOUT = "logout",
	RESPONSE = "response",
	REDIRECT = "redirect",
	SET_SESSION = "setSession",
	MAP = "map",
	REDUCE = "reduce",
	WHILE = "while",
	VAR = "variable",
	TIMEOUT = "timeout",
	LOG = "log",
	FILE_READ = "fileRead",
	FILE_CREATE = "fileCreate"
}

export enum NODE_OUTPUT_NAMES {
	ON_START = "onStart",
	ON_SUCCESS = "onSuccess",
	ON_ERROR = "onError",
	ON_FAIL = "onFail",
	ON_TRUE = "onTrue",
	ON_FALSE = "onFalse",
	ON_REQUEST = "onRequest",
	ON_ITEM = "onItem"
}

export const NODE_OUTPUTS = {
	ON_START: { label: "On Start", icon: "mdi/play" },
	ON_SUCCESS: { label: "On Success", icon: "mdi/check" },
	ON_ERROR: { label: "On Error", icon: "mdi/alert" },
	ON_FAIL: { label: "On Fail", icon: "mdi/close" },
	ON_TRUE: { label: "On True", icon: "mdi/check" },
	ON_FALSE: { label: "On False", icon: "mdi/close" },
	ON_CATCH: { label: "On Catch", icon: "mdi/alert" },
	ON_ITEM: { label: "On Item", icon: "mdi/debug-step-into" },
	ON_REQUEST: { label: "On Request", icon: "mdi/arrow-right" }
};

export type TNodeTypes = keyof typeof BlueprintNodeTypes;

export const BodyBlueprintSchema = BP.Any({
	label: termsEditor.nodes.common.body.label,
	description: termsEditor.nodes.common.body.description,
	defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
	fallbackValue: ""
});

export const HeadersBlueprintSchema = BP.Map({
	label: termsEditor.nodes.common.headers.label,
	description: termsEditor.nodes.common.headers.description,
	value: BP.String({
		label: termsEditor.nodes.common.header.label,
		description: termsEditor.nodes.common.header.description,
		default: ""
	})
});
