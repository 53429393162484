/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFileCreateNode = {
	name: NODE_TYPES.TRANSFORM,
	label: "File Create",
	icon: "mdi/file-plus",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			inputEncoding: BP.Prop(
				BP.Enum.String({
					label: "Input Encoding",
					description: "Encoding of the input contents.",
					default: "base64",
					fallbackValue: "base64",
					options: [
						{
							value: "utf8",
							label: "UTF-8"
						},
						{
							value: "base64",
							label: "Base64"
						}
					],
					constraints: {
						required: true
					}
				})
			),
			contents: BP.Prop(
				BP.String({
					label: "Contents",
					description: "File contents.",
					constraints: {
						required: true
					},
					default: "",
					fallbackValue: ""
				})
			)
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR
	})
};

export type TBlueprintFileCreateNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintFileCreateNode["opts"]
>;
