/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode
} from "../Schema/SchemaHelpers";
import { applyCodeArg } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaConstBoolean } from "./const/SchemaConstBoolean";
import { ISchemaConstObject, SchemaConstObject } from "./const/SchemaConstObject";
import { ISchemaValue, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { ISchemaValueBooleanOpts, SchemaValueBoolean } from "./value/SchemaValueBoolean";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";

export type ISchemaOptionalGroupSpec<TValueSchema extends TGenericBlueprintSchema> =
	null | TGetBlueprintSchemaSpec<TValueSchema>;

type TEnabledSchema = ISchemaValue<ISchemaConstBoolean>;

export type ISchemaOptionalGroupInternalSchema<TValueSchema extends TGenericBlueprintSchema> =
	ISchemaConstObject<{
		enabled: { schema: TEnabledSchema };
		value: { schema: TValueSchema };
	}>;

/**
 * Schema model
 */
export interface ISchemaOptionalGroupModel<TValueSchema extends TGenericBlueprintSchema>
	extends IModelNode<ISchemaOptionalGroup<TValueSchema>> {
	enabled: TGetBlueprintSchemaModel<TEnabledSchema>;
	value: TGetBlueprintSchemaModel<TValueSchema>;
	__internalModel: TGetBlueprintSchemaModel<ISchemaOptionalGroupInternalSchema<TValueSchema>>;
}

/**
 * Schema options
 */
export interface ISchemaOptionalGroupOpts<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchemaOpts {
	value: TValueSchema;
	enabledOpts?: ISchemaValueBooleanOpts;
}

/**
 * Default value
 */
export interface ISchemaOptionalGroupDefault<TValueSchema extends TGenericBlueprintSchema> {
	enabled: TGetBlueprintSchemaDefault<TEnabledSchema>;
	value: TGetBlueprintSchemaDefault<TValueSchema>;
}

/**
 * Schema type
 */
export interface ISchemaOptionalGroup<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchema<
		ISchemaOptionalGroupOpts<TValueSchema>,
		ISchemaOptionalGroupModel<TValueSchema>,
		ISchemaOptionalGroupSpec<TValueSchema>,
		ISchemaOptionalGroupDefault<TValueSchema>
	> {}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaOptionalGroup<TValueSchema extends TGenericBlueprintSchema>(
	opts: ISchemaOptionalGroupOpts<TValueSchema>
): ISchemaOptionalGroup<TValueSchema> {
	const internalSchema = SchemaConstObject({
		props: {
			enabled: {
				schema: SchemaValueBoolean({
					default: false,
					label: "Enabled",
					...opts.enabledOpts,
					constraints: {
						...opts.enabledOpts?.constraints,
						required: true
					}
				})
			},
			value: { schema: opts.value }
		}
	}) as ISchemaOptionalGroupInternalSchema<TValueSchema>;

	const schema = createEmptySchema<ISchemaOptionalGroup<TValueSchema>>("optGroup", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "__internalModel" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		internalModel: TGetBlueprintSchemaModel<typeof internalSchema>,
		parent: TBlueprintSchemaParentNode
	) => {
		const model = createModelNode(schema, dCtx, parent, internalModel.validationErrors, {
			enabled: internalModel.props.enabled,
			value: internalModel.props.value,
			__internalModel: internalModel
		});

		return assignParentToChildrenOf(model);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		const internalModel = internalSchema.createDefault(dCtx, null, defaultValue);
		return createModel(dCtx, internalModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedInternalSchema = internalSchema.clone(dCtx, modelNode.__internalModel, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			enabled: clonedInternalSchema.props.enabled,
			value: clonedInternalSchema.props.value,
			__internalModel: clonedInternalSchema
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		internalSchema.destroy(modelNode.__internalModel);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		const internalModel = internalSchema.parse(dCtx, idtNode, null);

		if (!internalModel) {
			return null;
		}

		return createModel(dCtx, internalModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		if (internalSchema.provideCompletion) {
			internalSchema.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return internalSchema.serialize(modelNode.__internalModel, path);
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const enabled = modelNode.enabled.schema.render(
			rCtx,
			modelNode.enabled,
			path.concat([ "enabled" ]),
			scope,
			prevSpec ? true : false
		);

		return (
			enabled
				? modelNode.value.schema.render(
						rCtx,
						modelNode.value,
						path.concat([ "value" ]),
						scope,
						prevSpec ? true : false
				  )
				: null
		) as ISchemaOptionalGroupSpec<TValueSchema>;
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Is static?
		if (modelNode.enabled.type === SCHEMA_VALUE_TYPE.CONST) {
			if (modelNode.enabled.constant.value === true) {
				return modelNode.value.schema.compileRender(cCtx, modelNode.value, path.concat([ "value" ]));
			} else {
				return {
					isScoped: false,
					code: `null`
				};
			}
		} else {
			const enabledCmp = modelNode.enabled.schema.compileRender(
				cCtx,
				modelNode.enabled,
				path.concat([ "enabled" ])
			);
			const valueCmp = modelNode.value.schema.compileRender(
				cCtx,
				modelNode.value,
				path.concat([ "value" ])
			);

			const isScoped = enabledCmp.isScoped || valueCmp.isScoped;

			// eslint-disable-next-line max-len
			const valueCode = `(${applyCodeArg(
				enabledCmp,
				`pv!==null?true:false`,
				`pt.concat(["enabled"])`
			)}?${applyCodeArg(valueCmp, `pv`, `pt.concat(["data"])`)}:null)`;

			return {
				isScoped,
				code: isScoped ? `(s,pv,pt)=>(${valueCode})` : `${valueCode}`
			};
		}
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		if (value !== undefined && value !== null) {
			return opts.value.validate(rCtx, path, modelNodeId, value, validateChildren);
		} else {
			return true;
		}
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const valueValidator = opts.value.compileValidate(cCtx, path, modelNodeId, validateChildren);

		if (valueValidator !== null) {
			return cCtx.addGlobalValue(
				`(v,pt)=>{if(v!==undefined&&v!==null){return (${valueValidator})(v,pt)}else{return true}}`
			);
		} else {
			return null;
		}
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaOptionalGroup", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return internalSchema.getTypeDescriptor();
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "enabled",
				node: modelNode.enabled
			},
			{
				key: "value",
				node: modelNode.value
			}
		];
	};

	return schema;
}
