/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type } from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintIntegrationNodeOptsSchemaSpec } from "../../blueprints";
import { BaseError, ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult, unknownNodeError } from "../helpers";

export async function integrationNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintIntegrationNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { functionName, integrationId, params } = opts;
	const { app, config, context } = aCtx;

	try {
		let integrationName = null;
		if (app.has("resourceManager")) {
			const resource = app.get("resourceManager").getResourceById(aCtx.id);
			if (resource) {
				integrationName = resource.label;
			}
		}

		nCtx.integrationName = integrationName;
		nCtx.functionName = functionName;

		const result = await app
			.get("integrationManager")
			.execute(integrationId, functionName, params, context, config);

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: result.data,
				typeDescriptor: result.typeDescriptor
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		if (error instanceof BaseError) {
			return createErrorNodeResult(
				{
					opts,
					outputName: NODE_OUTPUT_NAMES.ON_ERROR,
					error,
					data: {
						message: error.message,
						name: ERROR_NAMES.INTEGRATION_NODE,
						code: ERROR_CODES.INTEGRATION_NODE,
						httpStatus: error.errorDetails?.safeDetails?.httpStatus,
						headers: error.errorDetails?.safeDetails?.headers,
						data: error.errorDetails?.safeDetails?.data
					},
					typeDescriptor: Type.Object({
						props: {
							message: Type.String({}),
							name: Type.String({}),
							httpStatus: Type.Integer({})
						}
					})
				},
				aCtx,
				nCtx
			);
		} else {
			unknownNodeError(opts, error, aCtx, nCtx);
		}
	}
}
