/**
 * Name schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaConstString,
	ISchemaStringWithConstOpts,
	ISchemaValue
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../terms";

/**
 * Name schema
 *
 * @param opts Options
 */
export function NameSchema(
	opts: Partial<ISchemaStringWithConstOpts> = { alias: "name" }
): ISchemaValue<ISchemaConstString> {
	return BP.String({
		...termsEditor.schemas.common.name,
		...opts
	});
}
