/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Blueprint Document types
 */
export enum DOC_TYPES {
	ACTION_V1 = "v1/Action",
	ASSET_V1 = "v1/Asset",
	DICTIONARY_V1 = "v1/Directory",
	ENDPOINT_V1 = "v1/Endpoint",
	INTEGRATION_V1 = "v1/Integration",
	MANIFEST_V1 = "v1/Manifest",
	VIEW_V1 = "v1/View",
	ROUTES_V1 = "v1/Routes",
	TRANSLATIONS_V1 = "v1/Translations",
	THEME_V1 = "v1/Theme"
}
