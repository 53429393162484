/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ISchemaConstObject,
	ISchemaValue,
	TGenericBlueprintSchema,
	TRuntimeContextRenderFunction,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { ISchemaImportExport } from "@hexio_io/hae-lib-blueprint";
import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { IBlueprintRegistryItemProps } from "../blueprints";

/**
 * View Instance props
 */
export interface IViewInstanceProps extends IBlueprintRegistryItemProps {
	/** Params schema */
	paramsSchema: ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>;

	/** Render function */
	renderFunction?: TRuntimeContextRenderFunction<TGenericBlueprintSchema>;

	/** Export data for paramSchema */
	paramsSchemaExport?: ISchemaImportExport;

	/** Compiled view schema */
	compiledCode?: string;

	/** If view requires authenticated user to access it */
	requireAuthenticatedUser: boolean;

	/** If the view instance is valid and fully loaded */
	isValid: boolean;
}

export type IViewRegistry = ItemRegistry<IViewInstanceProps>;

export class ViewRegistry extends ItemRegistry<IViewInstanceProps> {}
