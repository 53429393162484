/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintLogNode = {
	name: NODE_TYPES.LOG,
	label: "Log",
	icon: "mdi/message-text-outline",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			level: BP.Prop(
				BP.Enum.String({
					label: termsEditor.nodes.log.level.label,
					description: termsEditor.nodes.log.level.description,
					options: [
						{
							value: "debug",
							label: termsEditor.nodes.log.levelLabels.debug
						},
						{
							value: "info",
							label: termsEditor.nodes.log.levelLabels.info
						},
						{
							value: "warn",
							label: termsEditor.nodes.log.levelLabels.warn
						},
						{
							value: "error",
							label: termsEditor.nodes.log.levelLabels.error
						}
					],
					default: "debug"
				}),
				0
			),
			message: BP.Prop(
				BP.String({
					label: termsEditor.nodes.log.message.label,
					description: termsEditor.nodes.log.message.description
				}),
				10
			),
			/** This was renamed to `detail`, but here it should be `data` for backward compatibility. */
			data: BP.Prop(
				BP.Any({
					label: termsEditor.nodes.log.data.label,
					description: termsEditor.nodes.log.data.description,
					defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
				}),
				20
			),
			attributes: BP.Prop(
				BP.Map({
					label: termsEditor.nodes.log.attributes.label,
					description: termsEditor.nodes.log.attributes.description,
					value: BP.String({
						label: termsEditor.nodes.log.attributeValue.label,
						description: termsEditor.nodes.log.attributeValue.description
					})
				}),
				30
			),
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS
	})
};

export type TBlueprintLogNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintLogNode["opts"]>;
