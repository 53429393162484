/**
 * Button style resolver
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaConstObject, TGetBlueprintSchemaSpec } from "@hexio_io/hae-lib-blueprint";
import { IButtonStyleProps, BUTTON_STYLE_string } from "@hexio_io/hae-lib-components";
import { HAEComponentButton_Props } from "./props";

/**
 * Resolved button style
 */
export interface IResolvedButtonStyle extends IButtonStyleProps {
	style: BUTTON_STYLE_string;
}

/**
 * Resolves button style
 *
 * @param styleData Style data
 */
export function resolveButtonStyle(
	styleData: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentButton_Props>>["styleData"]
): IResolvedButtonStyle {
	const style = styleData.type as BUTTON_STYLE_string;
	const styleDataValue = styleData.value[style];

	const result: IResolvedButtonStyle = {
		style
	};

	Object.entries(styleDataValue || {}).forEach(([ key, value ]) => {
		if (key !== "hoverState" && key !== "activeState" && value) {
			result[key] = value;
		}
	});

	if (styleDataValue?.hoverState) {
		Object.entries(styleDataValue.hoverState).forEach(([ key, value ]) => {
			if (value) {
				result[`hover${key.charAt(0).toUpperCase()}${key.slice(1)}`] = value;
			}
		});
	}

	if (styleDataValue?.activeState) {
		Object.entries(styleDataValue.activeState).forEach(([ key, value ]) => {
			if (value) {
				result[`active${key.charAt(0).toUpperCase()}${key.slice(1)}`] = value;
			}
		});
	}

	return result;
}
