/**
 * Repeat component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
	RUNTIME_CONTEXT_MODE,
	ISchemaComponentListSpec,
	IScope,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	createSubScope,
	ISchemaConstObjectModel,
	COMPONENT_MODE
} from "@hexio_io/hae-lib-blueprint";
import {
	ClassList,
	HAEComponentList,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { repeat as terms } from "../../terms/editor/components/repeat";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";

interface HAEComponentRepeat_State {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: Array<any>;
	/** Repeated elements */
	elements: Array<{
		components: ISchemaComponentListSpec;
		scope: IScope;
		key: string;
	}>;
}

const HAEComponentRepeat_Props = {
	items: BP.Prop(
		BP.Array({
			label: terms.schema.items.label,
			description: terms.schema.items.description,
			default: null,
			fallbackValue: null,
			constraints: {
				required: true
			},
			items: BP.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			})
		}),
		1
	),
	contentTemplate: BP.Prop(
		BP.ScopedTemplate({
			label: terms.schema.contentTemplate.label,
			description: terms.schema.contentTemplate.description,
			template: BP.ComponentList({})
		})
	),
	key: BP.Prop(
		BP.ScopedTemplate({
			label: terms.schema.key.label,
			description: terms.schema.key.description,
			template: BP.String({})
		})
	)
};

const HAEComponentRepeat_Events = {};

const HAEComponentRepeat_Definition = defineElementaryComponent<
	typeof HAEComponentRepeat_Props,
	HAEComponentRepeat_State,
	typeof HAEComponentRepeat_Events
>({
	name: "repeat",
	category: "logic",
	label: terms.component.label,
	description: terms.component.description,
	icon: "mdi/repeat",
	docUrl: "...",
	order: 20,
	nonVisual: true,
	props: HAEComponentRepeat_Props,
	events: HAEComponentRepeat_Events,

	resolve: (spec, state, _updateStateAsync, componentInstance, _rCtx, scope) => {
		const editComponentMode = componentInstance.componentMode === COMPONENT_MODE.EDIT;

		// Determine items and preserve previous one
		const items = spec.items !== null ? spec.items.slice() || [] : state?.items || [];

		if (editComponentMode && items.length === 0) {
			items.push(null);
		}

		return {
			items: items,
			elements: Array.isArray(items)
				? items.map((item, index) => {
					let cmpScope: IScope;

					let key = spec.key((currScope) => {
						return createSubScope(
							currScope,
							{
								item: item,
								[componentInstance.id + "_item"]: item
							},
							{
								item: Type.Any({}),
								[componentInstance.id + "_item"]: Type.Any({})
							}
						);
					});

					if (!key) {
						key = String(index);
					}

					const scopeKey = componentInstance.uid + "_" + key;
					const prevScope = scope.childScopes.get(scopeKey);

					const cmpList = spec.contentTemplate((currScope) => {
						cmpScope = createSubScope(
							currScope,
							{
								item: item,
								index: index,
								[componentInstance.id + "_item"]: item,
								[componentInstance.id + "_index"]: index,
								__key: key
							},
							{
								item: Type.Any({}),
								index: Type.Integer({}),
								[componentInstance.id + "_item"]: Type.Any({}),
								[componentInstance.id + "_index"]: Type.Integer({})
							},
							prevScope
						);

						return cmpScope;
					}, key);

					scope.childScopes.set(scopeKey, cmpScope);

					return {
						components: cmpList,
						scope: cmpScope,
						key: key
					};
				})
				: []
		};
	},

	// alterPropsSchemaHook: (propsSchema, createOpts) => {

	// 	propsSchema.opts.props.contentTemplate.schema.opts.template.overrideSchemaInheritedProps(createOpts?.inheritedProps);

	// },

	// restorePropsSchemaHook: (propsSchema) => {

	// 	propsSchema.opts.props.contentTemplate.schema.opts.template.restoreSchemaInheritedProps();

	// },

	// assignInheritedPropsHook: (propsModel, inheritedProps, _dCtx, notify) => {

	// 	const tpl = propsModel.props.contentTemplate.template;
	// 	tpl.schema.overrideInheritedProps(tpl, inheritedProps, notify);

	// 	return {};

	// },

	// unassignInheritedPropsHook: (propsModel, _dCtx, notify) => {

	// 	const tpl = propsModel.props.contentTemplate.template;
	// 	tpl.schema.restoreInheritedProps(tpl, notify);

	// },

	getInstanceList: (_spec, state, _prevInstanceList, cmpInstance, rCtx) => {
		const res = [];

		if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
			// cmpInstance.inheritedProps = state.elements[0].components[0].inheritedProps;
			res.push(cmpInstance);
		}

		state.elements.forEach((content, index) => {
			content.components.forEach((cmp) => {
				cmp.isTemplated = false;
				cmp.inheritedProps = cmpInstance.inheritedProps;
				cmp.originUid = cmpInstance.uid;

				if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR && index > 0) {
					cmp.isTemplated = true;
					cmp.modelNode = undefined;
				}

				if (rCtx.getMode() !== RUNTIME_CONTEXT_MODE.EDITOR || index > 0) {
					res.push(cmp);
				}
			});
		});

		return res;
	},

	getScopeData: (_spec, state) => {
		return {
			elements: state.elements.map((row) => row.scope.localData)
		};
	},

	getScopeType: (_spec, state) => {
		return Type.Object({
			props: {
				elements: Type.Array({
					label: terms.typeDesc.elements.label,
					description: terms.typeDesc.elements.description,
					items: state.elements.map((row) => row.scope.localType)
				})
			}
		});
	}
});

const HAEComponentRepeat_React: THAEComponentReact<typeof HAEComponentRepeat_Definition> = ({
	state,
	reactComponentClassList,
	componentInstance
}) => {
	const { safePath: componentPath, componentMode } = componentInstance;

	const sampleElementContents =
		state.elements.length > 0 ? state.elements[0].components : ([] as ISchemaComponentListSpec);
	const propsModel = componentInstance.modelNode?.props as ISchemaConstObjectModel<
		typeof HAEComponentRepeat_Props
	>;

	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-repeat", componentPath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	return (
		<div className={classList.toClassName()}>
			<HAEComponentList
				components={sampleElementContents}
				componentPath={[ ...componentPath, "component-list" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-repeat__content")}
				childClassList={new ClassList("cmp-repeat__item", "item")}
				childComponentClassList={new ClassList("item__component")}
				modelNode={propsModel?.props.contentTemplate.template}
				modifyModelOnDrop={() => null}
				dropZoneMode={DROP_ZONE_MODE.SINGLE}
			/>
		</div>
	);
};

export const HAEComponentRepeat: THAEComponentDefinition<typeof HAEComponentRepeat_Definition> = {
	...HAEComponentRepeat_Definition,
	reactComponent: HAEComponentRepeat_React
};
