/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Invalid model node manipulation
 */
export class ModelNodeManipulationError extends Error {
	public schemaName: string;
	public schemaOpts: unknown;
	public errorMessage: string;

	public constructor(schemaName: string, schemaOpts: unknown, message: string) {
		super(`Can't update model node: ${message}`);

		this.schemaName = schemaName;
		this.schemaOpts = schemaOpts;
		this.errorMessage = message;
	}
}
