/**
 * Menu MenuButton HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { Type, defineElementaryComponent, createSubScope } from "@hexio_io/hae-lib-blueprint";

import {
	ARROW_KEYS_NAVIGATION_TYPE,
	Button,
	ClassList,
	getStringEnumKeyByValue,
	Icon,
	ICON_NAME,
	ICON_SIZE_CLASS,
	IPopupRefProps,
	Popup,
	THAEComponentDefinition,
	THAEComponentReact,
	useArrowKeysNavigation
} from "@hexio_io/hae-lib-components";

import { isNonEmptyArray, isNonEmptyObject } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { HAEComponentMenuButton_Props } from "./props";
import { resolveButtonStyle } from "../Button/resolveButtonStyle";
import {
	HORIZONTAL_ALIGN_TO_POPUP_POSITION,
	POPUP_POSITION
} from "@hexio_io/hae-lib-components/src/Enums/POPUP_POSITION";

interface HAEComponentMenuButton_State {}

const HAEComponentMenuButton_Events = {
	itemClick: {
		...termsEditor.schemas.menuButton.events.itemClick
	}
};

const HAEComponentMenuButton_Definition = defineElementaryComponent<
	typeof HAEComponentMenuButton_Props,
	HAEComponentMenuButton_State,
	typeof HAEComponentMenuButton_Events
>({
	...termsEditor.components.menuButton.component,

	name: "menuButton",

	category: "content",

	icon: "mdi/arrow-down-drop-circle",

	events: HAEComponentMenuButton_Events,

	docUrl: "...",

	order: 40,

	props: HAEComponentMenuButton_Props,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentMenuButton_React: THAEComponentReact<typeof HAEComponentMenuButton_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList,
	additionalProps
}) => {
	const items = Array.isArray(props.items) ? props.items.filter((item) => isNonEmptyObject(item)) : [];

	const { safePath: componentPath, componentMode } = componentInstance;

	const [ active, setActive ] = React.useState(false);

	const elementRef = React.useRef<HTMLDivElement>();
	const popupRef = React.useRef<IPopupRefProps>();

	const buttonProps = {
		...(additionalProps?.resolvedButtonStyle || resolveButtonStyle(props.styleData))
	};

	const popupPosition = React.useMemo(() => {
		return getStringEnumKeyByValue(
			POPUP_POSITION,
			HORIZONTAL_ALIGN_TO_POPUP_POSITION.BOTTOM[props.menuHorizontalAlign]
		);
	}, [ props.menuHorizontalAlign ]);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-menu-button",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList, componentMode }
	);

	const _buttonClickHandler = React.useCallback(() => {
		if (!popupRef.current) {
			return;
		}

		setActive(popupRef.current.toggle(elementRef.current));
	}, []);

	const _popupKeyDownHandler = useArrowKeysNavigation(
		ARROW_KEYS_NAVIGATION_TYPE.VERTICAL,
		".cmp-menu-button__popup"
	);

	const _popupHideHandler = React.useCallback(() => {
		setActive(false);
	}, []);

	return (
		<div ref={elementRef} className={classList.toClassName()}>
			<Button
				{...buttonProps}
				labelText={props.labelText}
				labelIcon={props.labelIcon}
				enabled={props.enabled}
				active={active}
				componentPath={[ ...componentPath, "button" ]}
				componentMode={componentMode}
				classList={new ClassList("button--layout", "cmp-menu-button__button")}
				stopPropagationOnClick={false}
				onClick={_buttonClickHandler}
			>
				<Icon
					source={ICON_NAME.MENU_DOWN}
					size={getStringEnumKeyByValue(ICON_SIZE_CLASS, ICON_SIZE_CLASS.MEDIUM)}
					componentPath={[ ...componentPath, "button-icon" ]}
					componentMode={componentMode}
					classList={new ClassList("cmp-menu-button__button-icon")}
				/>
			</Button>

			{isNonEmptyArray(items) ? (
				<Popup
					ref={popupRef}
					position={popupPosition}
					focus={true}
					componentPath={[ ...componentPath, "popup" ]}
					componentMode={componentMode}
					classList={new ClassList("cmp-menu-button__popup")}
					onKeyDownCapture={_popupKeyDownHandler}
					onHide={_popupHideHandler}
				>
					<ul className="cmp-menu-button__items">
						{items.map((item, index) => {
							if (!item.visible) {
								return null;
							}

							const itemKey = item.key ? `id-${item.key}` : `${index}`;

							const itemClassList = ClassList.fromObject({
								"cmp-menu-button__item": true,
								"cmp-menu-button__item--separated": item.separated
							});

							function _itemClickHandler() {
								if (item.hideOnClick && popupRef.current) {
									popupRef.current.hide();
								}

								if (componentInstance.eventEnabled.itemClick) {
									return componentInstance.eventTriggers.itemClick((parentScope) => {
										return createSubScope(parentScope, { item }, { item: Type.Any({}) });
									});
								}

								return;
							}

							return (
								<li key={itemKey} className={itemClassList.toClassName()}>
									<Button
										style="CLEAR"
										labelText={item.labelText}
										labelIcon={item.labelIcon}
										link={item.link}
										tabIndex={0}
										componentPath={[ ...componentPath, itemKey, "button" ]}
										componentMode={componentMode}
										classList={new ClassList("cmp-menu-button__item-button")}
										onClick={_itemClickHandler}
									/>
								</li>
							);
						})}
					</ul>
				</Popup>
			) : null}
		</div>
	);
};

export const HAEComponentMenuButton: THAEComponentDefinition<typeof HAEComponentMenuButton_Definition> = {
	...HAEComponentMenuButton_Definition,
	reactComponent: HAEComponentMenuButton_React
};
