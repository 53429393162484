/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Refactoring operations
 */
export enum REFACTORING_OP {
	IDENTIFIER_RENAME = "identifierRename"
}

/**
 * Rename identifier refactoring operation object
 */
export interface IRefactoringEvent_IdentifierRename {
	operation: REFACTORING_OP.IDENTIFIER_RENAME;
	oldIdentifier: string;
	newIdentifier: string;
}

/**
 * Refactoring event
 */
export type TRefactoringEvent = IRefactoringEvent_IdentifierRename;
