/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IScope, TGetBlueprintSchemaSpec, TTypeDesc, Type } from "@hexio_io/hae-lib-blueprint";
import { isValidObject, truncateData } from "@hexio_io/hae-lib-shared";
import { NODE_OUTPUT_NAMES, TAllNodesSpec, TBlueprintNodeTypes } from "../blueprints";
import { BaseError, ERROR_CODES, ERROR_NAMES } from "../errors";
import { IActionContext, INodeContext, INodeResult } from "./ActionManager";
import {
	ACTION_ERROR_REASON,
	IActionResultError,
	IActionResultSuccess,
	NODE_RESULT_TYPE
} from "./IActionResult";

const errorTypeDescriptor = Type.Object({
	props: {
		errorData: Type.Object({
			props: {
				name: Type.String({}),
				code: Type.String({}),
				message: Type.String({}),
				detail: Type.Any({}),
				processedItems: Type.Any({}),
				errorList: Type.Any({}),
				httpStatus: Type.Integer({}),
				headers: Type.Any({}),
				errorData: Type.Any({}),
				errorTracking: Type.Any({})
			}
		}),
		errorTracking: Type.Object({
			props: {
				actionName: Type.String({}),
				nodeName: Type.String({}),
				nodeType: Type.String({}),
				integrationName: Type.String({}),
				functionName: Type.String({}),
				nestedActionCalls: Type.Integer({})
			}
		})
	}
});

export const createSuccessNodeResult = <TSpec extends Partial<TAllNodesSpec>>(
	params: {
		outputName: NODE_OUTPUT_NAMES;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data?: any;
		typeDescriptor?: TTypeDesc;
		nestedAction?: boolean;
		opts: TGetBlueprintSchemaSpec<TBlueprintNodeTypes[keyof TBlueprintNodeTypes]["opts"]>;
	},
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): INodeResult => {
	let executionTimeInMs = 0;
	if (nCtx.startTimeInMs) {
		executionTimeInMs = Date.now() - nCtx.startTimeInMs;
	}

	nCtx.status = NODE_RESULT_TYPE.SUCCESS;

	return {
		nodeId: nCtx.nodeId,
		outputName: params.outputName,
		data: params.data ?? null,
		typeDescriptor: params.typeDescriptor || Type.Void({}),
		debug:
			aCtx.config.debug === true
				? {
						inputScope: {
							globalData: nCtx.localScope.globalData,
							localData: nCtx.localScope.localData
						} as IScope,
						nodeOpts: params.opts,
						nodeType: nCtx.nodeType,
						invocationOrder: nCtx.invocationOrder,
						output: {
							name: params.outputName,
							data: params.data,
							type: params.typeDescriptor
						},
						executionTimeInMs,
						debug: { date: new Date().toISOString(), timeout: aCtx.timeout }
				  }
				: null,
		nCtx
	};
};

export const createErrorNodeResult = <TSpec extends Partial<TAllNodesSpec>>(
	params: {
		opts: TGetBlueprintSchemaSpec<TBlueprintNodeTypes[keyof TBlueprintNodeTypes]["opts"]>;
		outputName: NODE_OUTPUT_NAMES;
		data?: {
			message?: string;
			name?: string;
			code?: string;
			detail?: any;
			httpStatus?: number;
			data?: any;
			headers?: { [K: string]: string };
			/** For nested actions and so. */
			errorTracking?: INodeResult["data"]["errorTracking"];
		};
		/** Debug detail */
		debugDetail?: { [K: string]: any };
		error?: any;
		typeDescriptor?: TTypeDesc;
		nestedActionErrorResult?: IActionResultError;
	},
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): INodeResult => {
	const { context } = aCtx;

	let executionTimeInMs = 0;
	if (nCtx.startTimeInMs) {
		executionTimeInMs = Date.now() - nCtx.startTimeInMs;
	}

	nCtx.status = NODE_RESULT_TYPE.ERROR;

	let debug: any = {};
	if (aCtx.config.debug === true) {
		debug = {
			date: new Date().toISOString(),
			timeout: aCtx.timeout
		};
		if (params.error instanceof BaseError && params.error.errorDetails?.details) {
			debug = { ...debug, ...params.error.errorDetails.details };
		}
		if (params.debugDetail) {
			debug = { ...debug, ...params.debugDetail };
		}
	}

	let errorData: any = {};
	const errorTracking = createErrorTracking(aCtx, nCtx);

	if (params?.error instanceof BaseError) {
		context.debug("Create node result from BaseError.", params.error);

		/** Known error. */
		errorData = {
			name: params.error.name || null,
			code: params.error.code || null,
			message: params.error.message || null,
			httpStatus: params.error.errorDetails?.safeDetails?.httpStatus,
			headers: params.error.errorDetails?.safeDetails?.headers,
			data: params.error.errorDetails?.safeDetails?.data
		};
	} else if (params?.error) {
		context.debug("Create node result from Unknown error.", params.error);

		/** Unknown error. */
		errorData = {
			name: params?.data?.name || null,
			code: params?.data?.code || null,
			message: params?.data?.message || null,
			detail: params?.data?.detail,
			httpStatus: params?.data?.httpStatus,
			headers: params?.data?.headers,
			data: params?.data?.data
		};
	} else {
		if (params?.nestedActionErrorResult) {
			context.debug("Create node result from nested action error result.");

			errorData = {
				errorData: params?.nestedActionErrorResult?.error?.errorData || errorData,
				errorTracking: params?.nestedActionErrorResult?.error?.errorTracking || errorTracking
			};

			if (params?.nestedActionErrorResult?.error?.code === ERROR_CODES.MAX_RECURSION) {
				errorData = {
					...errorData,
					code: params?.nestedActionErrorResult?.error?.code,
					name: params?.nestedActionErrorResult?.error?.name,
					message: params?.nestedActionErrorResult?.error?.message
				};

				if (errorData.errorData?.errorData) {
					errorData.errorData = {
						errorData: errorData.errorData?.errorData,
						errorTracking: errorData.errorData?.errorTracking
					};
				}
			}
		} else {
			context.debug("Create node result from params.", params?.data);
			errorData = {
				name: params?.data?.name || null,
				code: params?.data?.code || null,
				message: params?.data?.message || null,
				detail: params?.data?.detail,
				httpStatus: params?.data?.httpStatus,
				headers: params?.data?.headers,
				data: params?.data?.data
			};
		}
	}

	const data = {
		errorData,
		errorTracking
	};

	return {
		nodeId: nCtx.nodeId,
		outputName: params.outputName,
		data,
		typeDescriptor: errorTypeDescriptor,
		debug:
			aCtx.config.debug === true
				? {
						inputScope: {
							globalData: nCtx.localScope.globalData,
							localData: nCtx.localScope.localData
						} as IScope,
						nodeOpts: params.opts,
						nodeType: nCtx.nodeType,
						invocationOrder: nCtx.invocationOrder,
						output: {
							name: params.outputName,
							data,
							type: errorTypeDescriptor
						},
						executionTimeInMs,
						debug
				  }
				: null,
		nCtx
	};
};

export function unknownNodeError<TSpec extends Partial<TAllNodesSpec>>(
	opts: any,
	error: any,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): INodeResult {
	aCtx.context.debug("Unhandled node error.");
	aCtx.context.debug({ error, message: error?.message });

	nCtx.status = NODE_RESULT_TYPE.ERROR;

	const debug = {
		date: new Date().toISOString(),
		timeout: aCtx.timeout
	};

	let executionTimeInMs = 0;
	if (nCtx.startTimeInMs) {
		executionTimeInMs = Date.now() - nCtx.startTimeInMs;
	}

	const outputName = NODE_OUTPUT_NAMES.ON_ERROR;

	const data = {
		name: ERROR_NAMES.UNKNOWN_ERROR,
		code: ERROR_CODES.UNKNOWN_ERROR,
		message: "Unknown action node error."
	};

	if (error instanceof BaseError) {
		data.message = error.message;
	}

	return {
		nodeId: nCtx.nodeId,
		outputName,
		data: {
			errorData: data,
			errorTracking: createErrorTracking(aCtx, nCtx)
		},
		typeDescriptor: errorTypeDescriptor,
		debug:
			aCtx.config.debug === true
				? {
						inputScope: {
							globalData: nCtx.localScope.globalData,
							localData: nCtx.localScope.localData
						} as IScope,
						nodeOpts: opts,
						nodeType: nCtx.nodeType,
						invocationOrder: nCtx.invocationOrder,
						output: {
							name: outputName,
							data,
							type: errorTypeDescriptor
						},
						executionTimeInMs,
						debug
				  }
				: null,
		nCtx
	};
}

export const createActionSuccessResult = <TSpec extends Partial<TAllNodesSpec>>(
	nodeResult: INodeResult,
	aCtx: IActionContext<TSpec>
): IActionResultSuccess => {
	const { config } = aCtx;

	let debug: any = null;
	if (aCtx.config.debug === true) {
		debug = { date: new Date().toISOString(), timeout: aCtx.timeout };
	}

	if (
		(config.isEditor && config.debug === true) ||
		(config.isEditor !== true && config.sensitiveLog === true && config.debug === true)
	) {
		debug = truncateData(aCtx.debug, {
			maxStringLength: 1024 * 1024 // 1MB
		});
	}

	let typeDescriptor = null;
	if (config.withTypeDescriptor === true) {
		typeDescriptor = nodeResult.typeDescriptor;
	}

	return {
		status: NODE_RESULT_TYPE.SUCCESS,
		data: nodeResult.data,
		typeDescriptor,
		debug
	};
};

export const createActionErrorResult = <TSpec extends Partial<TAllNodesSpec>>(
	params: {
		/** Error name */
		name: string;
		/** Error name */
		code: string;
		/** Error message */
		message?: string;
		/** Error detail */
		detail?: any;
		/** Action error reason */
		reason: ACTION_ERROR_REASON;
		/** Safe error message, without sensitive data */
		httpStatus?: number;
		/** Debug detail */
		debugDetail?: { [K: string]: any };
		/** Error instance */
		error?: any;
	},
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext,
	nodeResult?: INodeResult
): IActionResultError => {
	const { message, debugDetail, reason, name, code } = params;

	const { nodeId } = nCtx;
	const { config } = aCtx;

	/** Default error data and tracking */
	const errorData = {
		name: params?.name || null,
		code: params?.code || null,
		message: params?.message || null,
		detail: params?.detail || null,
		httpStatus: params?.httpStatus || null
	};
	const errorTracking = createErrorTracking(aCtx, nCtx);

	const actionResult: IActionResultError = {
		status: NODE_RESULT_TYPE.ERROR,
		error: {
			reqId: aCtx.config.reqId || null,
			traceId: aCtx.config.traceId || null,
			message,
			name,
			code,
			reason,
			errorData: nodeResult?.data?.errorData || errorData,
			errorTracking: nodeResult?.data?.errorTracking || errorTracking
		}
	};

	if (aCtx.config.debug === true) {
		if (nodeId && aCtx.debug?.nodes?.[nodeId]) {
			aCtx.debug.nodes[nodeId].debug = {
				...(aCtx.debug.nodes[nodeId].debug || {}),
				...debugDetail,
				date: new Date().toISOString()
			};
		}

		if (aCtx.debug && aCtx.startTimeInMs) {
			aCtx.debug.executionTimeInMs = Date.now() - aCtx.startTimeInMs;
		}
	}

	/** If nested error in Max Recursion error - return nested error details to bubble up this error to the top. */
	if (nodeResult) {
		const lastErrorData = getLastErrorData(nodeResult.data?.errorData);
		if (lastErrorData?.code === ERROR_CODES.MAX_RECURSION) {
			actionResult.error.code = lastErrorData.code;
			actionResult.error.name = lastErrorData.name;
			actionResult.error.message = lastErrorData.message;
		}
	}

	if (
		(config.isEditor && config.debug === true) ||
		(config.isEditor !== true && config.sensitiveLog === true && config.debug === true)
	) {
		actionResult.debug = truncateData(trimDebug(aCtx.debug), {
			maxStringLength: 1024 * 1024 // 1MB
		});
	}

	actionResult.error.errorData = trimErrorData(actionResult.error.errorData);
	return actionResult;
};

export const setScopeVariable = (scope: IScope, varName: string, value: any, type: TTypeDesc): IScope => {
	scope.localData[varName] = scope.globalData[varName] = value;
	scope.localType[varName] = scope.globalType[varName] = type;
	return scope;
};

/**
 * Takes IActionResultError and returns only first N nested errorData objects, all other errorData objects are removed.
 * @param errorData ErrorData object
 * @param nestedLevel Max nested level
 */
export function trimErrorData(
	errorData: IActionResultError["error"]["errorData"],
	maxNestedLevel = 3
): IActionResultError["error"]["errorData"] {
	let lastErrorData = errorData;
	let nestedLevel = 0;

	while (lastErrorData?.errorData) {
		nestedLevel++;
		lastErrorData = lastErrorData.errorData;
	}

	if (nestedLevel <= maxNestedLevel) {
		return errorData;
	} else {
		let trimLevel = nestedLevel - maxNestedLevel;
		while (trimLevel > 0) {
			trimLevel--;
			errorData = errorData.errorData;
		}

		return errorData;
	}
}

/** Trim everything that is below N nested levels. */
export function trimDebug(debugData: any, nestedLevel = 14): any {
	if (nestedLevel <= 0) {
		return null;
	} else {
		if (isValidObject(debugData)) {
			const debug = {};
			for (const [ name ] of Object.entries(debugData)) {
				debug[name] = trimDebug(debugData[name], nestedLevel - 1);
			}
			return debug;
		} else {
			return debugData;
		}
	}
}

/**
 * Creates Error Tracking object
 * @param aCtx Action Context
 * @param nCtx Node Context
 * @returns
 */
function createErrorTracking(
	aCtx: IActionContext<Partial<TAllNodesSpec>>,
	nCtx: INodeContext
): INodeResult["data"]["errorTracking"] {
	return {
		actionName: aCtx.name,
		nodeName: nCtx.nodeName,
		integrationName: nCtx.integrationName,
		functionName: nCtx.functionName,
		nodeType: nCtx.nodeType,
		nestedActionCalls: nCtx.nestedActionCalls || 0
	};
}

function getLastErrorData(
	errorData: IActionResultError["error"]["errorData"]
): IActionResultError["error"]["errorData"] {
	let lastErrorData = errorData;

	while (lastErrorData?.errorData) {
		lastErrorData = lastErrorData.errorData;
	}

	return lastErrorData;
}
