/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	TEditorApiAccountResponse,
	TEditorApiImpersonateAccountsResponse,
	TEditorApiUserResponse
} from "../api";
import { MOCKED_EMAIL, MOCKED_PERMISSIONS } from "./MockedConst";

export const MOCKED_ACCOUNT = {
	data: null,
	signedAt: new Date().toISOString(),
	isAdmin: false,
	timezone: "Europe/Prague",
	language: "En",
	country: null,
	phone: null,
	newsletters: false,
	accountId: "account.id",
	email: MOCKED_EMAIL,
	firstName: "Demo",
	lastName: "User",
	photo: null,
	enabled: true,
	terms: true,
	gdpr: true,
	fullName: "Demo User",
	permissions: MOCKED_PERMISSIONS,
	created: new Date().toISOString(),
	createdBy: "system",
	modified: new Date().toISOString(),
	modifiedBy: MOCKED_EMAIL
} as TEditorApiAccountResponse;

export const MOCKED_IMPERSONATE_ACCOUNT = {
	...MOCKED_ACCOUNT,
	resource: "Organization",
	resourceId: "00000000-0000-0000-0000-000000000000",
	roleId: "00000000-0000-0000-0000-000000000000",
	resourceName: null,
	roleName: null,
	endUser: false,
	roles: null
};

export const MOCKED_IMPERSONATE_ACCOUNTS = [
	MOCKED_IMPERSONATE_ACCOUNT
] as TEditorApiImpersonateAccountsResponse;

export const MOCKED_USER = {
	userId: "id",
	email: MOCKED_ACCOUNT.email,
	firstName: MOCKED_ACCOUNT.firstName,
	lastName: MOCKED_ACCOUNT.lastName,
	photo: MOCKED_ACCOUNT.photo,
	confirmed: true,
	enabled: true
} as TEditorApiUserResponse;
