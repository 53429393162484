/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";

import {
	CONSTANTS_TYPES,
	IAppServer,
	IConstant,
	IConstantEnvVar,
	IConstants,
	IConstantsManifest
} from "../app";
import { DOC_TYPES } from "../blueprints";
import { ILogger } from "../logger";
import { IResourceOnEventData } from "../resources";
import { Service } from "../services";
import { IConstantsManager } from "./IConstantsManager";

export class ConstantsManager extends Service implements IConstantsManager {
	/** Logger instance */
	protected logger: ILogger;

	/** Private constants */
	private privateValues: IConstants = {};

	/** Public constants */
	private publicValues: IConstants = {};

	private onResourceEventHandler: (data: IResourceOnEventData) => Promise<void>;

	public constructor(protected app: IAppServer) {
		super(app.get("logger"));
		this.logger = app.get("logger").facility("constants-manager");
	}

	/** Initializes */
	public async init(): Promise<void> {
		this.logger.info("Initializing...");

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;

		this.onResourceEventHandler = async (data: IResourceOnEventData) => {
			return that.onResourceEvent(data);
		};

		if (this.app.has("resourceManager")) {
			onEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
		}

		this.initialized = true;
	}

	/** Disposes */
	public async dispose(): Promise<void> {
		this.logger.info("Disposing...");

		if (this.onResourceEventHandler) {
			offEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
			this.onResourceEventHandler = undefined;
		}

		this.privateValues = {};
		this.publicValues = {};

		this.initialized = false;
	}

	/** Reload constants */
	public reload(constants: IConstantsManifest): void {
		this.logger.info("Reloading constants...");

		this.privateValues = {};
		this.publicValues = {};

		if (!constants) {
			this.logger.warn("Can't initialize", "Constants section not found in manifest.");
			return;
		}

		for (const [ key, value ] of Object.entries(constants)) {
			switch (value.type) {
				case "constant":
					this.addConstant(key, value.value.constant);
					break;

				case "envvar":
					this.addEnvVar(key, value.value.envvar);
					break;

				default:
					break;
			}
		}
	}

	/**
	 * Adds constant
	 *
	 * @param key Key
	 * @param value Value
	 */
	public addConstant(key: string, value: IConstant): void {
		if (value.public === true) {
			this.publicValues[key] = value.value;
		} else {
			this.privateValues[key] = value.value;
		}
	}

	/**
	 * Adds Environment Variable
	 * @param key Key
	 * @param value Value
	 */
	protected addEnvVar(key: string, value: IConstantEnvVar): void {
		if (![ "string", "number", "boolean" ].includes(typeof value)) {
			this.addConstant(key, { value: null, public: value.public });
		}

		const rawValue = process.env[value.name];

		let constantValue = null;

		if (typeof rawValue === "string") {
			switch (value.type) {
				case CONSTANTS_TYPES.boolean: {
					const boolStr = rawValue.toLowerCase();

					if ([ "true", "false" ].includes(boolStr)) {
						constantValue = boolStr === "true" ? true : false;
					} else if (value.default) {
						constantValue = value.default;
					}

					break;
				}
				case CONSTANTS_TYPES.number: {
					const number = Number.parseFloat(rawValue);

					if (!isNaN(number)) {
						constantValue = number;
					} else if (value.default) {
						constantValue = value.default;
					}

					break;
				}
				default:
					constantValue = rawValue === "" ? value.default : rawValue;
					break;
			}
		} else {
			constantValue = rawValue !== undefined ? rawValue : value.default;
		}

		this.addConstant(key, { value: constantValue, public: value.public });
	}

	/**
	 * Returns only public constants
	 * @returns
	 */
	public getPublicConstants(): IConstants {
		return this.publicValues;
	}

	/**
	 * Returns only private constants
	 * @returns
	 */
	public getPrivateConstants(): IConstants {
		return this.privateValues;
	}

	/**
	 * Returns all constants
	 * @returns
	 */
	public getAllConstants(): IConstants {
		return { ...this.privateValues, ...this.publicValues };
	}

	/** Resource Manager on load event handler */
	protected async onResourceEvent(data: IResourceOnEventData): Promise<void> {
		if (data.resource?.docType !== DOC_TYPES.MANIFEST_V1) {
			return;
		}

		this.logger.info("Reload constants...");

		const constants = this.app.get("resourceManager").getManifest().constants || {};
		await this.reload(constants);
	}
}
