/**
 * Custom HTML schema terms
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const customHtml = {
	markup: {
		label: "schema:customHtml.markup#label"
	},
	script: {
		label: "schema:customHtml.script#label"
	}
	/*style: {
		label: "schema:customHtml.style#label"
	}*/
};
