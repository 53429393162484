/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IRuntimeError } from "../Context/IRuntimeError";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";

/**
 * Formats validation errors into a string
 *
 * @param errors Errors
 */
export function formatValidationErrors(errors: IBlueprintSchemaValidationError[]): string {
	return errors.map((e) => `    - ${e.message}`).join("\n");
}

/**
 * Formats runtime error into a string
 *
 * @param error Error
 */
export function formatRuntimeError(error: IRuntimeError): string {
	return error.modelPath.join(".") + ": " + error.message + (error.details ? ` (${error.details.join("; ")})` : "");
}
