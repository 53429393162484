/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { applyCodeArg } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import { IThemeRefItem, IThemeRefResolver } from "../Resolvers";
import {
	IBlueprintSchema,
	IBlueprintSchemaChildNode,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators,
	assignParentToModelProps,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { TypeDescObject } from "../Shared/ITypeDescriptor";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { ValidatorObject } from "../validators/ValidatorObject";
import { ISchemaConstEnum } from "./const/SchemaConstEnum";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaDynamic, SchemaDynamic } from "./SchemaDynamic";
import { ISchemaValue, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { SchemaValueEnumString } from "./value/SchemaValueEnum";
import { SchemaValueString } from "./value/SchemaValueString";

type TSchemaThemeIDSchema = ISchemaValue<ISchemaConstString>;
type TSchemaThemeResolvedStyleSchema = ISchemaValue<ISchemaConstEnum<ISchemaConstString>>;
type TSchemaThemeGenericStyleSchema = ISchemaValue<ISchemaConstString>;
type TSchemaThemeUnionStyleSchema = TSchemaThemeResolvedStyleSchema | TSchemaThemeGenericStyleSchema;

type TSchemaThemeStyleSchema = ISchemaDynamic<IThemeResolveStyle, TSchemaThemeUnionStyleSchema>;

interface IThemeResolveStyle {
	themeId: string;
}

/**
 * Schema model
 */
export interface ISchemaThemeRefModel extends IModelNode<ISchemaThemeRef> {
	/** Theme ID */
	themeId: TGetBlueprintSchemaModel<TSchemaThemeIDSchema>;
	/** Theme style name */
	styleName: TGetBlueprintSchemaModel<TSchemaThemeStyleSchema>;
	/** Change event handler bound to model */
	__changeHandler: () => void;
	/** Resolver invalidate event bound to model */
	__invalidateHandler: () => void;
	/** If the theme ID constant change event has been bound */
	__themeIdConstantChangeEventBound: boolean;
	/** Last available theme ID - for ref counting */
	__lastThemeId: string;
}

/**
 * Schema options
 */
export interface ISchemaThemeRefOpts extends IBlueprintSchemaOpts {
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
}

/**
 * Default value
 */
export interface ISchemaThemeRefDefault {
	/** Theme ID */
	themeId: TGetBlueprintSchemaDefault<TSchemaThemeIDSchema>;
	/** Style Name */
	styleName: TGetBlueprintSchemaDefault<TSchemaThemeStyleSchema>;
}

/**
 * Schema spec
 */
export interface ISchemaThemeRefSpec {
	/** Theme ID */
	themeId: TGetBlueprintSchemaSpec<TSchemaThemeIDSchema>;
	/** Style Name */
	styleName: TGetBlueprintSchemaSpec<TSchemaThemeStyleSchema>;
}

/**
 * Schema type
 */
export interface ISchemaThemeRef
	extends IBlueprintSchema<
		ISchemaThemeRefOpts,
		ISchemaThemeRefModel,
		ISchemaThemeRefSpec,
		ISchemaThemeRefDefault
	> {
	/**
	 * Returns a list of available configuration names
	 */
	getThemeList: (modelNode: ISchemaThemeRefModel) => IThemeRefItem[];
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaThemeRef(opts: ISchemaThemeRefOpts): ISchemaThemeRef {
	type TIDModel = TGetBlueprintSchemaModel<TSchemaThemeIDSchema>;
	type TStyleModel = TGetBlueprintSchemaModel<TSchemaThemeStyleSchema>;

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });

	const resolvedStyleCache = new WeakMap();

	const getResolvedStyleSchema = (
		resolver: IThemeRefResolver,
		theme: IThemeResolveStyle
	): TSchemaThemeResolvedStyleSchema => {
		if (theme.themeId) {
			const styles = resolver.getStylesById(theme.themeId);

			if (!styles) {
				throw new Error(`Unknown theme ID '${theme.themeId}'.`);
			}

			if (resolvedStyleCache.has(styles)) {
				return resolvedStyleCache.get(styles);
			}

			const stylesSchema = SchemaValueEnumString({
				label: "Style name",
				placeholder: "common only",
				options: styles.map((style) => ({ label: style.label, value: style.name }))
			});

			resolvedStyleCache.set(styles, stylesSchema);

			return stylesSchema;
		} else {
			return null;
		}
	};

	const resolveStyleFromDesignCtx = (dCtx: DesignContext, theme: IThemeResolveStyle) => {
		return getResolvedStyleSchema(dCtx.getResolver<IThemeRefResolver>("themeRef"), theme);
	};

	const idSchema: TSchemaThemeIDSchema = SchemaValueString({
		label: "Theme",
		constraints: {
			required: opts?.constraints?.required,
			min: 1
		},
		allowTranslate: false,
		icon: "mdi/motion-play",
		editorOptions: {
			controlType: "themeSelector",
			layoutType: "noHeader"
		}
	});

	const styleSchema = SchemaDynamic<IThemeResolveStyle, TSchemaThemeUnionStyleSchema>({
		resolveSchemaDesign: resolveStyleFromDesignCtx,
		defaultSchema: SchemaValueString({
			label: "Style name"
		})
	});

	const keyPropRules = {
		themeId: {
			required: true,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				const themeList = dCtx.getResolver<IThemeRefResolver>("themeRef").getThemeList();

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = themeList.map((item) => ({
						kind: CMPL_ITEM_KIND.Reference,
						label: item.label,
						insertText: item.themeId
					}));

					return items;
				});
			}
		},
		styleName: {
			required: true
		}
	};

	const updateRef = (dCtx: DesignContext, prevId: string, newId: string) => {
		if (prevId !== null) {
			dCtx.__removeRef("theme", prevId);
		}

		if (newId !== null) {
			dCtx.__addRef("theme", newId);
		}
	};

	const schema = createEmptySchema<ISchemaThemeRef>("themeRef", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "themeId", "styleName" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		idModel: TIDModel,
		styleModel: TStyleModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			themeId: idModel,
			styleName: styleModel,
			__changeHandler: null,
			__invalidateHandler: null,
			__themeIdConstantChangeEventBound: false,
			__lastThemeId: null
		});

		const model = assignParentToChildrenOf(modelNode);

		const handleChange = (wasInvalidated?: boolean) => {
			if (model.themeId.type === SCHEMA_VALUE_TYPE.CONST) {
				if (!model.__themeIdConstantChangeEventBound) {
					onEvent(model.themeId.constant.changeEvent, model.__changeHandler);
					model.__themeIdConstantChangeEventBound = true;
				}

				model.styleName.schema.resolve(
					model.styleName,
					{
						themeId: model.themeId.constant.value
					},
					!wasInvalidated,
					wasInvalidated
				);

				updateRef(dCtx, model.__lastThemeId, model.themeId.constant.value);
				model.__lastThemeId = model.themeId.constant.value;
			} else {
				model.styleName.schema.reset(model.styleName, !wasInvalidated);

				updateRef(dCtx, model.__lastThemeId, null);
				model.__lastThemeId = null;
			}
		};

		model.__changeHandler = () => handleChange(false);
		model.__invalidateHandler = () => handleChange(true);

		onEvent(model.themeId.changeEvent, model.__changeHandler);
		onEvent(dCtx.getResolver<IThemeRefResolver>("themeRef").onInvalidate, model.__invalidateHandler);

		if (model.themeId.type === SCHEMA_VALUE_TYPE.CONST) {
			onEvent(model.themeId.constant.changeEvent, model.__changeHandler);
			model.__themeIdConstantChangeEventBound = true;

			updateRef(dCtx, null, model.themeId.constant.value);
			model.__lastThemeId = model.themeId.constant.value;
		}

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [ rootValidator as any ], defaultValue);

		const idModel = idSchema.createDefault(dCtx, null, defaultValue?.themeId);
		let styleModel: TStyleModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			styleModel = styleSchema.createDefault(dCtx, null, defaultValue?.styleName, {
				resolveParams: { themeId: idModel.constant.value }
			}) as TStyleModel;
		} else {
			styleModel = styleSchema.createDefault(dCtx, null, defaultValue?.styleName) as TStyleModel;
		}

		return createModel(dCtx, idModel, styleModel, errors, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedId = modelNode.themeId.schema.clone(dCtx, modelNode.themeId, null);
		const clonedStyleName = modelNode.styleName.schema.clone(dCtx, modelNode.styleName, null);

		const clone = createModel(dCtx, clonedId, clonedStyleName, modelNode.validationErrors, parent);

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.themeId.schema.destroy(modelNode.themeId);
		modelNode.styleName.schema.destroy(modelNode.styleName);

		offEvent(
			modelNode.ctx.getResolver<IThemeRefResolver>("themeRef").onInvalidate,
			modelNode.__invalidateHandler
		);

		updateRef(modelNode.ctx, modelNode.__lastThemeId, null);
		modelNode.__lastThemeId = null;

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const {
			keys,
			isValid: isRootKeysValid,
			keysValid: rootKeysValidity
		} = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		const idModel = rootKeysValidity.themeId ? idSchema.parse(dCtx, keys["themeId"].value, null) : null;

		if (keys.styleName && keys.styleName.key && idtNode.parseInfo && keys.styleName?.key?.parseInfo) {
			styleSchema.provideCompletion(
				dCtx,
				{
					uri: idtNode.parseInfo.loc.uri,
					range: {
						start: {
							line: keys.styleName.key.parseInfo.loc.range.end.line,
							col: keys.styleName.key.parseInfo.loc.range.end.col + 2
						},
						end: keys.styleName.parseInfo.loc.range.end
					}
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				keys.styleName.value,
				idModel && idModel.type === SCHEMA_VALUE_TYPE.CONST
					? {
							resolveParams: {
								themeId: idModel ? idModel.constant.value : null
							}
					  }
					: undefined
			);
		}

		if (!isRootKeysValid) {
			return schema.createDefault(dCtx, parent);
		}

		let styleModel: TStyleModel;

		if (idModel.type === SCHEMA_VALUE_TYPE.CONST) {
			styleModel = styleSchema.parse(dCtx, keys["styleName"].value, null, {
				resolveParams: { themeId: idModel.constant.value }
			}) as TStyleModel;
		} else {
			styleModel = styleSchema.parse(dCtx, keys["styleName"].value, null) as TStyleModel;
		}

		if (styleModel.hasError && keys["themeId"].value.parseInfo) {
			dCtx.logParseError(keys["themeId"].value.parseInfo.loc.uri, {
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve theme: ${styleModel.error}`,
				parsePath: keys["themeId"].value.path,
				range: keys["themeId"].value.parseInfo.loc.range,
				metaData: {
					errorInstance: styleModel.errorInstance,
					stack: styleModel.errorInstance instanceof Error ? styleModel.errorInstance.stack : null
				}
			});
		}

		return createModel(dCtx, idModel, styleModel, [], parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// name
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[themeId]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{themeId}" ]),
						value: "themeId"
					} as IBlueprintIDTScalar,
					value: modelNode.themeId.schema.serialize(modelNode.themeId, path.concat([ "themeId" ]))
				} as IBlueprintIDTMapElement,
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[styleName]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{styleName}" ]),
						value: "styleName"
					} as IBlueprintIDTScalar,
					value: modelNode.styleName.schema.serialize(
						modelNode.styleName,
						path.concat([ "styleName" ])
					)
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const themeId = modelNode.themeId.schema.render(
			rCtx,
			modelNode.themeId,
			path.concat([ "themeId" ]),
			scope,
			prevSpec?.themeId
		);
		const styleName = modelNode.styleName.schema.render(
			rCtx,
			modelNode.styleName,
			path.concat([ "styleName" ]),
			scope,
			prevSpec?.styleName
		);

		return { themeId, styleName };
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate params resolve state
		if (modelNode.styleName.hasError) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.INVALID_REF,
				message: `Cannot resolve theme: ${modelNode.styleName.error}`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:themeRef#errors.cannotResolve",
					themeId: modelNode.__lastThemeId
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		// Log notice when dynamic themeId
		if (modelNode.themeId.type !== SCHEMA_VALUE_TYPE.CONST) {
			cCtx.logCompileError({
				severity: DOC_ERROR_SEVERITY.HINT,
				name: DOC_ERROR_NAME.DYNAMIC_VALUE_NOTICE,
				// eslint-disable-next-line max-len
				message: `Theme ID is set dynamically. This prevents validation of theme parameters at design time and may result in runtime errors when configured incorrectly.`,
				metaData: {
					// @todo from translation table
					translationTerm: "schema:themeRef#errors.themeIdSetDynamically"
				},
				modelNodeId: modelNode.nodeId,
				modelPath: path
			});
		}

		const idCmp = modelNode.themeId.schema.compileRender(cCtx, modelNode.themeId, path.concat("themeId"));
		const styleNameCmp = modelNode.styleName.schema.compileRender(
			cCtx,
			modelNode.styleName,
			path.concat("styleName")
		);

		const idCode = applyCodeArg(
			idCmp,
			`typeof pv==="object"&&pv!==null?pv.themeId:undefined`,
			`pt.concat(["themeId"])`
		);
		const styleNameCode = applyCodeArg(
			styleNameCmp,
			`typeof pv==="object"&&pv!==null?pv.styleName:undefined`,
			`pt.concat(["styleName"])`
		);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({themeId:${idCode},styleName:${styleNameCode}})`
		};
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		let isValid = true;

		// Validate self as object
		isValid =
			applyRuntimeValidators<{ [K: string]: unknown }>(
				rCtx,
				path,
				modelNodeId,
				[ rootValidator ],
				DOC_ERROR_SEVERITY.ERROR,
				value as unknown as { [K: string]: unknown }
			) && isValid;

		if (validateChildren && value instanceof Object) {
			isValid =
				idSchema.validate(rCtx, path.concat([ "themeId" ]), modelNodeId, value.themeId, true) &&
				isValid;
			isValid =
				styleSchema.validate(rCtx, path.concat([ "styleName" ]), modelNodeId, value.styleName, true, {
					resolveParams: {
						themeId: value.themeId
					}
				}) && isValid;
		}

		return isValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[ rootValidator ],
			DOC_ERROR_SEVERITY.ERROR
		);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {
			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const idValid = idSchema.compileValidate(cCtx, path.concat([ "themeId" ]), modelNodeId, true);
			const styleNameValid = styleSchema.compileValidate(
				cCtx,
				path.concat([ "styleName" ]),
				modelNodeId,
				true
			);

			if (idValid) {
				expressions.push(`_vd=(${idValid})(v.themeId,pt)&&_vd;`);
			}

			if (styleNameValid) {
				expressions.push(`_vd=(${styleNameValid})(v.styleName,pt)&&_vd;`);
			}

			expressions.push(`}`);
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaThemeRef", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				themeId:
					modelNode?.themeId.schema.getTypeDescriptor(modelNode.themeId) ||
					idSchema.getTypeDescriptor(),
				styleName:
					modelNode?.styleName.schema.getTypeDescriptor(modelNode.styleName) ||
					styleSchema.getTypeDescriptor()
			},
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getThemeList = (modelNode: ISchemaThemeRefModel) => {
		return modelNode.ctx.getResolver<IThemeRefResolver>("themeRef").getThemeList();
	};

	schema.getChildNodes = (modelNode) => {
		const children: IBlueprintSchemaChildNode[] = [
			{
				key: "themeId",
				node: modelNode.themeId
			}
		];

		if (modelNode.styleName) {
			children.push({
				key: "styleName",
				node: modelNode.styleName
			});
		}

		return children;
	};

	return schema;
}
