/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { createEventEmitter, emitEvent, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTList,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { applyCodeArg, escapeString, inlineValue } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import {
	extractAndValidateIDTMapProperties,
	validateIDTNode,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions
} from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TTypeDesc, TypeDescObject, TypeDescString } from "../Shared/ITypeDescriptor";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import {
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	Prop,
	SchemaConstObject
} from "./const/SchemaConstObject";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import { ISchemaConstString, SchemaConstString } from "./const/SchemaConstString";
import { ISchemaScopedTemplate, SchemaScopedTemplate } from "./SchemaScopedTemplate";
import { ISchemaConstArray, SchemaConstArray } from "./const/SchemaConstArray";
import { ISchemaConstFloat, SchemaConstFloat } from "./const/SchemaConstFloat";
import { ModelNodeManipulationError } from "../Schema/ModelNodeManipulationError";
import { IParseInfo } from "../Shared/IParseInfo";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { IScope } from "../Shared/Scope";

type TVarNameSchema = ISchemaConstString;
type TOutputsSchema = ISchemaConstObject<{
	[K: string]: ISchemaConstObjectOptsProp<ISchemaConstArray<ISchemaConstString>>;
}>;
type TPositionSchema = ISchemaConstObject<{
	x: ISchemaConstObjectOptsProp<ISchemaConstFloat>;
	y: ISchemaConstObjectOptsProp<ISchemaConstFloat>;
}>;

/**
 * Flow node output definition
 */
export interface ISchemaFlowNodeTypeOutputDefinition {
	/** Output label */
	label?: string;
	/** Description */
	description?: string;
	/** Icon */
	icon?: string;
}

/**
 * Map of flow node output definitions
 */
export interface ISchemaFlowNodeTypeOutputDefinitionMap {
	[K: string]: ISchemaFlowNodeTypeOutputDefinition;
}

/**
 * Flow node type definition
 */
export interface ISchemaFlowNodeTypeDefinition<TOptsSchema extends TGenericBlueprintSchema> {
	/** Type name */
	name: string;
	/** Type label */
	label?: string;
	/** Description */
	description?: string;
	/** Icon */
	icon?: string;
	/** Editor options */
	editorOptions?: {
		/** If to show item in a quick menu (where applicable) */
		displayInQuickMenu?: boolean;
		/** If to show item in node palette */
		displayInPalette?: boolean;
		/** Node category name */
		category?: string;
	};
	/** Options schema */
	opts: TOptsSchema;

	/**
	 * Resolves output definition based on a current opts model
	 *
	 * @param opts Options model
	 */
	resolveOutputs: (opts: TGetBlueprintSchemaModel<TOptsSchema>) => ISchemaFlowNodeTypeOutputDefinitionMap;

	/**
	 * Resolver node label based on configured options. Optional.
	 */
	resolveLabel?: (opts: TGetBlueprintSchemaModel<TOptsSchema>) => string;
}

/**
 * Base map of flow node type definitions - is used for a type checking (is extended)
 */
export interface ISchemaFlowNodeTypeDefinitionMap {
	[K: string]: ISchemaFlowNodeTypeDefinition<TGenericBlueprintSchema>;
}

/**
 * Type helper to get options from type map
 */
export type TGetSchemaFlowNodeOptsSpec<
	TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap,
	TKey extends string
> = TGetBlueprintSchemaSpec<TTypeDefs[TKey]["opts"]>;

export interface ISchemaFlowNodeParseInfo {
	root: IParseInfo;
	id: IParseInfo;
	outputs: {
		[K: string]: IParseInfo[];
	};
}

/**
 * Info about node's runtime execution - for runtime debugging
 */
export interface ISchemaFlowNodeExecutionResult<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap> {
	scope?: IScope;
	resolvedOpts?: TGetBlueprintSchemaSpec<TTypeDefs[keyof TTypeDefs]["opts"]>;
	activeOutput?: string;
	outputData?: unknown;
	outputType?: TTypeDesc;
	debug?: unknown;
	executionTimeInMs?: number;
}

/**
 * Schema model
 */
export interface ISchemaFlowNodeModel<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IModelNode<ISchemaFlowNode<TTypeDefs>> {
	/** Flow node ID */
	id: string;
	/** Variable name */
	varName: TGetBlueprintSchemaModel<TVarNameSchema>;
	/** Flow node position in a flow chart */
	position: TGetBlueprintSchemaModel<TPositionSchema>;
	/** Flow node type */
	type: keyof TTypeDefs;
	/** Options model */
	opts: TGetBlueprintSchemaModel<ISchemaScopedTemplate<TTypeDefs[keyof TTypeDefs]["opts"]>>;
	/** Outputs */
	outputs: TGetBlueprintSchemaModel<TOutputsSchema>;
	/** Comment */
	comment: TGetBlueprintSchemaModel<ISchemaConstString>;
	/** Parse info for better error reporting */
	parseInfo?: ISchemaFlowNodeParseInfo;
	/** If node has been executed - for runtime debugging */
	executionResult: ISchemaFlowNodeExecutionResult<TTypeDefs>;
	/** Event emitted when execution result has been set or reset */
	executedEvent: TSimpleEventEmitter<ISchemaFlowNodeExecutionResult<TTypeDefs>>;
}

/**
 * Schema options
 */
export interface ISchemaFlowNodeOpts<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IBlueprintSchemaOpts {
	/** Flow node type definitions */
	nodeTypes: TTypeDefs;
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
}

/**
 * Default value
 */
export interface ISchemaFlowNodeDefault<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap> {
	/** Flow node ID */
	id: string;
	/** Variable name */
	varName: TGetBlueprintSchemaDefault<TVarNameSchema>;
	/** Flow node position in a flow chart */
	position: TGetBlueprintSchemaDefault<TPositionSchema>;
	/** Flow node type */
	type: keyof TTypeDefs;
	/** Options model */
	opts: TGetBlueprintSchemaDefault<ISchemaScopedTemplate<TTypeDefs[keyof TTypeDefs]["opts"]>>;
	/** Outputs */
	outputs: TGetBlueprintSchemaDefault<TOutputsSchema>;
	/** Comment */
	comment: TGetBlueprintSchemaDefault<ISchemaConstString>;
}

/**
 * Schema spec
 */
export interface ISchemaFlowNodeSpec<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap> {
	/** Flow node ID */
	id: string;
	/** Variable name */
	varName: TGetBlueprintSchemaDefault<TVarNameSchema>;
	/** Flow node position in a flow chart */
	position: TGetBlueprintSchemaDefault<TPositionSchema>;
	/** Flow node type */
	type: keyof TTypeDefs;
	/** Options spec */
	opts: TGetBlueprintSchemaSpec<ISchemaScopedTemplate<TTypeDefs[keyof TTypeDefs]["opts"]>>;
	/** Outputs */
	outputs: TGetBlueprintSchemaDefault<TOutputsSchema>;
	/** Node ID of model that renders the spec */
	__modelNodeId: number;
}

/**
 * Schema type
 */
export interface ISchemaFlowNode<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>
	extends IBlueprintSchema<
		ISchemaFlowNodeOpts<TTypeDefs>,
		ISchemaFlowNodeModel<TTypeDefs>,
		ISchemaFlowNodeSpec<TTypeDefs>,
		ISchemaFlowNodeDefault<TTypeDefs>
	> {
	/**
	 * Sets a node position in a flow chart
	 *
	 * @param modelNode Model node instance
	 * @param x X position
	 * @param y Y position
	 * @param notify If not emit change event(s)
	 */
	setPosition: (modelNode: ISchemaFlowNodeModel<TTypeDefs>, x: number, y: number, notify?: boolean) => void;

	/**
	 * Connects an output to another node
	 *
	 * @param modelNode Model node instance
	 * @param outputName Name of output to connect
	 * @param targetId ID of a target flow node
	 */
	connectOutput: (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		outputName: string,
		targetId: string,
		notify?: boolean
	) => void;

	/**
	 * Disconnects an output to another node
	 *
	 * @param modelNode Model node instance
	 * @param outputName Name of output to connect
	 * @param targetId ID of a target flow node
	 */
	disconnectOutput: (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		outputName: string,
		targetId: string,
		notify?: boolean
	) => void;

	/**
	 * Disconnects sa given node ID from all outputs if exist
	 *
	 * @param modelNode Model node instance
	 * @param targetId ID of target a flow node
	 */
	disconnectNode: (modelNode: ISchemaFlowNodeModel<TTypeDefs>, targetId: string, notify?: boolean) => void;

	/**
	 * Sets flow node execution result
	 */
	setExecutionResult: (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		result: ISchemaFlowNodeExecutionResult<TTypeDefs>,
		notify?: boolean
	) => void;

	/**
	 * Clears the flow node execution result
	 */
	clearExecutionResult: (modelNode: ISchemaFlowNodeModel<TTypeDefs>, notify?: boolean) => void;
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaFlowNode<TTypeDefs extends ISchemaFlowNodeTypeDefinitionMap>(
	opts: ISchemaFlowNodeOpts<TTypeDefs>
): ISchemaFlowNode<TTypeDefs> {
	type TVarNameModel = TGetBlueprintSchemaModel<TVarNameSchema>;
	type TPositionModel = TGetBlueprintSchemaModel<TPositionSchema>;
	type TOptsModel = TGetBlueprintSchemaModel<ISchemaScopedTemplate<TTypeDefs[keyof TTypeDefs]["opts"]>>;
	type TOutputsModel = TGetBlueprintSchemaModel<TOutputsSchema>;
	type TCommentModel = TGetBlueprintSchemaModel<ISchemaConstString>;
	type TTypeName = keyof TTypeDefs;

	const varNameSchema = SchemaConstString({
		label: "Variable name",
		description: "Function output will be stored in this variable.",
		constraints: {}
	});

	const commentSchema = SchemaConstString({
		label: "Comment",
		description: "User comment visible only in editor.",
		constraints: {
			required: false
		},
		default: '',
		fallbackValue: '',
		editorOptions: {
			controlType: "textarea"
		}
	});

	const defineOutputsSchema = (outputsDef: {
		[K: string]: ISchemaFlowNodeTypeOutputDefinition;
	}): TOutputsSchema => {
		const props = {};

		for (const k in outputsDef) {
			props[k] = Prop(
				SchemaConstArray({
					label: "Target nodes",
					description: "A list of nodes that will be executed after this output activates.",
					items: SchemaConstString({
						label: "Target node ID",
						constraints: {
							required: true,
							min: 1
						}
					}),
					constraints: {
						required: false
					}
				})
			);
		}

		return SchemaConstObject({
			label: "Outputs",
			props: props,
			constraints: {
				required: false
			}
		});
	};

	const defineOptsSchema = (optsSchema: TTypeDefs[keyof TTypeDefs]["opts"]) => {
		return SchemaScopedTemplate({
			label: "Options",
			template: optsSchema
		});
	};

	const positionSchema = SchemaConstObject({
		label: "Position",
		description: "Node position in a flow chart.",
		props: {
			x: Prop(
				SchemaConstFloat({
					label: "X coordinate",
					default: 0,
					fallbackValue: 0
				})
			),
			y: Prop(
				SchemaConstFloat({
					label: "Y coordinate",
					default: 0,
					fallbackValue: 0
				})
			)
		},
		constraints: {
			required: false
		}
	});

	const keyPropRules = {
		id: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING
		},
		varName: {
			required: false,
			provideCompletion: varNameSchema.provideCompletion
		},
		type: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = Object.keys(opts.nodeTypes).map((typeName) => ({
						kind: CMPL_ITEM_KIND.Class,
						label: typeName,
						insertText: typeName
					}));

					return items;
				});
			}
		},
		opts: {
			required: true
		},
		outputs: {
			required: false,
			idtType: BP_IDT_TYPE.MAP
		},
		position: {
			required: false,
			idtType: BP_IDT_TYPE.MAP,
			provideCompletion: positionSchema.provideCompletion
		},
		comment: {
			required: false,
			provideCompletion: commentSchema.provideCompletion
		}
	};

	const schema = createEmptySchema<ISchemaFlowNode<TTypeDefs>>("flowNode", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "varName", "opts", "outputs", "position" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		id: string,
		varNameModel: TVarNameModel,
		type: TTypeName,
		optsModel: TOptsModel,
		outputsModel: TOutputsModel,
		positionModel: TPositionModel,
		commentModel: TCommentModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode,
		parseInfo?: ISchemaFlowNodeParseInfo
	) => {
		const model = createModelNode(schema, dCtx, parent, validationErrors, {
			id: id,
			varName: varNameModel,
			type: type,
			opts: optsModel,
			outputs: outputsModel,
			position: positionModel,
			comment: commentModel,
			parseInfo: parseInfo,
			executionResult: null,
			executedEvent: createEventEmitter()
		});

		return assignParentToChildrenOf(model);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		if (!opts?.constraints?.required && !defaultValue) {
			return null;
		}

		if (!(defaultValue instanceof Object)) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to be an object."
			);
		}

		if (!defaultValue.id) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to have an 'id' property."
			);
		}

		if (!defaultValue.type) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to have a 'type' property."
			);
		}

		if (!opts.nodeTypes[defaultValue.type]) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Unknown node type '${String(defaultValue.type)}'.`
			);
		}

		const id = defaultValue.id;
		const type = defaultValue.type;
		const typeDef = opts.nodeTypes[type];

		const optsSchema = defineOptsSchema(typeDef.opts);
		const optsModel = optsSchema.createDefault(dCtx, null, defaultValue.opts);

		if (!optsModel.template) {
			optsModel.schema.destroy(optsModel);
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Property `opts` is not valid: failed to create opts model."
			);
		}

		const varNameModel = varNameSchema.createDefault(dCtx, null, defaultValue.varName);
		const outputsDef = typeDef.resolveOutputs(optsModel);
		const outputsSchema = defineOutputsSchema(outputsDef);
		const outputsModel = outputsSchema.createDefault(dCtx, null, defaultValue?.outputs);

		const positionModel = positionSchema.createDefault(dCtx, null, defaultValue?.position);
		const commentModel = commentSchema.createDefault(dCtx, null, defaultValue?.comment);

		return createModel(dCtx, id, varNameModel, type, optsModel, outputsModel, positionModel, commentModel, [], parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedVarName = modelNode.varName.schema.clone(dCtx, modelNode.varName, null);
		const clonedOpts = modelNode.opts.schema.clone(dCtx, modelNode.opts, null);
		const clonedOutputs = modelNode.outputs.schema.clone(dCtx, modelNode.outputs, null);
		const clonedPosition = modelNode.position.schema.clone(dCtx, modelNode.position, null);
		const clonedComment = modelNode.comment.schema.clone(dCtx, modelNode.comment, null);

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			id: modelNode.id,
			varName: clonedVarName,
			type: modelNode.type,
			opts: clonedOpts,
			outputs: clonedOutputs,
			position: clonedPosition,
			comment: clonedComment,
			executionResult: modelNode.executionResult,
			executedEvent: createEventEmitter()
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		modelNode.varName.schema.destroy(modelNode.varName);
		modelNode.opts.schema.destroy(modelNode.opts);
		modelNode.outputs.schema.destroy(modelNode.outputs);
		modelNode.position.schema.destroy(modelNode.position);
		modelNode.comment.schema.destroy(modelNode.comment);
		modelNode.executionResult = null;

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return null;
		}

		// Extract keys
		const { keys, keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		if (!keysValid.id || !keysValid.type) {
			return null;
		}

		const id = (keys["id"].value as IBlueprintIDTScalar).value as string;
		const type = (keys["type"].value as IBlueprintIDTScalar).value as string;
		const typeDef = opts.nodeTypes[type];

		if (!typeDef) {
			if (keys["type"].value.parseInfo) {
				dCtx.logParseError(keys["type"].value.parseInfo.loc.uri, {
					range: keys["type"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.ENUM_OUT_OF_RANGE,
					message: `Unknown node type '${type}'.`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:flowNode#errors.unknownType",
						args: {
							type: type
						}
					},
					parsePath: keys["type"].value.path
				});
			}

			return null;
		}

		const optsSchema = defineOptsSchema(typeDef.opts);

		provideIDTMapPropertyCompletions(
			dCtx,
			rootNode,
			{
				opts: keys.opts
			},
			{
				opts: optsSchema.provideCompletion
			}
		);

		if (!keysValid.opts || !keys["opts"] || !keys["opts"].value) {
			return null;
		}

		const optsModel = optsSchema.parse(dCtx, keys["opts"].value, null);

		const outputsDef = typeDef.resolveOutputs(optsModel);
		const outputsSchema = defineOutputsSchema(outputsDef);

		provideIDTMapPropertyCompletions(
			dCtx,
			rootNode,
			{
				outputs: keys.outputs
			},
			{
				outputs: outputsSchema.provideCompletion
			}
		);

		if (!optsModel.template) {
			optsModel.schema.destroy(optsModel);

			if (keys["opts"].value.parseInfo) {
				dCtx.logParseError(keys["opts"].value.parseInfo.loc.uri, {
					range: keys["opts"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_VALUE,
					message: `Invalid value.`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:flowNode#errors.invalidOpts"
					},
					parsePath: keys["opts"].value.path
				});
			}

			return null;
		}

		const varNameModel = (
			keys["varName"] && keys["varName"].value
				? varNameSchema.parse(dCtx, keys["varName"].value, null)
				: varNameSchema.createDefault(dCtx, null)
		) as TVarNameModel;

		const outputsModel =
			keys["outputs"] && keys["outputs"].value
				? outputsSchema.parse(dCtx, keys["outputs"].value, null)
				: outputsSchema.createDefault(dCtx, null);

		const positionModel =
			keys["position"] && keys["position"].value
				? positionSchema.parse(dCtx, keys["position"].value, null)
				: positionSchema.createDefault(dCtx, null);

		const commentModel =
			keys["comment"] && keys["comment"].value
				? commentSchema.parse(dCtx, keys["comment"].value, null)
				: commentSchema.createDefault(dCtx, null);

		const parseInfo: ISchemaFlowNodeParseInfo = {
			root: idtNode.parseInfo,
			id: keys["id"].value.parseInfo,
			outputs: {}
		};

		if (keysValid.outputs && keys["outputs"] && keys["outputs"].value) {
			const _outputs = keys["outputs"].value as IBlueprintIDTMap;

			for (let i = 0; i < _outputs.items.length; i++) {
				if (_outputs.items[i].value) {
					const _outputName = _outputs.items[i].key.value as string;
					parseInfo.outputs[_outputName] = [];

					const _targetList = _outputs.items[i].value as IBlueprintIDTList;

					for (let j = 0; j < _targetList.items.length; j++) {
						if (_targetList.items[j]) {
							parseInfo.outputs[_outputName].push(_targetList.items[j].parseInfo);
						}
					}
				}
			}
		}

		return createModel(
			dCtx,
			id,
			varNameModel,
			type,
			optsModel,
			outputsModel,
			positionModel,
			commentModel,
			[],
			parent,
			parseInfo
		);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// id
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[id]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{id}" ]),
						value: "id"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "id" ]),
						value: modelNode.id
					}
				} as IBlueprintIDTMapElement,
				// varName
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[varName]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{varName}" ]),
						value: "varName"
					} as IBlueprintIDTScalar,
					value: modelNode.varName.schema.serialize(modelNode.varName, path.concat([ "varName" ]))
				} as IBlueprintIDTMapElement,
				// type
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[type]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{type}" ]),
						value: "type"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "type" ]),
						value: modelNode.type
					}
				} as IBlueprintIDTMapElement,
				// opts
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[opts]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{opts}" ]),
						value: "opts"
					} as IBlueprintIDTScalar,
					value: modelNode.opts.schema.serialize(modelNode.opts, path.concat([ "opts" ]))
				} as IBlueprintIDTMapElement,
				// outputs
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[outputs]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{outputs}" ]),
						value: "outputs"
					} as IBlueprintIDTScalar,
					value: modelNode.outputs.schema.serialize(modelNode.outputs, path.concat([ "outputs" ]))
				} as IBlueprintIDTMapElement,
				// position
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[position]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{position}" ]),
						value: "position"
					} as IBlueprintIDTScalar,
					value: modelNode.position.schema.serialize(
						modelNode.position,
						path.concat([ "position" ])
					)
				} as IBlueprintIDTMapElement,
				// comment
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[comment]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{comment}" ]),
						value: "comment"
					} as IBlueprintIDTScalar,
					value: modelNode.comment.schema.serialize(modelNode.comment, path.concat([ "comment" ]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const varName = modelNode.varName.schema.render(
			rCtx,
			modelNode.varName,
			path.concat([ "varName" ]),
			scope,
			prevSpec?.varName
		);
		const opts = modelNode.opts.schema.render(
			rCtx,
			modelNode.opts,
			path.concat([ "opts" ]),
			scope,
			prevSpec?.opts
		);
		const outputs = modelNode.outputs.schema.render(
			rCtx,
			modelNode.outputs,
			path.concat([ "outputs" ]),
			scope,
			prevSpec?.outputs
		);
		const position = modelNode.position.schema.render(
			rCtx,
			modelNode.position,
			path.concat([ "position" ]),
			scope,
			prevSpec?.position
		);

		return {
			id: modelNode.id,
			varName: varName,
			type: modelNode.type,
			opts: opts,
			outputs: outputs,
			position: position,
			__modelNodeId: modelNode.nodeId
		};
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate params resolve state
		if (!modelNode.isValid) {
			cCtx.logValidationErrors(
				path,
				modelNode.nodeId,
				DOC_ERROR_SEVERITY.ERROR,
				modelNode.validationErrors
			);
		}

		const varNameCmp = modelNode.varName.schema.compileRender(
			cCtx,
			modelNode.varName,
			path.concat("varName")
		);
		const optsCmp = modelNode.opts.schema.compileRender(cCtx, modelNode.opts, path.concat("opts"));
		const outputsCmp = modelNode.outputs.schema.compileRender(
			cCtx,
			modelNode.outputs,
			path.concat("outputs")
		);
		const positionCmp = modelNode.position.schema.compileRender(
			cCtx,
			modelNode.position,
			path.concat("position")
		);

		const varNameCode = applyCodeArg(
			varNameCmp,
			`typeof pv==="object"&&pv!==null?pv.varName:undefined`,
			`pt.concat(["varName"])`
		);
		const optsCode = applyCodeArg(
			optsCmp,
			`typeof pv==="object"&&pv!==null?pv.opts:undefined`,
			`pt.concat(["opts"])`
		);
		const outputsCode = applyCodeArg(
			outputsCmp,
			`typeof pv==="object"&&pv!==null?pv.outputs:undefined`,
			`pt.concat(["outputs"])`
		);
		const positionCode = applyCodeArg(
			positionCmp,
			`typeof pv==="object"&&pv!==null?pv.position:undefined`,
			`pt.concat(["position"])`
		);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({${[
				`id:"${escapeString(modelNode.id)}"`,
				`varName:${varNameCode}`,
				`type:"${escapeString(modelNode.type as string)}"`,
				`opts:${optsCode}`,
				`outputs:${outputsCode}`,
				`position:${positionCode}`,
				`__modelNodeId:${inlineValue(modelNode.nodeId)}`
			].join(",")}})`
		};
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = (): ISchemaImportExport => {
		// Cannot be exported because nodeTypes cannot be exported
		throw new Error("FlowNode schema does not support export.");
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				id: TypeDescString({
					label: "Node ID"
				}),
				varName: modelNode.varName.schema.getTypeDescriptor(modelNode.varName),
				type: TypeDescString({
					label: "Node type"
				}),
				opts: modelNode.opts.schema.getTypeDescriptor(modelNode.opts),
				outputs: modelNode.outputs.schema.getTypeDescriptor(modelNode.outputs),
				position: modelNode.position.schema.getTypeDescriptor(modelNode.position)
			},
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.setPosition = (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		x: number,
		y: number,
		notify?: boolean
	): void => {
		modelNode.position.props.x.schema.setValue(modelNode.position.props.x, x, notify);
		modelNode.position.props.y.schema.setValue(modelNode.position.props.y, y, notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}
	};

	schema.connectOutput = (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		outputName: string,
		targetId: string,
		notify?: boolean
	): void => {
		if (!modelNode.outputs.props[outputName]) {
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Cannot connect output: output '${outputName}' does not exist.`
			);
		}

		const listNode = modelNode.outputs.props[outputName];
		const itemExists = listNode.items.filter((item) => item.value === targetId).length > 0;

		if (itemExists) {
			// eslint-disable-next-line max-len
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Cannot connect output: target '${targetId}' is already connected.`
			);
		}

		listNode.schema.addElement(listNode, targetId, notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.disconnectOutput = (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		outputName: string,
		targetId: string,
		notify?: boolean
	): void => {
		if (!modelNode.outputs.props[outputName]) {
			// eslint-disable-next-line max-len
			throw new ModelNodeManipulationError(
				schema.name,
				schema.opts,
				`Cannot disconnect output: output '${outputName}' does not exist.`
			);
		}

		const listNode = modelNode.outputs.props[outputName];
		let itemIndex = null;

		for (let i = 0; i < listNode.items.length; i++) {
			if (listNode.items[i].value === targetId) {
				itemIndex = i;
				break;
			}
		}

		// Skip if target is not connected anymore
		if (itemIndex === null) {
			return;
		}

		listNode.schema.removeElement(listNode, itemIndex, notify);

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.disconnectNode = (
		modelNode: ISchemaFlowNodeModel<TTypeDefs>,
		targetId: string,
		notify?: boolean
	): void => {
		for (const k in modelNode.outputs.props) {
			const targetList = modelNode.outputs.props[k];

			for (let i = 0; i < targetList.items.length; ) {
				if (targetList.items[i].value === targetId) {
					targetList.schema.removeElement(targetList, i, notify);
				} else {
					i++;
				}
			}
		}

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.setExecutionResult = (modelNode, result, notify) => {
		modelNode.executionResult = result;

		if (notify) {
			const eventData = {
				modelNode: modelNode,
				changeType: MODEL_CHANGE_TYPE.RUNTIME_INFO
			};

			emitEvent(modelNode.changeEvent, eventData);
			emitEvent(modelNode.executedEvent, result);
		}
	};

	schema.clearExecutionResult = (modelNode, notify) => {
		modelNode.executionResult = null;

		if (notify) {
			const eventData = {
				modelNode: modelNode,
				changeType: MODEL_CHANGE_TYPE.RUNTIME_INFO
			};

			emitEvent(modelNode.changeEvent, eventData);
			emitEvent(modelNode.executedEvent, null);
		}
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "varName",
				node: modelNode.varName
			},
			{
				key: "position",
				node: modelNode.position
			},
			{
				key: "opts",
				node: modelNode.opts
			},
			{
				key: "outputs",
				node: modelNode.outputs
			},
			{
				key: "comment",
				node: modelNode.comment
			}
		];
	};

	return schema;
}
