/**
 * hae-lib-core
 *
 * Hexio App Engine library for shared Core code.
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ERROR_NAMES } from "../errors/ErrorNames";

/**
 * Web Server error body object
 */
export interface IWebServerErrorBody {
	status: number;
	name: string;
	type: string;
	title: string;
	detail?: string;
	instance?: string;
	reqId?: string;
	traceId?: string;
	date: Date;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}

/**
 * Web Server error base class
 */
export class WebServerError extends Error {
	/** HTTP status code */
	public status: number;

	/** Error name */
	public name: ERROR_NAMES;

	/** Type reference (see rfc7807) */
	public type: string;

	/** Title (see rfc7807) */
	public title: string;

	/** Detail message (see rfc7807) */
	public detail: string;

	/** Instance (see rfc7807) */
	public instance: string;

	/** Web server request ID */
	public reqId: string;

	/** Trace ID */
	public traceId: string;

	/** Date and time when error ocurred */
	public date: Date;

	/** Additional properties - will be merged to the body object */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public additionalProps: Record<string, any>;

	/**
	 * Error constructor
	 *
	 * @param status HTTP status code
	 * @param name Error name
	 * @param type Type reference (see rfc7807)
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	public constructor(
		status: number,
		name: ERROR_NAMES,
		type: string,
		title: string,
		detail?: string,
		instance?: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		additionalProps?: Record<string, any>
	) {
		super(`${name}(${status}): ${title}`);

		this.status = status;
		this.name = name;
		this.type = type;
		this.title = title;
		this.detail = detail;
		this.instance = instance;
		this.additionalProps = additionalProps;

		this.date = new Date();
	}

	/**
	 * Converts error to a http body object which can be safely serialized to JSON.
	 *
	 * @param sensitiveLog Enable sensitive logs.
	 * @returns
	 */
	public toBody(sensitiveLog = false): IWebServerErrorBody {
		if (sensitiveLog === true) {
			/** Return all available details. */
			const body: IWebServerErrorBody = {
				...this.additionalProps,
				status: this.status,
				name: this.name,
				type: this.type,
				title: this.title,
				date: this.date,
				reqId: this.reqId,
				traceId: this.traceId
			};

			if (this.detail) {
				body.detail = this.detail;
			}

			if (this.instance) {
				body.instance = this.instance;
			}

			return body;
		}

		/** Return no error details. */
		return {
			date: this.date,
			status: this.status,
			name: "Error",
			type: this.type,
			reqId: this.reqId,
			traceId: this.traceId,
			title: this.name
		};
	}
}

/**
 * Error: Bad Request
 */
export class BadRequestError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			400,
			ERROR_NAMES.HTTP_BAD_REQUEST,
			"#badRequestError", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Unauthorized
 */
export class UnauthorizedError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			401,
			ERROR_NAMES.HTTP_UNAUTHORIZED,
			"#unauthorized", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Forbidden
 */
export class ForbiddenError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			403,
			ERROR_NAMES.HTTP_FORBIDDEN,
			"#forbidden", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Permissions
 */
export class PermissionError extends ForbiddenError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(detail?: string, instance?: string, additionalProps?: Record<string, any>) {
		super("Missing permissions", detail, instance, additionalProps);
	}
}

/**
 * Error: Not Found
 */
export class NotFoundError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			404,
			ERROR_NAMES.HTTP_NOT_FOUND,
			"#notFound", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Method Not Allowed
 */
export class MethodNotAllowedError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			405,
			ERROR_NAMES.HTTP_METHOD_NOT_ALLOWED,
			"#methodNotAllowed", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Conflict
 */
export class ConflictError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			409,
			ERROR_NAMES.HTTP_CONFLICT,
			"#conflict", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Unprocessable Entity
 */
export class UnprocessableEntityError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			422,
			ERROR_NAMES.HTTP_UNPROCESSABLE_ENTITY,
			"#unprocessableEntity", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Internal Server Error
 */
export class InternalServerError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			500,
			ERROR_NAMES.HTTP_INTERNAL_SERVER_ERROR,
			"#internalServerError", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Not Implemented
 */
export class NotImplementedError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			501,
			ERROR_NAMES.HTTP_NOT_IMPLEMENTED,
			"#notImplemented", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Service Unavailable
 */
export class ServiceUnavailableError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			503,
			ERROR_NAMES.HTTP_SERVICE_UNAVAILABLE,
			"#serviceUnavailable", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Payload Too Large
 */
export class PayloadTooLargeError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			413,
			ERROR_NAMES.HTTP_PAYLOAD_TOO_LARGE,
			"#payload-too-large", // @todo add absolute URL to docs
			title,
			detail,
			instance,
			additionalProps
		);
	}
}

/**
 * Error: Unsupported MediaType error
 */
export class UnsupportedMediaTypeError extends WebServerError {
	/**
	 * Error constructor
	 *
	 * @param title Title (see rfc7807)
	 * @param detail Detail message (see rfc7807)
	 * @param instance Instance (see rfc7807)
	 * @param additionalProps Additional properties - will be merged to the body object
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(
		title: string,
		detail?: string,
		instance?: string,
		additionalProps?: Record<string, any>
	) {
		super(
			415,
			ERROR_NAMES.HTTP_UNSUPPORTED_MEDIA_TYPE,
			"#unsupported-media-type",
			title,
			detail,
			instance,
			additionalProps
		);
	}
}
