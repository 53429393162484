/**
 * Data Table HAE component definition
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { Type, defineElementaryComponent, createSubScope, BP } from "@hexio_io/hae-lib-blueprint";

import {
	getTotalPages,
	resolveBooleanFromString,
	SORT_ORDER,
	termsEditor as HAELibComponentsTerms
} from "@hexio_io/hae-lib-components";

import {
	isDeepEqual,
	isFunction,
	isNonEmptyObjectWithValidValues,
	isString,
	isUndefined,
	isValidObject,
	isValidValue,
	toNumber
} from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";

import { HAEComponentDataTable_Props } from "./props";
import {
	DATA_TABLE_RESOLVED_ITEM_STATE,
	HAEComponentDataTable_State,
	IPagination,
	IResolvedItem,
	ISort,
	TResolvedItemData,
	TResolvedItemValue
} from "./state";
import { HAEComponentDataTable_Events } from "./events";
import { sortItems } from "../Table/sortItems";
import { TTableItemIds } from "../Table/types";
import { resolveFormattedValue } from "./resolveFormattedValue";

export function mapResolvedItemValues(item: IResolvedItem): { [K: string]: TResolvedItemValue } {
	const result: Record<string, TResolvedItemValue> = {};

	Object.entries(item.data).forEach(([ key, value ]) => {
		result[key] = value.value;
	});

	return result;
}

export const HAEComponentDataTable_Definition = defineElementaryComponent<
	typeof HAEComponentDataTable_Props,
	HAEComponentDataTable_State,
	typeof HAEComponentDataTable_Events
>({
	...termsEditor.components.dataTable.component,

	name: "dataTable",

	category: "content",

	icon: "mdi/table",

	docUrl: "...",

	order: 80,

	props: HAEComponentDataTable_Props,

	events: HAEComponentDataTable_Events,

	resolve: (spec, state, updateStateAsync) => {
		const columns = Array.isArray(spec.columns) ? spec.columns : [];

		// Items

		const items: unknown[] = Array.isArray(spec.items)
			? spec.items
			: Array.isArray(state?.items)
			? state.items
			: [];
		const stateResolvedItems = Array.isArray(state?.resolvedItems) ? state.resolvedItems : [];

		const resolvedItems = items.map<IResolvedItem>((item, index) => {
			const id = `${index}`;

			const stateResolvedItem: IResolvedItem = stateResolvedItems.find((item) => {
				return item.id === id && item.state !== DATA_TABLE_RESOLVED_ITEM_STATE.UNEDITED;
			});

			let data: TResolvedItemData;
			let state = DATA_TABLE_RESOLVED_ITEM_STATE.UNEDITED;

			if (stateResolvedItem) {
				data = stateResolvedItem.data;
				state = stateResolvedItem.state;

				columns.forEach((columnItem) => {
					data[columnItem.key].formattedValue = resolveFormattedValue(
						data[columnItem.key].value,
						columnItem.formatter
					);
				});
			} else {
				data = {};

				columns.forEach((columnItem) => {
					let value: unknown;

					if (columnItem.mapper) {
						value = columnItem.mapper((parentScope) => {
							return createSubScope(
								parentScope,
								{ item, index },
								{ item: Type.Any({}), index: Type.Integer({}) }
							);
						});
					} else {
						value = isValidObject(item) ? item[columnItem.key] : item;
					}

					const { type } = columnItem.typeData;

					switch (type) {
						case "NUMBER": {
							switch (typeof value) {
								case "number": // No need for any action
									break;

								case "string":
									value = toNumber(value); // !Number.isNaN(valueNumber) ? valueNumber : null;
									break;

								case "boolean":
									value = value ? 1 : 0;
									break;

								default:
									value = NaN;
							}

							break;
						}

						case "BOOLEAN": {
							const typeDataValue = columnItem.typeData.value[type];

							switch (typeof value) {
								case "boolean": // No need for any action
									break;

								case "string":
									value = resolveBooleanFromString(
										value,
										typeDataValue.specificStrings,
										typeDataValue.emptyString,
										typeDataValue.miscString
									);
									break;

								default:
									value = !!value;
							}

							break;
						}

						case "STRING": {
							if (!isString(value)) {
								value = `${value}`;
							}
						}
					}

					switch (typeof value) {
						case "string":
						case "number":
						case "boolean":
							data[columnItem.key] = { value };
							break;

						default:
							data[columnItem.key] = {
								value:
									isValidValue(value) && isFunction(value.toString) ? value.toString() : ""
							};
					}

					data[columnItem.key].formattedValue = resolveFormattedValue(
						data[columnItem.key].value,
						columnItem.formatter
					);
				});
			}

			return {
				id,
				data,
				originalData: item,
				state
			};
		});

		const resolvedItemsLength = resolvedItems.length;

		// Sort & order

		const specSort: ISort = spec.sort;
		const sort: ISort =
			((isDeepEqual(state?.specSort, specSort) ||
				(specSort !== null && !isNonEmptyObjectWithValidValues(specSort, 1))) &&
				state?.sort) ||
			specSort;

		let order: TTableItemIds;

		if (sort && sort.client && resolvedItemsLength > 1) {
			if (sort.key) {
				order = sortItems<IResolvedItem>(
					[ ...resolvedItems ],
					(item) => {
						return isValidObject(item.data) && !isUndefined(item.data[sort.key])
							? item.data[sort.key].value
							: item.data;
					},
					SORT_ORDER[sort.order]
				).map((item) => item.id);
			} else if (sort.key === "" && state?.order) {
				// When some record has been edited, set sort key to empty string
				order = state.order;
			}
		}

		if (!order) {
			order = resolvedItems.map((item) => item.id);
		}

		// Pagination, total items, set page method

		const specPagination: IPagination = spec.pagination;
		const pagination: IPagination =
			((isDeepEqual(state?.specPagination, specPagination) ||
				(specPagination !== null && !isNonEmptyObjectWithValidValues(specPagination, 1))) &&
				state?.pagination) ||
			specPagination;

		const totalItems =
			(pagination && pagination.typeData.type === "SERVER"
				? pagination.typeData.value[pagination.typeData.type].items
				: resolvedItemsLength) || 0;

		let setPage = null;

		if (pagination) {
			if (
				!Number.isFinite(pagination.page) ||
				pagination.page > getTotalPages(totalItems, pagination.limit)
			) {
				pagination.page = 1;
			}

			setPage = (page: number) => {
				updateStateAsync((prevState) => ({
					...prevState,
					pagination: {
						...prevState.pagination,
						page
					}
				}));
			};
		}

		// Page order

		const pageOrder =
			pagination && pagination.typeData.type === "CLIENT"
				? order.slice(
						(pagination.page - 1) * pagination.limit,
						Math.min(pagination.page * pagination.limit, resolvedItemsLength)
				  )
				: order;

		return {
			items,
			resolvedItems,
			totalItems,
			order,
			pageOrder,
			pagination,
			specPagination,
			sort,
			specSort,
			setPage
		};
	},

	getScopeData: (spec, state) => {
		return {
			items: spec.items,
			rows: state.resolvedItems.map((item) => mapResolvedItemValues(item)),
			pagination: state.pagination,
			sort: state.sort,
			setPage: state.setPage
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				items: props.props.items.schema.getTypeDescriptor(props.props.items),
				rows: Type.Any({
					...termsEditor.schemas.table.rows
					//items: Type.Any({})
				}),
				pagination: props.props.pagination.schema.getTypeDescriptor(props.props.pagination),
				sort: props.props.sort.schema.getTypeDescriptor(props.props.sort),
				setPage: Type.Method({
					...HAELibComponentsTerms.schemas.pagination.setPage,
					argRequiredCount: 1,
					argSchemas: [ BP.Integer({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});
