/**
 * Item Component schema
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, MEDIA_RESOLUTIONS_string, TSchemaConstObjectPropsSpec } from "@hexio_io/hae-lib-blueprint";

import { ClassList } from "../Classes/ClassList";
import { StyleSheet } from "../Classes/StyleSheet";

import { SPACING } from "../Enums/SPACING";
import { getValuesFromStringEnum, getStringEnumCssValue, getStringEnumValue } from "../Functions/enumHelpers";
import { termsEditor } from "../terms";
import { spacingPattern } from "../RegExp/spacingPattern";
import { mapResponsiveValue } from "../Functions/responsiveValueHelpers";
import { dimensionPattern } from "../RegExp/dimensionPattern";

/**
 * Item Inherited props
 */
export const ItemInheritedProps = {
	itemSpacing: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.item.spacing,
			constants: getValuesFromStringEnum(SPACING, termsEditor.schemas.common.spacingValues),
			default: "", // Has to stay empty
			hidden: true, // hidden because it's confusing
			example: "MEDIUM; 100px; 10px 15px 0px 5px; 50%",
			constraints: {
				...termsEditor.schemas.common.spacingConstraints,
				pattern: spacingPattern
			}
		}),
		10
	),

	itemWidth: BP.Prop(
		BP.ResponsiveValue({
			...termsEditor.schemas.common.width,
			value: BP.String({
				default: "",
				example: "10px; 50%",
				constraints: {
					...termsEditor.schemas.common.dimensionConstraints,
					pattern: dimensionPattern
				}
			})
		}),
		20
	),

	itemHeight: BP.Prop(
		BP.ResponsiveValue({
			...termsEditor.schemas.common.height,
			value: BP.String({
				default: "",
				example: "10px; 50%",
				constraints: {
					...termsEditor.schemas.common.dimensionConstraints,
					pattern: dimensionPattern
				}
			})
		}),
		30
	)
};

export type TItemInheritedProps = Partial<TSchemaConstObjectPropsSpec<typeof ItemInheritedProps>>;

/**
 * Creates item's style
 *
 * @param selector Item selector
 * @param props Item (inherited) properties
 * @param media Media
 */
export function createItemStyle(
	selector: string,
	props: TItemInheritedProps,
	media?: MEDIA_RESOLUTIONS_string
): { classList: ClassList; styleSheet: StyleSheet } {
	const classList = new ClassList("item");
	const styleSheet = new StyleSheet();

	const componentSelector = `${selector} > .item__component`;

	// Item spacing

	if (props.itemSpacing) {
		const itemSpacingValue = getStringEnumCssValue(SPACING, props.itemSpacing, "spacing-");

		styleSheet.addString(selector, `--element-spacing: ${itemSpacingValue};`);
	}

	// Item width

	const itemWidthValue = mapResponsiveValue(props.itemWidth);

	classList.addModifiers({ "auto-width": !itemWidthValue.length });

	styleSheet.addResponsiveValue(
		itemWidthValue,
		selector,
		(width: string) => {
			if (width.endsWith("%")) {
				return `width: ${width};`;
			}
		},
		media
	);

	styleSheet.addResponsiveValue(
		itemWidthValue,
		componentSelector,
		(width: string, mediaQuery) => {
			if (!width.endsWith("%")) {
				return `width: ${width};`;
			} else if (mediaQuery) {
				return "width: 100%;";
			}
		},
		media
	);

	// Item height

	const itemHeightValue = mapResponsiveValue(props.itemHeight);

	styleSheet.addResponsiveValue(
		itemHeightValue,
		selector,
		(height: string) => {
			if (!height.endsWith("%")) {
				return `--element-layout-height: ${height};`;
			} else {
				return `height: ${height};`;
			}
		},
		media
	);

	styleSheet.addResponsiveValue(
		itemHeightValue,
		componentSelector,
		(height: string, mediaQuery) => {
			if (!height.endsWith("%")) {
				return `height: ${height};`;
			} else if (mediaQuery) {
				return "height: 100%;";
			}
		},
		media
	);

	return {
		classList,
		styleSheet
	};
}
