/**
 * Text schema terms
 *
 * @package hae-lib-components
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const text = {
	schema: {
		label: "schema:text#label"
	},
	value: {
		label: "schema:text.value#label",
		description: "schema:text.value#description"
	},
	align: {
		label: "schema:text.align#label"
	},
	alignValues: {
		default: {
			label: "schema:text.align#values.default#label"
		},
		left: {
			label: "schema:text.align#values.left#label"
		},
		center: {
			label: "schema:text.align#values.center#label"
		},
		right: {
			label: "schema:text.align#values.right#label"
		},
		block: {
			label: "schema:text.align#values.block#label"
		}
	},

	overflow: {
		label: "schema:text.overflow#label"
	},
	overflowValues: {
		none: {
			label: "schema:text.overflow#values.none#label"
		},
		word: {
			label: "schema:text.overflow#values.word#label"
		},
		line: {
			label: "schema:text.overflow#values.line#label"
		},
		ellipsis: {
			label: "schema:text.overflow#values.ellipsis#label"
		}
	},

	fontSize: {
		label: "schema:text.fontSize#label"
	},
	fontSizeValues: {
		base: {
			label: "schema:text.fontSize#values.base#label"
		},
		xsmall: {
			label: "schema:text.fontSize#values.xsmall#label"
		},
		small: {
			label: "schema:text.fontSize#values.small#label"
		},
		medium: {
			label: "schema:text.fontSize#values.medium#label"
		},
		large: {
			label: "schema:text.fontSize#values.large#label"
		},
		xlarge: {
			label: "schema:text.fontSize#values.xlarge#label"
		},
		xxlarge: {
			label: "schema:text.fontSize#values.xxlarge#label"
		},
		xxxlarge: {
			label: "schema:text.fontSize#values.xxxlarge#label"
		}
	},
	fontSizeConstraints: {
		patternErrorMessage: "schema:text.fontSize#constraints.patternError#message"
	},
	style: {
		label: "schema:text.style#label",
		description: "schema:text.style#description"
	},
	bold: {
		label: "schema:text.bold#label"
	},
	italic: {
		label: "schema:text.italic#label"
	},
	underline: {
		label: "schema:text.underline#label"
	},
	monospace: {
		label: "schema:text.monospace#label"
	},
	markdown: {
		label: "schema:text.markdown#label",
		description: "schema:text.markdown#description"
	},
	markdownBlocks: {
		label: "schema:text.markdown#props.blocks#label",
		description: "schema:text.markdown#props.blocks#description"
	},
	html: {
		label: "schema:text.html#label",
		description: "schema:text.html#description"
	}
};
