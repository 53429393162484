/**
 * Form component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
	RUNTIME_CONTEXT_MODE,
	ISchemaComponentListSpec,
	IScope,
	createSubScope,
	ISchemaConstObjectModel,
	cmpDataEqual,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	SCHEMA_VALUE_TYPE,
} from "@hexio_io/hae-lib-blueprint";
import {
	addFormItem,
	ClassList,
	FormContext,
	FORM_CONTEXT_PROP_NAME,
	HAEComponentList,
	propGroups,
	removeFormItem,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";

interface HAEComponentForm_State {
	formContext: FormContext;
	content: ISchemaComponentListSpec;
	childScope: IScope;
	clearValue: (initial: boolean) => void;
}

interface TFormState {
	value: Record<string, unknown>;
	valid: boolean;
	changed: boolean;
}

const HAEComponentForm_Props = {
	contentTemplate: BP.Prop(
		BP.ScopedTemplate({
			...termsEditor.components.form.schema.contentTemplate,
			template: BP.ComponentList({})
		})
	),
	additionalItems: BP.Prop(
		BP.Array(({
			...termsEditor.components.form.schema.additionalItems,
			items: BP.Object({
				...termsEditor.components.form.schema.additionalItemObject,
				props: {
					name: BP.Prop(
						BP.String({
							...termsEditor.components.form.schema.additionalItemName,
							default: "itemName",
							fallbackValue: "",
							constraints: {
								required: true
							}
						})
					),
					value: BP.Prop(
						BP.Any({
							...termsEditor.components.form.schema.additionalItemValue,
							defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
						})
					),
					valid: BP.Prop(
						BP.Boolean({
							...termsEditor.components.form.schema.additionalItemValid,
							default: true,
							fallbackValue: false,
							constraints: {
								required: true
							}
						})
					),
					changed: BP.Prop(
						BP.Boolean({
							...termsEditor.components.form.schema.additionalItemChanged,
							default: false,
							fallbackValue: false,
							constraints: {
								required: true
							}
						})
					)
				},
				constraints: {
					required: true
				}
			}),
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.constant.value,
					icon: "mdi/help-rhombus-outline"
				};
			}
		}))
	),

	fieldName: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.name,
			default: null,
			fallbackValue: null,
			constraints: {
				required: false
			}
		}),
		90,
		propGroups.state
	),
};

const HAEComponentForm_Events = {
	change: {
		...termsEditor.components.form.schema.events.change,
		icon: "mdi/pencil"
	},
	clearValue: {
		...termsEditor.components.form.schema.events.clearValue,
		icon: "mdi/restore-alert"
	}
};

const HAEComponentForm_Definition = defineElementaryComponent<
	typeof HAEComponentForm_Props,
	HAEComponentForm_State,
	typeof HAEComponentForm_Events
>({
	name: "form",
	category: "logic",
	label: termsEditor.components.form.component.label,
	description: termsEditor.components.form.component.description,
	icon: "mdi/format-list-text",
	docUrl: "...",
	order: 50,
	nonVisual: true,
	props: HAEComponentForm_Props,
	events: HAEComponentForm_Events,

	resolve: (spec, state, _updateStateAsync, componentInstance, rCtx, scope) => {
		const formContext: FormContext = state?.formContext
			? state.formContext
			: new FormContext();
		
		let childScope: IScope;
		const prevChildScope = scope.childScopes.get(componentInstance.uid);

		const content = spec.contentTemplate((currScope) => {
			childScope = createSubScope(
				currScope,
				{
					[FORM_CONTEXT_PROP_NAME]: formContext
				},
				{
					[FORM_CONTEXT_PROP_NAME]: Type.Void({})
				},
				prevChildScope
			);

			return childScope;
		}, "content");

		scope.childScopes.set(componentInstance.uid, childScope);

		rCtx.__callAfterReconcile(() => {
			const formCtx = componentInstance.state.formContext;

			const formState: TFormState = {
				value: formCtx.getValues(),
				valid: formCtx.isValid(),
				changed: formCtx.isChanged()
			};

			if (!cmpDataEqual(formState, componentInstance.customData.lastFormState)) {
				const isFirstRun = componentInstance.customData.lastFormState === undefined;
				componentInstance.customData.lastFormState = formState;
				
				if (!isFirstRun && componentInstance.eventEnabled.change) {
					componentInstance.eventTriggers.change((parentScope) => createSubScope(parentScope, {
						formData: formState
					}, {
						formData: Type.Object({
							props: {
								value: Type.Any({}),
								valid: Type.Boolean({}),
								changed: Type.Boolean({})
							}
						})
					}));
				}
			}
		});

		const clearValue = (initial = false) => {
			formContext.clearValues(initial);

			if (componentInstance.eventEnabled.clearValue) {
				componentInstance.eventTriggers.clearValue((parentScope) => createSubScope(parentScope, {
					_initial: initial
				}, {
					_initial: Type.Boolean({})
				}));
			}
		};

		// Remove redundant additional items
		if (componentInstance.customData.lastAdditionalItemsCount !== spec.additionalItems.length) {
			for (let i = spec.additionalItems.length; i < componentInstance.customData.lastAdditionalItemsCount; i++) {
				formContext.removeItem("additional-item-" + i);
			}

			componentInstance.customData.lastAdditionalItemsCount = spec.additionalItems.length;
		}

		// Add additional items
		if (spec.additionalItems) {
			spec.additionalItems.forEach((item, index) => {
				formContext.addItem({
					uid: "additional-item-" + index,
					name: item.name,
					value: item.value,
					valid: item.valid,
					changed: item.changed,
					clearValue: () => { /* Do nothing */ }
				});
			});
		}

		// Try to add form to the parent scope to allow form nesting
		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: formContext.getValues(),
			valid: formContext.isValid(),
			changed: formContext.isChanged(),
			clearValue: clearValue
		});

		return {
			content: content,
			formContext: formContext,
			childScope: childScope,
			clearValue: clearValue
		}
	},

	getInstanceList: (_spec, state, _prevInstanceList, cmpInstance, rCtx) => {
		if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
			return [ cmpInstance ];
		}

		if (state.content) {
			state.content.forEach((cmp) => {
				cmp.isTemplated = false;
				cmp.inheritedProps = cmpInstance.inheritedProps;
				cmp.originUid = cmpInstance.uid;
			});
		}

		return state.content;
	},

	getScopeData: (_spec, state) => {
		return {
			content: state.childScope.localData,
			value: state.formContext.getValues(),
			valid: state.formContext.isValid(),
			changed: state.formContext.isChanged(),
			clearValue: state.clearValue
		};
	},

	getScopeType: (_spec, state) => {
		return Type.Object({
			props: {
				content: state.childScope.localType,
				value: Type.Any({}),
				valid: Type.Boolean({}),
				changed: Type.Boolean({}),
				clearValue: Type.Method({
					...termsEditor.components.form.typeDesc.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentForm_React: THAEComponentReact<typeof HAEComponentForm_Definition> = ({
	state,
	reactComponentClassList,
	componentInstance
}) => {
	const { safePath: componentPath, componentMode } = componentInstance;

	const propsModel = componentInstance.modelNode?.props as ISchemaConstObjectModel<
		typeof HAEComponentForm_Props
	>;

	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-repeat", componentPath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	return (
		<div className={classList.toClassName()}>
			<HAEComponentList
				components={state.content}
				componentPath={[ ...componentPath, "component-list" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-form__content")}
				childClassList={new ClassList("cmp-form__item", "item")}
				childComponentClassList={new ClassList("item__component")}
				modelNode={propsModel?.props.contentTemplate.template}
				modifyModelOnDrop={() => null}
				dropZoneMode={DROP_ZONE_MODE.SINGLE}
			/>
		</div>
	);
};

export const HAEComponentForm: THAEComponentDefinition<typeof HAEComponentForm_Definition> = {
	...HAEComponentForm_Definition,
	reactComponent: HAEComponentForm_React
};
