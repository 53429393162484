/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { TGenericModelNode } from "../Schema/IModelNode";

/**
 * Runtime context
 */
export class RuntimeValidationError extends Error {
	public modelNode: TGenericModelNode;
	public validationErrors: IBlueprintSchemaValidationError[];

	public constructor(modelNode: TGenericModelNode, errors: IBlueprintSchemaValidationError[]) {
		super(`Validation error:\n${errors.map((e) => "- " + e.message).join("\n")}`);

		this.modelNode = modelNode;
		this.validationErrors = errors;
	}
}
