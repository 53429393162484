/**
 * Composed Chart HAE component Create Ranges
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { IRoundedRectangleSettings } from "@amcharts/amcharts5/.internal/core/render/RoundedRectangle";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";
import { ILabelSettings } from "@amcharts/amcharts5/.internal/core/render/Label";
import { IButtonSettings } from "@amcharts/amcharts5/.internal/core/render/Button";

import { IScope } from "@hexio_io/hae-lib-blueprint";

import { getTimestamp, isFunction, toNumber } from "@hexio_io/hae-lib-shared";

import { COMPOSED_CHART_AXIS_TYPE } from "../../Enums/COMPOSED_CHART_AXIS_TYPE";

import { TChartAxes, TAxisDataItem } from "./types";
import { noPaddingSettings, rangeLabelBaseSettings, rangeBaseSettings } from "./settings";

import { TResolvedRanges } from "./state";
import { IColors } from "../../types/charts";
import { getChartColor } from "../../Functions/chartHelpers";

/**
 * Creates range resize button
 */
function createRangeResizeButton(
	root: am5.Root,
	chart: am5xy.XYChart,
	axis: am5xy.ValueAxis<AxisRenderer> & am5xy.DateAxis<AxisRenderer>,
	horizontal: boolean,
	opposite: boolean,
	labelSettings: ILabelSettings,
	backgroundSettings: IButtonSettings,
	onValueChange: (value: number) => void
): am5.Button {
	const buttonSettings: IButtonSettings = {
		themeTags: [ "resize" ],
		label: am5.Label.new(root, {
			...rangeLabelBaseSettings,
			...labelSettings
		}),
		background: am5.RoundedRectangle.new(root, {
			...backgroundSettings
		})
	};

	if (horizontal) {
		buttonSettings.centerY = opposite ? am5.p100 : am5.p0;
		buttonSettings.cursorOverStyle = "ew-resize";
	} else {
		buttonSettings.centerX = opposite ? am5.p0 : am5.p100;
		buttonSettings.cursorOverStyle = "ns-resize";
	}

	const result = am5.Button.new(root, {
		...noPaddingSettings,
		...buttonSettings
	});

	if (horizontal) {
		result.adapters.add("x", (x) => Math.max(0, Math.min(chart.plotContainer.width(), x as number)));
		result.adapters.add("y", () => 0);
	} else {
		result.adapters.add("x", () => 0);
		result.adapters.add("y", (y) => Math.max(0, Math.min(chart.plotContainer.height(), y as number)));
	}

	result.events.on("dragged", () => {
		onValueChange(
			axis.positionToValue(
				horizontal
					? axis.toAxisPosition(result.x() / chart.plotContainer.width())
					: axis.toAxisPosition(result.y() / chart.plotContainer.height())
			)
		);
	});

	return result;
}

/**
 * Creates ranges
 */
export function createRanges(
	ranges: TResolvedRanges,
	root: am5.Root,
	chart: am5xy.XYChart,
	chartAxes: TChartAxes,
	colors: IColors,
	roundedRectangleBaseSettings: IRoundedRectangleSettings,
	onRangeValueChange?: (scope: IScope) => Promise<void>
): void {
	const { black, white } = colors;

	ranges.forEach((item) => {
		const { axis } = item;
		const chartAxis = chartAxes[axis.id];

		// Base styling

		const color = getChartColor(item.originalData.foregroundColor);

		const rangeLabelSettings = {
			...rangeLabelBaseSettings,
			fill: am5.Color.alternative(color, white, black)
		};

		const rangeLabelBackgroundSettings = {
			...roundedRectangleBaseSettings,
			fill: color,
			fillOpacity: 1
		};

		// Data items

		let startDataItem: TAxisDataItem;
		let endDataItem: TAxisDataItem;

		//am5xy.IValueAxisDataItem
		//am5xy.ValueAxis<AxisRenderer>

		switch (axis.type) {
			case COMPOSED_CHART_AXIS_TYPE.VALUE: {
				const dataItemSettings: am5xy.IValueAxisDataItem = {
					value: toNumber(item.originalData.startValue) || 0
				};

				dataItemSettings.endValue =
					item.originalData.endValue || item.originalData.endValue === 0
						? toNumber(item.originalData.endValue) || 0
						: dataItemSettings.value;

				startDataItem = chartAxis.makeDataItem(dataItemSettings);

				if (dataItemSettings.value !== dataItemSettings.endValue) {
					endDataItem = chartAxis.makeDataItem({
						value: dataItemSettings.endValue,
						endValue: dataItemSettings.endValue
					} as am5xy.IValueAxisDataItem);
				}

				break;
			}

			case COMPOSED_CHART_AXIS_TYPE.CATEGORY: {
				const dataItemSettings: am5xy.ICategoryAxisDataItem = {
					category: item.originalData.startValue
				};

				dataItemSettings.endCategory =
					item.originalData.endValue || item.originalData.endValue === 0
						? item.originalData.endValue
						: dataItemSettings.category;

				startDataItem = chartAxis.makeDataItem(dataItemSettings);

				if (dataItemSettings.category !== dataItemSettings.endCategory) {
					endDataItem = chartAxis.makeDataItem({
						category: dataItemSettings.endCategory,
						endCategory: dataItemSettings.endCategory
					} as am5xy.ICategoryAxisDataItem);
				}

				break;
			}

			case COMPOSED_CHART_AXIS_TYPE.DATETIME: {
				const dataItemSettings: am5xy.IDateAxisDataItem = {
					value: getTimestamp(item.originalData.startValue)
				};

				dataItemSettings.endValue =
					item.originalData.endValue || item.originalData.endValue === 0
						? getTimestamp(item.originalData.endValue)
						: dataItemSettings.value;

				startDataItem = chartAxis.makeDataItem(dataItemSettings);

				if (dataItemSettings.value !== dataItemSettings.endValue) {
					endDataItem = chartAxis.makeDataItem({
						value: dataItemSettings.endValue,
						endValue: dataItemSettings.endValue
					} as am5xy.IDateAxisDataItem);
				}

				break;
			}
		}

		const startRange = chartAxis.createAxisRange(startDataItem) as TAxisDataItem;

		startDataItem.get("grid").setAll({ ...rangeBaseSettings, stroke: color });
		startDataItem.get("axisFill").setAll({
			fill: color,
			fillOpacity: item.originalData.fillOpacity / 100,
			visible: true
		});

		const startRangeLabelSettings = {
			...rangeLabelSettings,
			text: item.originalData.startTitle || String(item.originalData.startValue)
		};

		// eslint-disable-next-line no-constant-condition
		if (true /*!rangeItem.startDraggable || axisTypeValue === COMPOSED_CHART_AXIS_TYPE.CATEGORY*/) {
			startDataItem.get("label").setAll({
				...startRangeLabelSettings,
				background: am5.RoundedRectangle.new(root, { ...rangeLabelBackgroundSettings }),
				location: 0
			});
		} else {
			const rangeResizeButton = createRangeResizeButton(
				root,
				chart,
				chartAxis as am5xy.ValueAxis<AxisRenderer> & am5xy.DateAxis<AxisRenderer>,
				axis.horizontal,
				axis.opposite,
				startRangeLabelSettings,
				rangeLabelBackgroundSettings,
				(value) => {
					const settings = !endDataItem ? { value, endValue: value } : { value };

					startRange.setAll({ ...settings });
					startDataItem.setAll({ ...settings }); // valueAxis.getPrivate("max"));

					rangeResizeButton.get("label").set("text", item.originalData.startTitle || `${value}`);

					if (isFunction(onRangeValueChange)) {
						/*onRangeValueChange((parentScope) => {
							return createSubScope(
								parentScope,
								{ value },
								{ value: Type.Any({}) }
							);
						});*/
					}
				}
			);

			startRange.set(
				"bullet",
				am5xy.AxisBullet.new(root, {
					sprite: rangeResizeButton,
					location: 0
				})
			);
		}

		if (endDataItem) {
			const endRange = chartAxis.createAxisRange(endDataItem) as TAxisDataItem;

			endDataItem.get("grid").setAll({ ...rangeBaseSettings, stroke: color });

			const endRangeLabelSettings = {
				...rangeLabelSettings,
				text: item.originalData.endTitle || String(item.originalData.endValue)
			};

			// eslint-disable-next-line no-constant-condition
			if (true /*!rangeItem.endDraggable || axisTypeValue === COMPOSED_CHART_AXIS_TYPE.CATEGORY*/) {
				endDataItem.get("label").setAll({
					...endRangeLabelSettings,
					background: am5.RoundedRectangle.new(root, { ...rangeLabelBackgroundSettings }),
					location: 0
				});
			} else {
				const rangeResizeButton = createRangeResizeButton(
					root,
					chart,
					chartAxis as am5xy.ValueAxis<AxisRenderer> & am5xy.DateAxis<AxisRenderer>,
					axis.horizontal,
					axis.opposite,
					endRangeLabelSettings,
					rangeLabelBackgroundSettings,
					(value) => {
						endRange.setAll({ value, endValue: value });
						endDataItem.setAll({ value, endValue: value });

						startRange.set("endValue", value);
						startDataItem.set("endValue", value);

						rangeResizeButton.get("label").set("text", item.originalData.endTitle || `${value}`);
					}
				);

				endRange.set(
					"bullet",
					am5xy.AxisBullet.new(root, {
						sprite: rangeResizeButton,
						location: 0
					})
				);
			}
		}
	});
}
