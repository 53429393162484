/**
 * Is Deep Equal
 *
 * @package hae-lib-shared
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

/**
 * Checks if two values equal
 *
 * @param value1 First value to compare
 * @param value2 Second value to compare
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isDeepEqual(value1: any, value2: any): boolean {
	// Direct match
	if (value1 === value2) {
		return true;
	}

	// Is function
	if (value1 && value2 && typeof value1 === "function" && typeof value2 === "function") {
		return true;
	}

	// Is object
	if (value1 && value2 && typeof value1 === "object" && typeof value2 === "object") {
		if (value1.constructor !== value2.constructor) return false;

		// Check array
		if (Array.isArray(value1)) {
			const length = value1.length;

			if (length != value2.length) {
				return false;
			}

			for (let i = length; i-- !== 0; ) {
				if (!isDeepEqual(value1[i], value2[i])) return false;
			}

			return true;
		}

		// Check map
		if (value1 instanceof Map && value2 instanceof Map) {
			if (value1.size !== value2.size) {
				return false;
			}

			for (const i of value1.entries()) {
				if (!value2.has(i[0])) {
					return false;
				}
			}

			for (const i of value1.entries()) {
				if (!isDeepEqual(i[1], value2.get(i[0]))) {
					return false;
				}
			}

			return true;
		}

		// Check set
		if (value1 instanceof Set && value2 instanceof Set) {
			if (value1.size !== value2.size) {
				return false;
			}

			for (const i of value1.entries()) {
				if (!value2.has(i[0])) return false;
			}

			return true;
		}

		// Check array buffer
		if (ArrayBuffer.isView(value1) && ArrayBuffer.isView(value2)) {
			const length = value1.byteLength;

			if (length != value2.byteLength) {
				return false;
			}

			for (let i = length; i-- !== 0; ) {
				if (value1[i] !== value2[i]) return false;
			}

			return true;
		}

		if (value1.constructor === RegExp)
			return value1.source === value2.source && value1.flags === value2.flags;
		if (value1.valueOf !== Object.prototype.valueOf) return value1.valueOf() === value2.valueOf();
		if (value1.toString !== Object.prototype.toString) return value1.toString() === value2.toString();

		const keys = Object.keys(value1);
		const length = keys.length;
		if (length !== Object.keys(value2).length) return false;

		for (let i = length; i-- !== 0; ) {
			if (!Object.prototype.hasOwnProperty.call(value2, keys[i])) return false;
		}

		for (let i = length; i-- !== 0; ) {
			const key = keys[i];

			if (!isDeepEqual(value1[key], value2[key])) {
				return false;
			}
		}

		return true;
	}

	// true if both NaN, false otherwise
	return value1 !== value1 && value2 !== value2;
}
