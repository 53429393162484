/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNodePath } from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaCreateOpts,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	cloneModelNode,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
} from "../Schema/SchemaHelpers";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import { createEmptyScope, IScope } from "../Shared/Scope";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { applyCodeArg } from "../Context/CompileUtil";
import { TypeDescNull } from "../Shared/ITypeDescriptor";

/**
 * Schema model
 */
export interface ISchemaScopedTemplateModel<TValueSchema extends TGenericBlueprintSchema>
	extends IModelNode<ISchemaScopedTemplate<TValueSchema>> {
	template: TGetBlueprintSchemaModel<TValueSchema>;
}

/**
 * Schema options
 */
export interface ISchemaScopedTemplateOpts<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchemaOpts {
	template: TValueSchema;
}

/**
 * Schema spec
 */
export interface ISchemaScopedTemplateSpec<TValueSchema extends TGenericBlueprintSchema> {
	(scope: IScope | ((parentScope: IScope) => IScope), key?: string): TGetBlueprintSchemaSpec<TValueSchema>;
	childSpec?: { [K: string]: TGetBlueprintSchemaSpec<TValueSchema> };
}

/**
 * Schema type
 */
export interface ISchemaScopedTemplate<TValueSchema extends TGenericBlueprintSchema>
	extends IBlueprintSchema<
		ISchemaScopedTemplateOpts<TValueSchema>,
		ISchemaScopedTemplateModel<TValueSchema>,
		ISchemaScopedTemplateSpec<TValueSchema>,
		TGetBlueprintSchemaDefault<TValueSchema>,
		TGetBlueprintSchemaCreateOpts<TValueSchema>
	> {}

/**
 * Schema: Font size
 *
 * @param opts Schema options
 */
export function SchemaScopedTemplate<TValueSchema extends TGenericBlueprintSchema>(
	opts: ISchemaScopedTemplateOpts<TValueSchema>
): ISchemaScopedTemplate<TValueSchema> {
	type TTemplateModel = TGetBlueprintSchemaModel<TValueSchema>;

	const schema = createEmptySchema<ISchemaScopedTemplate<TValueSchema>>("scopedTemplate", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [ "template" ]);
	};

	const createModel = (
		dCtx: DesignContext,
		tplModel: TTemplateModel,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			template: tplModel
		});

		const model = assignParentToChildrenOf(modelNode);

		if (tplModel) {
			model.initRequiredValid = tplModel.initRequiredValid;
			tplModel.schema.assignParent(tplModel, model, false);
		}

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {
		const tplModel = opts.template.createDefault(
			dCtx,
			null,
			defaultValue,
			createOpts as never
		) as TTemplateModel;
		return createModel(dCtx, tplModel, parent);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedTpl = modelNode.template.schema.clone(dCtx, modelNode.template, null) as TTemplateModel;

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			template: clonedTpl
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		if (modelNode.template) {
			modelNode.template.schema.destroy(modelNode.template);
		}

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent, createOpts) => {
		const tplModel = opts.template.parse(dCtx, idtNode, null, createOpts as never) as TTemplateModel;

		return createModel(dCtx, tplModel, parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		if (opts.template.provideCompletion) {
			opts.template.provideCompletion(dCtx, parentLoc, minColumn, idtNode);
		}
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		return modelNode.template.schema.serialize(modelNode.template, path);
	};

	schema.render = (rCtx, modelNode, path, rootScope, prevSpec) => {
		const _prevSpec = prevSpec?.childSpec instanceof Object ? prevSpec.childSpec : {};
		const childSpec: { [K: string]: TGetBlueprintSchemaSpec<TValueSchema> } = {};
		let currChildIndex = 0;

		//console.log("Scoped template prev spec", path, _prevSpec);

		return Object.assign(
			(scope: IScope | ((parentScope: IScope) => IScope), key?: string) => {
				const itemKey = key !== undefined && key !== null ? key : String(currChildIndex);
				let childScope = typeof scope === "function" ? scope(rootScope) : scope;

				// Check for valid scope - if not, create a new one
				// This is mainly because of test framework which calls the every fucking function when comparing data
				if (!(childScope?.globalData instanceof Object)) {
					childScope = createEmptyScope();
				}

				childScope.globalData["__componentPath"] = (
					childScope.globalData["__componentPath"] || []
				).concat([ itemKey ]);

				const spec = modelNode.template.schema.render(
					rCtx,
					modelNode.template,
					path.concat([ itemKey ]),
					childScope,
					_prevSpec[itemKey]
				);

				// childSpec.push(spec);
				childSpec[itemKey] = spec;
				currChildIndex++;

				return spec;
			},
			{
				childSpec: childSpec
			}
		);
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		const tplCode = modelNode.template.schema.compileRender(cCtx, modelNode.template, path);

		return {
			isScoped: true,
			/* eslint-disable indent */
			code: `(_s,pv,pt)=>{${[
				`const _ps=pv&&pv.childSpec&&typeof pv.childSpec==="object"?pv.childSpec:{};`, // _ps = prevSpec
				`const _cs={};`, // _cs = childSpec
				`let _cci=0;`, // __cci = currentChildINdex
				`return Object.assign((sf,k)=>{`, // sf = scopeFunction, k = key
				`const _k=k!==undefined&&k!==null?k:String(_cci);`, // _k = determined key to be used
				`const s=typeof sf==="function"?sf(_s):sf;`, // s = scope
				`s.globalData["__componentPath"] = (s.globalData["__componentPath"] || []).concat([_k]);`,
				`const _r=${applyCodeArg(tplCode, `_ps[_k]`, `pt.concat([_k])`)};`, // _r = result
				`_cs[_k]=_r;`, // assign result to childSpec
				`_cci++;`,
				`return _r;`,
				`},{childSpec:_cs})`
			].join("")}}`
			/* eslint-enable indent */
		};
	};

	// We use cast spec to convert all dynamic experssions to scoped template functions
	schema.castSpec = (rCtx, path, modelNodeId, value) => {
		return Object.assign(
			(scope: IScope | ((parentScope: IScope) => IScope), key?: string) => {
				return value;
			},
			{
				childSpec: null
			}
		);
	};

	schema.compileCastSpec = (cCtx, path, modelNodeId) => {
		/* eslint-disable indent, max-len */
		return `(v,pt)=>{${[
			`return Object.assign((sf,k)=>{`, // sf = scopeFunction, k = key
			`return v;`,
			`},{childSpec:null})`
		].join("")}}`;
		/* eslint-enable indent, max-len */
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		return opts.template.validate(rCtx, path, modelNodeId, value, validateChildren);
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		return opts.template.compileValidate(cCtx, path, modelNodeId, validateChildren);
	};

	// Conditional schema is internal and cannot be exported
	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaScopedTemplate", [ opts ]);
	};

	// Always returns null because template function should never be passed into the scope and be publicly available
	schema.getTypeDescriptor = () => {
		return TypeDescNull({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "template",
				node: modelNode.template
			}
		];
	};

	return schema;
}
