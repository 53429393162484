/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintFileReadNode = {
	name: NODE_TYPES.TRANSFORM,
	label: "File Read",
	icon: "mdi/file-export",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			file: BP.Prop(
				BP.Object({
					label: "File",
					description: "Reference to a temp file",
					props: {
						fileId: BP.Prop(
							BP.String({
								label: "File ID",
								description: "ID of the temp file to read",
							})
						)
					}
				})
			),
			outputEncoding: BP.Prop(
				BP.Enum.String({
					label: "Output Encoding",
					description: "Encoding of the output string",
					default: "base64",
					options: [
						{
							value: "utf8",
							label: "UTF-8"
						},
						{
							value: "base64",
							label: "Base64"
						}
					]
				})
			)
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR
	})
};

export type TBlueprintFileReadNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintFileReadNode["opts"]
>;
