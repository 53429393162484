/**
 * Text Area Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, COMPONENT_MODE, Type } from "@hexio_io/hae-lib-blueprint";

import {
	addFormItem,
	ClassList,
	Label,
	propGroups,
	removeFormItem,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { isString, isValidObject, isValidValue } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { FieldBaseProps, FieldLengthProps } from "./props";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { getFieldStateProps, useField } from "./useField";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";
import { getSelectionByField, IFieldSelection, initialSelection } from "./selection";

interface HAEComponentTextAreaField_State extends IFieldState {
	initialValue: string;
	value: string;
	selection: IFieldSelection;
}

const HAEComponentTextAreaField_Props = {
	...FieldBaseProps,

	...FieldLengthProps,

	value: BP.Prop(
		BP.String({
			...termsEditor.schemas.textAreaField.value,
			default: "",
			fallbackValue: null,
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.common
	),

	placeholder: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.placeholder
		}),
		20,
		propGroups.common
	)
};

const HAEComponentTextAreaField_Events = {
	...HAEComponentField_Events
};

const HAEComponentTextAreaField_Definition = defineElementaryComponent<
	typeof HAEComponentTextAreaField_Props,
	HAEComponentTextAreaField_State,
	typeof HAEComponentTextAreaField_Events
>({
	...termsEditor.components.textAreaField.component,

	name: "textAreaField",

	category: "form",

	icon: "mdi/form-textarea",

	docUrl: "...",

	order: 20,

	props: HAEComponentTextAreaField_Props,

	events: HAEComponentTextAreaField_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, _rCtx, scope) => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;
		const value =
			state?.initialValue === initialValue
				? isValidValue(state?.value)
					? state.value
					: spec.value
				: spec.value;

		const selection = isValidObject(state?.selection)
			? {
				caret: state.selection.caret ?? initialSelection.caret,
				start: state.selection.start ?? initialSelection.start,
				end: state.selection.end ?? initialSelection.end,
				direction: state.selection.direction ?? initialSelection.direction
			}
			: initialSelection;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? "" : initialValue }));
		}

		const newState = {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			selection,
			setValue,
			clearValue
		};

		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: newState.value,
			changed: newState.changed || false,
			valid: newState.valid || false,
			clearValue: clearValue
		});

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			selection: state.selection,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.schemas.textAreaField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				selection: Type.Object({
					...termsEditor.schemas.field.selection,
					props: {
						caret: Type.Integer({ ...termsEditor.schemas.field.selectionCaret }),
						start: Type.Integer({ ...termsEditor.schemas.field.selectionStart }),
						end: Type.Integer({ ...termsEditor.schemas.field.selectionEnd }),
						direction: Type.String({ ...termsEditor.schemas.field.selectionDirection })
					}
				}),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentTextAreaField_React: THAEComponentReact<typeof HAEComponentTextAreaField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		placeholder,
		minLength,
		maxLength,
		fieldName,
		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		validate,
		required,
		customValidation
	} = props;

	const { value, empty, touched, changed, valid } = state;

	const { safePath: componentPath, componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const textareaRef = React.useRef<HTMLTextAreaElement>();

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;

	classList.addModifiers({
		textarea: true,
		validate
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }),
		false
	);

	const {
		setValue
		//setTouched
	} = useField<string>(
		{
			id,
			state,
			readOnly,
			validate,
			customValidation,
			validationDependencies: [ enabled, required, minLength, maxLength ],
			fixDefaultValue: true,
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	const textareaProps: React.HTMLProps<HTMLTextAreaElement> = {
		id,
		name: fieldName || id,
		className: "cmp-field__textarea",
		defaultValue: isString(value) ? value : "",
		placeholder,
		readOnly: elementReadOnly,
		disabled: !enabled,
		required,
		minLength,
		maxLength
	};

	// Event handlers

	const _textareaBlurHandler = React.useCallback(() => {
		setState((prevState) => ({ ...prevState, touched: true, selection: initialSelection }));
	}, [ initialSelection, setState ]);

	const _textareaMouseDownHandler = React.useCallback(() => {
		window.addEventListener(
			"mouseup",
			() => {
				setState((prevState) => ({
					...prevState,
					selection: getSelectionByField(textareaRef.current)
				}));
			},
			{ once: true }
		);
	}, [ getSelectionByField, setState ]);

	const _textareaKeyUpHandler = React.useCallback(() => {
		setState((prevState) => ({ ...prevState, selection: getSelectionByField(textareaRef.current) }));
	}, [ getSelectionByField, setState ]);

	const _textareaChangeHandler = React.useCallback(() => {
		if (!textareaRef.current) {
			return;
		}

		setValue(textareaRef.current.value, { selection: getSelectionByField(textareaRef.current) });
	}, [ getSelectionByField, setValue ]);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
				classList={new ClassList("cmp-field__label")}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				<textarea
					ref={textareaRef}
					{...textareaProps}
					onBlur={_textareaBlurHandler}
					onMouseDown={_textareaMouseDownHandler}
					onKeyUp={_textareaKeyUpHandler}
					onChange={_textareaChangeHandler}
				/>
			</div>

			<FieldInfo
				descriptionText={descriptionText}
				componentPath={[ ...componentPath, "info" ]}
				componentMode={componentMode}
			/>
		</div>
	);
};

export const HAEComponentTextAreaField: THAEComponentDefinition<typeof HAEComponentTextAreaField_Definition> =
	{
		...HAEComponentTextAreaField_Definition,
		reactComponent: HAEComponentTextAreaField_React
	};
