/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IViewRefItem, IViewRefResolver, TSchemaConstObjectProps } from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, TSimpleEventEmitter } from "@hexio_io/hae-lib-shared";
import { IResourceRegistry } from "../registries";
import { IViewResourceProps, RESOURCE_TYPES } from "../resources";

export class ViewRefResolver implements IViewRefResolver {
	/**
	 * Invalidate event - emitted when view list has changed (and param schemas must be re-resolved)
	 */
	public onInvalidate: TSimpleEventEmitter<void>;

	/**
	 * Constructor
	 * @param applicationContainer Application Container
	 */
	public constructor(protected resourceRegistry: IResourceRegistry) {
		this.onInvalidate = createEventEmitter();
	}

	/**
	 * Returns a params schema by view ID
	 */
	public getParamsSchemaById(viewId: string): TSchemaConstObjectProps {
		const viewInstance = this.resourceRegistry
			.getItemList()
			.filter((resource) => resource.id === viewId)[0] as IViewResourceProps;

		if (!viewInstance) {
			return null;
		}

		return viewInstance.parsedData.paramsSchemaImport.constSchema.opts.props;
	}

	/**
	 * Returns a list of available views
	 */
	public getViewList(): IViewRefItem[] {
		return this.resourceRegistry
			.getItemList()
			.filter((resource) => resource.resourceType === RESOURCE_TYPES.VIEW)
			.map((resource: IViewResourceProps) => ({
				viewId: resource.id,
				label: resource.parsedData.label,
				description: resource.parsedData.description
			}));
	}
}
