/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry, ItemRegistryItemProps } from "@hexio_io/hae-lib-shared";
import { IResolversRegistry } from "../registries";

interface ResolverProps extends ItemRegistryItemProps {
	resolver: unknown;
}

export class ResolversRegistry implements IResolversRegistry {
	/** Resolver registry */
	protected registry: ItemRegistry<ResolverProps> = new ItemRegistry<ResolverProps>();

	/**
	 * Registers resolver
	 *
	 * @param name Resolver's name
	 * @param resolver Resolver
	 */
	public register(name: string, resolver: unknown): void {
		this.registry.register({ name, resolver });
	}

	/**
	 * Removes resolver
	 *
	 * @param name Resolver's name
	 */
	public remove(name: string): void {
		this.registry.unregister(name);
	}

	/**
	 * Returns resolver by name
	 *
	 * @param name Resolver's name
	 */
	public get<T>(name: string): T {
		return this.registry.get(name).resolver as unknown as T;
	}

	/**
	 * Returns all registered resolvers
	 */
	public getAll(): { [K: string]: unknown } {
		const resolvers = {};
		for (const [ name, props ] of Object.entries(this.registry.getItems())) {
			resolvers[name] = props.resolver;
		}
		return resolvers;
	}

	/**
	 * Flush all resolvers
	 */
	public flush(): void {
		this.registry.flush();
	}
}
