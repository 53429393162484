/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintTimeoutNode = {
	name: NODE_TYPES.TIMEOUT,
	label: "Timeout",
	icon: "mdi/clock-outline",
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			timeoutInMs: BP.Prop(
				BP.Integer({
					label: termsEditor.nodes.timeout.value.label,
					description: termsEditor.nodes.timeout.value.description,
					default: 100,
					constraints: {
						min: 1,
						required: true
					}
				})
			)
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS
	})
};

export type TBlueprintTimeoutNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintTimeoutNode["opts"]
>;
