/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	DESIGN_CONTEXT_READ_MODE,
	ISchemaConstObject,
	ISchemaConstObjectOptsProp,
	ISchemaConstString,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	TGenericBlueprintSchema
} from "@hexio_io/hae-lib-blueprint";
import { DOC_TYPES } from "./DocTypes";
import { termsEditor } from "../terms";

/**
 * Blueprint Base Shared properties
 */
export const BlueprintBaseSharedProps = {
	id: BP.Prop(
		BP.Const.String({
			label: termsEditor.blueprints.base.id.label,
			description: termsEditor.blueprints.base.id.description,
			constraints: {
				required: true
			}
		})
	),
	label: BP.Prop(
		BP.Const.String({
			label: termsEditor.blueprints.base.label.label,
			description: termsEditor.blueprints.base.label.description
		})
	),
	description: BP.Prop(
		BP.Const.String({
			label: termsEditor.blueprints.base.description.label,
			description: termsEditor.blueprints.base.description.description
		})
	),
	attributes: BP.Prop(
		BP.Const.Object({
			props: {
				allowWrite: BP.Prop(
					BP.Const.Boolean({
						label: termsEditor.blueprints.base.attributes.allowWrite.label,
						description: termsEditor.blueprints.base.attributes.allowWrite.description,
						default: true
					})
				),
				allowRemove: BP.Prop(
					BP.Const.Boolean({
						label: termsEditor.blueprints.base.attributes.allowRemove.label,
						description: termsEditor.blueprints.base.attributes.allowRemove.description,
						default: true
					})
				),
				allowRename: BP.Prop(
					BP.Const.Boolean({
						label: termsEditor.blueprints.base.attributes.allowRename.label,
						description: termsEditor.blueprints.base.attributes.allowRename.description,
						default: true
					})
				),
				hidden: BP.Prop(
					BP.Const.Boolean({
						label: termsEditor.blueprints.base.attributes.hidden.label,
						description: termsEditor.blueprints.base.attributes.hidden.description,
						default: false
					})
				)
			}
		})
	),
	metadata: BP.Prop(
		BP.Const.Map({
			label: termsEditor.blueprints.base.metadata.label,
			description: termsEditor.blueprints.base.metadata.description,
			value: BP.Const.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
				allowedTypes: {
					[SCHEMA_CONST_ANY_VALUE_TYPE.STRING]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.NUMBER]: true,
					[SCHEMA_CONST_ANY_VALUE_TYPE.BOOLEAN]: true
				}
			})
		})
	)
};

/**
 * Base properties of a blueprint schema
 */
export const BlueprintBaseProps = {
	doctype: BP.Prop(
		BP.Const.String({
			constraints: {
				required: true
			}
		})
	),
	...BlueprintBaseSharedProps,
	// Skip spec parsing in generic blueprint
	spec: BP.Prop(
		BP.Conditional({
			condition: (dCtx) => dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL,
			value: BP.Const.Any({
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
				constOnly: true,
				allowInterpolation: false
			})
		})
	)
};

/**
 * Base Blueprint Schema
 */
export const BlueprintBase = BP.Const.Object({
	label: "Blueprint",
	props: BlueprintBaseProps
});

export type TBlueprintBaseSchema = typeof BlueprintBase;

/**
 * Creates a full blueprints schema for a given doctype
 *
 * @param doctype DocType
 * @param specSchema Schema of "spec" property
 */
export function createBlueprintSchema<TSpecSchema extends TGenericBlueprintSchema>(
	doctype: DOC_TYPES,
	specSchema: TSpecSchema
): ISchemaConstObject<
	{
		doctype: ISchemaConstObjectOptsProp<ISchemaConstString>;
		id: ISchemaConstObjectOptsProp<ISchemaConstString>;
		spec: ISchemaConstObjectOptsProp<TSpecSchema>;
	} & typeof BlueprintBaseSharedProps
> {
	return BP.Const.Object({
		label: "Blueprint",
		props: {
			doctype: BP.Prop(
				BP.Const.String({
					constraints: {
						const: doctype,
						required: true
					},
					default: doctype
				})
			),
			...BlueprintBaseSharedProps,
			spec: BP.Prop(specSchema)
		}
	});
}
