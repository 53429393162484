/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TBlueprintIDTNode } from "@hexio_io/hae-lib-blueprint";
import { IEditorApiBlueprintRevision, TEditorApiBlueprintHistory } from "../api";
import { IService } from "../services";

/**
 * Resource Adapter Types
 */
export enum RESOURCE_ADAPTER_TYPE {
	fs = "fs",
	git = "git"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IRawResourceErrorDetails {
	[K: string]: any;
}
[];

export interface IRawResource {
	uri: string;
	label: string;
	location: string;
	basePath: string;
	idt?: TBlueprintIDTNode;
	isValid: boolean;
	errors?: IRawResourceErrorDetails;
	dependencies?: string[];
	lastModified?: number;
}

/**
 * Resource Adapter Options
 */
export interface IResourceAdapterOptions {
	/** Path to blueprints */
	blueprintsPath: string;
}

export interface IResourceRevisionInfo
	extends Pick<IEditorApiBlueprintRevision, "modifiedBy" | "modifiedAt" | "revision"> {}

/**
 * Resource Adapter
 */
export interface IResourceAdapter extends IService {
	manifestUri: string;

	loadManifest(): Promise<IRawResource>;
	loadResource(filepath: string): Promise<IRawResource>;
	loadResources(): Promise<IRawResource[]>;
	loadResourceFromString(content: string, filename: string): Promise<IRawResource>;
	getLastRevision(path: string): Promise<IEditorApiBlueprintRevision>;

	/**
	 * Creates directory
	 *
	 * @param params
	 */
	createDir(params: { dirName: string; author?: string }): Promise<IRawResource>;

	/**
	 * Removes directory
	 *
	 * @param params
	 */
	removeDir(params: { dirName: string; author?: string }): Promise<void>;

	/**
	 * Renames directory
	 *
	 * @param params
	 */
	renameDir(params: { dirName: string; newName: string; basePath: string }): Promise<IRawResource>;

	/**
	 * Creates blueprint
	 *
	 * @param params Params
	 */
	createBlueprint(params: {
		content?: string;
		basePath: string;
		name: string;
		author?: string;
	}): Promise<IRawResource>;

	/**
	 * Updates blueprint content
	 *
	 * @param params Params
	 */
	updateBlueprintContent(params: {
		path: string;
		content: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IRawResource>;

	updateManifestContent(params: {
		content: string;
		sourceRevision?: string;
		author?: string;
	}): Promise<IRawResource>;

	/**
	 * Renames blueprint
	 *
	 * @param params Params
	 */
	renameBlueprint(params: {
		path: string;
		basePath: string;
		name: string;
		author?: string;
	}): Promise<IRawResource>;

	/**
	 * Removes blueprint
	 *
	 * @param params Params
	 */
	removeBlueprint(params: { path: string; author?: string }): Promise<void>;

	/**
	 * Returns blueprint history
	 *
	 * @param path Blueprint path
	 */
	getBlueprintHistory(params: { path: string }): Promise<TEditorApiBlueprintHistory>;

	/**
	 * Returns blueprint's revision
	 *
	 * @param param Params
	 */
	getBlueprintRevision(params: { commitSha: string; path: string }): Promise<string>;

	getBlueprintContent(params: { path: string }): Promise<string>;

	getManifestContent(): Promise<string>;

	makeRelease(params: { tag?: string; releaseNotes?: string }): Promise<string>;

	/**
	 * Synchronize repository
	 *
	 * @param overwrite Overwrite local changes
	 */
	syncRepository(params: { overwrite?: boolean; author?: string }): Promise<void>;

	getResourceUriFromPath(name: string, basePath?: string): string;
	getDirectoryUriFromPath(name: string): string;

	addAsset(filePath: string, commit?: boolean): Promise<void>;
}
