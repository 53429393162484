/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IRuntimeError, TTypeDesc } from "@hexio_io/hae-lib-blueprint";
import { INodeResult } from "./ActionManager";
import { IActionDebugData } from "./INodeDebugData";

export enum NODE_RESULT_TYPE {
	SUCCESS = "success",
	ERROR = "error"
}

export enum ACTION_ERROR_REASON {
	// Action not found
	ACTION_NOT_FOUND = "action_not_found",
	// Noe not found
	NODE_NOT_FOUND = "node_not_found",
	// Invalid function parameters
	INVALID_PARAMS = "invalid_params",
	// Invalid blueprint
	INVALID_BLUEPRINT = "invalid_blueprint",
	// Blueprint requires authenticated user
	UNAUTHENTICATED = "unauthenticated",
	// In case error node was reached - error defined by user
	USER_ERROR = "user_error",
	// Error can't be handled
	UNHANDLED_ERROR = "unhandled_error",
	// Action execution timeout
	TIMEOUT = "timeout",
	MAX_RECURSION = "max_recursion",
	// Global try catch - this exception should never be thrown (in theory)
	INTERNAL_ERROR = "internal_error",
	// Used internally by action delegate when request has failed - eg. because of network error
	REQUEST_ERROR = "request_error",
	ENDPOINT_NOT_FOUND = "endpoint_not_found"
}

export interface IActionResultErrorDetail {
	/** HTTP Status code */
	httpStatus?: number;

	/** Message */
	message?: string;

	/** Detailed details */
	detail?: Partial<IActionResultErrorObject> & IActionResultErrorDetail;

	/** Node type */
	nodeType?: string;

	/** Node ID */
	nodeId?: string;

	// TODO: remove this? same as nodeName
	/** Node variable name */
	nodeVarName?: string;

	/** Error name which can be used in api logic */
	errorName?: string;

	/** Optional error code */
	errorCode?: string;

	/** Custom data passed from the action */
	customData?: unknown;

	/** Error details */
	details?: IRuntimeError[];

	/** Date and time when error occurred */
	date?: Date;

	/** Additional props */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K: string]: any;
}

/**
 * Action Result Error Object
 */
export interface IActionResultErrorObject {
	/** Web server request ID */
	reqId: string;

	/** Trace ID */
	traceId: string;

	/** Error reason */
	reason: ACTION_ERROR_REASON;

	/** Type of the error */
	name: string;

	/** Error code */
	code: string;

	/** Human readable error message */
	message: string;

	errorData?: INodeResult["data"]["errorData"];

	errorTracking?: INodeResult["data"]["errorTracking"];
}

export interface IEndpointResultSuccess {
	/** Status */
	status: NODE_RESULT_TYPE.SUCCESS;

	/** Result data */
	data: {
		url?: string;
		body?: any;
		headers?: { [K: string]: string };
		status?: number;
	};

	/** Type descriptor */
	typeDescriptor?: TTypeDesc;

	/** Debug info */
	debug?: IActionDebugData;
}

/**
 * Successful Action Result
 */
export interface IActionResultSuccess {
	/** Status */
	status: NODE_RESULT_TYPE.SUCCESS;

	/** Result data */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;

	/** Type descriptor */
	typeDescriptor?: TTypeDesc;

	/** Debug info */
	debug?: IActionDebugData;
}

/**
 * Error Action Result
 */
export interface IActionResultError {
	/** Status */
	status: NODE_RESULT_TYPE.ERROR;

	/** Errors */
	error?: IActionResultErrorObject;

	/** Debug info */
	debug?: IActionDebugData;
}

/**
 * Action Result
 */
export type TActionResult = IActionResultSuccess | IEndpointResultSuccess | IActionResultError;
