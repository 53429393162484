/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	ITranslationManager,
	TTranslationTermRefs,
	TTranslationTermsIndex,
	TTranslationDomainTermsMap,
	TTranslationDomainMap,
	TTranslationTable,
	TLanguageTranslationTable
} from "./ITranslationManager";

/**
 * Adds translation terms to an index from refs structure
 * Also returns a list of added terms.
 *
 * @param termsIndex Index to add terms into
 * @param termRefs Term refs to parse
 */
function addTranslationTermsFromRefs(
	termsIndex: TTranslationTermsIndex,
	termRefs: TTranslationTermRefs
): string[] {
	const parsedTerms = [];

	const parseRefs = (refs: TTranslationTermRefs) => {
		for (const k in refs) {
			if (typeof refs[k] === "string") {
				termsIndex.add(refs[k] as string);
				parsedTerms.push(refs[k] as string);
			} else if (refs[k] instanceof Object) {
				parseRefs(refs[k] as TTranslationTermRefs);
			} else {
				throw new Error(`Failed to flatten term refs - unsupported value type: ${typeof refs[k]}.`);
			}
		}
	};

	parseRefs(termRefs);

	return parsedTerms;
}

export interface ITranslationManagerOpts {
	storeSourceMap?: boolean;
}

export class TranslationManager implements ITranslationManager {
	/** Known translation terms */
	private terms: TTranslationDomainTermsMap = {};

	/** Translations */
	private translations: TTranslationDomainMap = {};

	/** A set of registered terms source IDs - to avoid multiple registrations */
	private registeredTermSourceIds = new Set<string>();

	/** A set of registered translations source IDs - to avoid multiple registrations */
	private registeredTranslationsSourceIds = new Set<string>();

	/** A set of registered language codes */
	private registeredLanguageCodes = new Set<string>();

	public constructor(private opts: ITranslationManagerOpts) {}

	/**
	 * Registers known terms
	 *
	 * @param domain Translations Domain
	 * @param terms Terms Reference Object
	 * @param sourceId Source ID (unique name for a set of inported terms, eg. package name)
	 */
	public registerTerms(domain: string, terms: TTranslationTermRefs, sourceId: string): void {
		const _sourceId = domain + "_" + sourceId;

		if (this.registeredTermSourceIds.has(_sourceId)) {
			return;
		}

		this.registeredTermSourceIds.add(_sourceId);

		const table = this.terms[domain]
			? this.terms[domain]
			: (this.terms[domain] = {
					terms: new Set(),
					sourceMap: {}
			  });

		const parsedTerms = addTranslationTermsFromRefs(table.terms, terms);

		if (this.opts.storeSourceMap) {
			parsedTerms.forEach((term) => {
				if (table.sourceMap[term]) {
					table.sourceMap[term].push(sourceId);
				} else {
					table.sourceMap[term] = [ sourceId ];
				}
			});
		}
	}

	/**
	 * Returns all registered terms
	 */
	public getRegisteredTerms(): TTranslationDomainTermsMap {
		return this.terms;
	}

	/**
	 * Creates Source id
	 * @param domain Translations Domain
	 * @param languageCode Language code
	 * @param sourceId Source ID (unique name for a loaded translations, eg. package name)
	 * @returns
	 */
	protected createSourceId(domain: string, languageCode: string, sourceId: string): string {
		return domain + "_" + languageCode + "_" + sourceId;
	}

	/**
	 * Registers translations for a given language
	 *
	 * @param domain Translations Domain
	 * @param languageCode Language code
	 * @param translations Translations table
	 * @param sourceId Source ID (unique name for a loaded translations, eg. package name)
	 */
	public registerTranslations(
		domain: string,
		languageCode: string,
		translations: TTranslationTable,
		sourceId: string
	): void {
		const _sourceId = this.createSourceId(domain, languageCode, sourceId);

		if (this.registeredTranslationsSourceIds.has(_sourceId)) {
			return;
		}

		this.registeredTranslationsSourceIds.add(_sourceId);

		if (!this.translations[domain]) {
			this.translations[domain] = {};
		}

		if (!this.translations[domain][languageCode]) {
			this.translations[domain][languageCode] = {
				translations: {},
				sourceMap: {}
			};
		}

		this.registeredLanguageCodes.add(languageCode);

		const table = this.translations[domain][languageCode];
		for (const k in translations) {
			if (table.translations[k]) {
				throw new Error(
					`Translation for a domain '${domain}', language '${languageCode}' and term '${k}' already exists.`
				);
			}

			table.translations[k] = translations[k];

			if (this.opts.storeSourceMap) {
				if (!table.sourceMap[k]) {
					table.sourceMap[k] = [];
				}

				table.sourceMap[k].push(sourceId);
			}
		}
	}

	/**
	 * Removes source id
	 *
	 * @param domain Translations Domain
	 * @param languageCode Language code
	 * @param sourceId Source ID (unique name for a loaded translations, eg. package name)
	 */
	public removeSourceId(domain: string, languageCode: string, sourceId: string): void {
		const _sourceId = this.createSourceId(domain, languageCode, sourceId);

		if (!this.registeredTranslationsSourceIds.has(_sourceId)) {
			return;
		}

		this.registeredTermSourceIds.delete(_sourceId);
	}

	/**
	 * Removes translations
	 *
	 * @param domain Translations Domain
	 *
	 */
	public removeTranslations(domain: string): void {
		if (this.translations[domain]) {
			delete this.translations[domain];
		}
	}

	/**
	 * Returns all registered translations
	 */
	public getAllTranslations(): TTranslationDomainMap {
		return this.translations;
	}

	/**
	 * Returns translations for a given domain
	 *
	 * @param domain Translations Domain
	 */
	public getDomainTranslations(domain: string): TLanguageTranslationTable {
		return this.translations[domain] || {};
	}

	/**
	 * Returns a list of language codes that have at least one translation registered
	 */
	public getLanguageCodeList(): string[] {
		return [ ...this.registeredLanguageCodes ];
	}

	/**
	 * Imports translations for a given domain - USE WITH CAUTION
	 * This method don't do any checks and will eniterly replace translations for a given domain.
	 * It should be used only when importing translation from another manager (exported via `getDomainTranslations`).
	 *
	 * @param domain Translations Domain
	 * @param translations Translations object
	 */
	public importDomainTranslations(domain: string, translations: TLanguageTranslationTable): void {
		this.translations[domain] = translations;
	}

	/**
	 * Translates a given terms
	 *
	 * @param domain Translation Domain
	 * @param languageCode Language code
	 * @param term Term to translate
	 * @param vars Variables to be replaced in a translated value (in a form of `{{varName}}`).
	 * @param fallbackValue Fallback value to be returned if translation is not found. If not set the term will be returned.
	 */
	public translate(
		domain: string,
		languageCode: string,
		term: string,
		vars?: { [K: string]: string | number | boolean },
		fallbackValue?: string
	): string {
		let value: string =
			this.translations[domain] &&
			this.translations[domain][languageCode] &&
			this.translations[domain][languageCode].translations[term] !== undefined
				? this.translations[domain][languageCode].translations[term]
				: fallbackValue !== undefined && fallbackValue !== null
				? fallbackValue
				: term;

		if (vars) {
			for (const k in vars) {
				const escapedKey = k.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
				value = value.replace(new RegExp(`{{${escapedKey}}}`, "g"), String(vars[k]));
			}
		}

		return value;
	}
}
