/**
 * Terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { appHeader as appHeaderEditorComponent } from "./editor/components/appHeader";
import {
	checkboxField as checkboxFieldEditorComponent,
	colorField as colorFieldEditorComponent,
	datetimeField as datetimeFieldEditorComponent,
	fileField as fileFieldEditorComponent,
	numberField as numberFieldEditorComponent,
	optionsField as optionsFieldEditorComponent,
	rangeField as rangeFieldEditorComponent,
	textAreaField as textAreaFieldEditorComponent,
	textField as textFieldEditorComponent
} from "./editor/components/field";
import { box as boxEditorComponent } from "./editor/components/box";
import { button as buttonEditorComponent } from "./editor/components/button";
import { buttonGroup as buttonGroupEditorComponent } from "./editor/components/buttonGroup";
import { composedChart as composedChartEditorComponent } from "./editor/components/composedChart";
import { container as containerEditorComponent } from "./editor/components/container";
import { dataTable as dataTableEditorComponent } from "./editor/components/dataTable";
import { gaugeChart as gaugeChartEditorComponent } from "./editor/components/gaugeChart";
import { grid as gridEditorComponent } from "./editor/components/grid";
import { form as formEditorComponent } from "./editor/components/form";
import { iframe as iframeEditorComponent } from "./editor/components/iframe";
import { menuButton as menuButtonEditorComponent } from "./editor/components/menuButton";
import { oneOf as oneOfEditorComponent } from "./editor/components/oneOf";
import { icon as iconEditorComponent } from "./editor/components/icon";
import { image as imageEditorComponent } from "./editor/components/image";
import { label as labelEditorComponent } from "./editor/components/label";
import { pagination as paginationEditorComponent } from "./editor/components/pagination";
import { pixelGrid as pixelGridComponent } from "./editor/components/pixelGrid";
import { propertyItem as propertyItemEditorComponent } from "./editor/components/propertyItem";
import { qrCode as qrCodeEditorComponent } from "./editor/components/qrCode";
import { qrBarReader as qrBarReaderEditorComponent } from "./editor/components/qrBarReader";
import { repeat as repeatEditorComponent } from "./editor/components/repeat";
import { routes as routesEditorComponent } from "./editor/components/routes";
import { sidebarLayout as sidebarLayoutEditorComponent } from "./editor/components/sidebarLayout";
import { spacer as spacerEditorComponent } from "./editor/components/spacer";
import { table as tableEditorComponent } from "./editor/components/table";
import { text as textEditorComponent } from "./editor/components/text";
import { treeNavigation as treeNavigationEditorComponent } from "./editor/components/treeNavigation";

import { appHeader as appHeaderEditorSchema } from "./editor/schemas/appHeader";
import { buttonGroup as buttonGroupEditorSchema } from "./editor/schemas/buttonGroup";
import { chart as chartEditorSchema } from "./editor/schemas/chart";
import { composedChart as composedChartEditorSchema } from "./editor/schemas/composedChart";
import {
	checkboxField as checkboxFieldEditorSchema,
	colorField as colorFieldEditorSchema,
	datetimeField as datetimeFieldEditorSchema,
	field as fieldEditorSchema,
	fileField as fileFieldEditorSchema,
	numberField as numberFieldEditorSchema,
	optionsField as optionsFieldEditorSchema,
	optionsFieldItem as optionsFieldItemEditorSchema,
	rangeField as rangeFieldEditorSchema,
	textAreaField as textAreaFieldEditorSchema,
	textField as textFieldEditorSchema
} from "./editor/schemas/field";
import { gaugeChart as gaugeChartEditorSchema } from "./editor/schemas/gaugeChart";
import { grid as gridEditorSchema } from "./editor/schemas/grid";
import { iframe as iframeEditorSchema } from "./editor/schemas/iframe";
import { menuButton as menuButtonEditorSchema } from "./editor/schemas/menuButton";
import { propertyItem as propertyItemEditorSchema } from "./editor/schemas/propertyItem";
import { qrCode as qrCodeEditorSchema } from "./editor/schemas/qrCode";
import { qrBarReader as qrBarReaderEditorSchema } from "./editor/schemas/qrBarReader";
import { sidebarLayout as sidebarLayoutEditorSchema } from "./editor/schemas/sidebarLayout";
import { table as tableEditorSchema } from "./editor/schemas/table";
import { treeNavigation as treeNavigationEditorSchema } from "./editor/schemas/treeNavigation";

import { dataTable as dataTableRuntimeComponent } from "./runtime/components/dataTable";
import { fileField as fileFieldRuntimeComponent } from "./runtime/components/field";
import { propertyItem as propertyItemRuntimeComponent } from "./runtime/components/propertyItem";
import { view as viewRuntimeComponent } from "./runtime/components/view";

export const termsEditor = {
	components: {
		appHeader: appHeaderEditorComponent,
		box: boxEditorComponent,
		button: buttonEditorComponent,
		buttonGroup: buttonGroupEditorComponent,
		checkboxField: checkboxFieldEditorComponent,
		colorField: colorFieldEditorComponent,
		composedChart: composedChartEditorComponent,
		container: containerEditorComponent,
		dataTable: dataTableEditorComponent,
		datetimeField: datetimeFieldEditorComponent,
		fileField: fileFieldEditorComponent,
		form: formEditorComponent,
		gaugeChart: gaugeChartEditorComponent,
		grid: gridEditorComponent,
		icon: iconEditorComponent,
		iframe: iframeEditorComponent,
		image: imageEditorComponent,
		label: labelEditorComponent,
		menuButton: menuButtonEditorComponent,
		numberField: numberFieldEditorComponent,
		oneOf: oneOfEditorComponent,
		optionsField: optionsFieldEditorComponent,
		pagination: paginationEditorComponent,
		pixelGrid: pixelGridComponent,
		propertyItem: propertyItemEditorComponent,
		qrCode: qrCodeEditorComponent,
		qrBarReader: qrBarReaderEditorComponent,
		rangeField: rangeFieldEditorComponent,
		repeat: repeatEditorComponent,
		routes: routesEditorComponent,
		sidebarLayout: sidebarLayoutEditorComponent,
		spacer: spacerEditorComponent,
		table: tableEditorComponent,
		text: textEditorComponent,
		textAreaField: textAreaFieldEditorComponent,
		textField: textFieldEditorComponent,
		treeNavigation: treeNavigationEditorComponent
	},

	schemas: {
		appHeader: appHeaderEditorSchema,
		buttonGroup: buttonGroupEditorSchema,
		chart: chartEditorSchema,
		checkboxField: checkboxFieldEditorSchema,
		colorField: colorFieldEditorSchema,
		composedChart: composedChartEditorSchema,
		datetimeField: datetimeFieldEditorSchema,
		field: fieldEditorSchema,
		fileField: fileFieldEditorSchema,
		gaugeChart: gaugeChartEditorSchema,
		grid: gridEditorSchema,
		iframe: iframeEditorSchema,
		menuButton: menuButtonEditorSchema,
		numberField: numberFieldEditorSchema,
		optionsField: optionsFieldEditorSchema,
		optionsFieldItem: optionsFieldItemEditorSchema,
		propertyItem: propertyItemEditorSchema,
		qrCode: qrCodeEditorSchema,
		qrBarReader: qrBarReaderEditorSchema,
		rangeField: rangeFieldEditorSchema,
		sidebarLayout: sidebarLayoutEditorSchema,
		table: tableEditorSchema,
		textAreaField: textAreaFieldEditorSchema,
		textField: textFieldEditorSchema,
		treeNavigation: treeNavigationEditorSchema
	}
};

export const termsRuntime = {
	components: {
		dataTable: dataTableRuntimeComponent,
		fileField: fileFieldRuntimeComponent,
		propertyItem: propertyItemRuntimeComponent,
		view: viewRuntimeComponent
	}
};
