/*
 * Use State Prop hook
 *
 * @package hae-lib-components
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 *  See LICENSE file distributed with this source code for more information.
 */

import { useCallback, useState } from "react";

/**
 * Creates state that updates when provided value updates.
 * Useful for controlled/uncontrolled components
 *
 * @param prop
 */

export function useStateProp<P>(prop: P): [P, (state: P) => void] {
	const [ current, setCurrent ] = useState(() => ({ prop, state: prop }));

	// prop change already triggered re-render, so why trigger it again with setState
	if (current.prop !== prop) {
		current.prop = prop;
		current.state = prop;
	}

	const setState = useCallback<(state: P) => void>(
		(state: P) => {
			setCurrent({ prop, state });
		},
		[ setCurrent, prop ]
	);

	return [ current.state, setState ];
}
