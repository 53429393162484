/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2023 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const RENDERER = {
	DEFAULT_SSR_ENABLED: true,
	DEFAULT_SSR_TIMEOUT: 2000,
	/** View To Html node rendering timeout */
	VIEW_TO_RENDERER_SSR_TIMEOUT: 10000
};
