/**
 * App Header schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const appHeader = {
	height: {
		label: "schema:appHeader.height#label",
		description: "schema:appHeader.height#description"
	},
	heightValues: {
		small: {
			label: "schema:appHeader.height#values.small#label",
			description: "schema:appHeader.height#values.small#label"
		},
		medium: {
			label: "schema:appHeader.height#values.medium#label",
			description: "schema:appHeader.height#values.medium#label"
		},
		large: {
			label: "schema:appHeader.height#values.large#label",
			description: "schema:appHeader.height#values.large#label"
		}
	},
	heightConstraints: {
		patternErrorMessage: "schema:appHeader.height#constraints.patternError#message"
	}
};
