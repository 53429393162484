/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IBaseError, IBaseErrorDetails } from "./IError";

/**
 * Base Error
 */
export class BaseError extends Error implements IBaseError {
	/** Error name */
	public error: string;

	/** Error message */
	public message: string;

	/** Human readable message */
	public userMessage?: string;

	public constructor(
		name: string,
		public code: string,
		message: string,
		public errorDetails: IBaseErrorDetails = {},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		public sourceError?: any
	) {
		super(message);

		this.userMessage = message;
		this.message = message;
		this.name = name;
	}
}
