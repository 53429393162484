/**
 * One Of component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useCallback, ChangeEvent } from "react";

import {
	BP,
	Type,
	defineElementaryComponent,
	RUNTIME_CONTEXT_MODE,
	ISchemaComponentListSpec,
	ISchemaConstObjectModel,
	SCHEMA_VALUE_TYPE,
	COMPONENT_MODE
} from "@hexio_io/hae-lib-blueprint";
import {
	ClassList,
	HAEComponentList,
	Icon,
	NameSchema,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { oneOf as terms } from "../../terms/editor/components/oneOf";
import { DROP_ZONE_MODE } from "@hexio_io/hae-lib-components/src/Editor/useComponentListDnD";
import { isNonEmptyString } from "@hexio_io/hae-lib-shared";

interface HAEComponentOneOf_State {
	/** Index of a matched option */
	matchIndex: number;
	/** Content of (matched) option */
	childContent: ISchemaComponentListSpec;
	/** If option was matched via a preview */
	previewIndex: number;
}

const HAEComponentOneOf_Props = {
	options: BP.Prop(
		BP.Array({
			label: terms.schema.options.label,
			description: terms.schema.options.description,
			items: BP.Object({
				label: terms.schema.optionItem.label,
				props: {
					name: BP.Prop(NameSchema(), 0),
					condition: BP.Prop(
						BP.Boolean({
							label: terms.schema.optionCondition.label,
							description: terms.schema.optionCondition.description,
							constraints: {
								required: true
							},
							default: false
						}),
						10
					),
					content: BP.Prop(
						BP.ScopedTemplate({
							label: terms.schema.optionContent.label,
							description: terms.schema.optionContent.description,
							template: BP.ComponentList({})
						}),
						20
					)
				}
			}),
			default: [
				{
					name: "",
					condition: true,
					content: []
				}
			],
			outlineOptions: {
				displayChildren: true,
				allowAddElement: true
			},
			getElementModelNodeInfo: (modelNode) => {
				return {
					label:
						modelNode.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.type === SCHEMA_VALUE_TYPE.CONST &&
						modelNode.constant.props.name.constant.value,
					icon: "mdi/help-rhombus-outline"
				};
			}
		})
	)
};

const HAEComponentOneOf_Events = {};

const HAEComponentOneOf_Definition = defineElementaryComponent<
	typeof HAEComponentOneOf_Props,
	HAEComponentOneOf_State,
	typeof HAEComponentOneOf_Events
>({
	name: "oneOf",
	category: "logic",
	label: terms.component.label,
	description: terms.component.description,
	icon: "mdi/help-rhombus",
	docUrl: "...",
	order: 20,
	nonVisual: true,
	props: HAEComponentOneOf_Props,
	events: HAEComponentOneOf_Events,

	resolve: (spec, state, updateStateAsync, cmpInstance, rCtx) => {
		/*
		 * IMPORTANT NOTE!
		 * Options must be matched after props are reconciled.
		 * It's because of condition expression that may cause selection of different
		 * option on every render pass. Which causes re-creation and destroy of
		 * child components on every render pass. Which may lead to infinite render loop.
		 */
		if (!cmpInstance.customData.__reconcileBound) {
			rCtx.__callAfterReconcile(() => {
				cmpInstance.customData.__reconcileBound = false;

				const options = cmpInstance.props.options;
				let matchIndex = null;

				for (let i = 0; i < options.length; i++) {
					const isPreview =
						rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR &&
						cmpInstance.state.previewIndex === i;

					if ((options[i].condition && matchIndex == null) || isPreview) {
						matchIndex = i;
					}
				}

				if (matchIndex !== cmpInstance.state.matchIndex) {
					updateStateAsync((prevState) => ({
						...prevState,
						matchIndex: matchIndex
					}));
				}
			});

			cmpInstance.customData.__reconcileBound = true;
		}

		if (state) {
			const optionProp = state.matchIndex !== null ? spec.options[state.matchIndex] : null;
			const childContent = optionProp
				? optionProp.content((parentScope) => {
						return parentScope;
				  }, "match_" + state.matchIndex)
				: null;

			return {
				matchIndex: state.matchIndex,
				childContent: childContent,
				previewIndex: state.previewIndex
			};
		} else {
			return {
				matchIndex: null,
				previewIndex: -1,
				childContent: null
			};
		}
	},

	getInstanceList: (_spec, state, _prevInstanceList, cmpInstance, rCtx) => {
		if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
			return [ cmpInstance ];
		} else {
			const matchContent = state?.childContent;

			if (matchContent) {
				matchContent.forEach((cmp) => {
					cmp.inheritedProps = cmpInstance.inheritedProps;
				});
			}

			return matchContent || [];
		}
	},

	getScopeData: (_spec, state) => {
		return {
			matchIndex: state.matchIndex
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {
				matchIndex: Type.Integer({
					label: terms.typeDesc.matchIndex.label,
					description: terms.typeDesc.matchIndex.description
				})
			}
		});
	}
});

const HAEComponentOneOf_React: THAEComponentReact<typeof HAEComponentOneOf_Definition> = ({
	props,
	state,
	reactComponentClassList,
	setState,
	componentInstance,
	showOptions
}) => {
	const { safePath: componentPath, componentMode } = componentInstance;

	const optsProp = (
		componentInstance.modelNode?.props as ISchemaConstObjectModel<typeof HAEComponentOneOf_Props>
	)?.props.options;
	const optsModel = optsProp && optsProp.type === SCHEMA_VALUE_TYPE.CONST ? optsProp.constant : null;

	const selectPreview = useCallback(
		(ev: ChangeEvent<HTMLSelectElement>) => {
			if (setState) {
				setState((prevState) => ({
					...prevState,
					previewIndex: parseInt(ev.currentTarget.value, 10)
				}));
			}
		},
		[ setState ]
	);

	const addOption = useCallback(() => {
		if (optsModel) {
			optsModel.schema.addElement(
				optsModel,
				{
					name: "",
					condition: false,
					content: []
				},
				true
			);

			setState((prevState) => ({
				...prevState,
				previewIndex: optsModel.items.length - 1
			}));
		}
	}, [ optsModel ]);

	const removeOption = useCallback(() => {
		if (optsModel && state.matchIndex !== null) {
			optsModel.schema.removeElement(optsModel, state.matchIndex, true);
		}
	}, [ optsModel, state.matchIndex ]);

	const { classList } = ClassList.getElementClassListAndIdClassName("cmp-one-of", componentPath, {
		componentInstance,
		componentClassList: reactComponentClassList
	});

	// eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
	const contentModel = (componentInstance.modelNode as any)?.props.props.options.constant.items[
		state.matchIndex
	]?.constant?.props.content.template;

	return (
		<div className={classList.toClassName()}>
			{componentMode === COMPONENT_MODE.EDIT && showOptions ? (
				<div className="hae-editor-controls">
					<span className="hae-editor-controls__label">
						Current option: {state.matchIndex !== null ? `#${state.matchIndex + 1}` : "none"}
					</span>
					<select
						className="hae-editor-controls__select"
						value={state.previewIndex}
						onChange={selectPreview}
					>
						{/* @todo add translations */}
						<option value={-1}>No preview</option>
						{props.options.map((option, index) => {
							return (
								<option key={index} value={index}>
									Preview option #{index + 1}
									{isNonEmptyString(option.name) ? ` (${option.name})` : ""}
								</option>
							);
						})}
					</select>
					{optsModel ? (
						<>
							<button
								type="button"
								className="hae-editor-controls__button"
								onClick={addOption}
								title="Add new option"
							>
								<Icon
									source="mdi/plus"
									size="SMALL"
									componentPath={componentPath}
									componentMode={componentMode}
								/>
								<span>Add</span>
							</button>
							{state.matchIndex !== null ? (
								<button
									className="hae-editor-controls__button hae-editor-controls__button--delete"
									onClick={removeOption}
									title="Remove current option"
								>
									<Icon
										source="mdi/delete"
										size="SMALL"
										componentPath={componentPath}
										componentMode={componentMode}
									/>
								</button>
							) : null}
						</>
					) : null}
				</div>
			) : null}
			{props.options.length > 0 ? (
				state?.childContent ? (
					<HAEComponentList
						components={state.childContent}
						componentPath={[ ...componentPath, "component-list" ]}
						componentMode={componentMode}
						classList={new ClassList("cmp-one-of__content")}
						childClassList={new ClassList("cmp-one-of__item", "item")}
						childComponentClassList={new ClassList("item__component")}
						modelNode={contentModel}
						modifyModelOnDrop={() => null}
						dropZoneMode={DROP_ZONE_MODE.SINGLE}
					/>
				) : (
					<div className="cmp-one-of__note">
						<div className="cmp-one-of__note-text">No matching option.</div>
					</div>
				)
			) : (
				<div className="cmp-one-of__note">
					<div className="cmp-one-of__note-text">No options.</div>
				</div>
			)}
		</div>
	);
};

export const HAEComponentOneOf: THAEComponentDefinition<typeof HAEComponentOneOf_Definition> = {
	...HAEComponentOneOf_Definition,
	reactComponent: HAEComponentOneOf_React,
	hasOptions: true
};
