/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { TGetBlueprintSchemaSpec, Type } from "@hexio_io/hae-lib-blueprint";
import {
	NODE_OUTPUT_NAMES,
	TAllNodesSpec,
	TBlueprintActionNodeTypes,
	TBlueprintFileReadNodeOptsSchemaSpec,
} from "../../blueprints";
import { ERROR_CODES, ERROR_NAMES } from "../../errors";
import { IActionContext, INodeContext, INodeResult } from "../ActionManager";
import { createErrorNodeResult, createSuccessNodeResult } from "../helpers";

export async function fileReadNodeHandler<TSpec extends Partial<TAllNodesSpec>>(
	opts: TBlueprintFileReadNodeOptsSchemaSpec,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> {
	const { file, outputEncoding } = opts as TGetBlueprintSchemaSpec<TBlueprintActionNodeTypes["fileRead"]["opts"]>;
	const { app } = aCtx;

	try {
		const fileContents = await app.get('tempFileService').readBuffer(file.fileId);
		let result = "";

		switch(outputEncoding) {
			case "base64": {
				result = fileContents.toString("base64");
				break;
			}
			case "utf8": {
				result = fileContents.toString("utf8");
				break;
			}
		}

		return createSuccessNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_SUCCESS,
				data: result,
				typeDescriptor: Type.String({})
			},
			aCtx,
			nCtx
		);
	} catch (error) {
		return createErrorNodeResult(
			{
				opts,
				outputName: NODE_OUTPUT_NAMES.ON_ERROR,
				data: {
					name: ERROR_NAMES.FILE_READ_ERROR,
					code: ERROR_CODES.FILE_READ_ERROR,
					message: error?.message || "Failed to read temp file. Is the provided fileId correct?"
				},
				typeDescriptor: Type.Object({
					props: {
						message: Type.String({}),
						name: Type.String({})
					}
				}),
				error
			},
			aCtx,
			nCtx
		);
	}
}
