/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

import {
	ClassList,
	Container,
	CONTAINER_DEFAULT_HORIZONTAL_ALIGN,
	CONTAINER_DEFAULT_VERTICAL_ALIGN,
	ErrorBoundary,
	getMedia,
	HAE_RENDER_MODE,
	HAEComponentMainContext,
	LoadingInfo,
	MediaContext,
	Overlays,
	T,
	ToastMessages,
	TranslationContext,
	TRootComponentListSchema,
	useEditContext,
	Viewport
} from "@hexio_io/hae-lib-components";
import { isFunction, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { IRenderContext } from "./IRenderContext";
import { RouterComponent } from "./RouterComponent";
import {
	COMPONENT_MODE,
	RUNTIME_CONTEXT_MODE,
	TGetBlueprintSchemaModel
} from "@hexio_io/hae-lib-blueprint/src";
import { termsRuntime } from "../terms";
import { StylingContextProvider } from "../../../hae-lib-components/src/Styling/StylingContext";
import { StylingManager } from "@hexio_io/hae-lib-components/src/Styling/StylingManager";
import { useForkRef } from "./useForkRef";
import { StylingTheme } from "./StylingTheme";

export interface IViewportConfig {
	minWidth?: string;
	maxWidth?: string;
	width: string;
	height: string;
	zoom: number;
	showBoundaries: boolean;
	showSafeArea: boolean;
}

/**
 * Component props
 */
export interface IMainProps {
	stylingManager?: StylingManager;
	renderContext: IRenderContext;
	renderMode: HAE_RENDER_MODE;
	wasRenderedWithSsr: boolean;
	appRootId: string;
	viewportConfig?: IViewportConfig;
	mainComponentListModel?: TGetBlueprintSchemaModel<TRootComponentListSchema>;
	onRootClick?: (ev: MouseEvent) => void;
	renderCallback?: () => void;
}

const termErrorContactVars = {
	contactLink: "support@adapptio.com"
};

/**
 * Main Application Component
 *
 * @param props Component props
 */
export const Main = React.forwardRef<HTMLDivElement, IMainProps>(
	(
		{
			stylingManager,
			renderContext,
			renderMode,
			wasRenderedWithSsr,
			appRootId,
			viewportConfig,
			mainComponentListModel,
			onRootClick,
			renderCallback
		},
		ref
	) => {
		const rCtx = renderContext.getRuntimeContext();

		const editContext = useEditContext();

		const idClassName = `hae-app-root--id-${appRootId}`;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [ _update, setUpdate ] = useState(0);
		const forceUpdate = useMemo(() => {
			return () => setUpdate((prevState) => prevState + 1);
		}, []);

		const [ componentListSpec, setComponentListSpec ] = useState({
			content: renderContext.getComponentListSpec()
		});

		const elementRef = useRef<HTMLElement>();
		const forkRef = useForkRef(elementRef, ref);

		// Editable, render context mode & component mode

		const [ renderContextMode, setRenderContextMode ] = useState(renderContext?.mode);

		const componentMode = useMemo(() => {
			return renderContextMode === RUNTIME_CONTEXT_MODE.NORMAL
				? COMPONENT_MODE.NORMAL
				: renderContextMode === RUNTIME_CONTEXT_MODE.EDITOR
				? COMPONENT_MODE.EDIT
				: COMPONENT_MODE.READONLY;
		}, [ renderContextMode ]);

		useEffect(() => {
			const handleModeChange = () => {
				setRenderContextMode(renderContext?.mode);
			};

			onEvent(renderContext.onModeChange, handleModeChange);

			return () => {
				offEvent(renderContext.onModeChange, handleModeChange);
			};
		}, [ renderContext, setRenderContextMode ]);

		// Ready, fix references

		useEffect(() => {
			forceUpdate();
		}, []);

		// Viewport

		const inEditor = rCtx.isInEditor();

		const viewport = useMemo(
			() => new Viewport(elementRef.current || Viewport.DEFAULT_ROOT_SELECTOR, inEditor),
			[]
		);

		useEffect(() => {
			viewport.setRootElement(elementRef.current || Viewport.DEFAULT_ROOT_SELECTOR);
			viewport.setInEditor(inEditor);
			viewport.fix();

			return () => {
				viewport.removeAllEventListeners();
			};
		}, [ idClassName, elementRef.current, inEditor ]);

		useEffect(() => {
			window.dispatchEvent(new CustomEvent(Viewport.RESIZE_EVENT_TYPE));
		}, [ viewportConfig?.showSafeArea ]);

		// Render context onRender event

		useEffect(() => {
			const _contextRenderHandler = () => {
				setComponentListSpec({
					content: renderContext.getComponentListSpec()
				});
			}

			onEvent(renderContext.onRender, _contextRenderHandler);
			renderContext.getRuntimeContext().render();

			return () => {
				offEvent(renderContext.onRender, _contextRenderHandler);
			};
		}, [ renderContext ]);

		// Overlay and Toast Message managers onUpdate event

		useEffect(() => {
			function _updateHandler() {
				forceUpdate();
			}

			onEvent(renderContext.overlayManager.onUpdate, _updateHandler);
			onEvent(renderContext.toastMessageManager.onUpdate, _updateHandler);

			return () => {
				offEvent(renderContext.overlayManager.onUpdate, _updateHandler);
				offEvent(renderContext.toastMessageManager.onUpdate, _updateHandler);
			};
		}, [ renderContext.overlayManager, renderContext.toastMessageManager ]);

		const loading = !componentListSpec.content;

		const classList = new ClassList("hae-app-root", idClassName);

		classList.addModifiers({
			loading,
			"safe-area": viewportConfig?.showSafeArea,
			application: !inEditor,
			editor: inEditor,
			media: getMedia(editContext)
		});

		if (viewportConfig?.showBoundaries) {
			classList.add("editor-viewport--show-boundaries");
		}

		useEffect(() => {
			if (isFunction(renderCallback)) {
				renderCallback();
			}
		}, []);

		return (
			<TranslationContext.Provider
				value={{
					translate: renderContext.translateFn
				}}
			>
				<StylingContextProvider stylingManager={stylingManager}>
					<StylingTheme themeProvider={renderContext.themeProvider}>
						<div className={classList.toClassName()} ref={forkRef} onClick={onRootClick}>
							<ErrorBoundary
								fallback={
									<div className="hae-app-root__error">
										<p className="hae-app-root__error-paragraph">
											<b>
												<T domain="runtime" term={termsRuntime.appError.info} />
											</b>
										</p>
										<p className="hae-app-root__error-paragraph">
											<T
												domain="runtime"
												term={termsRuntime.appError.contact}
												vars={termErrorContactVars}
											/>
										</p>
									</div>
								}
							>
								<HAEComponentMainContext.Provider
									value={{
										componentRegistry: renderContext.componentRegistry,
										rCtx,
										mode: renderContextMode,
										renderMode,
										wasRenderedWithSsr,
										viewport,
										toastMessageManager: renderContext.toastMessageManager,
										appRootId
									}}
								>
									<RouterComponent routingManager={renderContext.routingManager}>
										<MediaContext.Provider
											value={{
												iconPackageUrlMapping: renderContext.iconPackageUrlMapping
											}}
										>
											{!loading ? (
												<Container
													content={componentListSpec.content}
													componentPath={[ `root-${appRootId}` ]}
													componentMode={componentMode}
													horizontalAlign={CONTAINER_DEFAULT_HORIZONTAL_ALIGN}
													verticalAlign={CONTAINER_DEFAULT_VERTICAL_ALIGN}
													contentModelNode={mainComponentListModel}
												/>
											) : (
												<LoadingInfo
													classList={new ClassList("hae-app-root__loading-info")}
												/>
											)}

											<Overlays manager={renderContext.overlayManager} />
											<ToastMessages manager={renderContext.toastMessageManager} />
										</MediaContext.Provider>
									</RouterComponent>
								</HAEComponentMainContext.Provider>
							</ErrorBoundary>
						</div>
					</StylingTheme>
				</StylingContextProvider>
			</TranslationContext.Provider>
		);
	}
);

Main.displayName = "MainComponent";
