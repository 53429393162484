/**
 * Number Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, COMPONENT_MODE, Type, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";

import {
	addFormItem,
	ClassList,
	ENUM_DEFAULT_VALUE,
	ForegroundColorSchema,
	getStringEnumValue,
	getValuesFromStringEnum,
	Label,
	propGroups,
	removeFormItem,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { isDefined, isNil, isNilOrEmptyString } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { FieldBaseProps } from "./props";
import { NUMBER_FIELD_TYPE } from "../../Enums/NUMBER_FIELD_TYPE";
import { NUMBER_FIELD_SLIDER_MODE } from "../../Enums/NUMBER_FIELD_SLIDER_MODE";
import { getFieldStateProps, useField } from "./useField";
import { NumberFieldSlider } from "./NumberFieldSlider";
import { NumberFieldInput } from "./NumberFieldInput";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

interface HAEComponentNumberField_State extends IFieldState {
	initialValue: number;
	value: number;
}

const HAEComponentNumberField_Props = {
	...FieldBaseProps,

	value: BP.Prop(
		BP.Float({
			...termsEditor.schemas.numberField.value,
			default: null,
			fallbackValue: NaN,
			constraints: {
				required: false
			}
		}),
		0,
		propGroups.common
	),

	typeData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.numberField.typeData,
			defaultType: "DEFAULT",
			types: {
				DEFAULT: {
					...termsEditor.schemas.numberField.typeDataValues.default,
					value: BP.Object({
						props: {
							min: BP.Prop(
								BP.Float({
									...termsEditor.schemas.numberField.min
								}),
								10
							),
							max: BP.Prop(
								BP.Float({
									...termsEditor.schemas.numberField.max
								}),
								20
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				SLIDER: {
					...termsEditor.schemas.numberField.typeDataValues.slider,
					value: BP.Object({
						props: {
							min: BP.Prop(
								BP.Float({
									...termsEditor.schemas.numberField.min,
									default: 0,
									fallbackValue: 0,
									constraints: {
										required: true
									}
								}),
								10
							),
							max: BP.Prop(
								BP.Float({
									...termsEditor.schemas.numberField.max,
									default: 100,
									fallbackValue: 100,
									constraints: {
										required: true
									}
								}),
								20
							),
							mode: BP.Prop(
								BP.Enum.String({
									...termsEditor.schemas.numberField.mode,
									options: getValuesFromStringEnum(
										NUMBER_FIELD_SLIDER_MODE,
										termsEditor.schemas.numberField.modeValues
									),
									default: ENUM_DEFAULT_VALUE
								}),
								30
							),
							marks: BP.Prop(
								BP.Array({
									...termsEditor.schemas.numberField.marks,
									label: "Marks",
									items: BP.Object({
										props: {
											position: BP.Prop(
												BP.Float({
													...termsEditor.schemas.numberField.markPosition,
												})
											),
											label: BP.Prop(
												BP.String({
													...termsEditor.schemas.numberField.markLabel,
												})
											),
											color: BP.Prop(
												ForegroundColorSchema({
													...termsEditor.schemas.numberField.markColor,
												})
											)
										}
									}),
									getElementModelNodeInfo: (modelNode) => {
										let label: string;
										if (modelNode.type === SCHEMA_VALUE_TYPE.CONST) {
											if (modelNode.constant.props.position.type === SCHEMA_VALUE_TYPE.CONST) {
												label = String(modelNode.constant.props.position.constant.value);
											} else {
												label = modelNode.constant.props.position.expression?.value ?? '(exp)';
											}
										} else {
											label = modelNode.expression?.value ?? '(exp)';
										}

										return {
											label: label,
											icon: null
										};
									}
								}),
								40
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}
			}
		}),
		10,
		propGroups.common
	),

	placeholder: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.placeholder
		}),
		20,
		propGroups.common
	),

	step: BP.Prop(
		BP.Float({
			...termsEditor.schemas.numberField.step
		}),
		200,
		propGroups.validation
	),

	htmlAutocomplete: BP.Prop(
		BP.String({
			...termsEditor.schemas.field.htmlAutocomplete
		}),
		0,
		propGroups.advanced
	),

	inputDebounceMs: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.field.inputDebounceMs,
			default: 0
		}),
		200,
		propGroups.state
	)
};

const HAEComponentNumberField_Events = {
	...HAEComponentField_Events
};

function isNumberFieldEmpty(value: number): boolean {
	return !Number.isFinite(value);
}

const HAEComponentNumberField_Definition = defineElementaryComponent<
	typeof HAEComponentNumberField_Props,
	HAEComponentNumberField_State,
	typeof HAEComponentNumberField_Events
>({
	...termsEditor.components.numberField.component,

	name: "numberField",

	category: "form",

	icon: "mdi/counter",

	docUrl: "...",

	order: 30,

	props: HAEComponentNumberField_Props,

	events: HAEComponentNumberField_Events,

	resolve: (spec, state, updateStateAsync, componentInstance, _rCtx, scope) => {
		const initialValue =
			(isDefined(spec.value) && spec.value !== null && !Number.isNaN(spec.value) ? spec.value : state?.initialValue) ?? null;
		const value =
			state?.initialValue === initialValue
				? isDefined(state?.value) && !Number.isNaN(state.value)
					? state.value
					: spec.value
				: spec.value;

		function setValue(value: number) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? null : initialValue }));
		}

		const newState = {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate, isNumberFieldEmpty),
			setValue,
			clearValue
		};

		addFormItem(scope, {
			uid: componentInstance.uid,
			name: spec.fieldName || componentInstance.id,
			value: newState.value,
			changed: newState.changed || false,
			valid: newState.valid || false,
			clearValue: clearValue
		});

		return newState;
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.Float({ ...termsEditor.schemas.numberField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.Float({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	},

	destroy: (_props, _state, componentInstance, _rCtx, scope) => {
		removeFormItem(scope, componentInstance.uid);
	}
});

const HAEComponentNumberField_React: THAEComponentReact<typeof HAEComponentNumberField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		typeData,
		placeholder,
		step,
		htmlAutocomplete,
		fieldName,
		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		validate,
		required,
		customValidation,
		inputDebounceMs
	} = props;

	const { value, empty, touched, changed, valid } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const { min, max } = typeData.value[typeData.type];

	const componentPath = componentInstance.safePath;

	let typeValue = getStringEnumValue(NUMBER_FIELD_TYPE, typeData.type);

	if (typeValue === NUMBER_FIELD_TYPE.SLIDER && (isNilOrEmptyString(min) || isNilOrEmptyString(max))) {
		typeValue = NUMBER_FIELD_TYPE.DEFAULT;
	}

	const isSlider = typeValue === NUMBER_FIELD_TYPE.SLIDER;

	const sliderModeValue = isSlider
		? getStringEnumValue(NUMBER_FIELD_SLIDER_MODE, typeData.value.SLIDER.mode)
		: null;

	const sliderMarksValue = isSlider
		? typeData.value.SLIDER.marks
		: undefined;

	// Classlist

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);
	const id = idClassName;

	classList.addModifiers({
		number: true,
		"number-type": typeValue,
		"has-marks": sliderMarksValue?.length > 0,
		validate
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }),
		false
	);

	if (sliderModeValue) {
		classList.add(`cmp-field--number-slider-mode-${sliderModeValue}`);
	}

	const sliderValidator = React.useCallback(
		(value: number) =>
			isNil(value) ? !required : (isNil(min) || value >= min) && (isNil(max) || value <= max),
		[ min, max, required ]
	);

	const { setValue, setTouched } = useField<number>(
		{
			id,
			state,
			readOnly,
			validate: validate && (!isSlider || sliderValidator),
			customValidation,
			validationDependencies: [ enabled, required, min, max ],
			fixDefaultValue: typeValue === NUMBER_FIELD_TYPE.DEFAULT,
			isEmpty: isNumberFieldEmpty,
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	const inputProps = {
		id,
		name: fieldName || id,
		className: `cmp-field__${typeValue}`,
		value,
		placeholder,
		autoComplete: htmlAutocomplete,
		readOnly: elementReadOnly,
		disabled: !enabled,
		required,
		min,
		max,
		step
	};

	// Event handlers

	const _inputBlurHandler = React.useCallback(() => {
		setTouched();
	}, [ setTouched ]);

	const _numberChangeHandler = React.useCallback(
		(value: number) => {
			setValue(value);
		},
		[ setValue ]
	);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				{(() => {
					switch (typeValue) {
						case NUMBER_FIELD_TYPE.DEFAULT:
							return (
								<NumberFieldInput
									{...inputProps}
									inputDebounceMs={inputDebounceMs}
									onBlur={_inputBlurHandler}
									onValueChange={_numberChangeHandler}
								/>
							);

						case NUMBER_FIELD_TYPE.SLIDER:
							return (
								<NumberFieldSlider
									className={inputProps.className}
									min={inputProps.min}
									max={inputProps.max}
									step={inputProps.step}
									disabled={inputProps.disabled || inputProps.readOnly}
									defaultValue={state.initialValue}
									value={inputProps.value}
									reverse={sliderModeValue === NUMBER_FIELD_SLIDER_MODE.FROM_END}
									marks={sliderMarksValue}
									onChange={_numberChangeHandler}
								/>
							);
					}
				})()}
			</div>

			<FieldInfo
				descriptionText={descriptionText}
				componentPath={[ ...componentPath, "info" ]}
				componentMode={componentMode}
			/>
		</div>
	);
};

export const HAEComponentNumberField: THAEComponentDefinition<typeof HAEComponentNumberField_Definition> = {
	...HAEComponentNumberField_Definition,
	reactComponent: HAEComponentNumberField_React
};
