/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	TBlueprintIDTNode,
	TBlueprintIDTNodePath,
	TGetBlueprintIDTNodeByType
} from "../IDT/ISchemaIDT";
import { DesignContext } from "./DesignContext";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IDocumentRange } from "../Shared/IDocumentRange";
import { IParseInfo } from "../Shared/IParseInfo";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

/**
 * Converts parse info into a string message
 *
 * @param parseInfo
 */
export function parseInfoToString(parseInfo: IParseInfo): string {
	return (
		"'" +
		parseInfo.loc.uri +
		"'" +
		(parseInfo.loc.range
			? " at position " + parseInfo.loc.range.start.line + ":" + parseInfo.loc.range.start.col
			: "")
	);
}

/**
 * Converts parse range into a string message
 *
 * @param parseInfo
 */
export function parseRangeToString(range: IDocumentRange): string {
	return "at position " + range.start.line + ":" + range.start.col;
}

/**
 * Converts IDT node path to string
 *
 * @param nodePath Node path
 */
export function nodePathToString(nodePath: TBlueprintIDTNodePath): string {
	return nodePath.join(".");
}

/**
 * Maps document error severity enum to string labels
 * @param severity
 */
export function mapDocumentErrorSeverity(severity: DOC_ERROR_SEVERITY): string {
	switch (severity) {
		case DOC_ERROR_SEVERITY.ERROR:
			return "[error]";
		case DOC_ERROR_SEVERITY.WARNING:
			return "[warn] ";
		case DOC_ERROR_SEVERITY.INFO:
			return "[info] ";
		case DOC_ERROR_SEVERITY.HINT:
			return "[hint] ";
	}
}

export interface IIDTValidateRule {
	idtType?: BP_IDT_TYPE;
	idtScalarSubType?: BP_IDT_SCALAR_SUBTYPE;
	required: boolean;
	provideCompletion?: (
		dCtx: DesignContext,
		parentLoc: IDocumentLocation,
		minColumn: number,
		idt: TBlueprintIDTNode
	) => void;
}

export interface IExtractPropRules {
	[K: string]: IIDTValidateRule;
}

type TExtractedProps<TPropRules extends IExtractPropRules> = {
	[K in keyof TPropRules]: IBlueprintIDTMapElement<TGetBlueprintIDTNodeByType<TPropRules[K]["idtType"]>>;
};

export function validateIDTNode<TValidateRule extends IIDTValidateRule>(
	dCtx: DesignContext,
	idtNode: TBlueprintIDTNode,
	rule: TValidateRule
): {
	node: TGetBlueprintIDTNodeByType<TValidateRule["idtType"]>;
	isValid: boolean;
} {
	let expTypeName: string;
	let errorName: DOC_ERROR_NAME;
	let isValid = true;

	// Check for null
	if (
		!rule.required &&
		idtNode &&
		idtNode.type === BP_IDT_TYPE.SCALAR &&
		idtNode.subType === BP_IDT_SCALAR_SUBTYPE.NULL
	) {
		return {
			isValid: isValid,
			node: null
		};
	}

	// Determine type and error name
	if (rule.idtType === BP_IDT_TYPE.SCALAR) {
		switch (rule.idtScalarSubType) {
			case BP_IDT_SCALAR_SUBTYPE.BOOLEAN:
				expTypeName = "boolean";
				errorName = DOC_ERROR_NAME.BOOL_NOT_BOOLEAN;
				break;
			case BP_IDT_SCALAR_SUBTYPE.DATE:
				expTypeName = "date";
				errorName = DOC_ERROR_NAME.DATE_NOT_DATE;
				break;
			case BP_IDT_SCALAR_SUBTYPE.FLOAT:
				expTypeName = "float";
				errorName = DOC_ERROR_NAME.FLOAT_NOT_NUMBER;
				break;
			case BP_IDT_SCALAR_SUBTYPE.INTEGER:
				expTypeName = "integer";
				errorName = DOC_ERROR_NAME.INT_NOT_NUMBER;
				break;
			case BP_IDT_SCALAR_SUBTYPE.NULL:
				expTypeName = "null";
				errorName = DOC_ERROR_NAME.INVALID_NODE;
				break;
			case BP_IDT_SCALAR_SUBTYPE.REGEXP:
				expTypeName = "regexp";
				errorName = DOC_ERROR_NAME.INVALID_NODE;
				break;
			case BP_IDT_SCALAR_SUBTYPE.STRING:
				expTypeName = "string";
				errorName = DOC_ERROR_NAME.STR_NOT_STRING;
				break;
		}
	} else {
		switch (rule.idtType) {
			case BP_IDT_TYPE.LIST:
				expTypeName = "list";
				errorName = DOC_ERROR_NAME.LIST_NOT_LIST;
				break;
			case BP_IDT_TYPE.MAP:
				expTypeName = "map";
				errorName = DOC_ERROR_NAME.MAP_NOT_MAP;
				break;
			case BP_IDT_TYPE.MAP_ELEMENT:
				expTypeName = "map element";
				errorName = DOC_ERROR_NAME.INVALID_NODE;
				break;
		}
	}

	// Check if required
	if (rule.required && !idtNode) {
		isValid = false;
	}

	// Check type
	if (rule.idtType && idtNode && idtNode.type !== rule.idtType) {
		if (idtNode.parseInfo) {
			dCtx.logParseError(idtNode.parseInfo.loc.uri, {
				range: idtNode.parseInfo.loc.range,
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: errorName,
				message: `Expecting a ${expTypeName}.`,
				parsePath: idtNode.path
			});
		}

		isValid = false;
	}

	// Check subtype
	if (
		rule.idtType === BP_IDT_TYPE.SCALAR &&
		rule.idtScalarSubType &&
		idtNode &&
		idtNode.type === BP_IDT_TYPE.SCALAR &&
		idtNode.subType !== rule.idtScalarSubType
	) {
		if (idtNode.parseInfo) {
			dCtx.logParseError(idtNode.parseInfo.loc.uri, {
				range: idtNode.parseInfo.loc.range,
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: errorName,
				message: `Expecting a ${expTypeName}.`,
				parsePath: idtNode.path
			});
		}

		isValid = false;
	}

	return {
		isValid: isValid,
		node: idtNode as TGetBlueprintIDTNodeByType<TValidateRule["idtType"]>
	};
}

export function extractAndValidateIDTMapProperties<TPropRules extends IExtractPropRules>(
	dCtx: DesignContext,
	idtNode: IBlueprintIDTMap,
	props: TPropRules
): {
	keys: TExtractedProps<TPropRules>;
	isValid: boolean;
	keysValid: { [K in keyof TPropRules]: boolean };
} {
	const keys: TExtractedProps<TPropRules> = {} as TExtractedProps<TPropRules>;
	const keysValid = {} as { [K in keyof TPropRules]: boolean };

	for (let i = 0; i < idtNode.items.length; i++) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		keys[idtNode.items[i].key.value as keyof TExtractedProps<TPropRules>] = idtNode.items[i] as any;
	}

	// const addKeyCompletion = (keyLocation: IDocumentLocation) => {
	// 	dCtx.__addCompletition(
	// 		keyLocation.uri,
	// 		keyLocation.range,
	// 		keyLocation.range.start.col + 1,
	// 		() => {
	// 			const items: ICompletionItem[] = [];

	// 			for (const k in props) {
	// 				items.push({
	// 					kind: CMPL_ITEM_KIND.Property,
	// 					label: k,
	// 					insertText: `${k}`
	// 				});
	// 			}

	// 			return items;
	// 		}
	// 	);
	// };

	let hasValidKeys = true;

	for (const k in props) {
		let _isValid = true;

		// Check if required
		if (props[k].required && (!keys[k] || !keys[k].value)) {
			const _parseInfo = idtNode.parentKeyParseInfo ? idtNode.parentKeyParseInfo : idtNode.parseInfo;

			if (_parseInfo) {
				dCtx.logParseError(_parseInfo.loc.uri, {
					range: _parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.OBJ_MISSING_PROPERTY,
					message: `Property '${k}' is required and must be specified.`,
					parsePath: idtNode.path
				});
			}

			_isValid = false;
		}

		if (keys[k]) {
			const { isValid: isKeyValid } = validateIDTNode(dCtx, keys[k].value, props[k]);

			// if (keys[k].key) {
			// 	addKeyCompletion(keys[k].key.parseInfo.loc);
			// }

			if (keys[k].key && keys[k].parseInfo?.loc?.range && props[k].provideCompletion) {
				props[k].provideCompletion(
					dCtx,
					{
						uri: idtNode.parseInfo.loc.uri,
						range: {
							start: {
								line: keys[k].key.parseInfo.loc.range.end.line,
								col: keys[k].key.parseInfo.loc.range.end.col + 2
							},
							end: keys[k].parseInfo.loc.range.end
						}
					},
					idtNode.parseInfo.loc.range.start.col + 1,
					keys[k].value
				);
			}

			if (!isKeyValid) {
				_isValid = false;
			}
		}

		keysValid[k] = _isValid;

		if (!_isValid) {
			hasValidKeys = false;
		}
	}

	// Check redundant (unknown) root properties
	for (const k in keys) {
		if (!Object.keys(props).includes(k) && keys[k].parseInfo) {
			dCtx.logParseError(keys[k].parseInfo.loc.uri, {
				range: keys[k].parseInfo?.loc?.range,
				severity: DOC_ERROR_SEVERITY.WARNING,
				name: DOC_ERROR_NAME.OBJ_UNKNOWN_PROPERTY,
				message: "Unknown property '" + k + "'.",
				parsePath: keys[k].path
			});
		}
	}

	return {
		keys: keys,
		isValid: hasValidKeys,
		keysValid: keysValid
	};
}

export function provideIDTMapPropertyCompletions<
	TExtractedKeys extends { [K: string]: IBlueprintIDTMapElement }
>(
	dCtx: DesignContext,
	idtNode: IBlueprintIDTMap,
	keys: TExtractedKeys,
	completions: {
		[K in keyof Partial<TExtractedKeys>]: (
			dCtx: DesignContext,
			parentLoc: IDocumentLocation,
			minColumn: number,
			idt: TBlueprintIDTNode
		) => void;
	}
): void {
	if (!idtNode.parseInfo) {
		return;
	}

	for (const k in keys) {
		if (!keys[k] || !keys[k].key || !keys[k].parseInfo || !keys[k].key?.parseInfo) {
			continue;
		}

		if (completions[k]) {
			completions[k](
				dCtx,
				{
					uri: idtNode.parseInfo.loc.uri,
					range: {
						start: {
							line: keys[k].key.parseInfo.loc.range.end.line,
							col: keys[k].key.parseInfo.loc.range.end.col + 2
						},
						end: keys[k].parseInfo.loc.range.end
					}
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				keys[k].value
			);
		} else {
			dCtx.__addCompletition(
				idtNode.parseInfo.loc.uri,
				{
					start: {
						line: keys[k].key.parseInfo.loc.range.end.line,
						col: keys[k].key.parseInfo.loc.range.end.col + 2
					},
					end: keys[k].parseInfo.loc.range.end
				},
				idtNode.parseInfo.loc.range.start.col + 1,
				() => []
			);
		}
	}
}

export function provideIDTMapRootCompletions(
	dCtx: DesignContext,
	parentLoc: IDocumentLocation,
	minColumn: number,
	idtNode: TBlueprintIDTNode,
	props: IExtractPropRules
): void {
	if (!idtNode?.parseInfo || !parentLoc) {
		return;
	}

	dCtx.__addCompletition(
		parentLoc.uri,
		{
			start: {
				line: parentLoc.range.start.line,
				col: parentLoc.range.start.col
			},
			end: idtNode ? idtNode.parseInfo.loc.range.end : parentLoc.range.end
		},
		minColumn,
		() => {
			const items: ICompletionItem[] = [];

			const existingKeys = new Set<string>();

			if (idtNode && idtNode.type === BP_IDT_TYPE.MAP) {
				for (let i = 0; i < idtNode.items.length; i++) {
					if (idtNode.items[i].key) {
						existingKeys.add(idtNode.items[i].key.value as string);
					}
				}
			}

			for (const k in props) {
				if (!existingKeys.has(k)) {
					items.push({
						kind: CMPL_ITEM_KIND.Property,
						label: k,
						insertText: `${k}`
					});
				}
			}

			return items;
		}
	);
}
