/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";
import { createBlueprintSchema } from "./BlueprintBase";
import { DOC_TYPES } from "./DocTypes";

const customCssBlueprintSchema = BP.Const.Array({
	items: BP.Const.Object({
		props: {
			name: BP.Prop(
				BP.Const.String({
					editorOptions: {
						hidden: true
					}
				})
			),
			content: BP.Prop(
				BP.Const.String({
					default: ".myclass {\n    color: #000;\n}",
					editorOptions: {
						hidden: true
					}
				})
			),
			preprocessor: BP.Prop(
				BP.Const.Enum({
					label: "Preprocessor",
					value: BP.Const.String({
						default: "NONE"
					}),
					options: [
						{
							label: "none",
							value: "NONE"
						}
					],
					editorOptions: {
						hidden: true
					}
				})
			)
		}
	}),
	editorOptions: {
		hidden: true
	}
});

const themeStyleBlueprintSchema = BP.Const.Object({
	props: {
		label: BP.Prop(BP.Const.String({})),
		customCss: BP.Prop(customCssBlueprintSchema)
	}
});

/**
 * Theme spec
 */
// TODO: add translations
export const BlueprintThemeSpec = BP.Const.Object({
	props: {
		label: BP.Prop(BP.Const.String({})),
		description: BP.Prop(BP.Const.String({})),
		customCss: BP.Prop(customCssBlueprintSchema),
		styles: BP.Prop(
			BP.Const.Map({
				value: themeStyleBlueprintSchema
			})
		)
	}
});

export type TBlueprintThemeCustomCssSchema = typeof customCssBlueprintSchema;
export type TBlueprintThemeStyleSchema = typeof themeStyleBlueprintSchema;
export type TBlueprintThemeSpecSchema = typeof BlueprintThemeSpec;

/**
 * Theme Blueprint Schema
 */
export const BlueprintTheme = createBlueprintSchema(DOC_TYPES.THEME_V1, BlueprintThemeSpec);

export type TBlueprintThemeSchema = typeof BlueprintTheme;
