/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export const BlueprintActionNode_v2 = {
	name: NODE_TYPES.ACTION_V2,
	label: "Action",
	icon: "mdi/motion-play",
	opts: BP.Object({
		props: {
			action: BP.Prop(BP.ActionRef({
				label: "Action",
				description: "Invokes action.",
				constraints: {
					required: true
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})),
			runInBackground: BP.Prop(BP.Boolean({
				label: "Run in background",
				// eslint-disable-next-line max-len
				description: "If to run the action in background. If true, the action will be invoked asynchronously and the flow will continue immediately.",
				default: false
			}))
		},
		editorOptions: {
			layoutType: "passthrough"
		}
	}),
	editorOptions: {
		displayInPalette: true
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR,
		[NODE_OUTPUT_NAMES.ON_FAIL]: NODE_OUTPUTS.ON_FAIL
	})
};

export type TBlueprintActionNodeOptsSchemaSpec_v2 = TGetBlueprintSchemaSpec<typeof BlueprintActionNode_v2["opts"]>;
