/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	DesignContext,
	DOC_ERROR_SEVERITY,
	importSchema,
	IRuntimeError,
	ISchemaConstObject,
	ISchemaValue,
	loadCompiledModel,
	RuntimeContext,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintAction, DOC_TYPES, NODE_TYPES, TBlueprintActionSchema } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { RESOURCE_ERROR_NAMES } from "../ResourceErrorNames";
import { IActionResourceProps, IActionResourceType } from "./IActionResource";

type TActionSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintAction>;
type TActionSchemaSpec = TGetBlueprintSchemaSpec<TBlueprintActionSchema>;

export class ActionResourceV1 implements IActionResourceType {
	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.ACTION_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.ACTION;
	}

	public get statsName(): string {
		return "actions";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-action-v1");
	}

	public setup(resource: IActionResourceProps): IActionResourceProps {
		resource.resourceType = RESOURCE_TYPES.ACTION;
		resource.parsingDetails.isRegistered = true;

		return resource;
	}

	public async scan(resource: IActionResourceProps): Promise<IActionResourceProps> {
		resource.parsingDetails.paramsSchemaImportOk = false;
		resource.parsingDetails.paramsSchemaOk = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Scan action '${uri}'.`);

		let dCtx: DesignContext;
		let model: TActionSchemaModel;

		try {
			dCtx = resourceManager.createDCtx(true);

			resource = await resourceManager.parseModel<IActionResourceProps>(
				resource,
				dCtx,
				BlueprintAction
			);
			model = resource.parsedData.model as TActionSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse action model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec as TActionSchemaSpec;

			if (!spec) {
				this.logger.warn("Can't parse action model.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.parsedData.label = spec.label;
			resource.parsedData.description = spec.description;
			resource.parsedData.requireAuthenticatedUser = spec.spec.requireAuthenticatedUser;
			resource.parsedData.private = spec.spec.private;
			resource.parsedData.timeout = spec.spec.timeout;
			resource.parsedData.maxRecursion = spec.spec.maxRecursion;

			resource.parsedData.scheduled = !!spec.spec.scheduled;
			if (resource.parsedData.scheduled) {
				resource.parsedData.scheduledOnce = spec.spec.scheduled.once;
				resource.parsedData.scheduledInterval = spec.spec.scheduled.interval;
			}

			resource.parsedData.paramsSchema = spec.spec.params;
			resource.parsingDetails.paramsSchemaOk = true;
			resource.parsedData.paramsSchemaImport = importSchema<
				ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>
			>(spec.spec.params);
			resource.parsingDetails.paramsSchemaImportOk = true;

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't scan action '${uri}'.`, error);
			return resource;
		} finally {
			if (model) {
				model.schema.destroy(model);
			}
			if (dCtx) {
				dCtx.destroy();
			}
		}
	}

	public async parse(resource: IActionResourceProps): Promise<IActionResourceProps> {
		resource.parsingDetails.renderFnOk = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse action '${uri}'.`);

		let dCtx: DesignContext;
		let model: TActionSchemaModel;
		let cCtx: CompileContext;

		try {
			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IActionResourceProps>(
				resource,
				dCtx,
				BlueprintAction
			);
			model = resource.parsedData.model as TActionSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse action model.");
				this.logger.debug({ uri });
				return resource;
			}

			/** If action is scheduled it needs to check if it has nodes that are forbidden for scheduled action. */
			if (resource.parsedData.scheduled) {
				/** Scheduled action can't require authenticated user. Because it's impossible to get user's context in scheduled action. */
				if (resource.parsedData.requireAuthenticatedUser) {
					resource.reportRuntimeErrors({
						getRuntimeErrors: () => {
							return [
								{
									severity: DOC_ERROR_SEVERITY.ERROR,
									name: RESOURCE_ERROR_NAMES.RESOURCE_INVALID_CONFIG,
									message: `Scheduled action can't require user to be authenticated. It's impossible to get user session in scheduled action.`,
									details: [
										"Invalid value for scheduled action: 'requireAuthenticatedUser = true'."
									],
									modelPath: [ "$", "spec", "requireAuthenticatedUser" ],
									modelNodeId: model.nodeId
								} as IRuntimeError
							];
						}
					} as RuntimeContext);
				}

				/** Full rendering. */
				resource = await resourceManager.renderSpec(resource);
				const spec = resource.parsedData.spec as TActionSchemaSpec;

				if (Array.isArray(spec.spec.nodes)) {
					for (let i = 0; i < spec.spec.nodes.length; i++) {
						const node = spec.spec.nodes[i];
						if (
							[ NODE_TYPES.LOGIN, NODE_TYPES.LOGOUT, NODE_TYPES.SET_SESSION ].includes(
								node.type
							)
						) {
							resource.reportRuntimeErrors({
								getRuntimeErrors: () => {
									return [
										{
											severity: DOC_ERROR_SEVERITY.ERROR,
											name: RESOURCE_ERROR_NAMES.RESOURCE_INVALID_CONFIG,
											message: `Action contains node type '${node.type}' that is forbidden for scheduled action.`,
											details: [
												`Action Id: '${spec.id}'`,
												`Action node '${node.id}'`,
												`Node type '${node.type}' is forbidden for scheduled action.`
											],
											modelPath: [ "$", "spec", "nodes", i, node.type ],
											modelNodeId: model.nodeId
										} as IRuntimeError
									];
								}
							} as RuntimeContext);
						}
					}
				}
			}

			cCtx = resourceManager.createCCtx();

			const compiledModel = cCtx.compileModel(model.props.spec, true, true);
			resource.reportCompileErrors(cCtx);

			if (cCtx.hasFatalErrors()) {
				this.logger.info(`Action '${uri}' has fatal compile errors.`);
				this.logger.debug(cCtx.getCompileErrors());
				return resource;
			}

			let renderFn;
			try {
				renderFn = loadCompiledModel(compiledModel.code);
			} catch (error) {
				this.logger.warn("Failed to load compiled model.");
				this.logger.debug(error);

				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [
							{
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: RESOURCE_ERROR_NAMES.RESOURCE_COMPILATION_ERROR,
								message: "Failed to load compiled model.",
								details: [ `Action Id: '${resource.id}'` ],
								modelPath: [ "$" ],
								modelNodeId: model.nodeId
							} as IRuntimeError
						];
					}
				} as RuntimeContext);

				return resource;
			}

			resource.parsedData.renderFn = renderFn;
			resource.parsingDetails.renderFnOk = true;

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't parse action '${uri}'.`, error);
			return resource;
		} finally {
			try {
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug(error);
			}
		}
	}

	public getDependenciesToReload(
		resource: IActionResourceProps,
		eventType?: RESOURCE_ON_EVENT_TYPE
	): string[] {
		if (
			eventType &&
			[ RESOURCE_ON_EVENT_TYPE.UPDATE, RESOURCE_ON_EVENT_TYPE.DELETE ].includes(eventType)
		) {
			return resource?.dependencies?.map((dep) => dep.uri) || [];
		}

		return [];
	}
}
