/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, DESIGN_CONTEXT_READ_MODE } from "@hexio_io/hae-lib-blueprint";

import { DOC_TYPES } from "./DocTypes";
import { createBlueprintSchema } from "./BlueprintBase";
import { NODE_TYPES } from "./nodes/BlueprintNode";
import { termsEditor } from "../terms";
import { CONST } from "../constants";
import { BlueprintEndpointFlowNodeTypes } from "./BlueprintNodes";

const EndpointObjectSchema = BP.Object({
	props: {
		requireAuthenticatedUser: BP.Prop(
			BP.Const.Boolean({
				label: termsEditor.endpoint.requireAuthenticatedUser.label,
				description: termsEditor.endpoint.requireAuthenticatedUser.description,
				//label: "Require authenticated user",
				//description: "When true only logged in users will be able to request this endpoint.",
				default: true
			})
		),
		nodes: BP.Prop(
			BP.Conditional({
				condition: (dCtx) => {
					return dCtx.getReadMode() === DESIGN_CONTEXT_READ_MODE.FULL;
				},
				value: BP.Special.FlowNodeList({
					nodeTypes: BlueprintEndpointFlowNodeTypes,
					entryNode: {
						id: "request",
						type: NODE_TYPES.REQUEST,
						defaultPosition: {
							x: 10,
							y: 50
						},
						defaultOpts: {}
					}
				})
			})
		),
		timeout: BP.Prop(
			BP.Const.Integer({
				label: termsEditor.endpoint.timeout.label,
				description: termsEditor.endpoint.timeout.description,
				default: CONST.ACTION.INVOCATION.DEFAULT_TIMEOUT,
				constraints: {
					required: true,
					min: CONST.ACTION.INVOCATION.MIN_TIMEOUT,
					max: CONST.ACTION.INVOCATION.MAX_TIMEOUT
				}
			})
		)
	}
});

export type TBlueprintEndpointSpecSchema = typeof EndpointObjectSchema;
export const BlueprintEndpoint = createBlueprintSchema(DOC_TYPES.ENDPOINT_V1, EndpointObjectSchema);
export type TBlueprintEndpointSchema = typeof BlueprintEndpoint;
