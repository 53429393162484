/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportValidator } from "../ExportImportSchema/ExportSchema";
import {
	IBlueprintSchemaValidationError,
	IBlueprintSchemaValidator,
	IBlueprintSchemaValidatorHandler,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ValidatorDeclarationError } from "../Shared/ValidatorDeclarationError";

type TValidatorNumberHandler = IBlueprintSchemaValidatorHandler<number>;
type TValidatorNumber<TOpts> = IBlueprintSchemaValidator<number, TOpts>;

/**
 * Number value validator options
 */
export type IValidatorNumberOpts = {
	/** Required value */
	required?: boolean;
	/** Constant value */
	const?: number;
	/** Minimum value */
	min?: number;
	/** Maximum value */
	max?: number;
	/** Minimum exclusive value*/
	exclusiveMin?: number;
	/** Maximum exclusive value*/
	exclusiveMax?: number;
};

const VALIDATOR_NAME = "ValidatorNumber";

/**
 * Number value validator
 */
export const ValidatorNumber: TValidatorNumber<IValidatorNumberOpts> = (
	opts: IValidatorNumberOpts
): TValidatorNumberHandler => {
	if (opts.required !== undefined && opts.required !== null && typeof opts.required !== "boolean") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `required` to be a boolean");
	}

	if (opts.const !== undefined && opts.const !== null && typeof opts.const !== "number") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `const` to be a number");
	}

	if (opts.min !== undefined && opts.min !== null && typeof opts.min !== "number") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `min` to be a number");
	}

	if (opts.max !== undefined && opts.max !== null && typeof opts.max !== "number") {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `max` to be a number");
	}

	if (
		opts.exclusiveMin !== undefined &&
		opts.exclusiveMin !== null &&
		typeof opts.exclusiveMin !== "number"
	) {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `exclusiveMin` to be a number");
	}

	if (
		opts.exclusiveMax !== undefined &&
		opts.exclusiveMax !== null &&
		typeof opts.exclusiveMax !== "number"
	) {
		throw new ValidatorDeclarationError(VALIDATOR_NAME, "expecting option `exclusiveMax` to be a number");
	}

	return {
		validate: (value: number): IBlueprintSchemaValidationError[] => {
			const errors = [];

			if (
				(opts.required === true && (typeof value !== "number" || isNaN(value))) ||
				(!opts.required &&
					!(value === null || value === undefined) &&
					(typeof value !== "number" || isNaN(value)))
			) {
				errors.push({
					type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
					message: "Should be a number"
				});
			}

			if (
				opts.const !== undefined &&
				opts.const !== null &&
				typeof value === "number" &&
				Math.abs(value - opts.const) >= Number.EPSILON
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.CONST,
						message: `Should be eq const ${opts.const}`,
						metaData: {}
					}
				];
			}

			if (
				opts.min !== undefined &&
				opts.min !== null &&
				typeof value === "number" &&
				value < opts.min
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Should be gt ${opts.min}`,
						metaData: {}
					}
				];
			}

			if (
				opts.max !== undefined &&
				opts.max !== null &&
				typeof value === "number" &&
				value > opts.max
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Should be lt ${opts.max}`,
						metaData: {}
					}
				];
			}

			if (
				opts.exclusiveMin !== undefined &&
				opts.exclusiveMin !== null &&
				typeof value === "number" &&
				value <= opts.exclusiveMin
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Should be ge ${opts.exclusiveMin}`,
						metaData: {}
					}
				];
			}

			if (
				opts.exclusiveMax !== undefined &&
				opts.exclusiveMax !== null &&
				typeof value === "number" &&
				value >= opts.exclusiveMax
			) {
				return [
					{
						type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
						message: `Should be le ${opts.exclusiveMax}`,
						metaData: {}
					}
				];
			}

			return errors;
		},

		compile: (): string => {
			const parts = [];

			if (opts.required === true) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value !== "number" || isNaN(value)){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a number" }); }`
				);
			}

			if (!opts.required) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(!(value === null || value === undefined) && (typeof value !== "number" || isNaN(value))){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED}",message: "Should be a number" }); }`
				);
			}

			if (opts.const !== undefined && opts.const !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value === "number" && Math.abs(value - ${opts.const}) >= Number.EPSILON){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.CONST}",message: "Should be eq const ${opts.const}" }); }`
				);
			}

			if (opts.min !== undefined && opts.min !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value === "number" && value < ${opts.min}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Should be gt ${opts.min}" }); }`
				);
			}

			if (opts.max !== undefined && opts.max !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value === "number" && value > ${opts.max}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Should be lt ${opts.max}" }); }`
				);
			}

			if (opts.exclusiveMin !== undefined && opts.exclusiveMin !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value === "number" && value <= ${opts.exclusiveMin}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Should be ge ${opts.exclusiveMin}" }); }`
				);
			}

			if (opts.exclusiveMax !== undefined && opts.exclusiveMax !== null) {
				// eslint-disable-next-line max-len
				parts.push(
					`if(typeof value === "number" && value >= ${opts.exclusiveMax}){ errors.push({ type: "${SCHEMA_VALIDATION_ERROR_TYPE.RANGE}",message: "Should be le ${opts.exclusiveMax}" }); }`
				);
			}

			// Minification
			const code = parts
				.join(" ")
				.replace(/{ /g, "{")
				.replace(/ }/g, "}")
				.replace(/: "/g, ':"')
				.replace(/ ,/g, ",")
				.replace(/ < /g, "<")
				.replace(/ > /g, ">")
				.replace(/ <= /g, "<=")
				.replace(/ >= /g, ">=")
				.replace(/ === /g, "===")
				.replace(/ && /g, "&&")
				.replace(/ !== /g, "!==")
				.replace(/"number"/g, "n")
				.replace(/value/g, "v")
				.replace(/errors.push/g, "e.push");

			return `const e=[];const n="number";${code} return e;`;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		export: (): any => {
			return exportValidator(VALIDATOR_NAME, [ opts ]);
		}
	};
};
